<template>
<div>
    <!-- 내용보기 모달 : 작동안됨, 모달 위치로 예상됨 -->
    <teleport to="body">
        <div class="modal fade" :id="idModalCompu" tabindex="-1" aria-labelledby="modalViewLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header btn-primary-soft">
                    <h1 class="modal-title fs-5" :id="idModalCompu">View</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="white-space-newline"><small>{{content}}</small></p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-success-soft" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>    

    <li>
        <a v-if="isShow" disabled class="list-group-item-action border-0 border-bottom d-flex p-3">
            <div class="me-3">
                <div class="avatar avatar-md">
                    <img v-if="create_photo" class="avatar-img rounded-circle" :src="create_photo" alt="avatar">
                    <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user.png" alt="avatar">
                </div>
            </div>
            <div >
                <p class="text-body small m-0">{{contentSplice60}}</p>
                <!-- <u class="small">{{create_name}}</u> -->
                <u class="small btn btn-sm" @click="onChangeShow" >View detail</u>
                <!-- <span class="badge text-bg-secondary" @click="onChangeShow" :data-bs-target="idMoadlClickCompu">View detail</span> -->
            </div>
        </a>
        <div v-else class="list-group-item-action border-0 border-bottom d-flex p-3">
            <div>
                <p class="white-space-newline"><small>{{content}}</small></p>
            </div>
        </div> 
    </li>


    <!-- List  -->
    <!-- <div class="my-4 d-flex" :class="depth_class" >
        <img v-if="create_photo" class="avatar avatar-md rounded-circle me-3" :src="create_photo" alt="avatar">
        <img v-else class="avatar avatar-md rounded-circle me-3" src="/assets/images/avatar/user.png" alt="avatar">
        <div>
            <div class="mb-2">
                <h5 class="m-0">{{create_name}}</h5>
                <span class="me-3 small">{{date}}</span>
            </div>
            <p class="white-space-newline">{{content}}</p>
            <button v-if="depth < 7" type="button" class="btn btn-sm btn-light mb-0" data-bs-toggle="modal" :data-bs-target="idShapCompu">Reply</button>
            <button type="button" class="btn btn-sm btn-info mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idUpdateShapCompu"><i class="bi bi-pencil"></i></button>
            <button type="button" class="btn btn-sm btn-danger mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu"><i class="bi bi-trash"></i></button>
            <button type="button" @click="changeIsDisplay" class="btn btn-sm mb-0 ms-2" :class="is_display ? 'btn-primary':'btn-orange'" >{{is_display ?'보임':'숨김'}}</button>
        </div>
    </div> -->
</div>        
</template>

<script>
export default {
    components: {},
    props: [
        'id','content','date',
        'create_name','create_photo',
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  

           isShow: true, 
        }
    },
    
    computed: {
        contentSplice60(){
            return this.content.slice(0,60);
        },
        idModalCompu(){
            return 'viewTopModal'+this.id
        },
        idMoadlClickCompu(){
            return '#viewTopModal'+this.id
        },

    },
    methods: {
        onChangeShow(){
            this.isShow = !this.isShow ;
            this.updateCheckForm();
        },
        updateCheckForm(){
            this.$emit('updateCheck', this.id)
        },
    },
    
}
</script>