<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>
	
    <!-- =======================
    Page Banner START -->
    <company-dashboard-top :title="'관리자 페이지 설정'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
                        
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <company-dashboard-left :menu="'display'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Edit profile START -->
                    <div class="card border rounded-3 shadow">
                        <!-- Card header -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="card-header-title mb-0">관리자 페이지 설정 > 공개여부</h3>
                        </div>
                  
                        <!-- Card body START -->
                        <div class="card-body">


                            <div class="row g-4">

                                <!-- Right side START -->
                                <div class="col-xl-12">

                                    <!-- Tab Content START -->
                                    <!-- <div class="tab-content"> -->

                                        <!-- Personal Information content START -->
                                        <!-- <div class="tab-pane show active" id="tab-1"> -->
                                            <!-- <div class="card shadow"> -->

                                                <!-- Card header -->
                                                <!-- <div class="card-header border-bottom">
                                                    <h5 class="card-header-title">Information Settings</h5>
                                                </div> -->


                                                <!-- Card body START -->
                                                <!-- <div class="card-body"> -->

                                                    <form @submit.prevent="sendDisplayForm" class="row g-4 align-items-center mt-1">

                                                        <!-- Radio items -->
                                                        <div class="col-lg-12">
                                                            <label class="form-label">공개 여부</label>
                                                            <div class="d-sm-flex">
                                                                <div class="form-check form-switch form-check-md">
                                                                    <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputDisplay" @click="onChangeDisplay">
                                                                    <label v-if="inputDisplay" class="form-check-label" for="profilePublic">공개 </label>
                                                                    <label v-else class="form-check-label" for="profilePublic">비공개</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Save button -->
                                                        <div class="d-sm-flex justify-content-end">
                                                            <button type="submit" class="btn btn-primary mb-0">Save changes</button>
                                                        </div>
                                                    </form>
                                                    <!-- </div> -->
                                                <!-- Card body END -->

                                            <!-- </div>			 -->
                                        <!-- </div> -->

                                    <!-- </div> -->
                                    <!-- Tab Content END -->
                                </div>
                                <!-- Right side END -->
                            </div> <!-- Row END -->		

                        </div>
                        <!-- Card body END -->
                    </div>
                    <!-- Edit profile END -->
                    
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pagess > companydash > CompanyDashSettingInfoUpdate.vue]" ;
import axios from 'axios';

import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';

export default {
    components: {
        "company-dashboard-top": CompanyDashboardTop,
        "company-dashboard-left": CompanyDashboardLeft,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,       
            
            inputId : null,

            inputPhoto : null ,
            inputPhotoUpdate : null,  
            inputPhotoTmp : null, 
            inputWidth : 300,
            
            inputName: null,
            inputNameUpdate: null,
            inputCompany: null, 
            inputCompanyUpdate: null, 
            inputMajor: null, 
            inputMajorUpdate: null, 
            inputDescription: null,
            inputDescriptionUpdate: null,
            inputPhone: null, 
            inputPhoneUpdate: null, 
            inputEmail: null, 
            inputEmailUpdate: null, 
            inputContactemail: null, 
            inputContactemailUpdate: null, 
            inputWeb: null, 
            inputWebUpdate: null, 
            inputAddr : null, 
            inputAddrUpdate : null, 

            inputDisplay : null, 
            inputDisplayUpdate : null, 

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            // this.id = this.isUser() ;
            // if(this.id) {
            this.getInfo();
            // this.getCareerList();
            // }
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },    
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        email : function(){
            return this.$store.getters['auth/email'] ;
        },
        name(){
            return this.inputNameUpdate ;
        },
        phone(){
            return this.inputPhoneUpdate ;
        },
        addr(){
            return this.inputAddrUpdate ; 
        },
        description(){
            return this.inputDescriptionUpdate ; 
        },
    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/signin');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            // console.log("[InstructorDashSettingUpdate.vue] isAuth():", this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            // console.log("[InstructorDashSettingUpdate.vue] isUser():", this.$store.getters['auth/userId']);
            return this.$store.getters['auth/userId'] ;
        },
        onChangeDisplay(){
            this.inputDisplay = !this.inputDisplay ;
        },
        
        async getInfo(){
            let funcname__ ="getInfo() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 
            
            let serverData = await axios({
                method: 'get',
                url: '/api/company/info',
                headers: {
                    'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined" || serverData === undefined ||  serverData.status !== 200){
                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" && data.status_code == 200){
                    // console.log(filename__+''+funcname__+' response:', data.response);
                    
                    const setting = data.response ;  //기본정보 

                    const careers = data.careers ? data.careers  : [] ;   // 학력, 회사경력 
                    const skills = data.skills ? data.skills: [];     // skill
                    const socials = data.socials ? data.socials : [] ;   // 소셜미디어 

                    this.inputId = setting.id ;

                    this.inputName = setting.name ? setting.name : '' ;
                    this.inputNameUpdate = setting.name ? setting.name : '' ;
                    this.inputCompany = setting.company ? setting.company : '' ;
                    this.inputCompanyUpdate = setting.company ? setting.company : '' ;
                    this.inputEmail = setting.email ? setting.email : '' ;
                    this.inputEmailUpdate = setting.email ? setting.email : '' ;
                    this.inputContactemail = setting.contactemail ? setting.contactemail : '' ;
                    this.inputContactemailUpdate = setting.contactemail ? setting.contactemail : '' ;
                    this.inputMajor = setting.major ? setting.major : '' ;
                    this.inputMajorUpdate = setting.major ? setting.major : '' ;
                    this.inputPhone = setting.phone ? setting.phone : '' ;
                    this.inputPhoneUpdate = setting.phone ? setting.phone : '' ;
                    this.inputWeb = setting.web ? setting.web : '' ;
                    this.inputWebUpdate = setting.web ? setting.web : '' ;
                    this.inputAddr  = setting.addr ? setting.addr : '' ;
                    this.inputAddrUpdate  = setting.addr ? setting.addr : '' ;
                    this.inputDescription = setting.description ? setting.description : '' ;
                    this.inputDescriptionUpdate = setting.description ? setting.description : '' ;

                    this.inputDisplay = setting.is_display ;
                    

                } else if( typeof data !== "undefined" && data.status_code == 401){
                    this.dialogTitle ="Error" ;                    
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [401] ";  // this.error = err.msg || 'Failed to authenticate';                

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  
                }
            }


            this.isLoading = false ;            
        },

        // 공개여부 업데이트  
        async sendDisplayForm(){
            let funcname__ ="sendDisplayForm() ";

            let dataForm = { 
                'is_display' : this.inputDisplay,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: `/api/company/display/update/${this.inputId}`,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+' data:', serverData.data);

                        if(data.status_code === 201){
                                                                            
                            this.dialogTitle ='Success' ;
                            this.error = '공개상태 변경되었습니다.';  
                            
                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;

                                }, 500);
                            }                                                    

                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            this.dialogTitle ="입력 실패" ;
                            this.error = serverData.data.error_description;

                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    }
}
</script>

<style scoped>

</style>