<template>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- 삭제 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" id="idDeleteCompu">Delete</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>
    
    <tr>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="w-60px">
                    <router-link :to="detailLink" >
                        <img v-if="image" :src="image" class="rounded" alt="">
                        <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" alt="">
                        <!-- <img src="/assets/images/element/gallery.svg" class="h-50px" alt=""></img> -->
                    </router-link>
                </div>
                <!-- Title -->
                <h6 class="table-responsive-title mb-0 ms-2">	
                    <router-link :to="detailLink" class="stretched-link">
                        <span v-if="inputIsDisplay && company_is_display">{{title}}</span>
                        <span v-else class="text-decoration-line-through "><i class="fas fa-eye-slash me-3"></i>{{title}}</span>
                    </router-link>
                    &nbsp;<span v-if="!inputIsDisplay" class="badge text-bg-warning">컨텐츠 비공개</span>
                    &nbsp;<span v-if="!company_is_display" class="badge text-bg-danger">파트너 비공개</span>
                </h6>
            </div>
        </td>
        <td> 
            <span class="badge bg-success bg-opacity-15 text-success">{{categorystr}}</span>
        </td>
        <!-- <td>             
            <span v-if="onoff" class="badge text-bg-orange">Online</span>
            <span v-else  class="badge text-bg-primary">Offline</span> 
        </td> -->
        <td>             
            <span v-if="level == 0" class="badge text-bg-orange">All level</span>
            <span v-else-if="level == 1"  class="badge text-bg-primary">Beginner</span> 
            <span v-else-if="level == 2" class="badge text-bg-purple">Intermediate</span>
            <span v-else-if="level == 3" class="badge text-bg-success">Advanced</span>
        </td>

        <!-- <td>
            <span v-if="is_price" >{{price}}</span>
            <span v-else class="badge text-bg-success">Free</span>
        </td> -->

        <!-- Table data -->
        <td> 
            <span v-if="is_display" class="badge bg-success bg-opacity-15 text-success">Live</span>
            <span v-else class="badge bg-warning bg-opacity-15 text-warning">Pending</span> 
            <!-- <a href="#" class="btn btn-sm btn-dark me-1 mb-1 mb-md-0">Edit</a> -->
        </td>
        <!-- Table data -->
        <td>
            <ul class="list-inline mb-0">
                <li v-if="reviewavg >= 1" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <li v-if="reviewavg >= 2" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <li v-if="reviewavg >= 3" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <li v-if="reviewavg >= 4" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <li v-if="reviewavg == 5" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li> 
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                
                <!-- <li class="list-inline-item ms-2 h6 fw-light">{{reviewavg}}/5.0</li> -->
            </ul>

        </td>
        <td>
            <div class="form-check form-switch form-check-md">
                <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsDisplay" @click="onChangeDisplay" />
                <label v-if="inputIsDisplay" class="form-check-label" for="profilePublic">보임 </label>
                <label v-else class="form-check-label" for="profilePublic">숨김</label>
            </div>
        </td>
        <td>
            <button type="button" @click="upSeqForm" :class="min? 'btn btn-outline-light disabled':'btn btn-outline-primary' "><i class="fas fa-arrow-alt-circle-up"></i></button>
            <button type="button" @click="downSeqForm" :class="max? 'btn btn-outline-light ms-2 disabled':'btn btn-outline-danger ms-2' "><i class="fas fa-arrow-circle-down"></i></button>
        </td>
        <td>
            <button type="button"  class="btn btn-outline-danger" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu"><i class="bi bi-trash3"></i></button>
        </td>
    </tr>


</template>

<script>
var filename__="[components > admin > courserecommend > AdminCourseRecommendListComponent.vue]" ;

import axios from 'axios';

export default {
    components: {},
    props: [
        'id','num',
        'is_display', 'seq', 'min','max',
        'content_id',
        'categorystr',
        'company_is_display',
        'title','level','is_price','price','coursetime','lecturecount','image','onoff','course_is_display','reviewavg'
    ],
    data() {
        return {
            isLoading: false,
			isApplyging: false,
            error: null,     
            dialogTitle: null,    

            // inputIsDisplay: this.is_display,
            inputIsDisplay: this.course_is_display,
        }
    },
    
    computed: {
        detailLink(){
            return '/totalcourse/'+this.content_id
        },
        timeFormat(){
            let hour = parseInt(this.coursetime/60) ;
            let minute = this.coursetime - hour * 60 ;
            if(hour == 0){
                return this.coursetime +'m'                
            } else if(minute == 0){
                return hour+'h'
            } else {
                return hour + 'h '+minute + 'm' ;                
            }
        },
        idDeleteCompu(){
            return 'deleteModal'+this.id
        },
        idDeleteShapCompu(){
            return '#deleteModal'+this.id
        },

    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        onChangeDisplay(){
            this.inputIsDisplay = !this.inputIsDisplay ;
            this.sendDisplayForm();
        },
        // 정보 업데이트  
        async sendDisplayForm(){
            let funcname__ ="sendDisplayForm()" ;

            let dataForm = { 
                'is_display': this.inputIsDisplay,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/course/recommend/display/'+this.id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('changeRefreshList');

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }

            } catch(error){
                // console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        async upSeqForm(){
            let funcname__ ="upSeqForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/course/recommend/upseq/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('changeRefreshList');

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                // console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        async downSeqForm(){
            let funcname__ ="downSeqForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/course/recommend/downseq/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('changeRefreshList');

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                // console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        async removeForm(){
            let funcname__ ="removeForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/course/recommend/delete/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }  

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '삭제에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('changeRefreshList');

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },


    },
    
}
</script>