export default {
    requests(state, _, _2, rootGetters){ // requests(state, getters, rootState, rootGetters){
        const coachId = rootGetters.userId; // store/index.js
        console.log(state.requests);
        return state.requests.filter(req => req.coachId === coachId ) ;
    },
    hasRequests(_, getters){ // hasRequests(state, getters){
        // return state.requests && state.requests.length > 0 ;
        return getters.requests && getters.requests.length > 0 ;
    }
};