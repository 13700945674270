<template>
    <div>
        <div class="offcanvas-xl offcanvas-end" tabindex="-1" id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
            <!-- Offcanvas header -->
            <div class="offcanvas-header bg-light">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">파트너 Dashboard</h5>
                <button  type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close"></button>
            </div>
            <!-- Offcanvas body -->
            <div class="offcanvas-body p-3 p-xl-0">
                <div class="bg-dark border rounded-3 pb-0 p-3 w-100">
                    <!-- Dashboard menu -->
                    <div class="list-group list-group-dark list-group-borderless">
                        <router-link to="/companydash/dashboard" class="list-group-item" :class="menu == 'dashboard' ?'active':''"><i class="bi bi-ui-checks-grid fa-fw me-2"></i>Dashboard</router-link>
                        <router-link to="/companydash/courses" class="list-group-item" ><i class="bi bi-list-task"></i>스터디룸/정보창고</router-link>
                        <router-link to="/companydash/courses" class="list-group-item ms-4" :class="menu == 'course' ?'active':''" ><i class="bi bi-film me-2"></i>스터디룸 리스트 </router-link>
                        <router-link to="/companydash/reviews" class="list-group-item ms-4" :class="menu == 'review' ?'active':''" ><i class="bi bi-star fa-fw me-2"></i>스터디룸 Reviews</router-link>
                        <router-link to="/companydash/comments" class="list-group-item ms-4" :class="menu == 'comment' ?'active':''" ><i class="fa fa-comments me-2"></i>스터디룸 Comments</router-link>
                        <router-link to="/companydash/blogs" class="list-group-item ms-4" :class="menu == 'blog' ?'active':''"><i class="bi bi-info-square me-2"></i>정보창고 리스트</router-link>
                        <router-link to="/companydash/course/workers" class="list-group-item ms-4" :class="menu == 'courseworker' ?'active':''"><i class="bi bi-pencil me-2"></i>공동작업자 등록내역</router-link>
                        <router-link to="/companydash/orders" class="list-group-item" :class="menu == 'order' ?'active':''"><i class="bi bi-folder-check fa-fw me-2"></i>수강신청내역</router-link>
                        <router-link to="/companydash/students" class="list-group-item ms-4" :class="menu == 'student' ?'active':''"><i class="bi bi-people fa-fw me-2"></i>Students</router-link>
                        <!-- <router-link to="/companydash/profile/update" class="list-group-item" :class="menu == 'profile' ?'active':''" ><i class="bi bi-pencil-square fa-fw me-2"></i>Edit Profile</router-link> -->
                        <router-link to="/companydash/workers" class="list-group-item" :class="menu == 'worker' ?'active':''"><i class="fas fa-chalkboard-teacher fa-fw me-2"></i>등록된 공동작업자</router-link>
                        <router-link to="/companydash/worker/requests" class="list-group-item ms-4" :class="menu == 'request' ?'active':''"><i class="fas fa-chevron-circle-right fa-fw me-2"></i>공동작업 등록신청자</router-link>
                        
                        <router-link to="/companydash/notifications" class="list-group-item" :class="menu == 'notification' ?'active':''"><i class="bi bi-bell fa-fw me-2"></i>Notification</router-link>

                        <router-link to="/companydash/setting/info/update" class="list-group-item"  ><i class="fas fa-user-cog fa-fw me-2"></i>관리자 페이지 설정</router-link>
                        <router-link to="/companydash/setting/info/update" class="list-group-item ms-4" :class="menu == 'info' ?'active':''"><i class="fas fa-globe fa-fw me-2"></i>Setting Info</router-link>
                        <router-link to="/companydash/setting/photo/update" class="list-group-item ms-4" :class="menu == 'photo' ?'active':''"><i class="bi bi-person-bounding-box fa-fw me-2"></i>대표 이미지 변경</router-link>
                        <router-link to="/companydash/setting/display/update" class="list-group-item ms-4" :class="menu == 'display' ?'active':''"><i class="fas fa-eye fa-fw me-2"></i>공개여부</router-link>
                        <router-link to="/companydash/setting/career/update" class="list-group-item ms-4" :class="menu == 'career' ?'active':''"><i class="fas fa-graduation-cap fa-fw me-2"></i>학력/경력 </router-link>
                        <router-link to="/companydash/setting/skill/update" class="list-group-item ms-4" :class="menu == 'skill' ?'active':''"><i class="fas fa-award fa-fw me-2"></i>Skill</router-link>
                        <router-link to="/companydash/setting/social/update" class="list-group-item ms-4" :class="menu == 'social' ?'active':''"><i class="fas fa-sliders-h fa-fw me-2"></i>Social Settings</router-link>
                        <router-link to="/companydash/setting/worker/update" class="list-group-item ms-4" :class="menu == 'workerset' ?'active':''"><i class="fas fa-user-cog fa-fw me-2"></i>공동작업자 설정여부</router-link>
                        
                        <router-link to="/companydash/delete" class="list-group-item" :class="menu == 'delete' ?'active':''" ><i class="bi bi-trash fa-fw me-2"></i>파트너 탈퇴하기</router-link>
                        <router-link to="#" @click="logout" class="list-group-item text-danger bg-danger-soft-hover"><i class="fas fa-sign-out-alt fa-fw me-2"></i>Sign Out</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    props: ['menu'],
    data() {
        return {

        }
    },
    methods: {
		logout(){
			this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
			this.$router.replace('/');
		},
        moveToHome(){
            this.$store.dispatch('auth/outPageAdmin'); //==> store/modules/auth/actions.js
            this.$router.replace('/');
        }

    },    
}
</script>

    