<template>
<div class="row g-4 mb-4">
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Counter item -->
    <div class="col-md-6 col-xxl-3">
        <!-- 등록된 강좌 수  -->
        <div class="card card-body bg-purple bg-opacity-10 p-4 h-100">
            <div class="d-flex justify-content-between align-items-center">
                <!-- Digit -->
                <div>
                    <h2 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="1600"	data-purecounter-delay="200">{{inputCourseCount}}</h2>
                    <span class="mb-0 h6 fw-light">온라인 강좌</span>
                </div>
                <!-- Icon -->
                <div class="icon-lg rounded-circle bg-purple text-white mb-0"><i class="fas fa-user-tie fa-fw"></i></div>
            </div>
        </div>
    </div>

    <!-- Counter item -->
    <div class="col-md-6 col-xxl-3">
        <div class="card card-body bg-primary bg-opacity-10 p-4 h-100">
            <div class="d-flex justify-content-between align-items-center">
                <!-- Digit -->
                <div>
                    <h2 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="1235"	data-purecounter-delay="200">{{ inputEnrollCount }}</h2>
                    <span class="mb-0 h6 fw-light">등록한 강좌</span>
                </div>
                <!-- Icon -->
                <div class="icon-lg rounded-circle bg-primary text-white mb-0"><i class="fas fa-user-graduate fa-fw"></i></div>
            </div>
        </div>
    </div>

    <!-- Counter item -->
    <div class="col-md-6 col-xxl-3">
        <div class="card card-body bg-warning bg-opacity-15 p-4 h-100">
            <div class="d-flex justify-content-between align-items-center">
                <!-- Digit -->
                <div>
                    <h2 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="1958" data-purecounter-delay="200">{{ inputCompleteCount }}</h2>
                    <span class="mb-0 h6 fw-light">완료된 강좌</span>
                </div>
                <!-- Icon -->
                <div class="icon-lg rounded-circle bg-warning text-white mb-0"><i class="fas fa-tv fa-fw"></i></div>
            </div>
        </div>
    </div>



    <!-- Counter item -->
    <div class="col-md-6 col-xxl-3">
        <div class="card card-body bg-success bg-opacity-10 p-4 h-100">
            <div class="d-flex justify-content-between align-items-center">
                <!-- Digit -->
                <div>
                    <div class="d-flex">
                        <h2 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="845"	data-purecounter-delay="200">{{ inputCompanyCount }}</h2>
                        <!-- <span class="mb-0 h2 ms-1">hrs</span> -->
                    </div>
                    <span class="mb-0 h6 fw-light">파트너</span>
                </div>
                <!-- Icon -->
                <div class="icon-lg rounded-circle bg-success text-white mb-0"><i class="bi bi-stopwatch-fill fa-fw"></i></div>
            </div>
        </div>
    </div>
</div>

</template>
<script>
var filename__= "[components > admin > dashboard > AdminCourseCountComponent.vue] "
import axios from 'axios';

export default {
    components: {},
    props: [],
    data() {
        return {
           
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputCourseCount : 0,
            inputEnrollCount : 0,
            inputCompleteCount : 0,
            inputCompanyCount : 0,
        }
    },
    created(){
        this.getCourseCount()
        this.getEnrollCount()
        this.getCompanyCount()
    },
    computed: {

    },

    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        async getCourseCount(){
            let funcname__ =" getCourseCount() "

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/content/course/all/count',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }


            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputCourseCount = data.response.display_count ;           

                // console.log(filename__+''+funcname__+" inputCount:", this.inputCount);

            } else {
                console.log(filename__+''+funcname__+' err:');

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.msg;  
            }

            this.isLoading = false ;            
        },
        async getEnrollCount(){
            let funcname__ =" getEnrollCount() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/student/course/complete/all/count',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputEnrollCount = data.response.enroll_count ;           
                this.inputCompleteCount = data.response.complete_count ;           

                // console.log(filename__+''+funcname__+" inputEnrollCount:", this.inputEnrollCount);
                // console.log(filename__+''+funcname__+" inputCompleteCount:", this.inputCompleteCount);

            } else {
                // console.log(filename__+''+funcname__+' err:');

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
                // alert('error')
            }

            this.isLoading = false ;            
        },
        async getCompanyCount(){
            let funcname__ =" getCompanyCount() "
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/company/count/user',

            }).catch( err => {

                if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputCompanyCount = data.response ;           

                // console.log(filename__+''+funcname__+" inputCompanyCount:", this.inputCompanyCount);

            } else {
                console.log(filename__+''+funcname__+' err:');

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
                // alert('error')
            }

            this.isLoading = false ;            
        },

    },
    
}
</script>

<style scoped>
</style>