export default {    
    isDashboard(state){
        // console.log('[store/modules/dashboard/getters.js] isDashboard() state:',state);
        //~~ double ! : https://hermeslog.tistory.com/279
        //~~ double ? : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator
        return !!state.dashboard ;
    },    
    isCompanyDashboard(state){
        // console.log('[store/modules/dashboard/getters.js] isCompanyDashboard() state:',state);
        return !!state.company_dashboard ;
    },    
    isCourseWorkerDashboard(state){
        // console.log('[store/modules/dashboard/getters.js] isCourseWorkerDashboard() state:',state);
        return !!state.is_courseworker_dashboard ;
    },    

}