<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Page main content START -->
    <div class="page-content-wrapper border">
    
        <!-- Title -->
        <div class="row">
            <div class="col-12">
                <h1 class="h3 mb-2 mb-sm-0">작업 리스트</h1>
            </div>
        </div>
        <div class="row justify-content-between align-middle mb-3">
            <!-- Title -->
            <div class="col-sm-5">
                <!-- <h3 class="card-header-title mb-2 mb-sm-0">작업리스트</h3> -->
                <form class="rounded position-relative" style="display: inline-block;">
                    <input class="form-control bg-transparent" type="search" placeholder="Search" aria-label="Search" v-model.trim="inputSearch" >
                    <button @click="onChangeSearch" class="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="button">
                        <i class="fas fa-search fs-6 "></i>
                    </button>
                </form>
                <button type="button" @click="onChangeSearch" :class="searchCompu? 'btn btn-primary':'btn btn-outline-primary'" class="mb-0 ms-2">Search</button>
                <button type="button" @click="onChangeAll" class="btn btn-primary mb-0 ms-2">All</button>
                
            </div>
            
            <!-- Short by filter -->
            <div class="col-sm-5">
                <div class="row justify-content-between align-middle">
                    <div class="col-sm-6 text-right">
                        <button type="button" @click="onChangeCategory(1)" :class="categoryCompu == 1 ?'btn btn-danger':'btn btn-outline-danger'" class="mb-0 ms-4">버그</button>
                        <button type="button" @click="onChangeCategory(3)" :class="categoryCompu == 3 ?'btn btn-success':'btn btn-outline-success'" class="mb-0 ms-2">보완</button>
                        <button type="button" @click="onChangeCategory(2)" :class="categoryCompu == 2 ?'btn btn-primary':'btn btn-outline-primary'"  class="mb-0 ms-2">새로운작업</button>
                        
                    </div>
                    <div class="col-sm-6 text-left">
                        <form>
                            <select class="form-select js-choice z-index-9 bg-white" aria-label=".form-select-sm"
                                v-model="inputProcess"    
                                @change="onChangeSelect($event)"
                                >
                                <option :value="99">진행상태 by (All)</option>
                                <option :value="0">미확인</option>
                                <option :value="1">확인</option>
                                <option :value="2">검토중</option>
                                <option :value="3">작업예정</option>
                                <option :value="4">작업중</option>
                                <option :value="9">보류</option>
                                <option :value="10">처리완료</option>
                                <option :value="11">Pass</option>
                            </select>
                        </form>
                        
                    </div>
                </div>

            </div>
            <div class="col-sm-2 text-end">
                <router-link :to="addLink" class="btn btn-primary mb-0 ms-2">Create</router-link>
            </div>        
        </div>
        
        
        <!-- All review table START -->
        <div class="card card-body bg-transparent pb-0 border mb-4">
        
            <!-- Table START -->
            <div class="table-responsive border-0">
                <table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
                    <!-- Table head -->
                    <thead>
                        <tr>
                            <th scope="col" class="border-0 rounded-start">#</th>
                            <th scope="col" class="border-0">Category</th>
                            <th scope="col" class="border-0">Title</th>
                            <th scope="col" class="border-0">Status</th>
                            <th scope="col" class="border-0">작업시작일</th>
                            <th scope="col" class="border-0">작업종료일</th>
                            <th scope="col" class="border-0">Name</th>
                            <th scope="col" class="border-0">Date</th>
                            <th scope="col" class="border-0">Update</th>                            
                            <th scope="col" class="border-0 rounded-end">Action</th>
                        </tr>
                    </thead>
        
                    <!-- Table body START -->
                    <tbody>
        
                        <admin-plan-list-component 
                            v-for="item in itemList"
                                :key="item.id"
                                :id="item.id"
                                :num="item.num"
        
                                :category="item.category"
                                :title ="item.title"
                                :description="item.description"

                                :startdate="item.startdate"
                                :enddate="item.enddate"

                                :date="item.date"
                                :update="item.update"
        
                                :process="item.process"
        
                                :create_id ="item.create_id"
                                :create_name="item.create_name"
                                :create_photo="item.create_photo"
        
                                :page="page"                                
        
                        />                             
        
                    </tbody>
                </table>
            </div>
            <!-- Table END -->
        
            <!-- Card footer START -->
            <div class="card-footer bg-transparent px-0">
                <!-- Pagination START -->
                <div v-if="itemList.length > 0"> 
                    <admin-pagination :pagination="paginationCompu" @paging="pagingSend" />
                </div>
                <!-- Pagination END -->
            </div>
            <!-- Card footer END -->
        </div>
    
    
    </div>
    <!-- Page main content END -->
    
    
    <!-- Page content END -->
        
</div>
</template>

<script>
var filename__="[pages > admin > plan/ AdminPlanList.vue]"
import axios from 'axios';

import AdminPlanListComponent from '../../../components/admin/plan/AdminPlanListComponent.vue' ;
import AdminPagination from '../../../components/admin/ui/AdminPagination.vue';

export default {
    components: {
        "admin-plan-list-component": AdminPlanListComponent,
        "admin-pagination": AdminPagination,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList: [],
            page:1,
            current_path:"/admin/plans",  //current_path
            pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수  
            inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputCategory : 99, // 가장 큰 숫자로 모두 보기 0: 미설정 # 1: 버그 2: 보완  3: 새로운 작업
            inputProcess : 99, //가장 큰 숫자로 모두 보기 0: 미확인 1: 확인 2: 검토중 3:작업예정 4:작업중 9: 보류 10: 작업완료 11:pass 

        }
    },
    computed: {
        addLink(){
            return '/admin/plan/create'
        },
        paginationCompu(){
            return this.pagination ;
        },
        categoryCompu(){
            return this.inputCategory ;
        },
        searchCompu(){
            if( this.inputSearch === 'undefinded' || this.inputSearch == '' || this.inputSearch == null) { 
                return false ;
            } else {
                return true ;
            }
        }
    },  
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },       
    created(){
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getList();
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        pagingSend(page){
            this.page = page ;
            return this.getList();
        },		
        // 답변이나 수정, 삭제 후 refresh list
        sendRefreshForm(){
            this.getList();
        },
        onChangeSelect(event){
            this.inputProcess = event.target.value;
            this.page = 1 ;
            this.getList();
        },  
        onChangeCategory(_category){
            this.page = 1 ;
            this.inputCategory = _category ;
            this.inputProcess = 99 ;
            this.getList(); 
        },
        onChangeSearch(){
            this.page = 1 ;
            this.inputCategory = 99 ;
            this.inputProcess = 99 ;
            this.getList();
        },
        onChangeAll(){
            this.page = 1 ;
            this.inputCategory = 99 ;
            this.inputProcess = 99 ;
            this.inputSearch = '' ;
            this.getList(); 
        },

        async getList(){
            let funcname__ ="getList() " ;

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }
            if( this.inputCategory === 'undefinded' || this.inputCategory === '') { 
                this.inputCategory = 99 ; //pagination, search 에서 계속작동하기 위해서                 
            }             

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: `/api/plan/all/admin`,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },
                params: { 
                    page: this.page , 
                    page_per_count : this.inputPagePerCount,
                    block_size : this.inputBlockSize,
                    category : this.inputCategory,
                    process : this.inputProcess,
                    search : this.inputSearch,
                }

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                        
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            // console.log(filename__+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+funcname__+'  response:', data.response);
                    
                    this.pagination = data.response.pagination ;
                    let counter = data.response.pagination.total_count ;

                    let tmpList = data.response.data ;
                    let list = []; 

                    //메인 카테고리 값들을 입력하기                     
                    for(let item of tmpList ){
                        list.push({
                            num: counter--, 
                            id: item.id, 

                            category: item.category,
                            title:item.title,
                            description : item.description,

                            process:item.process,

                            create_id: item.create_id,
                            create_photo: item.create_photo,
                            create_name: item.create_name,

                            startdate: item.startdate, 
                            enddate: item.enddate, 

                            date: item.date, 
                            update: item.update,
                            
                        });
                    }

                    // console.log(filename__+funcname__+" list:", list);
                    this.itemList = list;

                    console.log(filename__+funcname__+" this.itemList:", this.itemList);
                    // console.log(filename__+''+funcname__+" pagination:", this.pagination);

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;     
        },


    }
}
</script>

<style scoped>

</style>