<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

<!-- Title -->
<div class="row justify-content-between align-middle mb-3">
    <!-- Title -->
    <div class="col-sm-6">
        <h3 class="card-header-title mb-2 mb-sm-0">스터디룸 Reviews</h3>
    </div>
    
    <!-- Short by filter -->
    <div class="col-sm-4">
        <form>
            <select class="form-select js-choice z-index-9 bg-white" aria-label=".form-select-sm"
                v-model="inputReviewCount"    
                @change="onChangeSelect($event)"
                >
                <option :value="10">Sort by (All)</option>
                <option :value="5">★★★★★ (5/5)</option>
                <option :value="4">★★★★☆ (4/5)</option>
                <option :value="3">★★★☆☆ (3/5)</option>
                <option :value="2">★★☆☆☆ (2/5)</option>
                <option :value="1">★☆☆☆☆ (1/5)</option>
            </select>
        </form>
    </div>
</div>


<!-- All review table START -->
<div class="card card-body bg-transparent pb-0 border mb-4">

    <!-- Table START -->
    <div class="table-responsive border-0">
        <table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
            <!-- Table head -->
            <thead>
                <tr>
                    <th scope="col" class="border-0 rounded-start">#</th>
                    <th scope="col" class="border-0">Course</th>
                    <th scope="col" class="border-0">Comment</th>
                    <th scope="col" class="border-0">Rating</th>
                    <th scope="col" class="border-0">Name</th>
                    <th scope="col" class="border-0">파트너</th>
                    <th scope="col" class="border-0">Date</th>
                    <th scope="col" class="border-0">Status</th>
                    <th scope="col" class="border-0">Hide/Show</th>
                    <th scope="col" class="border-0 rounded-end">Action</th>
                </tr>
            </thead>

            <!-- Table body START -->
            <tbody>

                <admin-course-review-list-component 
                    v-for="item in itemList"
                        :key="item.id"
                        :id="item.id"
                        :num="item.num"

                        :company_id="item.company_id"
                        :company_name ="item.company_name"
                        :company_image ="item.company_image"

                        :course_id="item.course_id"
                        :course_title ="item.course_title"
                        :course_image ="item.course_image"

                        :content_id="item.content_id"

                        :description="item.description"
                        :review="item.review"
                        :date="item.date"

                        :is_display="item.is_display"
                        :status="item.status"

                        :reply="item.reply"

                        :create_id ="item.create_id"
                        :create_name="item.create_name"
                        :create_photo="item.create_photo"

                        :page="page"
                        :review_count="inputReviewCount"

                        @sendRefreshForm="sendRefreshForm"
                />                             

            </tbody>
        </table>
    </div>
    <!-- Table END -->

    <!-- Card footer START -->
    <div class="card-footer bg-transparent px-0">
        <!-- Pagination START -->
        <div v-if="itemList.length > 0"> 
            <admin-pagination :pagination="paginationCompu" @paging="pagingSend" />
        </div>
        <!-- Pagination END -->
    </div>
    <!-- Card footer END -->
</div>
<!-- All review table END -->


</div>
<!-- Page main content END -->


<!-- Page content END -->
    
</div>
</template>

<script>
var filename__="[pages > admin > AdminReviewList.vue]"
import axios from 'axios';

import AdminCourseReviewListComponent from '../../../components/admin/course/AdminCourseReviewListComponent.vue' ;
import AdminPagination from '../../../components/admin/ui/AdminPagination.vue';

export default {
    components: {
        "admin-course-review-list-component": AdminCourseReviewListComponent,
        "admin-pagination": AdminPagination,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList: [],
            page:1,
            current_path:"/admin/reviews",  //current_path
			pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputReviewCount : 10, //가장 큰 숫자로 모든 review 보기 

        }
    },
    computed: {
        paginationCompu(){
            return this.pagination ;
        },
    },  
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },       
    created(){
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getList();
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        // 답변이나 수정, 삭제 후 refresh list
        sendRefreshForm(){
            this.getList();
        },
        onChangeSelect(event){
            // let funcname__ ="onChangeSelect()"
            // console.log(filename__+ ""+funcname__+" value:",event.target.value)
            this.inputReviewCount = event.target.value;
            this.getList();
        },  

        async getList(){
            let funcname__ ="getList() " ;

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }
            if( this.inputReviewCount === 'undefinded' || this.inputReviewCount === '') { 
                this.inputReviewCount = 10 ; //pagination, search 에서 계속작동하기 위해서                 
            }             

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: `/api/comment/review/all/admin`,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
					review_count : this.inputReviewCount,
                }

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            // console.log(filename__+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+funcname__+'  response:', data.response);
                    
                    this.pagination = data.response.pagination ;
                    let counter = data.response.pagination.total_count ;

                    let tmpList = data.response.data ;
                    let list = []; 

                    //메인 카테고리 값들을 입력하기                     
                    for(let item of tmpList ){
                        list.push({
                            num: counter--, 
                            id: item.id, 

                            description: item.description,
                            depth:item.depth,
                            seq : item.seq,
                            review : item.review,

                            is_display:item.is_display,
                            status : item.status,

                            company_id : item.company_id,
                            company_name : item.company_name,
                            company_image : item.company_image,

                            content_id : item.content_id,

                            course_id : item.course_id,
                            course_title : item.course_title,
                            course_image : item.course_image,

                            create_id: item.create_id,
                            create_photo: item.create_photo,
                            create_name: item.create_name,

                            date: item.date, 
                            update: item.update,
                            
                        });
                    }

                    // console.log(filename__+funcname__+" list:", list);
                    this.itemList = list;

                    // console.log(filename__+funcname__+" this.itemList:", this.itemList);
                    // console.log(filename__+''+funcname__+" pagination:", this.pagination);

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;     
        },


    }
}
</script>

<style scoped>

</style>