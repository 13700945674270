<template>
    <tr>
        <!-- Course item -->
        <td class="text-center text-sm-start">{{num}}</td>       
        <td>
            <div class="d-flex align-items-center">
                <!-- Image -->
                <div v-if="!is_text" class="w-60px">
                    <router-link :to="detailLink" >
                        <img v-if="image" :src="image" class="rounded" alt="">
                        <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" alt="">
                    </router-link>

                </div>
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6>
                        <router-link :to="detailLink">
                            <span>{{title}}</span>
                        </router-link>
                    </h6>
                    <!-- Info -->
                    <div class="d-sm-flex">
                        <p class="h6 fw-light mb-0 small me-3"><i class="fas fa-table text-orange me-2"></i>{{lecturecount}} lectures</p>
                        <p class="h6 fw-light mb-0 small"><i class="fas fa-check-circle text-success me-2"></i>{{ completecount }} Completed</p>
                    </div>
                </div>
            </div>
        </td>
        <!-- <td>
            <div v-if="onoff" class="badge bg-danger bg-opacity-10 text-success">Online</div>
            <div v-else class="badge bg-success bg-opacity-10 text-danger">Offline</div>
        </td>
        <td>
            <div v-if="is_price" class="badge bg-danger bg-opacity-10 text-success">{{price}}</div>
            <div v-else class="badge bg-success bg-opacity-10 text-success">free</div>
        </td> -->
        <td>
            <!-- <a href="#" class="btn btn-sm btn-success-soft btn-round me-1 mb-0"><i class="far fa-fw fa-edit"></i></a> -->
            <button v-if="level == 0" class="btn btn-sm btn-success-soft mb-0">All Level</button>
            <button v-if="level == 1"  class="btn btn-sm btn-primary-soft mb-0">초급</button>
            <button v-if="level == 2" class="btn btn-sm btn-orange-soft mb-0">중급</button>
            <button v-if="level == 3" class="btn btn-sm btn-danger-soft mb-0">고급</button>
        </td>
        <td>
            <!-- <a href="#" class="btn btn-sm btn-success-soft btn-round me-1 mb-0"><i class="far fa-fw fa-edit"></i></a> -->
            <!-- <button class="btn btn-sm btn-danger-soft btn-round mb-0"><i class="fas fa-fw fa-times"></i></button> -->
            <!-- <button class="btn btn-sm btn-danger-soft btn-round mb-0"><i class="fas fa-eye-slash"></i></button> -->
            <div class="badge bg-danger bg-opacity-10 text-primary">{{timeFormat}}</div>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div v-if="create_photo" class="avatar avatar-md mb-2 mb-md-0">
                    <img :src="create_photo" class="rounded-circle" alt="">
                </div>
                <div class="mb-0 ms-2">
                    <span class="text-body small">{{nameSlice10}}</span>
                </div>
            </div>
        </td>

        <td>{{date}}</td>

    </tr>

</template>

<script>
export default {
    components: {},
    props: [
        'id','num','create_id','create_name','create_email','create_photo','date',
        'title','level','coursetime','lecturecount',
        'image','is_text',
        'is_display', 
        'completecount',
        'page', 'search'
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            // return '/instructordash/course/'+this.id+'?page='+this.$route.query.page+"&search="+this.$route.query.search
            return '/companydash/course/'+this.id ;
        },
        timeFormat(){
            let hour = parseInt(this.coursetime/60) ;
            let minute = this.coursetime - hour * 60 ;
            if(hour == 0){
                return this.coursetime +'분'                
            } else if(minute == 0){
                return hour+'시간'
            } else {
                return hour + '시간 '+minute + '분' ;                
            }
        },
        nameSlice10(){
            if(this.create_name.length > 10) {
                return this.create_name.slice(0,10)+".."; 
            } else {
                return this.create_name ;
            }
            
        },


    },
    methods: {

    },
    
}
</script>