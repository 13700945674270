<template>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <tr>
        <!-- Table data -->
        <td>{{num}}</td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <div class="mb-0 ms-2">
                    <h6 class="mb-0">
                        <router-link v-if="inputIsDisplay" :to="updateLink"  class="stretched-link">{{title}}</router-link>
                        <router-link v-else :to="updateLink"  class="stretched-link text-decoration-line-through">{{title}}</router-link>
                    </h6>
                    <p v-if="inputIsDisplay" class="white-space-newline">{{ content }}</p>
                    <p v-else class="white-space-newline text-decoration-line-through">{{ content }}</p>
                </div>
            </div>
        </td>
        <td width="100">
            <div class="form-check form-switch form-check-md">
                <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsDisplay" @click="onChangeDisplay" />
                <label v-if="is_display" class="form-check-label" for="profilePublic">보임 </label>
                <label v-else class="form-check-label" for="profilePublic">숨김</label>
            </div>
        </td>

        <td width="150">
            <button type="button" @click="upSeq" :class="min? 'btn btn-outline-light disabled':'btn btn-outline-primary' "><i class="fas fa-arrow-alt-circle-up"></i></button>
            <button type="button" @click="downSeq" :class="max? 'btn btn-outline-light disabled':'btn btn-outline-danger ms-2' "><i class="fas fa-arrow-circle-down"></i></button>
        </td>

        <!-- Table data -->
        <td width="80">
            <router-link :to="updateLink" class="btn btn-success-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Edit">
                <i class="bi bi-pencil-square"></i>
            </router-link>
            <!-- <button class="btn btn-danger-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Delete">
                <i class="bi bi-trash"></i>
            </button>
            <a href="#" class="btn btn-sm btn-info-soft mb-0" data-bs-toggle="modal" data-bs-target="#viewReview">View</a> -->
        </td>
    </tr>    
</template>

<script>
export default {
    components: {},
    props: [
        'id','num','seq','min','max','is_display',
        'title','content',
    ],
    data() {
        return {
            isLoading: false,
			isApplyging: false,
            error: null,     
            dialogTitle: null,    

            inputIsDisplay : this.is_display,
            
        }
    },
    
    computed: {
        updateLink(){
            return '/admin/faqfrequent/update/'+this.id ; 
        },
    },
    methods: {
        handleError(){
            this.error = null;
        },

        upSeq(){
            this.$emit('changeUpSeq', this.id)
        },
        downSeq(){
            this.$emit('changeDownSeq', this.id)
        },
        onChangeDisplay(){
            this.$emit('changeDisplay', this.id, !this.is_display)
        }

    },
    
}
</script>