<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>


	<!-- 답변 모달  -->
	<div class="modal fade" id="displayModal" tabindex="-1" aria-labelledby="displayModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header bg-warning">
                <h1 class="modal-title fs-5" id="displayModalLabel">Notification</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <label class="form-label">Your Message *</label>
                <textarea class="form-control" rows="5" v-model.trim="inputDisplayContent"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" @click="sendDisplayNotificationForm" class="btn btn-warning" data-bs-dismiss="modal">Send Notification</button>
            </div>
            </div>
        </div>
    </div>
	
	<!-- 답변 모달  -->
	<!-- <div class="modal fade" id="activeModal" tabindex="-1" aria-labelledby="activeModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header bg-warning">
                <h1 class="modal-title fs-5" id="activeModalLabel">Notification</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <label class="form-label">Your Message *</label>
                <textarea class="form-control" rows="5" v-model.trim="inputActiveContent"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" @click="sendActiveNotificationForm" class="btn btn-warning" data-bs-dismiss="modal">Send Notification</button>
            </div>
            </div>
        </div>
    </div> -->

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

		<!-- Title -->
		<div class="row">
			<div class="col-md-6 mb-3">
				<h1 class="h3 mb-2 mb-sm-0">파트너 상태 변경</h1>
			</div>
            <div class="col-md-6 text-end">
                <router-link :to="backToLink" class="btn btn-secondary mb-0 me-2">Back</router-link>
            </div>

		</div>
				
		<div class="row g-4">

			<!-- Personal information START -->
			<div class="col-xxl-7">
                <admin-company-detail-component 
					:id="id" 
					:is_status_btn="false"
					:vue_page="'status_update'"
					@owner_id ="changeOwner_id"
					@is_display="changeIsDisplay" 
					@is_active="changeIsActive" 
					@is_out="changeIsOut" 
				/>
            </div>
			<!-- Personal information END -->

			<!-- Student status chart START -->
			<div class="col-xxl-5">
				<div class="row g-4">

					<!-- Active student START -->
					<div class="col-md-4 col-xxl-12">
						<div class="card bg-transparent border overflow-hidden">
							<!-- Card header -->
							<div class="card-header bg-light border-bottom">
								<h5 class="card-header-title mb-0">공개/비공개 여부</h5>
							</div>
							<!-- Card body -->
							<div class="card-body p-0">
								<form @submit.prevent="sendDisplayForm" class="row g-4 align-items-center mt-1">
									<!-- Radio items -->
										<div class="ms-3">
											<div class="form-check form-switch form-check-md">
												<input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsDisplay" @click="onChangeDisplay">
												<label v-if="inputIsDisplay" class="form-check-label" for="profilePublic">공개 </label>
												<label v-else class="form-check-label" for="profilePublic">비공개</label>
											</div>
										</div>
										<div class="text-center mb-3">
											<button type="submit" class="btn btn-primary mb-0">Save change</button>
											<button type="button" class="btn btn-orange mb-0 ms-2" data-bs-toggle="modal" data-bs-target="#displayModal">Notification</button>
										</div>
								</form>

							</div>
						</div>
					</div>
					<!-- Active student END -->

					<!-- Enrolled START -->
					<div class="col-md-4 col-xxl-12">
						<div class="card bg-transparent border overflow-hidden">
							<!-- Card header -->
							<div class="card-header bg-light border-bottom">
								<h5 class="card-header-title mb-0">활성화/비활성화 여부</h5>
							</div>
							<!-- Card body -->
							<div class="card-body p-0">
								<form @submit.prevent="sendActiveForm" class="row g-4 align-items-center mt-1">
									<!-- Radio items -->
										<div class="ms-3">
											<div class="form-check form-switch form-check-md">
												<input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsActive" @click="onChangeActive">
												<label v-if="inputIsActive" class="form-check-label" for="profilePublic">활성화 </label>
												<label v-else class="form-check-label" for="profilePublic">비활성화</label>
											</div>
										</div>
										<div class="text-center mb-3">
											<button type="submit" class="btn btn-primary mb-0">Save change</button>
											<!-- <button type="button" class="btn btn-orange mb-0 ms-2" data-bs-toggle="modal" data-bs-target="#activeModal">Notification</button> -->
										</div>
										
								</form>
							</div>
						</div>
					</div>
					<!-- Enrolled END -->
					<!-- 탈퇴취소 START -->
					<div class="col-md-4 col-xxl-12">
						<div class="card bg-transparent border overflow-hidden">
							<!-- Card header -->
							<div class="card-header bg-light border-bottom">
								<h5 v-if="inputIsOut" class="card-header-title mb-0">탈퇴요청</h5>
								<h5 v-else class="card-header-title mb-0">탈퇴요청 취소</h5>
							</div>
							<!-- Card body -->
							<div class="card-body p-0">
								<form @submit.prevent="sendCancelOutForm" class="row g-4 align-items-center mt-1">
									<!-- Radio items -->
										<div class="ms-3">
											<div class="form-check form-switch form-check-md">
												<input class="form-check-input" type="checkbox" role="switch" id="profilePublic3" checked="" v-model="inputIsOut" @click="onChangeOut">
												<label v-if="inputIsOut" class="form-check-label" for="profilePublic3">탈퇴요청 </label>
												<label v-else class="form-check-label" for="profilePublic3">탈퇴취소</label>
											</div>
										</div>
										<div class="text-center mb-3">
											<button v-if="inputIsOut" type="submit" class="btn btn-danger mb-0">탈퇴 요청</button>
											<button v-else type="submit" class="btn btn-outline-danger mb-0">탈퇴 취소</button>
											<!-- <button type="button" class="btn btn-orange mb-0 ms-2" data-bs-toggle="modal" data-bs-target="#activeModal">Notification</button> -->
										</div>
										
								</form>
							</div>
						</div>
					</div>
					<!-- Enrolled END -->
                    
				</div>
			</div>
			<!-- Student status chart END -->
		
		</div> <!-- Row END -->
	</div>
	<!-- Page main content END -->


<!-- Page content END -->
    
    </div>
</template>

<script>
var filename__ = "[pages > admin > company > AdminCompanyStatusUpdate.vue]" ;
import axios from 'axios';

import AdminCompanyDetailComponent from '../../../components/admin/company/AdminCompanyDetailComponent.vue' ;

export default {
    components: {
        "admin-company-detail-component": AdminCompanyDetailComponent
    },
    props: ['id','company_id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

			inputIsDisplay : null,  // AdminCompanyDetailComponent $emit 에서 $emit 가져옴 
			inputIsActive : null,  // AdminCompanyDetailComponent 에서 $emit 가져옴 
			inputIsOut : null,  // AdminCompanyDetailComponent 에서 $emit 가져옴 

			inputOwner_id : null,  // AdminCompanyDetailComponent 에서 $emit 가져옴 
			inputDisplayContent : 'OO와 같은 이유로 해당 계정을 비공개로 변경했습니다.' ,
			inputActiveContent : 'OO와 같은 이유로 해당 계정을 비활성화로 변경했습니다.' ,
     
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //일반 사용자 페이지
    },     

    computed: {
        backToLink(){
            return '/admin/company/'+this.id+'/'+this.company_id+'?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },
        outCompu(){
            return this.inputIsOut ;
        }

    },    
    created(){
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            // this.id = this.isUser() ;
            // if(this.id) {
            
            // }
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },

		onChangeDisplay(){
			this.inputIsDisplay = !this.inputIsDisplay ;
		},
		onChangeActive(){
			this.inputIsActive = !this.inputIsActive ;
		},
		onChangeOut(){
			this.inputIsOut = !this.inputIsOut ;
		},

        async changeIsDisplay(_is_display){
            this.inputIsDisplay = _is_display ;
        },
        async changeIsActive(_is_active){
            this.inputIsActive = _is_active ;
        },
        async changeIsOut(_is_out){
            this.inputIsOut = _is_out ;
        },
        // 공개여부 업데이트  
		async changeOwner_id(_owner_id){
			this.inputOwner_id = _owner_id ;
		},

        async sendDisplayForm(){
            let funcname__ ="sendDisplayForm() ";

            let dataForm = { 
                'is_display' : this.inputIsDisplay,
            } ;  

            // console.log('[AdminCompanyStatusUpdate.vue] sendDisplayForm() dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: `/api/company/display/admin/${this.id}`,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log("[AdminCompanyStatusUpdate.vue] sendDisplayForm() serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log('[AdminCompanyStatusUpdate.vue] sendDisplayForm() data:', serverData.data);

                        if(data.status_code === 201){
                                                                            
                            this.dialogTitle ='Success' ;
                            this.error = '공개상태 변경되었습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.$router.replace(this.backToLink);

                                }, 500);
                            }                        

                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="입력 실패" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error('[AdminCompanyStatusUpdate.vue] sendDisplayForm() error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        // 활성화여부 업데이트  
        async sendActiveForm(){
            let funcname__ ="sendActiveForm() ";

            let dataForm = { 
                'is_active' : this.inputIsActive,
            } ;  

            // console.log('[AdminCompanyStatusUpdate.vue] sendActiveForm() dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: `/api/company/active/admin/${this.id}`,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log("[AdminCompanyStatusUpdate.vue] sendActiveForm() serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log('[AdminCompanyStatusUpdate.vue] sendActiveForm() data:', serverData.data);

                        if(data.status_code === 201){
                                                                            
                            this.dialogTitle ='Success' ;
                            this.error = '활성화 상태 변경되었습니다.';     

                            if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$router.replace(this.backToLink);

                            }, 500);
                        }                        


                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="입력 실패" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        // 탈퇴취소 
        async sendCancelOutForm(){
            let funcname__ ="sendCancelOutForm() ";

            let dataForm = { 
                'is_out' : this.inputIsOut,
            } ;  

            // console.log('[AdminCompanyStatusUpdate.vue] sendActiveForm() dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: `/api/company/out/admin/${this.id}`,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log("[AdminCompanyStatusUpdate.vue] sendActiveForm() serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log('[AdminCompanyStatusUpdate.vue] sendActiveForm() data:', serverData.data);

                        if(data.status_code === 201){
                                                                            
                            this.dialogTitle ='Success' ;
                            this.error = '상태 변경되었습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.$router.replace(this.backToLink);

                                }, 500);
                            }                        


                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="입력 실패" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // Notifciation 발송 
		sendDisplayNotificationForm(){
			this.sendNotificationForm(this.inputDisplayContent);
		},
		sendActiveNotificationForm(){
			this.sendNotificationForm(this.inputActiveContent);
		},

        async sendNotificationForm(_content){
            let funcname__ ="sendNotificationForm() ";

            let dataForm = { 
                'receive_id': this.company_id,
                'content': _content,
            } ;  

            console.log('[AdminCompanyStatusUpdate.vue] sendNotificationForm() dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/notification/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;

                } else {
                    // console.log("[AdminCompanyStatusUpdate.vue] sendNotificationForm() serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log('[AdminCompanyStatusUpdate.vue] sendNotificationForm()  data:', data);

                        if(data.status_code === 201){
                                                        
                            this.inputContent = null ;

                            this.dialogTitle ='Success' ;
                            this.error = 'Notification 전송에 성공했습니다.';    
                            
                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    // this.$router.replace(this.backToLink);

                                }, 500);
                            }                                                

                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                                
                            this.dialogTitle ="입력 실패" ;
                            this.error = serverData.data.error_description;

                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error('[AdminCompanyStatusUpdate.vue] sendNotificationForm() error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    }
}
</script>

<style scoped>

</style>