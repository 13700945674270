<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>
        
    <!-- 삭제 모달  -->
    <div class="modal fade" id="deleteItem" tabindex="-1" aria-labelledby="deleteItemLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header bg-warning">
                <h1 class="modal-title fs-5" id="deleteItem">Delete</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p>해당 내용을 삭제하시겠습니까?</p>
                <p>삭제된 내용은 복구되지 않습니다.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
            </div>
            </div>
        </div>
    </div>    

    <!-- Edit profile START -->
    <div class="page-content-wrapper border">
        <!-- Card header -->
        <div class="card-header bg-transparent border-bottom mb-2">
            <h3 class="card-header-title mb-0">정보창고 수정하기</h3>
        </div>

        <!-- Card body START -->
        <div class="card-body">
            
            <form @submit.prevent="sendForm" class="row g-4 mt-2">

                <!-- <hr/> -->
				<div class="col-md-12" >
					<div class="form-check form-switch form-check-md">
						<input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsDisplay" @click="onChange">
						<label v-if="inputIsDisplay" class="form-check-label" for="profilePublic">외부 공개</label>
						<label v-else class="form-check-label" for="profilePublic"><span  class="badge text-bg-orange">외부 비공개</span></label>
					</div>
                    <div class="form-check form-switch form-check-md">
                        <input class="form-check-input" type="checkbox" role="switch" id="isImage"  v-model="inputIsText" @click="onChangeIsText">
                        <label v-if="!inputIsText"  class="form-check-label" for="isImage">이미지 존재</label>
                        <label v-else class="form-check-label" for="isImage">이미지 없음</label>
                    </div>
				</div>
                <div v-if="!inputIsText" class="col-md-12">
                    <admin-main-image-panel-update-component :content_id="id" />
                </div>

                <div class="col-md-12">
                    <label class="form-label">Title</label>
                    <div class="input-group">
                        <input type="text" v-model.trim="inputTitle" class="form-control" placeholder="타이틀">
                    </div>
                </div>
                <div class="col-md-12">
                    <label class="form-label">짧은 설명(이미지 대신)</label>
                    <div class="input-group">
                        <textarea class="form-control" rows="5" placeholder="이미지 없을시 대신 노출 (250자 이상)" v-model.trim="inputSubtitle"></textarea>
                    </div>
                </div>
                
                <div class="col-md-12">
                    <label class="form-label">Content (<a href="https://gist.github.com/ihoneymon/652be052a0727ad59601" target="_blank">마크다운 설명 링크</a>)</label>
                    <div class="editor">
                        <!-- <textarea class="input" :value="inputMarkdown" @input="update" @input="updateMarkdown"></textarea> -->
                        <textarea class="input" v-model.trim="inputMarkdown" ></textarea>
                        <div class="output" v-html="outputMarkdownCompu"></div>
                    </div>
                </div>

                <div class="col-md-12 mt-2"  >                             
                    <label class="position-relative me-4" for="uploadfile-1">
                        위의 Markdown와 연동된 이미지이므로, 아래 이미지를 삭제하면 실제 존재하는 이미지가 삭제됩니다. 이미지 클릭(경로 복사) 
                    </label>                    
                </div>
                
                <!-- <admin-content-image-panel-update-component :content_id="id" /> -->
                <content_id-fileupload-panel-component 
                    :is-multi-file='true'
                    :file-kind="'image'"
                    :category="'blog'" 
                    :sub-category="'description'" 
                    :content_id="id" 
                />

                <hr/>
                <content_id-fileupload-panel-component 
                    :is-multi-file='true'
                    :file-kind="'file'"
                    :category="'blog'" 
                    :sub-category="'description'" 
                    :content_id="id" 
                />

                <hr/>
                <!-- Save button -->
                <!-- <div class="d-sm-flex justify-content-end">
                    <button type="button" @click="cancel" class="btn btn-secondary mb-0">Cancel</button>
                    <button type="submit" class="btn btn-primary mb-0 ms-2">Update</button>
                </div>                -->

                <div class="row align-items-center justify-content-between mt-3">
                    <div class="col-md-6">
                        <button type="button" class="btn btn-danger mb-0" data-bs-toggle="modal" data-bs-target="#deleteItem">Delete</button>
                    </div>
                    <div class="col-md-6 text-end">
                        <button type="button" @click="cancel" class="btn btn-secondary mb-0">Cancel</button>
                        <button type="submit" class="btn btn-primary mb-0 ms-2">Update</button>
                    </div>
                </div>


            </form>
        </div>
        <!-- Card body END -->
    </div>
    <!-- Edit profile END -->



<!-- Page content END -->
    
</div>
    <!-- <div v-html="description"></div> -->
</template>

<script>
//  file__ formData__  ifelse__
var filename__= "[pages > admin > blog > AdminBlogUpdate.vue] "

import axios from 'axios';
// import { ref } from 'vue' ;

// markdown 참고자료 : 
// https://vuejs.org/examples/#markdown
// https://codepen.io/immarina/pen/oNxXzyB
import { marked } from 'marked';         // yarn add marked  https://marked.js.org/

import AdminMainImagePanelUpdateComponent from '../../../components/admin/content/AdminMainImagePanelUpdateComponent.vue' ;

import ContentIdFileUploadPanelComponent from '../../../components/content/ContentIdFileUploadPanelComponent.vue';

export default {
    components: {
        'admin-main-image-panel-update-component': AdminMainImagePanelUpdateComponent,
        'content_id-fileupload-panel-component': ContentIdFileUploadPanelComponent,
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
			isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputTitle: null,
            inputSubtitle : '', 
            inputIsDisplay : false,

            inputIsText : null,

            inputDiscription: null, 
            inputDescriptionCategory :'markdown',

            inputMarkdown : '',

            // inputPhoto: null, 
            
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    mounted() {
    },
    setup(){
        // const inputPhoto2 = ref('') ;
        // return { inputPhoto2 }
    },        
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getItem();
            return ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },   
     
    computed: {
        backToLink(){
            return '/admin/blog/'+this.id ; 
        },		
        listLink(){
            return '/admin/blogs' ; 
        },		
        outputMarkdownCompu(){
            return marked(this.inputMarkdown);
        },	

    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },

        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
            return this.$store.getters['auth/isAdmin'] ;
        },
        onChange(){
            this.inputIsDisplay = !this.inputIsDisplay ;
        },
        onChangeIsText(){
            this.inputIsText = !this.inputIsText ;
        },

        //Cancel 
        async cancel(){
            this.$router.replace(this.backToLink);  

        },

        // 정보 가져오기 
        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/blog/admin/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){
                    
                    console.log(filename__+''+funcname__+' response:', data.response);

                    const blog = data.response

                    this.inputTitle = blog.title ;
                    this.inputSubtitle = blog.subtitle ;
                    this.inputMarkdown = blog.description  ;

                    this.inputIsDisplay = blog.is_display ;
                    this.inputIsText = blog.is_fronttext ;

                    this.inputDate = blog.date ;

                    this.inputCreate_name = blog.create_name ;
                    this.inputCreate_photo = blog.create_photo ? blog.create_photo : false  ;


                    // this.inputPhoto = blog.main_photo ? blog.main_photo.main_photo_path : false ;
                    // console.log(filename__+''+funcname__+' inputPhoto:', this.inputPhoto);
                           
                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;
                }
            }
            this.isLoading = false ;   
        },

        // 정보 업데이트  
        async sendForm(){
            let funcname__ ="sendForm() ";
            
            let dataForm = { 
                'title' : this.inputTitle,
                'subtitle' : this.inputSubtitle,
                'description': this.inputMarkdown,
                'description_category': this.inputDescriptionCategory,
                'is_display':this.inputIsDisplay,
                'is_fronttext': this.inputIsText,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/content/blog/update/'+this.id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+' data:', data);

                        if(data.status_code === 201){
                                                                            
                            this.dialogTitle ='Success' ;
                            this.error = '수정에 성공했습니다.';   
                            
                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.$router.replace(this.backToLink);

                                }, 500);
                            }                        
                            

                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // 정보 삭제  
        async removeForm(){
            let funcname__ ="removeForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/content/blog/delete/'+this.id,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                    
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '삭제 성공했습니다.';    

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$router.replace(this.listLink);

                            }, 700);
                        }                        


                    } else {
                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';
                    }

                }                    

            } catch(error){
                // console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="실패" ;
                this.error = error; 
            }
            this.isLoading = false ;

        },

        
    }
}
</script>

<style scoped>
.editor {
  height: 150vh;
  display: flex;
}

.input,
.output {
  overflow: auto;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.input {
  border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 20px;
}

code {
  color: #f66;
}



.white-space-newline {

/* white-space: normal;
white-space: nowrap;
white-space: pre;
white-space: pre-wrap;
white-space: pre-line; */
white-space: break-spaces;

/* Global values */
/* white-space: inherit;
white-space: initial;
white-space: unset; */

}
</style>
