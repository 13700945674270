<template>
    <div>

        <main>
	
    <!-- =======================
    Page Banner START -->
    <student-dashboard-top :title="'Edit Profile'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
            <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
                <p>{{ error }}</p>
            </base-dialog>

            <base-dialog :show="isLoading" title="Loading..." fixed>
                <p>Loading...</p>
                <base-spinner></base-spinner>
            </base-dialog>
                        
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <student-dashboard-left :menu="'update'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Edit profile START -->
                    <div class="card bg-transparent border rounded-3">
                        <!-- Card header -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="card-header-title mb-0">Edit Profile</h3>
                        </div>
                        <!-- Card body START -->
                        <div class="card-body">
                            <form @submit.prevent="sendForm" class="row g-4">
                                <!-- Name -->
                                <div class="col-md-6">
                                    <label class="form-label">Real Name</label>
                                    <div class="input-group">
                                        <input type="text" v-model.trim="inputName" class="form-control" placeholder="이름을 기재해주세요">
                                    </div>
                                </div>
    
                                <!-- Username -->
                                <div class="col-md-6">
                                    <label class="form-label">Displayname</label>
                                    <div class="input-group">
                                        <!-- <span class="input-group-text">Edu-Coop</span> -->
                                        <input type="text" v-model.trim="inputDisplayname" class="form-control" placeholder="노출용 이름을 기재헤주세요">
                                    </div>
                                </div>
    
                                <!-- Email id -->
                                <!-- <div class="col-md-6">
                                    <label class="form-label">Email id</label>
                                    <input class="form-control" type="email" value="example@gmail.com"  placeholder="Email">
                                </div> -->
    
                                <!-- Phone number -->
                                <div class="col-md-6">
                                    <label class="form-label">Phone number</label>
                                    <input type="text" v-model.trim="inputPhone" class="form-control" placeholder="필요시 전화번호를 기재해주세요">
                                </div>
    
                                <!-- Address -->
                                <!-- <div class="col-md-6">
                                    <label class="form-label">Address</label>
                                    <input class="form-control" type="text" v-model.trim="inputAddr" placeholder="필요시 주소를 기재해주세요.">
                                </div> -->
                                
                                <!-- About me -->
                                <div class="col-12">
                                    <label class="form-label">About me</label>
                                    <textarea class="form-control" rows="3" v-model.trim="inputDescription" placeholder="필요시 간단한 소개를 부탁합니다."></textarea>
                                    <!-- <div class="form-text">간단하게 자기소개를 기재해주세요.</div>  -->
                                </div>
    
                                <!-- Education -->
                                <!-- <div class="col-12">
                                    <label class="form-label">Education</label>
                                    <input class="form-control mb-2" type="text" value="Bachelor in Computer Graphics">
                                    <input class="form-control mb-2" type="text" value="Masters in Computer Graphics">
                                    <button class="btn btn-sm btn-light mb-0"><i class="bi bi-plus me-1"></i>Add more</button>
                                </div> -->
    
                                <!-- Save button -->
                                <div class="d-sm-flex justify-content-end">
                                    <button type="submit" class="btn btn-primary mb-0">Save changes</button>
                                </div>
                            </form>
                        </div>
                        <!-- Card body END -->
                    </div>
                    <!-- Edit profile END -->
                    
                    <div class="row g-4 mt-3">
                        <!-- <div class="col-lg-6">
                            <div class="card bg-transparent border rounded-3">
                                <div class="card-header bg-transparent border-bottom">
                                    <h5 class="card-header-title mb-0">Linked account</h5>
                                </div>
                                <div class="card-body pb-0">
                                    <div class="position-relative mb-4 d-sm-flex bg-success bg-opacity-10 border border-success p-3 rounded">
                                        <h2 class="fs-1 mb-0 me-3"><i class="fab fa-google text-google-icon"></i></h2>
                                        <div>
                                            <div class="position-absolute top-0 start-100 translate-middle bg-white rounded-circle lh-1 h-20px">
                                                <i class="bi bi-check-circle-fill text-success fs-5"></i>
                                            </div>
                                                <h6 class="mb-1">Google</h6>
                                                <p class="mb-1 small">You are successfully connected to your Google account</p>
                                                <button type="button" class="btn btn-sm btn-danger mb-0">Invoke</button>
                                                <a href="#" class="btn btn-sm btn-link text-body mb-0">Learn more</a>
                                        </div>
                                    </div>
    
                                    <div class="mb-4 d-sm-flex border p-3 rounded">
                                        <h2 class="fs-1 mb-0 me-3"><i class="fab fa-linkedin-in text-linkedin"></i></h2>
                                        <div>
                                            <h6 class="mb-1">Linkedin</h6>
                                            <p class="mb-1 small">Connect with Linkedin account for a personalized experience</p>
                                            <button type="button" class="btn btn-sm btn-primary mb-0">Connect Linkedin</button>
                                            <a href="#" class="btn btn-sm btn-link text-body mb-0">Learn more</a>
                                        </div>
                                    </div>
    
                                    <div class="mb-4 d-sm-flex border p-3 rounded">
                                        <h2 class="fs-1 mb-0 me-3"><i class="fab fa-facebook text-facebook"></i></h2>
                                        <div>
                                            <h6 class="mb-1">Facebook</h6>
                                            <p class="mb-1 small">Connect with Facebook account for a personalized experience</p>
                                            <button type="button" class="btn btn-sm btn-primary mb-0">Connect Facebook</button>
                                            <a href="#" class="btn btn-sm btn-link text-body mb-0">Learn more</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> -->
    
                        <!-- <div class="col-lg-6">
                            <div class="card bg-transparent border rounded-3">
                                <div class="card-header bg-transparent border-bottom">
                                    <h5 class="card-header-title mb-0">Social media profile</h5>
                                </div>
                                <div class="card-body">
                                    <div class="mb-3">
                                        <label class="form-label"><i class="fab fa-facebook text-facebook me-2"></i>Enter facebook username</label>
                                        <input class="form-control" type="text" value="loristev" placeholder="Enter username">
                                    </div>
                                    
                                    <div class="mb-3">
                                        <label class="form-label"><i class="bi bi-twitter text-twitter me-2"></i>Enter twitter username</label>
                                        <input class="form-control" type="text" value="loristev" placeholder="Enter username">
                                    </div>
    
                                    <div class="mb-3">
                                        <label class="form-label"><i class="fab fa-instagram text-instagram-gradient me-2"></i>Enter instagram username</label>
                                        <input class="form-control" type="text" value="loristev" placeholder="Enter username">
                                    </div>
    
                                    <div class="mb-3">
                                        <label class="form-label"><i class="fab fa-youtube text-youtube me-2"></i>Add your youtube profile URL</label>
                                        <input class="form-control" type="text" value="https://www.youtube.com/in/Eduport-05620abc" placeholder="Enter username">
                                    </div>
    
                                    <div class="d-flex justify-content-end mt-4">
                                        <button type="button" class="btn btn-primary mb-0">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div> -->
    
                        <!-- <div class="col-lg-6">
                            <div class="card bg-transparent border rounded-3">
                                <div class="card-header bg-transparent border-bottom">
                                    <h5 class="card-header-title mb-0">Update email</h5>
                                </div>
                                <div class="card-body">
                                    <p>Your current email address is <span class="text-primary">example@gmail.com</span></p>
                                    <form>
                                        <label class="form-label">Enter your new email id</label>
                                        <input class="form-control" type="email" placeholder="Enter new email">
                                        <div class="d-flex justify-content-end mt-4">
                                            <button type="button" class="btn btn-primary mb-0">Update email</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> -->
    
                    </div>
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pagess > studentdash > StudentDashProfileUpdate.vue]" ;
import axios from 'axios';

import StudentDashboardTop from '../../components/studentdash/StudentDashboardTop.vue';
import StudentDashboardLeft from '../../components/studentdash/StudentDashboardLeft.vue';

export default {
    components: {
        "student-dashboard-top": StudentDashboardTop,
        "student-dashboard-left": StudentDashboardLeft,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,       
            
            inputId : null,
            
            inputEmail: null, 
            inputName: null,
            inputNameUpdate: null,
            inputDisplayname: null, 
            inputDisplaynameUpdate: null, 
            inputDescription: null,
            inputDescriptionUpdate: null,
            inputPhone: null, 
            inputPhoneUpdate: null, 
            // inputAddr : null, 
            // inputAddrUpdate : null, 

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.id = this.isUser() ;
            if(this.id) {
                this.getMember();
            }
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },    
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        email : function(){
            return this.$store.getters['auth/email'] ;
        },
        name(){
            return this.inputNameUpdate ;
        },
        displayname(){
            return this.inputDisplaynameUpdate ;
        },
        phone(){
            return this.inputPhoneUpdate ;
        },
        // addr(){
        //     return this.inputAddrUpdate ; 
        // },
        description(){
            return this.inputDescriptionUpdate ; 
        },
    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/signin');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            // console.log("[StudentDashProfieleUpdate.vue] isAuth():", this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            // console.log("[StudentDashProfieleUpdate.vue] isUser():", this.$store.getters['auth/userId']);
            return this.$store.getters['auth/userId'] ;
        },
        async getMember(){
            let funcname__ ="getMember() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 
            
            
            let serverData = await axios({
                method: 'get',
                url: '/api/user/myaccount',
                headers: {
                    'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined" || serverData === undefined ||  serverData.status !== 200){
                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" && data.status_code == 200){
                    // console.log(filename__+''+funcname__+' response:', data.response);
                    
                    const profile = data.response ;

                    this.inputId = profile.id ;
                    this.inputEmail = profile.email ;

                    this.inputName = profile.name ? profile.name : '' ;
                    this.inputNameUpdate = profile.name ? profile.name : '' ;
                    this.inputDisplayname = profile.displayname ? profile.displayname : '' ;
                    this.inputDisplaynameUpdate = profile.displayname ? profile.displayname : '' ;
                    this.inputDescription = profile.description ? profile.description : '' ;
                    this.inputDescriptionUpdate = profile.description ? profile.description : '' ;
                    this.inputPhone = profile.phone ? profile.phone : '' ;
                    this.inputPhoneUpdate = profile.phone ? profile.phone : '' ;
                    // this.inputAddr  = profile.addr ? profile.addr : '' ;
                    // this.inputAddrUpdate  = profile.addr ? profile.addr : '' ;
                    
                    // console.log(filename__+''+funcname__+' inputId:', this.inputId);

                } else if( typeof data !== "undefined" && data.status_code == 401){
                    this.dialogTitle ="Error" ;                    
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [401] ";  // this.error = err.msg || 'Failed to authenticate';                

                } else {
                    console.log(filename__+''+funcname__+' err.msg:', serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  // this.error = err.msg || 'Failed to authenticate';                
                    // alert('error')
                }
            }


            this.isLoading = false ;            
        },

        // 프로필 정보 업데이트  
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'name': this.inputName, 
                'displayname': this.inputDisplayname,
                'description':this.inputDescription,
                'phone': this.inputPhone,
                // 'addr' : this.inputAddr,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: `/api/user/update_user/${this.inputId}`,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }
      

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+' data:', serverData.data);

                        if(data.status_code === 201){
                                                                            
                            this.inputNameUpdate = this.inputName ;
                            this.inputDisplaynameUpdate = this.inputDisplayname ;
                            this.inputDescriptionUpdate = this.inputDescription ;
                            this.inputPhoneUpdate = this.inputPhone ;
                            // this.inputAddrUpdate = this.inputAddr ;

                            this.dialogTitle ='Success' ;
                            this.error = '성공했습니다.';     

                            const user_id = this.$store.getters['auth/userId'] ;

                            //로그인한 사람과 수정하는 정보가 같으면, 최상단 정보 업데이트 
                            if(this.id == user_id) {
                                // console.log(filename__+''+funcname__+' name:', this.inputNameUpdate, this.inputDisplaynameUpdate);
                                const name = this.inputDisplaynameUpdate ? 
                                                    this.inputDisplaynameUpdate : this.inputNameUpdate ? 
                                                            this.inputNameUpdate : this.inputEmail.split('@')[0] ; //이메일 앞부분
                                const actionPayload = {
                                    name: name
                                };
                                this.$store.dispatch('auth/Name', actionPayload);  //  /store/modules/auth/actions.js + mutations.js 

                            }


                        } else {
                            console.log(filename__+''+funcname__+' err.msg:', serverData.data.error_description);
                            this.dialogTitle ="입력 실패" ;
                            this.error = serverData.data.error_description;

                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    }
}
</script>

<style scoped>

</style>