<template>
<div>
    <!-- Title -->
    <h5 class="mb-0">안녕하세요?</h5>
    <h1 class="mt-1 mb-1">{{companyinfo.name}}</h1>
    <h6>{{companyinfo.major}}</h6>
    <!-- Content -->
    <p class="mt-4 mb-4 white-space-newline" v-html="companyinfo.description"></p>
    <!-- Personal info -->
    <ul class="list-group list-group-borderless">
        <li class="list-group-item px-0">
            <span class="h6 fw-light"><i class="fas fa-fw fa-map-marker-alt text-primary me-1 me-sm-3"></i>주소:</span>
            <span>{{companyinfo.addr}}</span>
        </li>
        <li class="list-group-item px-0">
            <span class="h6 fw-light"><i class="fas fa-fw fa-envelope text-primary me-1 me-sm-3"></i>이메일:</span>
            <span>{{companyinfo.email}}</span>
        </li>
        <li class="list-group-item px-0">
            <span class="h6 fw-light"><i class="fas fa-fw fa-headphones text-primary me-1 me-sm-3"></i>전화번호:</span>
            <span>{{companyinfo.phone}}</span>
        </li>
        <li class="list-group-item px-0">
            <span class="h6 fw-light"><i class="fas fa-fw fa-globe text-primary me-1 me-sm-3"></i>홈페이지:</span>
            <span><a :href="companyinfo.web" target="_blank">{{companyinfo.web}}</a></span>
        </li>
    </ul>

</div>    
</template>

<script>

// import instructorDetailSkillListComponent from './instructorDetailSkillListComponent.vue';

export default {
    components: {
        // "instructor-detail-skill-list-component": instructorDetailSkillListComponent,
    },
    props: [
        'companyinfo'
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
        }
    },
    created(){
        
    },    
    computed: {
    },
    methods: {
        handleError(){
            this.error = null;
        },

    },
    
}
</script>
<style scoped>

</style> 