<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="col-md-12" v-if="itemList" > 
        <label class="form-label" >첨부파일</label> 
        <ul>         
            <admin-content-file-list-detail-component 
                v-for="item in itemList"
                    :key="item.id"
                    :id="item.id"
                    :img_id="item.img_id"
                    :file="item.file"
                />
        </ul>
    </div>

</div>    
</template>
    
<script>
var filename__= "[components > admin > content > AdminConentFilePanelDetailComponent.vue] "
import axios from 'axios';

import AdminContentFileListDetailComponent from './AdminContentFileListDetailComponent.vue';

export default {
    components: {
        "admin-content-file-list-detail-component" : AdminContentFileListDetailComponent,
    },
    props: ['content_id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,      

            inputCategory : 'blog',
            inputSubCategory : 'description',
            inputKind : 'file',

            inputFile : null ,
            inputFileUpdate : null,  
            inputFileTmp : null, 

            itemList: [],
            
        }
    },
    beforeMount() {
    },     

    computed: {
        fileTmpCompu(){
            // console.log("[AdminBlogCreate.vue] photo ()inputFileTmp :", this.inputFileTmp);
            return this.inputFileTmp ;
        },
    },    
    created(){
        this.getList()
    },
    methods: {
        handleError(){
            this.error = null;

        },

        refreshForm(){
            this.getList();
        },

        // content image 
        async getList(){
            let funcname__ ="getList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/file/contentid/all/'+this.content_id+'/blog/content/file',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                        
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        img_id: item.img_id, 
                        file:item.photo,
                    });
                }

                console.log(filename__+''+funcname__+" list:", list);
                this.itemList = list;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  
            }

            this.isLoading = false ;            
        },

    }
}
</script>
    
<style scoped>

</style>