<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

	<!-- Page main content START -->
	<div class="page-content-wrapper-match border">

		<!-- Title -->
		<div class="row g-3 align-items-center justify-content-between">
			<div class="col-md-9">
				<h1 class="h3 mb-2 mb-sm-0">Contact Us</h1>
			</div>
            <div class="col-md-3 text-end">
                <router-link :to="backToLink" class="btn btn-sm btn-secondary mb-0 me-2">목록</router-link>
                <router-link :to="statusLink" class="btn btn-sm btn-success mb-0 me-2">상태변경</router-link>
                <router-link :to="replyLink" class="btn btn-sm btn-primary mb-0">답변하기</router-link>
            </div>
		</div>

        <admin-contactus-detail-component :id="id" />
	</div>

    <div>
        <admin-contactus-reply-panel-component :id ="id" :parent_id ="parent_id" />
    </div>
</div>
</template>

<script>
// var filename__= "[pages > admin > faq > AdminContactusDetail.vue] "
// import axios from 'axios';

import AdminContactusDetailComponent from '../../../components/admin/faq/AdminContactusDetailComponent.vue';
import AdminContactusReplyPanelComponent from '../../../components/admin/faq/AdminContactusReplyPanelComponent.vue';

export default {
    components: {
        "admin-contactus-detail-component" : AdminContactusDetailComponent,
        "admin-contactus-reply-panel-component" : AdminContactusReplyPanelComponent,
    },
    props: ['id','parent_id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,      
            
            itemList : [],
            
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
        backToLink(){
            return '/admin/contactuses?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },
        statusLink(){
            return '/admin/contactus/status/update/'+this.id+'?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },
        replyLink(){
            return '/admin/contactus/reply/create/'+this.id+'?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },

    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			// this.getReplyList();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        
    }
}
</script>

<style scoped>

</style>