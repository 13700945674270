import axios from "axios";

export default {
    // isMessageCategory(state){
    //     return !!state.messagecategory ;
    // },
    async LoadSetting(context){

        const res = await axios( {
            method: 'get', 
            url:'/api/setting/all/user/setting',
        });
        // console.log('[store/modules/setting/actions.js] LoadSetting() res:', res);

        const responseData = await res.data ;
        // console.log('[store/modules/setting/actions.js] LoadSetting() responseData:', responseData);

        if(responseData.status_code === 200){
            // console.log('[store/modules/setting/actions.js] LoadSetting() response:', responseData.response);

            let settingList = responseData.response ;
            let setting= {} ;

            setting.name = ''
            setting.copyright = ''
            setting.description = ''
            setting.email = ''
            setting.phone = ''
            setting.worktime = ''
            setting.contactaddr = ''


            for(let item of settingList) {
                // console.log('[store/modules/setting/actions.js] LoadSetting() item.title:', item.title);

                if(item.category ==='setting' && item.title === 'sitename' && item.is_active === true ) {
                    setting.name = item.description
                
                } else if(item.category ==='setting' && item.title === 'copyright' && item.is_active === true ) {
                    setting.copyright = item.description

                } else if(item.category ==='setting' && item.title === 'description' && item.is_active === true ) {
                    setting.description = item.description

                } else if(item.category ==='setting' && item.title === 'email' && item.is_active === true ) {
                    setting.email = item.description

                } else if(item.category ==='setting' && item.title === 'tel' && item.is_active === true ) {
                    setting.phone = item.description

                } else if(item.category ==='setting' && item.title === 'worktime' && item.is_active === true ) {
                    setting.worktime = item.description

                } else if(item.category ==='setting' && item.title === 'supportemail' && item.is_active === true ) {
                    setting.supportemail = item.description

                } else if(item.category ==='setting' && item.title === 'address' && item.is_active === true ) {
                    setting.contactaddr = item.description                
                } 
                
                // 회원가입시 자동으로 로그인 활성화 여부  
                if(item.category ==='register' && item.title === 'approve'  ) {
                    setting.is_active = item.is_active

                }
            }

            context.commit('setLoading', {
                
                is_loading: true,
                
                name : setting.name,
                copyright: setting.copyright,
                email : setting.email,
                description : setting.description,
                phone : setting.phone,
                worktime : setting.worktime,
                supportemail : setting.supportemail,
                contactaddr : setting.contactaddr,
    
                is_active : setting.is_active,
                    
            });  // store/modules/setting/mutations.js

        } else {
            console.log('[store/modules/setting/actions.js] LoadSetting() error:', responseData.msg);

            // const error = new Error(responseData.msg || 'Failed to authenticate.');
            const error = new Error(responseData.msg);
            console.error("[store/modules/setting/actions.js] GetSetting() : \n", error);

            throw error ;            

        }
        
    },   
}