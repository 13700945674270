<template>
    <div>
        <section class="pt-0">
        <!-- Main banner background image -->
            <div class="container-fluid px-0">
                <div class="bg-blue h-100px h-md-200px rounded-0" style="background:url(/assets/images/pattern/04.png) no-repeat center center; background-size:cover;">
                    <div class="row">
                        <div class="col-12 text-center mt-6">
                            <h1 class="text-white">{{title}}</h1>
                            <!-- <p class="text-white mb-0">Read our <a href="#" class="text-white"><u>"Before you create a course"</u></a> article before submitting!</p> -->
                            
                        </div>
                    </div>            
            </div>
            </div>
            <!-- </section>
            <section class="pt-0"> -->
            <div class="container mt-n4">
                <div class="row">
                    <!-- Profile banner START -->
                    <div class="col-12">
                        <div class="card bg-transparent card-body p-0">
                            <div class="row d-flex justify-content-between">
                                <!-- Avatar -->
                                <div class="col-auto mt-4 mt-md-0">
                                    <div class="avatar avatar-xxl mt-n3">
                                        <img v-if="photo"  class="avatar-img rounded-circle border border-white border-3 shadow" :src="photo" alt="">
                                        <img v-else class="avatar-img rounded-circle border border-white border-1 shadow" src="/assets/images/avatar/user.png" alt="">
                                    </div>
                                </div>
                                <!-- Profile info -->
                                <div class="col d-md-flex justify-content-between align-items-center mt-4">
                                    <div>
                                        <h1 class="my-1 fs-4">
                                            {{name}} <i class="bi bi-patch-check-fill text-info small"></i>
                                        </h1>
                                        <!-- <ul class="list-inline mb-0">
                                            <li class="list-inline-item h6 fw-light me-3 mb-1 mb-sm-0"><i class="fas fa-star text-warning me-2"></i>4.5/5.0</li>
                                            <li class="list-inline-item h6 fw-light me-3 mb-1 mb-sm-0"><i class="fas fa-user-graduate text-orange me-2"></i>12k Enrolled Students</li>
                                            <li class="list-inline-item h6 fw-light me-3 mb-1 mb-sm-0"><i class="fas fa-book text-purple me-2"></i>25 Courses</li>
                                        </ul> -->

                                    </div>
                                    <!-- Button -->
                                    <div class="d-flex align-items-center mt-2 mt-md-0">
                                        <!-- <router-link to="/instructordash/course/create" class="btn btn-success mb-0">Create a course</router-link> -->
                                        <companydash-notification-panel-component />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Profile banner END -->
        
                        <!-- Advanced filter responsive toggler START -->
                        <!-- Divider -->
                        <hr class="d-xl-none">
                        <div class="col-12 col-xl-3 d-flex justify-content-between align-items-center">
                            <a class="h6 mb-0 fw-bold d-xl-none" href="#">Menu</a>
                            <button class="btn btn-primary d-xl-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
                                <i class="fas fa-sliders-h"></i>
                            </button>
                        </div>
                        <!-- Advanced filter responsive toggler END -->
                    </div>
                </div>
            </div>
    </section>

    </div>
</template>

<script>
import CompanyDashNotificationPanelComponent from '../notification/CompanyDashNotificationPanelComponent.vue'

export default {
    components: {
        "companydash-notification-panel-component" : CompanyDashNotificationPanelComponent,
    },
    props: ['title'],
    data() {
        return {
            
        }
    },
    computed: {
      isLoggedIn(){
        return this.$store.getters['auth/isAuthenticated'];
      },     
      name(){
        // console.log("[AdminTopHeader.vue] computed : name():", this.$store.getters['auth/name'])
        return this.$store.getters['auth/name']; 
      },
      email(){
        return this.$store.getters['auth/email']; 
      },
      photo(){
        // console.log("[TheHeader.vue] computed : photo():", this.$store.getters['auth/photo'])
        return this.$store.getters['auth/photo'];
      },

    },        
}
</script>

    