<template>
<div>

    <div data-sticky data-margin-top="80" data-sticky-for="768">
        <div class="row g-4">
            <div class="col-md-6 col-xl-12">
                <!-- Course info START -->
                <div class="card card-body border p-4">
                    <!-- Price and share button -->
                    <div class="d-flex justify-content-between align-items-center">
                    </div>
                        
                    <!-- Buttons -->
                    <div class="mt-0 d-grid">
                        <!-- <a href="#" class="btn btn-outline-primary">Add to cart</a> -->
                        <router-link :to="backToLink" class="btn btn-secondary">목록보기</router-link>

                        <!-- 로그인 안됐으면 수강신청 클릭 안됨  -->
                        <router-link  :to='updateLink'  class="btn btn-primary">편집하기</router-link>
                    </div>
                    <!-- Divider -->
                    <hr>

                    <!-- Title -->
                    <h5 class="mb-3">Thisincludes</h5>
                    <ul class="list-group list-group-borderless border-0">
                        <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-signal text-primary"></i>정보수준</span>
                            <span v-if="blog.level ===0"> All levels</span>
                            <span v-if="blog.level ===1"> Beginner</span>
                            <span v-if="blog.level ===2"> Intermediate</span>
                            <span v-if="blog.level ===3"> Advanced</span>
                        </li>
                    </ul>
                    <!-- Divider -->
                    <hr>

                    <!-- Instructor info -->
                    <div class="d-sm-flex align-items-center">
                        <!-- Avatar image -->
                        <div class="avatar avatar-lg">
                            <img v-if="blog.create_photo" class="avatar-img rounded-circle" :src="blog.create_photo" alt="avatar">
                            <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/05.jpg" alt="avatar">
                        </div>
                        <div class="ms-sm-3 mt-2 mt-sm-0">
                            <h5 class="mb-0"><a href="#">{{blog.create_name}}</a></h5>
                        </div>
                    </div>

                </div>
                <!-- Course info END -->
            </div>

        </div><!-- Row End -->
    </div>	

</div>
</template>

<script>
// var filename__ ="[components > courseedit > EditBlogDetailRightTopComponent.vue] " ;

export default {
    components: {},
    props: [
        'id','blog', 'path','page'
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  

            inputCompany_id : this.blog.company_id,
        }
    },
    created(){
    },
    computed: {
        backToLink(){
            return '/editcourses?page='+this.$route.query.page
            
        },
        updateLink(){
            return '/editblog/update/'+this.id ;
            
        },

    },
    methods: {
        handleError(){
            this.error = null;
        },

    },
    
}
</script>