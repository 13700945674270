import axios from "axios";

var filename__="[store > modules > courserecommend > action.js]"
export default {
    async Loading(context){
        let funcname__ = "Load() " ;

        const res = await axios( {
            method: 'get', 
            url:'/api/course/recommendcategory/all/topmenu/user',
        });
        // console.log(filename__+funcname__+ res:', res);

        const responseData = await res.data ;
        // console.log(filename__+funcname__+' responseData:', responseData);

        if(responseData.code === 200){
            // console.log(filename__+funcname__+ 'response:', responseData.response);

            let category_list = responseData.response ;
            
            context.commit('setLoad', {
                
                is_load: true,
                
                recommendcategorylist : category_list,
                    
            });  // store/modules/setting/mutations.js

        } else {
            console.log(filename__+funcname__+ 'error:', responseData.msg);

            // const error = new Error(responseData.msg || 'Failed to authenticate.');
            const error = new Error(responseData.msg);
            console.log(filename__+funcname__+ ': \n', error);

            throw error ;            

        }
        
    },   
}