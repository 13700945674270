<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>
    

    <div data-sticky data-margin-top="80" data-sticky-for="768">
        <div class="row g-4">
            <div class="col-md-6 col-xl-12">
                <!-- Course info START -->
                <div class="card card-body border p-4">
                    <!-- Price and share button -->
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- Price -->
                        <!-- <h3 class="fw-bold mb-0 me-2">$295.55</h3> -->
                        <!-- Share button with dropdown -->
                        <!-- <div class="dropdown">
                            <a href="#" class="btn btn-sm btn-light rounded mb-0 small" role="button" id="dropdownShare" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fas fa-fw fa-share-alt"></i>
                            </a>
                            <ul class="dropdown-menu dropdown-w-sm dropdown-menu-end min-w-auto shadow rounded" aria-labelledby="dropdownShare">
                                <li><a  class="dropdown-item" href="#"><i class="fab fa-twitter-square me-2"></i>Twitter</a></li>
                                <li><a class="dropdown-item" href="#"><i class="fab fa-facebook-square me-2"></i>Facebook</a></li>
                                <li><a class="dropdown-item" href="#"><i class="fab fa-linkedin me-2"></i>LinkedIn</a></li>
                                <li><a class="dropdown-item" href="#"><i class="fas fa-copy me-2"></i>Copy link</a></li>
                            </ul>
                        </div> -->
                    </div>
                        
                    <!-- Buttons -->
                    <div class="mt-0 d-grid">
                        <!-- <a href="#" class="btn btn-outline-primary">Add to cart</a> -->
                        <router-link :to="backToLink" class="btn btn-secondary">목록이동</router-link>
                    </div>
                    <!-- Divider -->
                    <hr>

                    <!-- Title -->
                    <h5 class="mb-3">This includes</h5>
                    <ul class="list-group list-group-borderless border-0">
                        <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-signal text-primary"></i>정보수준</span>
                            <span v-if="blog.level ===0"> All levels</span>
                            <span v-if="blog.level ===1"> Beginner</span>
                            <span v-if="blog.level ===2"> Intermediate</span>
                            <span v-if="blog.level ===3"> Advanced</span>
                        </li>
                    </ul>
                    <!-- Divider -->
                    <hr>

                    <!-- company info -->
                    <!-- <div class="d-sm-flex align-items-center">
                        <div class="avatar avatar-lg">
                            <img v-if="course.create_photo" class="avatar-img rounded-circle" :src="course.create_photo" alt="avatar">
                            <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/05.jpg" alt="avatar">
                        </div>
                        <div class="ms-sm-3 mt-2 mt-sm-0">
                            <h5 class="mb-0"><a href="#">{{course.create_name}}</a></h5>
                        </div>
                    </div> -->
                    <div class="d-sm-flex align-items-center">
                        <div class="avatar avatar-lg">
                            <img v-if="blog.company_photo" class="avatar-img rounded-circle" :src="blog.company_photo" alt="avatar">
                            <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user.png" alt="avatar">
                        </div>
                        <div class="ms-sm-3 mt-2 mt-sm-0">
                            <h5 class="mb-0">
                                <router-link :to="companyLink">{{blog.company_name}}</router-link>
                            </h5>
                            <p class="mb-0 small">{{blog.company_web}}</p>
                        </div>
                    </div>

                </div>
                <!-- Course info END -->
            </div>

        </div><!-- Row End -->
    </div>	

</div>
</template>

<script>
// modal__ teleport__ removeForm__
var filename__ ="[components > blog > BlogDetailRightTopComponent.vue] " ;

export default {
    components: {},
    props: [
        'id','blog', 'path',
        'is_exist', 'studentcourse_id',
        'is_wishlist',
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  

        }
    },
    created(){
    },
    computed: {
        backToLink(){
            if(this.path === 'undefined' || this.path === undefined || this.path === null || this.path ==='/undefined?page=undefined&search=undefined'){
                // 외부에서 유입됐을 경우 
                return '/totalblogs' 
            } else if(this.path === 'totalblogs'){
                return '/totalblogs' ;
            } else {
                return this.path
            }
            
        },
        companyLink(){
            return '/company/'+this.blog.company_id+'/'+this.blog.company_ownerid
        },

    },
    methods: {
        handleError(){
            this.error = null;
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },

    },
    
}
</script>