<template>
    <tr>
        <!-- Table data -->
        <td>{{num}}</td>

        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="avatar avatar-md">
                    <router-link :to="detailLink" >
                        <img v-if="create_photo" :src="create_photo" class="rounded-circle" alt="">
                        <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                    </router-link>
                </div>
            </div>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        <router-link :to="detailLink" >{{create_name}}</router-link>
                    </h6>
                </div>
            </div>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    {{create_email}}
                </div>
            </div>
        </td>

        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0"><a href="#" class="stretched-link">{{date}}</a></h6>
                </div>
            </div>
        </td>

        <td>
            <span v-if="is_active" class="badge text-bg-success">활성화</span>
            <span v-else class="badge text-bg-danger">비활성화</span>
        </td>
        <td>
            <button v-if="status == 0" type="button" class="btn btn-danger-soft me-1 mb-1 mb-lg-0">미처리</button>
            <button v-if="status == -1" type="button" class="btn btn-secondary-soft me-1 mb-1 mb-lg-0">Reject</button>
            <button v-if="status == 2" type="button" class="btn btn-primary-soft me-1 mb-1 mb-lg-0">진행중</button>
            <button v-if="status == 8" type="button" class="btn btn-warning-soft me-1 mb-1 mb-lg-0">보류중</button>
            <button v-if="status == 9" type="button" class="btn btn-success-soft me-1 mb-1 mb-lg-0">Accept</button>
        </td>
        <td>
            <router-link :to="detailLink" class="btn btn-info-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Edit">
                <i class="bi bi-pencil-square"></i>
            </router-link>
        </td>

    </tr>    
</template>

<script>
export default {
    components: {},
    props: [
        'id','num','create_id','create_name','create_email','create_photo','is_active','status','date',
        'page', 
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            return '/companydash/worker/request/'+this.id+'?page='+this.page ; 
        },

    },
    methods: {

    },
    
}
</script>