<template>
<div class="row g-4 mt-1">

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Course information START -->
    <div class="col-12">
        <div class="card bg-transparent border rounded-3 h-100">

            <!-- Catd header -->
            <div class="card-header bg-light border-bottom">
                <div class="d-sm-flex align-items-center">
                    <div class="avatar avatar-md ">
                        <img v-if="inputReceivePhoto" :src="inputReceivePhoto" class="rounded-circle" alt="">
                        <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                    </div>
                    <div class="mb-0 ms-2">
                        <h6 class="mb-0">{{inputReceiveName}} </h6>
                        <small>{{inputReceiveEmail}}</small>
                    </div>
                </div>
            </div>

            <!-- Card body START -->
            <div class="card-body">

                <!-- Course image and info START -->
                <div class="row g-4">
                    <!-- Course info and avatar -->
                    <div class="col-md-12">
                        <!-- Info -->
                        <span v-if="inputIsCheck == false" class="badge text-bg-orange"><i class="fas fa-bell"></i></span><br/>
                        <p class="mb-3 white-space-newline">{{inputContent}}</p>

                    </div>
                    <hr/>
                    <div class="col-md-2">
                        <div class="mb-0">
                            
                            <h6 class="mb-0">
                                메세지 발송인 : 
                                <router-link :to="openLink"  class="stretched-link">
                                    {{inputCreateName}} 
                                </router-link>
                            </h6>
                        </div>

                        <small>
                            <router-link :to="openLink"  class="stretched-link">
                                {{inputCreateEmail}}
                            </router-link>
                        </small>

                    </div>

                    <div class="col-md-3">
                        <div class="avatar avatar-md ">
                            <router-link :to="openLink"  class="stretched-link">
                                <img v-if="inputCreatePhoto" :src="inputCreatePhoto" class="rounded-circle" alt="">
                                <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                            </router-link>
                        </div>
                            <!-- <div class="mb-0 ms-2">
                                <h6 class="mb-0">{{inputCreateName}} </h6>
                            </div> -->

                            <!-- <h5 class="card-header-title">{{inputCreateName}}</h5> -->
                    </div>
                </div>
                <!-- Course image and info END -->
            </div>
            <!-- Card body END -->
        </div>
    </div>
    <!-- Course information END -->


</div> <!-- Row END -->

</template>

<script>
var filename__= "[components > admin > notification > AdminNotificationDetailComponent.vue] "
import axios from 'axios';

export default {
    components: {},
    props: [
        'id'
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            inputReceiveName : null,
            inputReceiveEmail : null,
            inputReceivePhoto: null,

            inputCreateId : null,
            inputCreateName : null,
            inputCreateEmail : null,
            inputCreatePhoto: null,

            inputContent : null,

            inputDate : null,
            inputIsCheck : null,
        }
    },
    created(){
        this.getNotification();
    },    
    computed: {
        openLink(){
            return '/admin/member/'+this.inputCreateId ; 
        },
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        async getNotification(){
            let funcname__ ="getNotification() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/notification/admin/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {


                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+''+funcname__+' response:', data.response);
                    const notification = data.response

                    this.inputCreateId = notification.create_id ;
                    this.inputCreateName = notification.create_name ;
                    this.inputCreateEmail = notification.create_email ;
                    this.inputCreatePhoto = notification.create_photo ;

                    this.inputReceiveName = notification.receive_name ;
                    this.inputReceiveEmail = notification.receive_email ;
                    this.inputReceivePhoto = notification.receive_photo ;

                    this.inputContent = notification.content ;

                    this.inputIsCheck = notification.is_check ;
                    this.inputDate = notification.date ;

                } else {
                    // console.log(filename__+''+funcname__+' err:');

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  
                }
            }

            this.isLoading = false ;     
        },

    },
    
}
</script>
<style scoped>

</style> 