<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>


	<!-- Page main content START -->
	<div class="page-content-wrapper-match border">

        <!-- Modal -->
        <!-- 참고자료 : https://getbootstrap.com/docs/5.2/components/modal/ -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Delete</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>

		<!-- Title -->
        <form @submit.prevent="sendForm" >
            <div class="row mb-0">
                <div class="col-12 text-end mb-3">
                    <!-- <h1 class="h3 mb-2 mb-sm-0">Contact Us Detail</h1> -->
                    <router-link :to="backToLink" class="btn btn-secondary mb-0 me-2">Back</router-link>
                </div>
            </div>

            <div class="col-12">
                <div class="card bg-transparent border rounded-3 h-100">

                    <!-- Catd header -->
                    <div class="card-header bg-light border-bottom">
                        <h5 class="card-header-title">자주하는 질문 수정 </h5>
                    </div>

                    <!-- Card body START -->
                    <div class="card-body">

                            <div class="row g-4">
                                <div class="col-12 mb-2">
                                    <label class="form-label">Title</label>
                                    <div class="input-group">
                                        <input type="text" v-model.trim="inputTitle" class="form-control" placeholder="title">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label class="form-label">Content</label>
                                    <div class="input-group">
                                        <textarea class="form-control" rows="5" v-model.trim="inputContent"></textarea>
                                    </div>
                                </div>

                            </div>

                    </div>
                    <!-- Card body END -->
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 d-sm-flex justify-content-between align-items-center">
                    <button type="button" class="btn btn-danger mb-0" data-bs-toggle="modal" data-bs-target="#exampleModal">Delete</button>
                    <button type="submit" class="btn btn-primary mb-0" >Save changes</button>
                </div>
            </div>
        </form>


	</div>

</div>
</template>

<script>
// status401__ status__
var filename__="[pages > admin > faq > AdminFaqFrequentUpdate.vue] "

import axios from 'axios';

export default {
    components: {
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  
            
            inputTitle : null, 
            inputContent : null, 

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			this.getItem();
            return ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    computed: {
        backToLink(){
            return '/admin/faqfrequents'; 
        },
    },    
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },

        async getItem(){
            let funcname__ ="getItem()" ;

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/faq/admin/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+''+funcname__+'  response:', data.response);

                    this.inputTitle = data.response.title ;
                    this.inputContent = data.response.content ;

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  // this.error = err.msg || 'Failed to authenticate';                
                    // alert('error')
                }
            }

            this.isLoading = false ;     
        },
        
        // 전송하기  
        async sendForm(){
            let funcname__ ="sendForm()" ;

            let dataForm = { 
                'category' : 'faq',
                'title':this.inputTitle,
                'content': this.inputContent,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputTitle === '' || this.inputTitle === undefined || this.inputTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="카테고리를  입력해주세요.";
                return ;
            }
            if( this.inputContent === '' || this.inputContent === undefined || this.inputContent === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/faq/frequent/update/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$router.replace(this.backToLink);

                            }, 500);
                        }                        


                    } else {
                        console.log(filename__+''+funcname__+' return err:', serverData.data.error_description);
                        this.dialogTitle ="전송 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // 팝업 창을 통해서 삭제   
        async removeForm(){
            let funcname__ ="removeForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/faq/frequent/'+this.id,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },
                    
                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        // console.log(filename__+''+funcname__+' data:', serverData.data);
                                                
                        this.dialogTitle ='Success' ;
                        this.error = '삭제 성공했습니다.';   

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$router.replace(this.backToLink);

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' return err:', serverData.data.error_description);

                        this.error = serverData.data.error_description;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },     
                
    }
}
</script>

<style scoped>

</style>