<template>
<div class="page-content-wrapper-match border">
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- 답변 모달  -->
    <div class="modal fade" :id="idCompu" tabindex="-1" aria-labelledby="notificationModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title fs-5" id="replyModalLabel">Notification</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <label class="form-label">Your Message *</label>
                <textarea class="form-control" rows="5" v-model.trim="inputContent"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" @click="sendForm" class="btn btn-primary" data-bs-dismiss="modal">Send Notification</button>
            </div>
            </div>
        </div>
    </div>

    <!-- Title -->
    <div class="row g-3 align-items-center justify-content-between">
        <div class="col-md-6">
            <h1 class="h3 mb-2 mb-sm-0">답변내용 {{seq}}번째</h1>
        </div>
        <div class="col-md-6 text-end">
            <router-link :to="updateLink" class="btn btn-sm btn-primary mb-0 me-2">Update</router-link>
            <!-- <button type="button" class="btn btn-sm btn-orange mb-0" data-bs-toggle="modal" :data-bs-target="idShapCompu">
                <i class="bi bi-bell fa-fw me-2"></i>Notification
            </button> -->
        </div>
    </div>

    <div class="row g-4 mt-1">
        <!-- Course information START -->
        <div class="col-12">
            <div class="card bg-transparent border rounded-3 h-100">

                <!-- Catd header -->
                <div class="card-header bg-light border-bottom">
                    
                    <div class="avatar avatar-md">
                        <img v-if="create_photo" class="avatar-img rounded-circle" :src="create_photo" alt="avatar">
                        <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user.png" alt="avatar">
                        <span><b>{{create_name}}</b></span>
                    </div>

                </div>

                <!-- Card body START -->
                <div class="card-body">

                    <!-- Course image and info START -->
                    <div class="row g-4">
                        <!-- Course info and avatar -->
                        <div class="col-md-12">
                            <!-- Info -->
                            <p class="mb-3 white-space-newline">{{content}}</p>
                        </div>
                        <div class="col-md-12">
                            <!-- Info -->
                            <hr/>
                            <h6 class="mb-3">{{date}}</h6>
                        </div>

                    </div>
                    <!-- Course image and info END -->
                </div>
                <!-- Card body END -->
            </div>
        </div>
        <!-- Course information END -->


    </div> <!-- Row END -->
</div>
</template>

<script>
var filename__= "[components > admin > company > AdminCompanyRequestReplyListComponent.vue] "
import axios from 'axios';

export default {
    components: {},
    props: [
        'id', 'parent_id', 'receive_id',
        'create_id','create_name','create_photo','content','date','seq'
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputContent : this.content, 

        }
    },
    created(){
        // this.getContactus();
    },    
    computed: {
        updateLink(){
            return '/admin/company/request/reply/update/'+this.id+'/'+this.parent_id+'/'+this.create_id+'?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },
        idCompu(){
            return 'modal'+this.id
        },
        idShapCompu(){
            return '#modal'+this.id
        },


    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        // notification 발송시
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'receive_id': this.create_id,
                'content': this.inputContent,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputContent === '' || this.inputContent === undefined || this.inputContent === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/notification/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                }, 

            }).catch( err => {


                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData.status === 200) {
                    const data = serverData.data ;
                    // console.log(filename__+''+funcname__+' data:', data);

                    if(data.code === 201){
                                                    
                        this.inputContent = null ;

                        this.dialogTitle ='Success' ;
                        this.error = 'Notification 전송에 성공했습니다.';     

                    } else {
                        // console.log(filename__+''+funcname__+' return err:', serverData.data.msg);
                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.msg;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }
            }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    },
    
}
</script>
<style scoped>

</style> 