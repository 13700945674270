<template>
    <tr>
        <!-- Table data -->
        <td>{{num}}</td>
        <td>
            <button v-if="category == 1" type="button" class="btn btn-sm btn-danger-soft me-1 mb-1 mb-lg-0">버그</button>
            <button v-if="category == 2" type="button" class="btn btn-sm btn-success-soft me-1 mb-1 mb-lg-0">보완</button>
            <button v-if="category == 3" type="button" class="btn btn-sm btn-primary-soft me-1 mb-1 mb-lg-0">새로운 작업</button>
        </td>
        <td>
            <router-link :to="detailLink" >{{title}}</router-link>
        </td>

        <td>
            <button v-if="process == 0" type="button" class="btn btn-sm btn-outline-primary me-1 mb-1 mb-lg-0">미확인</button>
            <button v-if="process == 1" type="button" class="btn btn-sm btn-info-soft me-1 mb-1 mb-lg-0">확인함</button>
            <button v-if="process == 2" type="button" class="btn btn-sm btn-outline-success me-1 mb-1 mb-lg-0">검토중</button>

            <button v-if="process == 3" type="button" class="btn btn-sm btn-primary-soft me-1 mb-1 mb-lg-0">작업예정</button>
            <button v-if="process == 4" type="button" class="btn btn-sm btn-primary me-1 mb-1 mb-lg-0">작업중</button>

            <button v-if="process == 9" type="button" class="btn btn-sm btn-danger me-1 mb-1 mb-lg-0">보류</button>
            <button v-if="process == 10" type="button" class="btn btn-sm btn-success me-1 mb-1 mb-lg-0">처리완료</button>
            <button v-if="process == 11" type="button" class="btn btn-sm btn-success-soft me-1 mb-1 mb-lg-0">Pass</button>
        </td>
        <td>
            <span v-if="processCompu" :class="workCompu?'badge text-bg-primary':'badge bg-primary bg-opacity-10 text-primary'">{{startdate}}</span>
        </td>
        <td>
            <span v-if="processCompu" :class="workCompu?'badge text-bg-danger':'badge bg-danger bg-opacity-10 text-danger'">{{enddate}}</span>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="avatar avatar-xs mb-2 mb-md-0">
                    <img v-if="create_photo" :src="create_photo" class="rounded-circle" alt="">
                    <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                </div>
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0"><a class="stretched-link">{{create_name}}</a></h6>
                </div>
            </div>
        </td>

        <td>{{date}}</td>
        <td>{{update}}</td>

        <td>
            <router-link :to="detailLink" class="btn btn-sm btn-info-soft mb-0" >View</router-link>
        </td>
    </tr>

</template>

<script>
var filename__="[components > admin > plan > AdminPlanListComponent.vue]" ;

export default {
    components: {},
    props: [
        'id','num',
        'title','description',
        'category','process',
        'startdate','enddate',
        'date','update',
        'create_id','create_name','create_photo',
        'page', 
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            return '/admin/plan/'+this.id+'?page='+this.$route.query.page+"&category="+this.$route.query.category+"&process="+this.$route.query.process+"&search="+this.$route.query.search
        },
        processCompu(){
            if(this.process === 3 || this.process === 4 || this.process === 10 ){
                return true ;
            } else {
                return false ;
            }
        },
        workCompu(){
            if(this.process===4){
                return true ;
            } else if(this.process===3){
                return false 
            }9
        },
        descriptionSlice30(){
            if(this.description.length > 30) {
                return this.description.slice(0,30)+"...."; 
            } else {
                return this.description ;
            }
            
        },
    },
    methods: {
    },
    
}
</script>