<template>
<div>

	<base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
		<p>{{ error }}</p>
	</base-dialog>

	<base-dialog :show="isLoading" title="Loading..." fixed>
		<p>Loading...</p>
		<base-spinner></base-spinner>
	</base-dialog>

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

		<!-- Title -->
		<div class="row">
			<div class="col-12">
				<h1 class="h3 mb-2 mb-sm-0">정보창고</h1>
			</div>
		</div>
		
		<div class="card bg-transparent">

			<!-- Card header START -->
			<div class="card-header bg-transparent border-bottom px-0">
				<!-- Search and select START -->
				<div class="row g-3 align-items-center justify-content-between">

					<!-- Search bar -->
					<div class="col-md-8">
						<form class="rounded position-relative" style="display: inline-block;">
							<input class="form-control bg-transparent" type="search" placeholder="Search" aria-label="Search" v-model.trim="inputSearch" >
							<button @click="onChangeSearch" class="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="button">
								<i class="fas fa-search fs-6 "></i>
							</button>
						</form>
						<button type="button" @click="onChangeSearch" class="btn btn-primary mb-0 ms-2">Search</button>
						<!-- <button type="button" @click="onChangeAll" class="btn btn-primary mb-0 ms-2">All</button> -->
						
						
						
					</div>


					<!-- Tab button -->
					<div class="col-md-3 text-end" >
						<div class="row g-3">
							<!-- Course item -->
							<div class="col-6 d-grid">
								<button type="button" @click="onChangeActive" :class="displayComu ?'btn btn-primary':'btn btn-outline-primary'">Active</button>
							</div>

							<!-- Course item -->
							<div class="col-6 d-grid">
								<button type="button" @click="onChangeAll" :class="displayComu ?'btn btn-outline-primary':'btn btn-primary'">All</button>
							</div>

						</div>						

					</div>
				</div>
				<!-- Search and select END -->
			</div>
			<!-- Card header END -->

			<!-- Card body START -->
			<div class="card-body px-0">

				<!-- <div class="tab-content"> -->
					<!-- 
					<div class="tab-pane fade show active" id="nav-preview-tab-1">
						<div class="row g-4">
							<admin-blog-col-component v-for="item in itemList"
								:key="item.id"
								:id="item.id"
								:num="item.num"
								:image_path="item.image_path"
								:title="item.title"
								:content="item.content"
								:create_photo="item.create_photo"
								:create_name="item.create_name"
								:date="item.date"
								:is_display="item.is_display"
								:page="page"
								:search="inputSearch"
								/>
						</div>
					</div>
					 -->
					
					<!-- <div class="tab-pane fade" id="nav-html-tab-1"> -->
						<div class="table-responsive border-0">
							<table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
								<!-- Table head -->
								<thead>
									<tr>
										<th scope="col" class="border-0 rounded-start">Num</th>
										<th scope="col" class="border-0 ">Image</th>
										<th scope="col" class="border-0 ">Title</th>
										<th scope="col" class="border-0 ">카테고리</th>
										<th scope="col" class="border-0">Company</th>
										<th scope="col" class="border-0">Active</th>
										<th scope="col" class="border-0">Date</th>
										<th scope="col" class="border-0 rounded-end">Action</th>
									</tr>
								</thead>

								<!-- Table body START -->
								<tbody>

									<!-- Table row -->
									<admin-blog-tr-component v-for="item in itemList"
										:key="item.id"
										:id="item.id"
										:num="item.num"
										:image_path="item.image_path"
										:is_text="item.is_text"
										:title="item.title"
										:categorystr="item.categorystr"
										:description="item.description"

										:create_photo="item.create_photo"
										:create_name="item.create_name"

										:company_id = "item.company_id" 
										:company_name = "item.company_name" 
										:company_email = "item.company_email" 
										:company_photo = "item.company_photo" 
										:company_is_display = "item.company_is_display" 

										:date="item.date"
										:is_display="item.is_display"
										:page="page"
										:search="inputSearch"

										/>

								</tbody>
								<!-- Table body END -->
							</table>
						</div>
					<!-- </div> -->

				<!-- </div> -->
				<!-- Tabs content END -->
			</div>
			<!-- Card body END -->

			<!-- Card footer START -->
			<div v-if="itemList.length > 0"> 
				<admin-pagination :pagination="paginationCompu" @paging="pagingSend" ></admin-pagination>
			</div>
			<!-- Card footer END -->
		</div>
	</div>
	<!-- Page main content END -->


<!-- Page content END -->
    
</div>
</template>

<script>
var filename__= "[pages > admin > blog > AdminBlogList.vue] "
import axios from 'axios';

// import AdminBlogColComponent from '../../../components/admin/blog/AdminBlogColComponent.vue' ;
import AdminBlogTrComponent from '../../../components/admin/blog/AdminBlogTrComponent.vue' ;
import AdminPagination from '../../../components/admin/ui/AdminPagination.vue';

export default {
    components: {
		// "admin-blog-col-component" : AdminBlogColComponent,
		"admin-blog-tr-component" : AdminBlogTrComponent,
        "admin-pagination": AdminPagination,
    },
    props: [],
    data() {
        return {
            isLoading: false,
			isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList: [], 
            page:1,
            current_path:"/admin/blogs",  //current_path
			pagination: null, 

            inputPagePerCount : 50,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',     
			inputIsDisplay : true, 
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    computed: {
		createLink(){
			return '/admin/blog/create' ; 
		},        
        paginationCompu(){
            return this.pagination ;
        },
		displayComu(){
			return this.inputIsDisplay ;
		},		
    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			this.getList();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
			// console.log('[AdminBlogList.vue] isAuth():',this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminBlogList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        // onChangePagePerCount(event){
        //     this.inputPagePerCount = event.target.value ;
        //     this.getList(); //page=1
        //     console.log("[MemberListAdmin.vue] methods() onChangePagePerCount() inputPagePerCount: ", this.inputPagePerCount);
        // },
        onChangeSearch(){
			this.page = 1 ;
			this.inputIsActive = true ;
            this.getList(); //page=1
        },
		onChangeAll(){
			this.page = 1 ;
			this.inputIsDisplay = false ; //모두검색
			this.inputSearch ='' ;
            this.getList(); //page=1
        },
        onChangeActive(){
			this.page = 1 ;
			this.inputIsDisplay = true ;
			this.inputSearch ='' ;
            this.getList(); //page=1
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        async getList(){
			let funcname__ ="getList() ";

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }

            if( this.inputSearch === 'undefinded' || this.inputSearch === '') { 
                this.inputSearch = '' ; //pagination, search 에서 계속작동하기 위해서                 
            }             


            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/blog/all/admin',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           
                params: { 
                    page: this.page , 
                    search: this.inputSearch,       
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
					is_active : this.inputIsActive,
					is_display: this.inputIsDisplay,     
                }

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ;
                return ;
            } else {
				const data = serverData.data ;

        
				console.log(filename__+''+funcname__+" data:", data);

				if( typeof data !== "undefined" &&  data.status_code == 200 ){
					// console.log(filename__+''+funcname__+'  response:', data.response);
					
					this.pagination = data.response.pagination ;
					let cnt = data.response.pagination.counter ;
					let tmpList = data.response.data ;
					let list = []; 

					//메인 카테고리 값들을 입력하기 
					for(let item of tmpList ){
						list.push({
							num: cnt--, 
							id: item.id, 

							image_path: item.main_photo ? item.main_photo.main_photo_path : '',
							is_text : item.is_fronttext,

							title: item.title, 
							description: item.description,
							categorystr: item.categorystr,

							create_photo: item.create_photo,
							create_name: item.create_name,

							company_id : item.company_id,
							company_email : item.company_email,
							company_name : item.company_name,
							company_photo : item.company_photo,
							company_is_display : item.company_is_display,

							date: item.date, 
							is_display: item.is_display,
							
						});
					}

					// console.log(filename__+''+funcname__+" list:", list);
					this.itemList = list;
					// this.isApplying = true ;

					// console.log(filename__+''+funcname__+" this.itemList:", this.itemList);
					// console.log(filename__+''+funcname__+" this.pagination:", this.pagination);

				} else {
					console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
					this.dialogTitle ="Error" ;
					this.error = serverData.data.error_description;
				}
			}

            this.isLoading = false ;     
        },


    }
}
</script>

<style scoped>

</style>