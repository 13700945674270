<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <teleport to="body">
        <!-- Modal -->
        <!-- 참고자료 : https://getbootstrap.com/docs/5.2/components/modal/ -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Delete</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>                         
    </teleport>         
    <div class="card card-body border p-4">
        <!-- Price and share button -->
        <!-- <div class="d-flex justify-content-between align-items-center">
            <h3 class="fw-bold mb-0 me-2">$295.55</h3>
            <div class="dropdown">
                <a href="#" class="btn btn-sm btn-light rounded mb-0 small" role="button" id="dropdownShare" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-fw fa-share-alt"></i>
                </a>
                <ul class="dropdown-menu dropdown-w-sm dropdown-menu-end min-w-auto shadow rounded" aria-labelledby="dropdownShare">
                    <li><a  class="dropdown-item" href="#"><i class="fab fa-twitter-square me-2"></i>Twitter</a></li>
                    <li><a class="dropdown-item" href="#"><i class="fab fa-facebook-square me-2"></i>Facebook</a></li>
                    <li><a class="dropdown-item" href="#"><i class="fab fa-linkedin me-2"></i>LinkedIn</a></li>
                    <li><a class="dropdown-item" href="#"><i class="fas fa-copy me-2"></i>Copy link</a></li>
                </ul>
            </div>
        </div> -->
            
        <!-- Buttons -->
        <div class="mt-0 d-grid">
            <!-- <a href="#" class="btn btn-outline-primary">Add to cart</a> -->
            <router-link :to="backToLink" class="btn btn-secondary">목록보기</router-link>
            <router-link :to="updateLink" class="btn btn-info">수정하기</router-link>
            <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">삭제하기</button>
        </div>
        <!-- Divider -->
        <hr>

        <!-- Title -->
        <h5 class="mb-3">This course includes</h5>
        <ul class="list-group list-group-borderless border-0">
            <li class="list-group-item px-0 d-flex justify-content-between">
                <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-book-open text-primary"></i>Lectures</span>
                <span>{{course.lecturecount ? course.lecturecount : ''}}</span>
            </li>
            <li class="list-group-item px-0 d-flex justify-content-between">
                <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-clock text-primary"></i>Duration</span>
                <span>{{timeFormatCompu}}</span>
            </li>
            <li class="list-group-item px-0 d-flex justify-content-between">
                <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-signal text-primary"></i>Skills</span>
                <span v-if="course.level ===0"> All levels</span>
                <span v-if="course.level ===1"> Beginner</span>
                <span v-if="course.level ===2"> Intermediate</span>
                <span v-if="course.level ===3"> Advanced</span>

            </li>
            <!-- <li class="list-group-item px-0 d-flex justify-content-between">
                <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-globe text-primary"></i>Language</span>
                <span>English</span>
            </li> -->
            <!-- <li class="list-group-item px-0 d-flex justify-content-between">
                <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-user-clock text-primary"></i>Deadline</span>
                <span>Nov 30 2021</span>
            </li> -->
            <!-- <li class="list-group-item px-0 d-flex justify-content-between">
                <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-medal text-primary"></i>Certificate</span>
                <span v-if="course.is_certificate">Yes</span>
                <span v-else>No</span>
            </li> -->
        </ul>
        <!-- Divider -->
        <hr>

        <!-- Instructor info -->
        <div class="d-sm-flex align-items-center">
            <!-- Avatar image -->
            <div class="avatar avatar-xl">
                <img v-if="course.create_photo" class="avatar-img rounded-circle" :src="course.create_photo" alt="avatar">
                <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/05.jpg" alt="avatar">
            </div>
            <div class="ms-sm-3 mt-2 mt-sm-0">
                <h5 class="mb-0"><a href="#">{{course.create_name}}</a></h5>
                <!-- <p class="mb-0 small">Founder Eduport company</p> -->
            </div>
        </div>

        <!-- Rating and follow -->
        <!-- <div class="d-sm-flex justify-content-sm-between align-items-center mt-0 mt-sm-2"> -->
            <!-- Rating star -->
            <!-- <ul class="list-inline mb-0">
                <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li class="list-inline-item me-0 small"><i class="fas fa-star-half-alt text-warning"></i></li>
                <li class="list-inline-item ms-2 h6 fw-light mb-0">4.5/5.0</li>
            </ul> -->

            <!-- button -->
            <!-- <button class="btn btn-sm btn-primary mb-0 mt-2 mt-sm-0">Follow</button> -->
        <!-- </div> -->
    </div>

</div>
</template>

<script>
// modal__ teleport__ removeForm__
var filename__ ="[components > companydash > CompanyDashCourseDetailRightComponent.vue] " ;

import axios from 'axios';

export default {
    components: {},
    props: [
        'id','course', 
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  
        }
    },
    
    computed: {
        backToLink(){
            return '/companydash/courses'
        },
        updateLink(){
            return '/companydash/course/update/'+this.id ;
        },
        timeFormatCompu(){
            if(this.course.coursetime) {
                let hour = parseInt(this.course.coursetime/60) ;
                let minute = this.course.coursetime - hour * 60 ;
                if(hour == 0){
                    return this.course.coursetime +'분'                
                } else if(minute == 0){
                    return hour+'시간'
                } else {
                    return hour + '시간 '+minute + '분' ;                
                }

            } else {
                return '';
            }
        }

    },
    methods: {
        handleError(){
            this.error = null;
        },

        // 팝업 창을 통해서 삭제   
        async removeForm(){
            let funcname__ ="removeForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/content/course/delete/'+this.id,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    const data = serverData.data ;

                    // console.log(filename__+''+funcname__+" data.code:", data.code);
                    if(data.status_code == 201){
                                                
                        this.dialogTitle ='Success' ;
                        this.error = '삭제 성공했습니다.';   

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$router.replace(this.backToLink);

                            }, 700);
                        }                        


                    } else if(data.status_code == 500){
                        this.dialogTitle ='Fail' ;
                        this.error = '이미 수강등록된 코스입니다.';   

                    } else {
                        console.log(filename__+''+funcname__+' return err:', serverData.data.msg);
                        this.dialogTitle ='error' ;
                        this.error = serverData.data.msg;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },     

    },
    
}
</script>