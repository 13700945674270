<template>
<div>

  <!-- Header START -->
  <header class="navbar-light navbar-sticky header-static">
    <!-- Logo Nav START -->
    <nav class="navbar navbar-expand-xl">
      <div class="container-fluid px-3 px-xl-5">
        <!-- Logo START -->
        <router-link to="/" class="navbar-brand" >
          <!-- <img class="light-mode-item navbar-brand-item" src="/assets/images/logo.svg" alt="logo">
          <img class="dark-mode-item navbar-brand-item" src="/assets/images/logo-light.svg" alt="logo"> -->
          <img class="light-mode-item navbar-brand-item" src="/assets/workerscafe/logo.svg" alt="logo">
          <img class="dark-mode-item navbar-brand-item" src="/assets/workerscafe/logo.svg" alt="logo">
        </router-link>
        <!-- Logo END -->

        <!-- Responsive navbar toggler -->
        <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-animation" >
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>

        <!-- Main navbar START -->
        <div class="navbar-collapse w-100 collapse"  id="navbarCollapse">

          <!-- Nav category menu START -->
          <ul class="navbar-nav navbar-nav-scroll me-auto">
            <!-- Nav item 1 Demos -->
            <li class="nav-item dropdown dropdown-menu-shadow-stacked" >
            </li>
          </ul>
          <!-- Nav category menu END -->
<!--
          <ul class="navbar-nav navbar-nav-scroll me-auto">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle active" href="#" id="demoMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="text-success fa-fw bi bi-cloud-download-fill me-2"></i>Test</a>
              <ul class="dropdown-menu" aria-labelledby="demoMenu">
                <li> <router-link to="/admin/blog/create" class="dropdown-item " >/admin/blog/create</router-link></li>
              </ul>
            </li>
          </ul>
 -->

          <!-- Nav Main menu START -->
          <ul class="navbar-nav navbar-nav-scroll me-auto">
            <!-- Nav item 1 Demos -->
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="demoMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="text-success fa-fw bi bi-cloud-download-fill me-2"></i>워커스 카페</a>
              <ul class="dropdown-menu" aria-labelledby="demoMenu">
                <li > <router-link to="/intro" class="dropdown-item " >워커스카페 소개</router-link></li>
                <!-- <li> <router-link to="/blogs" class="dropdown-item " >뉴스 및 블로그</router-link></li> -->
                <!-- <li> <router-link to="/microlearning" class="dropdown-item " >Micro Learning</router-link></li> -->
                <li> <router-link to="/contactus" class="dropdown-item " >Contact Us</router-link></li>
                <!-- <li> <router-link to="/admin/blog/create" class="dropdown-item " >/admin/blog/create</router-link></li> -->
                <!-- <li> <hr class="dropdown-divider"></li> -->
              </ul>
            </li>

            <!-- <li class="nav-item">
              <router-link class="nav-link" to="/blogs" >
                <i class="text-purple fa-fw bi bi-stickies-fill me-2"></i>정보창고
              </router-link>
            </li> -->

            <!-- Nav item 2 Pages -->
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="pagesMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="text-purple fa-fw bi bi-stickies-fill me-2"></i>정보창고</a>
              <ul class="dropdown-menu" aria-labelledby="pagesMenu">
                <li> <router-link to="/totalblogs" class="dropdown-item "  >전체 정보창고 리스트</router-link></li>
                <li> <hr class="dropdown-divider"></li>
                
                <li v-for="item in blogCategorList" :key="item.id"> 
                  <router-link :to="'/blogs/'+item.id+'/'+item.title" class="dropdown-item " >{{item.title}}</router-link>
                </li>
                
              </ul>
            </li>

            <!-- Nav item 2 Pages -->
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="pagesMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="text-danger fa-fw bi bi-film me-2"></i>스터디룸</a>
              <ul class="dropdown-menu" aria-labelledby="pagesMenu">
                <li> <router-link to="/totalcourses" class="dropdown-item "  >전체 스터디룸</router-link></li>
                <li> <hr class="dropdown-divider"></li>
                
                <li v-for="item in courseCategorList" :key="item.id"> 
                  <router-link :to="'/courses/'+item.id+'/'+item.title" class="dropdown-item " >{{item.title}}</router-link>
                </li>
                
              </ul>
            </li>

            <!-- 강사  -->
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="pagesMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="text-purple fas fa-user-tie me-2"></i>파트너</a>
              <ul class="dropdown-menu" aria-labelledby="pagesMenu">
                <li> <router-link to="/companies" class="dropdown-item " >
                  <i class="fas fa-user-tie fa-fw me-1"></i>파트너 리스트</router-link>
                </li>
                <li> <router-link to="/company/request" class="dropdown-item " >
                  <i class="fas fa-user-cog fa-fw me-1"></i>파트너 등록</router-link>
                </li>
                
                <!-- 파트너 대시보드, 코스 공동작업자 등록 여부  -->
                <top-company-dashboard-panel-component v-if="isAuth" />

              </ul>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" to="/faqs" >
                <i class="text-purple fa-fw bi bi-question-circle me-2"></i>자주하는 질문
              </router-link>
            </li>

            <!-- Nav item 4 Megamenu-->
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="pagesMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="text-orange fa-fw bi bi-speedometer me-2"></i>내 대시보드</a>
                <ul v-if="isAuth" class="dropdown-menu" aria-labelledby="pagesMenu">
                  <li> <router-link to="/studentdash/dashboard" class="dropdown-item"  >나의 대시보드</router-link> </li>
                      <li> <router-link to="/studentdash/courses" class="dropdown-item" >수강신청 내역</router-link> </li>
                      <li> <router-link to="/studentdash/wishlists" class="dropdown-item" >위시 리스트</router-link> </li>
                      <li> <router-link to="/studentdash/faqs" class="dropdown-item" >나의 질문</router-link> </li>
                      <!-- <li> <router-link to="/studentdash/notifications" class="dropdown-item" >나에게 온 메세지</router-link> </li> -->
                      <li> <hr class="dropdown-divider"></li>
                      <li> <router-link to="/studentdash/photo/update" class="dropdown-item" >프로필 사진 수정</router-link> </li>
                      <li> <router-link to="/studentdash/profile/update" class="dropdown-item" >프로필 정보 수정</router-link> </li>
                      <li> <router-link to="/studentdash/profile/pwupdate" class="dropdown-item" >비밀번호 변경</router-link> </li>
                      <li> <hr class="dropdown-divider"></li>
                      <li> <router-link to="/studentdash/sector/update" class="dropdown-item" >관련직종 변경</router-link> </li>
                      <li> <router-link to="/studentdash/sector/location/update" class="dropdown-item" >활동지역 변경</router-link> </li>
                      <!-- <li> <hr class="dropdown-divider"></li> -->
                      <!-- <li> <router-link to="/studentdash/profile/delete" class="dropdown-item" >회원탈퇴</router-link> </li> -->
                </ul>
                <ul v-else class="dropdown-menu" aria-labelledby="pagesMenu">  
                  <li> 
                    <router-link to="/signin" class="dropdown-item" >
                      <i class="bi bi-person fa-fw me-2"></i>Require 로그인 
                    </router-link>
                  </li>
                </ul>
            </li>
            

            <!--
            <li class="nav-item dropdown dropdown-fullwidth">
              <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="text-orange fa-fw bi bi-speedometer me-2"></i>내 대시보드</a>
              <div class="dropdown-menu dropdown-menu-end" data-bs-popper="none">
                <div class="row p-4 g-4">
                  <div class="col-xl-6 col-xxl-3">
                    <h6 class="mb-0">Get started</h6>
                    <hr>
                    <ul class="list-unstyled">
                      <li v-for="item in recommendCategoryList" :key="item.id"> 
                        <router-link v-if="item.category === 'getstarted'" :to="'/topmenucourses/getstarted/'+item.id+'/'+item.title" class="dropdown-item" >{{ item.title }}</router-link> 
                      </li>
                    </ul>
                  </div>

                  <div class="col-xl-6 col-xxl-3">
                    <h6 class="mb-0">Degree</h6>
                    <hr>
                    <div v-for="item in recommendCategoryList" :key="item.id">
                      <div class="mb-2 position-relative bg-primary-soft-hover rounded-2 transition-base p-1"  v-if="item.category === 'degree'" > 
                        <router-link :to="'/topmenucourses/degree/'+item.id+'/'+item.title" class="stretched-link h6 mb-0" >{{ item.title }}</router-link>
                        <p class="mb-0 small text-truncate-2">{{ item.subtitle }}</p>
                      </div>
                    </div>                 
                  </div>

                  <div class="col-xl-6 col-xxl-3">
                    <h6 class="mb-0">Certificate</h6>
                    <hr>
                    <div v-for="item in recommendCategoryList" :key="item.id">
                      <div class="d-flex mb-4 position-relative" v-if="item.category === 'certificate'">
                        <h2 class="mb-0">
                          <img v-if="item.image" :src="item.image" class="rounded" width="50" alt="">
                          <img v-else src="/assets/images/element/gallery.svg" class="rounded" width="50" alt="">
                        </h2>
                        <div class="ms-2">
                          <router-link :to="'/topmenucourses/degree/'+item.id+'/'+item.title" class="stretched-link h6 mb-0" >{{ item.title }}</router-link>
                          <p class="mb-0 small text-truncate-2">{{ item.subtitle }}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-xxl-3">
                    <h6 class="mb-0">My Dashboard</h6>
                    <hr>
                    <ul v-if="isAuth" class="list-unstyled">
                      <li> <router-link to="/studentdash/dashboard" class="dropdown-item"  >나의 대시보드</router-link> </li>
                      <li> <router-link to="/studentdash/courses" class="dropdown-item" >수강신청 내역</router-link> </li>
                      <li> <router-link to="/studentdash/wishlists" class="dropdown-item" >위시 리스트</router-link> </li>
                      <li> <hr class="dropdown-divider"></li>
                      <li> <router-link to="/studentdash/faqs" class="dropdown-item" >나의 질문</router-link> </li>
                      <li> <router-link to="/studentdash/notifications" class="dropdown-item" >나에게 온 메세지</router-link> </li>
                      <li> <hr class="dropdown-divider"></li>
                      <li> <router-link to="/studentdash/profile/update" class="dropdown-item" >프로필 수정</router-link> </li>
                      <li> <router-link to="/studentdash/profile/pwupdate" class="dropdown-item" >비밀번호 변경</router-link> </li>
                      <li> <router-link to="/studentdash/profile/delete" class="dropdown-item" >프로필 삭제</router-link> </li>
                    </ul>
                    <ul v-else class="list-unstyled">
                      <li>
                        <router-link to="/signin" class="dropdown-item" >
                          <i class="bi bi-person fa-fw me-2"></i>Require 로그인 
                        </router-link>
                      </li>
                    </ul>
								</div>

                </div>
              </div>
            </li>
            -->

            <!-- Nav item 5 link-->
            <!-- <li class="nav-item dropdown ms-3 me-4">
              <a class="nav-link" href="#" id="advanceMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-h"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end min-w-auto" data-bs-popper="none">
                <li>
                  <router-link to="/faq" class="dropdown-item" >
                    <i class="text-danger fa-fw bi bi-card-text me-2"></i>Faq
                  </router-link>
                </li>
              </ul>
            </li> -->

            <!-- notification  -->
            <!--             
            <li v-if="isAuth" class="nav-item dropdown">
							<a class="btn btn-light btn-round mb-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
								<i class="bi bi-bell fa-fw"></i>
							</a>
              <top-notification-container-component v-if="isAuth" />
            </li>
            <li v-else class="nav-item dropdown">
              <a class="nav-link" href="#" id="advanceMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="bi bi-bell fa-fw"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end min-w-auto" data-bs-popper="none">
                <li>
                  <router-link to="/signin" class="dropdown-item" >
                    <i class="bi bi-person fa-fw me-2"></i>Require 로그인 
                  </router-link>
                </li>
              </ul>
            </li>
            -->
          </ul>
          <!-- Nav Main menu END -->

          <!-- Nav Search START -->
          <!-- <div class="nav my-3 my-xl-0 px-4 flex-nowrap align-items-center">
            <div class="nav-item w-100">
              <form class="position-relative">
                <input class="form-control pe-5 bg-transparent" type="search" placeholder="Search" aria-label="Search">
                <button class="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="submit">
                  <i class="fas fa-search fs-6 "></i>
                </button>
              </form>
            </div>
          </div> -->
          <!-- Nav Search END -->
        </div>        
        <!-- Main navbar END -->


        <!-- Profile START -->
        <!-- 로그인  -->
        <div v-if="isAuth" class="dropdown ms-1 ms-lg-0">
          <a  class="avatar avatar-sm p-0" href="#" id="profileDropdown2" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false" >
            <img v-if="photo" class="avatar-img rounded-circle" :src="photo" alt="avatar" >
            <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user.png" alt="avatar" >
          </a>
          <ul class="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3" aria-labelledby="profileDropdown2">

            <li class="px-3 mb-3">
              <div class="d-flex align-items-center">

                <div v-if="photo" class="avatar me-3">
                  <img class="avatar-img rounded-circle shadow" :src="photo" alt="avatar">
                </div>
                <div v-else class="avatar me-3">
                  <img class="avatar-img rounded-circle shadow" src="/assets/images/avatar/user.png" alt="avatar">
                  <!-- <span class="fa-stack fa-2x fa-pull-left" style="width: 2em;"><i class="fas fa-user-graduate"></i></span> -->
                </div>
                <div>
                  <p class="h6" href="#">{{name}}</p>
                  <p class="small m-0">{{email}}</p>
                </div>
              </div>
            </li>
            <li> <hr class="dropdown-divider"></li>

            <li><router-link to="/studentdash/photo/update" class="dropdown-item" ><i class="bi bi-person-bounding-box fa-fw me-2"></i>프로필 사진수정</router-link></li>
            <li><router-link to="/studentdash/profile/update" class="dropdown-item" ><i class="bi bi-person fa-fw me-2"></i>프로필 정보수정</router-link></li>
            <li><router-link to="/studentdash/profile/pwupdate" class="dropdown-item" ><i class="bi bi-key fa-fw me-2"></i>비밀번호 변경</router-link></li>
            <!-- <li><router-link to="/support" class="dropdown-item" ><i class="bi bi-info-circle fa-fw me-2"></i>도움말</router-link></li> -->

            <li v-if="isAdmin" > <hr class="dropdown-divider"></li>
            <li v-if="isAdmin"><button type="button" @click="moveToAdmin" class="dropdown-item bg-danger-soft-hover" ><i class="bi bi-gear fa-fw me-2"></i>플랫폼 관리자 페이지</button></li>

            <li> <hr class="dropdown-divider"></li>
            <li><button type="button" @click="logout" class="dropdown-item bg-danger-soft-hover" ><i class="bi bi-power fa-fw me-2"></i>로그아웃</button></li>

          </ul>
        </div>
        <!-- 로그아웃  -->
        <div v-else class="dropdown ms-1 ms-lg-0">
          <a class="avatar avatar-sm p-0" href="#" id="loginDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
            <button class="btn btn-primary mb-0" >
              <i class="bi bi-person fa-fw"></i>
            </button>
          </a>
          <ul class="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3"   aria-labelledby="loginDropdown">
            <li>
              <router-link to="/signin" class="dropdown-item"  ><i class="bi bi-person fa-fw me-2"></i>로그인</router-link>
            </li>
            <li> <hr class="dropdown-divider"></li>
            <li>
              <router-link to="/signup" class="dropdown-item" ><i class="bi bi-gear fa-fw me-2"></i>회원가입</router-link>
            </li>
            <li> <hr class="dropdown-divider"></li>
            <li>
              <router-link to="/forgotpw" class="dropdown-item" ><i class="bi bi-question-circle fa-fw me-2"></i>비밀번호 찾기</router-link>
            </li>
          </ul>
        </div>


        <!-- Profile START -->
      </div>
    </nav>
    <!-- Logo Nav END -->
  </header>
  <!-- Header END -->



</div>
</template>

<script>
// string__
// var filename__ ="[components > layout > TheHeader.vue]" ;

import TopCompanyDashboardPanelComponent from './TopCompanyDashboardPanelComponent.vue'
// import TopNotificationContainerComponent from '../notification/TopNotificationContainerComponent.vue'

export default {
    components: {
      "top-company-dashboard-panel-component" : TopCompanyDashboardPanelComponent,
      // "top-notification-container-component" : TopNotificationContainerComponent,
    },
    props: [],
    data() {
        return {
          inputIsMenuShow : false,
          inputIsLoginShow : false,
        }
    },
    computed: {
      isAuth(){
        return this.$store.getters['auth/isAuthenticated'];
      },
      isAdmin(){
        // console.log("[TheHeader.vue] computed : isAdmin():", this.$store.getters['auth/isAdmin'])
        return this.$store.getters['auth/isAdmin'];
      },
      name(){
        // console.log("[AdminTopHeader.vue] computed : name():", this.$store.getters['auth/name'])
        return this.$store.getters['auth/name'];
      },
      email(){
        return this.$store.getters['auth/email'];
      },
      photo(){
        // console.log("[TheHeader.vue] computed : photo():", this.$store.getters['auth/photo'])
        return this.$store.getters['auth/photo'];
      },
      blogCategorList(){
          return this.$store.getters['blog/categorylist'];
      },
      courseCategorList(){
          return this.$store.getters['course/categorylist'];
      },
      recommendCategoryList(){
        // console.log("[TheHeader.vue] computed : recommendcategory():", this.$store.getters['courserecommend/recommendcategorylist'])
          return this.$store.getters['courserecommend/recommendcategorylist'];
      }
    },
    mounted() {
    },
    methods: {
      logout(){
        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
        this.$store.dispatch('dashboard/Remove'); //==> store/modules/dashboard/actions.js
        this.$router.replace('/');
      },
      moveToAdmin(){
        this.$store.dispatch('auth/pageAdmin'); //==> store/modules/auth/actions.js
        this.$router.replace('/admin');
      },
      showMobileMenu(){
        // let funcname__ ="showMobileMenu()" ;
        this.inputIsMenuShow = true ;
        // console.log(filename__+''+funcname__+' inputIsMenuShow :',this.inputIsMenuShow);  // ~~log
        this.inputIsLoginShow = false ;
      },
      hideMobileMenu(){
        // let funcname__ ="hideMobileMenu()" ;
        this.inputIsMenuShow = false ;
        // console.log(filename__+''+funcname__+' inputIsMenuShow :',this.inputIsMenuShow);  // ~~log
      },
      showMobileLogin(){
        // let funcname__ ="showMobileLogin()" ;
        this.inputIsLoginShow = true ;
        // console.log(filename__+''+funcname__+' inputIsLoginShow :',this.inputIsLoginShow);  // ~~log
        this.inputIsMenuShow = false ;
      },
      hideMobileLogin(){
        // let funcname__ ="hideMobileLogin()" ;
        this.inputIsLoginShow = false ;
        // console.log(filename__+''+funcname__+' inputIsLoginShow :',this.inputIsLoginShow);  // ~~log
      },

    },
}
</script>

<style scoped>

</style>
