<template>
    <div>
	<!-- Page main content START -->
	<div class="page-content-wrapper border">

		<!-- Title -->
		<div class="row mb-3">
			<div class="col-12">
                <div class="card bg-transparent border">
                    <div class="card-body" >
                        <h1 class="h4 mb-2 mb-sm-0 me-3">메인 이미지 종류 </h1>
                        <div class="form-check form-switch form-check-md">
                            <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsSlide" @click="onChangeIsSlide">
                            <label v-if="inputIsSlide" class="form-check-label" for="profilePublic">여러개의 메인 이미지 보이기 </label>
                            <label v-else class="form-check-label" for="profilePublic">1개 메인 이미지만 보이기</label>
                        </div>				

                    </div>
                </div>
			</div>
		</div>

		<div class="row mb-3">
			<div class="col-12 d-sm-flex justify-content-between align-items-center">
				<!-- <h1 class="h4 mb-2 mb-sm-0 me-3">메인 이미지 1개 </h1> -->
				<a class="btn" :class="inputIsSlide ? 'btn-secondary-soft':'btn-success'" >메인 이미지 1개 </a>
				<div>
					<router-link :to="addSingleLink" class="btn btn-primary mb-0">Add</router-link>
				</div>
			</div>
		</div>

		<!-- Card START -->
		<div class="card bg-transparent border">

			<!-- Card body START -->
			<!-- <div class="card-body" :class="inputIsSlide ? '':'bg-color-gray'"> -->
			<div class="card-body" >
				<!-- Course table START -->
				<div class="table-responsive border-0 rounded-3">
					<!-- Table START -->
					<table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
						<!-- Table head -->
						<thead>
							<tr>
								<th scope="col" class="border-0 rounded-start">Num</th>
								<th scope="col" class="border-0">이미지</th>
								<th scope="col" class="border-0">설명</th>
								<th scope="col" class="border-0">보임/숨김</th>
								<th scope="col" class="border-0">Up/Down</th>
                                <th scope="col" class="border-0">삭제</th>
								<th scope="col" class="border-0 rounded-end">Update</th>
							</tr>
						</thead>
						
						<!-- Table body START -->
						<tbody>
							<admin-mainslide-list-component v-for="item in itemSingleListCompu"
								:key = "item.id"
								:id ="item.id"
								:num ="item.seq"
								:category ="item.category"
								:image_path="item.image_path"
								:title="item.title"
								:link="item.link"
								:description="item.description"
								:is_display="item.is_display"
								:seq="item.seq"
								:min="item.min"
								:max="item.max"	

								@emit-refresh-list="emitRefreshList"
							/>
							

						</tbody>
						<!-- Table body END -->
					</table>
					<!-- Table END -->
				</div>
				<!-- Course table END -->
			</div>
			<!-- Card body END -->

		</div>

		<div class="row mt-3 mb-3">
			<div class="col-12 d-sm-flex justify-content-between align-items-center">
				<!-- <h1 class="h4 mb-2 mb-sm-0 me-3">메인 여러개 이미지 </h1> -->
				<a class="btn" :class="inputIsSlide ? 'btn-success':'btn-secondary-soft'" >메인 여러개 이미지 </a>
				<div>
					<router-link :to="addMultipleLink" class="btn btn-primary mb-0">Add</router-link>
				</div>
			</div>
		</div>

		<div class="card bg-transparent border">

			<!-- Card body START -->
			<!-- <div class="card-body" :class="inputIsSlide ? 'bg-color-gray':''"> -->
			<div class="card-body" >
				<!-- Course table START -->
				<div class="table-responsive border-0 rounded-3">
					<!-- Table START -->
					<table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
						<!-- Table head -->
						<thead>
							<tr>
								<th scope="col" class="border-0 rounded-start">Num</th>
								<th scope="col" class="border-0">이미지</th>
								<th scope="col" class="border-0" width="40%">설명</th>
								<th scope="col" class="border-0">보임/숨김</th>
								<th scope="col" class="border-0">Up/Down</th>
								<th scope="col" class="border-0">삭제</th>
								<th scope="col" class="border-0 rounded-end">Update</th>
							</tr>
						</thead>
						
						<!-- Table body START -->
						<tbody>
							<admin-mainslide-list-component v-for="item in itemMultipleListCompu"
								:key = "item.id"
								:id ="item.id"
								:num ="item.seq"
								:category ="item.category"
                                :image_path="item.image_path"
								:title="item.title"
								:link="item.link"
								:description="item.description"
								:is_display="item.is_display"
								:seq="item.seq"
								:min="item.min"
								:max="item.max"	

								@emit-refresh-list="emitRefreshList"		
							/>							

						</tbody>
						<!-- Table body END -->
					</table>
					<!-- Table END -->
				</div>
				<!-- Course table END -->
			</div>
			<!-- Card body END -->

		</div>
		<!-- Card END -->
	</div>
	<!-- Page main content END -->

    <!-- Page content END -->
    
    </div>
</template>

<script>
var filename__="[pages > admin > mainslide > AdminMainSlideList.vue] "

import axios from 'axios';

import AdminMainSlideListComponent from '../../../components/admin/mainslide/AdminMainSlideListComponent.vue' ;


export default {
    components: {
        "admin-mainslide-list-component": AdminMainSlideListComponent,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            

			inputIsSlide : null, 
            itemSingleList : [],
            itemMultipleList : [],
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    computed: {
        addSingleLink(){
            return '/admin/mainslide/create/single'; 
        },
        addMultipleLink(){
            return '/admin/mainslide/create/multiple'; 
        },
        itemSingleListCompu(){
            return this.itemSingleList ;
        },
        itemMultipleListCompu(){
            return this.itemMultipleList ;
        }
    },    
    created(){
		this.getItem();
        this.getSingleList();
        this.getMultipleList();
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        isAdmin(){
            return this.$store.getters['auth/isAdmin'] ;
        },
		emitRefreshList(_category){
			if(_category ==='single'){
				this.getSingleList();
			} else {
				this.getMultipleList();
			}
			
		},
        onChangeIsSlide(){
			// 메인슬라이드, single, multiple 여부 
            this.inputIsSlide = !this.inputIsSlide ;
			this.sendChangeIsSlideForm()
        },
        async getItem(){
            let funcname__ ="getItem()" ;
            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/setting/mainslide',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputIsSlide = data.response ;
				console.log(filename__+''+funcname__+" inputIsSlide:", this.inputIsSlide);
				
            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },

        // 전송하기  
        async sendChangeIsSlideForm(){
            let funcname__ ="sendChangeIsSlideForm() " ;
            let dataForm = { 
                'is_active':this.inputIsSlide,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/setting/mainslide/active/update',
                    headers: { 
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                    
                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.'; 

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                            }, 700);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                                
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;


        },

        async getSingleList(){
			let funcname__ ="getSingleList()" ;

            this.itemSingleList = [] ;
			this.isLoading = true ;   


			let serverData = await axios({
				method: 'get',
				url: '/api/mainslide/all/admin/'+'single',
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},               

			}).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){

				let tmpList = data.response.data ;
				let list = [];


				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        category: item.category, 
                        image_path:item.image_path,
                        title: item.title, 
                        link: item.link, 
                        description: item.description, 
                        is_display: item.is_display, 
                        seq:item.seq,
                        min: item.is_min ,
                        max: item.is_max,
                    });
				}

				console.log(filename__+''+funcname__+" list:", list);
				this.itemSingleList = list;
                console.log(filename__+''+funcname__+" itemSingleList:", this.itemSingleList);

			} else {
				console.log(filename__+''+funcname__+' err:');

				this.dialogTitle ="실패" ;                    
				this.error = serverData.data.error_description;  
			}

			this.isLoading = false ;            
		},

        async getMultipleList(){
			let funcname__ ="getMultipleList()" ;

            this.itemMultipleList = [] ; 
			this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

			let serverData = await axios({
				method: 'get',
				url: '/api/mainslide/all/admin/'+'multiple',
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},               

			}).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){

				let tmpList = data.response.data ;
				let list = [];


				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        category: item.category, 
                        image_path:item.image_path,
                        title: item.title, 
                        link: item.link, 
                        description: item.description, 
                        is_display: item.is_display, 
                        seq:item.seq,
                        min: item.is_min ,
                        max: item.is_max,
                    });
				}

				console.log(filename__+''+funcname__+" list:", list);
				this.itemMultipleList = list;

			} else {
				console.log(filename__+''+funcname__+' err:');

				this.dialogTitle ="실패" ;                    
				this.error = serverData.data.error_description;  
			}

			this.isLoading = false ;            
		},



    }
}
</script>

<style scoped>

</style>