<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>


<!-- =======================
Page Banner START -->
<section class="bg-light py-5">
	<div class="container">
		<div class="row g-4 g-md-5 position-relative">
			<!-- SVG decoration -->
			<figure class="position-absolute top-0 start-0 d-none d-sm-block">
				<svg width="22px" height="22px" viewBox="0 0 22 22">
					<polygon class="fill-purple" points="22,8.3 13.7,8.3 13.7,0 8.3,0 8.3,8.3 0,8.3 0,13.7 8.3,13.7 8.3,22 13.7,22 13.7,13.7 22,13.7 "></polygon>
				</svg>
			</figure>

			<!-- Title and Search -->
			<div class="col-lg-10 mx-auto text-center position-relative">
				<!-- SVG decoration -->
				<figure class="position-absolute top-50 end-0 translate-middle-y">
					<svg width="27px" height="27px">
						<path class="fill-orange" d="M13.122,5.946 L17.679,-0.001 L17.404,7.528 L24.661,5.946 L19.683,11.533 L26.244,15.056 L18.891,16.089 L21.686,23.068 L15.400,19.062 L13.122,26.232 L10.843,19.062 L4.557,23.068 L7.352,16.089 L-0.000,15.056 L6.561,11.533 L1.582,5.946 L8.839,7.528 L8.565,-0.001 L13.122,5.946 Z"></path>
					</svg>
				</figure>
				<!-- Title -->
				<h1 class="display-6">무엇을 도와드릴까요?</h1>
				<!-- Search bar -->
				<!-- <div class="col-lg-8 mx-auto text-center mt-4">
					<form class="bg-body shadow rounded p-2">
						<div class="input-group">
							<input class="form-control border-0 me-1" type="text" placeholder="질문하기...">
							<button type="button" class="btn btn-blue mb-0 rounded">전송</button>
						</div>
					</form>
				</div> -->
			</div>

			<!-- Category START -->

            <!-- Category END -->
		</div>
	</div>
</section>
<!-- =======================
Page Banner END -->

<!-- =======================
Page content START -->
<section class="pt-5 pb-0 pb-lg-0">
	<div class="container">
        <faq-frequent-panel-component />
	</div>
</section>
<!-- =======================
Page content END -->
<section >
        <div class="container">
            <div class="row g-1 g-lg-0 align-items-center">
    
                <div class="col-md-6 align-items-center text-center">
                    <!-- Image -->
                    <img src="assets/images/element/contact.svg" class="h-300px" alt="">
                </div>
    
                <!-- Contact form START -->
                <div class="col-md-6">
                    <!-- Title -->
                    <h2 class="mt-4 mt-md-0">개별 질의사항을 남겨주세요</h2>
                    <p>개별 질의가 있으시면 신속히 대응해드리겠습니다. </p>
                    <p>개별 질의 확인은 My Dashboard > My Faq 에서 확인하시면 됩니다.</p>
                    
                    <form @submit.prevent="sendForm" >
                        <!-- Message -->
                        <div class="mb-4 bg-light-input">
                            <!-- <label for="textareaBox" class="form-label">개별 질의 *</label> -->
                            <textarea class="form-control" id="textareaBox" rows="4" placeholder="개별 질의 내용*" v-model.trim="inputContent"></textarea>
                        </div>
                        <!-- Button -->
                        <div class="d-grid">
                            <button v-if="isAuth" type="submit" class="btn btn-lg btn-primary mb-0" >질의 보내기</button>
                            <button v-else type="button" class="btn btn-lg btn-primary mb-0" disabled >로그인 후 질의 보내기</button>
                        </div>	
                    </form>
                </div>
                <!-- Contact form END -->
            </div>
        </div>
    </section>

</main>

    <hr/>
    </div>
</template>

<script>
var filename__ = "[pages > help > Faq.vue]" ;
import axios from 'axios';

import FaqFrequentPanelComponent from '../../components/faq/FaqFrequentPanelComponent.vue';

export default {
    components: {
        "faq-frequent-panel-component": FaqFrequentPanelComponent
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,
            dialogTitle: null,

            inputContent: null, 
        }
    },
    computed: {
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
    },
    created(){
		
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },

        // 프로필 정보 업데이트
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = {
                'category' : 'faq',
                'content': this.inputContent,
            } ;

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/faq/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`,
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.inputContent = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '질의 발송에 성공했습니다. 답변은 My Dashboard > My Faq 에서 확인바랍니다.';

                    } else {
                        console.log(filename__+''+funcname__+' return err:', serverData.data.msg);
                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.msg;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error;
            }

            this.isLoading = false ;

        },

    }
}
</script>

<style scoped>

</style>
