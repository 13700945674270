<template>
    <tr>
        <!-- Course item -->
        <td class="text-center text-sm-start">{{num}}</td>   
        <td>
            <div class="badge bg-danger bg-opacity-10 text-danger">{{categorystr}}</div>
        </td>

        <td>
            <div class="d-flex align-items-center">
                <!-- Image -->
                <div v-if="!is_text" class="w-60px">
                    <router-link :to="detailLink" >
                        <img v-if="image" :src="image" class="rounded" alt="">
                        <!-- <img v-else src="/assets/images/courses/4by3/08.jpg" class="rounded" alt=""> -->
                        <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" alt="">
                    </router-link>

                </div>
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6>
                        <router-link :to="detailLink">
                            <span v-if="is_display">{{title}}</span>
                            <span v-else class="text-decoration-line-through "><i class="fas fa-eye-slash me-3"></i>{{title}}</span>
                        </router-link>
                    </h6>
                    <!-- Info -->
                    <div class="d-sm-flex">
                        <p class="h6 fw-light mb-0 small me-3"><i class="fas fa-table text-orange me-2"></i>{{lecturecount}} topics</p>
                        <!-- <p class="h6 fw-light mb-0 small"><i class="fas fa-check-circle text-success me-2"></i>6 Completed</p> -->
                    </div>
                </div>
            </div>
        </td>
        <!-- <td>
            <div v-if="onoff" class="badge bg-success bg-opacity-10 text-success">Online</div>
            <div v-else class="badge bg-danger bg-opacity-10 text-danger">Offline</div>
        </td> -->
        <!-- <td>
            <div v-if="is_price" class="badge bg-danger bg-opacity-10 text-danger">{{price}}</div>
            <div v-else class="badge bg-success bg-opacity-10 text-success">free</div>
        </td> -->
        <!-- Price item -->
        <!-- Action item -->
        <td>
            <!-- <a href="#" class="btn btn-sm btn-success-soft btn-round me-1 mb-0"><i class="far fa-fw fa-edit"></i></a> -->
            <button v-if="level == 0" class="btn btn-sm btn-success-soft mb-0">All</button>
            <button v-if="level == 1"  class="btn btn-sm btn-primary-soft mb-0">초급</button>
            <button v-if="level == 2" class="btn btn-sm btn-orange-soft mb-0">중급</button>
            <button v-if="level == 3" class="btn btn-sm btn-purple-soft mb-0">고급</button>
        </td>
        <td width="100"><small>{{update}}</small></td>
        <td>
            <router-link :to="detailLink" class="btn btn-sm btn-info-soft mb-0" >View</router-link>
        </td>


    </tr>

</template>

<script>
export default {
    components: {},
    props: [
        'id','num','create_id','create_name','create_email','create_photo','date','update',
        'title','category','categorystr','level','image','is_display',
        'is_text',
        'page', 'search'
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            return '/companydash/blog/'+this.id
        },

    },
    methods: {

    },
    
}
</script>