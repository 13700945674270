<template>
<section class="bg-light">
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Authenticating..." fixed>
        <p>Authentiacting...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="container">
        <div class="row g-4 g-lg-5 align-items-center">
            <div class="col-xl-7 order-2 order-xl-1">
                <div class="row mt-0 mt-xl-5">
                    <!-- Review -->
                    <div class="col-md-7 position-relative mb-0 mt-0 mt-md-5">
                        <!-- SVG -->
                        <figure class="fill-danger opacity-2 position-absolute top-0 start-0 translate-middle mb-3">
                            <svg width="211px" height="211px">
                                <path d="M210.030,105.011 C210.030,163.014 163.010,210.029 105.012,210.029 C47.013,210.029 -0.005,163.014 -0.005,105.011 C-0.005,47.015 47.013,-0.004 105.012,-0.004 C163.010,-0.004 210.030,47.015 210.030,105.011 Z"></path>
                            </svg>
                        </figure>

                        <div class="bg-body shadow text-center p-4 rounded-3 position-relative mb-5 mb-md-0">
                            <!-- Avatar -->
                            <div v-if="inputReviewFirst" class="avatar avatar-xl mb-3">
                                <img v-if="inputReviewFirst.create_photo" class="avatar-img rounded-circle" :src="inputReviewFirst.create_photo" alt="avatar">
                                <img v-else class="avatar-img rounded-circle" src="assets/images/avatar/user2.png" alt="avatar">
                            </div>
                            <!-- Content -->
                            <blockquote>
                                <p>
                                    <span class="me-1 small"><i class="fas fa-quote-left"></i></span>
                                    {{ inputReviewFirst? inputReviewFirst.description : '' }}
                                    <span class="ms-1 small"><i class="fas fa-quote-right"></i></span>
                                </p>
                            </blockquote>
                            <!-- Rating -->
                            <ul v-if="inputReviewFirst" class="list-inline mb-2">
                                <li v-if="inputReviewFirst.review >= 1" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>

                                <li v-if="inputReviewFirst.review >= 2" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                                <li v-if="inputReviewFirst.review >= 3" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                                <li v-if="inputReviewFirst.review >= 4" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                                <li v-if="inputReviewFirst.review == 5" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                            </ul>
                            <!-- Info -->
                            <h6 class="mb-0">{{ inputReviewFirst? inputReviewFirst.create_name : '' }}</h6>
                        </div>
                    </div>

                    <!-- Mentor list -->
                    <div class="col-md-5 mt-5 mb-7 mt-md-0 d-none d-md-block">
                        <div class="bg-body shadow p-4 rounded-3 d-inline-block position-relative">
                            <!-- Icon -->
                            <div class="icon-lg bg-warning rounded-circle position-absolute top-0 start-100 translate-middle">
                                <i class="bi bi-shield-fill-check text-dark"></i>
                            </div>
                            <!-- Title -->
                            <h6 class="mb-3">파트너</h6>
                            <!-- Mentor Item -->
                            <div class="d-flex align-items-center mb-3" v-for="item in itemList" :key="item.id">
                                <!-- Avatar -->
                                <div class="avatar avatar-sm">
                                    <img v-if="item.photo" class="avatar-img rounded-1" :src="item.photo" alt="avatar">
                                </div>
                                <!-- Info -->
                                <div class="ms-2">
                                    <h6 class="mb-0">{{item.name}}</h6>
                                    <p class="mb-0 small">{{ item.major }}</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div> <!-- Row END -->

                <div class="row mt-5 mt-xl-0">
                    <!-- Rating -->
                    <div class="col-7 mt-0 mt-xl-5 text-end position-relative z-index-1 d-none d-md-block">
                        <!-- SVG -->
                        <figure	class="fill-danger position-absolute top-0 start-50 mt-n7 ms-6 ps-3 pt-2 z-index-n1 d-none d-lg-block">
                            <svg enable-background="new 0 0 160.7 159.8" height="180px">
                                <path d="m153.2 114.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" />
                                <path d="m116.4 114.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <path d="m134.8 114.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <path d="m135.1 96.9c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <path d="m153.5 96.9c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" />
                                <path d="m98.3 96.9c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <ellipse cx="116.7" cy="99.1" rx="2.1" ry="2.2" />
                                <path d="m153.2 149.8c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.3 0.9-2.2 2.1-2.2z" />
                                <path d="m135.1 132.2c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2 0-1.3 0.9-2.2 2.1-2.2z" />
                                <path d="m153.5 132.2c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.3 0.9-2.2 2.1-2.2z" />
                                <path d="m80.2 79.3c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2z" />
                                <path d="m117 79.3c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <path d="m98.6 79.3c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <path d="m135.4 79.3c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <path d="m153.8 79.3c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <path d="m80.6 61.7c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" />
                                <ellipse cx="98.9" cy="63.9" rx="2.1" ry="2.2" />
                                <path d="m117.3 61.7c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <ellipse cx="62.2" cy="63.9" rx="2.1" ry="2.2" />
                                <ellipse cx="154.1" cy="63.9" rx="2.1" ry="2.2" />
                                <path d="m135.7 61.7c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <path d="m154.4 44.1c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <path d="m80.9 44.1c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" />
                                <path d="m44.1 44.1c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" />
                                <path d="m99.2 44.1c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2z" />
                                <ellipse cx="117.6" cy="46.3" rx="2.1" ry="2.2" />
                                <path d="m136 44.1c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <path d="m62.5 44.1c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" />
                                <path d="m154.7 26.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <path d="m62.8 26.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" />
                                <ellipse cx="136.3" cy="28.6" rx="2.1" ry="2.2" />
                                <path d="m99.6 26.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" />
                                <path d="m117.9 26.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2z" />
                                <path d="m81.2 26.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2-0.1-1.2 0.9-2.2 2.1-2.2z" />
                                <path d="m26 26.5c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2c-1.2 0-2.1-1-2.1-2.2s0.9-2.2 2.1-2.2z" />
                                <ellipse cx="44.4" cy="28.6" rx="2.1" ry="2.2" />
                                <path d="m136.6 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2 0.1 1.2-0.9 2.2-2.1 2.2z" />
                                <path d="m155 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2 0.1 1.2-0.9 2.2-2.1 2.2z" />
                                <path d="m26.3 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2s-0.9 2.2-2.1 2.2z" />
                                <path d="m81.5 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2s-0.9 2.2-2.1 2.2z" />
                                <path d="m63.1 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2s-0.9 2.2-2.1 2.2z" />
                                <path d="m44.7 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2s-0.9 2.2-2.1 2.2z" />
                                <path d="m118.2 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2 0.1 1.2-0.9 2.2-2.1 2.2z" />
                                <path d="m7.9 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2 0.1 1.2-0.9 2.2-2.1 2.2z" />
                                <path d="m99.9 13.2c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2s-1 2.2-2.1 2.2z" />
                            </svg>
                        </figure>

                        <div class="p-3 bg-primary d-inline-block rounded-4 shadow-lg text-center" style="background:url(assets/images/pattern/02.png) no-repeat center center; background-size:cover;">
                            <!-- Info -->
                            <h5 class="text-white mb-0">{{inputAvg}}/5.0</h5>
                            <!-- Rating -->
                            <ul class="list-inline mb-2">

                                <li v-if="inputAvg >= 1" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>

                                <li v-if="inputAvg >= 2" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                                <li v-if="inputAvg >= 3" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                                <li v-if="inputAvg >= 4" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                                <li v-if="inputAvg == 5" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                            </ul>
                            <p class="text-white mb-0">총 {{inputCount}}건</p>
                        </div>
                    </div>

                    <!-- Review -->
                    <div class="col-md-5 mt-n6 mb-0 mb-md-5">
                        <div class="bg-body shadow text-center p-4 rounded-3">
                            <!-- Avatar -->
                            <div v-if="inputReviewSecond" class="avatar avatar-xl mb-3">
                                <img v-if="inputReviewSecond.create_photo" class="avatar-img rounded-circle" :src="inputReviewSecond.create_photo" alt="avatar">
                                <img v-else class="avatar-img rounded-circle" src="assets/images/avatar/user2.png" alt="avatar">

                            </div>
                            <!-- Content -->
                            <blockquote>
                                <p>
                                    <span class="me-1 small"><i class="fas fa-quote-left"></i></span>
                                    {{ inputReviewSecond? inputReviewSecond.description : '' }}
                                    <span class="ms-1 small"><i class="fas fa-quote-right"></i></span>
                                </p>
                            </blockquote>
                            <!-- Rating -->
                            <ul v-if="inputReviewSecond" class="list-inline mb-2">
                                <li v-if="inputReviewSecond.review >= 1" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>

                                <li v-if="inputReviewSecond.review >= 2" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                                <li v-if="inputReviewSecond.review >= 3" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                                <li v-if="inputReviewSecond.review >= 4" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                                <li v-if="inputReviewSecond.review == 5" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                            </ul>
                            <!-- Info -->
                            <h6 class="mb-0">{{ inputReviewSecond? inputReviewSecond.create_name : '' }}</h6>
                        </div>
                    </div>
                </div> <!-- Row END -->
            </div>
            <div class="col-xl-5 order-1 text-center text-xl-start">
                <!-- Title -->
                <h2 class="fs-1">워커스 카페의 수강생으로부터의 감동의 피드백</h2>
                <p>수강생으로부터 받은 감동의 글은 새로운 수강생들에게 동기를 부여합니다. 에듀쿱의 친절한 강사진과 섬세한 강의 스킬은 애매했던 진로를 명확하게 해줍니다.</p>
                <!-- <a href="#" class="btn btn-primary mb-0">리뷰 보기</a> -->
            </div>
        </div> <!-- Row END -->
    </div>
</section>

</template>

<script>
// compu__ return__
var filename__="[components > main > MainReviewPanelComponent.vue] "

import axios from 'axios';

export default {
    components: {
    },
    props: [],
    data() {
        return {
            isLoading: false,
            isApplying : false, 
            error: null,     
            dialogTitle: null,    
            

            inputIsMainReview : null, // 메인 리뷰 로딩 여부 

            inputReviewFirst : null,
            inputReviewSecond : null, 
            inputCount : null, 
            inputAvg : null, 

            itemList: [],
        }
    },
    created(){
        this.getReview();  // review 2개만 
        this.getList();   // company 4개만 

    },
    computed: {
        // isMainReview(){
        //     return this.$store.getters['coursereview/is_load'];
        // },

    },
    methods: {
        handleError(){
            this.error = null;
        }, 

        async getReview(){
            let funcname__ ="getReview()" ;

            // this.isLoading = true ;   // 
            // await this.$store.dispatch('coursereview/Loading'); //==> store/modules/coursereview/actions.js       

            let serverData = await axios({
                method: 'get',
                url: '/api/comment/review/main/user',

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
 
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);   //~~log 

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let reviews = data.response.review ;

                this.inputReviewFirst = reviews[0] ;
                this.inputReviewSecond = reviews[1] ;
                this.inputCount =  data.response.count ;
                this.inputAvg = data.response.avg ;

                // console.log(filename__+''+funcname__+" inputReviewFirst:", this.inputReviewFirst); //~~log
                // console.log(filename__+''+funcname__+" inputReviewSecond:", this.inputReviewSecond); //~~log
                // console.log(filename__+''+funcname__+" inputCount:", this.inputCount); //~~log
                // console.log(filename__+''+funcname__+" inputAvg:", this.inputAvg); //~~log

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ; 
     
        },
        async getList(){
            let funcname__ ="getList()" ;

            this.isLoading = true ;   
            
            let serverData = await axios({
                method: 'get',
                url: '/api/company/main/user',

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);  // ~~log

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list= [] ;

                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id,
                        name: item.name,
                        photo: item.photo,
                        company : item.company,
                        major : item.major,

                    })
                }

                this.itemList = list;
                // console.log(filename__+''+funcname__+" itemList:", this.itemList);  // ~~log

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }
            this.isLoading = false ;            
        },

    }
}

</script>

<style scoped>

</style>