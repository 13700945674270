<template>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- 추가 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idAddCompu" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h1 class="modal-title fs-5" :id="idAddCompu">Add a Course</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 코스를 추가하시겠습니까?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="addForm" class="btn btn-primary" data-bs-dismiss="modal">Add</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

    <tr>
        <td class="text-center text-sm-start">{{num}}</td>       
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="w-60px">
                    <router-link :to="detailLink" >
                        <img v-if="image" :src="image" class="rounded" alt="">
                        <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" alt="">
                        <!-- <img src="/assets/images/element/gallery.svg" class="h-50px" alt=""></img> -->
                    </router-link>
                </div>
                <!-- Title -->
                <h6 class="table-responsive-title mb-0 ms-2">	
                    <router-link :to="detailLink" class="stretched-link">
                        <span v-if="is_display">{{title}}</span>
                        <span v-else class="text-decoration-line-through "><i class="fas fa-eye-slash me-3"></i>{{title}}</span>
                    </router-link>
                </h6>
            </div>
        </td>
        <td> 
            <span class="badge bg-success bg-opacity-15 text-success">{{categorystr}}</span>
        </td>
        <td>
            <div class="d-flex align-items-center mb-3">
                <!-- Avatar -->
                <div class="avatar avatar-xs flex-shrink-0">
                    <img v-if="company_photo" class="avatar-img rounded-circle" :src="company_photo" alt="avatar">
                    <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/09.jpg" alt="avatar">
                </div>
                <!-- Info -->
                <div class="ms-2">
                    <h6 class="mb-0 fw-light">{{company_name}}</h6>
                    <small>{{company_email}}</small>
                </div>
            </div>
        </td>

        <!-- <td>             
            <span v-if="onoff" class="badge text-bg-orange">Online</span>
            <span v-else  class="badge text-bg-primary">Offline</span> 
        </td> -->
        <td>             
            <span v-if="level == 0" class="badge text-bg-orange">All level</span>
            <span v-else-if="level == 1"  class="badge text-bg-primary">Beginner</span> 
            <span v-else-if="level == 2" class="badge text-bg-purple">Intermediate</span>
            <span v-else-if="level == 3" class="badge text-bg-success">Advanced</span>
        </td>
        
        <!-- <td>
            <span v-if="is_price" >{{price}}</span>
            <span v-else class="badge text-bg-success">Free</span>
        </td> -->

        <td>
            <ul class="list-inline mb-0">
                <li v-if="reviewavg >= 1" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <li v-if="reviewavg >= 2" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <li v-if="reviewavg >= 3" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <li v-if="reviewavg >= 4" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <li v-if="reviewavg == 5" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>                
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <!-- <li class="list-inline-item ms-2 h6 fw-light">{{reviewavg}}</li> -->
            </ul>

        </td>
        <td>
            <span v-if="enroll_count > 0" class="badge text-bg-primary">{{ enroll_count }}</span>
        </td>
        <td>{{update}}</td>
        <td>
            <button type="button" class="btn btn-sm btn-outline-danger mb-0" data-bs-toggle="modal" :data-bs-target="idAddShapCompu">
                <i class="bi bi-plus-circle"></i>
            </button>
        </td>
    </tr>


</template>

<script>
var filename__="[components > admin > courserecommend > AdminCourseRecommendAddListComponent.vue]" ;

import axios from 'axios';

export default {
    components: {},
    props: [
        'id','num','create_id','create_name','create_email','create_photo','date','update',
        'company_name','company_email','company_photo',
        'categorystr',
        'title','level','is_price','price','coursetime','lecturecount','image','is_display', 'onoff','reviewavg',
        'page', 'search', 'is_active',
        'enroll_count',
        'recommendcategory_id','recommendcategory'
    ],
    data() {
        return {
            isLoading: false,
			isApplyging: false,
            error: null,     
            dialogTitle: null,    
            
        }
    },
    
    computed: {        
        detailLink(){
            return '/admin/course/'+this.id
        },
        timeFormat(){
            let hour = parseInt(this.coursetime/60) ;
            let minute = this.coursetime - hour * 60 ;
            if(hour == 0){
                return this.coursetime +'분'                
            } else if(minute == 0){
                return hour+'시간'
            } else {
                return hour + '시간 '+minute + '분' ;                
            }
        },
        idAddCompu(){
            return 'addModal'+this.id
        },
        idAddShapCompu(){
            return '#addModal'+this.id
        },


    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        // 정보 업데이트  
        async addForm(){
            let funcname__ ="addForm()" ;

            let dataForm = { 
                'parent_category': this.recommendcategory,
                'category_id': this.recommendcategory_id,
                'content_id' : this.id, 
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/course/recommend/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                                                
                        this.dialogTitle ='Success' ;
                        this.error = '추가에 성공했습니다.';    
                         

                    } else if(serverData.data.status_code == 500){
                        
                        this.dialogTitle ='Fail' ;
                        this.error = '이미 추가된 코스입니다.';     


                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    },
    
}
</script>