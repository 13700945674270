<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

	<main>



		
<!-- =======================
Page Banner START -->
<section class="bg-blue align-items-center d-flex" style="background:url(/assets/images/pattern/04.png) no-repeat center center; background-size:cover;">
	<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<!-- Title -->
				<h1 class="text-white">{{inputCategoryTitle}}</h1>
				<!-- Breadcrumb -->
				<div class="d-flex justify-content-center">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb breadcrumb-dark breadcrumb-dots mb-0">
							<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
							<li class="breadcrumb-item"><a >정보창고</a></li>
							<li class="breadcrumb-item active" aria-current="page">{{inputCategoryTitle }}</li>
						</ol>
					</nav>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- =======================
Page Banner END -->

<!-- =======================
Page content START -->
<section class="py-5">
<div class="container">
	<div class="row">
		<!-- Main content START -->
		<div class="col-lg-8 col-xl-9">

			<!-- Search option START -->
			<div class="row mb-4 align-items-center">

				<!-- Search bar -->
				<div class="col-xl-12 ">
					<div class="card card-body shadow">
						<form @submit.prevent="onChangeSearch" class="border rounded">
							<div class="input-group input-borderless">
								<input class="form-control me-1" type="search" placeholder="정보 찾기" v-model.trim="inputSearch">
								<button type="submit" class="btn btn-primary mb-0 rounded z-index-1"><i class="fas fa-search"></i></button>
							</div>
						</form>
					</div>

				</div>

				<!-- Select option -->
				<!-- <div class="col-xl-3 mt-3 mt-xl-0">
					<form class="border rounded p-2 input-borderless">
						<select class="form-select form-select-sm js-choice border-0" aria-label=".form-select-sm">
							<option value="">전체 보기</option>
							<option>최근 검색</option>
							<option>최강 인기</option>
							<option>최다 비율</option>
						</select>
					</form>
				</div> -->

				<!-- Content -->
				<!-- <div class="col-12 col-xl-3 d-flex justify-content-between align-items-center mt-3 mt-xl-0">
					<button class="btn btn-primary mb-0 d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
						<i class="fas fa-sliders-h me-1"></i> 필터링 하기
					</button>
					<p class="mb-0 text-end">총 30개 중 9개</p>
				</div> -->

			</div>
			<!-- Search option END -->

			<!-- Course Grid START -->
			<div class="row g-4">

				<blog-list-component v-for="item in itemList"
					:key = "item.id"
					:coursecategory="inputCourseCategoryId"
					:category_title="inputCategoryTitle"
					:id ="item.id"
					:num ="item.num"

					:title ="item.title"
					:subtitle="item.subtitle"
					
					:level ="item.level"
					:is_price ="item.is_price"
					:price ="item.price"
					:coursetime ="item.coursetime"
					:lecturecount ="item.lecturecount"

					:image ="item.image"
					:is_text = "item.is_text"
					
					:date ="item.date"
					:update ="item.update"
					:onoff="item.onoff"
					:reviewavg="item.reviewavg"
					:categorystr="item.categorystr"

					:company_id="item.company_id"
					:is_student="item.is_student"
					:is_wishlist="item.is_wishlist"

					:create_id = "item.create_id" 
					:create_name = "item.create_name" 
					:create_email = "item.create_email" 
					:create_photo = "item.create_photo" 

					:listpath="listpathCompu"
					:page="page" 
					:search="inputSearch" 
					
					/>
								

				<!-- Card item START -->
<!-- 				
				<div class="col-sm-6 col-xl-4">
					<div class="card shadow h-100">
						<img src="/assets/images/courses/4by3/02.jpg" class="card-img-top" alt="course image">
						<div class="card-body pb-0">
							<div class="d-flex justify-content-between mb-2">
								<a href="#" class="badge bg-success bg-opacity-10 text-success">Beginner</a>
								<a href="#" class="text-danger"><i class="fas fa-heart"></i></a>
							</div>
							<h5 class="card-title"><router-link to="/totalcourse/detail">그래픽 디자인 마스터 교실</router-link></h5>
							<p class="mb-2 text-truncate-2">그래픽 디자이너는 무엇이 필요할까. 이 강좌에서 그 해결책을 찾을 수 있습니다.</p>
							<ul class="list-inline mb-0">
								<li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
								<li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
								<li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
								<li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
								<li class="list-inline-item me-0 small"><i class="fas fa-star-half-alt text-warning"></i></li>
								<li class="list-inline-item ms-2 h6 fw-light mb-0">4.5/5.0</li>
							</ul>
						</div>
						<div class="card-footer pt-0 pb-3">
							<hr>
							<div class="d-flex justify-content-between">
								<span class="h6 fw-light mb-0"><i class="far fa-clock text-danger me-2"></i>20시간</span>
								<span class="h6 fw-light mb-0"><i class="fas fa-table text-orange me-2"></i>6회 강의</span>
							</div>
						</div>
					</div>
				</div>
 -->


			</div>
			<!-- Course Grid END -->

			<!-- Pagination START -->
			<div class="col-12 mt-4">
				<div v-if="itemList.length > 0"> 
					<user-pagination :pagination="paginationCompu" @paging="pagingSend" ></user-pagination>
				</div>

				<!-- <nav class="mt-4 d-flex justify-content-center" aria-label="navigation">
					<ul class="pagination pagination-primary-soft d-inline-block d-md-flex rounded mb-0">
						<li class="page-item mb-0"><a class="page-link" href="#" tabindex="-1"><i class="fas fa-angle-double-left"></i></a></li>
						<li class="page-item mb-0"><a class="page-link" href="#">1</a></li>
						<li class="page-item mb-0 active" aria-current="page"><a class="page-link" href="#">2</a></li>
						<li class="page-item mb-0"><a class="page-link" href="#">..</a></li>
						<li class="page-item mb-0"><a class="page-link" href="#">6</a></li>
						<li class="page-item mb-0"><a class="page-link" href="#"><i class="fas fa-angle-double-right"></i></a></li>
					</ul>
				</nav> -->
			</div>
			<!-- Pagination END -->
		</div>
		<!-- Main content END -->

		<!-- Right sidebar START -->
		<div class="col-lg-4 col-xl-3 ">
			<!-- Responsive offcanvas body START -->
			<div class="offcanvas-lg offcanvas-end" tabindex="-1" id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
				<div class="offcanvas-header bg-light">
					<h5 class="offcanvas-title" id="offcanvasNavbarLabel">Advance Filter</h5>
					<button  type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close"></button>
				</div>
				<div class="offcanvas-body p-3 p-lg-0">
		
							<!-- Skill level START -->
							<div class="card card-body shadow p-4 mb-4">
								<!-- Title -->
								<h4 class="mb-3">Skill level</h4>
								<ul class="list-inline mb-0">
									<!-- Item -->
									<!-- <li class="list-inline-item mb-2">
										<input type="checkbox" class="btn-check" id="btn-check-12" @change="onChangeLevel(-1)" >
										<label class="btn btn-light btn-primary-soft-check" for="btn-check-12" >모두보기</label>
									</li> -->
									<li class="list-inline-item mb-2">
										<input type="checkbox" class="btn-check" id="btn-check-12" @change="onChangeLevel(0)" >
										<label class="btn btn-light btn-primary-soft-check" for="btn-check-12" >All level</label>
									</li>
									<!-- Item -->
									<li class="list-inline-item mb-2">
										<input type="checkbox" class="btn-check" id="btn-check-9" @change="onChangeLevel(1)">
										<label class="btn btn-light btn-primary-soft-check" for="btn-check-9" >Beginner</label>
									</li>
									<!-- Item -->
									<li class="list-inline-item mb-2">
										<input type="checkbox" class="btn-check" id="btn-check-10" @change="onChangeLevel(2)">
										<label class="btn btn-light btn-primary-soft-check" for="btn-check-10" >Intermediate</label>
									</li>
									<!-- Item -->
									<li class="list-inline-item mb-2">
										<input type="checkbox" class="btn-check" id="btn-check-11" @change="onChangeLevel(3)">
										<label class="btn btn-light btn-primary-soft-check" for="btn-check-11" >Advanced</label>
									</li>
								</ul>
							</div>
							<!-- Skill level END -->
	
				</div>


			</div>
			<!-- Responsive offcanvas body END -->
		</div>
		<!-- Right sidebar END -->
	</div><!-- Row END -->
	<div class="row mt-5">
		<div class="col-12">
			<regist-company/>
		</div>
	</div>
	
</div>
</section>
<!-- =======================
Page content END -->

	</main>
    <hr/>
</div>
</template>

<script>
// param__ params__ watch__
var filename__ ="[pages > blog > BlogList.vue] "
import axios from 'axios';

import BlogListComponent from '../../components/blog/BlogListComponent.vue';
import UserPagination from '../../components/ui/UserPagination.vue';

import RegistCompany from '../../components/company/RegistCompany.vue' ;

export default {
    components: {
		"blog-list-component": BlogListComponent , 
        "user-pagination": UserPagination,
		"regist-company": RegistCompany
    },
    props: ['coursecategory','category_title'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
			inputCourseCategoryId : this.coursecategory, 
			inputCategoryTitle: this.category_title, 

			itemList: [], 

            page:1,
            current_path:'/blogs/'+this.coursecategory+'/'+this.category_title,  //current_path
			pagination: null, 

            inputPagePerCount : 9,  // 한 페이지에 보여주는 줄수(갯수)  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',


        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); 
    },     
    created(){
		// let funcname__="created()" ;
        // 같은 페이지를 파라미터 달리해서 불러올 때  
        // 참고자료 : https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
        this.$watch(
			() => this.$route.params,(toParams, previousParams) => {
					// console.log(filename__+funcname__+" $watch toParams: ", toParams['coursecategory']);
					// console.log(filename__+funcname__+ " $watch previousParams: ", previousParams['coursecategory']); 
					console.log(" $watch previousParams: ", previousParams['coursecategory']); 

					this.inputCourseCategoryId = toParams['coursecategory'];
					this.inputCategoryTitle =toParams['category_title'] ;

					this.getList();
				}
        )        
        this.getList();
    },

    computed: {
        paginationCompu(){
            return this.pagination ;
        },
		listpathCompu(){
			return 'blogs/'+this.inputCourseCategoryId+'/'+this.inputCategoryTitle ;
		}
    },    
    methods: {
        handleError(){
            this.error = null;
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        onChangeSearch(){
			this.page = 1 ;
            this.getList(); //page=1
        },
        onChangeLevel(_level){
			this.page = 1 ;
			this.inputLevel = _level ;			
            this.getList(); //page=1
        },
		async getList(){
			let funcname__ ="getList()" ;

			console.log(filename__+funcname__+" coursecategory: ", this.inputCourseCategoryId);  //~~log

			this.isLoading = true ;   

			let url ='' ;
			// if(this.isAuth()){
			// 	// 로그인 여부에 따라 수강여부 및 wishlist여부를 체크함
			// 	url='/api/content/course/category/auth/'+this.inputCourseCategoryId;
			// } else {
			// 	url='/api/content/course/category/user/'+this.inputCourseCategoryId ;
			// }
            url='/api/content/blog/category/user/'+this.inputCourseCategoryId ;

			let serverData = await axios({
				method: 'get',
				url: url,
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},           
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                    search: this.inputSearch,  
                    level: this.inputLevel,   

                }

			}).catch( err => {

				if(err.response.status === 401) {  
					this.dialogTitle ="Login" ;
					this.error = "로그인이 필요한 페이지입니다.";          
									
				} else {
					let dataForm = { 
						category: 'errorlog(api catech)' , 
						filename : filename__,
						methodname : funcname__,
						title: 'Error(API)',       
						content : ''+err,
					} ;  
					console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

					axios({
						method: 'post',
						url: '/api/errorlog/create',
						data: dataForm,
					});

					this.dialogTitle ="Error" ;
					this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
					
					this.isLoading = false ;

					return ;
				}

			});

			// console.log(filename__+''+funcname__+" serverData:", serverData);  //~~log

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			console.log(filename__+''+funcname__+" data:", data);  //~~log

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];

				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					list.push({
						num: counter--, 
						id: item.id, 
						title:item.title,
						subtitle:item.subtitle,
						level : item.level,
						is_price : item.is_price,
						price : item.price,
                        coursetime : item.coursetime,
                        lecturecount : item.lecturecount,
                        onoff : item.onoff,
						reviewavg:item.review_avg,
						categorystr: item.categorystr,

						company_id : item.company_id,
						is_student: item.is_student,
						is_wishlist: item.is_wishlist,

                        image : item.main_photo ? item.main_photo.main_photo_path : false,
						is_text: item.is_fronttext,
						seq:item.seq,
                        date : item.date,
                        update : item.update,
						create_name : item.create_name,
						create_email : item.create_email,
						create_photo : item.create_photo,
                        
					});
				}

				// console.log(filename__+''+funcname__+" tmpList:", tmpList); //~~log
				this.itemList = list;

				console.log(filename__+''+funcname__+" itemList:", this.itemList);  //~~log
                console.log(filename__+''+funcname__+" pagination:", this.pagination);  //~~log

			} else {
				console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
				this.dialogTitle ="Error" ;
				this.error = serverData.data.error_description;

			}

			this.isLoading = false ;            
		},

    }
}
</script>

<style scoped>

</style>
