<template>
    <tr>
        <!-- Course item -->
        <td class="text-center text-sm-start">{{num}}</td> 
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <router-link :to="detailLink" >
                    <div v-if="create_photo" class="avatar avatar-md mb-2 mb-md-0">
                        <img :src="create_photo" class="rounded" alt="">
                    </div>
                    <div class="mb-0 ms-2">
                        <!-- Title -->
                        <h6 class="mb-0"><a class="stretched-link">{{create_name}}</a></h6>
                        <!-- Address -->
                        <!-- <span class="text-body small"><i class="fas fa-fw fa-map-marker-alt me-1 mt-1"></i>Mumbai</span> -->
                    </div>
                </router-link>
            </div>
        </td>
        <td class="text-center text-sm-start">
            <div class=" overflow-hidden">
                <h6 class="mb-0">{{percent}}%</h6>
                <div class="progress progress-sm bg-primary bg-opacity-10">
                    <div class="progress-bar bg-primary aos" role="progressbar" data-aos="slide-right" data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-out" style="'width: '+{{percent}}+'%" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                    </div>
                </div>
            </div>
        </td>


        <td>
            <div class="d-flex align-items-center">
                <!-- Image -->
                <div v-if="!is_text" class="w-60px">
                    <router-link :to="detailLink" >
                        <img v-if="image" :src="image" class="rounded" alt="">
                        <!-- <img v-else src="/assets/images/courses/4by3/08.jpg" class="rounded" alt=""> -->
                        <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" alt="">
                    </router-link>

                </div>
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6>
                        <router-link :to="detailLink">
                            <span>{{title}}</span>
                        </router-link>
                    </h6>
                    <!-- Info -->
                    <div class="d-sm-flex">
                        <p class="h6 fw-light mb-0 small me-3"><i class="fas fa-table text-orange me-2"></i>{{total_count}} lectures</p>
                    </div>
                </div>
            </div>
        </td>
        <td>{{enroll_date}}</td>

    </tr>

</template>

<script>
export default {
    components: {},
    props: [
        'id','num','create_id','create_name','create_email','create_photo','date',
        'title','level','coursetime','lecturecount',
        'image','is_text',
        'is_display', 
        'percent','total_count', 'enroll_date',
        'page', 'search'
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            // return '/instructordash/course/'+this.id+'?page='+this.$route.query.page+"&search="+this.$route.query.search
            return '/companydash/course/'+this.id ;
        },
        timeFormat(){
            let hour = parseInt(this.coursetime/60) ;
            let minute = this.coursetime - hour * 60 ;
            if(hour == 0){
                return this.coursetime +'m'                
            } else if(minute == 0){
                return hour+'h'
            } else {
                return hour + 'h '+minute + 'm' ;                
            }
        }

    },
    methods: {

    },
    
}
</script>