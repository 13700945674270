import axios from "axios";

export default {
    // isMessageCategory(state){
    //     return !!state.messagecategory ;
    // },
    async LoadSocial(context){

        const res = await axios( {
            method: 'get', 
            url:'/api/setting/all/user/social',
        });
        // console.log('[store/modules/setting/actions.js] LoadSetting() res:', res);

        const responseData = await res.data ;
        // console.log('[store/modules/setting/actions.js] LoadSetting() responseData:', responseData);

        if(responseData.status_code === 200){
            // console.log('[store/modules/social/actions.js] LoadSocial() response:', responseData.response);


            let socials = responseData.response ;
            var tUrl ='' ;
            var fUrl = '' ;
            var yUrl = '' ;
            var iUrl = '' ;

            for(let item of socials){
                if(item.title === 'twitter'){
                    tUrl = item.description ;
                    
                } else if (item.title === 'facebook'){
                    fUrl = item.description ;

                } else if (item.title === 'youtube'){
                    yUrl = item.description ;

                } else if (item.title === 'instagram'){
                    iUrl = item.description ;
                }
            }            
            
            context.commit('setSocial', {
                
                is_social: true,
                
                facebook : fUrl,
                twitter: tUrl,
                youtube : yUrl,
                instagram : iUrl,
    
            });  // store/modules/social/mutations.js

        } else {
            console.log('[store/modules/social/actions.js] LoadSocial() error:', responseData.msg);

            // const error = new Error(responseData.msg || 'Failed to authenticate.');
            const error = new Error(responseData.msg);
            console.error("[store/modules/social/actions.js] LoadSocial() : \n", error);

            throw error ;            

        }
        
    },   
}