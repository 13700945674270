<template>
    <div>

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

		<!-- Title -->
		<div class="row mb-3">
			<div class="col-12 d-sm-flex justify-content-between align-items-center">
				<h1 class="h3 mb-2 mb-sm-0">직종 </h1>
				<div>
					<router-link :to="addLink" class="btn btn-sm btn-primary mb-0">Add 직종</router-link>
				</div>
				
			</div>
		</div>

		<!-- Card START -->
		<div class="card bg-transparent border">

			<!-- Card body START -->
			<div class="card-body">
				<!-- Course table START -->
				<div class="table-responsive border-0 rounded-3">
					<!-- Table START -->
					<table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
						<!-- Table head -->
						<thead>
							<tr>
								<th scope="col" class="border-0 rounded-start">직종</th>
								<th scope="col" class="border-0">세부직종</th>
								<th scope="col" class="border-0">세부직종 변경</th>
								<th scope="col" class="border-0">Hide/Show</th>
								<th scope="col" class="border-0">Up/Down</th>
								<th scope="col" class="border-0">Delete</th>
								<th scope="col" class="border-0 rounded-end">Update</th>
							</tr>
						</thead>
						
						<!-- Table body START -->
						<tbody>
							<admin-sector-list-component v-for="item in itemList"
								:key = "item.id"
								:id ="item.id"
								:title ="item.title"
								:subtitleList="item.subtitleList"
								:seq="item.seq"
								:is_display="item.is_display"
								:min="item.min"
								:max="item.max"																

								@changeRefreshList="emitRefreshList"
								
							/>
							
						</tbody>
						<!-- Table body END -->
					</table>
					<!-- Table END -->
				</div>
				<!-- Course table END -->
			</div>
			<!-- Card body END -->

		</div>
		<!-- Card END -->
	</div>
	<!-- Page main content END -->

    <!-- Page content END -->
    
    </div>
</template>

<script>
var filename__="[pages > admin > sector > AdminSectorList.vue] "

import axios from 'axios';

import AdminSectorListComponent from '../../../components/admin/sector/AdminSectorListComponent.vue' ;


export default {
    components: {
        "admin-sector-list-component": AdminSectorListComponent,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            

            itemList : [],
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
		addLink(){
			return '/admin/sector/sector/add'; 
		}
    },    
    created(){		
        this.getList();
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        isAdmin(){
            return this.$store.getters['auth/isAdmin'] ;
        },
		emitRefreshList(){
			this.getList();
		},
        async getList(){
			let funcname__ ="getList()" ;

			this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

			let serverData = await axios({
				method: 'get',
				url: '/api/user/sector/all',
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},               

			}).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				// console.log('[AdminCourseCategoryList.vue] profile:', data.profile);
				
				let tmpList = data.response ;
				let list = [];


				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					if(item.category === 'sector' && item.parent_id === 0 ) {
						list.push({
							id: item.id, 
							category: item.category, 
							depth: item.depth, 
							parent: item.parent, 
							title: item.title, 
							subtitleList : [],
							is_display: item.is_display, 
							seq:item.seq,
							min: false ,
							max: false,
						});
					}

				}
				if(list.length > 1) {
					for(let item of list ){
						item['min'] = item.seq === 1 ? true : false ;
						item['max'] = item.seq === list.length ? true : false ;
					}
					list.sort(function(a,b){
						// console.log("seq:",a.seq)
						return a.seq-b.seq
					});
				}

				//서브 타이틀 배열에 추가하기 
				for(let cate1 of list ){
					for(let item2 of tmpList ){
						if (cate1.id == item2.parent_id) {
							cate1.subtitleList.push({
								// num: cnt++, 
								id: item2.id,
								title: item2.title,    
								seq : item2.seq,    
								is_display: item2.is_display                         
							});
						}
					}

					cate1.subtitleList.sort(function(a,b){
						return a.seq-b.seq
					});

				}
				console.log(filename__+''+funcname__+" list:", list);
				this.itemList = list;

			} else {
				console.log(filename__+''+funcname__+' err:');

				this.dialogTitle ="실패" ;                    
				this.error = serverData.data.error_description;  
			}


			this.isLoading = false ;            
		},



    }
}
</script>

<style scoped>

</style>