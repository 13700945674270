<template>
    <tr>
        <td class="text-center text-sm-start">{{num}}</td>       
        <td class="text-center">
            <i v-if="title == 'facebook'" class="fab fa-facebook text-facebook me-2"></i>
            <i v-else-if="title == 'youtube'" class="fab fa-youtube text-google-icon me-2"></i>
            <i v-else-if="title == 'twitter'" class="fab fa-twitter text-twitter me-2"></i>
            <i v-else-if="title == 'instagram'" class="fab fa-instagram text-facebook me-2"></i>
            
        </td>       
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Title -->
                <h6 class="table-responsive-title mb-0 ms-2">	
                    <router-link :to="updateLink" class="stretched-link">
                        <span v-if="is_active">{{title}}</span>
                        <span v-else class="text-decoration-line-through "><i class="fas fa-eye-slash me-3"></i>{{title}}</span>
                    </router-link>
                </h6>
            </div>
        </td>

        <td>{{ description }}</td>
        <td> 
            <span v-if="is_active" class="badge bg-success bg-opacity-15 text-success">보임</span>
            <span v-else class="badge bg-danger bg-opacity-15 text-danger">숨김</span> 
        </td>

        <td>
            <router-link :to="updateLink" class="btn btn-success-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Edit">
                <i class="bi bi-pencil-square"></i>
            </router-link>

        </td>
    </tr>


</template>

<script>
export default {
    components: {},
    props: [
        'id','num',
        'title','description','is_active'
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        updateLink(){
            // return '/instructordash/course/'+this.id+'?page='+this.$route.query.page+"&search="+this.$route.query.search
            return '/admin/setting/'+this.id
        },
    },
    methods: {

    },
    
}
</script>