<template>
    <div>

        <main>
	
    <!-- =======================
    Page Banner START -->
    <student-dashboard-top :title="'Update Password'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <student-dashboard-left :menu="'pwupdate'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">

                    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
                        <p>{{ error }}</p>
                    </base-dialog>

                    <base-dialog :show="isLoading" title="Loading..." fixed>
                        <p>Loading...</p>
                        <base-spinner></base-spinner>
                    </base-dialog>
                    
                    <div class="row g-4">
    
                        <!-- Password change START -->
                        <div class="col-lg-6">
                            <div class="card border bg-transparent rounded-3">
                                <form @submit.prevent="sendForm">
                                    <!-- Card header -->
                                    <div class="card-header bg-transparent border-bottom">
                                        <h5 class="card-header-title mb-0">Update password</h5>
                                    </div>
                                    <!-- Card body START -->
                                    <div class="card-body">
                                        <!-- Current password -->
                                        <div class="mb-3">
                                            <label class="form-label">Current password</label>
                                            <input v-if="isShowPassword" class="form-control" type="text" placeholder="Enter current password" v-model.trim="inputPassword">
                                            <input v-else class="form-control" type="password" placeholder="Enter current password" v-model.trim="inputPassword">
                                        </div>
                                        <!-- New password -->
                                        <div class="mb-3">
                                            <label class="form-label"> Enter new password</label>
                                            <div class="input-group">
                                                <input v-if="isShowPassword" class="form-control" type="text" placeholder="Enter new password" v-model.trim="inputNewPassword">
                                                <input v-else class="form-control" type="password" placeholder="Enter new password" v-model.trim="inputNewPassword">
                                                <span class="input-group-text p-0 bg-transparent">
                                                    <i class="far cursor-pointer p-2 w-40px"
                                                        :class="{ 'fa-eye-slash': !isShowPassword, 'fa-eye': isShowPassword }"
                                                        @click="toggleShow"                                                    
                                                    ></i>
                                                </span>
                                            </div>
                                            <div class="rounded mt-1" id="psw-strength"></div>
                                        </div>
                                        <!-- Confirm password -->
                                        <div>
                                            <label class="form-label">Confirm new password</label>
                                            <input v-if="isShowPassword" class="form-control" type="text" placeholder="Enter new password" v-model.trim="inputNewPasswordConfirm">
                                            <input v-else class="form-control" type="password" placeholder="Enter new password" v-model.trim="inputNewPasswordConfirm">
                                        </div>
                                        <!-- Button -->
                                        <div class="d-flex justify-content-end mt-4">
                                            <button type="submit" class="btn btn-primary mb-0">Change password</button>
                                        </div>
                                    </div>
                                    <!-- Card body END -->
                                </form>
                            </div>
                        </div>
                        <!-- Password change end -->
                    </div>
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pagess > studentdash > StudentDashPasswordUpdate.vue]" ;
import axios from 'axios';

import StudentDashboardTop from '../../components/studentdash/StudentDashboardTop.vue';
import StudentDashboardLeft from '../../components/studentdash/StudentDashboardLeft.vue';

export default {
    components: {
        "student-dashboard-top": StudentDashboardTop,
        "student-dashboard-left": StudentDashboardLeft,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            id : null,
            isShowPassword : false, 
            inputPassword : null, 
            inputNewPassword : null, 
            inputNewPasswordConfirm : null, 
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },

    },    
    created(){
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.id = this.isUser() ;

        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        toggleShow() {
            this.isShowPassword = !this.isShowPassword;
        },        
        // 프로필 정보 업데이트  
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'password': this.inputPassword, 
                'newpassword': this.inputNewPassword, 
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputPassword === "undefined" || this.inputPassword === undefined || this.inputPassword === "null" || this.inputPassword === null )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="비밀번호는 6자리 이상이어야 합니다.";
                return ;
            }

            if( this.inputPassword.length < 6 )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="비밀번호는 6자리 이상이어야 합니다.";
                return ;
            }

            if( this.inputNewPassword === "undefined" || this.inputNewPassword === undefined || this.inputNewPassword === "null" || this.inputNewPassword === null )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="새로운 비밀번호는 6자리 이상이어야 합니다.";
                return ;
            }

            if( this.inputNewPassword.length < 6 )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="새로운 비밀번호는 6자리 이상이어야 합니다.";
                return ;
            }

            if( this.inputPassword === this.inputNewPassword )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="새로운 비밀번호는 이전 비밀번호와 달라야 됩니다.";
                this.inputNewPassword = '' ;
                return ;
            }
            if( this.inputNewPassword !== this.inputNewPasswordConfirm )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="확인용 비밀번호가 다릅니다.";
                this.inputNewPasswordConfirm = '' ;
                return ;
            }
            


            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/user/change/pw',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;

                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    const data = serverData.data ;
                    // console.log(filename__+''+funcname__+' data:', serverData.data);

                    if(data.status_code === 500){
                        this.dialogTitle ='Fail' ;
                        this.error = '기존 비밀번호가 맞지 않습니다.';     

                        this.inputPassword = null ;
                        this.inputNewPassword = null ;
                        this.inputNewPasswordConfirm = null ;

                    } else if(data.status_code === 201){
                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다. 다음번 로그인 때 바뀐 비밀번호로 로그인 해주십시오';     

                        this.inputPassword = null ;
                        this.inputNewPassword = null ;
                        this.inputNewPasswordConfirm = null ;

                    } else {
                        console.log(filename__+''+funcname__+' err.mserror_descriptiong:', data.error_description);
                        this.dialogTitle ="입력 실패" ;
                        this.error = data.error_description;

                        this.inputPassword = null ;
                        this.inputNewPassword = null ;
                        this.inputNewPasswordConfirm = null ;

                    }

                }
            

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    }
}
</script>

<style scoped>

</style>