<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- 강사(company) 등록되어 있을 경우(관리자에서)  -->
    <li v-if="isCompanyDashboardCompu"> <hr class="dropdown-divider"></li>
    <li v-if="isCompanyDashboardCompu"> 
        <!-- <router-link to="/companydash/dashboard" class="dropdown-item " > -->
        <router-link to="/companydash/dashboard" class="dropdown-item " >
            <i class="text-info fa-fw bi bi-toggle-off me-2"></i>파트너 대시보드
        </router-link>
    </li>

    <!-- 코스별 공동작업자 등록되어 있을 경우(instructor dashboard 에서)  -->
    <li v-if="isCourseWorkerDashboardCompu"> <hr class="dropdown-divider"></li>
    <li v-if="isCourseWorkerDashboardCompu"> 
        <router-link to="/editcourses" class="dropdown-item " >
        <i class="text-info fa-fw bi bi-bookmarks me-2"></i>강좌별 공동작업자 </router-link>
    </li>

</div>    

</template>

<script>
var filename__ ="[components > layout > TopCompanyDashboardPanelComponent.vue] "

import axios from 'axios';

export default {
    components: { },
    props: [],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputIsCompany : null, //파트너(company) 등록 여부 
            inputIsCourseWorker : null, // 공동작업자 등록여부 
            isCompanyDashboard : false, 
            isCourseWorkerDashboard : false, 

        }
    },
    created(){
        // let funcname__="created()" ;

        if(!this.isCompanyDashboard) {
            // store 에 담겨있지 않으면 불러옴 : 강사(company) 등록여부 
            this.getIsCompany();  
        }

        if(!this.isCourseWorkerDashboard) {
            // store 에 담겨있지 않으면 불러옴 : 코스 공동작업자 등록 여부 
            this.getIsCourseWorker();  
        }

    },    
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        isCompanyDashboardCompu(){
            return this.$store.getters['dashboard/isCompanyDashboard'];
        },
        isCourseWorkerDashboardCompu(){
            return this.$store.getters['dashboard/isCourseWorkerDashboard'];
        },

    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },

        // 파트너(company) 등록여부 체크 
        async getIsCompany(){
            let funcname__ ="getIsCompany() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/company/iscompany/companydash',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }


            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log(filename__+''+funcname__+' profile:', data.profile);
                
                this.inputIsCompany = data.is_exist ;
                // this.$store.dispatch('dashboard/MyInstructorDashboard', actionPayload);  //  /store/modules/auth/actions.js + mutations.js 

                // console.log(filename__+''+funcname__+" inputIsInstructor:", this.inputIsInstructor);

                const actionPayload = {
                    company_dashboard: this.inputIsCompany
                };
                this.$store.dispatch('dashboard/companyDashboard', actionPayload);  //  /store/modules/auth/actions.js + mutations.js 


            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  
            }

            this.isLoading = false ;            
        },

        // 코스별 공동작업자 등록여부 체크 
        async getIsCourseWorker(){
            let funcname__ ="getIsCourseWorker()" ;

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/workercourse/is_exist',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputIsCourseWorker = data.is_exist ;

                // console.log(filename__+''+funcname__+" inputIsCourseInstructor:", this.inputIsCourseInstructor);

                const actionPayload = {
                    is_courseworker_dashboard: this.inputIsCourseWorker
                };

                this.$store.dispatch('dashboard/isCourseWorkerDashboard', actionPayload);  //  /store/modules/dashboard/ actions.js + mutations.js 


            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;

            }

            this.isLoading = false ;            
        },

    
    },
    
}
</script>
<style scoped>

</style> 