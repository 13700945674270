<template>
<div>    
    <!-- 삭제 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" :id="idDeleteCompu">Delete 첨부파일</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 파일을 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

    <!-- image  -->
    <label class="position-relative text-center me-4" for="uploadfile-1" >
        <div class="w-auto me-4">
            <label class="border border-white border-3"  >{{ fileCompu }}</label>        
        </div>
        <!-- <button type="button" class="uploadremove" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu">
            <i class="bi bi-x text-white"></i>
        </button> -->
        <button type="button" class="uploadremove" @click="removeForm">
            <i class="bi bi-x text-white"></i>
        </button>
    </label>
</div>
</template>

<script>
var filename__= "[components > content > ContentFileListComponent.vue] "
import axios from 'axios';

export default {
    components: {},
    props: [
        'id', 'img_id', 'file',
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

        }
    },
    created(){
        
    },    
    computed: {
        idDeleteCompu(){
            return 'deleteFileModal'+this.id
        },
        idDeleteShapCompu(){
            return '#deleteFileModal'+this.id
        },
        fileCompu(){
            let filename = this.file.replace(/^.*[\\\/]/, '') ;
            return filename ;
        }

    },
    methods: {
        handleError(){
            this.error = null;
        },
        // 파일 삭제  
        async removeForm(){
            let funcname__ ="removeForm() ";

            const isConfirm = confirm('Are you delete file?');
            if(!isConfirm) return ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: `/api/content/file/delete/id/${this.id}`,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){
                        // console.log(filename__+''+funcname__+' data:', data);
                        
                        this.dialogTitle ='Success' ;
                        this.error = '파일 삭제 성공했습니다.';    

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('refreshFileList');
                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }

                }                    

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="실패" ;
                this.error = error; 
            }
            this.isLoading = false ;

        },


    },
    
}
</script>
<style scoped>

</style> 