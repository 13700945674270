<template>
    <li v-if="name == 'facebook'" class="list-inline-item">
        <a :href="url" class="mb-0 me-1 text-facebook" target="_blank" ><i class="fab fa-fw fa-facebook-f"></i></a>
    </li>
    <li v-if="name == 'instagram'" class="list-inline-item">
        <a :href="url" class="mb-0 me-1 text-instagram-gradient" target="_blank" ><i class="fab fa-fw fa-instagram"></i></a>
    </li>
    <li v-if="name == 'twitter'" class="list-inline-item">
        <a :href="url" class="mb-0 me-1 text-twitter" target="_blank" ><i class="fab fa-fw fa-twitter"></i></a>
    </li>
    <li v-if="name == 'linkedin'" class="list-inline-item">
        <a :href="url" class="mb-0 text-linkedin" target="_blank" ><i class="fab fa-fw fa-linkedin-in"></i></a>
    </li>
    <li v-if="name == 'youtube'" class="list-inline-item">
        <a :href="url" class="mb-0 text-youtube" target="_blank"><i class="fab fa-fw fa-youtube"></i></a>
    </li>


</template>

<script>

export default {
    components: {
    },
    props: [
        'id', 
        'name','url'
    ],

    data() {
        return {
            error: null,     
            dialogTitle: null,  

        }
    },
    
    computed: {
        detailLink(){
            return '/instructor/'+this.id
        },
        // contentSplice(){
        //     return this.description.splice(0,30);
        // },
    },
    methods: {
        handleError(){
            this.error = null;
        },
    },
    
}
</script>