<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="col-12 mb-2">
        <!-- Title -->
        <h2>{{blog.title}}</h2>
        <p v-if="!blog.is_fronttext">{{blog.subtitle}}</p>
        <!-- Content -->
        <ul class="list-inline mb-0">
            <li class="list-inline-item fw-light h6 me-3 mb-1 mb-sm-0"><i class="fas fa-signal me-2"></i>
                <span v-if="blog.level ===0"> All levels</span>
                <span v-if="blog.level ===1"> Beginner</span>
                <span v-if="blog.level ===2"> Intermediate</span>
                <span v-if="blog.level ===3"> Advanced</span>
            </li>
            <li class="list-inline-item fw-light h6 me-3 mb-1 mb-sm-0"><i class="bi bi-patch-exclamation-fill me-2"></i>Last updated {{blog.update}}</li>
        </ul>
    </div>
    <!-- Title END -->

</div>


</template>

<script>
var filename__ = "[components > blog > BlogDetailCenterComponent.vue] " ;

export default {
    components: {},
    props: [
        'content_id','blog','company_id',
        'is_exist',
        'topic_id','topic_title',
        'topic_kind','topic_url','topic_description','topic_photo',
        'topic_iscomplete',
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,     
			
            itemFileList : [],
            itemCardnewsList : [],

            inputTopic_iscomplete : null,

        }
    },
    created(){
    },
    computed: {
        topicTitleComu(){
            // console.log(filename__+' topicTitleComu() topic_title:', this.topic_title);

            if(this.topic_title){
                return this.topic_title ;
            } else {
                return '';
            }
        },

    },
    methods: {
        handleError(){
            this.error = null;
        },

        

    },
    
}
</script>