<template>
<div class="row mt-4">

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Comment START -->
    <div class="col-md-7">
        <h3>{{inputCounter}} comments</h3>
    <!-- Comment level 1-->
        <admin-comment-list-component 
            v-for="item in itemList"
                :key="item.id"
                :id="item.id"
                :description="item.description"
                :depth="item.depth"
                :seq="item.seq"
                :date="item.date"
                :is_display="item.is_display"
                :create_name="item.create_name"
                :create_photo="item.create_photo"
                @replySendForm="replyModalSendForm"
                @updateForm="updateModalSendForm"
                @isDisplayForm="updateIsDisplayForm"
                @removeForm="removeForm"
        />    
    </div>
    <!-- Comment END -->

    <!-- Form START -->
    <div class="col-md-5">
        <!-- Title -->
        <h3 class="mt-3 mt-sm-0">Your Views Please!</h3>
        <!-- <small>Your email address will not be published. Required fields are marked *</small> -->

        <form @submit.prevent="sendForm" class="row g-3 mt-2 mb-5">
            <!-- Name -->
            <!-- <div class="col-lg-6">
                <label class="form-label">Name *</label>
                <input type="text" class="form-control" aria-label="First name">
            </div> -->
            <!-- Email -->
            <!-- <div class="col-lg-6">
                <label class="form-label">Email *</label>
                <input type="email" class="form-control">
            </div> -->
            <!-- Comment -->
            <div class="col-12">
                <label class="form-label">Your Comment *</label>
                <textarea class="form-control" rows="3" v-model.trim="inputDescription"></textarea>
            </div>
            <!-- Button -->
            <div class="col-12">
                <button type="submit" class="btn btn-primary mb-0">Post comment</button>
            </div>
        </form>
    </div>
    <!-- Form END -->
</div>

</template>

<script>
var filename__ ="[components > admin > comment > AdminCommentContainerComponent.vue]" ;

import axios from 'axios';

import AdminCommentListComponent from './AdminCommentListComponent.vue' ;

export default {
    components: {
        'admin-comment-list-component' : AdminCommentListComponent
    },
    props: [
        "content",'content_id',
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputCounter : null, 
            itemList: [], 

            inputDescription : null,

        }
    },
    created(){
        this.getList();
    },    
    computed: {
        // detailLink(){
        //     return '/admin/contactus/'+this.id+'/'+this.parent_id+'?page='+this.page+"&search="+this.search ; 
        // },

    },
    methods: {
        handleError(){
            this.error = null;
        },

        async getList(){
            let funcname__ ="getList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: `/api/comment/all/${this.content}/${this.content_id}`,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           
                // params: { 
                //     board: this.board , 
                // }

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+''+funcname__+' response:', data.response);
                    
                    this.inputCounter = data.response.counter ;
                    let tmpList = data.response.data ;
                    let list = []; 

                    //메인 카테고리 값들을 입력하기 
                    for(let item of tmpList ){
                        list.push({
                            id: item.id, 

                            description: item.description,
                            depth:item.depth,
                            seq : item.seq,

                            create_photo: item.create_photo,
                            create_name: item.create_name,

                            date: item.update, 
                            is_display: item.is_display,
                            
                        });
                    }

                    // console.log(filename__+''+funcname__+" list:", list);
                    this.itemList = list;

                    console.log(filename__+''+funcname__+" this.itemList:", this.itemList);

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;     
        },

        // 주석 입력   
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'content_id': this.content_id,
                'content': this.content,
                'description': this.inputDescription,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputDescription === '' || this.inputDescription === undefined || this.inputDescription === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/comment/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                }, 

            }).catch( err => {

                if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }


            });

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;
            
            } else {
                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData.status === 200) {
                    const data = serverData.data ;
                    // console.log(filename__+''+funcname__+'  data:', data);

                    if(data.status_code === 201){
                                                    
                        this.inputDescription = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '입력에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.getList(); 

                            }, 700);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }
            }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // 답변 입력   
        async replyModalSendForm(_id, _description){
            let funcname__ ="replyModalSendForm() ";

            let dataForm = { 
                'content': this.content,
                'content_id': this.content_id,
                'parent_id' : _id,
                'description' : _description, 
            } ;  

            // console.log(filename__+''+funcname__+' replyModalSendForm:', dataForm);

            if( _description === '' || _description === undefined || _description === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/comment/reply',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+'  data:', data);

                        if(data.status_code === 201){
                                                        
                            this.inputDescription = null ;

                            this.dialogTitle ='Success' ;
                            this.error = '입력에 성공했습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.getList(); 

                                }, 700);
                            }                        

                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
        
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error('[AdminCommentComponent.vue] sendReplyForm() error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        // 수정 입력   
        async updateModalSendForm(_id, _description){
            let funcname__ ="updateModalSendForm() ";

            let dataForm = { 
                'description' : _description, 
            } ;  

            // console.log(filename__+''+funcname__+' dataForm :', dataForm);

            if( _description === '' || _description === undefined || _description === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                        method: 'put',
                        url: '/api/comment/update/'+_id,
                        data: dataForm,
                        headers: {
                            Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
      

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+'  data:', data);

                        if(data.status_code === 201){
                                                        
                            this.inputDescription = null ;

                            this.dialogTitle ='Success' ;
                            this.error = '수정에 성공했습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.getList(); 

                                }, 700);
                            }                        

                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },
        // 보임/숨김 수정 입력   
        async updateIsDisplayForm(_id, _is_display){
            let funcname__ ="updateIsDisplayForm() ";

            let dataForm = { 
                'is_display' : _is_display, 
            } ;  

            // console.log(filename__+''+funcname__+' dataForm :', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                        method: 'put',
                        url: '/api/comment/display/'+_id,
                        data: dataForm,
                        headers: {
                            Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+'  data:', data);

                        if(data.status_code === 201){
                                                        
                            this.inputDescription = null ;

                            this.dialogTitle ='Success' ;
                            this.error = '수정에 성공했습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.getList(); 

                                }, 700);
                            }                        

                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },
        // 팝업 창을 통해서 삭제   
        async removeForm(_id){
            let funcname__ ="removeForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/comment/delete/'+_id,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        // console.log(filename__+''+funcname__+' data:', serverData.data);
                                                
                        this.dialogTitle ='Success' ;
                        this.error = '삭제 성공했습니다.';  
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.getList(); 

                            }, 700);
                        }                        

                    } else if(serverData.data.status_code == 500){
                        // console.log(filename__+''+funcname__+' data:', serverData.data);
                                                
                        this.dialogTitle ='Fail' ;
                        this.error = '하위 답변이 존재해서 삭제할 수 없습니다.';    
                                                                                                
                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },     
    
    },
    
}
</script>
<style scoped>

</style> 