<template>
    <div>

        <main>
<!-- =======================
Page Banner START -->
<section class="bg-primary bg-opacity-10">
	<div class="container">
		<div class="row">
			<!-- Content START -->
			<div class="col-lg-8 mx-auto text-center">
				<!-- Title -->
				<h1 class="display-6">해결책 찾기. 지원 받기</h1>
				<p class="mb-0">여기에서 당신의 질문에 대한 답변을 찾아 보세요</p>
				<!-- Search bar -->
				<form class="bg-body rounded p-2 mt-4">
					<div class="input-group">
						<input class="form-control border-0 me-1" type="text" placeholder="질문 찾기...">
						<button type="button" class="btn btn-dark mb-0 rounded">찾기</button>
					</div>
				</form>

				<!-- Popular questions START -->
				<div class="row mt-4 align-items-center">
					<div class="col-12">
						<h5 class="mb-3">인기있는 질문</h5>
						<!-- Questions List START -->
						<div class="list-group list-group-horizontal gap-2 justify-content-center flex-wrap mb-0 border-0">
							<router-link to="/support/detail" class="btn btn-white btn-sm fw-light" > 어떻게 도와드릴까요?</router-link>
							<router-link to="/support/detail" class="btn btn-white btn-sm fw-light" > 어떻게 해야 수강료를 줄일 수 있을까요? </router-link>
							<router-link to="/support/detail" class="btn btn-white btn-sm fw-light" > 수강 과목 가이드 메뉴는? </router-link>
							<router-link to="/support/detail" class="btn btn-white btn-sm fw-light" > 강사의 이력을 알 수 있나요? </router-link>
							<router-link to="/support/detail" class="btn btn-white btn-sm fw-light" > 연말정산을 받을 수 있나?</router-link>
							<router-link to="/support" class="btn btn-primary-soft btn-sm fw-light" > 모든 질문 보기</router-link>
						</div>
						<!-- Questions list END -->
					</div>
				</div>
				<!-- Popular questions END -->
			</div>
      <!-- Content END -->

			<!-- Image -->
			<div class="col-12 mt-6">
				<img src="/assets/images/element/help-center.svg" class="w-100" alt="">
			</div>
		</div>
	</div>
</section>
<!-- =======================
Page Banner END -->

<!-- =======================
Recommended topics START -->
<section>
	<div class="container">
			<!-- Titles -->
			<div class="row">
				<div class="col-12 text-center">
					<h2 class="text-center mb-4">추천 토픽</h2>
				</div>
			</div>

			<!-- Row START -->
			<div class="row g-4">
				<div class="col-md-6 col-xl-3">
					<!-- Card START -->
					<div class="card bg-light h-100">
						<!-- Title -->
						<div class="card-header bg-light pb-0 border-0">
							<i class="bi bi-emoji-smile fs-1 text-success"></i>
							<h5 class="card-title mb-0 mt-2">시작하기</h5>
						</div>
						<!-- List -->
						<div class="card-body">
							<ul class="nav flex-column">
								<li class="nav-item"><router-link to="/support/detail" class="nav-link d-flex" ><i class="fas fa-angle-right text-primary pt-1 me-2"></i>과목 참고하기</router-link></li>
								<li class="nav-item"><router-link to="/support/detail" class="nav-link d-flex" ><i class="fas fa-angle-right text-primary pt-1 me-2"></i>과목별 일정표 참고하기</router-link></li>
								<li class="nav-item"><router-link to="/support/detail" class="nav-link d-flex" ><i class="fas fa-angle-right text-primary pt-1 me-2"></i>담임 강사 이력 확인</router-link></li>
								<li class="nav-item"><router-link to="/support/detail" class="nav-link d-flex" ><i class="fas fa-angle-right text-primary pt-1 me-2"></i>과목별 수강료 및 할인율</router-link></li>
								<li class="nav-item"><router-link to="/support/detail" class="nav-link d-flex" ><i class="fas fa-angle-right text-primary pt-1 me-2"></i>연관 과목 이수율</router-link></li>
							</ul>
						</div>
					</div> 
					<!-- Card END --> 
				</div>

				<div class="col-md-6 col-xl-3">
					<!-- Card START -->
					<div class="card bg-light h-100">
						<!-- Title -->
						<div class="card-header bg-light pb-0 border-0">
							<i class="bi bi-layers fs-1 text-warning"></i>
							<h5 class="card-title mb-0 mt-2">사용자 계정 설정</h5>
						</div>
						<!-- List -->
						<div class="card-body">
							<ul class="nav flex-column">
								<li class="nav-item"><a class="nav-link d-flex" href="help-center-detail.html"><i class="fas fa-angle-right text-primary pt-1 me-2"></i>사용자 계정 등록하기</a></li>
								<li class="nav-item"><a class="nav-link d-flex" href="help-center-detail.html"><i class="fas fa-angle-right text-primary pt-1 me-2"></i>계정 수정하기</a></li>
								<li class="nav-item"><a class="nav-link d-flex" href="help-center-detail.html"><i class="fas fa-angle-right text-primary pt-1 me-2"></i>수강 과목 연결하기</a></li>
								<li class="nav-item"><a class="nav-link d-flex" href="help-center-detail.html"><i class="fas fa-angle-right text-primary pt-1 me-2"></i>주요 내용 정리하기</a></li>
								<li class="nav-item"><a class="nav-link d-flex" href="help-center-detail.html"><i class="fas fa-angle-right text-primary pt-1 me-2"></i>메모</a></li>
							</ul>
						</div>
					</div>  
					<!-- Card END -->
				</div>

				<div class="col-md-6 col-xl-3">
					<!-- Card START -->
					<div class="card bg-light h-100">
						<!-- Title -->
						<div class="card-header bg-light pb-0 border-0">
							<i class="bi bi-info-circle fs-1 text-orange"></i>
							<h5 class="card-title mb-0 mt-2">기타 토픽들 </h5>
						</div>
						<!-- List -->
						<div class="card-body">
							<ul class="nav flex-column">
								<li class="nav-item"><router-link to="/support/detail" class="nav-link d-flex" ><i class="fas fa-angle-right text-primary pt-1 me-2"></i>보안 &amp; 개인정보</router-link></li>
								<li class="nav-item"><router-link to="/support/detail" class="nav-link d-flex" ><i class="fas fa-angle-right text-primary pt-1 me-2"></i>작가, 발행사 &amp; 관리자 지침</router-link></li>
								<li class="nav-item"><router-link to="/support/detail" class="nav-link d-flex" ><i class="fas fa-angle-right text-primary pt-1 me-2"></i>가격 정책</router-link></li>
								<li class="nav-item"><router-link to="/support/detail" class="nav-link d-flex" ><i class="fas fa-angle-right text-primary pt-1 me-2"></i>판매가 &amp; 포인트</router-link></li>
								<li class="nav-item"><router-link to="/support/detail" class="nav-link d-flex" ><i class="fas fa-angle-right text-primary pt-1 me-2"></i>프로모션 &amp; 할인율</router-link></li>
							</ul>
						</div>
					</div>  
					<!-- Card END -->
				</div>

				<div class="col-md-6 col-xl-3">
					<!-- Card START -->
					<div class="card bg-light h-100">
						<!-- Title START -->
						<div class="card-header bg-light pb-0 border-0">
							<i class="bi bi-house fs-1 text-purple"></i>
							<h5 class="card-title mb-0 mt-2">다음 단계 과정 </h5>
						</div>
						<!-- List -->
						<div class="card-body">
							<ul class="nav flex-column">
								<li class="nav-item"><a class="nav-link d-flex" href="help-center-detail.html"><i class="fas fa-angle-right text-primary pt-1 fa-fw me-2"></i>관리자 &amp; 행동기준</a></li>
								<li class="nav-item"><a class="nav-link d-flex" href="help-center-detail.html"><i class="fas fa-angle-right text-primary pt-1 fa-fw me-2"></i>프로가 되기</a></li>
								<li class="nav-item"><a class="nav-link d-flex" href="help-center-detail.html"><i class="fas fa-angle-right text-primary pt-1 fa-fw me-2"></i>모바일 앱</a></li>
								<li class="nav-item"><a class="nav-link d-flex" href="help-center-detail.html"><i class="fas fa-angle-right text-primary pt-1 fa-fw me-2"></i>가이드</a></li>
								<li class="nav-item"><a class="nav-link d-flex" href="help-center-detail.html"><i class="fas fa-angle-right text-primary pt-1 fa-fw me-2"></i>프로모션 &amp; 판매</a></li>
							</ul>
						</div>
					</div>  
					<!-- Card END -->
				</div>
			</div>
			<!-- Row END -->
	</div>
</section>
<!-- =======================
Recommended topics END -->

<!-- =======================
Popular articles START -->
<section>
	<div class="container">
		<!-- Titles -->
		<div class="row">
			<div class="col-12 text-center">
				<h2 class="text-center mb-4">인기있는 기사</h2>
			</div>
		</div>

		<div class="row g-4">

			<div class="col-xl-6">
				<!-- Card item START -->
				<div class="card card-body border p-4 h-100">
					<!-- Title -->
					<h4 class="card-title mb-4"><router-link to="/support/detail" class="stretched-link">설치 파트</router-link></h4>
					
					<!-- Avatar group and content -->
					<div class="d-sm-flex align-items-center">
						<!-- Avatar group -->
						<ul class="avatar-group mb-2 mb-sm-0">
							<li class="avatar avatar-md">
								<img class="avatar-img rounded-circle border-white" src="/assets/images/avatar/01.jpg" alt="avatar">
							</li>
							<li class="avatar avatar-md">
								<img class="avatar-img rounded-circle border-white" src="/assets/images/avatar/02.jpg" alt="avatar">
							</li>
							<li class="avatar avatar-md">
								<img class="avatar-img rounded-circle border-white" src="/assets/images/avatar/03.jpg" alt="avatar">
							</li>
						</ul>
						<!-- Content -->
						<div class="ms-sm-2">
							<h6 class="mb-1">10 개의 기사가 선별되었습니다.</h6>
							<p class="mb-0">작성자: <b>정진수</b> ,<b>방상일</b>, <b>최영식</b></p>
						</div>
					</div>
				</div>
				<!-- Card item END -->
			</div>

			<div class="col-xl-6">
				<!-- Card item START -->
				<div class="card card-body border p-4 h-100">
					<!-- Title -->
					<h4 class="card-title mb-4"><router-link to="/support/detail" class="stretched-link">메일 고객 지원</router-link></h4>
					
					<!-- Avatar group and content -->
					<div class="d-sm-flex align-items-center">
						<!-- Avatar group -->
						<ul class="avatar-group mb-2 mb-sm-0">
							<li class="avatar avatar-md">
								<img class="avatar-img rounded-circle border-white" src="/assets/images/avatar/08.jpg" alt="avatar">
							</li>
							<li class="avatar avatar-md">
								<img class="avatar-img rounded-circle border-white" src="/assets/images/avatar/04.jpg" alt="avatar">
							</li>
						</ul>
						<!-- Content -->
						<div class="ms-sm-2">
							<h6 class="mb-1">5 개의 기사가 선별되었습니다</h6>
							<p class="mb-0">작성자: <b>윤형만</b> , <b>이태경</b></p>
						</div>
					</div>
				</div>
				<!-- Card item END -->
			</div>

			<div class="col-xl-6">
				<!-- Card item START -->
				<div class="card card-body border p-4 h-100">
					<!-- Title -->
					<h4 class="card-title mb-4"><a href="#" class="stretched-link">효과적인 이메일 보내기 </a></h4>
					
					<!-- Avatar group and content -->
					<div class="d-sm-flex align-items-center">
						<!-- Avatar group -->
						<ul class="avatar-group mb-2 mb-sm-0">
							<li class="avatar avatar-md">
								<img class="avatar-img rounded-circle border-white" src="/assets/images/avatar/05.jpg" alt="avatar">
							</li>
						</ul>
						<!-- Content -->
						<div class="ms-sm-2">
							<h6 class="mb-1">4 개의 기사가 선별되었습니다</h6>
							<p class="mb-0">작성자: <b>문형석</b></p>
						</div>
					</div>
				</div>
				<!-- Card item END -->
			</div>

			<div class="col-xl-6">
				<!-- Card item START -->
				<div class="card card-body border p-4 h-100">
					<!-- Title -->
					<h4 class="card-title mb-4"><a href="#" class="stretched-link">고객과의 소통</a></h4>
					
					<!-- Avatar group and content -->
					<div class="d-sm-flex align-items-center">
						<!-- Avatar group -->
						<ul class="avatar-group mb-2 mb-sm-0">
							<li class="avatar avatar-md">
								<img class="avatar-img rounded-circle border-white" src="/assets/images/avatar/09.jpg" alt="avatar">
							</li>
							<li class="avatar avatar-md">
								<img class="avatar-img rounded-circle border-white" src="/assets/images/avatar/10.jpg" alt="avatar">
							</li>
						</ul>
						<!-- Content -->
						<div class="ms-sm-2">
							<h6 class="mb-1">11 개의 기사가 선별되었습니다</h6>
							<p class="mb-0">작성자: <b>최형일</b>, <b>공인주</b></p>
						</div>
					</div>
				</div>
				<!-- Card item END -->
			</div>

		</div> <!-- Row END -->
	</div>
</section>
<!-- =======================
Popular articles END -->

<!-- =======================
Action boxes START -->
<section>
	<div class="container">
		<div class="row g-4">

			<!-- Action box item -->
			<div class="col-lg-4">
				<div class="bg-info bg-opacity-10 rounded-3 p-5">
					<!-- Icon -->
					<h2 class="display-5 text-info"><i class="bi bi-headset"></i></h2>
					<!-- Title -->
					<h3>지원 연결?</h3>
					<p>문제가 있으면 즉시 연결해주세요. 언제든 지원할 준비가 되어있습니다.</p>
					<!-- Button -->
					<a href="#" class="btn btn-dark mb-0">연결하기</a>
				</div>
			</div>

			<!-- Action box item -->
			<div class="col-lg-4">
				<div class="bg-purple bg-opacity-10 rounded-3 p-5">
					<!-- Icon -->
					<h2 class="display-5 text-purple"><i class="fas fa-ticket-alt"></i></h2>
					<!-- Title -->
					<h3>서비스티켓 제출</h3>
					<p>첫 고객에게 지원되는 서비스티켓을 제출합니다. 이제 대해 메일로 결과를 알려드립니다.</p>
					<!-- Button -->
					<a href="#" class="btn btn-dark mb-0">티켓 제출</a>
				</div>
			</div>

			<!-- Action box item -->
			<div class="col-lg-4">
				<div class="bg-warning bg-opacity-15 rounded-3 p-5">
					<!-- Icon -->
					<h2 class="display-5 text-warning"><i class="bi bi-envelope-fill"></i></h2>
					<!-- Title -->
					<h3>특징 요청</h3>
					<p>과목의 특징에 대해 요청하면 즉시 대응합니다. 요청해주세요</p>
					<!-- Button -->
					<a href="#" class="btn btn-dark mb-0">요청</a>
				</div>
			</div>

		</div> <!-- Row END -->
	</div>
</section>
<!-- =======================
Action boxes ENd -->

</main>
                
    <hr/>
    </div>
</template>

<script>
// var filename__ = "[pagess > help > Support.vue]" ;

// import MemberInfoComp from '../../components/account/MemberInfoComp.vue';

export default {
    components: {
        // "member-info-comp": MemberInfoComp
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
                        
        }
    },
    computed: {
        
    },    
    created(){
        // if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
        //     this.id = this.isUser() ;
        //     if(this.id) {
        //         this.getMember();
        //     }
            
        // } else {
        //     this.dialogTitle ="Error" ;
        //     this.error = "로그인이 필요합니다";    
        //     this.errorLogin = true ;
        // }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },

    }
}
</script>

<style scoped>

</style>
