import mutations from './mutations.js' ;
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state(){
        return {
            is_social: false,

            facebook : null,
            youtube : null,
            twitter : null,
            instagram : null, 
        }
    },    
    mutations,
    actions,
    getters
}