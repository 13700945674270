<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Page main content START -->
	<div class="page-content-wrapper-match border">

		<!-- Title -->
        <form @submit.prevent="sendForm" >

            <div class="col-12">
                <div class="card bg-transparent border rounded-3 h-100">

                    <!-- Catd header -->
                    <div class="card-header bg-light border-bottom">
                        <h5 class="card-header-title"> 
                            <span v-if="category =='single'">1개 이미지 보이기</span>
                            <span v-else>여러 이미지 보이기</span>
                             이미지 업로드

                        </h5>
                    </div>

                    <!-- Card body START -->
                    <div class="card-body">

                        <div class="row g-4 mb-4">
                            <div class="col-12">
                                <id-fileupload-component
                                    :key="inputFileuploadId"
                                    :id="inputFileuploadId" 
                                    :image_path="inputPhoto" 
                                    :dbtable="'mainslide'" 
                                    :category="'mainslide'" 
                                    :sub-category="category" 
                                    :file-kind="'image'"
                                    :height="200"
                                    :explain="inputFileuploadExplain"     
                                    />
                            </div>
                        </div>
                        <div class="row g-4">
                            <div class="col-12 mb-2">
                                <label class="form-label">Title</label>
                                <div class="input-group">
                                    <input type="text" v-model.trim="inputTitle" class="form-control" placeholder="title">
                                </div>
                            </div>
                        </div>
                        <div class="row g-4">
                            <div class="col-12 mb-2">
                                <label class="form-label">Link</label>
                                <div class="input-group">
                                    <input type="text" v-model.trim="inputLink" class="form-control" placeholder="필요시">
                                </div>
                            </div>
                        </div>
                        <div class="row g-4">
                            <div class="col-12 mb-2">
                                <label class="form-label">Description</label>
                                <div class="input-group">
                                    <textarea class="form-control" rows="5" placeholder="필요시" v-model.trim="inputDescription"></textarea>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- Card body END -->
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 d-sm-flex justify-content-between align-items-center">
                    <router-link :to="backToLink" class="btn btn-secondary mb-0" >Back</router-link>
                    <button type="submit" class="btn btn-info mb-0" >Text Update</button>
                </div>
            </div>
        </form>


	</div>

</div>
</template>

<script>
var filename__="[pages > admin > mainslide > AdminMainSlideUpdate.vue] "

import axios from 'axios';

import IdFileUploadComponent from '../../../components/fileupload/IdFileUploadComponent.vue';

export default {
    components: {
        'id-fileupload-component' : IdFileUploadComponent
    },
    props: ['id','category'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  
            
            inputFileuploadId: null, 

            inputCategory : this.category,
            inputTitle : null, 
            inputLink : null, 
            inputDescription : null, 

            inputFileuploadExplain :`(최적 이미지 비율 및 크기 1420 x 400)`,        
        }
    },
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getItem();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    computed: {
        backToLink(){
            return '/admin/mainslide'; 
        },

    },    
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        async getItem(){
            let funcname__ ="getItem()" ;
            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/mainslide/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputPhoto = data.response.image_path ;
                this.inputFileuploadId = data.response.fileupload_id ;

                this.inputTitle = data.response.title ;
                this.inputLink = data.response.link ;
                this.inputDescription = data.response.description ;
				
				
            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },

        // 전송하기  
        async sendForm(){
            let funcname__ ="sendForm()" ;

            let dataForm = { 
                'title':this.inputTitle,
                'link' : this.inputLink,
                'description' : this.inputDescription,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputTitle === '' || this.inputTitle === undefined || this.inputTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="카테고리 타이틀을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/mainslide/update/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 
                                this.$router.replace(this.backToLink); 

                            }, 700);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' return err:', serverData.data.error_description);
                        this.dialogTitle ="생성 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        
    }
}
</script>

<style scoped>

</style>