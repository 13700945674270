<template>
<div> 
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="row">

        <div class="col-12 mb-3">
            <div class="form-check form-switch form-check-md">
                <input class="form-check-input" type="checkbox" role="switch" id="isImage"  v-model="inputIsText" @click="onChangeIsText">
                <label v-if="inputIsText"  class="form-check-label" for="isImage">Text 출력(description)</label>
                <label v-else class="form-check-label" for="isImage">이미지 및 동영상 </label>
            </div>
        </div>
        <hr/>
        <div v-if="isTextCompu" class="col-12">
            <span class="badge text-bg-secondary mb-2">Course Details : 강좌에 대한 짧은 설명</span>
            <p v-if="course" class="form-control p-grey">{{ course.subtitle }}</p>
            <textarea v-else class="form-control" rows="2" placeholder="subtitle의 내용이 대신합니다" disabled></textarea>
        </div>

        <div v-if="!isTextCompu" class="col-12">
            <div class="row g-xl-0 align-items-center">
                <div class="col-lg-5 ms-3">
                    <h6 class="mb-lg-0">Display Image or Movie in Detail <span class="text-danger">*</span></h6>
                </div>
                <!-- <div class="col-lg-6">
                    <div class="d-flex">
                        <div class="form-check radio-bg-light me-4">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
                                    v-model="inputKind" 
                                    value="1"
                                >
                            <label class="form-check-label" for="flexRadioDefault1">Image</label>
                        </div>
                        <div class="form-check radio-bg-light">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" 
                                    v-model="inputKind" 
                                    value="2"
                                >
                            <label class="form-check-label" for="flexRadioDefault2">Moive url </label>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <!-- Upload image START -->
        <div v-if="!isTextCompu && content_id" class="col-12 mt-5">
            <!-- 메인 이미지  -->
            <content_id-main-image-fileupload-panel-component
                :key="media"
                :file-kind="'image'"
                :category="'course'" 
                :sub-category="'main'" 
                :content_id="content_id" 
            />
        </div>
        <!-- Upload image END -->

        <div v-if="!isTextCompu && content_id" class="col-12 mt-4">
            
            <div class="form-check form-check-md bg-color-form-gray ">
                <div class="d-flex">
                    <div class="form-check radio-bg-light me-4">
                        <input class="form-check-input" type="radio" name="flexRadioSalutation" id="flexRadioSalutation11" 
                            v-model="inputKind" 
                            value="1" 
                            @click="onChangeKind(1)"
                            >
                        <label class="form-check-label" for="flexRadioSalutation11">쎔네일 이미지만 보임</label>
                    </div>
                    <div class="form-check radio-bg-light me-4">
                        <input class="form-check-input" type="radio" name="flexRadioSalutation" id="flexRadioSalutation22" 
                            v-model="inputKind" 
                            value="2" 
                            @click="onChangeKind(2)"
                            >
                        <label class="form-check-label" for="flexRadioSalutation22">세부 페이지 보기에  소개 동영상 노출</label>
                    </div>
                </div>                
            </div>
        </div>
        
        <form @submit.prevent="sendForm" > 
            <!-- Upload video START -->
            <div v-if="!isTextCompu && inputKind == 2 ">
            
                <div class="col-12 mt-3">
                    <h5>소개 Video Link</h5>
                    <!-- Input -->
                    <div class="col-md-12">
                        <label class="form-label">Youtube 링크를 복사해서 입력해주세요</label>
                        <input class="form-control" type="text" placeholder="https://www.youtube.com/watch?v=nPIYUS2UqUg"  v-model.trim="inputYoutubeTotalstr" style="width:100%;" @input="onChangeTotal($event)">
                    </div>
                    <div class="col-md-12">
                        <label class="form-label">ex) <small>https://www.youtube.com/watch?v=</small>
                            <b>nPIYUS2UqUg</b>
                            <small>&t=1s</small>
                            &nbsp;&nbsp;&nbsp;
                            <b>(굵은부분만)</b>
                        </label>
                        <input class="form-control" type="text" placeholder="nPIYUS2UqUg"  v-model.trim="inputYoutubestr" style="width:200px;">
                    </div>
                    <!-- Video link -->
                    <div class="col-md-12">
                        <label class="form-label">별도로 입력되는 링크(하단 link 포맷 참조)</label>
                        <input class="form-control" type="text" placeholder="Enter Video link" v-model.trim="inputUrl">
                    </div>
                    <div class="col-md-12">
                        <label class="form-label">link : {{urlCompu}}</label>
                    </div>
                                    
                    <!-- Preview -->
                    <h5 v-if="urlCompu" class="mt-4">소개 Video Preview</h5>
                    <div v-if="urlCompu" class="position-relative">
                        <div class="ratio ratio-16x9">
                            <iframe 
                                :src="urlCompu" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen
                            ></iframe>                                                            
                        </div>

                    </div>
                </div>
                <!-- Upload video END -->

                <!-- Step 2 button -->
                <!-- <div class="d-flex justify-content-between mt-3">
                    <button type="button" @click="onChangeStep(2)" class="btn btn-secondary prev-btn mb-0">Previous</button>
                    <button type="submit" class="btn btn-primary next-btn mb-0">Save & Next</button>
                </div> -->

            
            </div>
            <div class="d-flex justify-content-between mt-3">
                <button type="button" @click="onChangeStep(2)" class="btn btn-secondary prev-btn mb-0">Previous</button>
                <button type="submit" class="btn btn-primary next-btn mb-0">Save & Next</button>
            </div>

        </form>
        <!-- <div v-else>
            <div class="d-flex justify-content-between mt-3">
                <button type="button" @click="onChangeStep(2)" class="btn btn-secondary prev-btn mb-0">Previous</button>
                <button type="button" @click="onChangeStep(4)" class="btn btn-primary next-btn mb-0">Next</button>
            </div>
        </div> -->

    </div>
</div>

</template>

<script>
var filename__ = " [components > companydash > CompanyDashCourseMediaPanelComponent.vue] " ;

import axios from 'axios';

import ContentIdMainImageFileUploadPanelComponent from '../content/ContentIdMainImageFileUploadPanelComponent.vue' ;

export default {
    components: {
        'content_id-main-image-fileupload-panel-component': ContentIdMainImageFileUploadPanelComponent,
    },
    props: ['content_id','course'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            // step3 
            inputIsText : this.course ? this.course.is_fronttext : false , // 텍스트 위주 
            inputKind : this.course ? this.course.kind : 2,

            inputUrl: null ,
            inputYoutubestr : null ,
            inputYoutubeTotalstr: null,
           
        }
    },
    created(){
        // console.log(filename__+" content_id:", this.content_id);  //~~log    
        // console.log(filename__+" course:", this.course);  //~~log    
        if(this.course){
            if(this.course.youtubestr){
                this.inputYoutubestr = this.course.youtubestr ;
            }
        }
    },
    computed: {
        isTextCompu(){
            return this.inputIsText;
        }, 
        urlCompu(){
            if(this.inputYoutubestr){
                return 'https://www.youtube.com/embed/'+this.inputYoutubestr+'?autohide=0&showinfo=0&controls=0' ;
            } else {
                return this.inputUrl;
            }   
        }
    },
    methods: {
        handleError(){
            this.error = null;
        },
        onChangeTotal(event){
            if(event.target.value.length > 0){
                const idx = event.target.value.indexOf('com/watch?v=');
                // console.log(filename__+" onChangeTotal() idx:", idx);  //~~log    
                const first_idx = idx + 12 ;  
                const last_idx = event.target.value.length-1 ;

                // console.log(filename__+" onChangeTotal() first_idx:", first_idx);  //~~log    
                // console.log(filename__+" onChangeTotal() last_idx:", last_idx);  //~~log    
                // console.log(filename__+" onChangeTotal() 문자열:", event.target.value.substr(first_idx,last_idx));  //~~log    
                
                this.inputYoutubestr = event.target.value.substr(first_idx,last_idx) ;
            }
        },
		onChangeIsText(){
			this.inputIsText = !this.inputIsText ;
		},        
		onChangeKind(_kind){
			this.inputKind = _kind ;
		},        
		onChangeStep(_step){
			this.$emit('changestep', _step) ;
            return ;
		},

        async sendForm(){
            let funcname__ = ' sendForm() '

            if(this.inputYoutubestr){
                this.inputUrl = 'https://www.youtube.com/embed/'+this.inputYoutubestr+'?autohide=0&showinfo=0&controls=0'
            }
            let dataForm = { 
                'is_fronttext': this.inputIsText,
                'kind': this.inputKind,
                'youtubestr' : this.inputYoutubestr, 
                'url': this.inputUrl,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if(this.inputIsText == false) {
                // if( this.inputKind === '' || this.inputKind === undefined || this.inputKind === null ) {
                //     this.formIsValid = false ;
                //     this.dialogTitle = 'Error'
                //     this.error ="이미지나 동영상 링크를 력해주세요.";
                //     return ;
                // }
                if(this.inputKind == '2' || this.inputKind == 2) {
                    if( this.inputUrl === '' || this.inputUrl === undefined || this.inputUrl === null ) {
                        this.formIsValid = false ;
                        this.dialogTitle = 'Error'
                        this.error ="동영상 링크를 입력해주세요.";
                        return ;
                    }
                }
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/media/update/'+this.content_id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle = "Success" ;
                        this.error = "수정되었습니다.";

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('changestep', 4) ;

                            }, 500);
                        }                        


                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },



    },
    
}
</script>