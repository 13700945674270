<template>
    <div>

        <main>
	
    <!-- =======================
    Page Banner START -->
    <section class="pt-5 pb-0" style="background-image:url(assets/images/element/map.svg); background-position: center left; background-size: cover;">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-xl-6 text-center mx-auto">
                    <!-- Title -->
                    <h6 class="text-primary">Contact us</h6>
                    <h1 class="mb-4">여기에서 도와드립니다!</h1>
                </div>
            </div>
            
            <!-- Contact info box -->
            <div class="row g-4 g-md-5 mt-0 mt-lg-3">
                <!-- Box item -->
                <div class="col-lg-6 mt-lg-0">
                    <div class="card card-body bg-primary shadow py-5 text-center h-100">
                        <!-- Title -->
                        <h5 class="text-white mb-3">고객 지원</h5>
                        <ul class="list-inline mb-0">
                            <!-- Address -->
                            <li class="list-item mb-3">
                                <a href="#" class="text-white"> <i class="fas fa-fw fa-map-marker-alt me-2 mt-1"></i>{{ nameCompu }}</a>
                            </li>
                            <!-- Phone number -->
                            <li class="list-item mb-3">
                                <a href="#" class="text-white"> <i class="fas fa-fw fa-phone-alt me-2"></i>Tel: {{ phoneCompu }}</a>
                            </li>
                            <!-- Email id -->
                            <li class="list-item mb-0">
                                <a href="#" class="text-white"> <i class="far fa-fw fa-envelope me-2"></i>{{ emailCompu }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
    
                <!-- Box item -->
                <div class="col-lg-6 mt-lg-0">
                    <div class="card card-body shadow py-5 text-center h-100">
                        <!-- Title -->
                        <h5 class="mb-3">주소</h5>
                        <ul class="list-inline mb-0">
                            <!-- Address -->
                            <li class="list-item mb-3 h6 fw-light">
                                <a href="#"> <i class="fas fa-fw fa-map-marker-alt me-2 mt-1"></i>{{ contactaddrCompu }}</a>
                            </li>
                            <!-- Phone number -->
                            <!-- <li class="list-item mb-3 h6 fw-light">
                                <a href="#"> 여의도역 5번 출구</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
    
                <!-- Box item -->
                <!-- <div class="col-lg-4 mt-lg-0">
                    <div class="card card-body shadow py-5 text-center h-100">
                        <h5 class="mb-3">에듀쿱</h5>
                        <ul class="list-inline mb-0">
                            <li class="list-item mb-3 h6 fw-light">
                                <a href="#"> <i class="fas fa-fw fa-map-marker-alt me-2 mt-1"></i>서울혁신파크미래청416호</a>
                            </li>
                            <li class="list-item mb-3 h6 fw-light">
                                <a href="#"> <i class="fas fa-fw fa-phone-alt me-2"></i>02-3667-2022</a>
                            </li>
                            <li class="list-item mb-0 h6 fw-light">
                                <a href="#"> <i class="far fa-fw fa-envelope me-2"></i>educoop@ictact.kr </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Image and contact form START -->
    <section >
        <div class="container">
            <div class="row g-4 g-lg-0 align-items-center">
    
                <div class="col-md-6 align-items-center text-center">
                    <!-- Image -->
                    <img src="assets/images/element/contact.svg" class="h-400px" alt="">
    
                    <!-- Social media button -->
                    <!-- <div class="d-sm-flex align-items-center justify-content-center mt-2 mt-sm-4">
                        <h5 class="mb-0">SNS : </h5> 
                        <ul class="list-inline mb-0 ms-sm-2">
                            <li class="list-inline-item"> <a class="fs-5 me-1 text-facebook" href="#"><i class="fab fa-fw fa-facebook-square"></i></a> </li>
                            <li class="list-inline-item"> <a class="fs-5 me-1 text-instagram" href="#"><i class="fab fa-fw fa-instagram"></i></a> </li>
                            <li class="list-inline-item"> <a class="fs-5 me-1 text-twitter" href="#"><i class="fab fa-fw fa-twitter"></i></a> </li>
                            <li class="list-inline-item"> <a class="fs-5 me-1 text-linkedin" href="#"><i class="fab fa-fw fa-linkedin-in"></i></a> </li>
                            <li class="list-inline-item"> <a class="fs-5 me-1 text-dribbble" href="#"><i class="fas fa-fw fa-basketball-ball"></i></a> </li>
                            <li class="list-inline-item"> <a class="fs-5 me-1 text-pinterest" href="#"><i class="fab fa-fw fa-pinterest"></i></a> </li>
                        </ul>
                    </div> -->
                </div>
    
                <!-- Contact form START -->
                <div class="col-md-6">
                    <!-- Title -->
                    <h2 class="mt-4 mt-md-0">의견을 남겨주세요</h2>
                    <p>의견에 대해서 신속히 대응해드리겠습니다. 여러분들의 의견으로 우리는 발전한다는 것을 믿습니다.</p>

                    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
                        <p>{{ error }}</p>
                    </base-dialog>

                    <base-dialog :show="isLoading" title="Loading..." fixed>
                        <p>Loading...</p>
                        <base-spinner></base-spinner>
                    </base-dialog>
                    
                    <form @submit.prevent="sendForm" >
                        <!-- Name -->
                        <div class="mb-4 bg-light-input">
                            <label for="yourName" class="form-label">성함 *</label>
                            <input type="text" class="form-control form-control-lg" id="yourName" v-model.trim="inputName">
                        </div>
                        <!-- Email -->
                        <div class="mb-4 bg-light-input">
                            <label for="emailInput" class="form-label">Email address *</label>
                            <input type="email" class="form-control form-control-lg" id="emailInput" v-model.trim="inputEmail">
                        </div>
                        <!-- Message -->
                        <div class="mb-4 bg-light-input">
                            <label for="textareaBox" class="form-label">남기실 메시지 *</label>
                            <textarea class="form-control" id="textareaBox" rows="4" v-model.trim="inputContent"></textarea>
                        </div>
                        <!-- Button -->
                        <div class="d-grid">
                            <button type="submit" class="btn btn-lg btn-primary mb-0" >의견 보내기</button>
                        </div>	
                    </form>
                </div>
                <!-- Contact form END -->
            </div>
        </div>
    </section>
    <!-- =======================
    Image and contact form END -->
    
    <!-- =======================
    Map START -->
    <!-- <section class="pt-0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <iframe class="w-100 h-400px grayscale rounded" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9663095343008!2d-74.00425878428698!3d40.74076684379132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259bf5c1654f3%3A0xc80f9cfce5383d5d!2sGoogle!5e0!3m2!1sen!2sin!4v1586000412513!5m2!1sen!2sin" height="500" style="border:0;" aria-hidden="false" tabindex="0"></iframe>	
                </div>
            </div>
        </div>
    </section>
     -->
    <!-- =======================
    Map END -->
    
    </main>
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pages > faq > Contactus.vue]" ;

import axios from 'axios';
// import MemberInfoComp from '../../components/account/MemberInfoComp.vue';
export default {
    components: {
        // "member-info-comp": MemberInfoComp
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            inputName: null, 
            inputEmail: null, 
            inputContent: null, 
        }
    },
    computed: {
        nameCompu(){
            return this.$store.getters['setting/name'];
        },
        copyrightCompu(){
            return this.$store.getters['setting/copyright'];
        },
        emailCompu(){
            return this.$store.getters['setting/email'];
        },
        descriptionCompu(){
            return this.$store.getters['setting/description'];
        },
        phoneCompu(){
            return this.$store.getters['setting/phone'];
        },
        worktimeCompu(){
            return this.$store.getters['setting/worktime'];
        },
        supportemailCompu(){
            return this.$store.getters['setting/supportemail'];
        },
        contactaddrCompu(){
            return this.$store.getters['setting/contactaddr'];
        },        
    },    
    created(){
    },
    methods: {
        handleError(){
            this.error = null;

        },
        // 전송하기  
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'category': 'contactus', 
                'name': this.inputName, 
                'email': this.inputEmail,
                'content':this.inputContent,
            } ;  
            
            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputName === '' || this.inputName === undefined || this.inputName === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이름을 입력해주세요.";
                return ;
            }
            if( this.inputEmail === '' || this.inputEmail === undefined || this.inputEmail === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이메일 주소가 이상이 있습니다.";
                return ;
            }
            if( !this.inputEmail.includes('@') ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이메일 주소가 이상이 있습니다.";
                return ;
            }
            if( this.inputContent === '' || this.inputContent === undefined || this.inputContent === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="메세지를 입력해주세요.";
                return ;
            }

            this.isLoading = true ;
            
            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/faq/contactus/create',
                    data: dataForm,
                }).catch( err => {

                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                
                });
                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }
                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        this.inputName = null ;
                        this.inputEmail = null ;
                        this.inputContent = null ;
                        this.dialogTitle ='Success' ;
                        this.error = '문의 전송에 성공했습니다. 최대한 빠른 시간 내에 답변드리겠습니다.';     
                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                        this.dialogTitle ="전송 실패" ;
                        this.error = serverData.data.error_description;
                    }
                } else {
                    this.isLoading = false ;
                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }
            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);
                this.isLoading = false ;
                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }
            this.isLoading = false ;
        },
    }
}
</script>

<style scoped>
</style>
