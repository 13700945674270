<template>
    <tr>
        <!-- Table data -->
        <td>{{num}}</td>

        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="avatar avatar-md">
                    <!-- <router-link :to="detailLink" > -->
                        <img v-if="create_photo" :src="create_photo" class="rounded-circle" alt="">
                        <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                    <!-- </router-link> -->
                </div>
            </div>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        {{create_name}}
                        <!-- <router-link :to="detailLink" >{{create_name}}</router-link> -->
                    </h6>
                </div>
            </div>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    {{create_email}}
                </div>
            </div>
        </td>

    </tr>    
</template>

<script>
export default {
    components: {},
    props: [
        'id','num','create_id','create_name','create_email','create_photo','is_active','status','date',
        'page', 
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        // detailLink(){
        //     return '/companydash/worker/'+this.id+'?page='+this.page ; 
        // },

    },
    methods: {

    },
    
}
</script>