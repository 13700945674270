<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <form @submit.prevent="sendCreateForm" > 
        <div class="row g-4">
            <!-- Course title -->
            <!-- 
            <div class="col-2">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputOnoff" @click="onChangeOnoff">
                    <label v-if="inputOnoff" class="form-check-label" for="onoffPublic">Online </label>
                    <label v-else class="form-check-label" for="onoffPublic">Offline</label>
                </div>
            </div>
            <div class="col-3">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" role="switch" id="certificatePublic" checked="" v-model="inputCertificate" @click="onChangeCertificate">
                    <label v-if="inputCertificate" class="form-check-label" for="certificatePublic">Certificate </label>
                    <label v-else class="form-check-label" for="certificatePublic">No Certificate</label>
                </div>
            </div>
-->
            <div class="col-12">
                <label class="form-label">타이틀</label>
                <input class="form-control" type="text" placeholder="Enter course title" v-model.trim="inputTitle" >
            </div>

            <!-- Short description -->
            <div class="col-12">
                <label class="form-label">내용에 대한 짧은 설명</label>
                <textarea class="form-control" rows="5" placeholder="Enter keywords" v-model.trim="inputSubtitle"></textarea>
            </div>

            <!-- Course category -->
            <div class="col-md-6">
                <label class="form-label">정보 카테고리(선택해주세요)</label>
                <select 
                        class="form-select js-choice border-0 z-index-9 bg-transparent form-control" 
                        aria-label=".form-select-sm" 
                        data-max-item-count="4" 
                        data-search-enabled="true"
                        v-model="inputCategory"
                        @change="onChangeSelectCategory($event)"
                    >
                    <option disabled value="">Please select category</option>                                                            
                    <option 
                        v-for="opt in categoryList"  
                            :key="opt.id" 
                            :value="opt.id" 
                        >
                        {{ opt.title}}
                    </option>                                                        
                </select>
            </div>

            <!-- Course level -->
            <div class="col-md-6">
                <label class="form-label">정보수준(선택해주세요)</label>
                <select 
                        class="form-select js-choice border-0 z-index-9 bg-transparent" 
                        aria-label=".form-select-sm" 
                        data-search-enabled="false" 
                        data-max-item-count="4" 
                        data-remove-item-button="true"
                        name="level"
                        v-model="inputLevel"
                        @change="onChangeSelectLevel($event)"
                    >
                    <!-- <option value="" selected>--- Select course level----</option> -->
                    <option disabled value="" selected>Please select level</option>
                    <option :value=0 >All level</option>
                    <option :value=1 >Beginner</option>
                    <option :value=2 >Intermediate</option>
                    <option :Value=3 >Advanced</option>
                </select>
            </div>
                    
            <!-- Step 1 button -->
            <div class="d-flex justify-content-end mt-3">
                <button type="submit" class="btn btn-primary next-btn mb-0">
                    <span>Save</span>  & Next
                </button>
            </div>
        </div>
        <!-- Basic information START -->
    </form>    
</div>
</template>

<script>
var filename__ = " [componetns > companydash > CiompanyDashBlogIntroCreateComponent.vue] " ;

import axios from 'axios';

export default {
    components: {},
    props: [],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            // step1
            inputId : null,
            inputMethod : null, 
            inputUrl : null, 

            inputIsDisplay : false,
            inputOnoff : true, 
            inputCertificate : false, 

            inputTitle : null, 
            inputSubtitle : null, 

            categoryList: [],
            inputCategory: null,
            inputLevel : null, 

            inputCoursetime : 0, 
            inputLecturecount : 0, 

            inputIsPeriod : false, 
            inputIsApply : false, 
            inputStartapply : null, 
            inputEndapply : null, 
            inputStartdate : null, 
            inputEnddate : null, 

            inputIsPrice: false,
            inputPrice: 0,
            inputIsDiscount: false, 
            inputDiscountprice: 0, 

            inputIsAuto : false, 
            inputDaycount: 0,

            inputIsAmount : false,
            inputAmount: 0, 
       
        }
    },
    created(){
        this.getCategoryList();
    },
    computed: {

    },
    methods: {
        handleError(){
            this.error = null;

        },
		onChangeIsDisplay(){
			this.inputIsDisplay = !this.inputIsDisplay ;
		},
		onChangeOnoff(){
			this.inputOnoff = !this.inputOnoff ;
		},
		onChangeCertificate(){
			this.inputCertificate = !this.inputCertificate ;
		},
		onChangePrice(){
			this.inputIsPrice = !this.inputIsPrice ;
		},
		onChangePeriod(){
			this.inputIsPeriod = !this.inputIsPeriod ;
		},
		onChangeApply(){
			this.inputIsApply = !this.inputIsApply ;
		},
		onChangeAuto(){
			this.inputIsAuto = !this.inputIsAuto ;
		},
		onChangeAmount(){
			this.inputIsAmount = !this.inputIsAmount ;
		},

        onChangeSelectCategory(event){
            // let funcname__ ="onChangeSelectCategory()"
            // console.log(filename__+ ""+funcname__+" value:",event.target.value)
            this.inputCategory = event.target.value;
        },  
        onChangeSelectLevel(event){
            // let funcname__ ="onChangeSelectLevel()"
            // console.log(filename__+ ""+funcname__+" onChangeSelectLevel() value:",event.target.value)
            this.inputLevel = parseInt(event.target.value);
        },  

        async getCategoryList(){
            let funcname__ ="getCategoryList() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/course/category/all/companydash',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        title:item.title,
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.categoryList = list;

                // console.log(filename__+''+funcname__+" categoryList:", this.categoryList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },

        sendCreateForm(){
            if(this.inputId){
                this.inputMethod ='put' ;
                this.inputUrl = '/api/content/blog/intro/update/'+this.inputId ;
            } else {
                this.inputMethod ='post' ;
                this.inputUrl = '/api/content/blog/create'
            }
            this.sendInroCreateForm();
        },
        async sendInroCreateForm(){
            let funcname__ ="sendInroCreateForm() ";

            let dataForm = { 
                'is_display': this.inputIsDisplay,
                'onoff': this.inputOnoff,
                'is_certificate': this.inputCertificate, 
                'title': this.inputTitle,
                'subtitle': this.inputSubtitle,
                'category' : this.inputCategory,
                'level' : this.inputLevel,

                'is_period' : this.inputIsPeriod,
                'is_apply' : this.inputIsApply,
                'startapply' : this.inputStartapply,
                'endapply' : this.inputEndapply,
                'startdate' : this.inputStartdate,
                'enddate' : this.inputEnddate,

                'is_auto' : this.inputIsAuto,
                'daycount' : this.inputDaycount, 

                'is_price' : this.inputIsPrice,
                'price' : this.inputPrice,
                'is_discount' : this.inputIsDiscount,
                'discountprice': this.inputDiscountprice,

                'is_amount' : this.inputIsAmount,
                'amount' : this.inputAmount,

            } ;  

            console.log(filename__+ ""+funcname__+" dataForm:",dataForm);

            if( this.inputTitle === '' || this.inputTitle === undefined || this.inputTitle === null ) {
                console.log(filename__+ ""+funcname__+" if title :",this.inputTitle);
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="타이틀을  입력해주세요.";
                return ;
            } else {
                console.log(filename__+ ""+funcname__+" else title :",this.inputTitle);
            }

            if( this.inputSubtitle === '' || this.inputSubtitle === undefined || this.inputSubtitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="짧은 설명을 입력해주세요.";
                return ;
            }
            if( this.inputCategory === '' || this.inputCategory === undefined || this.inputCategory === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="카테고리를 선택해주세요.";
                return ;
            }
            if( this.inputLevel === '' || this.inputLevel === undefined || this.inputLevel === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="레벨을 선택해 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: this.inputMethod,   //'post',
                    url: this.inputUrl,         // '/api/content/course/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }
    
                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        // 처음 생성할 때만 적용 
                        this.dialogTitle ="Success" ;

                        if(this.inputMethod === 'post') {
                            this.inputId = serverData.data.id ;
                            this.error = "정보가 생성되었습니다.";          
                        } else {
                            this.error = "수정되었습니다.";          
                        }

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('changestep', 2) ;

                            }, 700);
                        }                        
                        
                        this.$emit('updateContentId', this.inputId)

                        // this.dialogTitle ='Success' ;
                        // this.error = '성공했습니다.';    

                        // this.onChangeStep(2);

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    },
    
}
</script>