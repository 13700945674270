<template>
<div>
    <!-- 삭제 모달  -->
    <!-- teleport 를 통해서 body에 붙임  -->
    <teleport to="body">
        <div class="modal fade" :id="idDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" :id="idDeleteCompu">Delete</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div> 
    </teleport>

    <form @submit.prevent="sendUpdateForm" class="row g-4 align-items-center mt-1">
        <!-- Radio items -->
        <div class="col-lg-5">
            <label v-if="inputName == 'youtube'" class="form-label"><i class="fab fa-youtube text-google-icon me-2"></i>Youtube</label>
            <label v-if="inputName == 'twitter'" class="form-label"><i class="fab fa-twitter text-twitter me-2"></i>Twitter</label>
            <label v-if="inputName == 'instagram'" class="form-label"><i class="fab fa-instagram text-facebook me-2"></i>Instagram</label>
            <label v-if="inputName == 'facebook'" class="form-label"><i class="fab fa-facebook  me-2"></i>Facebook</label>
            <label v-if="inputName == 'linkedin'" class="form-label"><i class="fab fa-fw fa-linkedin-in me-2"></i>LinkedIn</label>

            <input type="text" class="form-control" placeholder="social site" v-model.trim="inputName" disabled>
        </div>
        <div class="col-lg-7">
            <label class="form-label">url</label>
            <input type="text" class="form-control" placeholder="url" v-model.trim="inputUrl">
        </div>


        <div class="col-lg-6">
            <label class="form-label"><i v-if="!max" @click="downSeq" class="fas fa-arrow-alt-circle-down me-2" style="font-size:27px;cursor:pointer"></i></label>
            <label class="form-label"><i v-if="!min" @click="upSeq" class="fas fa-arrow-alt-circle-up  me-2" style="font-size:27px;cursor:pointer"></i></label>
        </div>
        <div class="col-lg-6 text-lg-end">
            <button type="submit" class="btn btn-success mb-0">Save change</button>
            <button type="button" class="btn btn-sm btn-danger mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu"><i class="bi bi-trash"></i></button>
        </div>

        <!-- <div class="d-sm-flex justify-content-end">
            <button type="submit" class="btn btn-success mb-0">Save change</button>
        </div> -->
    </form>
    <hr/>
</div>    
</template>

<script>

export default {
    components: {
    },
    props: [
        'id', 'company_id',
        'name','url',
        'seq','min','max',
    ],

    data() {
        return {
           error: null,     
           dialogTitle: null,  

           inputName : this.name,
           inputUrl : this.url,

        }
    },
    
    computed: {
        idDeleteCompu(){
            return 'deleteModalSkill'+this.id
        },
        idDeleteShapCompu(){
            return '#deleteModalSkill'+this.id
        },
    },
    methods: {
        handleError(){
            this.error = null;
        },

        upSeq(){
            this.$emit('changeUpSeq', this.id)
        },
        downSeq(){
            this.$emit('changeDownSeq', this.id)
        },
        sendUpdateForm(){
            this.$emit('sendUpdateForm',this.id, this.inputName, this.inputUrl)
        },
        removeForm(){
            this.$emit('removeForm', this.id)
        },


    },
    
}
</script>