<template>
<div class="row g-4 mt-1">

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Course information START -->
    <div class="col-12">
        <div class="card bg-transparent border rounded-3 h-100">

            <!-- Catd header -->
            <div class="card-header bg-light border-bottom">
                <div class="d-sm-flex align-items-center">
                    <div class="ms-sm-3 mt-2 mt-sm-0">
                        <button v-if="inputStatus == 0" type="button" class="btn btn-danger-soft me-1 mb-1 mb-lg-0">미처리</button>
                        <button v-if="inputStatus == 2" type="button" class="btn btn-primary-soft me-1 mb-1 mb-lg-0">진행중</button>
                        <button v-if="inputStatus == 8" type="button" class="btn btn-warning-soft me-1 mb-1 mb-lg-0">보류중</button>
                        <button v-if="inputStatus == 9" type="button" class="btn btn-success-soft me-1 mb-1 mb-lg-0">Accept</button>
                        <button v-if="inputStatus == -1" type="button" class="btn btn-secondary-soft me-1 mb-1 mb-lg-0">Reject</button>

                    </div>
                </div>

            </div>

            <!-- Card body START -->
            <div class="card-body">

                <!-- Course image and info START -->
                <div class="row g-4">
                    <!-- Course info and avatar -->
                    <div class="col-md-12">

                        <h5 class="mb-3">요청자 이름 : {{inputName}}</h5>
                        <h6 class="mb-3">요청자 연락처 : {{inputPhone}}</h6>
                        <h6 class="mb-3">요청자 이메일 : {{inputEmail}}</h6>

                        <!-- Info -->
                        <p class="mb-3 white-space-newline">{{inputContent}}</p>

                    </div>
                    <hr/>
                    <div class="col-md-1">
                        <div class="avatar avatar-md ">
                            <router-link :to="openLink"  class="stretched-link">
                                <img v-if="inputCreatePhoto" :src="inputCreatePhoto" class="rounded-circle" alt="">
                                <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                            </router-link>
                        </div>
                            <!-- <div class="mb-0 ms-2">
                                <h6 class="mb-0">{{inputCreateName}} </h6>
                            </div> -->

                            <!-- <h5 class="card-header-title">{{inputCreateName}}</h5> -->
                    </div>
                    <div class="col-md-4">
                        <div class="mb-0">
                            
                            <h6 class="mb-0">
                                <router-link :to="openLink"  class="stretched-link">
                                    {{inputCreateName}} 
                                </router-link>
                            </h6>
                        </div>

                        <small>
                            <router-link :to="openLink"  class="stretched-link">
                                {{inputCreateEmail}}
                            </router-link>
                        </small>

                    </div>
                </div>
                <!-- Course image and info END -->
            </div>
            <!-- Card body END -->
        </div>
    </div>
    <!-- Course information END -->


</div> <!-- Row END -->

</template>

<script>
var filename__= "[components > admin > company > AdminCompanyRequestDetailComponent.vue] "
import axios from 'axios';

export default {
    components: {},
    props: [
        'id','create_id',
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            inputCreateName : null,
            inputCreateEmail : null,
            inputCreatePhoto: null,
            inputName : null, 
            inputPhone : null, 
            inputEmail : null, 
            inputContent : null,
            inputDate : null,
            inputStatus : null,
        }
    },
    created(){
        this.getItem();
    },    
    computed: {
        openLink(){
            return '/admin/member/'+this.create_id ; 
        },
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/company/request/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {


                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+''+funcname__+' response:', data.response);
                    const request = data.response

                    this.inputParent_id = request.parent_id ;
                    this.inputCreateName = request.create_name ;
                    this.inputCreateEmail = request.create_email ;
                    this.inputCreatePhoto = request.create_photo ;
                    this.inputName = request.name ;
                    this.inputPhone = request.phone ;
                    this.inputEmail = request.email ;
                    this.inputContent = request.content ;

                    this.inputStatus = request.status ;
                    this.inputDate = request.date ;

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description; 
                }
            }

            this.isLoading = false ;     
        },

    },
    
}
</script>
<style scoped>

</style> 