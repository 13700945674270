<template>
    <div v-if="pagination" class="card-footer bg-transparent pt-0 px-0">
        <!-- Pagination START -->
        <div class="d-sm-flex justify-content-sm-between align-items-sm-center">
            <!-- Content -->
            <p class="mb-0 text-center text-sm-start">Showing {{pagination.page}} to {{pagination.last_page_num}} of {{pagination.total_count}} entries</p>

            <!-- Pagination -->
            <nav class="d-flex justify-content-center mb-0" aria-label="navigation">
                <ul class="pagination pagination-sm pagination-primary-soft mb-0 pb-0 px-0">
                    <!-- previous  -->
                    <li class="page-item mb-0" >
                        <a v-if="(pagination.block_start_page_num-1) > 0" @click="sendPaging(pagination.block_start_page_num-1)" class="page-link" tabindex="-1">
                            <i class="fas fa-angle-left"></i></a>
                        <a v-else class="page-link.disabled" disabled >
                            <i class="fas fa-angle-left"></i></a>
                    </li>

                    <!-- page  -->
                    <li class="page-item mb-0"
                        v-for="(item, index) in (pagination.block_end_page_num - pagination.block_start_page_num +1)"  
                            :key="item"
                            :class=" (pagination.block_start_page_num+index) == pagination.page ? 'active' : '' " 
                            @click="sendPaging(pagination.block_start_page_num+index)"
                        > 
                        <!-- <a v-if="(pagination.block_size > index) && (pagination.block_start_page_num+index <= pagination.last_page_num )" 
                            class="page-link" href="#" @click="sendPaging(pagination.block_start_page_num+index)" > -->
                            <button class="page-link">{{ pagination.block_start_page_num+index}}</button>
                        <!-- </a> -->
                    </li>

                    <!-- next  -->
                    <li class="page-item mb-0">
                        <a v-if="pagination.block_end_page_num < pagination.last_page_num" class="page-link" @click="sendPaging(pagination.block_end_page_num+1)" style="cursor: pointer">
                            <i class="fas fa-angle-right"></i></a>
                        <a v-else class="page-link.disabled" disabled >
                            <i class="fas fa-angle-right"></i></a>
                    </li>
                </ul>
            </nav>
        </div>
        <!-- Pagination END -->
    </div>



</template>
<script>
var filename__ = "[components > admin > yu > AdminPagination.vue]" ;

export default {
    components: {},
    props: ['pagination'],
    data(){
        return {
            
            // block_end_page_num: null,  // 다음 페이지 블록 >>  
            // current_block_num: null,       // 현재 페이지 블록 
            // block_start_page_num: null,   // 이전 페이지 블록 << 
            // block_size: null,      // 페이지 블록 : 한줄에 페이지 몇개까지 
            // last_page_num: null,   // 마지막 페이지 
            // counter: null,
            // page: null,         // 현재 페이지 
            // total_count: null,  //전체 갯수     
        }
    },
    created(){
        // console.log("[components/admin/ui/AdminPagination] pagination:", this.pagination);
        // this.block_end_page_num = this.pagination.block_end_page_num ;
        // this.current_block_num = this.pagination.current_block_num ;
        // this.block_start_page_num = this.pagination.block_start_page_num ;
        // this.block_size = this.pagination.block_size ;
        // this.last_page_num = this.pagination.last_page_num ;
        // this.couter = this.pagination.counter ;
        // this.page = this.pagination.page ;
        // this.total_count = this.pagination.total_count ;
    },
    computed:{

    },
    methods :{
        sendPaging(page){
            console.log(filename__,' sendPaging(page):',page);
            this.$emit("paging", page) 
        }
    },
}
</script>
