<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>
	
    <!-- =======================
    Page Banner START -->
    <student-dashboard-top :title="'My Faq Detail'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
                        
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <student-dashboard-left :menu="'faq'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Edit profile START -->
                    <div class="card bg-transparent border rounded-3 mb-3">
                        <!-- Card header -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="card-header-title mb-0">Faq Detail</h3>
                        </div>
                        <!-- Card body START -->
                        <div class="card-body">
                            <form  class="row g-4">
                                
                                <!-- About me -->
                                <div class="col-12">
                                    <label class="form-label">나의 질문 내역</label>
                                    <p class="form-control font-13 white-space-newline mb-0" >{{ inputContent }}</p>
                                </div>
    
    
                                <!-- Save button -->
                                <div class="d-sm-flex justify-content-between">
                                    <div>
                                        <span v-if="inputStatus == 0" class="badge text-bg-danger">미처리</span>
                                        <span v-if="inputStatus == 1" class="badge text-bg-info">확인중</span>
                                        <span v-if="inputStatus == 2" class="badge text-bg-primary">진행중</span>
                                        <span v-if="inputStatus == 8" class="badge text-bg-warning">보류중</span>
                                        <span v-if="inputStatus == 9" class="badge text-bg-success">처리완료</span>
                                    </div>    
                                    <router-link :to="backToLink" class="btn btn-sm btn-secondary mb-0 me-2">목록</router-link>
                                </div>
                            </form>
                        </div>
                        <!-- Card body END -->
                    </div>
                    <!-- Edit profile END -->
                    <div>
                        <studentdash-faq-reply-panel-component :id ="id"  />
                    </div>

                    
                </div>

                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
</div>
</template>

<script>
// newline__ new-line__
var filename__ = "[pages > studentdash > StudentDashFaqDetail.vue]" ;

import axios from 'axios';

import StudentDashboardTop from '../../components/studentdash/StudentDashboardTop.vue';
import StudentDashboardLeft from '../../components/studentdash/StudentDashboardLeft.vue';
import StudentDashFaqReplyPanelComponent from '../../components/studentdash/StudentDashFaqReplyPanelComponent.vue';

export default {
    components: {
        "student-dashboard-top": StudentDashboardTop,
        "student-dashboard-left": StudentDashboardLeft,
        "studentdash-faq-reply-panel-component": StudentDashFaqReplyPanelComponent,
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            dialogTitle: null,       
            
            inputContent : null,
            inputDate : null,
            inputStatus : null, 

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getItem();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },    
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        backToLink(){
            return '/studentdash/faqs?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },

    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/signin');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            // console.log("[StudentDashProfieleUpdate.vue] isAuth():", this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            // console.log("[StudentDashProfieleUpdate.vue] isUser():", this.$store.getters['auth/userId']);
            return this.$store.getters['auth/userId'] ;
        },

        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/faq/user/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+''+funcname__+' response:', data.response);
                    const item = data.response

                    this.inputContent = item.content ;

                    this.inputStatus = item.status ;
                    this.inputDate = item.date ;

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:'+serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  
                }
            }

            this.isLoading = false ;     
        },

    }
}
</script>

<style scoped>

</style>