<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Authenticating..." fixed>
        <p>Authentiacting...</p>
        <base-spinner></base-spinner>
    </base-dialog>


    <main>

	<section class="p-0 d-flex align-items-center position-relative overflow-hidden">
	
		<div class="container-fluid">
			<div class="row">
				<!-- left -->
				<div class="col-12 col-lg-6 d-md-flex align-items-center justify-content-center bg-primary bg-opacity-10 vh-lg-100">
					<div class="p-3 p-lg-5">
						<!-- Title -->
						<div class="text-center">
							<h2 class="fw-bold">워커스카페에 오신 것을 환영합니다</h2>
							<p class="mb-0 h6 fw-light">플랫폼 노동에 대한 다양한 정보를 접할 수 있습니다.</p>
						</div>
						
						<img src="assets/images/element/02.svg" class="mt-5" alt="">
						
						<!-- <div class="d-sm-flex mt-5 align-items-center justify-content-center">
							
							<ul class="avatar-group mb-2 mb-sm-0">
								<li class="avatar avatar-sm">
									<img class="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt="avatar">
								</li>
								<li class="avatar avatar-sm">
									<img class="avatar-img rounded-circle" src="assets/images/avatar/02.jpg" alt="avatar">
								</li>
								<li class="avatar avatar-sm">
									<img class="avatar-img rounded-circle" src="assets/images/avatar/03.jpg" alt="avatar">
								</li>
								<li class="avatar avatar-sm">
									<img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt="avatar">
								</li>
							</ul>
							
							<p class="mb-0 h6 fw-light ms-0 ms-sm-3">오늘 4명의 학생이 참가했습니다. 이제 귀하의 차려입니다.</p>
						</div> -->
					</div>
				</div>

				<!-- Right -->
				<div class="col-12 col-lg-6 m-auto">

                    <div class="row my-5">
						<div class="col-sm-10 col-xl-8 m-auto">
							<!-- Title -->
							<span class="mb-0 fs-1">👋</span>
							<h1 class="fs-2">워커스 카페 로그인!</h1>
							<p class="lead mb-4">반갑습니다!</p>
							

							<!-- Form START -->
							<form @submit.prevent="submitForm">
								<!-- Email -->
								<div class="mb-4">
									<label for="exampleInputEmail1" class="form-label">이메일 주소 *</label>
									<div class="input-group input-group-lg">
										<span class="input-group-text bg-light rounded-start border-0 text-secondary px-3"><i class="bi bi-envelope-fill"></i></span>
										<input type="email" class="form-control border-0 bg-light rounded-end ps-1" placeholder="E-mail" id="exampleInputEmail1"
                                            v-model.trim="inputEmail"
                                            >
									</div>
								</div>
								<!-- Password -->
								<div class="mb-4">
									<label for="inputPassword5" class="form-label">암호 *</label>
									<div class="input-group input-group-lg">
										<span class="input-group-text bg-light rounded-start border-0 text-secondary px-3"><i class="fas fa-lock"></i></span>
										<input v-if="isShowPassword"  type="text" class="form-control border-0 bg-light rounded-end ps-1" placeholder="password" id="inputPassword5" v-model.trim="inputPassword" >
										<input v-else type="password" class="form-control border-0 bg-light rounded-end ps-1" placeholder="password" id="inputPassword5" v-model.trim="inputPassword" >
                                        <span class="input-group-text p-0 bg-transparent">
                                            <i class="far cursor-pointer p-2 w-40px"
                                                :class="{ 'fa-eye-slash': !isShowPassword, 'fa-eye': isShowPassword }"
                                                @click="toggleShow"                                                    
                                            ></i>
                                        </span>
										<div class="rounded mt-1" id="psw-strength"></div>
									</div>
									<div id="passwordHelpBlock" class="form-text">
										암호는 6자 이상이어야 합니다. 
									</div>
								</div>
								<!-- Check box -->
								<div class="mb-4 d-flex justify-content-between mb-4">
									<!-- <div class="form-check">
										<input type="checkbox" class="form-check-input" id="exampleCheck1">
										<label class="form-check-label" for="exampleCheck1">Remember me</label>
									</div> -->
									<div class="text-primary-hover">
										<router-link to="/forgotpw" class="text-secondary">
											<u>암호를 잊으셨나요?</u>
										</router-link>
									</div>
								</div>
								<!-- Button -->
								<div class="align-items-center mt-0">
									<div class="d-grid">
										<button type="submit" class="btn btn-primary mb-0" >로그인</button>
									</div>
								</div>
							</form>
							<!-- Form END -->

							<!-- Sign up link -->
							<div class="mt-4 text-center">
								<span>계정 ID가 없으신가요? <router-link to="/signup">신규회원 등록</router-link></span>
							</div>
						</div>
					</div> <!-- Row END -->
				</div>
			</div> <!-- Row END -->
		</div>
	</section>
</main>
                
    <hr/>
</div>
</template>

<script>

export default {
    components: {
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
			isShowPassword : false, 
            inputEmail : null, 
            inputPassword : null, 
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     

    computed: {        
    },    
    created(){
   
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle ==='Success'){
                this.$router.replace('/'); 
            }             
        },
        toggleShow() {
            this.isShowPassword = !this.isShowPassword;
        },        
        async submitForm(){
            //validation
            this.formIsValid = true ;

            if( this.inputEmail === '' || this.inputEmail === undefined || this.inputEmail === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이메일 주소를 입력해주세요.";
                return ;
            }

            if( !this.inputEmail.includes('@') ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이메일 주소가 이상이 있습니다.";
                return ;
            }

            if( this.inputPassword.length < 6 )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="비밀번호는 6자리 이상이어야 합니다.";
                return ;
            }

            this.isLoading = true ;

            //send http request...
            const actionPayload = {
                email: this.inputEmail,
                password: this.inputPassword
            };

            try {

                await this.$store.dispatch('auth/Login', actionPayload);  //  /store/modules/auth/actions.js + mutations.js                   

                this.dialogTitle ="Success" ;
                this.error = "로그인에 성공했습니다."

                this.inputEmail = '';
                this.inputPassword = '';
                this.isLoading = false ;     

                if(this.dialogTitle ==='Success'){
                    setTimeout(() => {
                        this.error = null;
                        this.dialogTitle = null 
                        this.$router.replace('/');  //__fix__
                        // this.$router.replace('/admin/courses'); 
                        // this.$router.replace('/admin/course/11'); 
                        // this.$router.replace('/admin/plan/9'); 
                        // this.$router.replace('/companydash/course/cardtmp/11');  
                        // this.$router.replace('/companydash/courses');  
                        // this.$router.replace('/companydash/course/update/98');  
                        // this.$router.replace('/companydash/course/58');  
                        // this.$router.replace('/companydash/blogs');  
                        // this.$router.replace('/companydash/blog/update/82');  
                    }, 500);
                }				
            
            } catch(err){
                this.isLoading = false ; 
                this.inputPassword = '';                   


                // console.log('[Signin.vue]  err:',err);
                // console.error('[Signin.vue]  err.status:', err.status);
                // console.error('[Signin.vue]  err.msg:', err.msg);
				
                this.dialogTitle ="로그인 실패" ;
                this.error = err.error_description;   
                return ;         
            }

            this.isLoading = false ;            
        },
        
    }
}
</script>

<style scoped>

</style>
