<template>
<div>
    <!-- 삭제 모달  -->
    <!-- teleport 를 통해서 body에 붙임  -->
    <teleport to="body">
        <div class="modal fade" :id="idDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" :id="idDeleteCompu">Delete</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeSkillForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div> 
    </teleport>

    <form @submit.prevent="sendSkillUpdateForm" class="row g-4 align-items-center mt-1">
        <!-- Radio items -->
        <div class="col-lg-8">
            <label class="form-label">skill</label>
            <input type="text" class="form-control" placeholder="skill" v-model.trim="inputSkillName">
            <div class="form-text">Enter Skill. </div>
        </div>
        <div class="col-lg-4">
            <label class="form-label">skill 실력 (0~100)</label>
            <input type="number" class="form-control" placeholder="0" v-model.trim="inputSkillPercent">
            <div class="form-text">For skill level </div>
        </div>


        <div class="col-lg-6">
            <label class="form-label"><i v-if="!max" @click="downSeq" class="fas fa-arrow-alt-circle-down me-2" style="font-size:27px;cursor:pointer"></i></label>
            <label class="form-label"><i v-if="!min" @click="upSeq" class="fas fa-arrow-alt-circle-up  me-2" style="font-size:27px;cursor:pointer"></i></label>
        </div>
        <div class="col-lg-6 text-lg-end">
            <button type="submit" class="btn btn-success mb-0">Save change</button>
            <button type="button" class="btn btn-sm btn-danger mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu"><i class="bi bi-trash"></i></button>
        </div>

        <!-- <div class="d-sm-flex justify-content-end">
            <button type="submit" class="btn btn-success mb-0">Save change</button>
        </div> -->
    </form>
    <hr/>
</div>    
</template>

<script>

export default {
    components: {
    },
    props: [
        'id', 'company_id',
        'name','percent',
        'seq','min','max',
    ],

    data() {
        return {
           error: null,     
           dialogTitle: null,  

           inputSkillName : this.name,
           inputSkillPercent : this.percent,

        }
    },
    
    computed: {
        idDeleteCompu(){
            return 'deleteModalSkill'+this.id
        },
        idDeleteShapCompu(){
            return '#deleteModalSkill'+this.id
        },
    },
    methods: {
        handleError(){
            this.error = null;
        },

        upSeq(){
            this.$emit('changeUpSeq', this.id)
        },
        downSeq(){
            this.$emit('changeDownSeq', this.id)
        },
        sendSkillUpdateForm(){
            this.$emit('sendSkillUpdateForm',this.id, this.inputSkillName, this.inputSkillPercent)
        },
        removeSkillForm(){
            this.$emit('removeSkillForm', this.id)
        },


    },
    
}
</script>