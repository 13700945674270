<template>
    <div>

        <main>
	
    <!-- =======================
    Page Banner START -->
    <student-dashboard-top :title="'Notification Detail'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
            <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
                <p>{{ error }}</p>
            </base-dialog>

            <base-dialog :show="isLoading" title="Loading..." fixed>
                <p>Loading...</p>
                <base-spinner></base-spinner>
            </base-dialog>
                        
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <student-dashboard-left :menu="'notification'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Edit profile START -->
                    <div class="card bg-transparent border rounded-3">
                        <!-- Card header -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="card-header-title mb-0">Notification</h3>
                        </div>
                        <!-- Card body START -->
                        <div class="card-body">
                            <!-- Form -->
                            <form @submit.prevent="sendFileForm" class="row g-4">
    
                                <!-- Profile picture -->                                
                                <div class="col-12 justify-content-center align-items-center mb-3">
                                    <label class="form-label">Sender</label>
                                    <div class="d-flex align-items-center">
                                        <div  >
                                            <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                                <!-- Avatar place holder -->
                                                <span class="avatar avatar-xl">
                                                    <img v-if="inputCreatePhoto" :src="inputCreatePhoto" id="uploadfile-1-preview" class="avatar-img rounded-circle border border-white border-3 shadow"  alt="">
                                                    <img v-else src="/assets/images/avatar/user.png" id="uploadfile-1-preview" class="avatar-img rounded-circle border border-white border-3 shadow"  alt="">
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <!-- </div> -->
                            <form @submit.prevent="sendForm" class="row g-4">
                                
                                <!-- About me -->
                                <div class="col-12">
                                    <label class="form-label">Message</label>
                                    <textarea class="form-control" rows="3" v-model.trim="inputContent" placeholder="small message" disabled></textarea>
                                    <!-- <div class="form-text">Brief message for your help.</div>  -->
                                </div>
    
    
                                <!-- Save button -->
                                <div class="d-sm-flex justify-content-end">
                                    <router-link :to="backToLink" class="btn btn-sm btn-secondary mb-0 me-2">목록</router-link>
                                </div>
                            </form>
                        </div>
                        <!-- Card body END -->
                    </div>
                    <!-- Edit profile END -->
                    
                </div>

                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pagess > studentdash> StudentDashNotificationDetail.vue]" ;
import axios from 'axios';

import StudentDashboardTop from '../../components/studentdash/StudentDashboardTop.vue';
import StudentDashboardLeft from '../../components/studentdash/StudentDashboardLeft.vue';
// import StudentDashNotificationDetailComponent from '../../components/studentdash/StudentDashNotificationDetailComponent.vue';

export default {
    components: {
        "student-dashboard-top": StudentDashboardTop,
        "student-dashboard-left": StudentDashboardLeft,
        // "studentdash-notification-detail-component": StudentDashNotificationDetailComponent,
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,       
            
            inputReceiveName : null,
            inputReceiveEmail : null,
            inputReceivePhoto: null,

            inputCreateId : null,
            inputCreateName : null,
            inputCreateEmail : null,
            inputCreatePhoto: null,

            inputContent : null,

            inputDate : null,
            inputIsCheck : null,

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getNotification();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },    
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        backToLink(){
            return '/studentdash/notifications?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },

    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/signin');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            // console.log("[StudentDashProfieleUpdate.vue] isAuth():", this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            // console.log("[StudentDashProfieleUpdate.vue] isUser():", this.$store.getters['auth/userId']);
            return this.$store.getters['auth/userId'] ;
        },

        async getNotification(){
            let funcname__ ="getNotification() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/notification/user/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.code == 200 ){

                    // console.log(filename__+''+funcname__+' response:', data.response);
                    const notification = data.response

                    this.inputCreateId = notification.create_id ;
                    this.inputCreateName = notification.create_name ;
                    this.inputCreateEmail = notification.create_email ;
                    this.inputCreatePhoto = notification.create_photo ;

                    this.inputReceiveName = notification.receive_name ;
                    this.inputReceiveEmail = notification.receive_email ;
                    this.inputReceivePhoto = notification.receive_photo ;

                    this.inputContent = notification.content ;

                    this.inputIsCheck = notification.is_check ;
                    this.inputDate = notification.date ;

                } else {
                    console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.msg;  
                }
            }

            this.isLoading = false ;     
        },

    }
}
</script>

<style scoped>

</style>