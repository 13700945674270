<template>
    <div>

        <main>
	<section class="p-0 d-flex align-items-center position-relative overflow-hidden">
	
		<div class="container-fluid">
			<div class="row">
				<!-- left -->
				<div class="col-12 col-lg-6 d-md-flex align-items-center justify-content-center bg-primary bg-opacity-10 vh-lg-50">
					<div class="p-3 p-lg-5">
						<!-- Title -->
						<div class="text-center">
							<h2 class="fw-bold">워커스카페에 오신 것을 환영합니다</h2>
							<p class="mb-0 h6 fw-light">플랫폼 노동에 대한 다양한 정보를 접할 수 있습니다.</p>
						</div>
						<!-- SVG Image -->
						<img src="assets/images/element/02.svg" style="height:250px;" class="mt-5" alt="">
						
						<!-- <div class="d-sm-flex mt-5 align-items-center justify-content-center">
							<ul class="avatar-group mb-2 mb-sm-0">
								<li class="avatar avatar-sm"><img class="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt="avatar"></li>
								<li class="avatar avatar-sm"><img class="avatar-img rounded-circle" src="assets/images/avatar/02.jpg" alt="avatar"></li>
								<li class="avatar avatar-sm"><img class="avatar-img rounded-circle" src="assets/images/avatar/03.jpg" alt="avatar"></li>
								<li class="avatar avatar-sm"><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt="avatar"></li>
							</ul>
						
							<p class="mb-0 h6 fw-light ms-0 ms-sm-3">오늘 4명의 학생이 등록했습니다. 이제 당신의 차례입니다.</p>
						</div> -->
					</div>
				</div>

				<!-- Right -->
				<div class="col-12 col-lg-6 m-auto">
                    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
                        <p>{{ error }}</p>
                    </base-dialog>

                    <base-dialog :show="isLoading" title="Authenticating..." fixed>
                        <p>Authentiacting...</p>
                        <base-spinner></base-spinner>
                    </base-dialog>


					<div class="row my-5">
						<div class="col-sm-10 col-xl-8 m-auto">
							<!-- Title -->
							<img src="assets/images/element/03.svg" class="h-40px mb-2" alt="">
							<h2>주요 직종 및 지역선택</h2>
							<p class="lead mb-4">직종과 활동지역을 통해서 보다 쉽게 정보를 전달해드릴수있습니다.</p>
						
							<!-- Form START -->
							<form @submit.prevent="sendForm">
                                <!-- 직무 -->
                                <!-- <div class="col-12 mt-5 mb-4">
                                    <div class="row g-xl-0 align-items-center">
                                        <div class="col-lg-2">
                                            <h6 class="mb-lg-0">직종 <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-lg-10">
                                            <div class="d-flex">
                                                <div class="form-check radio-bg-light me-4">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked>
                                                    <label class="form-check-label" for="flexRadioDefault1">
                                                        대리운전
                                                    </label>
                                                </div>
                                                <div class="form-check radio-bg-light me-4">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                                                    <label class="form-check-label" for="flexRadioDefault2">
                                                        택배
                                                    </label>
                                                </div>
                                                <div class="form-check radio-bg-light me-4">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3">
                                                    <label class="form-check-label" for="flexRadioDefault3">
                                                        라이더
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- 직종 -->
                                <div class="col-12 mb-2">
                                    <div class="row g-xl-0 align-items-center">
                                        <div class="col-lg-2">
                                            <h6 class="mb-lg-0">직종 <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-lg-10">
                                            <select class="form-select js-choice z-index-9 border-0 bg-light" aria-label=".form-select-sm"
                                                v-model="inputMainSector"
                                                @change="onChangeSelectMainSector($event)"
                                                >
                                                <option 
                                                    v-for="opt in sectorList"  :key="opt.id"
                                                    :value="opt.id" 
                                                    >
                                                    {{ opt.title}}
                                                </option>                                                        
                                            </select>
                                            <small>&nbsp;&nbsp;* 직종을 선택해주세요</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 mb-6">
                                    <div class="row g-xl-0 align-items-center">
                                        <div class="col-lg-2">
                                            <h6 class="mb-lg-0">세부직종 </h6>
                                        </div>
                                        <div v-if="mainSectorCompu" class="col-lg-10">
                                            <select class="form-select js-choice z-index-9 border-0 bg-light" aria-label=".form-select-sm"
                                                v-model="inputSubSector"
                                                @change="onChangeSelectSubSector($event)"
                                                >
                                                <option 
                                                    v-for="opt in subSectorList"  :key="opt.id"
                                                    :value="opt.id" 
                                                    >
                                                    {{ opt.title}}
                                                </option>                                                        
                                            </select>
                                            <small>&nbsp;&nbsp;* 세부직종을 선택해주세요</small>
                                        </div>
                                    </div>
                                </div>

                                <!-- 지역 -->
                                <div class="col-12 mb-6">
                                    <div class="row g-xl-0 align-items-center">
                                        <div class="col-lg-2">
                                            <h6 class="mb-lg-0">활동지역 <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-lg-10">
                                            <select class="form-select js-choice z-index-9 border-0 bg-light" aria-label=".form-select-sm"
                                                v-model="inputLocation"
                                                @change="onChangeSelectLocation($event)"
                                                >
                                                <option 
                                                    v-for="opt in locationList"  :key="opt.id"
                                                    :value="opt.id" 
                                                    >
                                                    {{ opt.title}}
                                                </option>                                                        
                                            </select>
                                            <small>&nbsp;&nbsp;* 활동지역을 선택해주세요</small>
                                        </div>
                                    </div>
                                </div>

								<!-- Button -->
								<!-- <div class="align-items-center mt-0">
									<div class="d-grid">
										<button type="submit" class="btn btn-primary mb-0" >등록하기</button>
										<button type="submit" class="btn btn-primary mb-0" >다음에 선택하기</button>
									</div>
								</div> -->
                                <div class="col-12 mt-0">
                                    <div class="row g-xl-0 align-items-center">
                                        <div class="col-lg-4 d-grid">
                                            <button type="button" @click="logout" class="btn btn-info mb-0" >다음에 선택하기</button>
                                        </div>
                                        <div class="col-lg-8 d-grid">
                                            <button type="submit" class="btn btn-primary mb-0 ms-2" >직종 및 지역 등록하기</button>
                                        </div>
                                    </div>
                                </div>

							</form>
							<!-- Form END -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</main>
                
    <hr/>
    </div>
</template>

<script>
// __select
var filename__="[pages > auth > SignupSector.vue] "

import axios from 'axios';

export default {
    components: {
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            dialogTitle: null,       

            formIsValid: true,

            inputMainSector : null, 
            inputSubSector : null, 
            inputLocation : null, 
            
            locationList : [], 
            sectorList : [], 
            subSectorList : [],

        }
    },
    computed: {
        is_activeCompu(){
            return this.$store.getters['setting/is_active'];
        },  
        mainSectorCompu(){
            return this.inputMainSector
        }      
    },    
    created() {
      let is_contact = this.$store.getters['setting/is_contact'];
      // 한번도 불러오지 않았으면 
      if(!is_contact) {
        console.log("Signup2.vue에서 getSetting() 해야됨 ")
        // this.getSetting();
      } 
      this.getList();
        
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle ==='Success'){
                this.$router.replace('/signin'); 
            }             
        },
        isUserId(){
            return this.$store.getters['auth/userId'] ;
        },
        logout(){
            this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
            this.$store.dispatch('dashboard/Remove'); //==> store/modules/dashboard/actions.js
            this.$router.replace('/signin');
        },
        onChangeSelectMainSector(event){
            // let funcname__ ="onChangeSelectCategory()"
            console.log(filename__+ " onChangeSelectMainSector() value:",event.target.value)
            this.inputMainSector = event.target.value;

            //서브 카테고리 값들을 입력하기 
            for(let item of this.sectorList ){
                if(item.id === parseInt(event.target.value)){
                    this.subSectorList = item.subtitleList ;
                    break ;
                }
            }

        },  
        onChangeSelectSubSector(event){
            // let funcname__ ="onChangeSelectCategory()"
            console.log(filename__+ " onChangeSelectSubSector() value:",event.target.value)
            this.inputSubSector = event.target.value;
        },  
        onChangeSelectLocation(event){
            // let funcname__ ="onChangeSelectCategory()"
            console.log(filename__+ " onChangeSelectLocation() value:",event.target.value)
            this.inputLocation = event.target.value;
        },  

        async getSetting(refresh=false){
            
            // this.isLoading = true;

            try {
                await this.$store.dispatch('setting/LoadSetting' , { foreceRefresh: refresh } );  //  /store/modules/message/actions.js + mutations.js   

                // this.isLoading = false ; 

            
            } catch(err){
                console.error('[App.vue]store.disptach() err:',err);
                // this.dialogTitle ="Error" ;
                // this.error = err;
            }

            // this.isLoading = false ;
            
        },
        async getList(){
			let funcname__ ="getList()" ;

			this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

			let serverData = await axios({
				method: 'get',
				url: '/api/user/sector/all',

			}).catch( err => {

                let dataForm = { 
                    category: 'errorlog(api catech)' , 
                    filename : filename__,
                    methodname : funcname__,
                    title: 'Error(API)',       
                    content : ''+err,
                } ;  
                console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                axios({
                    method: 'post',
                    url: '/api/errorlog/create',
                    data: dataForm,
                });

                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                return ;
                
			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				// console.log('[AdminCourseCategoryList.vue] profile:', data.profile);
				
				let tmpList = data.response ;
				let sectorList = [];
                
				let locationList = [];

				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
                    // location 
					if(item.category === 'location' && item.is_display === true ) {
						locationList.push({
							id: item.id, 
							category: item.category, 
							depth: item.depth, 
							parent: item.parent, 
							title: item.title, 
							is_display: item.is_display, 
							seq:item.seq,
						});
					}


                    // sector 

					else if(item.category === 'sector' && item.parent_id === 0 && item.is_display === true ) {
						sectorList.push({
							id: item.id, 
							category: item.category, 
							depth: item.depth, 
							parent: item.parent, 
							title: item.title, 
							subtitleList : [],
							is_display: item.is_display, 
							seq:item.seq,
						});
					}

				}

				if(locationList.length > 1) {
					locationList.sort(function(a,b){
						// console.log("seq:",a.seq)
						return a.seq-b.seq
					});
				}

				if(locationList.length > 1) {
					locationList.sort(function(a,b){
						// console.log("seq:",a.seq)
						return a.seq-b.seq
					});
				}

				//서브 타이틀 배열에 추가하기 
				for(let cate1 of sectorList ){
					for(let item2 of tmpList ){
						if (cate1.id == item2.parent_id && item2.is_display === true ) {
							cate1.subtitleList.push({
								// num: cnt++, 
								id: item2.id,
								title: item2.title,    
								seq : item2.seq,    
								is_display: item2.is_display                         
							});
						}
					}

					cate1.subtitleList.sort(function(a,b){
						return a.seq-b.seq
					});

				}
				
                console.log(filename__+''+funcname__+" locationList:", locationList);
                console.log(filename__+''+funcname__+" sectorList:", sectorList);
				
                this.locationList = locationList;
                this.sectorList = sectorList;

			} else {
				console.log(filename__+''+funcname__+' err:');

				this.dialogTitle ="실패" ;                    
				this.error = serverData.data.error_description;  
			}


			this.isLoading = false ;            
		},

        async sendForm(){
            let funcname__ ="sendForm()"

            //validation
            this.formIsValid = true ;

            if( this.inputSubSector === '' || this.inputSubSector === undefined || this.inputSubSector === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="직종을 선택해 주세요.";
                return ;
            }

            if( this.inputLocation === '' || this.inputLocation === undefined || this.inputLocation === false ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="활동지역을 선택해 주세요.";
                return ;
            }

            this.isLoading = true ;

            //send http request...
            const dataForm = {
                id : this.isUserId(),
                main_sector: this.inputMainSector,
                sub_sector: this.inputSubSector,
                location_sector: this.inputLocation
            };

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/user/sector/signup',
                    data: dataForm,

                }).catch( err => {

                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '적용에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 
                                this.$router.replace('/signin'); 
                            }, 1000);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' return err:', serverData.data.error_description);
                        this.dialogTitle ="생성 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;


        },


    }
}
</script>

<style scoped>

</style>
