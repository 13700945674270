export default {

    messageCategory(state){
        return state.messagecategory ;
    },
    isMessageCategory(state){
        return !!state.messagecategory;
    },
    hasMessageCategory(state){
        return state.messagecategory && state.messagecategory.length > 0 ;
    },     
}