<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- company dash notification 모달  -->
    <teleport to="body">
        <div class="modal fade" id="modalNotification" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header btn-primary-soft">
                    <h1 class="modal-title fs-5" id="modalNotification">Clear All Notifications</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>모든 Notifcation이 확인으로 변경되고, 알람 표시가 없어집니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-success-soft" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="clearAllForm" class="btn btn-sm btn-danger-soft" data-bs-dismiss="modal">Clear All</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>       

    <main>
	
    <!-- =======================
    Page Banner START -->
    <company-dashboard-top :title="'Notification'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
                        
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <company-dashboard-left :menu="'notification'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Edit profile START -->

                    <div class="card card-body bg-transparent pb-0 border mb-4">
                        <!-- Search bar -->
                        <div class="col-12 mb-2 d-flex justify-content-between align-items-center">
                            <div>
                                <form class="rounded position-relative" style="display: inline-block;">
                                    <input class="form-control bg-transparent" type="search" placeholder="Search" aria-label="Search" v-model.trim="inputSearch" >
                                    <button @click="onChangeSearch" class="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="button">
                                        <i class="fas fa-search fs-6 "></i>
                                    </button>
                                </form>
                                <button type="button" @click="onChangeSearch" class="btn btn-primary mb-0 ms-2">Search</button>
                                <button type="button" @click="onChangeAll" class="btn btn-primary mb-0 ms-2">All</button>
                            </div>
                            <div>
                                <button type="button" class="btn btn-danger-soft mb-0 ms-2" data-bs-toggle="modal" data-bs-target="#modalNotification">Clear All</button>
                            </div>
                        </div>

                        <!-- Table START -->
                        <div class="table-responsive border-0">
                            <table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
                                <!-- Table head -->
                                <thead>
                                    <tr>
                                        <th scope="col" class="border-0 rounded-start">#</th>
                                        <th scope="col" class="border-0">Message</th>
                                        <th scope="col" class="border-0">Date</th>
                                        <th scope="col" class="border-0">읽음여부</th>
                                        <th scope="col" class="border-0">Send Name</th>
                                        <th scope="col" class="border-0 rounded-end">Action</th>
                                    </tr>
                                </thead>

                                <!-- Table body START -->
                                <tbody>

                                    <!-- Table row -->
                                    <companydash-notification-list-component v-for="item in itemList"
                                        :key = "item.id"
                                        :id ="item.id"
                                        :num ="item.num"
                                        :receive_id = "item.receive_id" 
                                        :receive_name = "item.receive_name" 
                                        :receive_email = "item.receive_email" 
                                        :receive_photo = "item.receive_photo" 

                                        :create_id = "item.create_id" 
                                        :create_name = "item.create_name" 
                                        :create_email = "item.create_email" 
                                        :create_photo = "item.create_photo" 

                                        :content="item.content"
                                        :is_check ="item.is_check"
                                        :date ="item.date"
                                        :search="inputSearch"
                                        :page="page" />

                                </tbody>
                                <!-- Table body END -->
                            </table>
                        </div>
                        <!-- Table END -->

                        <!-- Card footer START -->
                        <div v-if="itemList.length > 0"> 
                            <user-pagination :pagination="paginationCompu" @paging="pagingSend" />
                        </div>
                        <!-- Card footer END -->
                    </div>


                    <!-- Edit profile END -->
                    
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
    </div>
</template>

<script>
var filename__="[pages > companydash > CompanyDashNotificationList.vue]" ;
import axios from 'axios';

import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';

import CompanyDashNotificationListComponent from '../../components/companydash/CompanyDashNotificationListComponent.vue';
import UserPagination from '../../components/ui/UserPagination.vue';

export default {
    components: {
        "company-dashboard-top": CompanyDashboardTop,
        "company-dashboard-left": CompanyDashboardLeft,
        "companydash-notification-list-component" : CompanyDashNotificationListComponent,
        "user-pagination": UserPagination,

    },
    props: [],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,       
            
            itemList: [], 
            page:1,
            current_path:"/companydash/notifications",  //current_path
			pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',     

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.id = this.isUser() ;
            if(this.id) {
                this.getList();
            }
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },    
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        paginationCompu(){
            return this.pagination ;
        },

    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/signin');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            // console.log("[StudentDashProfieleUpdate.vue] isAuth():", this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            // console.log("[StudentDashProfieleUpdate.vue] isUser():", this.$store.getters['auth/userId']);
            return this.$store.getters['auth/userId'] ;
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        onChangeSearch(){
			this.page = 1 ;
            this.getList(); //page=1
        },
        onChangeAll(){
			this.page = 1 ;
			this.inputSearch = '' ;
            this.inputStatus = 0 ;  //status = 10 일 경우만 미처리 함 
            this.getList(); //page=1
        },

        async getList(){
            let funcname__ ="getList()" ;

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }

            if( this.inputSearch === 'undefinded' || this.inputSearch === '') { 
                this.inputSearch = '' ; //pagination, search 에서 계속작동하기 위해서                 
            }             

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/notification/all/company',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
                params: { 
                    page: this.page , 
                    search: this.inputSearch,       
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                }
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log('[StudentDashNotificationList.vue] profile:', data.profile);
                
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        num: counter--, 
                        id: item.id, 

                        receive_id: item.receive_id,
                        receive_name: item.receive_name,
                        receive_email: item.receive_email,
                        receive_photo : item.receive_photo,
                        
                        create_id: item.create_id,
                        create_name: item.create_name,
                        create_email: item.create_email,
                        create_photo : item.create_photo,

                        content: item.content, 
                        is_check:item.is_check,
                        date: item.date
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.itemList = list;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="입력 실패" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },

        // clear all 전송하기  
        async clearAllForm(){
            let funcname__ ="clearAllForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/notification/clear/all',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log("[TopNotificationContainerComponent.vue] clearAllForm() serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';   
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.getList();

                            }, 500);
                        }                        
                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    // this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' sendForm() error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },


    }
}
</script>

<style scoped>

</style>