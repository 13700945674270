<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Tabs START -->
    <ul class="nav nav-pills nav-pills-bg-soft px-3" id="course-pills-tab" role="tablist">
        <!-- Tab item -->
        <li class="nav-item me-2 me-sm-4" role="presentation">
            Description
            <!-- <button class="nav-link mb-0 active" id="course-pills-tab-1" data-bs-toggle="pill" data-bs-target="#course-pills-1" type="button" role="tab" aria-controls="course-pills-1" aria-selected="true">Description</button> -->
        </li>
    </ul>
    <!-- Tabs END -->

    <!-- Tab contents START -->
    <div class="tab-content pt-4 px-3" id="course-pills-tabContent">

        <!-- Content START -->
        <div class="tab-pane fade show active" id="course-pills-1" role="tabpanel" aria-labelledby="course-pills-tab-1">
            <!-- Course detail START -->
            <div class="mb-3" v-html="outputMarkdownCompu"></div>
            <!-- Course detail END -->
            <hr/>
            <div v-if="itemFileList.length > 0 " class="mt-2">
                <label class="form-label" >첨부파일</label> 
                <ul>
                    <li v-for="item in itemFileList" :key="item.id">
                        <a :href="item.filepath" v-text="item.filename" @click.prevent="downloadItem(item.filepath, item.filename)" />
                    </li>
                </ul>
            </div>
        </div>
        <!-- Content END -->

    </div>
    <!-- Tab contents END -->


</div>

</template>

<script>
var filename__ ="[components > blog > BlogDetailBottomTabComponent.vue] " ;

import axios from 'axios';
import { marked } from 'marked';         // yarn add marked  https://marked.js.org/


export default {
    components: {
    },
    props: [
        'content_id', 'blog',
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            // blog : null,
            inputDescription: this.blog.description, 
            itemList : [],

            category : 'blog', 
            subCategory : 'description', 
            fileKind : 'file', 

            itemFileList : [],

        }
    },
    created(){
        this.getFileList()
    },    
    
    computed: {
        outputMarkdownCompu(){
            return marked(this.blog.description);
        },			        
    },
    methods: {
        handleError(){
            this.error = null;
        },
        async getFileList(){
            let funcname__ ="getFileList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/file/contentid/all/'+this.content_id+'/'+this.category+'/'+this.subCategory+'/'+this.fileKind,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                        
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        filename: item.photo.replace(/^.*[\\\/]/, ''), 
                        filepath:item.photo,
                    });
                }

                // console.log(filename__+''+funcname__+" list:", list);
                this.itemFileList = list;

                console.log(filename__+''+funcname__+" itemFileList:", this.itemFileList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  

            }

            this.isLoading = false ;            
        },
        async downloadItem(_filepath, _filename) {
            axios.get(_filepath, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/octet-stream' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = _filename
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        }        


    },
    
}
</script>