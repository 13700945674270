<template>
    <tr>
        <!-- Table data -->
        <td>{{num}}</td>


        <td class="text-center text-sm-start">
            <div v-if="!is_text" class="d-flex align-items-center">
                <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                    <div class="w-auto">
                        <router-link :to="detailLink">
                            <img v-if="image_path" :src="image_path" class="h-50px border border-white border-3 shadow"  alt=""/>
                            <i v-else class="far fa-image fa-2xl" style="font-size:70px"></i>
                        </router-link>
                    </div>
                </label>   
            </div>                    
        </td>
        <td>
            <div class="d-flex align-items-center">
                <router-link :to="detailLink">
                    <span v-if="is_display">{{title}}</span>
                    <span v-else class="text-decoration-line-through "><i class="fas fa-eye-slash me-3"></i>{{title}}</span>
                </router-link>
            </div>                    
        </td>
        <td> 
            <span class="badge bg-success bg-opacity-15 text-success">{{categorystr}}</span>
        </td>
        <td>
            <div class="d-flex align-items-center mb-3">
                <!-- Avatar -->
                <div class="avatar avatar-xs flex-shrink-0">
                    <img v-if="company_photo" class="avatar-img rounded-circle" :src="company_photo" alt="avatar">
                    <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/09.jpg" alt="avatar">
                </div>
                <!-- Info -->
                <div class="ms-2">
                    <h6 class="mb-0 fw-light">{{company_name}}<span v-if="!company_is_display" class="badge text-bg-orange ms-2">안보임</span></h6>
                    <small>{{company_email}}</small>
                </div>
            </div>
            
        </td>

        <!-- <td>
            <div v-if="create_photo" class="avatar me-3">
                <img class="avatar-img rounded-circle shadow" :src="create_photo" alt="avatar">                
            </div>
            <div v-else class="avatar me-3">
                <img class="avatar-img rounded-circle shadow" src="/assets/images/avatar/user.png" alt="avatar">
            </div>
            {{ create_name }}            
        </td>         -->
        <td><span v-if="!is_display" class="badge text-bg-orange">비공개</span></td>
        <td>{{date}}</td>
        <td>
            <router-link :to="detailLink" class="btn btn-sm btn-info-soft mb-0" >View</router-link>
        </td>

    </tr>
</template>

<script>
export default {
    components: {},
    props: [
        'id','num','image_path','title','description','categorystr',
        'create_photo','create_name',
        'company_id','company_name','company_email','company_photo','company_is_display',
        'date',
        'is_text',
        'is_display',
        'page','search',
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            return '/admin/blog/'+this.id+'?page='+this.page+"&search="+this.search ; 
        },
    },
    methods: {

    },
    
}
</script>