<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>
            

<!-- Page main content START -->
<div class="page-content-wrapper border">

    <!-- Title -->
    <div class="row mb-3">
        <!-- <div class="col-12 d-sm-flex justify-content-between align-items-center">
            <h1 class="h3 mb-2 mb-sm-0">Course Details</h1> -->
            <!-- <a href="admin-edit-course-detail.html" class="btn btn-sm btn-primary mb-0">Edit Course</a> -->
            <!-- <router-link :to="backToLink" class="btn btn-sm btn-primary mb-0">목록보기</router-link> -->
        <!-- </div> -->
        <div class="col-8 text-start">
            <h1 class="h3 mb-2 mb-sm-0">Course Comment Detail</h1>
        </div>
        <div class="col-4 text-end">
            <router-link :to="backToLink" class="btn btn-secondary mb-0 me-2">목록</router-link>
        </div>
    </div>

    <div class="row g-4">

        <!-- Course information START -->
        <div class="col-xxl-8">

            <div class="card bg-transparent h-100 ms-3 me-3">

                <!-- Card body START -->
                <div class="card-body">
                    <!-- Profile picture -->
                    <div class="avatar avatar-xl mb-3">
                        <router-link :to="`/admin/member/${inputCreate_id}`" class="stretched-link">
                            <img v-if="inputPhoto" class="avatar-img rounded-circle border border-white border-3 shadow" :src="inputPhoto" alt="">
                            <img v-else class="avatar-img rounded-circle border border-white border-3 shadow" src="/assets/images/avatar/user.png" alt="">
                        </router-link>
                    </div>

                    <!-- Information START -->
                    <div class="row">

                        <!-- Information item -->
                        <div class="col-md-12">
                            <ul class="list-group list-group-borderless">
                                <li class="list-group-item">
                                    <span>Name:</span>
                                    <span class="h6 mb-0">
                                        <router-link :to="`/admin/member/${inputCreate_id}`" class="stretched-link">
                                        {{inputName}}
                                        </router-link>
                                    </span>
                                </li>

                                <!-- <li class="list-group-item">
                                    <span>Email:</span>
                                    <span class="h6 mb-0">{{inputEmail}}</span>
                                </li> -->
                            </ul>
                            <div class="bg-light p-2 rounded mt-3">
                                <div class="d-flex">
                                    <div class="me-2">
                                        <p v-if="inputIsDisplay" class="mb-0 white-space-newline">{{inputContent}}</p>
                                        <p v-else class="mb-0 white-space-newline text-decoration-line-through">{{inputContent}}</p>
                                    </div>
                                    <!-- <small>{{date}}</small> -->
                                </div>
                            </div>
                            <ul class="list-group list-group-borderless mt-3">
                                <li class="list-group-item" >
                                    <h4 v-if="inputIsDisplay">{{inputDescription}}</h4>
                                    <h4 v-else class="text-decoration-line-through">
                                        <i class="far cursor-pointer p-2 w-40px fa-eye-slash"></i>{{inputDescription}}
                                    </h4>
                                </li>
                                <admin-course-comment-reply-list-component  
                                    v-for="item in replyList" 
                                        :key="item.id"
                                        :id="item.id"
                                        :content_id="item.content_id"
                                        :company_id="item.company_id"
                                        :group_id="item.group_id"
                                        :description="item.description"
                                        :create_name="item.create_name"
                                        :create_photo="item.create_photo"
                                        :depth="item.depth"
                                        :is_display="item.is_display"
                                        @sendRefreshForm="sendRefreshForm"
                                    />
                            </ul>


                        </div>

                    </div>
                    <!-- Information END -->
                </div>
                <!-- Card body END -->
            </div>
        </div>
    <!-- Course information END -->

        <!-- Chart START -->
        <div class="col-xxl-4">
            <div class="row g-4">
                <form @submit.prevent="sendDisplayForm" > 
                    <!-- Active student START -->
                    <div class="col-md-6 col-xxl-12">
                        <div class="card bg-transparent border overflow-hidden">
                            <!-- Card header -->
                            <div class="card-header bg-light border-bottom">
                                <h5 class="card-header-title mb-0">보임/숨김</h5>
                            </div>
                            <!-- Card body -->
                            <div class="card-body p-0">
                                <div class="d-sm-flex justify-content-between p-4">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="amountPublic" checked="" v-model="inputIsDisplay" @click="onChangeDisplay">
                                        <label v-if="inputIsDisplay" class="form-check-label" for="amountPublic" >보임</label>
                                        <label v-else class="form-check-label" for="amountPublic" >숨김</label>
                                    </div>
                                </div>
                                
                                <div class="d-grid mt-2 me-4 ms-4 mb-3 ">                                    
                                    <button type="submit" class="btn btn-primary" >수정하기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Active student END -->
                </form>
            </div>
        </div>
        <!-- Chart END -->

    </div> <!-- Row END -->
</div>
<!-- Page main content END -->

<!-- Page content END -->

</div>
</template>

<script>
var filename__="[pages > admin > course > AdminCourseCommentDetail.vue]" ;

import axios from 'axios';

import AdminCourseCommentReplyListComponent from '../../../components/admin/course/AdminCourseCommentReplyListComponent.vue';

export default {
    components: {
        "admin-course-comment-reply-list-component": AdminCourseCommentReplyListComponent,
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,    
            
            inputCreate_id : null,
            inputPhoto : null,
            inputName : null,
            inputEmail : null,

            inutCompany_id : null, 
            inputContent_id : null, 
            inputReplyDescription : null, 

            inputCourseTitle : null, 
            inputCourseImage : null, 

            replyList : [] ,

            inputDescription : null, 
            inputStatus : null,
            inputIsDisplay : null,
            
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getItem();            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },    
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        paginationCompu(){
            return this.pagination ;
        },
        backToLink(){
            return '/admin/course/comments?page='+this.$route.query.page+"&search="+this.$route.query.search
        },

    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUserId(){
            return this.$store.getters['auth/userId'] ;
        },
        onChangeDisplay(){
            this.inputIsDisplay = !this.inputIsDisplay ;
        },

        async getItem(){
            let funcname__ ="getItem()" ;
            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/comment/course/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputCreate_id = data.response.create_id ;
                this.inputPhoto = data.response.create_photo ;
                this.inputName = data.response.create_name ;
                // this.inputEmail = data.response.create_email ;

                this.inutCompany_id = data.response.company_id ;

                this.inputContent_id = data.response.content_id ;
                this.inputCourseTitle = data.response.course_title ;
                this.inputCourseImage = data.response.course_image ;

                this.inputStatus = data.response.status ;
                this.inputIsDisplay = data.response.is_display ;
                this.inputDescription = data.response.description ;

                let tmpList = data.response.reply_group ;
                let list = [];
				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					list.push({
						id: item.id, 
						content_id: item.content_id, 
						company_id: item.company_id, 
						group_id: item.group_id, 
                        description : item.description,
                        depth : item.depth,
                        date : item.date,
                        create_name : item.create_name,
                        create_photo : item.create_photo,
						is_display : item.is_display,                        
					});
				}

				// console.log(filename__+''+funcname__+" tmpList:", tmpList);
				this.replyList = list;

				// console.log(filename__+''+funcname__+" replyList:", this.replyList);


            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },

        // 전송하기  
        async sendDisplayForm(){
            let funcname__ ="sendForm() " ;
            let dataForm = { 
                'is_display':this.inputIsDisplay,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputIsDisplay === '' || this.inputIsDisplay === undefined || this.inputIsDisplay === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="보임/숨김을 선택해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/comment/course/display/'+this.id,
                    headers: { 
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                    
                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.getItem();

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+'return err:', serverData.data.msg);
                        this.dialogTitle ="전송 실패" ;
                        this.error = serverData.data.msg;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;


        },

    }
}
</script>

<style scoped>

</style>