<template>
    <div>
        <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
            <p>{{ error }}</p>
        </base-dialog>
    
        <base-dialog :show="isLoading" title="Loading..." fixed>
            <p>Loading...</p>
            <base-spinner></base-spinner>
        </base-dialog>
    
    
        <!-- Reply 모달  -->
        <teleport to="body">
            <div class="modal fade" id="replyModal" tabindex="-1" aria-labelledby="replyModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header bg-info">
                        <h1 class="modal-title fs-5" id="replyModalLabel">Reply Review</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <label class="form-label">Your Review *</label>
                        <textarea class="form-control" rows="5" v-model.trim="inputReplyDescription"></textarea>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" @click="sendReplyForm" class="btn btn-info" data-bs-dismiss="modal">Reply Review</button>
                    </div>
                    </div>
                </div>
            </div>
        </teleport>
    
        <!-- Reply Update 모달  -->
        <teleport to="body">
            <div class="modal fade" id="updateModal" tabindex="-1" aria-labelledby="updateModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header bg-info">
                        <h1 class="modal-title fs-5" id="updateModalLabel">Reply Update Review</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <label class="form-label">Your Reply Review Update *</label>
                        <textarea class="form-control" rows="5" v-model.trim="inputReplyDescription"></textarea>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" @click="sendReplyForm" class="btn btn-info" data-bs-dismiss="modal">Reply Update Review</button>
                    </div>
                    </div>
                </div>
            </div>
        </teleport>
                    
        <!-- Notification 모달  -->
        <div class="modal fade" id="notificationModal" tabindex="-1" aria-labelledby="notificationModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-orange">
                    <h1 class="modal-title fs-5" id="notificationModalLabel">Notification</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <label class="form-label">Your Message *</label>
                    <textarea class="form-control" rows="5" v-model.trim="inputNotificationContent"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="sendNotificationForm" class="btn btn-orange" data-bs-dismiss="modal">Send Notification</button>
                </div>
                </div>
            </div>
        </div>
    
        <main>
        
        <!-- =======================
        Page Banner START -->
        <company-dashboard-top :title="'Review Detail'" />
        <!-- =======================
        Page Banner END -->
        
        <!-- =======================
        Page content START -->
        <section class="pt-0">
            <div class="container">
    
                                                    
                <div class="row">
        
                    <!-- Left sidebar START -->
                    <div class="col-xl-3">
                        <!-- Responsive offcanvas body START -->
                        <company-dashboard-left :menu="'review'" />
                        <!-- Responsive offcanvas body END -->
                    </div>
                    <!-- Left sidebar END -->
        
                    <!-- Main content START -->
                    <div class="col-xl-9">
                        <!-- Edit profile START -->
                        <div class="card bg-transparent border rounded-3">
                            <!-- Card header -->
                            <div class="card-header bg-transparent border-bottom">
                                <!-- <h3 class="card-header-title mb-0">comment detail</h3> -->
                                <div class="d-sm-flex justify-content-sm-between align-items-center">
                                    <h3 class="mb-2 mb-sm-0">Review Detail</h3>
                                    <router-link :to="backToLink" class="btn btn-secondary">목록</router-link>
                                </div>
    
                            </div>
    
                            <div class="row g-4">
    
                                <div class="col-xxl-12">
    
                                    <div class="card bg-transparent h-100 ms-3 me-3">
    
                                        <!-- Card body START -->
                                        <div class="card-body">
                                            <!-- Profile picture -->
                                            <div class="row mt-3">
                                                <div v-if="!inputIsText" class="col-md-2 w-100px ">
                                                    <img v-if="inputCourseImage" :src="inputCourseImage" class="rounded h-50px" alt="">
                                                    <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" alt="">
                                                </div>
                                                <div class="col-md-6">  
                                                    <h6>{{inputCourseTitle}}</h6>
                                                </div>
                                            </div>
                                            <hr/>
    
                                            <div class="avatar">
                                                <img v-if="inputPhoto" class="avatar-img rounded-circle border border-white border-3 shadow" :src="inputPhoto" alt="">
                                                <img v-else class="avatar-img rounded-circle border border-white border-3 shadow" src="/assets/images/avatar/user.png" alt="">
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <ul class="list-group list-group-borderless">
                                                        <li class="list-group-item">
                                                            <span>Name:</span>
                                                            <span class="h6 mb-0">{{inputName}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
    
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <ul class="list-group list-group-borderless">
                                                        <!-- <li class="list-group-item">
                                                            <span>Email:</span>
                                                            <span class="h6 mb-0">{{inputEmail}}</span>
                                                        </li> -->
                                                    </ul>
                                                    <div class="bg-light p-2 rounded mt-3">
                                                        <div class="d-flex">
                                                            <div class="me-2">
                                                                <p class="mb-0 white-space-newline">{{inputDescription}}</p>
                                                            </div>
                                                            <!-- <small>{{date}}</small> -->
                                                        </div>                                                    
                                                    </div>
                                                    <button type="button" @click="changeIsDisplay" class="btn btn-sm mb-0 ms-2" :class="inputIsDisplay ? 'btn-primary':'btn-orange'" >{{inputIsDisplay ?'보임':'숨김'}}</button>
                                                    
                                                    <ul class="list-group list-group-borderless mt-3">
                                                        <companydash-review-reply-list-component  
                                                            v-for="item in replyList" 
                                                                :key="item.id"
                                                                :id="item.id"
                                                                :content_id="item.content_id"
                                                                :company_id="item.company_id"
                                                                :group_id="item.group_id"
                                                                :description="item.description"
                                                                :create_name="item.create_name"
                                                                :create_photo="item.create_photo"
                                                                :depth="item.depth"
                                                                :is_display="item.is_display"
                                                                @sendRefreshForm="sendRefreshForm"
                                                            />
                                                    </ul>
    
    
                                                </div>
    
                                            </div>
                                            <!-- Information END -->
                                        </div>
                                        <!-- Card body END -->
                                    </div>
                                </div>
                                
                                <!-- Personal information END -->
    
                                <!-- Student status chart START -->
                                <div class="col-xxl-12">
                                    <div class="row g-4">
    
                                        <!-- Enrolled START -->
                                        <div class="col-md-12 col-xxl-12">
                                            <div class="card bg-transparent border overflow-hidden">
                                                <!-- Card header -->
                                                <div class="card-header bg-light border-bottom">
                                                    <h5 class="card-header-title mb-0">상태변경</h5>
                                                </div>
                                                <!-- Card body -->
                                                <div class="card-body p-0">
                                                    <div class="row g-4 align-items-center mt-1">
    
                                                        <div class="col-12">
                                                            <form @submit.prevent="sendStatusForm"  class="row g-xl-0 align-items-center">
                                                                <div class="col-md-1 ms-3">
                                                                    <h6 class="mb-lg-0">상태변경 <span class="text-danger">*</span></h6>
                                                                </div>
                                                            
                                                                <div class="col-md-2 me-3">
                                                                    <select class="form-select js-choice z-index-9 border-0 bg-light" aria-label=".form-select-sm" v-model="inputStatus">
                                                                        <option :value="0">미처리</option>
                                                                        <option :value="2">진행중</option>
                                                                        <option :value="8">보류중</option>
                                                                        <option :value="9">처리완료</option>
                                                                        <option :value="10">Pass</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-md-2">
                                                                    <button type="submit" class="btn btn-primary mb-0">Change Status</button>
                                                                </div>
    
                                                            </form>
                                                        </div>
    
                                                        <hr/>
    
                                                        <!-- Radio items -->
                                                            <!-- <div class="ms-3">
                                                                <div class="form-check form-switch form-check-md">
                                                                    <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsActive" @click="onChangeActive">
                                                                    <label v-if="inputIsActive" class="form-check-label" for="profilePublic">활성화 </label>
                                                                    <label v-else class="form-check-label" for="profilePublic">비활성화</label>
                                                                </div>
                                                            </div> -->
                                                        <div class="d-flex justify-content-end mt-1 mb-2">                                                   
                                                            <!-- <button type="button" class="btn btn-info me-2" data-bs-toggle="modal" data-bs-target="#replyModal">Reply</button> -->
                                                            <!-- <button type="button" class="btn btn-orange mb-0 ms-2" data-bs-toggle="modal" data-bs-target="#notificationModal">Notification</button> -->
                                                            <router-link :to="backToLink" class="btn btn-secondary me-2" >목록</router-link>
                                                        </div>
                                                            
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Enrolled END -->
    
                                    </div>
                                </div>
                                <!-- Student status chart END -->
    
                            </div> <!-- Row END -->
    
                      
    
                        </div>
                        <!-- Edit profile END -->
                        
                    </div>
                    <!-- Main content END -->
                </div><!-- Row END -->
            </div>
        </section>
        <!-- =======================
        Page content END -->
        
        </main>
                    
        <hr/>
        </div>
    </template>
    
    <script>
    var filename__="[pages > companydash > CompanyDashCommentDetail.vue]" ;
    import axios from 'axios';
    
    import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
    import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';
    import CompanyDashReviewReplyListComponent from '../../components/companydash/CompanyDashReviewReplyListComponent.vue';
    
    export default {
        components: {
            "company-dashboard-top": CompanyDashboardTop,
            "company-dashboard-left": CompanyDashboardLeft,
            "companydash-review-reply-list-component": CompanyDashReviewReplyListComponent,
        },
        props: ['id'],
        data() {
            return {
                isLoading: false,
                isApplying: false, 
                error: null,     
                errorLogin : null,             
                dialogTitle: null,    
                
                inputCreate_id : null,
                inputPhoto : null,
                inputName : null,
                inputEmail : null,
    
                inutCompany_id : null, 
                inputContent_id : null, 
                inputReplyDescription : null, 
    
                inputCourseTitle : null, 
                inputCourseImage : null, 
                inputIsText : null, 
    
                replyList : [] ,
    
                inputDescription : null, 
                inputStatus : null,
                inputIsDisplay : null,
                
                inputNotificationContent : null, 
            }
        },
        beforeMount() {
            this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
        },     
        created(){        
            if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
                this.getItem();            
            } else {
                this.dialogTitle ="Error" ;
                this.error = "로그인이 필요합니다";    
                this.errorLogin = true ;
            }       
        },    
        computed: {
            backToLink(){
                return '/companydash/reviews?page='+this.$route.query.page+"&search="+this.$route.query.search
            },
            isLoggedIn(){
                return this.$store.getters['auth/isAuthenticated'];
            },
            paginationCompu(){
                return this.pagination ;
            },
    
        },    
    
        methods: {
            handleError(){
                this.error = null;
                if(this.dialogTitle === 'Login'  ){
                    this.$router.replace('/signin');
                }
    
            },
            isAuth(){
                return this.$store.getters['auth/isAuthenticated'] ;
            },
            isUserId(){
                return this.$store.getters['auth/userId'] ;
            },
            changeIsDisplay(){
                this.inputIsDisplay = !this.inputIsDisplay ;
                this.sendDisplayForm(this.id, this.inputIsDisplay);
            },
    
            sendRefreshForm(){
                this.getItem();
            },
    
            async getItem(){
                let funcname__ ="getItem()" ;
                this.isLoading = true ;   
    
                let serverData = await axios({
                    method: 'get',
                    url: '/api/comment/review/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                
                }).catch( err => {
    
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
    
                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log
    
                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });
    
                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;
    
                        return ;
                    }
    
                });
    
    
                // console.log(filename__+''+funcname__+" serverData:", serverData);
    
                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ; 
                    return ;
                }
                const data = serverData.data ;
    
                // console.log(filename__+''+funcname__+" data:", data);
    
                if( typeof data !== "undefined" &&  data.status_code == 200 ){
                    // console.log('[InstructorDashRequestUpdate.vue] profile:', data.profile);
                    
                    this.inputCreate_id = data.response.create_id ;
                    this.inputPhoto = data.response.create_photo ;
                    this.inputName = data.response.create_name ;
                    // this.inputEmail = data.response.create_email ;
    
                    this.inutCompany_id = data.response.company_id ;
    
                    this.inputContent_id = data.response.content_id ;
                    this.inputCourseTitle = data.response.course_title ;
                    this.inputCourseImage = data.response.course_image ;
                    this.inputIsText = data.response.is_fronttext ;
    
                    this.inputStatus = data.response.status ;
                    this.inputIsDisplay = data.response.is_display ;
                    this.inputDescription = data.response.description ;
    
                    let tmpList = data.response.reply_group ;
                    let list = [];

                    //답변리스트 입력하기 
                    for(let item of tmpList ){
                        list.push({
                            id: item.id, 
                            content_id: item.content_id, 
                            company_id: item.company_id, 
                            group_id: item.group_id, 
                            description : item.description,
                            depth : item.depth,
                            date : item.date,
                            create_name : item.create_name,
                            create_photo : item.create_photo,
                            is_display : item.is_display,                        
                        });
                    }
    
                    // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                    this.replyList = list;
    
                    // console.log(filename__+''+funcname__+" replyList:", this.replyList);
    
    
                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;
                }
    
                this.isLoading = false ;            
            },
    
            // reply comment 발송    
            async sendReplyForm(){
                let funcname__ ="sendReplyForm() "
                let dataForm = { 
                    'category': 'review',
                    'content_id': this.inputContent_id,
                    'company_id': this.inutCompany_id,
                    'parent_id' : parseInt(this.id),
                    'group_id' : parseInt(this.id),
                    'description': this.inputReplyDescription,                
                } ;  
    
                console.log(filename__+''+funcname__+' dataForm:', dataForm);
    
                if( this.inputReplyDescription === '' || this.inputReplyDescription === undefined || this.inputReplyDescription === null ) {
                    this.formIsValid = false ;
                    this.dialogTitle = 'Error'
                    this.error ="내용을 입력해주세요.";
                    return ;
                }
    
                this.isLoading = true ;
    
                try {
                    let serverData = await axios({
                        method: 'post',
                        url: '/api/comment/review/reply/create',
                        data: dataForm,
                        headers: {
                            Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 
    
                }).catch( err => {
    
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
    
                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log
    
                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });
    
                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;
    
                        return ;
                    }
    
                });
    
                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
    
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);
    
                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+'  data:', data);
    
                        if(data.status_code === 201){
                                                        
                            this.inputReplyDescription = null ;
    
                            this.dialogTitle ='Success' ;
                            this.error = 'Reply Comment 전송에 성공했습니다.';    
    
                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.getItem();
    
                                }, 500);
                            }                        
    
                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                                
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
                        }
                    } else {
                        this.isLoading = false ;
    
                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';
    
                    }
                }
    
    
                } catch(error){
                    console.error(filename__+''+funcname__+' error:',error);
    
                    this.isLoading = false ;
    
                    this.dialogTitle ="전송 실패" ;
                    this.error = error; 
                }
    
                this.isLoading = false ;
    
            },
            // 전송하기  
            async sendStatusForm(){
                let funcname__ ="sendForm() " ;
                let dataForm = { 
                    'status':this.inputStatus,
                } ;  
    
                // console.log(filename__+''+funcname__+' dataForm:', dataForm);
    
                if( this.inputStatus === '' || this.inputStatus === undefined || this.inputStatus === null ) {
                    this.formIsValid = false ;
                    this.dialogTitle = 'Error'
                    this.error ="상태를 선택해주세요.";
                    return ;
                }
    
                this.isLoading = true ;
    
                try {
                    let serverData = await axios({
                        method: 'put',
                        url: '/api/comment/course/status/'+this.id,
                        headers: { 
                            Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                        },                     
                        data: dataForm,
    
                    }).catch( err => {
    
                        if(err.response.status === 401) {  
                            this.dialogTitle ="Login" ;
                            this.error = "로그인이 필요한 페이지입니다.";          
    
                            // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                            this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                            
                        } else {
                            let dataForm = { 
                                category: 'errorlog(api catech)' , 
                                filename : filename__,
                                methodname : funcname__,
                                title: 'Error(API)',       
                                content : ''+err,
                            } ;  
                            console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log
    
                            axios({
                                method: 'post',
                                url: '/api/errorlog/create',
                                data: dataForm,
                            });
    
                            this.dialogTitle ="Error" ;
                            this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                            
                            this.isLoading = false ;
    
                            return ;
                        }
    
                    });
    
                    if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                        this.isLoading = false ;
                        return ;
                    }
    
                    // console.log(filename__+''+funcname__+" serverData:", serverData);
    
                    if(typeof serverData !== "undefined" && serverData) {
                        if(serverData.data.status_code == 201){
    
                            this.dialogTitle ='Success' ;
                            this.error = '변경에 성공했습니다.';     
    
                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.$router.replace(this.backToLink);
    
                                }, 500);
                            }                        
    
    
                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                                
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;
    
                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';
    
                    }
    
    
                } catch(error){
                    console.error(filename__+''+funcname__+' error:',error);
    
                    this.isLoading = false ;
    
                    this.dialogTitle ="전송 실패" ;
                    this.error = error; 
                }
    
                this.isLoading = false ;
    
    
            },
    
            async sendDisplayForm(_id, _is_display){
                let funcname__ ="sendDisplayForm() " ;
                let dataForm = { 
                    'is_display':_is_display,
                    'content_id': this.inputContent_id,  //별점 재계산을 위해서 
                } ;  
    
                console.log(filename__+''+funcname__+' dataForm:', dataForm);
    
                this.isLoading = true ;
    
                try {
                    let serverData = await axios({
                        method: 'put',
                        url: '/api/comment/review/display/'+_id,
                        headers: { 
                            Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                        },                     
                        data: dataForm,
    
                    }).catch( err => {
    
                        if(err.response.status === 401) {  
                            this.dialogTitle ="Login" ;
                            this.error = "로그인이 필요한 페이지입니다.";          
    
                            // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                            this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                            
                        } else {
                            let dataForm = { 
                                category: 'errorlog(api catech)' , 
                                filename : filename__,
                                methodname : funcname__,
                                title: 'Error(API)',       
                                content : ''+err,
                            } ;  
                            console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log
    
                            axios({
                                method: 'post',
                                url: '/api/errorlog/create',
                                data: dataForm,
                            });
    
                            this.dialogTitle ="Error" ;
                            this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                            
                            this.isLoading = false ;
    
                            return ;
                        }
    
                    });
    
                    if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                        this.isLoading = false ;
                        return ;
                    }
    
                    // console.log(filename__+''+funcname__+" serverData:", serverData);
    
                    if(typeof serverData !== "undefined" && serverData) {
                        if(serverData.data.status_code == 201){
    
                            this.dialogTitle ='Success' ;
                            this.error = '변경에 성공했습니다.';     
    
                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.getItem();
    
                                }, 500);
                            }                        
    
    
                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                                
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;
    
                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';
    
                    }
    
    
                } catch(error){
                    console.error(filename__+''+funcname__+' error:',error);
    
                    this.isLoading = false ;
    
                    this.dialogTitle ="전송 실패" ;
                    this.error = error; 
                }
    
                this.isLoading = false ;
    
    
            },
    
            // notification 발송    
            async sendNotificationForm(){
                let funcname__="sendNotificationForm() "
                let dataForm = { 
                    'receive_id': this.inputCreate_id,
                    'content': this.inputNotificationContent,
                } ;  
    
                console.log(filename__+''+funcname__+' dataForm:', dataForm);
    
                if( this.inputNotificationContent === '' || this.inputNotificationContent === undefined || this.inputNotificationContent === null ) {
                    this.formIsValid = false ;
                    this.dialogTitle = 'Error'
                    this.error ="메세지 내용을 입력해주세요.";
                    return ;
                }
    
                this.isLoading = true ;
    
                try {
                    let serverData = await axios({
                        method: 'post',
                        url: '/api/notification/create',
                        data: dataForm,
                        headers: {
                            Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 
    
                }).catch( err => {
    
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
    
                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log
    
                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });
    
                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;
    
                        return ;
                    }
      
    
                });
    
                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
    
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);
    
                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+'  data:', data);
    
                        if(data.code === 201){
                                                        
                            this.inputNotificationContent = null ;
    
                            this.dialogTitle ='Success' ;
                            this.error = 'Notification 전송에 성공했습니다.';     
    
                        } else {
                            console.log(filename__+''+funcname__+' return err:', serverData.data.msg);
                            this.dialogTitle ="입력 실패" ;
                            this.error = serverData.data.msg;
    
                        }
                    } else {
                        this.isLoading = false ;
    
                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';
    
                    }
                }
    
    
                } catch(error){
                    console.error(filename__+''+funcname__+' error:',error);
    
                    this.isLoading = false ;
    
                    this.dialogTitle ="전송 실패" ;
                    this.error = error; 
                }
    
                this.isLoading = false ;
    
            },
    
    
        }
    }
    </script>
    
    <style scoped>
    
    </style>