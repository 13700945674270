<template>
<div class="col-12">

    <!-- Popup modal for play START -->
    <teleport to="body">
        <div class="modal fade" :id="idCompu" tabindex="-1" aria-labelledby="viewModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header bg-dark">
                        <h5 class="modal-title text-white" id="viewMotalLabel">Topic</h5>
                        <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                    </div>
                    <form>
                        <div class="modal-body">
                            <div class="row text-start g-3">
                                <!-- Topic name -->
                                <div class="col-md-12">
                                    <label class="form-label">Topic name</label>
                                    <input class="form-control" type="text" placeholder="Enter topic name" v-model.trim="inputTitle" disabled>
                                </div>

                                <!-- Video link -->
                                <div v-if="kind == 2">
                                    <!-- Video link -->
                                    <h5 v-if="urlCompu" class="mt-4">Video preview</h5>
                                    <div v-if="urlCompu" class="position-relative">
                                        <div class="ratio ratio-16x9">
                                            <iframe 
                                                :src="urlCompu" 
                                                title="YouTube video player" 
                                                frameborder="0" 
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                allowfullscreen
                                            ></iframe>                                                            
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-12">
                                        <label class="form-label">ex) <small>https://www.youtube.com/watch?v=</small>
                                            <b>lIEgvzcWmrY</b>
                                            <small>&t=1s</small>
                                            &nbsp;&nbsp;&nbsp;
                                            <b>(굵은부분만)</b>
                                        </label>
                                        <input class="form-control" type="text" placeholder="Youtube Short string"  v-model.trim="urlCompu" disabled>
                                    </div> -->
                                    <div class="col-md-12 mt-1">
                                        <label class="form-label">{{urlCompu}}</label>
                                    </div>


                                </div>
                                <!-- text  -->
                                <div v-if="kindCompu == 4">                                    
                                    <div class="col-md-12">
                                        <p class="mt-2" v-html="markedDescriptionCompu"></p>
                                    </div>
                                    <hr v-if="itemFileList.length > 0" />
                                    <div v-if="itemFileList.length > 0 " class="mt-2 ms-4">
                                        <label class="form-label" >첨부파일</label> 
                                        <ul>
                                            <li v-for="item in itemFileList" :key="item.id">
                                                <a :href="item.filepath" v-text="item.filename" @click.prevent="downloadItem(item.filepath, item.filename)" />
                                            </li>
                                        </ul>
                                    </div>

                                </div>

                                <!-- image  -->
                                <div v-if="kind == 1">                                    
                                    <div class="col-md-12">
                                        <div class="card card-xl text-center justify-content-center align-items-center">
                                            <img :src="topic_photo" class="img-fluid border border-white border-3 shadow"  alt="" />
                                        </div>                                        
                                    </div>
                                </div>

                                <!-- cardnews   -->
                                <div v-if="kind == 5">                                    
                                    <div class="col-md-12">
                                        <div class="card card-xl text-center justify-content-center align-items-center">
                                            <img :src="topic_photo" class="img-fluid border border-white border-3 shadow"  alt="" />
                                        </div>                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-success-soft my-0" data-bs-dismiss="modal">Close</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </teleport>
    
    <div class="d-sm-flex justify-content-sm-between align-items-center">
        <div class="d-flex">

            <a v-if="kind == 2" else class="btn btn-danger-soft btn-round mb-0"><i class="fas fa-play"></i></a>
            <a v-else-if="kind == 4" class="btn btn-primary-soft btn-round mb-0"><i class="fas fa-file-alt fa-fw"></i></a>
            <a v-else-if="kind == 1" class="btn btn-orange-soft btn-round mb-0"><i class="bi bi-card-image"></i></a>
            <a v-else-if="kind == 5" class="btn btn-danger-soft btn-round mb-0"><i class="bi bi-boxes"></i></a>

            <a v-if="is_price" class="btn btn-light btn-round mb-0 ms-2 flex-shrink-0"><i class="bi bi-lock-fill"></i></a>

            <div class="ms-2 ms-sm-3 mt-1 mt-sm-0">	

                <h6 v-if="is_display" class="mb-0">{{title}}</h6>
                <h6 v-else class="text-decoration-line-through mb-0">{{title}}</h6>

                <p class="mb-2 mb-sm-0 small"><b>{{topictime}}</b> minutes</p>
            </div>
        </div>
        <!-- Button -->
        <button type="button" 
            :class="is_price?'btn btn-sm btn-orange mb-0':'btn btn-sm btn-success mb-0' "
            :data-bs-target="'#'+idCompu"
            data-bs-toggle="modal" 
            >
            <span v-if="is_price">수강신청 후 Play</span>
            <span v-else>미리보기 가능</span>
        </button>

    </div>
    <!-- Divider -->
    <hr v-if="!is_max" />
                        
</div>

</template>

<script>
var filename__ ="[components > companydash > CompanyDashCourseDetailCurriculumPlayComponent.vue] " ;

import axios from 'axios';
import { marked } from 'marked'; 

export default {
    components: {},
    props: [
        'id',
        'is_price','title','topictime','is_display' ,'is_max',
        'kind',
        'url','youtubestr','description','topic_photo'
    ],
    data() {
        return {
            inputTitle : this.title,
            inputUrl : this.url,
            inputYoutubestr : this.youtubestr, 

            itemFileList : [],
            
        }
    },
    created(){
    },    
    computed: {
        idCompu(){
            return 'viewModal'+this.id
        },
        kindCompu(){
            // console.log(filename__+' topicKindCompu() topic_kind:', this.topic_kind);
            if(this.kind === 4){
                // console.log(filename__+' topicKindCompu() getFileList()');                
                this.getFileList() ;
            }

            return this.kind ;  //토픽 클릭한 내용 보여주기 
        },

        urlCompu(){
            if(this.inputYoutubestr){
                return 'https://www.youtube.com/embed/'+this.inputYoutubestr+'?autohide=0&showinfo=0&controls=0' ;
            } else {
                return this.inputUrl;
            }            
        },
        markedDescriptionCompu(){
            return marked(this.description);
        },        
    },
    methods: {
        async getFileList(){
            let funcname__ ="getFileList() ";

            this.itemFileList = [] ;

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/file/contentid/all/'+this.id+'/topic/description/file',
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                        
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        filename: item.photo.replace(/^.*[\\\/]/, ''), 
                        filepath:item.photo,
                    });
                }

                // console.log(filename__+''+funcname__+" list:", list);
                this.itemFileList = list;

                console.log(filename__+''+funcname__+" itemFileList:", this.itemFileList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  

            }

            this.isLoading = false ;            
        },
        async downloadItem(_filepath, _filename) {
            axios.get(_filepath, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/octet-stream' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = _filename
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        },        

    },
    
}
</script>