<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <teleport to="body">
        <div class="modal fade" id="modalMarkdown" tabindex="-1" aria-labelledby="addTopicLabel" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content" style="width:900px">
                    <div class="modal-header bg-dark">
                        <h5 class="modal-title text-white" id="modalMarkdown">Markdown Sample</h5>
                        <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                    </div>                    
                    <div class="modal-body">
                        <div class="row text-start g-3">
                            <div class="col-12 mt-3">
                                <div class="editor_sample">
                                    <textarea class="input" v-model.trim="inputMarkdownInit"></textarea>
                                    <div class="output" v-html="outputMarkdownInitCompu"></div>
                                </div>                
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger-soft my-0" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>


    <!-- Title -->
    <!-- <h4>Course Description</h4>
    <hr>  -->

    <form @submit.prevent="sendDescriptionForm" > 
        <!-- Basic information START -->
        <div class="row g-4">
            
            <!-- Course description -->
            <!-- <div class="col-12">
                <label class="form-label">Add description</label>
            </div> -->

            <div class="col-md-12">
                <label class="form-label">
                    Content (<a href="https://gist.github.com/ihoneymon/652be052a0727ad59601" target="_blank">마크다운 설명 링크</a>)
                    <button type="button" class="btn btn-sm btn-outline-danger mb-0 ms-2"
                        data-bs-toggle="modal" 
                        data-bs-target="#modalMarkdown"
                        >마크다운 샘플예제 보기</button>
                </label>

                <div class="editor">
                    <!-- <textarea class="input" :value="inputMarkdown" @input="update" @input="updateMarkdown"></textarea> -->
                    <textarea class="input" v-model.trim="inputMarkdown" ></textarea>
                    <div class="output" v-html="outputMarkdownCompu"></div>
                </div>
            </div>

            <div class="col-md-12 mt-4"  >                             
                <label class="position-relative me-4" for="uploadfile-1">
                    위의 Markdown와 연동된 이미지이므로, 아래 이미지를 삭제하면 실제 존재하는 이미지가 삭제됩니다. 이미지 클릭(경로 복사) 
                </label>                    
            </div>
            <div class="col-md-12 mt-2"  >                             
                <!-- 이미지 업로드  -->
                <content_id-fileupload-panel-component 
                    :key="content_id+'image'"
                    :is-multi-file='true'
                    :file-kind="'image'"
                    :category="'blog'" 
                    :sub-category="'description'" 
                    :content_id="content_id" 
                />
            </div>
            <div class="col-md-12 mt-4 mb-3"  >  
                <!-- 첨부 파일 업로드 -->
                <content_id-fileupload-panel-component 
                    :key="content_id+'file'"
                    :is-multi-file='true'
                    :file-kind="'file'"
                    :category="'blog'" 
                    :sub-category="'description'" 
                    :content_id="content_id" 
                />
            </div>

            <!-- <div class="d-flex justify-content-end mt-2"> -->
            <div class="d-flex justify-content-between mt-2">    
                <button type="button" @click="onChangeStep(1)" class="btn btn-secondary prev-btn mb-0">Previous</button>
                <button type="submit" class="btn btn-primary next-btn mb-0">Save & Next</button>
            </div>
        </div>
        <!-- Basic information START -->
    </form>

</div>    

</template>

<script>
var filename__ = " [components > companydash > CompanyDashBlogDescriptionComponent.vue] " ;

import axios from 'axios';
import { marked } from 'marked';         // yarn add marked  https://marked.js.org/

import ContentIdFileUploadPanelComponent from '../content/ContentIdFileUploadPanelComponent.vue' ;

export default {
    components: {
        'content_id-fileupload-panel-component': ContentIdFileUploadPanelComponent,
    },
    props: ['content_id','blog'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,     
            
            isShow : false, 

            // step2
            inputDiscription: null, 
            inputDescriptionCategory :'markdown',
            inputMarkdown : null, 
            inputMarkdownInit : 
            `
### 타이틀 3
##### 타이틀 5  (#1개 ~ #6개까지 가능)


![image](/assets/images/about/sample.jpg)

<img src='/assets/images/about/sample.jpg' width='30%' >


[Google](https://google.com, "google link")

* 외부링크: <https://google.com/>

---

1. 첫번째
2. 두번째
3. 세번째

**글자 강조**

~~취소선~~
`,
        }
    },
    created() {
        if(this.blog){
            this.inputMarkdown = this.blog.description ;
        } else {
            this.inputMarkdown = this.inputMarkdownInit ;
        }
    },
    computed: {
        outputMarkdownCompu(){
            return marked(this.inputMarkdown);
        },	
        outputMarkdownInitCompu(){
            return marked(this.inputMarkdownInit);
        },	
    },
    methods: {
        handleError(){
            this.error = null;
        },
        onChangeShow(){
            this.isShow = !this.isShow ;
            console.log(filename__+ "onChangeShow() isShow:" , this.isShow);
        },
		onChangeStep(_num){
			this.$emit('changestep', _num) ;
            return ;
		},

        // 정보 업데이트  
        async sendDescriptionForm(){
            let funcname__ = " sendDescriptionForm() " ;

            // let instance = this.$refs.myEditor ;
            // this.inputDesription = instance.getHTML() ;

            let dataForm = { 
                'description': this.inputMarkdown,
                'description_category': this.inputDescriptionCategory,
            } ;  

            // console.log(filename__+ ""+funcname__+' dataForm:', dataForm);

            if( this.inputMarkdown === '' || this.inputMarkdown === undefined || this.inputMarkdown === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="Description을  입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/blog/description/update/'+this.content_id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+ ""+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle = "Success" ;
                        this.error = "입력되었습니다.";

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('changestep', 3) ;

                            }, 500);
                        }                        
                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+ ""+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    },
    
}
</script>
<style scoped>
.editor {
  height: 60vh;
  display: flex;
}
.editor_sample {
  height: 70vh;
  display: flex;
}

.input,
.output {
  overflow: auto;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.input {
  border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 20px;
}

code {
  color: #f66;
}
</style>