export default {
    setUser(state, payload){
        // console.log('[store/auth/mutations.js_setUser()]', payload);
        
        state.token = payload.token;
        state.userId = payload.userId;
        state.email = payload.email;
        state.name = payload.name;
        state.is_admin = payload.is_admin;
        state.photo = payload.photo;
        state.isPageAdmin = payload.isPageAdmin;   //false 설정 
        // state.tokenExpiration = payload.tokenExpiration ;
    },

    setUserId(state, payload){
        state.userId = payload.userId;
    },
    setName(state, payload){
        state.name = payload.name;
    },

    setPhoto(state, payload){
        state.photo = payload.photo;
    },

    setLevel(state, payload){
        state.level = payload.level;
    },
    setPageAdmin(state, payload){
        state.isPageAdmin = payload.isPageAdmin ;
    },
    setOutPageAdmin(state, payload){
        state.isPageAdmin = payload.isPageAdmin ;
    },
}