<template>
<!-- <section class="py-0 py-xl-5"> -->
<section class="py-0">

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="container">
        <div class="row g-4">
            <!-- Counter item -->
            <div class="col-sm-6 col-xl-4">
                <div class="d-flex justify-content-center align-items-center p-4 bg-warning bg-opacity-15 rounded-3">
                    <span class="display-6 lh-1 text-warning mb-0"><i class="fas fa-tv"></i></span>
                    <div class="ms-4 h6 fw-normal mb-0">
                        <div class="d-flex">
                            <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="10"	data-purecounter-delay="200">{{ inputCourseCount }}</h5>
                            <span class="mb-0 h5">개</span>
                        </div>
                        <p class="mb-0">온라인 강좌</p>
                    </div>
                </div>
            </div>
            <!-- Counter item -->
            <div class="col-sm-6 col-xl-4">
                <div class="d-flex justify-content-center align-items-center p-4 bg-blue bg-opacity-10 rounded-3">
                    <span class="display-6 lh-1 text-blue mb-0"><i class="fas fa-user-tie"></i></span>
                    <div class="ms-4 h6 fw-normal mb-0">
                        <div class="d-flex">
                            <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="200" data-purecounter-delay="200">{{ inputCompanyCount }}</h5>
                            <span class="mb-0 h5">명</span>
                        </div>
                        <p class="mb-0">파트너</p>
                    </div>
                </div>
            </div>
            <!-- Counter item -->
            <div class="col-sm-6 col-xl-4">
                <div class="d-flex justify-content-center align-items-center p-4 bg-purple bg-opacity-10 rounded-3">
                    <span class="display-6 lh-1 text-purple mb-0"><i class="fas fa-user-graduate"></i></span>
                    <div class="ms-4 h6 fw-normal mb-0">
                        <div class="d-flex">
                            <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="60"	data-purecounter-delay="200">{{ inputStudentCount }}</h5>
                            <span class="mb-0 h5">명</span>
                        </div>
                        <p class="mb-0">온라인 수강생</p>
                    </div>
                </div>
            </div>
            <!-- Counter item -->
            <!-- <div class="col-sm-6 col-xl-3">
                <div class="d-flex justify-content-center align-items-center p-4 bg-info bg-opacity-10 rounded-3">
                    <span class="display-6 lh-1 text-info mb-0"><i class="bi bi-patch-check-fill"></i></span>
                    <div class="ms-4 h6 fw-normal mb-0">
                        <div class="d-flex">
                            <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="6" data-purecounter-delay="300">{{ inputCertificateCount }}</h5>
                            <span class="mb-0 h5">개</span>
                        </div>
                        <p class="mb-0">인증 코스</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

</template>

<script>
var filename__= "[components > main > MainCourseInfoComponent.vue] "
import axios from 'axios';

export default {
    components: {},
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputCourseCount: 0, 
            inputCompanyCount: 0,
            inputStudentCount: 0,  //등록한 갯수 
            inputCertificateCount: 0 ,

        }
    },
    created(){
        this.getCourseCount();
        this.getCompanyCount();
        this.getStudentCount();  // 수강신청한 중인 학생수 
    },
    computed: {

    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        // 코스(강의, 콘텐츠) 갯수 
        async getCourseCount(){
            let funcname__ =" getCourseCount() "

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/course/all/count',

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputCourseCount = data.response.display_count ;  
                this.inputCertificateCount = data.response.certificate_count ;  

                // console.log(filename__+''+funcname__+" TotalCount:", data.response.total_count);
                // console.log(filename__+''+funcname__+" inputCourseCount:", this.inputCourseCount);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="입력 실패" ;
                this.error = serverData.data.error_description;

            }

            this.isLoading = false ;            
        },

        // 파트너 등록숫자 
        async getCompanyCount(){
            let funcname__ =" getCompanyCount() "
            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/company/count/user',

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputCompanyCount = data.response ;           

                // console.log(filename__+''+funcname__+" inputCompanyCount:", this.inputCompanyCount);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="입력 실패" ;
                this.error = serverData.data.error_description;

            }

            this.isLoading = false ;            
        },

        // 전체 수강 학생수  
        async getStudentCount(){
            let funcname__ =" getStudentCount() "
            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/student/course/order/allstudent/count',

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputStudentCount = data.response ;           

                // console.log(filename__+''+funcname__+" inputStudentCount:", this.inputStudentCount);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="입력 실패" ;
                this.error = serverData.data.error_description;
                
            }

            this.isLoading = false ;            
        },


    },
    
}
</script>