<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

	<!-- Page main content START -->
	<div class="page-content-wrapper-match border">

		<!-- Title -->
		<div class="row mb-0">
			<div class="col-12 d-sm-flex justify-content-between align-items-center">
				<h1 class="h3 mb-2 mb-sm-0">Contact Us Detail</h1>
                <router-link :to="backToLink" class="btn btn-sm btn-secondary mb-0 me-2">Back</router-link>
			</div>
		</div>
        <admin-contactus-detail-component :id="id" />
	</div>

	<div class="page-content-wrapper-match border">
        <form @submit.prevent="sendForm" >
            <!-- Title -->
            <div class="row g-3 align-items-center justify-content-between">
                <div class="col-md-9">
                    <h1 class="h3 mb-2 mb-sm-0">Status Update</h1>
                </div>
                <div class="col-md-3">
                    <!-- <router-link :to="backToLink" class="btn btn-sm btn-secondary mb-0 me-2">Back</router-link> -->
                </div>
            </div>

            <div class="mb-2 bg-light-input">
                <select class="form-select" aria-label="Default select example" v-model="inputStatus" @change="onChangeSelect($event)" >
                    <option :value="0">미처리</option>
                    <option :value="1">확인중</option>
                    <option :value="2">진행중</option>
                    <option :value="8">보류중</option>
                    <option :value="9">처리완료</option>
                </select>                    
            </div>

            <!-- Button -->
            <div class="row mt-1">
                <div class="col-12 d-sm-flex justify-content-between align-items-center">
                    <router-link :to="backToLink" class="btn btn-sm btn-secondary mb-0 me-2">Back</router-link>
                    <button type="submit" class="btn btn-primary mb-0" >Status Update</button>
                </div>
            </div>
        </form>

	</div>
    
</div>
</template>

<script>
var filename__= "[pages > admin > faq > AdminContactusStatusUpdate.vue] "
import axios from 'axios';

import AdminContactusDetailComponent from '../../../components/admin/faq/AdminContactusDetailComponent.vue';

export default {
    components: {
        "admin-contactus-detail-component" : AdminContactusDetailComponent,
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  
            
            inputStatus : null, 
            
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
        backToLink(){
            return '/admin/contactus/'+this.id+'?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },

    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			this.getItem();
            return ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },

        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/faq/contactus/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+''+funcname__+'  response:', data.response);
                    const contactus = data.response

                    this.inputStatus = contactus.status ;

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:',serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  
                }
            }

            this.isLoading = false ;     
        },
        
        // 전송하기  
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'status':this.inputStatus,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputStatus === '' || this.inputStatus === undefined || this.inputStatus === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="상태를 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/faq/contactus/status/update/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                    
                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$router.replace(this.backToLink);

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' return err.error_description:', serverData.data.error_description);
                        this.dialogTitle ="전송 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        
    }
}
</script>

<style scoped>

</style>