<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Page main content START -->
    <div class="page-content-wrapper-match border">

        <!-- Title -->
        <form @submit.prevent="sendForm" >

            <div class="col-12">
                <div class="card bg-transparent border rounded-3 h-100">

                    <!-- Catd header -->
                    <div class="card-header bg-light border-bottom">
                        <h5 class="card-header-title">작업 내역 </h5>
                    </div>

                    <!-- Card body START -->
                    <div class="card-body">

                        <div class="row g-4">
                            <!-- Course info and avatar -->
                            <div class="col-12 mb-2">
                                <label class="form-label me-2">카테고리 종류</label>
                                <button v-if="inputCategory == 1" type="button" class="btn btn-sm btn-danger-soft me-1 mb-1 mb-lg-0">버그</button>
                                <button v-if="inputCategory == 2" type="button" class="btn btn-sm btn-success-soft me-1 mb-1 mb-lg-0">보완</button>
                                <button v-if="inputCategory == 3" type="button" class="btn btn-sm btn-primary-soft me-1 mb-1 mb-lg-0">새로운 작업</button>
                            </div>
                        </div>

                        <div class="row g-4">
                            <!-- Course info and avatar -->
                            <div class="col-12">
                                <label class="form-label">Title</label>
                                <div class="input-group">
                                    <input type="text" v-model.trim="inputTitle" class="form-control" placeholder="title" disabled>
                                </div>
                            </div>
                        </div>

                        <div class="row g-4 mt-1">                                    
                            <div class="col-md-12">
                                <label class="form-label">설명</label>
                                <p class="bg-color-form-gray white-space-newline" v-html="inputDescription"></p>
                            </div>
                        </div>

                        <div class="row g-4 mt-1">                                    
                            <div class="col-md-12">
                                <label class="form-label me-2">작업 진행상태</label>
                                <button v-if="inputProcess == 0" type="button" class="btn btn-sm btn-outline-primary me-1 mb-1 mb-lg-0">미확인</button>
                                <button v-if="inputProcess == 1" type="button" class="btn btn-sm btn-info-soft me-1 mb-1 mb-lg-0">확인함</button>
                                <button v-if="inputProcess == 2" type="button" class="btn btn-sm btn-outline-success me-1 mb-1 mb-lg-0">검토중</button>

                                <button v-if="inputProcess == 3" type="button" class="btn btn-sm btn-primary-soft me-1 mb-1 mb-lg-0">작업예정</button>
                                <button v-if="inputProcess == 4" type="button" class="btn btn-sm btn-primary me-1 mb-1 mb-lg-0">작업중</button>

                                <button v-if="inputProcess == 9" type="button" class="btn btn-sm btn-danger me-1 mb-1 mb-lg-0">보류</button>
                                <button v-if="inputProcess == 10" type="button" class="btn btn-sm btn-success me-1 mb-1 mb-lg-0">처리완료</button>
                                <button v-if="inputProcess == 11" type="button" class="btn btn-sm btn-success-soft me-1 mb-1 mb-lg-0">Pass</button>

                                <span v-if="processCompu" :class="workCompu?'badge text-bg-primary ms-2':'badge bg-primary bg-opacity-10 text-primary ms-2'">{{inputStartdate}}</span>
                                <span v-if="processCompu" > - </span>
                                <span v-if="processCompu" :class="workCompu?'badge text-bg-danger':'badge bg-danger bg-opacity-10 text-danger'">{{inputEnddate}}</span>
                            </div>
                        </div>

                    </div>
                    <!-- Card body END -->
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 d-sm-flex justify-content-between align-items-center">
                    <router-link :to="backToLink" class="btn btn-secondary mb-0" >목록</router-link>
                    <router-link :to="updateLink" class="btn btn-primary mb-0" >Update</router-link>
                </div>
            </div>
        </form>


    </div>

</div>
</template>

<script>
var filename__="[pages > admin > plan > AdminPlanDetail.vue] "

import axios from 'axios';

export default {
    components: {
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  
            

            inputCategory : null, 
            inputProcess : null, 
            inputTitle : null, 

            inputDiscription: null, 
            inputDescriptionCategory :'text', 
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
        backToLink(){            
            return '/admin/plans?page='+this.$route.query.page+"&category="+this.$route.query.category+"&process="+this.$route.query.process+"&search="+this.$route.query.search
        },
        updateLink(){            
            return '/admin/plan/update/'+this.id+'?page='+this.$route.query.page+"&category="+this.$route.query.category+"&process="+this.$route.query.process+"&search="+this.$route.query.search
        },
        processCompu(){
            if(this.inputProcess == 3 || this.inputProcess == 4 || this.inputProcess == 10 ){
                return true ;
            } else {
                return false ;
            }
        },
        workCompu(){
            if(this.inputProcess == 4){
                return true ;
            } else if(this.inputProcess == 3){
                return false 
            }
        },

    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getItem();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
            // console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        // 전송하기  
        async getItem(){
            let funcname__ ="getItem()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'get',
                    url: '/api/plan/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 200){
                        const data = serverData.data ;

                        this.inputCategory = data.response.category ; 

                        this.inputTitle = data.response.title ; 
                        this.inputDescription = data.response.description ; 

                        this.inputProcess = data.response.process ; 
                        this.inputStartdate = data.response.startdate ; 
                        this.inputEnddate = data.response.enddate ; 

                    } else {
                        console.log(filename__+''+funcname__+' return err:', serverData.data.error_description);
                        this.dialogTitle ="생성 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        
    }
}
</script>

<style scoped>

</style>