<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <form >
        <div v-if="photoCompu" class="text-center justify-content-center align-items-center p-2 p-sm-3 border border-2 border-dashed position-relative rounded-3">
            <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                <div class="card card-xl text-center justify-content-center align-items-center">
                    <img :src="photoCompu" class="img-fluid border border-white border-3 shadow"  alt=""  :style="'height:'+height+'px;'" />
                </div>
            </label>
        </div>
        <div>
            <div class="text-center justify-content-center align-items-center p-2 p-sm-3 border border-2 border-dashed position-relative rounded-3">
                <div>
                    <label style="cursor:pointer;">
                        <span> 
                            <input class="form-control stretched-link" 
                                name="my-image" 
                                id="uploadfile-1"
                                type="file" 
                                accept="image/gif, image/jpg, image/jpeg, image/png"  
                                @change="onChangeFile"  
                            />
                        </span>
                    </label>
                    <p class="small mb-0 mt-2"><b>Note:</b> Only GIF, JPG, JPEG and PNG.</p>
                    <p class="small mb-0 mt-2">{{ explain }}</p>
                </div>	
            </div>                
        </div>

    </form>

</div>    
</template>
    
<script>
var filename__= "[components > fileupload > IdFileuploadComponent.vue] "

import axios from 'axios';

export default {
    components: {
    },
    props: [
        'image_path',
        'id',
        'dbtable','category','subCategory','fileKind',
        'height','explain',
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,      

            inputId: null, 
            inputPhoto : this.image_path ,
            inputPhotoUpdate : null,  

        }
    },
    computed: {
        photoCompu(){
            return this.inputPhoto ;
        },
    },    
    created(){
    },
    methods: {
        handleError(){
            this.error = null;

        },
        onChangeFile(evt){
            const file = evt.target.files[0] ;
            const file_size = 1024*1024*10 ; // 10M 크기 

            if(file.size > file_size ){
                // this.dialogTitle ="Error" ;
                // this.error = "이미지 사이즈는 10M 이하만 업로드 됩니다.";
                alert("이미지 사이즈는 10M 이하만 업로드 됩니다.")
                return ;                       

            } else {
                this.inputPhotoUpdate = file; 
                this.inputFileAddTmp = URL.createObjectURL(file);
                
                this.sendFileForm(); // 파일 업로드 
            }
        },

        // 메인 사진 업로드         
        async sendFileForm(){
            let funcname__ ="sendFileForm() ";
            
            if(this.inputPhotoUpdate == null){
                this.dialogTitle ="Error" ;
                this.error = "업로드할 사진을 선택해주세요.";
                return ;                       

            }

            // 파일전송 FormData() : https://hagohobby.tistory.com/21 
            const formData = new FormData()
            formData.append('category', this.category);
            formData.append('subcategory', this.subCategory);
            formData.append('filekind', this.fileKind);
            formData.append('file', this.inputPhotoUpdate);

            for (const value of formData.values()) {
                console.log(filename__+''+funcname__+' formData.value:', value);
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: `/api/fileupload/update/id/${this.id}`,
                    headers: {
                        'Content-Type' : 'multipart/form-data',
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 
                    data: formData

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){

                        console.log(filename__+''+funcname__+' data:', data);
                        
                        this.inputPhoto = data.image_path ;
                        this.inputPhotoUpdate = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '이미지 업로드 성공했습니다.';  
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                            }, 700);
                        }                        
                        
                        
                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    
    }
}
</script>
    
<style scoped>

</style>