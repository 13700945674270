<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>
    
    <div class="row g-4 g-md-5">
        <!-- Main content START -->
        <div class="col-lg-12">
            <!-- Title -->
            <h3 class="mb-4">자주하는 질문</h3>

            <!-- FAQ START -->
            <div class="accordion accordion-icon accordion-bg-light" id="accordionExample2">
                <!-- Item -->
                <div class="accordion-item mb-3" v-for="(item, idx) in itemList" :key="item.id">
                    <h6 class="accordion-header font-base" :id="'heading-'+item.id">
                        <button class="accordion-button fw-bold rounded d-inline-block collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+item.id" :aria-expanded="idx===0 ? 'true':'false'" :aria-controls="'collapse-'+item.id">
                            {{ item.title }}
                        </button>
                    </h6>
                    <!-- Body -->
                    <div :id="'collapse-'+item.id" class="accordion-collapse collapse" :class="idx===0 ?'show':''" :aria-labelledby="'heading-'+item.id" :data-bs-parent="'#accordionExample'+item.id">
                        <div class="accordion-body white-space-newline mt-3">
                            {{ item.content }}
                        </div>
                    </div>
                </div>



            </div>
            <!-- FAQ END -->
        </div>
        <!-- Main content END -->

    </div><!-- Row END -->
    
</div>
</template>
    
<script>
var filename__= "[components > faq > FaqFrequentPanelComponent.vue] "
import axios from 'axios';

// import FaqFrequentListComponent from './FaqFrequentListComponent.vue';

export default {
    components: {
        // "faq-frequent-list-component" : FaqFrequentListComponent,
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,      
            
            itemList : [],
            
        }
    },
    beforeMount() {
    },     

    computed: {

    },    
    created(){
        this.getList();            
    },
    methods: {
        handleError(){
            this.error = null;

        },
        async getList(){
            let funcname__ ="getList()" ;

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/faq/frequent/all/user',

			}).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];

                tmpList.sort(function(a,b){
                    // console.log(filename__+''+funcname__+" a.seq:", a.seq);
                    return a.seq-b.seq
                });

                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        num: counter--, 
                        id: item.id, 
                        title:item.title,
                        content:item.content,
                        seq: item.seq,
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.itemList = list;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:',serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  
            }

            this.isLoading = false ;            
        },

        
    }
}
</script>
    
<style scoped>

</style>