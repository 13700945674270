<template>
    <tr>
        <!-- Table data -->
        <td>{{num}}</td>

        <!-- Table data -->
        <td>
            <h6 class="table-responsive-title mb-0">
                <router-link :to="detailLink">{{contentSplice40}} </router-link>
            </h6>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0"><a href="#" class="stretched-link">{{date}}</a></h6>
                </div>
            </div>
        </td>

        <!-- Table data -->
        <td>
            <span v-if="is_check == false" class="badge text-bg-orange"><i class="fas fa-bell"></i></span>
        </td>

        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="avatar avatar-md">
                    <img v-if="create_photo" :src="create_photo" class="rounded-circle" alt="">
                    <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                </div>
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        {{create_name}}
                    </h6>
                </div>
            </div>
        </td>

    </tr>    
</template>

<script>
export default {
    components: {},
    props: [
        'id','num',
        'receive_id','receive_name','receive_email','receive_photo',
        'create_id','create_name','create_email','create_photo',
        'content','is_check','date',
        'page','search',
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            return '/studentdash/notification/'+this.id+'?page='+this.page+"&search="+this.search ; 
        },
        contentSplice40(){
            return this.content.slice(0,40)+"....";
        },

    },
    methods: {

    },
    
}
</script>