<template>
<div>
	<base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
		<p>{{ error }}</p>
	</base-dialog>

	<base-dialog :show="isLoading" title="Loading..." fixed>
		<p>Loading...</p>
		<base-spinner></base-spinner>
	</base-dialog>

	<main>

<!-- =======================
Page Banner START -->
<section class="py-0">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="bg-dark p-4 text-center rounded-3">
					<h1 class="text-white m-0">파트너 리스트</h1>
					<!-- Breadcrumb -->
					<div class="d-flex justify-content-center">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb breadcrumb-dark breadcrumb-dots mb-0">
								<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                                <li class="breadcrumb-item"><a href='#'>파트너</a></li>
								<li class="breadcrumb-item active" aria-current="page">파트너 리스트</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- =======================
Page Banner END -->

<!-- =======================
Inner part START -->
<section class="pt-4">
	<div class="container">
		
		<!-- Search option START -->
		<div class="row mb-4 align-items-center">
			<!-- Search bar -->
			<!-- <div class="col-sm-6 col-xl-6"> -->
			<div class="col-12">
				<div class="card card-body shadow">
					<form class="border rounded p-2">
						<div class="input-group input-borderless">
							<input class="form-control me-1" type="search" placeholder="파트너 찾기" v-model.trim="inputSearch">
							<button type="button" @click="onChangeSearch" class="btn btn-primary mb-0 rounded"><i class="fas fa-search"></i></button>
							<button type="button" @click="onChangeAll" class="btn btn-primary mb-0 rounded ms-2">All</button>
						</div>
					</form>
				</div>
			</div>

			<!-- Select option -->
			<!-- <div class="col-sm-6 col-xl-3 mt-3 mt-lg-0">
				<form class="border rounded p-2 input-borderless">
					<select class="form-select form-select-sm js-choice" aria-label=".form-select-sm">
						<option value="">강의 범주</option>
						<option>All</option>
						<option>Development</option>
						<option>Design</option>
						<option>Accounting</option>
						<option>Translation</option>
						<option>Finance</option>
						<option>Legal</option>
						<option>Photography</option>
						<option>Writing</option>
						<option>Marketing</option>
					</select>
				</form>
			</div> -->

			<!-- Select option -->
			<!-- <div class="col-sm-6 col-xl-4 mt-3 mt-xl-0">
				<form class="border rounded p-2 input-borderless">
					<select class="form-select form-select-sm js-choice" aria-label=".form-select-sm">
						<option value="">정렬 기준</option>
						<option>Most popular</option>
						<option>Most viewed</option>
						<option>Top rated</option>
					</select>
				</form>
			</div> -->

			<!-- Button -->
			<!-- <div class="col-sm-6 col-xl-2 mt-3 mt-xl-0 d-grid">
				<a href="#" class="btn btn-lg btn-primary mb-0">조건 검색 </a>
			</div> -->
		</div>
		<!-- Search option END -->

		<!-- Instructor list START -->
		<div class="row g-4 justify-content-center">

			<!-- Card item START -->
			<company-list-component v-for="item in itemList"
				:key="item.id"
				:id="item.id"
				:company_id="item.company_id"
				:num="item.num"
				:photo="item.photo"
				:name="item.name"
				:company="item.company"
				:major="item.major"
				:phone="item.phone"
				:email="item.email"
				:addr="item.addr"
				:description="item.description"
				:date="item.date"
				
				:socials="item.socials"

				:page="page"
				:search="inputSearch"

				/>			
			<!-- Card item END -->

			<!-- Card item START -->
<!-- 			
			<div class="col-lg-10 col-xl-6">
				<div class="card shadow p-2">
					<div class="row g-0">
						<div class="col-md-4">
							<img src="assets/images/instructor/02.jpg" class="rounded-3" alt="...">
						</div>

						<div class="col-md-8">
							<div class="card-body">
								<div class="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
									<div>
										<h5 class="card-title mb-0"><router-link to="/instructor/detail">원희지</router-link></h5>
										<p class="small mb-2 mb-sm-0">서강대학교 전산학과 교수</p>
									</div>
									<span class="h6 fw-light">4.0<i class="fas fa-star text-warning ms-1"></i></span>
								</div>
								<p class="text-truncate-2 mb-3">Frontend에 대한 Skill을 원리부터 응용까지 깊이있게 강의합니다.</p>
								<div class="d-sm-flex justify-content-sm-between align-items-center">
									<h6 class="text-orange mb-0">HTML, CSS, Javascript 강사</h6>

									<ul class="list-inline mb-0 mt-3 mt-sm-0">
										<li class="list-inline-item">
											<a class="mb-0 me-1 text-facebook" href="#"><i class="fab fa-fw fa-facebook-f"></i></a>
										</li>
										<li class="list-inline-item">
											<a class="mb-0 me-1 text-instagram-gradient" href="#"><i class="fab fa-fw fa-instagram"></i></a>
										</li>
										<li class="list-inline-item">
											<a class="mb-0 me-1 text-twitter" href="#"><i class="fab fa-fw fa-twitter"></i></a>
										</li>
										<li class="list-inline-item">
											<a class="mb-0 text-linkedin" href="#"><i class="fab fa-fw fa-linkedin-in"></i></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
 -->
			<!-- Card item END -->

		</div>
		<!-- Instructor list END -->

		<!-- Pagination START -->
		<!-- <nav class="mt-4 d-flex justify-content-center" aria-label="navigation">
			<ul class="pagination pagination-primary-soft d-inline-block d-md-flex rounded mb-0">
				<li class="page-item mb-0"><a class="page-link" href="#" tabindex="-1"><i class="fas fa-angle-double-left"></i></a></li>
				<li class="page-item mb-0"><a class="page-link" href="#">1</a></li>
				<li class="page-item mb-0 active"><a class="page-link" href="#">2</a></li>
				<li class="page-item mb-0"><a class="page-link" href="#">..</a></li>
				<li class="page-item mb-0"><a class="page-link" href="#">6</a></li>
				<li class="page-item mb-0"><a class="page-link" href="#"><i class="fas fa-angle-double-right"></i></a></li>
			</ul>
		</nav> -->
        <div v-if="itemList && itemList.length > 0"> 
            <user-pagination :pagination="paginationCompu" @paging="pagingSend" ></user-pagination>
        </div>
		<!-- Pagination END -->

	</div>
</section>
<!-- =======================
Inner part END -->

<!-- =======================
Action box START -->
<section class="pt-0">
	<div class="container position-relative">
		<!-- SVG -->
		<figure class="position-absolute top-50 start-50 translate-middle ms-2">
			<svg>
				<path d="m496 22.999c0 10.493-8.506 18.999-18.999 18.999s-19-8.506-19-18.999 8.507-18.999 19-18.999 18.999 8.506 18.999 18.999z" fill="#fff" fill-rule="evenodd" opacity=".502"/>
				<path d="m775 102.5c0 5.799-4.701 10.5-10.5 10.5-5.798 0-10.499-4.701-10.499-10.5 0-5.798 4.701-10.499 10.499-10.499 5.799 0 10.5 4.701 10.5 10.499z" fill="#fff" fill-rule="evenodd" opacity=".102"/>
				<path d="m192 102c0 6.626-5.373 11.999-12 11.999s-11.999-5.373-11.999-11.999c0-6.628 5.372-12 11.999-12s12 5.372 12 12z" fill="#fff" fill-rule="evenodd" opacity=".2"/>
				<path d="m20.499 10.25c0 5.66-4.589 10.249-10.25 10.249-5.66 0-10.249-4.589-10.249-10.249-0-5.661 4.589-10.25 10.249-10.25 5.661-0 10.25 4.589 10.25 10.25z" fill="#fff" fill-rule="evenodd" opacity=".2"/>
			</svg>
		</figure>

		<div class="bg-success p-4 p-sm-5 rounded-3">
			<div class="row justify-content-center position-relative">
				<!-- Svg -->
				<figure class="fill-white opacity-1 position-absolute top-50 start-0 translate-middle-y">
					<svg width="141px" height="141px">
						<path d="M140.520,70.258 C140.520,109.064 109.062,140.519 70.258,140.519 C31.454,140.519 -0.004,109.064 -0.004,70.258 C-0.004,31.455 31.454,-0.003 70.258,-0.003 C109.062,-0.003 140.520,31.455 140.520,70.258 Z"/>
					</svg>
				</figure>
				<!-- Action box -->
				<div class="col-11 position-relative">
					<div class="row align-items-center">
						<!-- Title -->
						<div class="col-lg-7">
							<h3 class="text-white">파트너를 신청하세요!</h3>
							<p class="text-white mb-3 mb-lg-0">파트너는 개인등록도 가능합니다.당신이 가지고 있는 놀라운 능력을 사장시키지 마십시요. 당신이 가지고 있는 지식에 목마른 수강생들이 여기 있습니다. 지금 바로 저희와 함께 하십시요.</p>
						</div>
						<!-- Button -->
						<div class="col-lg-5 text-lg-end">
							<router-link to="/company/request" class="btn btn-dark mb-0">파트너 등록하기</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- =======================
Action box END -->

</main>

    <hr/>
</div>
</template>

<script>
var filename__ = "[pages > company > CompanyList.vue]" ;
import axios from 'axios';

import CompanyListComponent from '../../components/company/CompanyListComponent.vue';
import UserPagination from '../../components/ui/UserPagination.vue';
export default {
    components: {
        "company-list-component": CompanyListComponent,
		"user-pagination": UserPagination
    },
    props: [],
    data() {
        return {
            isLoading: false,
			isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList: [], 
            page:1,
            current_path:"/companies",  //current_path
			pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수 (갯수) 
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',     
            
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin');
    }, 
    computed: {
        paginationCompu(){
            return this.pagination ;
        },
    },    
    created(){
		this.getList();
    },
    methods: {
        handleError(){
            this.error = null;
        },
        onChangeSearch(){
			this.page = 1 ;
            this.getList(); //page=1
        },
        onChangeAll(){
			this.page = 1 ;
			this.inputSearch = '' ;
            this.getList(); //page=1
        },
        onChangeDisplay(){
			this.page = 1 ;
			this.inputSearch = '' ;
            this.getList(); //page=1
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        async getList(){
			let funcname__ ="getList() ";

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }

            if( this.inputSearch === 'undefinded' || this.inputSearch === '') { 
                this.inputSearch = '' ; //pagination, search 에서 계속작동하기 위해서                 
            }             


            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/company/all/user',
                params: { 
                    page: this.page , 
                    search: this.inputSearch,       
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                }

            }).catch( err => {

				if(err.response.status === 401) {  
					this.dialogTitle ="Login" ;
					this.error = "로그인이 필요한 페이지입니다.";          

					// 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
					this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
									
				} else {
					let dataForm = { 
						category: 'errorlog(api catech)' , 
						filename : filename__,
						methodname : funcname__,
						title: 'Error(API)',       
						content : ''+err,
					} ;  
					console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

					axios({
						method: 'post',
						url: '/api/errorlog/create',
						data: dataForm,
					});

					this.dialogTitle ="Error" ;
					this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
					
					this.isLoading = false ;

					return ;
				}

            });

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ;
                return ;

            } else {
				const data = serverData.data ;

        
				// console.log(filename__+''+funcname__+" data:", data);

				if( typeof data !== "undefined" &&  data.status_code == 200 ){
					// console.log(filename__+''+funcname__+'  response:', data.response);
					
					this.pagination = data.response.pagination ;
					let cnt = data.response.pagination.counter ;
					let tmpList = data.response.data ;
					let list = []; 

					//메인 카테고리 값들을 입력하기 
					for(let item of tmpList ){
						list.push({
							num: cnt--, 
							id: item.id, 
							company_id : item.owner_id,  //실제로 DB에서 company_id 역할을 하게됨, company 생성자의 id와 같음 , course생성될 때 생성자 id와 같게 됨  
							email:item.email ? item.email : '',

							name: item.name ? item.name : '',
							company: item.company ? item.company : '',
							major: item.major ? item.major: '',
							photo: item.photo ? item.photo :'',
							phone: item.phone ? item.phone : ' ',
							addr: item.addr ? item.addr: '',							
							description: item.description ? item.description: '', 

							date: item.date, 
							socials: item.socials ?  item.socials  : [],
							
						});
					}

					// console.log(filename__+''+funcname__+" list:", list);
					this.itemList = list;
					// this.isApplying = true ;

					// console.log(filename__+''+funcname__+" this.itemList:", this.itemList);
					// console.log(filename__+''+funcname__+" this.pagination:", this.pagination);

				} else {
					console.log(filename__+''+funcname__+' err.error_description:',data.error_description);

					this.dialogTitle ="실패" ;                    
					this.error = serverData.data.error_description;  
				}
			}

            this.isLoading = false ;
        },

    }
}
</script>

<style scoped>

</style>
