<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>
	
    <!-- =======================
    Page Banner START -->
    <company-dashboard-top :title="'관리자 페이지 설정'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
                        
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <company-dashboard-left :menu="'setting'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Edit profile START -->
                    <div class="card bg-transparent border rounded-3">
                        <!-- Card header -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="card-header-title mb-0">관리자 페이지 설정</h3>
                        </div>
                  
                        <!-- Card body START -->
                        <div class="card-body">


                            <div class="row g-4">
                                <!-- Left side START -->
                                <div class="col-xl-3">
                                    <!-- Tab START -->
                                    <ul class="nav nav-pills nav-tabs-bg-dark flex-column">
                                        <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#tab-1"><i class="fas fa-globe fa-fw me-2"></i>Info Settings</a> </li>
                                        <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#tab-2"><i class="fas fa-eye fa-fw me-2"></i>공개여부</a> </li>
                                        <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#tab-3"><i class="fas fa-graduation-cap fa-fw me-2"></i>학력/경력 </a> </li>
                                        <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#tab-4"><i class="fas fa-award fa-fw me-2"></i>Skill</a> </li>
                                        <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#tab-5"><i class="fas fa-sliders-h fa-fw me-2"></i>Social Settings</a> </li>
                                        <!-- <li class="nav-item"> <a class="nav-link mb-0" data-bs-toggle="tab" href="#tab-6"><i class="fas fa-envelope-open-text fa-fw me-2"></i>Email Settings</a> </li> -->
                                    </ul>
                                    <!-- Tab END -->
                                </div>
                                <!-- Left side END -->

                                <!-- Right side START -->
                                <div class="col-xl-9">

                                    <!-- Tab Content START -->
                                    <div class="tab-content">

                                        <!-- Personal Information content START -->
                                        <div class="tab-pane show active" id="tab-1">
                                            <div class="card shadow">

                                                <!-- Card header -->
                                                <div class="card-header border-bottom">
                                                    <h5 class="card-header-title">Information Settings</h5>
                                                </div>


                                                <!-- Card body START -->
                                                <div class="card-body">

                                                    <form @submit.prevent="sendFileForm" class="row g-4 align-items-center">

                                                        <!-- Profile picture -->                                
                                                        <div class="col-12 justify-content-center align-items-center">
                                                            <label class="form-label">Front Image </label>
                                                            <div class="d-flex align-items-center">
                                                                <div v-if="photo"  >
                                                                    <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                                                        <!-- Avatar place holder -->
                                                                        <div class="card card-xl">
                                                                            <img :src="photo" id="uploadfile-1-preview" class="img-fluid border border-white border-3 shadow"  :width="widthCompu" alt=""/>
                                                                        </div>
                                                                        <!-- Remove btn -->
                                                                        <button type="button" @click="removePhotoForm" class="uploadremove"><i class="bi bi-x text-white"></i></button>
                                                                    </label>
                                                                </div>
                                                                <div v-else-if="phototmp" >
                                                                    <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                                                        <!-- Avatar place holder -->
                                                                        <div class="card card-xl">
                                                                            <img :src="phototmp" id="uploadfile-1-preview" class="img-fluid border border-white border-3 shadow" :width="widthCompu"  alt="" />
                                                                        </div>
                                                                        <!-- Remove btn -->
                                                                        <button type="button" @click="resetImage" class="updateremove"><i class="bi bi-x text-white"></i></button>
                                                                    </label>
                                                                    <!-- Upload button -->
                                                                    <label class="btn btn-primary-soft mb-0" for="uploadfile">Save</label>
                                                                    <input type="submit" id="uploadfile" class="form-control d-none" >
                                                                </div>

                                                                <div v-else >
                                                                    <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                                                        <!-- Avatar place holder -->
                                                                        <span class="card card-xl">
                                                                            <i class="far fa-image fa-2xl" style="font-size:200px"></i>
                                                                            <!-- <img id="uploadfile-1-preview" class="card-img rounded-circle border border-white border-3 shadow" src="/assets/images/avatar/user.png" alt=""> -->
                                                                        </span>
                                                                    </label>

                                                                    <!-- Upload button -->
                                                                    <label class="btn btn-primary-soft mb-0" for="uploadfile-1">Change</label>
                                                                    <input type="file" accept="image/*" ref="photoref" @change="onChangeFile" id="uploadfile-1" class="form-control d-none" >
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>

                                                    <form @submit.prevent="sendForm" class="row g-4 align-items-center mt-1">

                                                        <!-- Input item -->
                                                        <div class="col-lg-6">
                                                            <label class="form-label">Title Name</label>
                                                            <input type="text" class="form-control" placeholder="Site Name" v-model.trim="inputName">
                                                            <div class="form-text">Enter Title Name. </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="form-label">Company</label>
                                                            <input type="text" class="form-control" placeholder="Company" v-model.trim="inputCompany">
                                                            <div class="form-text">For Company </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="form-label">직책 또는 특기 </label>
                                                            <input type="text" class="form-control" placeholder="보유기술" v-model.trim="inputMajor">
                                                            <div class="form-text">For Majority Text</div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="form-label">Email</label>
                                                            <input type="email" class="form-control" placeholder="외부공개용" v-model.trim="inputEmail">
                                                            <div class="form-text">Using for Customer</div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <label class="form-label">Contact Email</label>
                                                            <input type="email" class="form-control" placeholder="Contact Email" v-model.trim="inputContactemail">
                                                            <div class="form-text">For Inner Contact Email</div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <label class="form-label">Phone</label>
                                                            <input type="text" class="form-control" placeholder="Phone number" v-model.trim="inputPhone">
                                                            <div class="form-text">Enter Phone Number Text </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="form-label">Website</label>
                                                            <input type="text" class="form-control" placeholder="Web Site" v-model.trim="inputWeb">
                                                            <div class="form-text">For Website Text  </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="form-label">Address</label>
                                                            <input type="text" class="form-control" placeholder="address" v-model.trim="inputAddr">
                                                            <div class="form-text">Address</div>
                                                        </div>

                                                        <div class="col-12">
                                                            <label class="form-label">Site Description</label>
                                                            <textarea class="form-control" rows="6" v-model.trim="inputDescription"></textarea>
                                                            <div class="form-text">For write brief description of your organization, or a Website.</div> 
                                                        </div>

                                                        <!-- Save button -->
                                                        <div class="d-sm-flex justify-content-end">
                                                            <button type="submit" class="btn btn-primary mb-0">Save changes</button>
                                                        </div>
                                                    </form>
                                                </div>
                                                <!-- Card body END -->

                                            </div>			
                                        </div>
                                        <!-- Personal Information content END -->
                                        <div class="tab-pane" id="tab-2">
                                            <div class="card shadow">

                                                <!-- Card header -->
                                                <div class="card-header border-bottom">
                                                    <h5 class="card-header-title">공개여부 Settings</h5>
                                                </div>


                                                <!-- Card body START -->
                                                <div class="card-body">

                                                    <form @submit.prevent="sendDisplayForm" class="row g-4 align-items-center mt-1">

                                                        <!-- Radio items -->
                                                        <div class="col-lg-12">
                                                            <label class="form-label">공개 여부</label>
                                                            <div class="d-sm-flex">
                                                                <div class="form-check form-switch form-check-md">
                                                                    <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputDisplay" @click="onChangeDisplay">
                                                                    <label v-if="inputDisplay" class="form-check-label" for="profilePublic">공개 </label>
                                                                    <label v-else class="form-check-label" for="profilePublic">비공개</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Save button -->
                                                        <div class="d-sm-flex justify-content-end">
                                                            <button type="submit" class="btn btn-primary mb-0">Save changes</button>
                                                        </div>
                                                    </form>
                                                </div>
                                                <!-- Card body END -->

                                            </div>			
                                        </div>

                                        <!-- General Settings content START -->
                                        <div class="tab-pane" id="tab-3">
                                            <div class="card shadow">
                                                <!-- Card header -->
                                                <div class="card-header border-bottom d-sm-flex justify-content-between align-items-center">
                                                    <h5 class="card-header-title mb-0">학력/경력 Settings</h5>
                                                    <!-- <a href="#" class="btn btn-sm btn-primary mb-0">Add new</a> -->
                                                </div>
                                                <!-- Card body START -->
                                                <div class="card-body">
                                                    <instructor-dash-setting-career-list-update-component
                                                        v-for="item in careerList"
                                                        :key="item.id"
                                                        :id="item.id"
                                                        :company_id ="item.company_id"
                                                        :career="item.career"
                                                        :name="item.name"
                                                        :major="item.major"
                                                        :seq ="item.seq"
                                                        :min="item.min"
                                                        :max="item.max"
                                                        @changeUpSeq='sendChageUpSeqForm(item.id)'
                                                        @changeDownSeq='sendChageDownSeqForm(item.id)'   
                                                        @sendCareerUpdateForm="sendCareerUpdateForm"                                                                                             
                                                        @removeCareerForm="removeCareerForm"
                                                        />                                                    

                                                    <form @submit.prevent="sendCareerForm" class="row g-4 align-items-center mt-1">
                                                        <!-- Radio items -->
                                                        <div class="col-lg-12">
                                                            <label class="form-label">회사근무/학교졸업 추가</label>
                                                            <div class="d-sm-flex">
                                                                <div class="form-check form-switch form-check-md">
                                                                    <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputCareer" @click="onChangeCareer">
                                                                    <label v-if="inputCareer" class="form-check-label" for="profilePublic"><i class="far fa-address-card fa-fw me-2"></i>회사근무 </label>
                                                                    <label v-else class="form-check-label" for="profilePublic"><i class="fas fa-user-graduate fa-fw me-2"></i>학교졸업</label>
                                                                </div>	
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="form-label">학교이름 or 회사이름 </label>
                                                            <input type="text" class="form-control" placeholder="학교/회사" v-model.trim="inputCareerName">
                                                            <div class="form-text">Enter Name. </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="form-label">졸업내역 or 회사직책</label>
                                                            <input type="text" class="form-control" placeholder="졸업내역 or 회사직책" v-model.trim="inputCareerMajor">
                                                            <div class="form-text">For 졸업, 회사직책 </div>
                                                        </div>

                                                        <!-- Add button -->
                                                        <div class="d-sm-flex justify-content-end">
                                                            <button type="submit" class="btn btn-primary mb-0">Save add</button>
                                                        </div>
                                                    </form>

                                                </div>
                                                <!-- Card body END -->
                                            </div>
                                        </div>

                                        <!-- General Settings content END -->
                                        <div class="tab-pane" id="tab-4">
                                            <div class="card shadow">
                                                <!-- Card header -->
                                                <div class="card-header border-bottom d-sm-flex justify-content-between align-items-center">
                                                    <h5 class="card-header-title mb-0">Skill Settings</h5>
                                                    <!-- <a href="#" class="btn btn-sm btn-primary mb-0">Add new</a> -->
                                                </div>
                                                <!-- Card body START -->
                                                <div class="card-body">
                                                    <instructor-dash-setting-skill-list-update-component
                                                        v-for="item in skillList"
                                                        :key="item.id"
                                                        :id="item.id"
                                                        :company_id ="item.company_id"
                                                        :name="item.name"
                                                        :percent="item.percent"
                                                        :seq ="item.seq"
                                                        :min="item.min"
                                                        :max="item.max"
                                                        @changeUpSeq='sendSkillChageUpSeqForm(item.id)'
                                                        @changeDownSeq='sendSkillChageDownSeqForm(item.id)'   
                                                        @sendSkillUpdateForm="sendSkillUpdateForm"                                                                                             
                                                        @removeSkillForm="removeSkillForm"
                                                        />                                                    

                                                    <form @submit.prevent="sendSkillForm" class="row g-4 align-items-center mt-1">
                                                        <div class="col-lg-8">
                                                            <label class="form-label">skill  </label>
                                                            <input type="text" class="form-control" placeholder="skill" v-model.trim="inputSkillName">
                                                            <div class="form-text">Enter Name. </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <label class="form-label">percent</label>
                                                            <input type="number" class="form-control" placeholder="0" v-model.trim="inputSkillPercent">
                                                            <div class="form-text">For skill percent </div>
                                                        </div>

                                                        <!-- Add button -->
                                                        <div class="d-sm-flex justify-content-end">
                                                            <button type="submit" class="btn btn-primary mb-0">Save add</button>
                                                        </div>
                                                    </form>

                                                </div>
                                                <!-- Card body END -->
                                            </div>
                                        </div>

                                        <!-- Notification setting content START -->
<!-- 
                                        <div class="tab-pane" id="tab-3">
                                            <div class="card shadow">
                                                <div class="card-header border-bottom">
                                                    <h5 class="card-header-title">Notifications Settings</h5>
                                                </div>

                                                <div class="card-body">
                                                    <h6 class="mb-2">Choose type of notifications you want to receive</h6>
                                                    <div class="form-check form-switch form-check-md mb-3">
                                                        <input class="form-check-input" type="checkbox" id="checkPrivacy12" checked="">
                                                        <label class="form-check-label" for="checkPrivacy12">Withdrawal activity</label>
                                                    </div>
                                                    <div class="form-check form-switch form-check-md mb-3">
                                                        <input class="form-check-input" type="checkbox" id="checkPrivacy2">
                                                        <label class="form-check-label" for="checkPrivacy2">Weekly report</label>
                                                    </div>
                                                    <div class="form-check form-switch form-check-md mb-3">
                                                        <input class="form-check-input" type="checkbox" id="checkPrivacy3" checked="">
                                                        <label class="form-check-label" for="checkPrivacy3">Password change</label>
                                                    </div>
                                                    <div class="form-check form-switch form-check-md mb-0">
                                                        <input class="form-check-input" type="checkbox" id="checkPrivacy4">
                                                        <label class="form-check-label" for="checkPrivacy4">Play sound on a message</label>
                                                    </div>

                                                    <h6 class="mb-2 mt-4">Instructor Related Notification</h6>
                                                    <div class="form-check form-switch form-check-md mb-3">
                                                        <input class="form-check-input" type="checkbox" id="checkPrivacy11" checked="">
                                                        <label class="form-check-label" for="checkPrivacy5">Joining new instructors</label>
                                                    </div>
                                                    <div class="form-check form-switch form-check-md mb-3">
                                                        <input class="form-check-input" type="checkbox" id="checkPrivacy5">
                                                        <label class="form-check-label" for="checkPrivacy5">Notify when the instructorss added new courses</label>
                                                    </div>
                                                    <div class="form-check form-switch form-check-md mb-3">
                                                        <input class="form-check-input" type="checkbox" id="checkPrivacy6" checked="">
                                                        <label class="form-check-label" for="checkPrivacy6">Notify when instructors update courses</label>
                                                    </div>
                                                    <div class="form-check form-switch form-check-md mb-0">
                                                        <input class="form-check-input" type="checkbox" id="checkPrivacy7">
                                                        <label class="form-check-label" for="checkPrivacy7">Course weekly report</label>
                                                    </div>

                                                    <h6 class="mb-2 mt-4">Student Related Notification</h6>
                                                    <div class="form-check form-switch form-check-md mb-3">
                                                        <input class="form-check-input" type="checkbox" id="checkPrivacy8" checked="">
                                                        <label class="form-check-label" for="checkPrivacy8">Joining new student</label>
                                                    </div>
                                                    <div class="form-check form-switch form-check-md mb-3">
                                                        <input class="form-check-input" type="checkbox" id="checkPrivacy9">
                                                        <label class="form-check-label" for="checkPrivacy9">Notify when students purchase new courses</label>
                                                    </div>
                                                    <div class="form-check form-switch form-check-md mb-0">
                                                        <input class="form-check-input" type="checkbox" id="checkPrivacy10">
                                                        <label class="form-check-label" for="checkPrivacy10">Course weekly report</label>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
 -->

                                        <!-- Account setting content START -->
                                        <!-- Account setting content END -->

                                        <!-- Social Settings content START -->
                                        <div class="tab-pane" id="tab-5">
                                            <div class="card shadow">
                                                <!-- Card header -->
                                                <div class="card-header border-bottom d-sm-flex justify-content-between align-items-center">
                                                    <h5 class="card-header-title mb-0">Social Media Settings</h5>
                                                    <!-- <a href="#" class="btn btn-sm btn-primary mb-0">Add new</a> -->
                                                </div>
                                                <!-- Card body START -->
                                                <div class="card-body">
                                                    <form @submit.prevent="sendSocial1Form" class="row align-items-center mb-4">
                                                        <!-- Title -->
                                                        <div class="col-lg-9">
                                                            <label class="form-label"><i class="fab fa-facebook  me-2"></i>Enter facebook url</label>
                                                            <input class="form-control" type="text" v-model.trim="inputFacebook">
                                                        </div>
                                                        <!-- Content and input -->
                                                        <div class="col-lg-3 text-lg-end">
                                                            <label class="form-label" style="color:white;">Enter facebook url</label>
                                                            <button type="submit" class="btn btn-primary mb-0">Update</button>
                                                        </div>
                                                    </form>                                                    

                                                    <form @submit.prevent="sendSocial2Form" class="row align-items-center mb-4">
                                                        <!-- Title -->
                                                        <div class="col-lg-9">
                                                            <label class="form-label"><i class="fab fa-youtube text-google-icon me-2"></i>Enter youtube url</label>
                                                            <input class="form-control" type="text" v-model.trim="inputYoutube">
                                                        </div>
                                                        <!-- Content and input -->
                                                        <div class="col-lg-3 text-lg-end">
                                                            <label class="form-label" style="color:white;">Enter youtube url</label>
                                                            <button type="submit" class="btn btn-primary mb-0">Update</button>
                                                        </div>
                                                    </form>                                                    

                                                    <form @submit.prevent="sendSocial3Form" class="row align-items-center mb-4">
                                                        <div class="col-lg-9">
                                                            <label class="form-label"><i class="fab fa-twitter text-twitter me-2"></i>Enter twitter url</label>
                                                            <input class="form-control" type="text" v-model.trim="inputTwitter">
                                                        </div>
                                                        <!-- Content and input -->
                                                        <div class="col-lg-3 text-lg-end">
                                                            <label class="form-label" style="color:white;">Enter twitter url</label>
                                                            <button type="submit" class="btn btn-primary mb-0">Update</button>
                                                        </div>
                                                    </form>
                                                    <form @submit.prevent="sendSocial4Form" class="row align-items-center mb-4">
                                                        <div class="col-lg-9">
                                                            <label class="form-label"><i class="fab fa-instagram text-facebook me-2"></i>Enter instagram url</label>
                                                            <input class="form-control" type="text" v-model.trim="inputInstagram">
                                                        </div>
                                                        <!-- Content and input -->
                                                        <div class="col-lg-3 text-lg-end">
                                                            <label class="form-label" style="color:white;">Enter instagram url</label>
                                                            <button type="submit" class="btn btn-primary mb-0">Update</button>
                                                        </div>
                                                    </form>
                                                    <form @submit.prevent="sendSocial5Form" class="row align-items-center mb-4">
                                                        <div class="col-lg-9">
                                                            <label class="form-label"><i class="fab fa-fw fa-linkedin-in me-2"></i>Enter Linkedin url</label>
                                                            <input class="form-control" type="text" v-model.trim="inputLinkedin">
                                                        </div>
                                                        <!-- Content and input -->
                                                        <div class="col-lg-3 text-lg-end">
                                                            <label class="form-label" style="color:white;">Enter Linked url</label>
                                                            <button type="submit" class="btn btn-primary mb-0">Update</button>
                                                        </div>
                                                    </form>

                                                </div>
                                                <!-- Card body END -->
                                            </div>
                                        </div>
                                        <!-- Social Settings content END -->

                                        <!-- Email Settings content START -->
                                        <!-- Email Settings content END -->

                                    </div>
                                    <!-- Tab Content END -->
                                </div>
                                <!-- Right side END -->
                            </div> <!-- Row END -->		

                        </div>
                        <!-- Card body END -->
                    </div>
                    <!-- Edit profile END -->
                    
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pagess > companydash > CompanyDashSettingUpdate.vue]" ;
import axios from 'axios';

import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';
// import CompanyDashSettingCareerUpdateListComponent from '../../components/companydash/InstructorDashSettingCareerUpdateListComponent.vue';
// import CompanyDashSettingSkillUpdateListComponent from '../../components/companydash/InstructorDashSettingSkillUpdateListComponent.vue';

export default {
    components: {
        "company-dashboard-top": CompanyDashboardTop,
        "company-dashboard-left": CompanyDashboardLeft,
        // "instructor-dash-setting-career-list-update-component" : InstructorDashSettingCareerUpdateListComponent,
        // "instructor-dash-setting-skill-list-update-component" : InstructorDashSettingSkillUpdateListComponent,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,       
            
            inputId : null,

            inputPhoto : null ,
            inputPhotoUpdate : null,  
            inputPhotoTmp : null, 
            inputWidth : 300,
            
            inputName: null,
            inputNameUpdate: null,
            inputCompany: null, 
            inputCompanyUpdate: null, 
            inputMajor: null, 
            inputMajorUpdate: null, 
            inputDescription: null,
            inputDescriptionUpdate: null,
            inputPhone: null, 
            inputPhoneUpdate: null, 
            inputEmail: null, 
            inputEmailUpdate: null, 
            inputContactemail: null, 
            inputContactemailUpdate: null, 
            inputWeb: null, 
            inputWebUpdate: null, 
            inputAddr : null, 
            inputAddrUpdate : null, 

            inputDisplay : null, 
            inputDisplayUpdate : null, 

            //학력 / 경력 
            careerList: [], 
            inputCareer: true,
            inputCareerName : null,
            inputCareerMajor : null, 

            //skill
            skillList: [],
            inputSkillName : null ,
            inputSkillPercent: 0, 

            //social 
            inputFacebook : 'https://',
            inputInstagram : 'https://', 
            inputLinkedin : 'https://', 
            inputYoutube : 'https://', 
            inputTwitter : 'https://', 

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            // this.id = this.isUser() ;
            // if(this.id) {
            this.getInfo();
            // this.getCareerList();
            // }
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },    
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        email : function(){
            return this.$store.getters['auth/email'] ;
        },
        name(){
            return this.inputNameUpdate ;
        },
        phone(){
            return this.inputPhoneUpdate ;
        },
        addr(){
            return this.inputAddrUpdate ; 
        },
        description(){
            return this.inputDescriptionUpdate ; 
        },
        photo(){
            // console.log("[InstructorDashSettingUpdate.vue] photo ()inputPhotoUpdate :", this.inputPhoto);
            return this.inputPhoto ;
        },
        phototmp(){
            // console.log("[InstructorDashSettingUpdate.vue] photo ()inputPhotoTmp :", this.inputPhotoTmp);
            return this.inputPhotoTmp ;
        },
        widthCompu(){
            return this.inputWidth ;
        }
    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/signin');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            // console.log("[InstructorDashSettingUpdate.vue] isAuth():", this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            // console.log("[InstructorDashSettingUpdate.vue] isUser():", this.$store.getters['auth/userId']);
            return this.$store.getters['auth/userId'] ;
        },
        onChangeDisplay(){
            this.inputDisplay = !this.inputDisplay ;
        },
        onChangeCareer(){
            this.inputCareer = !this.inputCareer ;
        },
        
        async getInfo(){
            let funcname__ ="getInfo() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 
            
            // console.log("[InstructorDashSettingUpdate.vue] ${this.$store.getters[auth/isToken]}:", this.$store.getters['auth/isToken'] );
            // console.log("[InstructorDashSettingUpdate.vue] ${this.$store.getters[auth/isToken]}2:", `Bearer  ${this.$store.getters['auth/isToken']}` );

            let serverData = await axios({
                method: 'get',
                url: '/api/company/info',
                headers: {
                    'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined" || serverData === undefined ||  serverData.status !== 200){
                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" && data.status_code == 200){
                    // console.log(filename__+''+funcname__+' response:', data.response);
                    
                    const setting = data.response ;  //기본정보 

                    const careers = data.careers ? data.careers  : [] ;   // 학력, 회사경력 
                    const skills = data.skills ? data.skills: [];     // skill
                    const socials = data.socials ? data.socials : [] ;   // 소셜미디어 

                    this.inputId = setting.id ;
                    this.inputPhoto = setting.photo ? setting.photo : '' ;
                    this.inputPhotoUpdate = setting.photo ? setting.photo : '' ;

                    this.inputName = setting.name ? setting.name : '' ;
                    this.inputNameUpdate = setting.name ? setting.name : '' ;
                    this.inputCompany = setting.company ? setting.company : '' ;
                    this.inputCompanyUpdate = setting.company ? setting.company : '' ;
                    this.inputEmail = setting.email ? setting.email : '' ;
                    this.inputEmailUpdate = setting.email ? setting.email : '' ;
                    this.inputContactemail = setting.contactemail ? setting.contactemail : '' ;
                    this.inputContactemailUpdate = setting.contactemail ? setting.contactemail : '' ;
                    this.inputMajor = setting.major ? setting.major : '' ;
                    this.inputMajorUpdate = setting.major ? setting.major : '' ;
                    this.inputPhone = setting.phone ? setting.phone : '' ;
                    this.inputPhoneUpdate = setting.phone ? setting.phone : '' ;
                    this.inputWeb = setting.web ? setting.web : '' ;
                    this.inputWebUpdate = setting.web ? setting.web : '' ;
                    this.inputAddr  = setting.addr ? setting.addr : '' ;
                    this.inputAddrUpdate  = setting.addr ? setting.addr : '' ;
                    this.inputDescription = setting.description ? setting.description : '' ;
                    this.inputDescriptionUpdate = setting.description ? setting.description : '' ;

                    this.inputDisplay = setting.is_display ;
                    
                    // 학교졸업/회사경력 정보 
                    for(let item of careers ){
                        this.careerList.push({
                            id: item.id, 

                            company_id : item.company_id,
                            name:item.name,
                            career: item.career,
                            major: item.major, 
                            seq: item.seq,
                            min: item.min,
                            max: item.max,
                        });
                    }
                    // skill
                    for(let item of skills ){
                        this.skillList.push({
                            id: item.id, 

                            company_id : item.company_id,
                            name:item.name,
                            percent: item.percent,
                            seq: item.seq,
                            min: item.min,
                            max: item.max,
                        });
                    }

                    // 소셜 정보 
                    for(let item of socials){
                        if(item.name === 'twitter'){
                            this.inputTwitter = item.url ;

                        } else if (item.name === 'facebook'){
                            this.inputFacebook = item.url ;

                        } else if (item.name === 'youtube'){
                            this.inputYoutube = item.url ;

                        } else if (item.name === 'instagram'){
                            this.inputInstagram = item.url ;

                        } else if (item.name === 'linkedin'){
                            this.inputLinkedin = item.url ;
                        }
                    }


                    // console.log(filename__+''+funcname__+' inputId:', this.inputId);
                    // console.log(filename__+''+funcname__+' careerList:', this.careerList);

                } else if( typeof data !== "undefined" && data.status_code == 401){
                    this.dialogTitle ="Error" ;                    
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [401] ";  // this.error = err.msg || 'Failed to authenticate';                

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  
                }
            }


            this.isLoading = false ;            
        },

        // 정보 업데이트  
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'name': this.inputName, 
                'company': this.inputCompany ,
                'major': this.inputMajor,
                'description':this.inputDescription,
                'phone': this.inputPhone ,
                'email': this.inputEmail,
                'contactemail': this.inputContactemail ,
                'web': this.inputWeb ,
                'addr' : this.inputAddr ,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputName === '' || this.inputName === undefined || this.inputName === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이름을 입력해주세요.";
                return ;
            }
            if( this.inputMajor === '' || this.inputMajor === undefined || this.inputMajor === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="특기를 입력해주세요.";
                return ;
            }
            if( this.inputDescription === '' || this.inputDescription === undefined || this.inputDescription === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="설명을 입력해주세요.";
                return ;
            }
            if( this.inputEmail === '' || this.inputEmail === undefined || this.inputEmail === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이메일(외부공개용)을 입력해주세요.";
                return ;
            }
            if( this.inputContactemail === '' || this.inputContactemail === undefined || this.inputContactemail === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이메일(내부컨텍용)을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: `/api/instructor/company/update/${this.inputId}`,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+' data:', serverData.data);

                        if(data.code === 201){
                                                                            
                            this.inputNameUpdate = this.inputName ;
                            this.inputDisplaynameUpdate = this.inputDisplayname ;
                            this.inputDescriptionUpdate = this.inputDescription ;
                            this.inputPhoneUpdate = this.inputPhone ;
                            this.inputAddrUpdate = this.inputAddr ;

                            this.dialogTitle ='Success' ;
                            this.error = '성공했습니다.';     

                        } else {
                            console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                            this.dialogTitle ="입력 실패" ;
                            this.error = serverData.data.msg;

                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // 공개여부 업데이트  
        async sendDisplayForm(){
            let funcname__ ="sendDisplayForm() ";

            let dataForm = { 
                'is_display' : this.inputDisplay,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: `/api/instructor/company/display/update/${this.inputId}`,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+' data:', serverData.data);

                        if(data.code === 201){
                                                                            
                            this.dialogTitle ='Success' ;
                            this.error = '공개상태 변경되었습니다.';     

                        } else {
                            console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                            this.dialogTitle ="입력 실패" ;
                            this.error = serverData.data.msg;

                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // https://codepen.io/Atinux/pen/qOvawK/        
        resetImage: function(){
            // this.inputPhoto= null;
            // this.$refs.photoref.value = null ;  //업로드 type=file 내용 없애기 
            this.inputPhotoUpdate = null;  // this.inputImage = this.$refs.file.files[0];
            this.inputPhotoTmp = null;

        },
        onChangeFile(evt){
            // console.log(filename__+''+funcname__+' onChangeFile()1 ');
            // const files = evt.target.files[0] || evt.dataTransfer.files;
            const file = evt.target.files[0] ;

            // console.log(filename__+''+funcname__+' onChangeFile()2 file:', file);
            
            this.inputPhotoUpdate = file;  // this.inputImage = this.$refs.file.files[0];
            this.inputPhotoTmp = URL.createObjectURL(file);
        },

        //프로필 사진 업로드 
        async sendFileForm(){
            let funcname__ ="sendFileForm() ";

            if(this.inputPhotoUpdate == null){
                this.dialogTitle ="Error" ;
                this.error = "업로드할 사진을 선택해주세요.";
                return ;                       

            }
            // 파일전송 FormData() : https://hagohobby.tistory.com/21 
            const formData = new FormData()
            formData.append('photo', this.inputPhotoUpdate);

            // console.log(filename__+''+funcname__+' formData:', formData);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: `/api/instructor/company/photo/${this.inputId}`,
                    headers: {
                        'Content-Type' : 'multipart/form-data',
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 
                    data: formData

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {
    
                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.code == 201){

                        // console.log(filename__+''+funcname__+' data:', data);
                        
                        this.inputPhoto = data.photo_path ;
                        this.inputPhotoUpdate = null ;
                        this.inputPhotoTmp = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '이미지 변경 성공했습니다.';    
                                                
                    } else {
                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';
                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        //프로필 사진 삭제  
        async removePhotoForm(){
            let funcname__ ="removePhotoForm() ";

            const isConfirm = confirm('Are you delete photo?');
            if(!isConfirm) return ;
            
            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: `/api/instructor/company/photo/${this.inputId}`,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.code == 201){
                        // console.log(filename__+''+funcname__+' data:', data);
                        
                        this.inputPhoto = null ;
                        this.inputPhotoUpdate = null ;
                        this.inputPhotoTmp = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '사진 삭제 성공했습니다.';    

                        const user_id = this.$store.getters['auth/userId'] ;

                        //로그인한 사람과 수정하는 정보가 같으면, 최상단 사진 정보 업데이트 
                        if(this.id == user_id) {
                            const actionPayload = {
                                photo: ''
                            };
                            this.$store.dispatch('auth/Photo', actionPayload);  //  /store/modules/auth/actions.js + mutations.js 

                        }                        

                    } else {
                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';
                    }

                }                    

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="실패" ;
                this.error = error; 
            }
            this.isLoading = false ;

        },

        //학교졸업/회사경력 
        async getCareerList(){
            let funcname__ ="getCareerList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/instructor/company/career/all/'+this.inputId,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;
            } else {
                const data = serverData.data ;


                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.code == 200 ){
                    // console.log(filename__+''+funcname__+'  response:', data.response);
                    
                    let tmpList = data.response ;
                    let list = []; 

                    //메인 카테고리 값들을 입력하기 
                    for(let item of tmpList ){
                        list.push({
                            id: item.id, 

                            company_id : item.company_id,
                            name:item.name,
                            career: item.career,
                            major: item.major, 
                            seq: item.seq,
                            min : item.min,
                            max : item.max,
                        });
                    }

                    // console.log(filename__+''+funcname__+" list:", list);
                    this.careerList = list;
                    // this.isApplying = true ;

                    // console.log(filename__+''+funcname__+" this.careerList:", this.careerList);

                } else {
                    console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.msg;  
                }
            }

            this.isLoading = false ;     
            // if(this.isApplying){
            // 	this.getMemberList();
            // 	this.isApplying = false ;
            // }       
        },

        //학교 경력 입력 
        async sendCareerForm(){
            let funcname__ ="sendCareerForm() ";

            let dataForm = { 
                'company_id' : this.inputId,
                'career': this.inputCareer, 
                'name': this.inputCareerName, 
                'major': this.inputCareerMajor,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/instructor/company/career',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.code == 201){
                        
                        this.inputCareer = true ;
                        this.inputCareerName = null ;
                        this.inputCareerMajor = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '추가했습니다.';     

                        this.isApplying = true ;


                    } else {
                        console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.msg;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
            if(this.isApplying){
                this.isApplying = false ;
                this.getCareerList();
            }

        },

        //학교 경력 입력 
        async sendCareerUpdateForm(_id, _career, _name, _major ){
            let funcname__ ="sendCareerUpdateForm() ";

            let dataForm = { 
                'career': _career, 
                'name': _name, 
                'major': _major,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/instructor/company/career/'+_id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '수정했습니다.';     

                        this.isApplying = true ;


                    } else {
                        console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.msg;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
            if(this.isApplying){
                this.isApplying = false ;
                this.getCareerList();
            }

        },
        // 학교 경력 업데이트 
        async sendChageUpSeqForm(fromId){
            let funcname__ ="sendChageUpSeqForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/instructor/company/career/upseq/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },
                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    // this.dialogTitle ='Success' ;
                    // this.error = '변경에 성공했습니다.';

                    // move                     
                    // this.$router.replace('/admin/open/infos'); 
                    this.isApplying = true ;

                } else {
                    console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                    this.dialogTitle ="순서 변경 실패" ;
                    this.error = serverData.data.msg;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="순서 변경 실패" ;
                this.error = err; // this.error = err.msg || 'Failed to authenticate';
            }

            this.isLoading = false ;
            if(this.isApplying){
                this.getCareerList();
                this.isApplying = false ;
            }
            

        },   
        
        // 순서 변경 : 아래로 내리기         
        async sendChageDownSeqForm(fromId){
            let funcname__ ="sendChageDownSeqForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/instructor/company/career/downseq/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    // this.dialogTitle ='Success' ;
                    // this.error = '변경에 성공했습니다.';

                    this.isApplying = true ;

                } else {
                    console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);

                    this.dialogTitle ="내용 변경 실패" ;
                    this.error = serverData.data.msg;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="내용 변경 실패" ;
                this.error = err; 
            }

            this.isLoading = false ;
            if(this.isApplying) {
                this.getCareerList();
                this.isApplying = false ;
            }
            
        },  
        // 학교 경력 업데이트 
        async removeCareerForm(fromId){
            let funcname__ ="removeCareerForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/instructor/company/career/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },
                }).catch( err => {
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    this.dialogTitle ='Success' ;
                    this.error = '삭제에 성공했습니다.';

                    // move                     
                    // this.$router.replace('/admin/open/infos'); 
                    this.isApplying = true ;

                } else {
                    console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                    this.dialogTitle ="순서 변경 실패" ;

                    // this.error = err.msg || 'Failed to authenticate';
                    this.error = serverData.data.msg;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="순서 변경 실패" ;
                this.error = err; // this.error = err.msg || 'Failed to authenticate';
            }

            this.isLoading = false ;
            if(this.isApplying){
                this.getCareerList();
                this.isApplying = false ;
            }
            

        },   

        //skill
        async getSkillList(){
            let funcname__ ="getSkillList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/instructor/company/skill/all/'+this.inputId,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;
            } else {
                const data = serverData.data ;


                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.code == 200 ){
                    // console.log(filename__+''+funcname__+' response:', data.response);
                    
                    let tmpList = data.response ;
                    let list = []; 

                    //메인 카테고리 값들을 입력하기 
                    for(let item of tmpList ){
                        list.push({
                            id: item.id, 

                            company_id : item.company_id,
                            name:item.name,
                            percent: item.percent,
                            seq: item.seq,
                            min : item.min,
                            max : item.max,
                        });
                    }

                    // console.log(filename__+''+funcname__+" list:", list);
                    this.skillList = list;

                    // console.log(filename__+''+funcname__+" this.skillList:", this.skillList);

                } else {
                    console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.msg;  
                }
            }

            this.isLoading = false ;     
            // if(this.isApplying){
            // 	this.getMemberList();
            // 	this.isApplying = false ;
            // }       
        },

        //skill 입력 
        async sendSkillForm(){
            let funcname__ ="sendSkillForm() ";

            let dataForm = { 
                'company_id' : this.inputId,
                'name': this.inputSkillName, 
                'percent': this.inputSkillPercent,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/instructor/company/skill',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.code == 201){
                        
                        this.inputSkillName = null ;
                        this.inputSkillPercent = 0 ;

                        this.dialogTitle ='Success' ;
                        this.error = '추가했습니다.';     

                        this.isApplying = true ;


                    } else {
                        console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.msg;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
            if(this.isApplying){
                this.isApplying = false ;
                this.getSkillList();
            }

        },

        //skill update 입력 
        async sendSkillUpdateForm(_id, _name, _percent ){
            let funcname__ ="sendSkillUpdateForm() ";

            let dataForm = { 
                'name': _name, 
                'percent': _percent,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/instructor/company/skill/'+_id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '수정했습니다.';     

                        this.isApplying = true ;


                    } else {
                        console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.msg;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
            if(this.isApplying){
                this.isApplying = false ;
                this.getSkillList();
            }

        },
        // skill 업데이트 
        async sendSkillChageUpSeqForm(fromId){
            let funcname__ ="sendSkillChageUpSeqForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/instructor/company/skill/upseq/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },
                }).catch( err => {
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    // this.dialogTitle ='Success' ;
                    // this.error = '변경에 성공했습니다.';

                    // move                     
                    // this.$router.replace('/admin/open/infos'); 
                    this.isApplying = true ;

                } else {
                    console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                    this.dialogTitle ="순서 변경 실패" ;
                    this.error = serverData.data.msg;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="순서 변경 실패" ;
                this.error = err; // this.error = err.msg || 'Failed to authenticate';
            }

            this.isLoading = false ;
            if(this.isApplying){
                this.getSkillList();
                this.isApplying = false ;
            }


        },   

        // 순서 변경 : 아래로 내리기         
        async sendSkillChageDownSeqForm(fromId){
            let funcname__ ="sendSkillChageDownSeqForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/instructor/company/skill/downseq/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    // this.dialogTitle ='Success' ;
                    // this.error = '변경에 성공했습니다.';

                    this.isApplying = true ;

                } else {
                    console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);

                    this.dialogTitle ="내용 변경 실패" ;
                    this.error = serverData.data.msg;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="내용 변경 실패" ;
                this.error = err; 
            }

            this.isLoading = false ;
            if(this.isApplying) {
                this.getSkillList();
                this.isApplying = false ;
            }

        },  
        // skill 업데이트 
        async removeSkillForm(fromId){
            let funcname__ ="removeSkillForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/instructor/company/skill/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },
                }).catch( err => {
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    this.dialogTitle ='Success' ;
                    this.error = '삭제에 성공했습니다.';

                    // move                     
                    // this.$router.replace('/admin/open/infos'); 
                    this.isApplying = true ;

                } else {
                    console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                    this.dialogTitle ="순서 변경 실패" ;
                    this.error = serverData.data.msg;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="순서 변경 실패" ;
                this.error = err; // this.error = err.msg || 'Failed to authenticate';
            }

            this.isLoading = false ;
            if(this.isApplying){
                this.getSkillList();
                this.isApplying = false ;
            }

        },   


        // 소셜 정보 업데이트  
        sendSocial1Form(){
            this.sendSocialForm('facebook',this.inputFacebook);
        },
        sendSocial2Form(){
            this.sendSocialForm('youtube',this.inputYoutube);
        },
        sendSocial3Form(){
            this.sendSocialForm('twitter',this.inputTwitter);
        },
        sendSocial4Form(){
            this.sendSocialForm('instagram',this.inputInstagram);
        },
        sendSocial5Form(){
            this.sendSocialForm('linkedin',this.inputLinkedin);
        },
        async sendSocialForm(name, url){
            let funcname__ ="sendSocialForm() ";

            let dataForm = { 
                'company_id' : this.inputId,
                'name': name, 
                'url': url,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/instructor/company/social/create_update',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     


                    } else {
                        console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.msg;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },



    }
}
</script>

<style scoped>

</style>