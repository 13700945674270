import axios from "axios";

export default {

    async jobLevelAction(context){

        const response = await axios( { 
            method: 'get', 
            url:'/api/job/todo/category/user/'+'aircon',
            // headers: {
            //     Authorization: `Bearer  ${this.$store.getters['auth/isToken']}`, 
            // },
        });
        
        console.log('[store/modules/job/actions.js_jobLevelAction()] response:', response);

        const responseData = await response.data ;
        console.log('[store/modules/job/actions.js_jobLevelAction()] responseData:', responseData);

        if(responseData.code === 200){            
            console.log('[store/modules/job/actions.js_jobLevelAction()] responseData(200):', responseData);
            // console.log('[store/modules/auth/actions.js_login()] responseData.user:', responseData['user']);
            
            const response = responseData['response'] ; 
            // console.log('[store/modules/job/actions.js_jobLevelAction()] productCategory type:', typeof productCategory['admin']);

            context.commit('setJobLevelMutation', {
                
                jobLevel : response,

            });  // store/modules/product/mutations.js

        } else {

            const error = {
                code : responseData.code ,
                msg : responseData.msg 
            } ;           

            // console.error("error ==> [store/modules/auth/actions.js_signup()] : \n", error);
            
            throw error ;            
        }
    },   
      
}