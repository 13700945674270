<template>
<div class="accordion-item mb-3" >
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- create lecture modal -->
    <teleport to="body">
        <div class="modal fade" id="addLecture" tabindex="-1" aria-labelledby="addLectureLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-dark">
                    <h5 class="modal-title text-white" id="addLectureLabel">Add Lecture</h5>
                    <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                </div>
                <form @submit.prevent="sendLectureCreateForm">
                    <div class="modal-body">
                        <div class="row text-start g-3">
                            <!-- Course name -->
                            <div class="col-12">
                                <label class="form-label">Course name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter course name" v-model.trim="inputLecture" >
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger-soft my-0" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-success my-0" data-bs-dismiss="modal">Save Lecture</button>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </teleport>


    <div class="row">
        <!-- Add lecture Modal button -->
        <div class="d-sm-flex justify-content-sm-between align-items-center mb-3">
            <h5 class="mb-2 mb-sm-0">Upload Lecture</h5>
            <button type="button" class="btn btn-sm btn-primary-soft mb-0" data-bs-toggle="modal" data-bs-target="#addLecture"><i class="bi bi-plus-circle me-2"></i>Add Lecture</button>
        </div>

        <!-- Edit lecture START -->
        <div class="accordion accordion-icon accordion-bg-light" id="accordionExample2">
            <companydash-course-lecture-panel-component 
                v-for="lecture in itemList" 
                    :key="lecture.id"
                    :content_id="content_id"
                    :id ="lecture.id"
                    :company_id="lecture.company_id"
                    :title="lecture.title"
                    :min="lecture.min"
                    :max="lecture.max"
                    :is_display="lecture.is_display"
                    :sublist="lecture.subList"
                    @refresh-list="refreshList"
            />

        </div>
        <!-- Edit lecture END -->

    </div>



</div>    
</template>

<script>
// v-for__string__
var filename__ = "[components > companydash > CompanyDashCourseCurriculumPanelComponent.vue] " ;

import axios from 'axios';

import CompanyDashCourseLecturePanelComponent from './CompanyDashCourseLecturePanelComponent.vue';

export default {
    components: {
        "companydash-course-lecture-panel-component":CompanyDashCourseLecturePanelComponent,
    },
    props: [ 'content_id'],
    provide() {
        return {
            curriculumlist : this.refreshList  // CompanyDashCourseTopicUpdateComponent.vue 에서 사용
        }        
    },
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList : [],
            inputLecture : null,

        }
    },
    created(){
        this.getList()
    },    
    computed: {
    },
    methods: {
        handleError(){
            this.error = null;
        },
        refreshList(){
            // console.log(filename__+'refreshList()');
            this.getList()
        },
        // all(lecture, topic) list 
        async getList(){
            let funcname__ ="getList()" ;

            this.isLoading = true;

            let serverData = await axios({
                method: 'get',
                url: '/api/content/course/curriculum/all/'+this.content_id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },

            }).catch( err=> {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            this.isLoading = false ;

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData !== "undefined" && serverData) {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+"  data:", data);

                if(data.status_code == 200 ){

                    const response = data.response ;

                    // console.log(filename__+''+funcname__+' response:', response);
                    
                    let tmpList = response.data ;
                    let list = [];

                    tmpList.sort(function(a,b){
                        // console.log("sequence:",a.sequence)
                        return a.seq-b.seq
                    });
                    // console.log(filename__+''+funcname__+" tmpList:", tmpList);

                    let cnt=1
                    //메인 카테고리 값들을 입력하기 : lecture 
                    for(let item of tmpList ){
                        if (item.parent_id == this.content_id) {
                            list.push({
                                num: cnt++, 
                                id: item.id, 
                                company_id: item.company_id, 
                                title: item.title, 
                                subList : [],
                                is_display : item.is_display,
                                seq: item.seq, 
                                min : item.min,
                                max: item.max,
                            });
                        }
                    }

                    // console.log(filename__+''+funcname__+" list1:", list);

                    //서브 타이틀 배열에 추가하기 : topic  
                    for(let sub of list ){
                        for(let subitem of tmpList ){
                            if (sub.id == subitem.parent_id) {
                                sub.subList.push({
                                    // num: cnt++, 
                                    id: subitem.id,
                                    company_id: subitem.company_id, //cardnew image 업로드할 때 필요
                                    title: subitem.title,    
                                    kind: subitem.kind,    
                                    is_price : subitem.is_price,
                                    topictime : subitem.topictime,                                    
                                    seq : subitem.seq,    
                                    subseq : subitem.subseq,  
                                    submin: subitem.submin,
                                    submax : subitem.submax,  
                                    is_min : subitem.is_min,
                                    is_max : subitem.is_max,
                                    is_display: subitem.is_display                         
                                });
                            }
                        }
                        sub.subList.sort(function(a,b){
                            return a.subseq-b.subseq
                        });

                    }

                    // console.log(filename__+''+funcname__+" list2:", list);
                    this.itemList = list

                    // console.log(filename__+''+funcname__+" itemList:", this.itemList);

                }

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;
        },

        // lecure 생성하기   
        async sendLectureCreateForm(){
            let funcname__ ="sendLectureCreateForm()" ;

            let dataForm = { 
                'group_id' : this.content_id,
                'parent_id' : this.content_id,
                'title': this.inputLecture,
            } ;  

            if( this.inputLecture === '' || this.inputLecture === undefined || this.inputLecture === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="타이틀을 입력해주세요.";
                return ;
            }

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/content/course/curriculum/lecture/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.inputLecture = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.getList();

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    },
    
}
</script>
<style scoped>

</style> 