<template>
    <div>
        <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
            <p>{{ error }}</p>
        </base-dialog>

        <base-dialog :show="isLoading" title="Loading..." fixed>
            <p>Loading...</p>
            <base-spinner></base-spinner>
        </base-dialog>

        <main>
	
    <!-- =======================
    Page Banner START -->
    <company-dashboard-top :title="'Notification'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
                        
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <company-dashboard-left :menu="'notification'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Edit profile START -->

                    <div class="card card-body bg-transparent pb-0 border mb-4">
                        <!-- Search bar -->
                        <div class="row g-3 align-items-center justify-content-between">
                            <div class="col-md-6">
                                <h1 class="h3 mb-2 mb-sm-0">Notification</h1>
                            </div>
                            <div class="col-md-6 text-end">
                                <router-link :to="backToLink" class="btn btn-secondary mb-0 me-2">목록</router-link>
                            </div>
                        </div>

                        <!-- Course information START -->
                        <div class="col-12 mt-3 mb-2">
                            <div class="card bg-transparent border rounded-3 h-100">

                                <!-- Card body START -->
                                <div class="card-body">

                                    <!-- Course image and info START -->
                                    <div class="row g-4">
                                        <!-- Course info and avatar -->
                                        <div class="col-md-12">
                                            <!-- Info -->
                                            <span v-if="inputIsCheck == false" class="badge text-bg-orange"><i class="fas fa-bell"></i></span><br/>
                                            <p class="mb-3 white-space-newline">{{inputContent}}</p>

                                        </div>
                                        <hr/>
                                        <div class="col-md-1">
                                            <div class="avatar avatar-md ">
                                                <img v-if="inputCreatePhoto" :src="inputCreatePhoto" class="rounded-circle" alt="">
                                                <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                                            </div>
                                                <!-- <div class="mb-0 ms-2">
                                                    <h6 class="mb-0">{{inputCreateName}} </h6>
                                                </div> -->

                                                <!-- <h5 class="card-header-title">{{inputCreateName}}</h5> -->
                                        </div>
                                        <div class="col-md-5">
                                            <div class="mb-0">
                                                
                                                <h6 class="mb-0">
                                                    메세지 발송인 : {{inputCreateName}} 
                                                </h6>
                                            </div>

                                            <small>{{inputCreateEmail}}</small>

                                        </div>


                                    </div>
                                    <!-- Course image and info END -->
                                </div>
                                <!-- Card body END -->
                            </div>
                        </div>
                        <!-- Course information END -->

                    </div>


                    <!-- Edit profile END -->
                    
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
    </div>
</template>

<script>
var filename__="[pages > companydash > CompanyDashNotificationDetail.vue]" ;
import axios from 'axios';

import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';

export default {
    components: {
        "company-dashboard-top": CompanyDashboardTop,
        "company-dashboard-left": CompanyDashboardLeft,

    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,       
            
            inputReceiveName : null,
            inputReceiveEmail : null,
            inputReceivePhoto: null,

            inputCreateId : null,
            inputCreateName : null,
            inputCreateEmail : null,
            inputCreatePhoto: null,

            inputContent : null,

            inputDate : null,
            inputIsCheck : null,

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getItem();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },    
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },

        backToLink(){
            return '/companydash/notifications?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },

    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/signin');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            // console.log("[StudentDashProfieleUpdate.vue] isAuth():", this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        

        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/notification/company/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {


                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+''+funcname__+' response:', data.response);
                    const notification = data.response

                    this.inputCreateId = notification.create_id ;
                    this.inputCreateName = notification.create_name ;
                    this.inputCreateEmail = notification.create_email ;
                    this.inputCreatePhoto = notification.create_photo ;

                    this.inputReceiveName = notification.receive_name ;
                    this.inputReceiveEmail = notification.receive_email ;
                    this.inputReceivePhoto = notification.receive_photo ;

                    this.inputContent = notification.content ;

                    this.inputIsCheck = notification.is_check ;
                    this.inputDate = notification.date ;

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                    this.dialogTitle ="입력 실패" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;     
        },


    }
}
</script>

<style scoped>

</style>