import axios from "axios";

var filename__="[store > modules > course > action.js]"
export default {
    // isMessageCategory(state){
    //     return !!state.messagecategory ;
    // },
    async Loading(context){
        let funcname__ = "Load() " ;

        const res = await axios( {
            method: 'get', 
            url:'/api/course/category/all/user',
        });

        // console.log(filename__+funcname__+ 'res:', res);

        const responseData = await res.data ;
        // console.log(filename__+funcname__+' responseData:', responseData);

        if(responseData.status_code === 200){
            // console.log(filename__+funcname__+ response:', responseData.response);

            let category_list = responseData.response ;
            
            context.commit('setLoad', {
                
                is_load: true,
                
                categorylist : category_list,
                    
            });  // store/modules/setting/mutations.js

        } else {
            console.log(filename__+funcname__+ 'error:', responseData.data.error_description);

            // const error = new Error(responseData.msg || 'Failed to authenticate.');
            const error = new Error(responseData.data.error_description);
            console.log(filename__+funcname__+ ': \n', error);

            throw error ;            

        }
        
    },   
}