<template>
    <tr>
        <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
            <p>{{ error }}</p>
        </base-dialog>

        <base-dialog :show="isLoading" title="Loading..." fixed>
            <p>Loading...</p>
            <base-spinner></base-spinner>
        </base-dialog>

        <!-- Table data -->
        <td>{{num}}</td>

        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="avatar avatar-md">
                    <!-- <router-link :to="detailLink" > -->
                        <img v-if="user_photo" :src="user_photo" class="rounded-circle" alt="">
                        <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                    <!-- </router-link> -->
                </div>
            </div>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        <!-- <router-link :to="detailLink" >{{user_name}}</router-link> -->
                        {{user_name}}
                    </h6>
                </div>
            </div>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    {{user_email}}
                </div>
            </div>
        </td>
        <td>
            <button type="button" class="btn btn-sm btn-primary" @click="sendForm">
                <i class="bi bi-plus-circle me-2"></i> Add
            </button>
        </td>

    </tr>    
</template>

<script>
// 200__ 
var filename__= "[components > companydash > CompanyDashCourseWorkerAddListComponent.vue] "
import axios from 'axios';

export default {
    components: {},
    props: [
        'id','num',
        'content_id', 'company_id',
        'user_id','user_name','user_email','user_photo','date',
        'page', 
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            dialogTitle: null,       
        }
    },
    
    computed: {
        // detailLink(){
        //     // return '/instructordash/request/'+this.id+'?page='+this.page ; 
        //     return '#' ;
        // },

    },
    methods: {
        handleError(){
            this.error = null;
        },

        async sendForm(){
			let funcname__ ="sendForm()" ;

			let dataForm = { 
				'content_id': this.content_id,
				'company_id': this.company_id,
				'user_id': this.user_id,
			} ;  

			// console.log(filename__+''+funcname__+' dataForm:', dataForm);

			this.isLoading = true ;

			try {
				let serverData = await axios({
					method: 'post',
					url: '/api/workercourse/create',
					data: dataForm,
					headers: {
						Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
					}, 

				}).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

				});

				if(typeof serverData === "undefined"  ||  serverData.status !== 200){
					this.isLoading = false ;
					return ;

				} else {
					// console.log(filename__+''+funcname__+" serverData:", serverData);

					if(typeof serverData !== "undefined" && serverData.status === 200) {
						const data = serverData.data ;
						// console.log(filename__+''+funcname__+' data:', data);

						if(data.status_code === 201){
														
							this.dialogTitle ='Success' ;
							this.error = '공동작업자를 추가했습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.$emit('sendRefreshForm') ; // 커리큐럼에 수강완료 표시하기 위해서 다시 불러오기 

                                }, 500);
                            }                        

						} else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
						}
					} else {
						this.isLoading = false ;

						this.dialogTitle ='error' ;
						this.error = '관리자에게 문의 부탁합니다.';

					}
				}


			} catch(error){
				console.error(filename__+''+funcname__+' error:',error);

				this.isLoading = false ;

				this.dialogTitle ="전송 실패" ;
				this.error = error; 
			}

			this.isLoading = false ;

		},

    },
    
}
</script>