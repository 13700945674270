<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- 삭제 모달  -->
    <teleport to="body">
        <div class="modal fade" id="deleteMainPhoto" tabindex="-1" aria-labelledby="deleteMainPhotoLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" id="deleteMainPhoto">Delete</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 이미지를 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>   
    </teleport> 

    <form @submit.prevent="sendFileForm">
        <div v-if="photoCompu" >
            <label class="position-relative me-4" :for="content_id+'uploadmainimage'" title="Replace this pic">
                <div class="card card-xl text-center justify-content-center align-items-center">
                    <img :src="photoCompu" class="img-fluid border border-white border-3 shadow"  alt=""  :style="'width:'+width+'px;'" />
                </div>
            </label>

            <div class="d-sm-flex justify-content-center mt-2">
                <!-- <button type="button" class="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#deleteMainPhoto">Remove image</button> -->
                <button type="button" class="btn btn-sm btn-danger" @click="removeForm">Remove image</button>
            </div>
        </div>
        <div v-else >
            <div class="text-center justify-content-center align-items-center p-2 p-sm-3 border border-2 border-dashed position-relative rounded-3">
                <img src="/assets/images/element/gallery.svg" class="h-50px" alt="">
                <div>
                    <h6 class="my-2">Upload course image here, or<a href="#!" class="text-primary"> Browse</a></h6>
                    <label style="cursor:pointer;">
                        <span> 
                            <input class="form-control stretched-link" type="file" name="my-image" accept="image/gif, image/jpg, image/jpeg, image/png"  @change="onChangeFile" :id="content_id+'uploadmainimage'" />
                        </span>
                    </label>
                    <p class="small mb-0 mt-2"><b>Note:</b> Only JPG, JPEG and PNG. Our suggested dimensions are 600px * 450px. Larger image will be cropped to 4:3 to fit our thumbnails/previews.</p>
                    <p class="small mb-0 mt-2">Image Width(쎔네일 이미지 3:2 비율 600x400, 300x200)</p>
                </div>	
            </div>                
            <!-- <div class="d-sm-flex justify-content-end mt-2">
                <button type="button" class="btn btn-sm btn-success-soft mb-3">Change image</button>
            </div> -->
        </div>

    </form>

</div>    
</template>
    
<script>
var filename__= "[components > content > ContentIdMainImageFileUploadPanelComponent.vue] "

import axios from 'axios';

export default {
    components: {
    },
    props: ['content_id','fileKind','category','subCategory', 'width'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,      

            inputWidth : this.width ? this.width : 300,

            inputPhoto : null ,
            inputPhotoUpdate : null,  

            inputId : null ,             
        }
    },
    computed: {
        photoCompu(){
            return this.inputPhoto ;
        },
        widthCompu(){
            return this.inputWidth ;
        },
    },    
    created(){
        // console.log(filename__+" created() content_id:", this.content_id);  //~~log    
        // console.log(filename__+" created() fileKind:", this.fileKind);  //~~log    
        this.getItem()
    },
    methods: {
        handleError(){
            this.error = null;

        },
        onChangeFile(evt){
            const file = evt.target.files[0] ;
            const file_size = 1024*1024*10 ; // 10M 크기 

            if(file.size > file_size ){
                // this.dialogTitle ="Error" ;
                // this.error = "이미지 사이즈는 10M 이하만 업로드 됩니다.";
                alert("이미지 사이즈는 10M 이하만 업로드 됩니다.")
                return ;                       

            } else {
                this.inputPhotoUpdate = file; 
                this.inputFileAddTmp = URL.createObjectURL(file);
                
                this.sendFileForm(); // 파일 업로드 
            }
        },
        // 정보 가져오기 
        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/file/contentid/'+this.content_id+'/'+this.category+'/'+this.subCategory+'/'+this.fileKind,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);  // __log

                if( typeof data !== "undefined" &&  data.status_code == 200 ){
                    
                    // console.log(filename__+''+funcname__+' response:', data.response);  // __log

                    const photo = data.response ;
                    if(photo){
                        this.inputId = data.response.id ;
                        this.inputPhoto = data.response.photo ? data.response.photo  : false ;
                    }
                    // console.log(filename__+''+funcname__+' inputPhoto:', this.inputPhoto);
                           
                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;
                }
            }
            this.isLoading = false ;   
        },

        // 메인 사진 업로드         
        async sendFileForm(){
            let funcname__ ="sendFileForm() ";
            
            if(this.inputPhotoUpdate == null){
                this.dialogTitle ="Error" ;
                this.error = "업로드할 사진을 선택해주세요.";
                return ;                       

            }

            // 파일전송 FormData() : https://hagohobby.tistory.com/21 
            const formData = new FormData()
            formData.append('category', this.category);
            formData.append('subcategory', this.subCategory);
            formData.append('filekind', this.fileKind);
            formData.append('file', this.inputPhotoUpdate);

            // for (const value of formData.values()) {
            //     console.log(filename__+''+funcname__+' formData.value:', value);
            // }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: `/api/content/file/upload/contentid/${this.content_id}`,
                    headers: {
                        'Content-Type' : 'multipart/form-data',
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 
                    data: formData

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){

                        // console.log(filename__+''+funcname__+' data:', data);
                        
                        this.inputId = data.id ;
                        this.inputPhoto = data.photo_path ;
                        this.inputPhotoUpdate = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '이미지 업로드 성공했습니다.';  
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                // this.getItem(); // 이미지 업로드 
                            }, 500);
                        }                        
                        
                        
                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // 메인  사진 삭제  
        async removeForm(){
            let funcname__ ="removeForm() ";

            const isConfirm = confirm('Are you delete file?');
            if(!isConfirm) return ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: `/api/content/file/delete/id/${this.inputId}`,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){
                        // console.log(filename__+''+funcname__+' data:', data);
                        
                        // this.inputId = null ;
                        this.inputPhoto = null ;
                        this.inputPhotoUpdate = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '이미지 삭제 성공했습니다.';    

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }

                }                    

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="실패" ;
                this.error = error; 
            }
            this.isLoading = false ;

        },
    
    }
}
</script>
    
<style scoped>

</style>