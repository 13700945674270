<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>


    <div class="card bg-transparent border h-100">

        <!-- Card header -->
        <div class="card-header bg-light border-bottom">
            <h5 class="mb-0">Courses List</h5>
        </div>

        <!-- Card body START -->
        <div class="card-body pb-0">
            <!-- Table START -->
            <div class="table-responsive border-0">
                <table class="table table-dark-gray align-middle p-4 mb-0 table-hover">

                    <!-- Table head -->
                    <thead>
                        <tr>
                            <!-- <th scope="col" class="border-0 rounded-start">Course Name</th>
                            <th scope="col" class="border-0">Enrolled</th>
                            <th scope="col" class="border-0">Status</th>
                            <th scope="col" class="border-0 rounded-end">Action</th> -->
                            <th scope="col" class="border-0 rounded-start">Num</th>
                            <th scope="col" class="border-0">Course Name</th>
                            <th scope="col" class="border-0">카테고리</th>
                            <!-- <th scope="col" class="border-0">On/Off</th> -->
                            <th scope="col" class="border-0">Type</th>
                            <!-- <th scope="col" class="border-0">Price</th> -->
                            <th scope="col" class="border-0">Status</th>
                            <th scope="col" class="border-0">Rating</th>
                            <th scope="col" class="border-0">Enrolled</th>
                            <th scope="col" class="border-0 rounded-end">Updated Date</th>
                        </tr>
                    </thead>

                    <!-- Table body START -->
                    <tbody>
                        <admin-company-detail-course-list-component v-for="item in itemList"
								:key = "item.id"
								:id ="item.id"
								:num ="item.num"
								
								:title ="item.title"
                                :category ="item.category"
                                :categorystr ="item.categorystr"

								:level ="item.level"
								:coursetime ="item.coursetime"
								:lecturecount ="item.lecturecount"
								:image ="item.image"
								:reviewavg="item.reviewavg"
								:is_display="item.is_display"

                                :date ="item.date"
								:update ="item.update"

								:create_id = "item.create_id" 
								:create_name = "item.create_name" 
								:create_email = "item.create_email" 
								:create_photo = "item.create_photo" 

								:enroll_count="item.enroll_count"

								:page="page" 
								:search="inputSearch" 
								
							/>

                        <!-- Table row -->
                        <!-- <tr>
                            <td class="d-flex align-items-center position-relative">
                                <div class="w-60px"><img src="/assets/images/courses/4by3/10.jpg" class="rounded" alt=""></div>
                                <h6 class="table-responsive-title mb-0 ms-2"><a href="#" class="stretched-link">Bootstrap 5 From Scratch</a></h6>
                            </td>

                            <td>58</td>

                            <td>
                                <span class="badge bg-danger bg-opacity-15 text-danger">Cancel</span>
                            </td>

                            <td>
                                <a href="#" class="btn btn-sm btn-info-soft mb-0">View</a>
                            </td>
                        </tr> -->
                    </tbody>
                    <!-- Table body END -->
                </table>
            </div>
            <!-- Table END -->
        </div>
        <!-- Card body END -->

        <!-- Card footer START -->
        <div v-if="itemList.length > 0"> 
            <admin-pagination :pagination="paginationCompu" @paging="pagingSend" />
        </div>

        <!-- <div class="card-footer bg-transparent">
            <div class="d-sm-flex justify-content-sm-between align-items-sm-center">
                <p class="mb-0 text-center text-sm-start">Showing 1 to 8 of 20 entries</p>
                <nav class="d-flex justify-content-center mb-0" aria-label="navigation">
                    <ul class="pagination pagination-sm pagination-primary-soft d-inline-block d-md-flex rounded mb-0">
                        <li class="page-item mb-0"><a class="page-link" href="#" tabindex="-1"><i class="fas fa-angle-left"></i></a></li>
                        <li class="page-item mb-0"><a class="page-link" href="#">1</a></li>
                        <li class="page-item mb-0 active"><a class="page-link" href="#">2</a></li>
                        <li class="page-item mb-0"><a class="page-link" href="#">3</a></li>
                        <li class="page-item mb-0"><a class="page-link" href="#"><i class="fas fa-angle-right"></i></a></li>
                    </ul>
                </nav>
            </div>
        </div> -->
        <!-- Card footer END -->
    </div>

</div> <!-- Row END -->

</template>

<script>
//  emit__
var filename__= "[components > admin > company > AdminCompanyDetailCoursePanelComponent.vue] "

import axios from 'axios';

import AdminCompanyDetailCourseListComponent from './AdminCompanyDetailCourseListComponent.vue';
import AdminPagination from '../ui/AdminPagination.vue';

export default {
    components: {
        'admin-company-detail-course-list-component' : AdminCompanyDetailCourseListComponent,
        "admin-pagination": AdminPagination,
    },
    props: [
        'company_id',
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            dialogTitle: null,     
            
            itemList : [],
            page:1,
            current_path:"/admin/company/"+this.company_id,  //current_path
			pagination: null, 

            inputPagePerCount : 50,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',    
                                 
        }
    },
    created(){
        this.getList();
    },    
    computed: {
        paginationCompu(){
            return this.pagination ;
        },
    },
    methods: {
        handleError(){
            this.error = null;

        },
        
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        onChangeSearch(){
			this.page = 1 ;
            this.getList(); //page=1
        },

        async getList(){
			let funcname__ ="getList()" ;

			this.isLoading = true ;   

			let serverData = await axios({
				method: 'get',
				url: '/api/content/course/company/admin/'+this.company_id,
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},           
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                    search: this.inputSearch,       
                }

			}).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }
			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			// console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];

				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					list.push({
						num: counter--, 
						id: item.id, 

						title:item.title,
						category:item.category,
						categorystr:item.categorystr,

						level : item.level,
                        coursetime : item.coursetime ? item.coursetime : false ,
                        lecturecount : item.lecturecount ,
                        reviewavg : item.review_avg,

                        image : item.main_photo ? item.main_photo.main_photo_path : false,

						seq:item.seq,
                        date : item.date,
                        update : item.update,
						is_display : item.is_display,
                        
						create_name : item.create_name,
						create_email : item.create_email,
						create_photo : item.create_photo,

						enroll_count: item.enroll_count,
                        
					});
				}

				// console.log(filename__+''+funcname__+" list:", list);
				this.itemList = list;

				// console.log(filename__+''+funcname__+" itemList:", this.itemList);
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);

			} else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

			this.isLoading = false ;            
		},


    },
    
}
</script>
<style scoped>

</style> 