<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>
	
    <!-- =======================
    Page Banner START -->
    <company-dashboard-top :title="'Delete Partner Account'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">

            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <company-dashboard-left :menu="'delete'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Title and select START -->
                    <div class="card border bg-transparent rounded-3 mb-0">
                        <!-- Card header -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="card-header-title mb-0">파트너 계정 탈퇴하기</h3>
                        </div>
                        <!-- Card body -->
                        <div class="card-body">
                            <form @submit.prevent="sendForm">
                                <h6>Before you go...</h6>
                                <ul>
                                    <!-- <li>Take a backup of your data <a href="#">Here</a> </li> -->
                                    <li>파트너 계정 탈퇴시 1개월 동안 정보를 보관하며, 그 이후 모든 파트너 관련 정보는 삭제됩니다.</li>
                                    <li>파트너 계정 탈퇴 즉시, 파트너가 제공하는 콘텐츠는 즉시적으로 노출이 중단됩니다.</li>
                                    <li>수강생들에게 이미 제공됐던 강좌형식의 콘텐츠는 그대로 유지됩니다.</li>
                                </ul>
                                <div class="mb-3">
                                    <label class="form-label">Current password</label>
                                    <div class="input-group">
                                        <input v-if="isShowPassword"  class="form-control" type="text" placeholder="Enter current password" v-model.trim="inputPassword">
                                        <input v-else class="form-control" type="password" placeholder="Enter current password" v-model.trim="inputPassword">
                                        <span class="input-group-text p-0 bg-transparent">
                                            <i class="far cursor-pointer p-2 w-40px"
                                                :class="{ 'fa-eye-slash': !isShowPassword, 'fa-eye': isShowPassword }"
                                                @click="toggleShow"                                                    
                                            ></i>
                                        </span>
										<div class="rounded mt-1" id="psw-strength"></div>
                                    </div>
                                </div>
                                <div class="form-check form-check-md my-4">
                                    <input class="form-check-input" type="checkbox" value="" id="deleteaccountCheck" v-model="inputCheck" @click="onChangeCheck">
                                    <label class="form-check-label" for="deleteaccountCheck">Yes, I'd like to delete my partner account</label>
                                </div>
                                <button type="submit" class="btn btn-danger mb-0">파트너 계정 탈퇴하기</button>
                            </form>
                        </div>
                    </div>
                    <!-- Title and select END -->
                </div>
                <!-- Main content END -->
            </div>
        </div>	
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                    
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pages > companydash > CompanyDashDeleteUpdate.vue]" ;
import axios from 'axios';

import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';

export default {
    components: {
        "company-dashboard-top": CompanyDashboardTop,
        "company-dashboard-left": CompanyDashboardLeft,

    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            id : null,
            isShowPassword : false, 
            inputPassword : '',
            inputCheck : false, 
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },
    computed: {

    },    
    created(){
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
        //     this.id = this.isUser() ;
        //     if(this.id) {
        //         this.getMember();
        //     }
            return ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        toggleShow() {
            this.isShowPassword = !this.isShowPassword;
        },        

        onChangeCheck(){
            this.inputCheck = !this.inputCheck ;
        },

        // 프로필 정보 업데이트  
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'password': this.inputPassword, 
            } ;  

            if( this.inputPassword === "undefined" || this.inputPassword === undefined || this.inputPassword === "null" || this.inputPassword === null )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="비밀번호는 6자리 이상이어야 합니다.";
                return ;
            }

            if( this.inputPassword.length < 6 )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="비밀번호는 6자리 이상이어야 합니다.";
                return ;
            }

            if( this.inputCheck === '' || this.inputCheck === undefined || this.inputCheck === false ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="탈퇴승인 동의를 체크해주세요.";
                return ;
            }

            
            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/company/delete',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {

                    // console.log("[InstructorDashDeleteUpdate.vue] serverData:", serverData);

                    const data = serverData.data ;

                    if(data.status_code === 500){
                        this.dialogTitle ='Fail' ;
                        this.error = '기존 비밀번호가 맞지 않습니다.';     

                        this.inputPassword = null ;
                        this.inputCheck = false ;

                    } else if(data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '파트너 탈퇴 요청이 접수되었습니다. 1개월간 정보 보관 후 관련 정보를 삭제합니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                //모든 토큰 없앰 
                                this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                this.$router.replace('/');

                            }, 2000);
                        }                        


                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        
    }
}
</script>

<style scoped>

</style>