<template>
    <tr>
        <!-- Table data -->
        <td>{{num}}</td>
        <td v-if="category === 'certificate'">
            <img v-if="image" :src="image" class="rounded w-60px" alt="">        
        </td>

        <td>
            <div class="d-flex align-items-center position-relative">
                <div class="mb-0 ms-2">
                    <h6 class="mb-0">
                        <router-link v-if="is_display" :to="detailLink"  class="stretched-link">{{title}}</router-link>
                        <router-link v-else :to="detailLink"  class="stretched-link text-decoration-line-through">{{title}}</router-link>
                        <span v-if="recommend_count > 0" class="badge bg-primary ms-2 ">{{ recommend_count }}</span>
                    </h6>
                    <p v-if="(category === 'degree' || category === 'certificate') && is_display">{{ subtitle }}</p>
                    <p v-if="(category === 'degree' || category === 'certificate') && !is_display" class="text-decoration-line-through">{{ subtitle }}</p>
                </div>
            </div>
        </td>
        <td>
            <router-link :to="detailLink" class="btn btn-primary-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Edit">
                <i class="bi bi-pencil-square"></i>
            </router-link>
        </td>
        <td>
            <!-- <button class="btn btn-danger-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Delete">
                <i class="bi bi-trash"></i>
            </button> -->
            <button v-if="is_display === false" class="btn btn-danger-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Delete">
                숨김
            </button>
        </td>

        <td>
            <button type="button" @click="upSeqCategory" :class="min? 'btn btn-outline-light disabled':'btn btn-outline-primary' "><i class="fas fa-arrow-alt-circle-up"></i></button>
            <button type="button" @click="downSeqCategory" :class="max? 'btn btn-outline-light ms-2 disabled':'btn btn-outline-danger ms-2' "><i class="fas fa-arrow-circle-down"></i></button>
        </td>

        <!-- Table data -->
        <td>
            <router-link :to="updateLink" class="btn btn-success-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Edit">
                <i class="bi bi-pencil-square"></i>
            </router-link>
            <!-- <button class="btn btn-danger-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Delete">
                <i class="bi bi-trash"></i>
            </button>
            <a href="#" class="btn btn-sm btn-info-soft mb-0" data-bs-toggle="modal" data-bs-target="#viewReview">View</a> -->
        </td>
    </tr>    
</template>

<script>
export default {
    components: {},
    props: [
        'category',
        'id','num','seq','min','max','is_display',
        'recommend_count',
        'title','subtitle','image',
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            return '/admin/course/recommends/'+this.id+'/'+this.category+'/'+this.title ; 
        },
        updateLink(){
            return '/admin/course/recommendcategory/update/'+this.category+'/'+this.id ; 
        },

    },
    methods: {
        upSeqCategory(){
            this.$emit('changeUpSeq', this.id)
        },
        downSeqCategory(){
            this.$emit('changeDownSeq', this.id)
        },

    },
    
}
</script>