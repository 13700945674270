import mutations from './mutations.js' ;
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state(){
        return {
            dashboard: false,
            company_dashboard: false,   // 파트너 여부 
            is_courseworker_dashboard: false,  // 강좌 공동작업자 여부 
        }
    },    
    mutations,
    actions,
    getters
}
