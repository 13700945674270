<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>
    
    <main>

<!-- =======================
Page content START -->
<section class="pt-3 pt-xl-5">
	<div class="container" data-sticky-container>
		<div class="row g-4">
			
			<!-- Main content START -->
			<div class="col-xl-8">

				<div class="row g-4">
					<div v-if="blog" >
						<blog-detail-center-component 
                            ref="centerRef"
							:content_id="id" 
							:company_id="blog.company_id"
							:blog="blog" 							
							:topic_id="topicIdCompu"
							:topic_title="topicTitleCompu"
							
						/>
					</div>

					<!-- Course detail Bottom Tab -->
					<div v-if="blog" class="col-12 mt-2">
						<blog-detail-bottom-tab-component :content_id="id" :blog="blog" />                        
					</div>
					<!-- Course detail END -->
                </div>
			</div>
			<!-- Main content END -->

			<!-- Right sidebar START -->
			<div class="col-xl-4">
				<div v-if="blog">                    
					<blog-detail-right-top-component 
						:id="id" 
						:blog="blog" 
                        :path="backToLink"
					/>   

				</div>

			</div>
			<!-- Right sidebar END -->

		</div><!-- Row END -->
	</div>
</section>
<!-- =======================
Page content END -->

</main>

    <hr/>
    </div>
</template>

<script>
var filename__ = "[pages > blog > BlogDetail.vue]" ;

import axios from 'axios';

import BlogDetailCenterComponent from '../../components/blog/BlogDetailCenterComponent.vue';
import BlogDetailBottomTabComponent from '../../components/blog/BlogDetailBottomTabComponent.vue';

import BlogDetailRightTopComponent from '../../components/blog/BlogDetailRightTopComponent.vue';

export default {
    components: {
        "blog-detail-center-component": BlogDetailCenterComponent,
        "blog-detail-bottom-tab-component": BlogDetailBottomTabComponent,

		"blog-detail-right-top-component": BlogDetailRightTopComponent,

    },
    props: ['id','listpath'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,     
			
            inputUuidid : null, 
			blog : null,

			inputIsExist : false, //수강신청 여부    
            InputStudentCourseId : null, // 수강취소를 위해서 
            inputIsWishlist : false, // wishlist 여부   

			inputTopic_id : null,   //토픽 수강완료 
			inputTopic_title : null, //토픽 타이틀 

			inputTopic_kind : 0,  //토픽 처음 시작 :0,  2: 영상 , 4:텍스트, 1 : 이미지 

			inputTopic_url : null,  //토픽 url 
			inputTopic_description : null,  //토픽 설명
			inputTopic_photo : null,  //토픽 사진

			inputTopic_iscomplete : null,  //토픽 수강완료 여부  

            inputIsChangeTopic : false, 
					
        }
    },
    created(){
        this.getItem();
    },
    computed: {
		backToLink(){
			return '/'+this.$route.query.listpath+'?page='+this.$route.query.page+'&search='+this.$route.query.search
		},

		topicIdCompu(){
			return this.inputTopic_id ;
		},
		topicTitleCompu(){
			return this.inputTopic_title ;
		},

		topicKindCompu(){
			return this.inputTopic_kind ;
		},

    },    
    methods: {
        handleError(){
            this.error = null;
        },

        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },

		//course 값 불러오기 
        async getItem(){
            let funcname__ =" getItem() "
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/content/blog/user/'+this.id,

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }
            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.blog = data.response ;       
                this.isApplying = true ;

				this.inputCompany_id = this.blog.company_id ;
                
				// console.log(filename__+''+funcname__+" inputCompany_id:", this.inputCompany_id);
				// console.log(filename__+''+funcname__+" course:", this.course);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;    
        },

    }
}
</script>

<style scoped>

</style>