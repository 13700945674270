<template>
<div class="col-sm-6 col-xl-3">
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="card shadow h-100">
        <!-- Image -->
        <img v-if="image" :src="image" class="card-img-top" alt="course image">
        <img v-else src="/assets/images/courses/course.png" class="card-img-top" alt="course image">

        <!-- Card body -->
        <div class="card-body pb-0">
            <!-- Badge and favorite -->
            <div class="d-flex justify-content-between mb-2">
                <div>
                    <a v-if="level ==0" href="#" class="badge bg-success bg-opacity-10 text-success">All level</a>
                    <a v-else-if="level ==1" href="#" class="badge bg-primary bg-opacity-10 text-primary">Beginner</a>
                    <a v-else-if="level ==2" href="#" class="badge bg-orange bg-opacity-10 text-orange">Intermediate</a>
                    <a v-else-if="level ==3" href="#" class="badge bg-purple bg-opacity-10 text-purple">Advanced</a>

                </div>
            </div>
            <!-- Title -->
            <h5 class="card-title"><router-link :to="detailLink">{{title}}</router-link></h5>
            <p class="mb-2 text-truncate-2">{{subtitle}}</p>
            
            <div class="d-flex justify-content-between mb-2">
                <!-- Rating star -->
                <ul class="list-inline mb-0">
                    <li v-if="reviewavg > 0" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    <li v-if="reviewavg >= 1" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    <li v-if="reviewavg >= 2" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    <li v-if="reviewavg >= 3" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    <li v-if="reviewavg >= 4" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                    <li v-if="reviewavg == 5" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>

                    <li class="list-inline-item ms-2 h6 fw-light mb-0">{{reviewavg}}/5.0</li>
                </ul>
                <a v-if="categorystr" href="#" class="badge bg-primary bg-opacity-10 text-success ms-1"><b>{{ categorystr }}</b></a>
            </div>
        </div>
        <!-- Card footer -->
        <div class="card-footer pt-0 pb-3">
            <hr>
            <div class="d-flex justify-content-between">
                <span class="h6 fw-light mb-0"><i class="fas fa-table text-orange me-2"></i>{{lecturecount}}회 강의</span>
                <span class="h6 fw-light mb-0"><i class="far fa-clock text-danger me-2"></i>{{timeFormat}}</span>
            </div>
        </div>
    </div>
</div>

</template>

<script>
var filename__="[components > course > TotalCourseListComponent.vue] "

import axios from 'axios';

export default {
    components: {},
    props: [
        'id','num','create_id','create_name','create_email','create_photo','date',
        'title','subtitle','level','is_price','price','coursetime','lecturecount','image','is_display', 'onoff', 'reviewavg', 
        'categorystr',
        'parent_category','category_id','category_title',
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  

            inputIsWishlist : this.is_wishlist,
        }
    },
    
    computed: {
        detailLink(){
            return '/topmenucourse/'+this.id+'/'+this.parent_category+'/'+this.category_id+'/'+this.category_title
        },
        timeFormat(){
            let hour = parseInt(this.coursetime/60) ;
            let minute = this.coursetime - hour * 60 ;
            if(hour == 0){
                return this.coursetime +'분'                
            } else if(minute == 0){
                return hour+'시간'
            } else {
                return hour + '시간 '+minute + '분' ;                
            }
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }
        },

        onChangeWishlist(){
            this.inputIsWishlist = !this.inputIsWishlist ;
            if(this.inputIsWishlist){
                this.sendForm();
            } else {
                this.removeForm();
            }
            
        },
        // wishlist 
        async sendForm(){
            let funcname__ ="sendForm()" ;

            let dataForm = { 
                'course_id':this.id,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/student/wishlist/create',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    const data = serverData.data ;

                    // console.log(filename__+''+funcname__+" data.code:", data.code);
                    if(data.code == 201){
                                                
                        this.dialogTitle ='Success' ;
                        this.error = 'Wishlist 등록에 성공했습니다.';   

                        // this.$emit('changeIsExist', true)

                    } else {
                        // console.log(filename__+''+funcname__+' return err:', serverData.data.msg);

                        this.error = serverData.data.msg;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },     
        // wishlist 삭제   
        async removeForm(){
            let funcname__ = "removeForm() " ;

            this.isLoading = true ;
            let dataForm = { 
                'course_id':this.id,
            } ;  

            // console.log(filename__+funcname__+' dataForm :', dataForm);

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/student/wishlist/delete',
                    data: dataForm,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+funcname__+"serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.code == 201){
                        // console.log(filename__+funcname__+' data:', serverData.data);

                        this.dialogTitle ='Success' ;
                        this.error = 'Wishlist 삭제에 성공했습니다.';   
                        
                        this.isApplying = true ;

                    } else {
                        console.log(filename__+funcname__+' return err:', serverData.data.msg);

                        this.error = serverData.data.msg;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },     

    },
    
}
</script>