<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>

<!-- =======================
Page content START -->
<section class="pt-2 pb-0">
	<div class="container">
		<div class="row g-1 g-lg-3">

			<!-- Left sidebar START -->
            <div class="col-lg-12 text-end">
                <router-link :to="backToLink" class="btn btn-primary rounded" >목록보기</router-link>
            </div>
			<div class="col-lg-4">
				<div class="row">
					<div class="col-md-6 col-lg-12">
						<!-- Instructor image START -->
                        <div v-if="isApplyingCompu" class="card shadow p-2 mb-4 text-center">
                            <!-- 강사 이미지  -->
                            <company-detail-image-social-component :companyinfo="inputCompanyInfo" :socials="socialList" />

                        </div>
						<!-- Instructor image END -->
					</div>

					<div v-if="careerList.length >0 || skillList.length > 0" class="col-md-6 col-lg-12">
						<div v-if="careerList.length >0 && isApplyingCompu" class="card card-body shadow p-4 mb-4">
                            <company-detail-career-panel-component :careers="careerList"  />
						</div>
						<div v-if="skillList.length > 0 && isApplyingCompu" class="card card-body shadow p-4 mb-4">
                            <company-detail-skill-panel-component  :skills="skillList" />
						</div>
					</div>

					<div v-if="isWorkerCompu" class="col-md-6 col-lg-12 text-center">
                        <!-- 코스와 공동작업자는 company_id(company 생성자 id) 로 생성해야 됨  -->
                        <company-detail-worker-component :company_id="company_id"  />
                    </div>

				</div> <!-- Row End -->
			</div>
			<!-- Left sidebar END -->

			<!-- Main content START -->
			<div class="col-lg-8">
                <div v-if="isApplyingCompu" class="card card-body shadow p-4 mb-2">
                    <company-detail-info-component :companyinfo="inputCompanyInfo" />
                </div>


				<!-- Course START -->
                <!-- 코스와 공동작업자는 company_id(company 생성자 id) 로 생성해야 됨  -->
                <company-detail-course-panel-component :id="id" />
				<!-- Course END -->
            </div>
			<!-- Main content END -->

		</div><!-- Row END -->
	</div>
</section>
<!-- =======================
Page content END -->

</main>

    <hr/>
    </div>
</template>

<script>
var filename__="[pages > company > CompanyDetail.vue] "
import axios from 'axios';

import CompanyDetailImageSocialComponent from '../../components/company/CompanyDetailImageSocialComponent.vue';
import CompanyDetailCareerPanelComponent from '../../components/company/CompanyDetailCareerPanelComponent.vue';
import CompanyDetailSkillPanelComponent from '../../components/company/CompanyDetailSkillPanelComponent.vue';

import CompanyDetailInfoComponent from '../../components/company/CompanyDetailInfoComponent.vue';

// 공동작업자 등록 여부 확인 
import CompanyDetailWorkerComponent from '../../components/company/CompanyDetailWorkerComponent.vue';
import CompanyDetailCoursePanelComponent from '../../components/company/CompanyDetailCoursePanelComponent.vue';

export default {
    components: {
        "company-detail-image-social-component": CompanyDetailImageSocialComponent,
        "company-detail-career-panel-component": CompanyDetailCareerPanelComponent,
        "company-detail-skill-panel-component": CompanyDetailSkillPanelComponent,
        "company-detail-info-component": CompanyDetailInfoComponent,
        "company-detail-worker-component": CompanyDetailWorkerComponent,
        "company-detail-course-panel-component": CompanyDetailCoursePanelComponent,
    },
    props: ['id','company_id', 'page','search'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,
            errorLogin : null,
            dialogTitle: null,

            inputCompanyInfo : null,
            careerList : [],
            skillList : [],
            socialList : [],
            inputIsWorker : false, //공동작업자 

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){
        this.getItem();
        this.getCareerList();
        this.getSkillList();
        this.getSocialList();
        if(this.isAuth()){
            this.getIsWorker();
        }
        
    },
    computed: {
        backToLink(){
            // return '/instructors?page='+this.$route.query.page+'&search='+this.$route.query.search ; 
            return '/companies?page='+this.page+'&search='+this.search ; 
        },	
        isApplyingCompu(){
            return this.isApplying ;
        },
        isLogged(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isWorkerCompu(){
            // 로그인하고 파트너에서 공동작업자 true 일때만 선택가능
            if (this.isAuth() && this.isApplying === true && this.inputIsWorker === true ){
                return true ;
            } else {
                return false ;
            }
        },

    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        async getItem(){
            let funcname__ ='getItem() ';

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            // console.log("[InstructorDetail.vue] ${this.$store.getters[auth/isToken]}:", this.$store.getters['auth/isToken'] );
            // console.log("[InstructorDetail.vue] ${this.$store.getters[auth/isToken]}2:", `Bearer  ${this.$store.getters['auth/isToken']}` );

            let serverData = await axios({
                method: 'get',
                url: '/api/company/user/'+this.id,

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined" || serverData === undefined ||  serverData.status !== 200){
                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

            } else {
                const data = serverData.data ;

                // console.log("[InstructorDetail.vue] data:", data);

                if( typeof data !== "undefined" && data.status_code == 200){
                    // console.log(filename__+funcname__+' response:', data.response);
                    
                    this.inputCompanyInfo = data.response ;  //기본정보 
                    const careers = data.careers ? data.careers :[] ;   // 학력, 회사경력 
                    const skills = data.skills ? data.skills : [] ;     // skill
                    const socials = data.socials ? data.socials : [] ;   // 소셜미디어 
                    
                    // 학교졸업/회사경력 정보 
                    for(let item of careers ){
                        this.careerList.push({
                            id: item.id, 
                            company_id : item.company_id,
                            name:item.name,
                            career: item.career,
                            major: item.major, 
                        });
                    }
                    // skill
                    let count = 0 ; //색깔을 변환시키기 위해서 
                    for(let item of skills ){
                        this.skillList.push({
                            id: item.id, 
                            count : count++,
                            company_id : item.company_id,
                            name:item.name,
                            percent: item.percent,
                        });
                    }

                    // 소셜 정보 
                    for(let item of socials){
                        this.socialList.push({
                            id : item.id,
                            name : item.name,
                            url : item.url, 
                        })
                    }

                    this.isApplying = true ;  //내부 컴포넌트 불러오기 위해서 

                    // console.log(filename__+funcname__+' inputCompanyInfo:', this.inputCompanyInfo);
                    // console.log(filename__+funcname__+' socialList:', this.socialList);
                    // console.log(filename__+funcname__+' skillList:', this.skillList);
                    // console.log(filename__+funcname__+' careerList:', this.careerList);

                } else if( typeof data !== "undefined" && data.status_code == 401){
                    this.dialogTitle ="Error" ;                    
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [401] ";  // this.error = err.msg || 'Failed to authenticate';                

                } else {
                    console.log(filename__+funcname__+' err.error_description:', serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  
                }
            }

            this.isLoading = false ;   
        },
        //학교졸업/회사경력 
        async getCareerList(){
            let funcname__ ="getCareerList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/company/setting/all/user/career/'+this.id,
            }).catch( err => {

                let dataForm = { 
                    category: 'errorlog(api catech)' , 
                    filename : filename__,
                    methodname : funcname__,
                    title: 'Error(API)',       
                    content : ''+err,
                } ;  
                console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                axios({
                    method: 'post',
                    url: '/api/errorlog/create',
                    data: dataForm,
                });

                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                
                this.isLoading = false ;

                return ;

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;
            } else {
                const data = serverData.data ;


                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){
                    // console.log(filename__+''+funcname__+'  response:', data.response);
                    
                    let tmpList = data.response ;
                    let list = []; 

                    //메인 카테고리 값들을 입력하기 
                    for(let item of tmpList ){
                        list.push({
                            id: item.id, 

                            company_id : item.company_id,
                            name:item.name,
                            career: item.career,
                            major: item.major, 
                        });
                    }

                    // console.log(filename__+''+funcname__+" list:", list);
                    this.careerList = list;
                    // this.isApplying = true ;

                    // console.log(filename__+''+funcname__+" this.careerList:", this.careerList);

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    this.dialogTitle ="입력 실패" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;     
        },
        
        //경력
        async getSkillList(){
            let funcname__ ="getSkillList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/company/setting/all/user/skill/'+this.id,
            }).catch( err => {

                let dataForm = { 
                    category: 'errorlog(api catech)' , 
                    filename : filename__,
                    methodname : funcname__,
                    title: 'Error(API)',       
                    content : ''+err,
                } ;  
                console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                axios({
                    method: 'post',
                    url: '/api/errorlog/create',
                    data: dataForm,
                });

                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                
                this.isLoading = false ;

                return ;

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;
            } else {
                const data = serverData.data ;


                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){
                    // console.log(filename__+''+funcname__+'  response:', data.response);
                    
                    let tmpList = data.response ;
                    let list = []; 

                    //메인 카테고리 값들을 입력하기 
                    let count=0;
                    for(let item of tmpList ){
                        list.push({
                            id: item.id, 

                            company_id : item.company_id,
                            name:item.name,
                            percent: item.percent,
                            count : count++,
                        });
                    }

                    // console.log(filename__+''+funcname__+" list:", list);
                    this.skillList = list;
                    // this.isApplying = true ;

                    // console.log(filename__+''+funcname__+" this.careerList:", this.careerList);

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    this.dialogTitle ="입력 실패" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;     
        },

        //소셜
        async getSocialList(){
            let funcname__ ="getSocialList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/company/setting/all/user/social/'+this.id,
            }).catch( err => {

                let dataForm = { 
                    category: 'errorlog(api catech)' , 
                    filename : filename__,
                    methodname : funcname__,
                    title: 'Error(API)',       
                    content : ''+err,
                } ;  
                console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                axios({
                    method: 'post',
                    url: '/api/errorlog/create',
                    data: dataForm,
                });

                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                
                this.isLoading = false ;

                return ;

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;
            } else {
                const data = serverData.data ;


                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){
                    // console.log(filename__+''+funcname__+'  response:', data.response);
                    
                    let tmpList = data.response ;
                    let list = []; 

                    //메인 카테고리 값들을 입력하기 
                    for(let item of tmpList ){
                        list.push({
                            id: item.id, 

                            company_id : item.company_id,
                            name:item.name,
                            url: item.url,
                        });
                    }

                    // console.log(filename__+''+funcname__+" list:", list);
                    this.socialList = list;
                    // this.isApplying = true ;

                    // console.log(filename__+''+funcname__+" this.careerList:", this.careerList);

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    this.dialogTitle ="입력 실패" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;     
        },
        //공동작업자 
        async getIsWorker(){
            let funcname__ ="getIsWorker() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/company/setting/worker/user/'+this.id,  // id : company_id 
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                let dataForm = { 
                    category: 'errorlog(api catech)' , 
                    filename : filename__,
                    methodname : funcname__,
                    title: 'Error(API)',       
                    content : ''+err,
                } ;  
                console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                axios({
                    method: 'post',
                    url: '/api/errorlog/create',
                    data: dataForm,
                });

                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                
                this.isLoading = false ;

                return ;

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;
            } else {
                const data = serverData.data ;


                console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){
                    // console.log(filename__+''+funcname__+'  response:', data.response);
                    
                    this.inputIsWorker = data.response ;

                    console.log(filename__+''+funcname__+" this.inputIsWorker:", this.inputIsWorker);

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    this.dialogTitle ="입력 실패" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;     
        },






    }
}
</script>

<style scoped>

</style>
