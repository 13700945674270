<template>
<li>
    <label class="position-relative text-center me-4" for="uploadfile-1" >
        <div class="w-auto me-4">
            <label class="border border-white border-3 shadow"  >
                <a :href="file" v-text="fileCompu" @click.prevent="downloadItem" />
            </label>
            
        </div>
    </label>
</li>    
</template>

<script>
var filename__= "[components > admin > content > AdminContentFileListDetailComponent.vue] "
import axios from 'axios';

export default {
    components: {},
    props: [
        'id', 'img_id', 'file',
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

        }
    },
    created(){
        
    },    
    computed: {
        fileCompu(){
            let filename = this.file.replace(/^.*[\\\/]/, '') ;
            return filename ;
        }

    },
    methods: {
        handleError(){
            this.error = null;
        },
        async downloadItem() {
            axios.get(this.file, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/octet-stream' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = this.fileCompu
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        }
    },
    
}
</script>
<style scoped>

</style> 