<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>
        
    <!-- Edit profile START -->
    <div class="page-content-wrapper border">
        <!-- Card header -->
        <div class="card-header bg-transparent border-bottom mb-2">
            <h3 class="card-header-title mb-0">정보창고 생성하기</h3>
        </div>

        <!-- Card body START -->
        <div class="card-body">
            

            <form @submit.prevent="sendForm" class="row g-4 mt-2">

                <!-- <hr/> -->
				<div class="col-md-12" >
					<div class="form-check form-switch form-check-md">
						<input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsDisplay" @click="onChange">
						<label v-if="inputIsDisplay" class="form-check-label" for="profilePublic">외부 공개</label>
						<label v-else class="form-check-label" for="profilePublic"><span  class="badge text-bg-orange">외부 비공개</span></label>
					</div>
                    <div class="form-check form-switch form-check-md">
                        <input class="form-check-input" type="checkbox" role="switch" id="isImage"  v-model="inputIsText" @click="onChangeIsText">
                        <label v-if="!inputIsText"  class="form-check-label" for="isImage">이미지 존재</label>
                        <label v-else class="form-check-label" for="isImage">이미지 없음</label>
                    </div>
				</div>
                <div v-if="!inputIsText" class="col-md-12">
                    <admin-main-image-panel-component :uuidid="inputUuidid" />
                </div>
                <div class="col-md-12">
                    <label class="form-label">Title</label>
                    <div class="input-group">
                        <input type="text" v-model.trim="inputTitle" class="form-control" placeholder="타이틀">
                    </div>
                </div>

                <div class="col-md-12">
                    <label class="form-label">짧은 설명(이미지 대신)</label>
                    <div class="input-group">
                        <textarea class="form-control" rows="5" placeholder="이미지 없을시 대신 노출 (250자 이상)" v-model.trim="inputSubtitle"></textarea>
                    </div>
                </div>
                
                <div class="col-md-12">
                    <label class="form-label">Content (<a href="https://gist.github.com/ihoneymon/652be052a0727ad59601" target="_blank">마크다운 설명 링크</a>)</label>
                    <div class="editor">
                        <!-- <textarea class="input" :value="inputMarkdown" @input="update" @input="updateMarkdown"></textarea> -->
                        <textarea class="input" v-model.trim="inputMarkdown" ></textarea>
                        <div class="output" v-html="outputMarkdownCompu"></div>
                    </div>
                </div>

                <div class="col-md-12 mt-2"  >                             
                    <label class="position-relative me-4" for="uploadfile-1">
                        위의 Markdown와 연동된 이미지이므로, 아래 이미지를 삭제하면 실제 존재하는 이미지가 삭제됩니다. 이미지 클릭(경로 복사) 
                    </label>                    
                </div>
                
                <!-- <admin-content-image-panel-component :uuidid="inputUuidid" /> -->
                <hr/>
                <!-- <admin-content-file-panel-component :uuidid="inputUuidid" /> -->

                <!-- Save button -->
                <div class="d-sm-flex justify-content-end">
                    <button type="button" @click="cancelForm" class="btn btn-secondary mb-0">Cancel</button>
                    <button type="submit" class="btn btn-primary mb-0 ms-2">Create</button>
                </div>                

            </form>
        </div>
        <!-- Card body END -->
    </div>
    <!-- Edit profile END -->



<!-- Page content END -->
    
</div>
    <!-- <div v-html="description"></div> -->
</template>

<script>
//  file__ formData__  ifelse__
var filename__= "[pages > admin > blog > AdminBlogCreate.vue] "

import axios from 'axios';

// markdown 참고자료 : 
// https://vuejs.org/examples/#markdown
// https://codepen.io/immarina/pen/oNxXzyB
import { marked } from 'marked';         // yarn add marked  https://marked.js.org/

// uuid__
// 참고자료 : https://jane-aeiou.tistory.com/59
// 아직 blog 페이지가 만들어지기 전에 이미지나 업로드 파일을 저장해야 하기 때문에, 이를 구분하기 위해서 
import { v4 } from 'uuid';

import AdminMainImagePanelComponent from '../../../components/admin/content/AdminMainImagePanelComponent.vue' ;
// import AdminContentImagePanelComponent from '../../../components/admin/content/AdminContentImagePanelComponent.vue' ;
// import AdminContentFilePanelComponent from '../../../components/admin/content/AdminContentFilePanelComponent.vue' ;

export default {
    components: {
        'admin-main-image-panel-component': AdminMainImagePanelComponent,
        // 'admin-content-image-panel-component': AdminContentImagePanelComponent,
        // 'admin-content-file-panel-component': AdminContentFilePanelComponent,
    },
    props: {
    },
    data() {
        return {
            isLoading: false,
			isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputId : null, 
            inputUuidid : null,
            inputCategory : 'blog',
            inputSubCategory : 'blog',
            inputKind : 'image',

            inputIsText : false,
            
            inputTitle: null,
            inputSubtitle : '',
            inputIsDisplay : false,

            inputDiscription: null, 
            inputDescriptionCategory :'markdown',

            inputMarkdown : 
`
# This is a H1
## This is a H2
### This is a H3
#### This is a H4
##### This is a H5
###### This is a H6

---

1. 첫번째
2. 두번째
3. 세번째

---

- 빨강
  - 녹색
    - 파랑

    ---

> This is a first blockqute.
>	> This is a second blockqute.
>	>	> This is a third blockqute.

---

[Google](https://google.com, "google link")

* 외부링크: <https://google.com/>

---

**글자 강조**

~~취소선~~

---

![image](/assets/images/about/01.jpg)
<img src='/assets/images/about/01.jpg' width='30%' >

`, 
            
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    mounted() {
    },
    setup(){
        // let funcname__ ="setup() ";
        // Set options
        // marked.use({
        //     async: true,
        //     pedantic: false,
        //     gfm: true,
        // });

        // uuid 생성 
        // 아직 blog 페이지가 만들어지기 전이라서, 이미지 업로드 할 때 이를 구분학 ㅣ우해서 
        const generate_uuid = () => {
            const tokens = v4().split('-')
            return tokens[2] + tokens[1] + tokens[0] + tokens[3] + tokens[4];
        }
        const uuidid = generate_uuid() 
        return { uuidid }
    },        
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.inputUuidid = this.uuidid ;  // setup() 안에서 생성된 uuidid   
            // this.inputUuidid = '45d5a8aa8849939d99e070d48a2706f7' ;  // setup() 안에서 생성된 uuidid   
            console.log(filename__+' created() uuid:', this.uuidid ) ;
            return ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },   
     
    computed: {
        backToLink(){
            return '/admin/blogs' ; 
        },		
        detailLink(){
            return '/admin/blog/'+this.inputId ; 
        },		
        outputMarkdownCompu(){
            // return marked(this.inputMarkdown, { sanitize: true });
            return marked(this.inputMarkdown);
            // return marked.parse(this.inputMarkdown);
        },			

    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },

        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
            return this.$store.getters['auth/isAdmin'] ;
        },
        onChange(){
            this.inputIsDisplay = !this.inputIsDisplay ;
        },
        onChangeIsText(){
            this.inputIsText = !this.inputIsText ;
        },

        // 프로필 정보 업데이트  
        async sendForm(){
            let funcname__ ="sendForm() ";
            
            let dataForm = { 
                'uuidid' : this.inputUuidid,
                'category' : 'blog',
                'title' : this.inputTitle,
                'subtitle' : this.inputSubtitle,
                'is_fronttext': this.inputIsText,
                'description': this.inputMarkdown,
                'description_category': this.inputDescriptionCategory,
                'is_display':this.inputIsDisplay,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/content/blog/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+' data:', data);

                        if(data.status_code === 201){
                                                                            
                            this.inputId = data.response.id ;

                            this.dialogTitle ='Success' ;
                            this.error = '입력에 성공했습니다.';   
                            
                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.$router.replace(this.detailLink);

                                }, 1000);
                            }                        
                            

                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        //Cancel 기존 자료들 삭제   
        async cancelForm(){
            let funcname__ ="cancelForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: `/api/content/file/delete/uuidid/all/${this.inputUuidid}`,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){
                        // console.log(filename__+''+funcname__+' data:', data);
                        
                        this.$router.replace('/admin/blogs');  

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }

                }                    

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="실패" ;
                this.error = error; 
            }
            this.isLoading = false ;

        },
        
    }
}
</script>

<style scoped>
.editor {
  height: 150vh;
  display: flex;
}

.input,
.output {
  overflow: auto;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.input {
  border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 20px;
}

code {
  color: #f66;
}



.white-space-newline {

/* white-space: normal;
white-space: nowrap;
white-space: pre;
white-space: pre-wrap;
white-space: pre-line; */
white-space: break-spaces;

/* Global values */
/* white-space: inherit;
white-space: initial;
white-space: unset; */

}
</style>
