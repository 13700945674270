<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>
	
    <!-- =======================
    Page Banner START -->
    <company-dashboard-top :title="'관리자 페이지 설정'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
                        
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <company-dashboard-left :menu="'skill'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Edit profile START -->
                    <div class="card border rounded-3 shadow">
                        <!-- Card header -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="card-header-title mb-0">관리자 페이지 설정 > 보유기술</h3>
                        </div>
                  
                        <!-- Card body START -->
                        <div class="card-body">


                            <div class="row g-4">

                                <!-- Right side START -->
                                <div class="col-xl-12">

                                    <!-- Tab Content START -->
                                    <!-- <div class="tab-content"> -->

                                        <!-- Personal Information content START -->
                                        <!-- <div class="tab-pane show active" id="tab-1"> -->
                                            <!-- <div class="card shadow"> -->

                                                <!-- Card header -->
                                                <!-- <div class="card-header border-bottom">
                                                    <h5 class="card-header-title">Information Settings</h5>
                                                </div> -->


                                                <!-- Card body START -->
                                                <!-- <div class="card-body"> -->

                                                    <company-dash-setting-skill-list-update-component
                                                        v-for="item in skillList"
                                                        :key="item.id"
                                                        :id="item.id"
                                                        :company_id ="item.company_id"
                                                        :name="item.name"
                                                        :percent="item.percent"
                                                        :seq ="item.seq"
                                                        :min="item.min"
                                                        :max="item.max"
                                                        @changeUpSeq='sendSkillChageUpSeqForm(item.id)'
                                                        @changeDownSeq='sendSkillChageDownSeqForm(item.id)'   
                                                        @sendSkillUpdateForm="sendSkillUpdateForm"                                                                                             
                                                        @removeSkillForm="removeSkillForm"
                                                    />                                                    

                                                    <form @submit.prevent="sendSkillForm" class="row g-4 align-items-center mt-1">
                                                        <div class="col-lg-8">
                                                            <label class="form-label">skill  </label>
                                                            <input type="text" class="form-control" placeholder="skill" v-model.trim="inputSkillName">
                                                            <div class="form-text">Enter Name. </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <label class="form-label">percent</label>
                                                            <input type="number" class="form-control" placeholder="0" v-model.trim="inputSkillPercent">
                                                            <div class="form-text">For skill percent </div>
                                                        </div>

                                                        <!-- Add button -->
                                                        <div class="d-sm-flex justify-content-end">
                                                            <button type="submit" class="btn btn-primary mb-0">Save add</button>
                                                        </div>
                                                    </form>
                                                <!-- </div> -->
                                                <!-- Card body END -->

                                            <!-- </div>			 -->
                                        <!-- </div> -->

                                    <!-- </div> -->
                                    <!-- Tab Content END -->
                                </div>
                                <!-- Right side END -->
                            </div> <!-- Row END -->		

                        </div>
                        <!-- Card body END -->
                    </div>
                    <!-- Edit profile END -->
                    
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pagess > companydash > CompanyDashSettingSkillUpdate.vue]" ;
import axios from 'axios';

import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';

import CompanyDashSettingSkillUpdateListComponent from '../../components/companydash/CompanyDashSettingSkillUpdateListComponent.vue';

export default {
    components: {
        "company-dashboard-top": CompanyDashboardTop,
        "company-dashboard-left": CompanyDashboardLeft,

        "company-dash-setting-skill-list-update-component" : CompanyDashSettingSkillUpdateListComponent,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,       
            
            inputId : null,
            inputUuidid :null, 

            skillList : [],
            inputSkillName : null,
            inputSkillPercent : null, 
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getInfo();
            // this.getSkillList();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },    
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        email : function(){
            return this.$store.getters['auth/email'] ;
        },
        name(){
            return this.inputNameUpdate ;
        },
        phone(){
            return this.inputPhoneUpdate ;
        },
        addr(){
            return this.inputAddrUpdate ; 
        },
        description(){
            return this.inputDescriptionUpdate ; 
        },
    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/signin');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            // console.log("[InstructorDashSettingUpdate.vue] isAuth():", this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            // console.log("[InstructorDashSettingUpdate.vue] isUser():", this.$store.getters['auth/userId']);
            return this.$store.getters['auth/userId'] ;
        },
        
        async getInfo(){
            let funcname__ ="getInfo() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 
            
            let serverData = await axios({
                method: 'get',
                url: '/api/company/info',
                headers: {
                    'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined" || serverData === undefined ||  serverData.status !== 200){
                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" && data.status_code == 200){
                    // console.log(filename__+''+funcname__+' response:', data.response);
                    
                    const setting = data.response ;  //기본정보 

                    // const careers = data.careers ? data.careers  : [] ;   // 학력, 회사경력 
                    // const skills = data.skills ? data.skills: [];     // skill
                    // const socials = data.socials ? data.socials : [] ;   // 소셜미디어 

                    this.inputId = setting.id ;
                    this.inputUuidid = setting.uuidid ;

                    this.inputName = setting.name ? setting.name : '' ;
                    this.inputNameUpdate = setting.name ? setting.name : '' ;
                    this.inputCompany = setting.company ? setting.company : '' ;
                    this.inputCompanyUpdate = setting.company ? setting.company : '' ;
                    this.inputEmail = setting.email ? setting.email : '' ;
                    this.inputEmailUpdate = setting.email ? setting.email : '' ;
                    this.inputContactemail = setting.contactemail ? setting.contactemail : '' ;
                    this.inputContactemailUpdate = setting.contactemail ? setting.contactemail : '' ;
                    this.inputMajor = setting.major ? setting.major : '' ;
                    this.inputMajorUpdate = setting.major ? setting.major : '' ;
                    this.inputPhone = setting.phone ? setting.phone : '' ;
                    this.inputPhoneUpdate = setting.phone ? setting.phone : '' ;
                    this.inputWeb = setting.web ? setting.web : '' ;
                    this.inputWebUpdate = setting.web ? setting.web : '' ;
                    this.inputAddr  = setting.addr ? setting.addr : '' ;
                    this.inputAddrUpdate  = setting.addr ? setting.addr : '' ;
                    this.inputDescription = setting.description ? setting.description : '' ;
                    this.inputDescriptionUpdate = setting.description ? setting.description : '' ;

                    this.inputDisplay = setting.is_display ;
                    
                    this.isApplying= true ;

                } else if( typeof data !== "undefined" && data.status_code == 401){
                    this.dialogTitle ="Error" ;                    
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [401] ";  // this.error = err.msg || 'Failed to authenticate';                

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  
                }
            }


            this.isLoading = false ;    
            if(this.isApplying){
                this.isApplying = false ;
                this.getSkillList();
            }

        },

        //skill
        async getSkillList(){
            let funcname__ ="getSkillList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/company/setting/all/skill/'+this.inputId,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;
            } else {
                const data = serverData.data ;


                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){
                    // console.log(filename__+''+funcname__+' response:', data.response);
                    
                    let tmpList = data.response ;
                    let list = []; 

                    //메인 카테고리 값들을 입력하기 
                    for(let item of tmpList ){
                        list.push({
                            id: item.id, 

                            company_id : item.company_id,
                            name:item.name,
                            percent: item.percent,
                            seq: item.seq,
                            min : item.min,
                            max : item.max,
                        });
                    }

                    // console.log(filename__+''+funcname__+" list:", list);
                    this.skillList = list;

                    // console.log(filename__+''+funcname__+" this.skillList:", this.skillList);

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    this.dialogTitle ="입력 실패" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;     
        },

        //skill 입력 
        async sendSkillForm(){
            let funcname__ ="sendSkillForm() ";

            let dataForm = { 
                'company_id' : this.inputId,
                'company_uuidid' : this.inputUuidid,
                'category' : 'skill',
                'name': this.inputSkillName, 
                'percent': this.inputSkillPercent,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/company/setting/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.inputSkillName = null ;
                        this.inputSkillPercent = 0 ;

                        this.dialogTitle ='Success' ;
                        this.error = '추가했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                            }, 500);
                        }                        

                        this.isApplying = true ;

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
            if(this.isApplying){
                this.isApplying = false ;
                this.getSkillList();
            }

        },

        //skill update 입력 
        async sendSkillUpdateForm(_id, _name, _percent ){
            let funcname__ ="sendSkillUpdateForm() ";

            let dataForm = { 
                'name': _name, 
                'percent': _percent,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/company/setting/update/'+_id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '수정했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                            }, 500);
                        }                        

                        this.isApplying = true ;

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
            if(this.isApplying){
                this.isApplying = false ;
                this.getSkillList();
            }

        },
        // skill 업데이트 
        async sendSkillChageUpSeqForm(fromId){
            let funcname__ ="sendSkillChageUpSeqForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/company/setting/upseq/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },
                }).catch( err => {
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.status_code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    this.dialogTitle ='Success' ;
                    this.error = '변경에 성공했습니다.';

                    if(this.dialogTitle ==='Success'){
                        setTimeout(() => {
                            this.error = null;
                            this.dialogTitle = null ;

                        }, 500);
                    }                        

                    // move                     
                    // this.$router.replace('/admin/open/infos'); 
                    this.isApplying = true ;

                } else {
                    console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                    this.dialogTitle ="순서 변경 실패" ;
                    this.error = serverData.data.msg;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="순서 변경 실패" ;
                this.error = err; // this.error = err.msg || 'Failed to authenticate';
            }

            this.isLoading = false ;
            if(this.isApplying){
                this.getSkillList();
                this.isApplying = false ;
            }


        },   

        // 순서 변경 : 아래로 내리기         
        async sendSkillChageDownSeqForm(fromId){
            let funcname__ ="sendSkillChageDownSeqForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/company/setting/downseq/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.status_code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    this.dialogTitle ='Success' ;
                    this.error = '변경에 성공했습니다.';

                    if(this.dialogTitle ==='Success'){
                        setTimeout(() => {
                            this.error = null;
                            this.dialogTitle = null ;

                        }, 500);
                    }                        

                    this.isApplying = true ;

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    this.dialogTitle ="입력 실패" ;
                    this.error = serverData.data.error_description;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="내용 변경 실패" ;
                this.error = err; 
            }

            this.isLoading = false ;
            if(this.isApplying) {
                this.getSkillList();
                this.isApplying = false ;
            }

        },  
        // skill 업데이트 
        async removeSkillForm(fromId){
            let funcname__ ="removeSkillForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/company/setting/delete/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },
                }).catch( err => {
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.status_code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    this.dialogTitle ='Success' ;
                    this.error = '삭제에 성공했습니다.';

                    if(this.dialogTitle ==='Success'){
                        setTimeout(() => {
                            this.error = null;
                            this.dialogTitle = null ;

                        }, 500);
                    }                        

                    // move                     
                    // this.$router.replace('/admin/open/infos'); 
                    this.isApplying = true ;

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    this.dialogTitle ="실패" ;
                    this.error = serverData.data.error_description;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="순서 변경 실패" ;
                this.error = err; // this.error = err.msg || 'Failed to authenticate';
            }

            this.isLoading = false ;
            if(this.isApplying){
                this.getSkillList();
                this.isApplying = false ;
            }

        },   

    }
}
</script>

<style scoped>

</style>