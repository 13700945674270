<template>
<div>
    <!-- Education START -->
    <!-- Title -->
    <h4 v-if="careers.length > 0 " class="mb-3">학력/경력</h4>

    <!-- Education item -->
    <div class="d-flex align-items-center mb-4" 
        v-for="item in careers"
            :key="item.id">
            <span v-if="item.career" class="icon-md text-dark mb-0 bg-light rounded-3"><i class="fas fa-address-card"></i></span>
            <span v-else class="icon-md text-dark mb-0 bg-light rounded-3"><i class="fas fa-graduation-cap"></i></span>
            <div class="ms-3">
                <h6 class="mb-0">{{item.name}}</h6>
                <p class="mb-0 small">{{item.major}}</p>
            </div>
    </div>
    <!-- Education END -->

</div>    
</template>

<script>

export default {
    components: {
    },
    props: [
        'careers'
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
        }
    },
    created(){
        
    },    
    computed: {
    },
    methods: {
        handleError(){
            this.error = null;
        },

    },
    
}
</script>
<style scoped>

</style> 