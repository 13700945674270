<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>


    <!-- company dash notification 모달  -->
    <teleport to="body">
        <div class="modal fade" id="modalCompanyNotification" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header btn-primary-soft">
                    <h1 class="modal-title fs-5" id="modalCompanyNotification">New Notification</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="card dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md p-0 shadow-lg border-0">
                        <div class="card-header border-bottom py-4 d-flex justify-content-between align-items-center">
                            <h6 class="m-0">Notifications 
                                <span v-if="inputCounter > 0" class="badge bg-danger bg-opacity-10 text-danger ms-2">{{inputCounter}} new</span>
                            </h6>
                        <!-- <a class="small" href="#" @click.prevent="clearAllForm">Clear all</a> -->
                        </div>
                        <div class="card-body p-0">
                        <ul class="list-group list-unstyled list-group-flush">
                            <!-- Notif item -->

                            <top-notification-list-component v-for="item in itemList"
                                :key = "item.id"
                                :id ="item.id"
                                :num ="item.num"
                                :receive_id = "item.receive_id" 
                                :receive_name = "item.receive_name" 
                                :receive_email = "item.receive_email" 
                                :receive_photo = "item.receive_photo" 

                                :create_id = "item.create_id" 
                                :create_name = "item.create_name" 
                                :create_email = "item.create_email" 
                                :create_photo = "item.create_photo" 

                                :content="item.content"
                                :is_check ="item.is_check"
                                :date ="item.date"

                                @updateCheck ="updateCheckForm(item.id)"
                                />                        
                        </ul>
                        </div>
                        <!-- Button -->
                        <!-- <div class="card-footer bg-transparent border-0 py-3 text-center position-relative">
                            <div data-bs-dismiss="modal">
                                <router-link :to="notificationAllLink" class="stretched-link" >See all</router-link>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="modal-footer justify-content-between align-items-center">
                    <button type="button" @click="moveToNotification" class="btn btn-sm btn-primary-soft" data-bs-dismiss="modal">See all</button>
                    <button type="button" class="btn btn-sm btn-success-soft" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>           

    <ul class="list-unstyled">
    <li class="nav-item dropdown">
        <!-- <a class="btn btn-light btn-round mb-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close=""> -->
        <a class="btn btn-light btn-round mb-0" href="#" role="button" data-bs-toggle="modal" data-bs-target="#modalCompanyNotification" aria-expanded="false" data-bs-auto-close="">
            <i class="bi bi-bell fa-fw"></i>
        </a>
        

        <!-- Notification dote -->
        <span v-if="inputCounter > 0" class="notif-badge animation-blink"></span>
    </li>
    </ul>
</div>    

</template>

<script>
// compu__ return__
var filename__ = "[components > notification > TopNotificationPanelComponent.vue]" ;
import axios from 'axios';

import TopNotificationListComponent from './TopNotificationListComponent.vue' ;

export default {
    components: {
        'top-notification-list-component' : TopNotificationListComponent
    },
    props: [
        
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputCounter : null, 
            itemList: [], 

        }
    },
    created(){
        this.getList()
    },    
    computed: {
        // detailLink(){
        //     return '/admin/contactus/'+this.id+'/'+this.parent_id+'?page='+this.page+"&search="+this.search ; 
        // },
        
        notificationAllLink(){
            return '/companydash/notifications'            
        },
        contentSplice() {
            return (_content) => {
                return _content.slice(0,60);
            };
        },   

    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        moveToNotification(){
            this.$router.replace('/companydash/notifications');            
        },
        async getList(){
            let funcname__ ="getList() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/notification/new/company',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log("[TopNotificationContainerComponent.vue] getList() serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log('[TopNotificationContainerComponent.vue] profile:', data.profile);
                
                this.inputCounter = data.response.counter ;
                let tmpList = data.response.data ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        receive_id: item.receive_id,
                        receive_name: item.receive_name,
                        receive_email: item.receive_email,
                        receive_photo : item.receive_photo,
                        
                        create_id: item.create_id,
                        create_name: item.create_name,
                        create_email: item.create_email,
                        create_photo : item.create_photo,

                        content: item.content, 
                        is_check:item.is_check,
                        date: item.date
                    });
                }

                // console.log("[TopNotificationContainerComponent.vue] tmpList:", tmpList);
                this.itemList = list;

                // console.logfilename__+''+funcname__+(" itemList:", this.itemList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="입력 실패" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },

        // 전송하기  
        async updateCheckForm(_id){
            let funcname__ ="updateCheckForm() ";
            
            // this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/notification/check/'+_id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     

                }).catch( err => {


                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                    this.isLoading = false ;

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        // this.dialogTitle ='Success' ;
                        // this.error = '변경에 성공했습니다.';     
                        this.inputCounter-- ;

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // clear all 전송하기  
        async clearAllForm(){
            let funcname__ ="clearAllForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/notification/clear/all',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log("[TopNotificationContainerComponent.vue] clearAllForm() serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        // this.dialogTitle ='Success' ;
                        // this.error = '변경에 성공했습니다.';     
                        this.inputCounter =0  ;
                        this.itemList = [] ;

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                } else {
                    // this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            // this.isLoading = false ;

        },



    
    },
    
}
</script>
<style scoped>

</style> 