<template>
<div class="card shadow h-100">

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Card header -->
    <div class="card-header border-bottom d-flex justify-content-between align-items-center p-4">
        <h5 class="card-header-title">Error Logs</h5>
        <router-link to="/admin/errorlogs" class="btn btn-link p-0 mb-0">View all</router-link>
    </div>

    <!-- Card body START -->
    <div class="card-body p-4">
        <!-- Chart -->
        <!-- <div class="col-sm-6 mx-auto">
            <div id="ChartTrafficViews"></div>
        </div> -->

        <!-- Content -->
        <ul class="list-group list-group-borderless mt-3">
            <li class="list-group-item" v-for="(item, idx ) in itemList" :key="item.id" >
                <i class="fas fa-circle me-2" :class="changeTextColor(idx)"></i><b>{{item.which}}</b> {{item.content}}
            </li>
            <!-- <li class="list-group-item"><i class="text-success fas fa-circle me-2"></i>The Complete Digital Marketing Course - 12 Courses in 1</li>
            <li class="list-group-item"><i class="text-warning fas fa-circle me-2"></i>Google Ads Training: Become a PPC Expert</li>
            <li class="list-group-item"><i class="text-danger fas fa-circle me-2"></i>Microsoft Excel - Excel from Beginner to Advanced</li> -->
        </ul>
    </div>
</div>

</template>
<script>
// return__ param__
var filename__= "[components > admin > dasbhoard > AdminDashboardErrorLogListComponent.vue] "

import axios from 'axios';

export default {
    components: {},
    props: [],
    data() {
        return {
           
            isLoading: false,
            error: null,     
            dialogTitle: null,       

            itemList : [],
            inputMaxCount: 0,

            page:1,
            // current_path:"/admin/companies",  //current_path
			pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 1,      // 하단 pagination 좌우 블록수     

        }
    },
    created(){
        this.getList()
    },
    computed: {
        changeTextColor() {
            return (_idx) => {
                if( _idx%4 === 0) {
                    return 'text-primary'
                
                } else if( _idx%4 === 1) {
                    return 'text-success' ;

                } else if( _idx%4 === 2) {
                    return 'text-warning' ;

                } else if( _idx%4 === 3) {
                    return 'text-danger' ;
                } else {
                    return 'text-secondary' ;
                }
                
            };
        },   

    },

    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }
        },
        async getList(){
            let funcname__ ="getList() ";

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }

            
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/errorlog/dashboard/admin',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                }
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }


            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);  // ~~log

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);  // ~~log

            if( typeof data !== "undefined" &&  data.code == 200 ){
                // console.log('[AdminContactusList.vue] profile:', data.profile);  // ~~log
                
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        num: counter--, 
                        id: item.id, 
                        which: item.which, 
                        content: item.content, 
                        status:item.status,
                        create_name:item.create_name,
                        create_email:item.create_email,
                        create_photo:item.create_photo,
                        date: item.date
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);  // ~~log
                this.itemList = list;
                this.inputMaxCount = list.length;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);  // ~~log
                // console.log(filename__+''+funcname__+" inputMaxCount:", this.inputMaxCount);  // ~~log
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);  // ~~log

            } else {
                console.log(filename__+''+funcname__+' err:');  // ~~log

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
                
                await axios({
                    method: 'post',
                    url: '/api/errorlog/create',
                    params: { 
                        cateogry: 'errorlog' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error',       
                        content : serverData.data.msg,
                    }
                }).catch( err => {
                    console.log(filename__+''+funcname__+' errorlog :',err);  // ~~log
                });                
            }

            this.isLoading = false ;            
        },


    },
    
}
</script>

<style scoped>
</style>