<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <h5 class="mb-4">Comments</h5>

    <!-- Comment box -->
    <div class="d-flex mb-4">
        <!-- Avatar -->
        <div class="avatar avatar-sm flex-shrink-0 me-2">
            <img v-if="isAuth && photo" class="avatar-img rounded-circle" :src="photo" alt=""> 
            <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user.png" alt=""> 
        </div>

        <form @submit.prevent="sendForm" class="w-100 d-flex">
            <textarea v-if="isAuth" class="one form-control pe-4 bg-light" id="autoheighttextarea" rows="1" placeholder="Add a comment..." v-model.trim="inputDescription"></textarea>
            <textarea v-else disabled class="one form-control pe-4 bg-light" id="autoheighttextarea" rows="1" placeholder="Add a comment..." v-model.trim="inputDescription"></textarea>
            <button v-if="isAuth" type="submit" class="btn btn-primary ms-2 mb-0" >Post</button>
            <button v-else type="button" disabled class="btn btn-primary ms-2 mb-0" >로그인 후</button>
        </form>
    </div>

    <!-- Comment item START -->    
    <course-detail-comment-list-component 
        v-for="item in itemList"
            :key="item.id"
            :id="item.id"
            :group_id="item.group_id"
            :is_display="item.is_display"
            :description="item.description"
            :depth="item.depth"
            :seq="item.seq"
            :date="item.date"
            :create_id="item.create_id"
            :create_name="item.create_name"
            :create_photo="item.create_photo"
            @replySendForm="replyModalSendForm"
            @updateForm="updateModalSendForm"
            @removeForm="removeForm"
    /> 
      
<!-- 
    <div class="border p-2 p-sm-4 rounded-3 mb-3">
        <ul class="list-unstyled mb-0">
            <li class="comment-item">
                <div class="d-flex mb-3">
                    <div class="avatar avatar-sm flex-shrink-0">
                        <a href="#"><img class="avatar-img rounded-circle" src="/assets/images/avatar/05.jpg" alt=""></a>
                    </div>
                    <div class="ms-2">
                        <div class="bg-light p-3 rounded">
                            <div class="d-flex justify-content-center">
                                <div class="me-2">
                                    <h6 class="mb-1 lead fw-bold"> <a href="#!"> Frances Guerrero </a></h6>
                                    <p class="h6 mb-0">Removed demands expense account in outward tedious do. Particular way thoroughly unaffected projection?</p>
                                </div>
                                <small>5hr</small>
                            </div>
                        </div>
                        <ul class="nav nav-divider py-2 small">
                            <li class="nav-item"> <a class="text-primary-hover" href="#"> Like (3)</a> </li>
                            <li class="nav-item"> <a class="text-primary-hover" href="#"> Reply</a> </li>
                            <li class="nav-item"> <a class="text-primary-hover" href="#"> View 5 replies</a> </li>
                        </ul>
                    </div>
                </div>
                
                <ul class="list-unstyled ms-4">
                    <li class="comment-item">
                        <div class="d-flex">
                            <div class="avatar avatar-xs flex-shrink-0">
                                <a href="#"><img class="avatar-img rounded-circle" src="/assets/images/avatar/06.jpg" alt=""></a>
                            </div>
                            <div class="ms-2">
                                <div class="bg-light p-3 rounded">
                                    <div class="d-flex justify-content-center">
                                        <div class="me-2">
                                            <h6 class="mb-1  lead fw-bold"> <a href="#"> Lori Stevens </a> </h6>
                                            <p class=" mb-0">See resolved goodness felicity shy civility domestic had but Drawings offended yet answered Jennings perceive. Domestic had but Drawings offended yet answered Jennings perceive.</p>
                                        </div>
                                        <small>2hr</small>
                                    </div>
                                </div>
                                <ul class="nav nav-divider py-2 small">
                                    <li class="nav-item"><a class="text-primary-hover" href="#!"> Like (5)</a></li>
                                    <li class="nav-item"><a class="text-primary-hover" href="#!"> Reply</a>	</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </div> -->

</div>


</template>

<script>
var filename__ ="[components > comment > CourseDetailCommentPanelComponent.vue] " ;

import axios from 'axios';

import CourseDetailCommentListComponent from './CourseDetailCommentListComponent.vue';

export default {
    components: {
        "course-detail-comment-list-component": CourseDetailCommentListComponent,
    },
    props: [ 'content_id','company_id' ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList : [],
            inputDescription: null, 
        }
    },
    created(){
        this.getList();
    },    
    
    computed: {
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        name(){
            return this.$store.getters['auth/name'];
        },
        email(){
            return this.$store.getters['auth/email'];
        },
        photo(){
            return this.$store.getters['auth/photo'];
        },

    },
    methods: {
        handleError(){
            this.error = null;
        },
        // 답변이나 수정, 삭제 후 refresh list
        sendRefreshForm(){
            this.getList();
        },
        async getList(){
            let funcname__ ="getList() "
            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: `/api/comment/course/all/user/course/${this.content_id}`, // category:course

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }


            });

            // console.log(filename__+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+funcname__+'  response:', data.response);
                    
                    let tmpList = data.response.data ;
                    let list = []; 

                    //메인 카테고리 값들을 입력하기 
                    for(let item of tmpList ){
                        list.push({
                            id: item.id, 
                            group_id: item.group_id, 

                            is_display: item.is_display, 
                            description: item.description,
                            depth:item.depth,
                            seq : item.seq,

                            create_id: item.create_id,
                            create_photo: item.create_photo,
                            create_name: item.create_name,

                            date: item.date, 
                            
                        });
                    }

                    // console.log(filename__+funcname__+" list:", list);
                    this.itemList = list;

                    // console.log(filename__+funcname__+" this.itemList:", this.itemList);

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;     
        },

        // comment 입력   
        async sendForm(){
            let funcname__ = "sendForm() "

            let dataForm = { 
                'category': 'course',
                'company_id': this.company_id,
                'content_id': this.content_id,
                'description': this.inputDescription,
            } ;  

            // console.log(filename__+funcname__+' dataForm:', dataForm);

            if( this.inputDescription === '' || this.inputDescription === undefined || this.inputDescription === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/comment/course/create/user',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;

                } else {
                    // console.log(filename__+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+funcname__+'  data:', data);

                        if(data.status_code === 201){
                                                        
                            this.inputDescription = null ;

                            this.dialogTitle ='Success' ;
                            this.error = '입력에 성공했습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.getList();  

                                }, 100);
                            }                        

                        } else {
                            console.log(filename__+funcname__+' return err:', serverData.data.msg);
                            this.dialogTitle ="입력 실패" ;
                            this.error = serverData.data.msg;

                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // 답변 입력   
        async replyModalSendForm(_id, _group_id, _description){
            let funcname__ ="replyModalSendForm() ";

            let dataForm = { 
                'category': 'course',
                'company_id': this.company_id,
                'content_id': this.content_id,
                'parent_id' : _id,
                'group_id' : _group_id,
                'description' : _description, 
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( _description === '' || _description === undefined || _description === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                        method: 'post',
                        url: '/api/comment/course/reply/create/user',
                        data: dataForm,
                        headers: {
                            Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+' data:', data);

                        if(data.status_code === 201){
                                                        
                            this.inputDescription = null ;

                            this.dialogTitle ='Success' ;
                            this.error = '입력에 성공했습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.getList();

                                }, 700);
                            }                        

                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        // 수정 입력   
        async updateModalSendForm(_id, _description){
            let funcname__ ="updateModalSendForm() ";

            let dataForm = { 
                'description' : _description, 
            } ;  

            // console.log(filename__+''+funcname__+' dataForm :', dataForm);

            if( _description === '' || _description === undefined || _description === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                        method: 'put',
                        url: '/api/comment/course/update/user/'+_id,
                        data: dataForm,
                        headers: {
                            Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+' data:', data);

                        if(data.status_code === 201){
                                                        
                            this.inputDescription = null ;

                            this.dialogTitle ='Success' ;
                            this.error = '수정에 성공했습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.getList();

                                }, 700);
                            }                        

                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        // 팝업 창을 통해서 삭제   
        async removeForm(_id){
            let funcname__ ="removeForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/comment/course/delete/user/'+_id,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        console.log(filename__+''+funcname__+' data:', serverData.data);
                                                
                        this.dialogTitle ='Success' ;
                        this.error = '삭제 성공했습니다.';  
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.getList();

                            }, 700);
                        }                        

                    } else if(serverData.data.status_code == 500){
                        // console.log(filename__+''+funcname__+' data:', serverData.data);
                                                
                        this.dialogTitle ='Fail' ;
                        this.error = '하위 답변이 존재해서 삭제할 수 없습니다.';    
                                                                                                
                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },     

    },
    
}
</script>