<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <teleport to="body">
        <div class="modal fade" id="modalMarkdown" tabindex="-1" aria-labelledby="addTopicLabel" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content" style="width:900px">
                    <div class="modal-header bg-dark">
                        <h5 class="modal-title text-white" id="modalMarkdown">Markdown Sample</h5>
                        <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                    </div>                    
                    <div class="modal-body">
                        <div class="row text-start g-3">
                            <div class="col-12 mt-3">
                                <div class="editor_sample">
                                    <textarea class="input" v-model.trim="inputMarkdownInit"></textarea>
                                    <div class="output" v-html="outputMarkdownInitCompu"></div>
                                </div>                
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger-soft my-0" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>

    <!-- Page main content START -->
    <div class="page-content-wrapper-match border">

        <!-- Title -->
        <form @submit.prevent="sendForm" >

            <div class="col-12">
                <div class="card bg-transparent border rounded-3 h-100">

                    <!-- Catd header -->
                    <div class="card-header bg-light border-bottom">
                        <h5 class="card-header-title">작업 생성 </h5>
                    </div>

                    <!-- Card body START -->
                    <div class="card-body">

                        <div class="row g-4">
                            <!-- Course info and avatar -->
                            <div class="col-12 mb-2">
                                <label class="form-label">카테고리(선택해주세요)</label>
                                <select 
                                        class="form-select-gray js-choice border-0 z-index-9 form-control" 
                                        aria-label=".form-select-sm" 
                                        data-max-item-count="4" 
                                        data-search-enabled="true"
                                        v-model="inputCategory"
                                    >
                                    <option disabled value="">Please select category</option>                                                            
                                    <option :value="1">버그</option>
                                    <option :value="2">보완</option>
                                    <option :value="3">새로운 작업</option>                          
                                </select>
                            </div>
                        </div>

                        <div class="row g-4">
                            <!-- Course info and avatar -->
                            <div class="col-12 mb-2">
                                <label class="form-label">Title</label>
                                <div class="input-group">
                                    <input type="text" v-model.trim="inputTitle" class="form-control" placeholder="title">
                                </div>
                            </div>
                        </div>

                        <div class="row g-4 mt-2">                                    
                            <div class="col-md-12">
                                <label class="form-label">설명</label>
                                <textarea class="form-control" rows="7" placeholder="Enter keywords" v-model.trim="inputDescription"></textarea>
                            </div>
                        </div>

                    </div>
                    <!-- Card body END -->
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 d-sm-flex justify-content-between align-items-center">
                    <router-link :to="backToLink" class="btn btn-secondary mb-0" >Cancel</router-link>
                    <button type="submit" class="btn btn-primary mb-0" >Create</button>
                </div>
            </div>
        </form>


    </div>

</div>
</template>

<script>
var filename__="[pages > admin > plan > AdminPlanCreate.vue] "

import axios from 'axios';

export default {
    components: {
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  
            
            inputUuidid: null, 

            inputCategory : null, 
            inputTitle : null, 

            inputDiscription: null, 
            inputDescriptionCategory :'text', 
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
        backToLink(){            
            return '/admin/plans'; 
        },
    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
            // this.createUuidid()
            return ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
            // console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        createUuidid(){
            // uuid 생성 
            const generate_uuid = () => {
                const tokens = v4().split('-')
                return tokens[2] + tokens[1] + tokens[0] + tokens[3] + tokens[4];
            }
            this.inputUuidid =  generate_uuid() ;
            console.log(filename__+' createUuidid() inputUuidid:', this.inputUuidid);
        },
        // 전송하기  
        async sendForm(){
            let funcname__ ="sendForm()" ;

            let dataForm = { 
                'category':this.inputCategory,
                'title':this.inputTitle,
                'description' : this.inputDescription,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputCategory === '' || this.inputCategory === undefined || this.inputCategory === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="카테고리를 입력해주세요.";
                return ;
            }
            if( this.inputTitle === '' || this.inputTitle === undefined || this.inputTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="타이틀을 입력해주세요.";
                return ;
            }
            if( this.inputDescription === '' || this.inputDescription === undefined || this.inputDescription === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/plan/create',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '생성에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 
                                this.$router.replace(this.backToLink); 
                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' return err:', serverData.data.error_description);
                        this.dialogTitle ="생성 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        // 임의의 이미지나 첨부파일 존재하면 삭제
        async removeUuididForm(){
            let funcname__ ="removeUuididForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/fileupload/delete/uuidid/'+this.inputUuidid+'/'+'plan',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }  

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        // this.dialogTitle ='Success' ;
                        // this.error = '삭제에 성공했습니다.';     

                        this.isApplying = true ;

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 
                                
                                // this.$emit('emitRefreshList', this.category);
                            }, 500);
                        }

                    } else {
                        // console.log(filename__+''+funcname__+' return err:', serverData.data.error_description);

                        this.dialogTitle ="변경 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },


        
    }
}
</script>

<style scoped>
.editor {
  height: 60vh;
  display: flex;
}
.editor_sample {
  height: 60vh;
  display: flex;
}

.input,
.output {
  overflow: auto;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.input {
  border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 20px;
}

code {
  color: #f66;
}
</style>