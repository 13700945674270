<template>
<div class="page-content-wrapper-match border">

    <!-- Title -->
    <div class="row g-3 align-items-center justify-content-between">
        <div class="col-md-11">
            <h1 class="h3 mb-2 mb-sm-0">답변내용</h1>
        </div>
        <div class="col-md-1 text-end">
            <router-link :to="updateLink" class="btn btn-sm btn-primary mb-0">답변 수정</router-link>
        </div>
    </div>

    <div class="row g-4 mt-1">
        <!-- Course information START -->
        <div class="col-12">
            <div class="card bg-transparent border rounded-3 h-100">

                <!-- Catd header -->
                <div class="card-header bg-light border-bottom">
                    
                    <div class="avatar avatar-md">
                        <img v-if="done_photo" class="avatar-img rounded-circle" :src="done_photo" alt="avatar">
                        <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user.png" alt="avatar">
                        <!-- <span>{{done_name}}</span> -->
                    </div>

                </div>

                <!-- Card body START -->
                <div class="card-body">

                    <!-- Course image and info START -->
                    <div class="row g-4">
                        <!-- Course info and avatar -->
                        <div class="col-md-12">
                            <!-- Info -->
                            <p class="mb-3 white-space-newline">{{content}}</p>
                        </div>
                        <div class="col-md-12">
                            <!-- Info -->
                            <hr/>
                            <h6 class="mb-3">{{date}}</h6>
                        </div>

                    </div>
                    <!-- Course image and info END -->
                </div>
                <!-- Card body END -->
            </div>
        </div>
        <!-- Course information END -->


    </div> <!-- Row END -->
</div>
</template>

<script>

export default {
    components: {},
    props: [
        'id', 'parent_id','done_name','done_photo','content','date'
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

        }
    },
    created(){
        // this.getContactus();
    },    
    computed: {
        updateLink(){
            return '/admin/contactus/reply/update/'+this.id+'/'+this.parent_id+'?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },

    },
    methods: {
        handleError(){
            this.error = null;
        },

    },
    
}
</script>
<style scoped>
.white-space-newline {

    /* white-space: normal;
    white-space: nowrap;
    white-space: pre;
    white-space: pre-wrap;
    white-space: pre-line; */
    white-space: break-spaces;

    /* Global values */
    /* white-space: inherit;
    white-space: initial;
    white-space: unset; */

}

</style> 