<template>
<div>

    <div data-sticky data-margin-top="80" data-sticky-for="768">
        <div class="row g-4">
            <div class="col-md-6 col-xl-12">
                <!-- Course info START -->
                <div class="card card-body border p-4">
                    <!-- Price and share button -->
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- Price -->
                        <!-- <h3 class="fw-bold mb-0 me-2">$295.55</h3> -->
                        <!-- Share button with dropdown -->
                        <!-- <div class="dropdown">
                            <a href="#" class="btn btn-sm btn-light rounded mb-0 small" role="button" id="dropdownShare" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fas fa-fw fa-share-alt"></i>
                            </a>
                            <ul class="dropdown-menu dropdown-w-sm dropdown-menu-end min-w-auto shadow rounded" aria-labelledby="dropdownShare">
                                <li><a  class="dropdown-item" href="#"><i class="fab fa-twitter-square me-2"></i>Twitter</a></li>
                                <li><a class="dropdown-item" href="#"><i class="fab fa-facebook-square me-2"></i>Facebook</a></li>
                                <li><a class="dropdown-item" href="#"><i class="fab fa-linkedin me-2"></i>LinkedIn</a></li>
                                <li><a class="dropdown-item" href="#"><i class="fas fa-copy me-2"></i>Copy link</a></li>
                            </ul>
                        </div> -->
                    </div>
                        
                    <!-- Buttons -->
                    <div class="mt-0 d-grid">
                        <!-- <a href="#" class="btn btn-outline-primary">Add to cart</a> -->
                        <router-link :to="backToLink" class="btn btn-secondary">목록보기</router-link>

                        <!-- 로그인 안됐으면 수강신청 클릭 안됨  -->
                        <router-link  :to='updateLink'  class="btn btn-primary">편집하기</router-link>
                    </div>
                    <!-- Divider -->
                    <hr>

                    <!-- Title -->
                    <h5 class="mb-3">This course includes</h5>
                    <ul class="list-group list-group-borderless border-0">
                        <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-book-open text-primary"></i>Lectures</span>
                            <span>{{course.lecturecount}} </span>
                        </li>
                        <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-clock text-primary"></i>Duration</span>
                            <span>{{timeFormat}}</span>
                        </li>
                        <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-signal text-primary"></i>Skills</span>
                            <span v-if="course.level ===0"> All levels</span>
                            <span v-if="course.level ===1"> Beginner</span>
                            <span v-if="course.level ===2"> Intermediate</span>
                            <span v-if="course.level ===3"> Advanced</span>
                        </li>
                        <!-- <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-globe text-primary"></i>Language</span>
                            <span>English</span>
                        </li> -->
                        <!-- <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-user-clock text-primary"></i>Deadline</span>
                            <span>Nov 30 2021</span>
                        </li> -->
                        <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-medal text-primary"></i>Certificate</span>
                            <span v-if="course.is_certificate">Yes</span>
                            <span v-else>No</span>
                        </li>
                    </ul>
                    <!-- Divider -->
                    <hr>

                    <!-- Instructor info -->
                    <div class="d-sm-flex align-items-center">
                        <!-- Avatar image -->
                        <div class="avatar avatar-lg">
                            <img v-if="course.create_photo" class="avatar-img rounded-circle" :src="course.create_photo" alt="avatar">
                            <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/05.jpg" alt="avatar">
                        </div>
                        <div class="ms-sm-3 mt-2 mt-sm-0">
                            <h5 class="mb-0"><a href="#">{{course.create_name}}</a></h5>
                            <!-- <p class="mb-0 small">Founder Eduport company</p> -->
                        </div>
                    </div>

                </div>
                <!-- Course info END -->
            </div>

        </div><!-- Row End -->
    </div>	

</div>
</template>

<script>
// var filename__ ="[components > course > CourseDetailRightTopComponent.vue] " ;

export default {
    components: {},
    props: [
        'id','course', 'path','page'
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  

            inputCompany_id : this.course.company_id,
        }
    },
    created(){
    },
    computed: {
        backToLink(){
            return '/editcourses?page='+this.$route.query.page
            
        },
        updateLink(){
            return '/editcourse/update/'+this.id ;
            
        },
        timeFormat(){
            let hour = parseInt(this.course.coursetime/60) ;
            let minute = this.course.coursetime - hour * 60 ;
            if(hour == 0){
                return this.course.coursetime +'분'                
            } else if(minute == 0){
                return hour+'시간'
            } else {
                return hour + '시간 '+minute + '분' ;                
            }
        }

    },
    methods: {
        handleError(){
            this.error = null;
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },

    },
    
}
</script>