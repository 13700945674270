import { createApp } from 'vue';

import router from './router.js';
import store from './store/index.js';
import App from './App.vue';

import BaseCard from './components/ui/BaseCard.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseBadge from './components/ui/BaseBadge.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
import AdvancedButton from './components/ui/AdvancedButton.vue';
import AdvancedDialog from './components/ui/AdvancedDialog.vue';

const app = createApp(App);

app.use(router); // 참고자료(라우터) : https://router.vuejs.org/installation.html
app.use(store);

app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-badge', BaseBadge);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);

app.component('advanced-button', AdvancedButton);
app.component('advanced-dialog', AdvancedDialog);

app.mount('#app');
