<template>
<div>
    <!-- Skills START -->
    <h4 v-if="skills.length > 0 " class="mb-3">보유 기술</h4>

    <!-- Progress item -->
    <!-- <div class="overflow-hidden mb-4">
        <h6 class="uppercase">웹 디자인</h6>
        <div class="progress progress-sm bg-success bg-opacity-10">
            <div class="progress-bar bg-success aos" role="progressbar" data-aos="slide-right" data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-out" style="width: 80%;" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                <span class="progress-percent-simple h6 mb-0">80%</span>
            </div>
        </div>
    </div> -->

    <company-detail-skill-list-component
        v-for="item in skills"
          :key="item.id"
          :count="item.count"
          :name="item.name"
          :percent="item.percent"
    />

</div>    
</template>

<script>

import CompanyDetailSkillListComponent from './CompanyDetailSkillListComponent.vue';

export default {
    components: {
        "company-detail-skill-list-component": CompanyDetailSkillListComponent,
    },
    props: [
        'careers','skills'
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
        }
    },
    created(){
        
    },    
    computed: {
    },
    methods: {
        handleError(){
            this.error = null;
        },

    },
    
}
</script>
<style scoped>

</style> 