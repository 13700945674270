<template>
    <div>
        <!-- Card body -->
        <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
            <p>{{ error }}</p>
        </base-dialog>

        <base-dialog :show="isLoading" title="Loading..." fixed>
            <p>Loading...</p>
            <base-spinner></base-spinner>
        </base-dialog>

        <main>
	
    <!-- =======================
    Page Banner START -->
    <student-dashboard-top :title="'Delete Account'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <student-dashboard-left :menu="'delete'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Title and select START -->
                    <div class="card border bg-transparent rounded-3 mb-0">
                        <!-- Card header -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="card-header-title mb-0">회원탈퇴</h3>
                        </div>

                        <div class="card-body">
                            <form @submit.prevent="sendForm">
                                <h6>Before you go...</h6>
                                <ul>
                                    <!-- <li>Take a backup of your data <a href="#">Here</a> </li> -->
                                    <li>회원탈퇴시 1개월 동안 개인정보를 보관하며, 그 이후 모든 개인정보는 삭제됩니다.</li>
                                </ul>
                                <div class="mb-3">
                                    <label class="form-label">Current password</label>
                                    <div class="input-group">
                                        <input v-if="isShowPassword"  class="form-control" type="text" placeholder="Enter current password" v-model.trim="inputPassword">
                                        <input v-else class="form-control" type="password" placeholder="Enter current password" v-model.trim="inputPassword">
                                        <span class="input-group-text p-0 bg-transparent">
                                            <i class="far cursor-pointer p-2 w-40px"
                                                :class="{ 'fa-eye-slash': !isShowPassword, 'fa-eye': isShowPassword }"
                                                @click="toggleShow"                                                    
                                            ></i>
                                        </span>
										<div class="rounded mt-1" id="psw-strength"></div>
                                    </div>
                                </div>

                                <div class="form-check form-check-md my-4">
                                    <input class="form-check-input" type="checkbox" id="deleteaccountCheck" v-model="inputCheck" @click="onChangeCheck">
                                    <label class="form-check-label" for="deleteaccountCheck">Yes, 회원탈퇴합니다.</label>
                                </div>
                                <!-- <a href="#" class="btn btn-success-soft mb-2 mb-sm-0">Keep my account</a> -->
                                <button type="submit" class="btn btn-danger mb-0">회원탈퇴</button>
                            </form>
                        </div>
                    </div>
                    <!-- Title and select END -->
                </div>
                <!-- Main content END -->
            </div>
        </div>	
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                    
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pages > studentdash > StudentDashDeleteUpdate.vue]" ;
import axios from 'axios';

import StudentDashboardTop from '../../components/studentdash/StudentDashboardTop.vue';
import StudentDashboardLeft from '../../components/studentdash/StudentDashboardLeft.vue';

export default {
    components: {
        "student-dashboard-top": StudentDashboardTop,
        "student-dashboard-left": StudentDashboardLeft,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            id : null,
            isShowPassword : false, 
            inputPassword : '',
            inputCheck : false, 
        }
    },
    computed: {
        email : function(){
            return this.$store.getters['auth/email'] ;
        },
        photo(){
            return this.inputPhotoUpdate ;
        },
        realname(){
            return this.inputRealnameUpdate ;
        },
        displayname(){
            return this.inputDisplaynameUpdate ;
        },
        phone(){
            return this.inputPhoneUpdate ;
        },

    },    
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.id = this.isUser() ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');

            } else if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');

            } else if(this.dialogTitle === 'SuccessDel'  ){
                //모든 토큰 없앰 
                this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                this.$router.replace('/');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        toggleShow() {
            this.isShowPassword = !this.isShowPassword;
        },        

        onChangeCheck(){
            this.inputCheck = !this.inputCheck ;
        },

        // 프로필 정보 업데이트  
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'password': this.inputPassword, 
            } ;  

            if( this.inputPassword === "undefined" || this.inputPassword === undefined || this.inputPassword === "null" || this.inputPassword === null )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="비밀번호는 6자리 이상이어야 합니다.";
                return ;
            }

            if( this.inputPassword.length < 6 )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="비밀번호는 6자리 이상이어야 합니다.";
                return ;
            }

            if( this.inputCheck === '' || this.inputCheck === undefined || this.inputCheck === false ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="탈퇴승인 동의를 체크해주세요.";
                return ;
            }

            
            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/user/out',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {

                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    const data = serverData.data ;

                    if(data.status_code === 500){
                        this.dialogTitle ='Fail' ;
                        this.error = '기존 비밀번호가 맞지 않습니다.';     

                        this.inputPassword = null ;
                        this.inputCheck = false ;

                    } else if(data.status_code == 201){
                        
                        this.dialogTitle ='SuccessDel' ;
                        this.error = '회원 탈퇴되었습니다. 1개월간 개인정보 보관후 모든 회원관련 정보를 삭제합니다.';     

                        if(this.dialogTitle ==='SuccessDel'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 

                                //모든 토큰 없앰 
                                this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                this.$router.replace('/');

                            }, 5000);
                        }

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', data.error_description);
                        this.dialogTitle ="입력 실패" ;
                        this.error = data.error_description;

                    }

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    }
}
</script>

<style scoped>

</style>