export default {
    is_social(state){
        return state.is_social ;
    },
    facebook(state){
        return state.facebook;
    },
    youtube(state){
        return state.youtube;
    },
    twitter(state){
        return state.twitter;
    },
    instagram(state){
        return state.instagram;
    },
}