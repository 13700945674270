<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

	<!-- Page main content START -->
	<div class="page-content-wrapper-match border">
        <form @submit.prevent="sendForm" >
            <!-- Title -->
            <div class="row mb-2">
                <div class="col-12 d-sm-flex justify-content-between align-items-center">
                    <h1 class="h3 mb-2 mb-sm-0">Setting Create</h1>
                    <router-link :to="backToLink" class="btn btn-secondary mb-0 me-2">Back</router-link>
                </div>
            </div>

            <div class="card card-body bg-transparent pb-0 border mb-4">
                <div class="row mb-1">
                    <div class="mb-2 bg-light-input">
                        <label for="titleBox" class="form-label">Title *</label>
                        <input type="text" class="form-control" placeholder="title" v-model.trim="inputTitle">
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="mb-2 bg-light-input">
                        <label for="textareaBox" class="form-label">Description *</label>
                        <textarea class="form-control" id="textareaBox" rows="3" v-model.trim="inputDescription"></textarea>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="mb-2 bg-light-input">
                        <label for="activeBox" class="form-label">보임/숨김 *</label>
                        <div class="form-check form-switch form-check-md">
                            <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsActive" @click="onChangeActive" />
                            <label v-if="inputIsActive" class="form-check-label" for="profilePublic">보임 </label>
                            <label v-else class="form-check-label" for="profilePublic">숨김</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-1">
                <div class="col-12 d-sm-flex justify-content-between align-items-center">
                    <div></div>
                    <button type="submit" class="btn btn-primary mb-0" >Create</button>
                </div>
            </div>
        </form>
	</div>
    
</div>
</template>

<script>
var filename__= "[pages > admin > setting > AdminSettingCreate.vue] "
import axios from 'axios';


export default {
    components: {
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  
            
            inputTitle : null, 
            inputDescription : null, 
            inputIsActive : null, 
            
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
        backToLink(){
            // return '/admin/settings/'+this.parent_id+'?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
            return '/admin/settings'; 
        },

    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
            return ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        onChangeActive(){
            this.inputIsActive = !this.inputIsActive ;
        },

        
        // 전송하기  
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'category': 'setting',
                'title':this.inputTitle,
                'description':this.inputDescription,
                'is_active':this.inputIsActive,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputTitle === '' || this.inputTitle === undefined || this.inputTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="타이틀을 입력해주세요.";
                return ;
            }
            if( this.inputDescription === '' || this.inputDescription === undefined || this.inputDescription === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="description을 입력해주세요.";
                return ;
            }
            if( this.inputIsActive === '' || this.inputIsActive === undefined || this.inputIsActive === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="보임 또는 숨김을 선택해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/setting/create',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                    
                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '추가에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 
                                this.$router.replace('/admin/settings'); 
                            }, 1000);
                        }                        


                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                        this.dialogTitle ="전송 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        
    }
}
</script>

<style scoped>

</style>