<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Modal -->
    <!-- 참고자료 : https://getbootstrap.com/docs/5.2/components/modal/ -->

    <!-- 수정 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idUpdateCompu" tabindex="-1" aria-labelledby="updateModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-info">
                    <h1 class="modal-title fs-5" id="updateModalLabel">수정하기</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <select id="inputState2" class="form-select  js-choice"
                        v-model="inputReviewUpdate"
                        @change="onChangeSelect($event)"
                        >
                        <option :value="5" selected="">★★★★★ (5/5)</option>
                        <option :value="4">★★★★☆ (4/5)</option>
                        <option :value="3">★★★☆☆ (3/5)</option>
                        <option :value="2">★★☆☆☆ (2/5)</option>
                        <option :value="1">★☆☆☆☆ (1/5)</option>
                    </select>

                    <label class="form-label">Your Review</label>
                    <textarea class="form-control" rows="3" v-model.trim="inputDescriptionUpdate"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="updateSendForm" class="btn btn-info" data-bs-dismiss="modal">Update review</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

    <!-- 삭제 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" :id="idDeleteCompu">Delete Review</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

    <!-- List  -->
    <div class="d-md-flex my-4">

        <div class="avatar avatar-xl me-4 flex-shrink-0">
            <img v-if="create_photo && is_display" class="avatar-img rounded-circle" :src="create_photo" alt="avatar">
            <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user.png" alt="avatar">
        </div>
        <!-- Text -->
        <div>
            <div v-if="is_display" class="d-sm-flex mt-1 mt-md-0 align-items-center">
                <h5 class="me-3 mb-0">{{create_name}}</h5>
                <!-- Review star -->
                <ul v-if="review == 5" class="list-inline mb-0">
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                </ul>
                <ul v-if="review >= 4 && review < 5" class="list-inline mb-0">
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                </ul>

                <ul v-if="review >= 3 && review < 4" class="list-inline mb-0">
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                </ul>

                <ul v-if="review >= 2 && review < 3" class="list-inline mb-0">
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                </ul>

                <ul v-if="review >= 1 && review < 2" class="list-inline mb-0">
                    <li class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                </ul>
                <ul v-if="review >= 0 && review < 1" class="list-inline mb-0">
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                    <li class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>
                </ul>

            </div>
            <!-- Info -->
            <p class="small mb-2">{{date}}</p>
            <!-- <p class="mb-2">{{content}}</p> -->
            <div class="bg-light p-3 rounded mb-2">
                <div class="d-flex justify-content-center">
                    <div class="me-2">
                        <p v-if="is_display" class="mb-0 white-space-newline">{{description}}</p>
                        <p v-else class="mb-0"><i class="bi bi-exclamation-circle"></i> 보기 중단된 메세지입니다.</p>
                    </div>
                    <!-- <small>{{date}}</small> -->
                </div>
            </div>
            <button v-if="isUser == create_id && is_display" type="button" class="btn btn-sm btn-info mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idUpdateShapCompu"><i class="bi bi-pencil"></i></button>
            <button v-if="isUser == create_id && is_display" type="button" class="btn btn-sm btn-danger mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu"><i class="bi bi-trash"></i></button>
            
        </div>
        <!-- Divider -->
        <hr>

    </div>
  
</div>        
</template>

<script>
var filename__ ="[components > course > CourseDetailReviewListComponent.vue] " ;

import axios from 'axios';

export default {
    components: {},
    props: [
        'id',
        'is_display',
        'description','depth','seq','date','create_id','create_name','create_photo','is_display',
        'review','reply',
        'content_id', 
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputReviewUpdate : this.review, 
            inputDescriptionUpdate : this.description ,
            inputReviewUpdateAvg : null, 
        }
    },
    
    computed: {
        depth_class(){
            if(this.depth === 1){
                return 'mt-5'
            
            } else {
                return "ps-"+this.depth+" ps-md-"+(this.depth+2)
            }    
        },
        idUpdateCompu(){
            return 'updateReviewModal'+this.id
        },
        idUpdateShapCompu(){
            return '#updateReviewModal'+this.id
        },
        idDeleteCompu(){
            return 'deleteReviewModal'+this.id
        },
        idDeleteShapCompu(){
            return '#deleteReviewModal'+this.id
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },

    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        onChangeSelect(event){
            // let funcname__ ="onChangeSelect()"
            // console.log(filename__+ ""+funcname__+" value:",event.target.value)
            this.inputReviewUpdate = event.target.value;
        },  

        // changeReplyShow(){
        //     this.isReplyShow = !this.isReplyShow ;
        // },
        // changeIsDisplay(){
        //     this.inputIsDisplay = !this.inputIsDisplay ;
        //     this.updateIsDisplayForm();
        // },
        // updateIsDisplayForm(){
        //     this.$emit('isDisplayForm', this.id, this.inputIsDisplay)
        // },

        // 수정 입력   
        async updateSendForm(){
            let funcname__="updateSendForm() "
            let dataForm = { 
                'review' : this.inputReviewUpdate, 
                'description' : this.inputDescriptionUpdate, 
                'content_id' : this.content_id,  // 별점 업데이트  
            } ;  

            // console.log(filename__+funcname__+' dataForm :', dataForm);

            if( this.inputReviewUpdate === '' || this.inputReviewUpdate === undefined || this.inputReviewUpdate === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="별점을 선택해주세요.";
                return ;
            }
            if( this.inputDescriptionUpdate === '' || this.inputDescriptionUpdate === undefined || this.inputDescriptionUpdate === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/comment/review/update/user/'+this.id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+funcname__+'  data:', data);

                        if(data.status_code === 201){
                                                        
                            // this.inputDescriptionUpdate = null ;
                            this.inputReviewUpdateAvg = data.response ;
                            this.dialogTitle ='Success' ;
                            this.error = '수정에 성공했습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.$emit('sendRefreshForm', this.inputReviewUpdateAvg);

                                }, 500);
                            }                        

                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        // 팝업 창을 통해서 삭제   
        async removeForm(){
            let funcname__ = "removeForm() " ;

            this.isLoading = true ;
            let dataForm = { 
                'content_id' : this.content_id,
            } ;  

            // console.log(filename__+funcname__+' dataForm :', dataForm);

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/comment/review/delete/user/'+this.id,
                    data: dataForm,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+funcname__+"serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        // console.log(filename__+funcname__+' data:', serverData.data);

                        this.inputReviewUpdateAvg = serverData.data.response ;

                        this.dialogTitle ='Success' ;
                        this.error = '삭제 성공했습니다.';  
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('sendRefreshForm', this.inputReviewUpdateAvg);

                            }, 500);
                        }                        


                    } else {
                        console.log(filename__+funcname__+' return err:', serverData.data.msg);

                        this.error = serverData.data.msg;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },     

    },


    
}
</script>