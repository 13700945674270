<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="row g-4">
                                                
        <!-- Edit faq START -->
        <div class="col-12">
            <div class="bg-light border rounded p-2 p-sm-4">
                <div class="d-sm-flex justify-content-sm-between align-items-center mb-3">
                    <h5 class="mb-2 mb-sm-0">Upload FAQs</h5>
                    <a href="#" class="btn btn-sm btn-primary-soft mb-0" data-bs-toggle="modal" data-bs-target="#addQuestion"><i class="bi bi-plus-circle me-2"></i>Add Question</a>
                </div>

                <div class="row g-4">


                    <div class="col-12" v-for="item in itemList" :key="item.id" >
                        <div class="bg-body p-3 p-sm-4 border rounded">
                            <!-- Item 1 -->
                            <div class="d-sm-flex justify-content-sm-between align-items-center mb-2">
                                <!-- Question -->
                                <h6 class="mb-0">
                                    <span v-if="item.is_display" class="me-1 ms-1">{{item.title}}</span>
                                    <span v-else class="text-decoration-line-through me-1 ms-1">{{item.title}}</span>

                                </h6>
                                <!-- Button -->
                                <div class="align-middle">

                                    <button v-if="item.min != true  " 
                                            @click="upSeqForm(item.id)" 
                                            class="btn btn-sm btn-info mb-0 me-2" 
                                        ><i class="fas fa-arrow-alt-circle-up"></i></button>

                                    <button v-if="item.max != true" 
                                            @click="downSeqForm(item.id)" 
                                            class="btn btn-sm btn-danger mb-0 me-2"
                                        ><i class="fas fa-arrow-circle-down"></i></button>
                                                                
                                    <button type="button" @click="getItem(item.id)" 
                                            class="btn btn-sm btn-success-soft btn-round me-1 mb-1 mb-md-0"
                                            data-bs-toggle="modal" data-bs-target="#updateQuestion"
                                        ><i class="far fa-fw fa-edit"></i></button>

                                    <button type="button" 
                                            @click="removeForm(item.id)" 
                                            class="btn btn-sm btn-danger-soft btn-round mb-0"
                                        ><i class="fas fa-fw fa-times"></i></button>

                                </div>
                            </div>
                            <!-- Content -->
                            <p v-if="item.is_display" class="white-space-newline">{{item.content}}</p>
                            <p v-else class="text-decoration-line-through white-space-newline">{{item.content}}</p>

                        </div>
                    </div>

                    
                </div>
            </div>	
        </div>
        <!-- Edit faq END -->

        <!-- Tags START -->
        <!-- <div class="col-12">
            <div class="bg-light border rounded p-4">
                <h5 class="mb-0">Tags</h5>
                <div class="mt-3">
                    <input type="text" class="form-control js-choice mb-0" data-placeholder="true" data-placeholder-Val="Enter tags" data-max-item-count="14" data-remove-item-button="true">
                    <span class="small">Maximum of 14 keywords. Keywords should all be in lowercase and separated by commas. e.g. javascript, react, marketing</span>
                </div>
            </div>
        </div> -->
        <!-- Tags START -->

        <!-- Reviewer START -->
        <!-- <div class="col-12">
            <div class="bg-light border rounded p-4">
                <h5 class="mb-0">Message to a reviewer</h5>

                <div class="mt-3">
                    <textarea class="form-control" rows="4" placeholder="Write a message" spellcheck="false"></textarea>
                    <div class="form-check mb-0 mt-2">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        <label class="form-check-label" for="exampleCheck1">
                            Any images, sounds, or other assets that are not my own work, have been appropriately licensed for use in the file preview or main course. Other than these items, this work is entirely my own and I have full rights to sell it here.
                        </label>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Reviewer START -->
        
        <!-- Popup modal for add faq START -->
        <teleport to="body">
            <div class="modal fade" id="addQuestion" tabindex="-1" aria-labelledby="addQuestionLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header bg-dark">
                            <h5 class="modal-title text-white" id="addQuestionLabel">Add FAQ</h5>
                            <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                        </div>
                        <form @submit.prevent="sendForm">
                            <div class="modal-body">
                                <div class="row text-start g-3">
                                        <!-- Question -->
                                        <div class="col-12">
                                            <label class="form-label">Question</label>
                                            <input class="form-control" type="text" placeholder="Write a question" v-model.trim="inputTitle" >
                                        </div>
                                        <!-- Answer -->
                                        <div class="col-12 mt-3">
                                            <label class="form-label">Answer</label>
                                            <textarea class="form-control" rows="4" placeholder="Write a answer" spellcheck="false" v-model.trim="inputContent" ></textarea>
                                        </div>
                                    </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger-soft my-0" data-bs-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-success my-0" data-bs-dismiss="modal">Save Faq</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </teleport>
        <teleport to="body">
            <div class="modal fade" id="updateQuestion" tabindex="-1" aria-labelledby="updateQuestionLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header bg-dark">
                            <h5 class="modal-title text-white" id="updateQuestionLabel">Update FAQ</h5>
                            <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                        </div>
                        <form @submit.prevent="sendUpdateForm">
                            <div class="modal-body">
                                <div class="row text-start g-3">
                                        <!-- Question -->
                                        <div class="col-12 mt-3">
                                            <div class="form-check form-switch form-check-md">
                                                <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIsDisplayUpdate" @click="onChangeIsDisplayUpdate">
                                                <label v-if="inputIsDisplayUpdate" class="form-check-label" for="onoffPublic">외부 공개</label>
                                                <label v-else class="form-check-label" for="onoffPublic">외부 비공개</label>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <label class="form-label">Question</label>
                                            <input class="form-control" type="text" placeholder="Write a question" v-model.trim="inputTitleUpdate" >
                                        </div>
                                        <!-- Answer -->
                                        <div class="col-12 mt-3">
                                            <label class="form-label">Answer</label>
                                            <textarea class="form-control" rows="4" placeholder="Write a answer" spellcheck="false" v-model.trim="inputContentUpdate" ></textarea>
                                        </div>
                                    </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger-soft my-0" data-bs-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-success my-0" data-bs-dismiss="modal">Save Faq</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </teleport>
        <!-- Popup modal for add faq END -->    

    </div>
</div>    
</template>

<script>
// v-for__string__
var filename__ ="[components > companydash > CompanyDashFaqPanelUpdateComponent.vue]"

import axios from 'axios';

export default {
    components: {
    },
    props: [ 'content_id' ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList : [],

            inputTitle : null,
            inputTitleUpdate : null,
            inputContent : null,
            inputContentUpdate : null,

            inputIsDisplayUpdate : null,

            inputChoiceId: null,

        }
    },
    created(){
        this.getList()
    },    
    computed: {
    },
    methods: {
        handleError(){
            this.error = null;

        },
        onChangeIsDisplayUpdate(){
            this.inputIsDisplayUpdate = !this.inputIsDisplayUpdate ;
        },

        async getList(){
            let funcname__ ="getList()" ;

            this.isLoading = true;

            let serverData = await axios({
                method: 'get',
                url: '/api/faq/course/all/'+this.content_id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },

            }).catch( err=> {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            this.isLoading = false ;

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData !== "undefined" && serverData) {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+"  data:", data);

                if(data.status_code == 200 ){
                    
                    let tmpList = data.response.data ;
                    let list = [];

                    // console.log(filename__+''+funcname__+" tmpList:", tmpList);

                    let cnt=1
                    //메인 카테고리 값들을 입력하기 
                    for(let item of tmpList ){
                        list.push({
                            num: cnt++, 
                            id: item.id, 
                            title: item.title, 
                            content: item.content, 
                            is_display : item.is_display,
                            seq: item.seq, 
                            min : item.min,
                            max : item.max,
                        });
                    }

                    // console.log(filename__+''+funcname__+" list:", list);

                    this.itemList = list

                    // console.log(filename__+''+funcname__+" itemList:", this.itemList);

                }

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;

        },
        async getItem(_id){
            let funcname__ ="getItem()" ;

            this.inputChoiceId = _id ; //update 대비 

            this.isLoading = true;

            let serverData = await axios({
                method: 'get',
                url: '/api/faq/course/'+_id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },

            }).catch( err=> {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            this.isLoading = false ;

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData !== "undefined" && serverData) {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+"  data:", data);

                if(data.status_code == 200 ){

                    const response = data.response ;

                    // console.log(filename__+''+funcname__+' response:', response);
                    
                    this.inputTitleUpdate = response.title ;
                    this.inputContentUpdate = response.content ;
                    this.inputIsDisplayUpdate = response.is_display ;
                }

            } else {
                console.log(filename__+''+funcname__+' return err:');

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.msg; 
            }

        },

        // lecure 정보 업데이트  
        async sendForm(){
            let funcname__ ="sendForm()" ;

            let dataForm = { 
                'category' : 'course',
                'content_id' : this.content_id,
                'title': this.inputTitle,
                'content': this.inputContent,
            } ;  

            if( this.inputTitle === '' || this.inputTitle === undefined || this.inputTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="질문을 입력해주세요.";
                return ;
            }
            if( this.inputContent === '' || this.inputContent === undefined || this.inputContent === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="답변을 입력해주세요.";
                return ;
            }

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/faq/course/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

     
                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.inputTitle = null ;
                        this.inputContent = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.getList();

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // 정보 업데이트  
        async sendUpdateForm(){
            let funcname__ ="sendUpdateForm()" ;

            let dataForm = { 
                'title' : this.inputTitleUpdate,
                'content': this.inputContentUpdate,
                'is_display': this.inputIsDisplayUpdate,
            } ;  

            if( this.inputTitleUpdate === '' || this.inputTitleUpdate === undefined || this.inputTitleUpdate === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="질문을 입력해주세요.";
                return ;
            }
            if( this.inputContentUpdate === '' || this.inputContentUpdate === undefined || this.inputContentUpdate === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="답변을 입력해주세요.";
                return ;
            }

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/faq/course/update/'+this.inputChoiceId,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.inputChoiceId = null ;
                        this.inputTitleUpdate = null ;
                        this.inputContentUpdate = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.getList();

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        async upSeqForm(_id){
            let funcname__ ="upSeqForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/faq/course/upseq/'+_id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        this.getList();

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        async downSeqForm(_id){
            let funcname__ ="downSeqForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/faq/course/downseq/'+_id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                    
                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        // this.dialogTitle ='Success' ;
                        // this.error = '성공했습니다.';     

                        this.getList();

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        async removeForm(_id){
            let funcname__ ="removeForm()" ;

            const isConfirm = confirm('Are you delete ?');
            if(!isConfirm) return ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/faq/course/delete/'+_id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '삭제에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.getList();

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    },
    
}
</script>
<style scoped>

</style> 