<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="col-md-12"  >           
        <div class="row">
            <compaydash-course-cardnews-list-component v-for="item in itemCardnewsList"
                :key="item.id"
                :id="item.id"
                :parent_id="parent_id"
                :photo="item.photo"
                :is_fronttext="item.is_fronttext"
                :description="item.description"
                :subseq="item.subseq"
                :is_min="item.is_min"
                :is_max="item.is_max"
                @refresh-cardnews-list="refreshCardnewsListForm"
            />
        </div>
    </div>

    <div class="col-md-12 mt-2"  > 
        <form class="row">

            <!-- <div class="col-12 justify-content-center align-items-center"> -->
            <div class="col-md-12">
                <!-- <label class="form-label">파일은 다중 선택이 가능합니다.</label> -->
                <div>
                    <label class="btn btn-danger-soft mb-0" for="uploadfileCardnews">카드뉴스 이미지 업로드</label>
                    <input type="file" multiple="multiple" accept="image/*" @change="onChangeCardnewsMultiFile" id="uploadfileCardnews" class="form-control d-none" >
                </div>

            </div>
        </form>
    </div>
</div>    
</template>
    
<script>
var filename__= "[components > content > ContentCardnewsFileUploadPanelComponent.vue] "

import axios from 'axios';
import { v4 } from 'uuid';  // 참고자료 uuid__ : https://jane-aeiou.tistory. com/59

// <==from : CompanyDashCourseTopicListComponent, CompanyDashCourseTopicCreateFormComponent
import CompanyDashCourseCardnewsListComponent from '../companydash/CompanyDashCourseCardnewsListComponent.vue';

export default {
    components: {
        "compaydash-course-cardnews-list-component" : CompanyDashCourseCardnewsListComponent,
    },
    props: [
        'group_id','parent_id','company_id',  
        'isMultiFile',
        'fileKind','category','subCategory'
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,      

            inputWidth : 300,

            inputFileList : [], 
            inputFileTmpList : [], 

            itemCardnewsList: [],            
        }
    },
    beforeMount() {
    },     
    computed: {
    },    
    created(){    
        // if(this.initialize){
        //     this.getCardnewsList()
        // }
        this.getCardnewsList()        
    },
    methods: {
        handleError(){
            this.error = null;

        },
        createCardnewsPanelUuidid(){
            // uuid 생성 : key를 매번 새롭게 갱신해주여야 itemList 변경되었을 때 새롭게 갱신됨
            const generate_uuid = () => {
                const tokens = v4().split('-')
                return tokens[2] + tokens[1] + tokens[0] + tokens[3] + tokens[4];
            }
            return generate_uuid() ;
        },

        onChangeCardnewsMultiFile(evt){ // 파일 여러개 업로드 
                        
            const files = evt.target.files;  // this.$refs.file.files
            this.inputFileList = files ; // 업로드 할 내용  ;
                        
            for (var i = 0; i < files.length; i++ ){
                console.log(filename__+" onChangeMultiFile files["+i+"]:", files[i].name);  //~~log
                
                const file_size = 1024*1024*10 ; // 10M 크기 
                if(files[i].size > file_size ){
                    alert("이미지 사이즈는 10M 이하만 업로드 됩니다. [ 크기초 과파일 : "+files[i].name+ " ]")
                    return ;                       
                }

                // 참고자료 : https://marketsplash.com/tutorials/vue-js/vue-js-file-upload/
                // const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];    
                if(this.fileKind === 'image') {
                    const allowedFileTypes = ['image/jpeg', 'image/png','image/gif'];
                    if (!allowedFileTypes.includes(files[i].type)) {
                        alert('이미지 파일이 아닙니다.Please upload a JPEG, PNG. GIF');
                        return ;
                    }
                }            

                // this.inputFileTmpList.push(URL.createObjectURL(files[i])); //미리보기용 
                
            }
            this.sendCardnewsMultiFileForm(); // 멀티파일 업로드 
        },
            
        refreshCardnewsListForm(){
            this.getCardnewsList();
        },
        // content image 
        async getCardnewsList(){
            let funcname__ ="getCardnewsList() ";
            console.log(filename__+funcname__+'  parent_id:', this.parent_id);    

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/course/curriculum/cardnews/all/'+this.group_id+'/'+this.parent_id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                        
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response.data ;
                let list = [];

                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        description: item.description, 
                        photo:item.topic_photo,
                        is_fronttext:item.is_fronttext,
                        subseq: item.subseq, 
                        is_min: item.is_min, 
                        is_max: item.is_max, 
                    });
                }

                console.log(filename__+''+funcname__+" list:", list);
                this.itemCardnewsList = list;

                console.log(filename__+''+funcname__+" itemCardnewsList:", this.itemCardnewsList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  
            }

            this.isLoading = false ;            
        },
        // 멀티파일 업로드         
        async sendCardnewsMultiFileForm(){
            let funcname__ ="sendCardnewsMultiFileForm() ";
            console.log(filename__+funcname__+'  parent_id:', this.parent_id);  

            if(this.inputFileList.length === 0 ){
                this.dialogTitle ="Error" ;
                this.error = "업로드할 파일을 선택해주세요.";
                return ;                       

            }

            // 파일전송 FormData() : https://hagohobby.tistory.com/21 
            const formData = new FormData()
            formData.append('group_id', parseInt(this.group_id));
            formData.append('parent_id', parseInt(this.parent_id));
            formData.append('company_id', parseInt(this.company_id));
            formData.append('category', this.category);
            formData.append('subcategory', this.subCategory);
            formData.append('filekind', this.fileKind);

            for (const file of this.inputFileList) {
                // 참고자료 : https://stackoverflow.com/questions/74507306/fastapi-returns-error-422-unprocessable-entity-when-i-send-multipart-form-dat
                formData.append('files', file);
            }

            for (const value of formData.values()) {
                console.log(filename__+''+funcname__+' formData.value:', value);
            }

            this.isLoading = true ;
            try {
                let serverData = await axios({
                    method: 'post',
                    url: `/api/content/file/multiupload/cardnews/create`,
                    headers: {
                        'Content-Type' : 'multipart/form-data',
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 
                    data: formData

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){

                        console.log(filename__+''+funcname__+' data:', data);
                        
                        this.inputFileList = [] ;
                        this.inputFileTmpList = [] ;

                        this.dialogTitle ='Success' ;
                        this.error = '업로드 성공했습니다.';  
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                this.refreshCardnewsListForm();  // 이미지 리스트 다시 불러오기 

                            }, 500);
                        }                        
                        
                        
                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        // this.dialogTitle ="Error" ;
                        // this.error = serverData.data.error_description;
                        alert(serverData.data.error_description)
                    }
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    }
}
</script>
    
<style scoped>

</style>