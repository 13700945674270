<template>
<div class="row g-4">

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>



    <!-- Course START -->
    <div class="col-md-6 col-xxl-12">
        <div class="card bg-transparent border overflow-hidden">
            <!-- Card header -->
            <div class="card-header bg-light border-bottom">
                <h5 class="card-header-title mb-0">Course Count</h5>
            </div>
            <!-- Card body -->
            <div class="card-body p-0">
                <div class="d-sm-flex justify-content-between p-4">
                    <h4 class="text-blue mb-0">{{ inputCourseCount }}</h4>
                    <!-- <p class="mb-0"><span class="text-success me-1">0.35%<i class="bi bi-arrow-up"></i></span>vs last Week</p> -->
                </div>
                <!-- Apex chart -->
                <!-- <div id="activeChartstudent2"></div> -->
            </div>
        </div>
    </div>
    <!-- Course END -->

    <!-- Enrolled START -->
    <div class="col-md-6 col-xxl-12">
        <div class="card bg-transparent border overflow-hidden">
            <!-- Card header -->
            <div class="card-header bg-light border-bottom">
                <h5 class="card-header-title mb-0">Enroll Count</h5>
            </div>
            <!-- Card body -->
            <div class="card-body p-0">
                <div class="d-sm-flex justify-content-between p-4">
                    <h4 class="text-blue mb-0">{{ inputEnrollCount }}</h4>
                    <!-- <p class="mb-0"><span class="text-success me-1">0.35%<i class="bi bi-arrow-up"></i></span>vs last Week</p> -->
                </div>
                <!-- Apex chart -->
                <!-- <div id="activeChartstudent2"></div> -->
            </div>
        </div>
    </div>
    <!-- Enrolled END -->

    <!-- Active student START -->
    <div class="col-md-6 col-xxl-12">
        <div class="card bg-transparent border overflow-hidden">
            <!-- Card header -->
            <div class="card-header bg-light border-bottom">
                <h5 class="card-header-title mb-0">Active Students</h5>
            </div>
            <!-- Card body -->
            <div class="card-body p-0">
                <div class="d-sm-flex justify-content-between p-4">
                    <h4 class="text-blue mb-0">{{ inputStudentCount }}</h4>
                    <!-- <p class="mb-0"><span class="text-success me-1">0.20%<i class="bi bi-arrow-up"></i></span>vs last Week</p> -->
                </div>
                <!-- Apex chart -->
                <!-- <div id="activeChartstudent"></div> -->
            </div>
        </div>
    </div>
    <!-- Active student END -->

</div> <!-- Row END -->

</template>

<script>
//  emit__
var filename__= "[components > admin > company > AdminCompanyDetailCourseCountComponent.vue] "

import axios from 'axios';

export default {
    components: {},
    props: [
        'company_id',
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            dialogTitle: null,     
            
            inputCourseCount: 0,
            inputEnrollCount: 0,
            inputStudentCount :0,
                    
        }
    },
    created(){
        this.getItem();
    },    
    computed: {
    },
    methods: {
        handleError(){
            this.error = null;
        },
        
        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            // console.log(filename__+''+funcname__+" ${this.$store.getters[auth/isToken]}:", this.$store.getters['auth/isToken'] );
            // console.log(filename__+''+funcname__+" ${this.$store.getters[auth/isToken]}2:", `Bearer  ${this.$store.getters['auth/isToken']}` );

            let serverData = await axios({
                method: 'get',
                url: '/api/content/course/company/counts/'+this.company_id,
                headers: {
                    'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {


                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined" || serverData === undefined ||  serverData.status !== 200){
                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" && data.status_code == 200){
                    // console.log(filename__+''+funcname__+' response:', data.response);
                    
                    this.inputCourseCount = data.response.display_count ;
                    this.inputEnrollCount = data.response.total_enrollcount ;
                    this.inputStudentCount = data.response.total_studentcount ;


                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;    
        },

    },
    
}
</script>
<style scoped>

</style> 