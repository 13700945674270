<template>
<div>

    <!-- Sidebar START -->
    <!-- Sidebar END -->
    
    <!-- Page content START -->
	
	<!-- Top bar START -->
	<!-- Top bar END -->

	<!-- Page main content START -->
	<div class="page-content-wrapper-match border">

		<base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
			<p>{{ error }}</p>
		</base-dialog>

		<base-dialog :show="isLoading" title="Loading..." fixed>
			<p>Loading...</p>
			<base-spinner></base-spinner>
		</base-dialog>

        <!-- Modal -->
        <!-- 참고자료 : https://getbootstrap.com/docs/5.2/components/modal/ -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Delete</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>

		<!-- Title -->
        <form @submit.prevent="sendForm" >
            <div class="row mb-0">
                <div class="col-12 text-end mb-3">
                    <!-- <h1 class="h3 mb-2 mb-sm-0">Contact Us Detail</h1> -->
                    <router-link :to="backToLink" class="btn btn-secondary mb-0 me-2">Back</router-link>
                </div>
            </div>

            <div class="col-12">
                <div class="card bg-transparent border rounded-3 h-100">

                    <!-- Catd header -->
                    <div class="card-header bg-light border-bottom">
                        <h5 class="card-header-title">코스 카테고리 수정 </h5>
                    </div>

                    <!-- Card body START -->
                    <div class="card-body">

                            <div class="row g-4">
                                <!-- Course info and avatar -->
                                <div class="col-12 mb-2">
                                    <label class="form-label">Title</label>
                                    <div class="input-group">
                                        <input type="text" v-model.trim="inputTitle" class="form-control" placeholder="title">
                                    </div>
                                </div>
                                <div v-if="category === 'degree' || category === 'certificate' " class="col-12 mb-2">
                                    <label class="form-label">Sub Title</label>
                                    <div class="input-group">
                                        <input type="text" v-model.trim="inputSubtitle" class="form-control" placeholder="sub title">
                                    </div>
                                </div>

                                <!-- Upload image START -->
                                <div v-if="category === 'certificate'" class="col-12 mt-5">
                                    <form @submit.prevent="sendFileForm">
                                        <div v-if="photoCompu" >
                                            <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                                <div class="card card-xl text-center justify-content-center align-items-center">
                                                    <img :src="photoCompu" class="img-fluid border border-white border-3 shadow"  :width="widthCompu" alt="" />
                                                </div>
                                            </label>

                                            <div class="d-sm-flex justify-content-end mt-2">
                                                <button type="button" @click="removePhotoForm" class="btn btn-sm btn-orange">Remove image</button>
                                            </div>
                                        </div>

                                        <div v-else-if="phototmpCompu" >
                                            <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                                <div class="card card-xl text-center justify-content-center align-items-center">
                                                    <img :src="phototmpCompu" id="uploadfile-1-preview" class="img-fluid border border-white border-3 shadow"  :width="widthCompu" alt="" />
                                                </div>
                                                <button type="button" @click="resetImage" class="updateremove"><i class="bi bi-x text-white"></i></button>
                                            </label>
                                            <div class="d-sm-flex justify-content-end mt-2">                                                                
                                                <button type="submit" class="btn btn-sm btn-info">Save Image</button>
                                            </div>
                                        </div>

                                        <div v-else >
                                            <div class="text-center justify-content-center align-items-center p-2 p-sm-3 border border-2 border-dashed position-relative rounded-3">
                                                <img src="/assets/images/element/gallery.svg" class="h-50px" alt="">
                                                <div>
                                                    <h6 class="my-2">Upload course image here, or<a href="#!" class="text-primary"> Browse</a></h6>
                                                    <label style="cursor:pointer;">
                                                        <span> 
                                                            <input class="form-control stretched-link" type="file" name="my-image" accept="image/gif, image/jpeg, image/png" ref="photoref" @change="onChangeFile" id="uploadfile-1" />
                                                        </span>
                                                    </label>
                                                    <p class="small mb-0 mt-2"><b>Note:</b> Only JPG, JPEG and PNG. Our suggested dimensions are 600px * 450px. Larger image will be cropped to 4:3 to fit our thumbnails/previews.</p>
                                                </div>	
                                            </div>                
                                            <!-- <div class="d-sm-flex justify-content-end mt-2">
                                                <button type="button" class="btn btn-sm btn-success-soft mb-3">Change image</button>
                                            </div> -->
                                        </div>
                                        <hr/>

                                    </form>
                                </div>
                                <!-- Upload image END -->

                                <div class="col-12">
                                    <div class="row g-xl-0 align-items-center">
                                        <div class="col-lg-2 ms-3">
                                            <h6 class="mb-lg-0">보임/숨김 <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="d-flex">
                                                <div class="form-check radio-bg-light me-4">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
                                                            v-model="inputIsDisplay" 
                                                            value="true"
                                                        >
                                                    <label class="form-check-label" for="flexRadioDefault1">보임</label>
                                                </div>
                                                <div class="form-check radio-bg-light">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" 
                                                            v-model="inputIsDisplay" 
                                                            value="false"
                                                        >
                                                    <label class="form-check-label" for="flexRadioDefault2">숨김</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                    </div>
                    <!-- Card body END -->
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 d-sm-flex justify-content-between align-items-center">
                    <button v-if="inputRecommendCount > 0"  type="button" class="btn btn-danger mb-0" disabled>Delete</button>
                    <button v-else type="button" class="btn btn-danger mb-0" data-bs-toggle="modal" data-bs-target="#exampleModal">Delete</button>
                    <button type="submit" class="btn btn-primary mb-0" >Save changes</button>
                </div>
            </div>
        </form>


	</div>

</div>
</template>

<script>
var filename__="[pages > admin > courserecommend > AdminCourseRecommendCategoryUpdate.vue] "

import axios from 'axios';

export default {
    components: {
    },
    props: ['id','category'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  
            
            inputTitle : null, 
            inputSubtitle : '',    
            inputDescription: '',
            inputIsDisplay : null, 

            inputPhoto : null ,
            inputPhotoUpdate : null,  
            inputPhotoTmp : null, 
            inputWidth : 150,

            inputRecommendCount : 0 ,
            
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			this.getItem();
            return ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    computed: {
        backToLink(){
            return '/admin/course/recommendcategories/'+this.category; 
        },
        photoCompu(){
            return this.inputPhoto ;
        },
        phototmpCompu(){
            return this.inputPhotoTmp ;
        },        
        widthCompu(){
            return this.inputWidth ;
        },
    },    
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },

        async getItem(){
            let funcname__ ="getItem()" ;

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/course/recommendcategory/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+''+funcname__+'  response:', data.response);

                    this.inputTitle = data.response.title ;
                    this.inputSubtitle = data.response.subtitle ;
                    this.inputIsDisplay = data.response.is_display ;

                    this.inputPhoto = data.response.image ;

                    this.inputRecommendCount = data.response.recommend_count ;

                    // console.log(filename__+''+funcname__+'  inputRecommendCount:', this.inputRecommendCount);

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;     
        },
        
        // 전송하기  
        async sendForm(){
            let funcname__ ="sendForm()" ;

            let dataForm = { 
                'title':this.inputTitle,
                'subtitle': this.inputSubtitle,
                'description': this.inputDescription,
                'is_display':this.inputIsDisplay,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputTitle === '' || this.inputTitle === undefined || this.inputTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="카테고리를  입력해주세요.";
                return ;
            }
            if(this.category === 'degree') {
                if( this.inputSubtitle === '' || this.inputSubtitle === undefined || this.inputSubtitle === null ) {
                    this.formIsValid = false ;
                    this.dialogTitle = 'Error'
                    this.error ="서브 타이틀을 입력해주세요.";
                    return ;
                }
            }            

            if( this.inputIsDisplay === '' || this.inputIsDisplay === undefined || this.inputIsDisplay === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="보임/숨김 상태를 선택해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/course/recommendcategory/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                                   
                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$router.replace(this.backToLink);

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // 팝업 창을 통해서 삭제   
        async removeForm(){
            let funcname__ ="removeForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/course/recommendcategory/'+this.id,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        // console.log(filename__+''+funcname__+' data:', serverData.data);
                                                
                        this.dialogTitle ='Success' ;
                        this.error = '삭제 성공했습니다.';   

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$router.replace(this.backToLink);

                            }, 500);
                        }                        


                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },     
        
        // https://codepen.io/Atinux/pen/qOvawK/        
        resetImage: function(){
            this.inputPhotoUpdate = null;  // this.inputImage = this.$refs.file.files[0];
            this.inputPhotoTmp = null;
        },
        onChangeFile(evt){
            // let funcname__ ="onChangeFile() " ;

            // console.log(filename__+ ""+funcname__+' 1 ');
            // const files = evt.target.files[0] || evt.dataTransfer.files;
            const file = evt.target.files[0] ;

            // console.log(filename__+ ""+funcname__+'2 file:', file);
            
            this.inputPhotoUpdate = file;  // this.inputImage = this.$refs.file.files[0];
            this.inputPhotoTmp = URL.createObjectURL(file);
        },

        //사진 업로드 
        async sendFileForm(){
            let funcname__ ="sendFileForm() " ;

            if(this.inputPhotoUpdate == null){
                this.dialogTitle ="Error" ;
                this.error = "업로드할 사진을 선택해주세요.";
                return ;                       
            }
            // 파일전송 FormData() : https://hagohobby.tistory.com/21 
            const formData = new FormData()
            formData.append('upload_image', this.inputPhotoUpdate);

            // console.log(filename__+ ""+funcname__+' formData:', formData);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/course/recommendcategory/image/'+this.id,
                    headers: {
                        'Content-Type' : 'multipart/form-data',
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 
                    data: formData

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+ ""+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        // console.log(filename__+ ""+funcname__+' data:', serverData.data);
                        
                        this.inputPhoto = serverData.data.image_path ;
                        this.inputPhotoUpdate = null ;
                        this.inputPhotoTmp = null ;

                        this.dialogTitle ='Success.' ;
                        this.error = '이미지 업로드 성공했습니다.';    

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$router.replace(this.backToLink);

                            }, 500);
                        }                        

                        
                    } else {
                        console.log(filename__+ ""+funcname__+' return err:', serverData.data.msg);

                        this.error = serverData.data.msg;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+ ""+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        //프로필 사진 삭제  
        async removePhotoForm(){
            let funcname__="removePhotoForm() "
            
            const isConfirm = confirm('Are you delete image?');
            if(!isConfirm) return ;
            
            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/course/recommendcategory/image/'+this.id,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                    
                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+ ""+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        // console.log(filename__+ ""+funcname__+' data:', serverData.data);
                        
                        this.inputPhotoUpdate = serverData.data.image_path ;
                        this.inputPhoto = null ;

                        this.dialogTitle ='Success.' ;
                        this.error = '이미지 삭제 성공했습니다.';    

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$router.replace(this.backToLink);

                            }, 500);
                        }                        

                        
                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+ ""+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        
    }
}
</script>

<style scoped>

</style>