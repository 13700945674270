<template>
<div>
    <div class="col-12 mb-2">
        <!-- Title -->
        <h2 v-if="blog.is_display">{{blog.title}}</h2>
        <h2 v-else class="text-decoration-line-through"><i class="fas fa-eye-slash me-3"></i>{{blog.title}}</h2>
        <p v-if="blog.is_display">{{blog.subtitle}}</p>
        <p v-else class="text-decoration-line-through">{{blog.subtitle}}</p>
        <!-- Content -->
        <ul class="list-inline mb-0">
            <li class="list-inline-item fw-light h6 me-3 mb-1 mb-sm-0"><i class="fas fa-signal me-2"></i>
                <span v-if="blog.level ===0"> All levels</span>
                <span v-if="blog.level ===1"> Beginner</span>
                <span v-if="blog.level ===2"> Intermediate</span>
                <span v-if="blog.level ===3"> Advanced</span>
            </li>
            <li class="list-inline-item fw-light h6 me-3 mb-1 mb-sm-0"><i class="bi bi-patch-exclamation-fill me-2"></i>Last updated {{blog.update}}</li>
        </ul>
    </div>
    <!-- Title END -->

    <!-- Image and video -->
    <div v-if="!blog.is_fronttext" class="position-relative mt-4 mb-2">
        <img v-if="isMainPhotoCompu" :src="blog.main_photo.main_photo_path" class="rounded  h-200px" alt="">
        <img v-else src="/assets/images/element/gallery.svg" class="rounded h-150px" alt="">
    </div>
    <!-- About  START -->
    <div class="col-12">
        <div class="card border">
            <!-- Card header START -->
            <div class="card-header border-bottom">
                <h3 class="mb-0">정보창고 description</h3>
            </div>
            <!-- Card header END -->

            <!-- Card body START -->
            <div class="card-body">
                <div class="mb-3" v-html="outputMarkdownCompu"></div>
            </div>
            <!-- Card body START -->

        </div>
    </div>
    <div v-if="itemFileList.length > 0 " class="mt-2">
        <label class="form-label" >첨부파일</label> 
        <ul>
            <li v-for="item in itemFileList" :key="item.id">
                <a :href="item.filepath" v-text="item.filename" @click.prevent="downloadItem(item.filepath, item.filename)" />
            </li>
        </ul>
    </div>

    <!-- About  END -->

</div>
</template>

<script>
var filename__= "[components > companydash > CompanyDashBlogDetailCenterComponent.vue] "

import axios from 'axios';
import { marked } from 'marked';         // yarn add marked  https://marked.js.org/

export default {
    components: {},
    props: [
        'content_id','blog', 
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  

           category : 'blog', 
           subCategory : 'description', 
           fileKind : 'file', 

           itemFileList : [],
        }
    },
    created(){
        this.getFileList()
    },
    computed: {
        outputMarkdownCompu(){
            return marked(this.blog.description);
        },	
        isMainPhotoCompu(){
            // return Object.keys(this.blog.main_photo).length === 0;
            return Object.keys(this.blog.main_photo).length > 0;
        }		        

    },
    methods: {
        async getFileList(){
            let funcname__ ="getFileList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/file/contentid/all/'+this.content_id+'/'+this.category+'/'+this.subCategory+'/'+this.fileKind,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                        
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        filename: item.photo.replace(/^.*[\\\/]/, ''), 
                        filepath:item.photo,
                    });
                }

                // console.log(filename__+''+funcname__+" list:", list);
                this.itemFileList = list;

                console.log(filename__+''+funcname__+" itemFileList:", this.itemFileList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  

            }

            this.isLoading = false ;            
        },
        async downloadItem(_filepath, _filename) {
            axios.get(_filepath, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/octet-stream' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = _filename
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        }        

    },
    
}
</script>