import { createStore} from 'vuex';

import settingModule from './modules/setting/index.js';
import socialModule from './modules/social/index.js';
import authModule from './modules/auth/index.js';
import courseModule from './modules/course/index.js';
import courserecommendModule from './modules/courserecommend/index.js';
import blogModule from './modules/blog/index.js';

import requestesModule from './modules/requestes/index.js';
import messageModule from './modules/message/index.js';
import dashboardModule from './modules/dashboard/index.js';
import productModule from './modules/product/index.js';
import jobModule from './modules/job/index.js';

const store = createStore({
    modules: {

        setting: settingModule,
        social: socialModule,
        auth: authModule,
        course: courseModule,
        courserecommend: courserecommendModule,
        blog: blogModule,

        requests: requestesModule,
        message: messageModule,
        dashboard : dashboardModule,
        product : productModule,
        job : jobModule,
    }
});

export default store ;