export default {
    userId(state){
        return state.userId ;
    },
    token(state){
        return state.token ;
    },
    name(state){
        return state.name ;
    },
    photo(state){
        return state.photo ;
    },
    email(state){
        return state.email ;
    },
    phone(state){
        return state.phone
    },

    isAuthenticated(state){
        // console.log('[store/modules/auth/getters.js]isAuthenticated() state:',state);
        //~~ double ! : https://hermeslog.tistory.com/279
        //~~ double ? : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator
        return !!state.token ;
    },
    isAdmin(state){
        return state.is_admin;
    },

    isToken(state){
        return state.token ;
    },
    isLevel(state){
        return state.level; 
    },
    isPageAdmin(state){
        return state.isPageAdmin ;
    },

    didAutoLogout(state){
        return state.didAutoLogout ;
    }    
}