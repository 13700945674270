<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <form @submit.prevent="sendStep1UpdateForm" > 
        <div class="row g-4">
            <!-- Course title -->
            <div class="col-12">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic"  v-model="inputIsDisplay" @click="onChangeIsDisplay">
                    <label v-if="inputIsDisplay" class="form-check-label" for="onoffPublic">공개 </label>
                    <label v-else class="form-check-label" for="onoffPublic">비공개</label>
                </div>
            </div>
            <!-- 
            <div class="col-2">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputOnoff" @click="onChangeOnoff">
                    <label v-if="inputOnoff" class="form-check-label" for="onoffPublic">Online </label>
                    <label v-else class="form-check-label" for="onoffPublic">Offline</label>
                </div>
            </div>
            <div class="col-3">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" role="switch" id="certificatePublic" checked="" v-model="inputCertificate" @click="onChangeCertificate">
                    <label v-if="inputCertificate" class="form-check-label" for="certificatePublic">Certificate </label>
                    <label v-else class="form-check-label" for="certificatePublic">No Certificate</label>
                </div>
            </div> -->

            <div class="col-12">
                <label class="form-label">강좌 타이틀</label>
                <input class="form-control" type="text" placeholder="Enter course title" v-model.trim="inputTitle" >
            </div>

            <!-- Short description -->
            <div class="col-12">
                <label class="form-label">강좌에 대한 짧은 설명</label>
                <textarea class="form-control" rows="5" placeholder="Enter keywords" v-model.trim="inputSubtitle"></textarea>
            </div>

            <!-- Course category -->
            <div class="col-md-6">
                <label class="form-label">강좌 카테고리(선택해주세요)</label>
                <select 
                        class="form-select js-choice border-0 z-index-9 bg-transparent form-control" 
                        aria-label=".form-select-sm" 
                        data-max-item-count="4" 
                        data-search-enabled="true"
                        v-model="inputCategory"
                        @change="onChangeSelectCategory($event)"
                    >
                    <!-- <option value="" selected>----Select category ----</option> -->
                    <option 
                        v-for="opt in categoryList"  
                            :key="opt.id"
                            :value="opt.id" 
                        >
                        {{ opt.title}}
                    </option>                                                        
                </select>
            </div>

            <!-- Course level -->
            <div class="col-md-6">
                <label class="form-label">강좌 레벨(선택해주세요)</label>
                <select 
                        class="form-select js-choice border-0 z-index-9 bg-transparent" 
                        aria-label=".form-select-sm" 
                        data-search-enabled="false" 
                        data-max-item-count="4" 
                        data-remove-item-button="true"
                        v-model="inputLevel"
                        @change="onChangeSelectLevel($event)"
                    >
                    <!-- <option value="" selected>--- Select course level----</option> -->
                    <option :value=0 >All level</option>
                    <option :value=1 >Beginner</option>
                    <option :value=2>Intermediate</option>
                    <option :Value=3>Advance</option>
                </select>
            </div>

            <!-- Language -->
            <!-- <div class="col-md-6">
                <label class="form-label">Language</label>
                <select class="form-select js-choice border-0 z-index-9 bg-transparent" multiple="multiple" aria-label=".form-select-sm" data-max-item-count="3" data-remove-item-button="true">
                    <option value="">Select language</option>
                    <option>English</option>
                    <option>German</option>
                    <option>French</option>
                    <option>Hindi</option>
                </select>
            </div> -->

            <!-- Switch -->
            <!-- <div class="col-md-6 d-flex align-items-center justify-content-start mt-5">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" id="checkPrivacy1">
                    <label class="form-check-label" for="checkPrivacy1">Check this for featured course</label>
                </div>
            </div> -->
            <!-- Total lecture -->
            <div class="col-md-6">
                <label class="form-label">Total Topic 갯수</label>
                <input class="form-control" type="number" placeholder="Enter total lecture" v-model="inputLecturecount" disabled>
            </div>

            <!-- Course time -->
            <div class="col-md-6">
                <label class="form-label">Total Course 시간 (기준 : minutes)</label>
                <input class="form-control" type="number" placeholder="Enter course time" v-model="inputCoursetime" disabled>
            </div>


            <!-- 인원제한  -->
            <!-- 
            <hr/>
            <div class="col-md-12">-- (주의) ------------------------------ 현재 아래는 구현되지 않은 상태임 --------------------------------- </div>
            <div class="col-md-6">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" role="switch" id="amountPublic" checked="" v-model="inputIsAmount" @click="onChangeAmount">
                    <label v-if="inputIsAmount" class="form-check-label" for="amountPublic" >인원제한</label>
                    <label v-else class="form-check-label" for="amountPublic" >인원제한 없음 </label>
                </div>
            </div>
            <div v-if="!inputIsAmount" class="col-md-6"></div> < !--온라인 수강 아래로 내리기 위해서 -- > 
            <div v-if="inputIsAmount" class="col-md-6">
                <label class="form-label">인원제한</label>
                <input class="form-control" type="number" placeholder="Enter 인원제한" v-model="inputAmount">
            </div> 
            -->

            <!-- 온라인시 수강 마감일 여부  -->
            <!-- <hr/>
            <div class="col-md-6">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" role="switch" id="periodPublic" checked="" v-model="inputIsPeriod" @click="onChangePeriod">
                    <label v-if="inputIsPeriod" class="form-check-label" for="periodPublic" >수강기간 있음</label>
                    <label v-else class="form-check-label" for="periodPublic" >수강기간 없음</label>
                </div>
            </div>                                                
            <div v-if="!inputIsPeriod" class="col-md-6"></div>  -->
                <!--수강 자동 마감일 아래로 내리기 위해서 --> 

            <!-- 수강 신청기간 존재여부  -->
            <!-- <div v-if="inputIsPeriod" class="col-md-6">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" role="switch" id="applyPublic" checked="" v-model="inputIsApply" @click="onChangeApply">
                    <label v-if="inputIsApply" class="form-check-label" for="applyPublic" >수강신청기간 있음</label>
                    <label v-else class="form-check-label" for="applyPublic" >수강신청기간 없음</label>
                </div>
            </div> -->

            <!-- <div v-if="inputIsPeriod && inputIsApply" class="col-md-6">
                <label class="form-label">신청기간 시작일</label>
                <input type="datetime-local" class="form-control" placeholder="Enter start" v-model="inputStartapply">
            </div>
            <div v-if="inputIsPeriod && inputIsApply" class="col-md-6">
                <label class="form-label">신청기간 마지막일</label>
                <input type="datetime-local" class="form-control" placeholder="Enter end" v-model="inputEndapply">
            </div>

            <div v-if="inputIsPeriod " class="col-md-6">
                <label class="form-label">수강 시작일</label>
                <input type="datetime-local" class="form-control" placeholder="Enter start" v-model="inputStartdate" >
            </div>
            <div v-if="inputIsPeriod " class="col-md-6">
                <label class="form-label">수강 종료일</label>
                <input type="datetime-local" class="form-control" placeholder="Enter start" v-model="inputEnddate" >
            </div> -->

            <!-- 수강 신청 기간 없는 온라인 학습시 마감일 여부  -->
            <!-- <hr v-if="!inputIsPeriod"/>
            <div v-if="!inputIsPeriod" class="col-md-6">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" role="switch" id="applyPublic" checked="" v-model="inputIsAuto" @click="onChangeAuto">
                    <label v-if="inputIsAuto" class="form-check-label" for="applyPublic" >수강종료일 존재</label>
                    <label v-else class="form-check-label" for="applyPublic" >수강종료일 없음</label>
                </div>
            </div>
            <div v-if="!inputIsPeriod && inputIsAuto" class="col-md-6">
                <div class="form-check form-switch form-check-md">
                    <label class="form-label">수강일 마감일수</label>
                    <input type="number" class="form-control" placeholder="Enter course price" v-model="inputDaycount">
                </div>
            </div> -->

            <!-- 유료/무료 Course price -->
            <!-- <hr/>
            <div class="col-md-12">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" role="switch" id="pricePublic" checked="" v-model="inputIsPrice" @click="onChangePrice">
                    <label v-if="inputIsPrice" class="form-check-label" for="pricePublic" >코스 유료과정</label>
                    <label v-else class="form-check-label" for="pricePublic" >코스 무료과정</label>
                </div>
            </div> -->
            <!-- 유료시 수강 가격  -->
            <!-- <div v-if="inputIsPrice" class="col-md-6">
                <label class="form-label">Course price</label>
                <input type="number" class="form-control" placeholder="Enter course price" v-model="inputPrice">
            </div> -->

            <!-- 할인시 Course discount -->
            <!-- <div v-if="inputIsPrice" class="col-md-6">
                <label class="form-label">Discount price</label>
                <input class="form-control" type="number" placeholder="Enter discount" v-model="inputDiscountprice">
                <div class="col-12 mt-1 mb-0">
                    <div class="form-check small mb-0">
                        <input class="form-check-input" type="checkbox" id="checkBox1" v-model="inputIsDiscount">
                        <label class="form-check-label" for="checkBox1">
                            Enable this Discount
                        </label>
                    </div>
                </div>
            </div> -->

                        
            <!-- Step 1 button -->
            <div class="d-flex justify-content-end mt-3">
                <!-- <button @click="onChangeStep(2)" class="btn btn-primary next-btn mb-0">Save & Next</button> -->
                <button type="submit" class="btn btn-primary next-btn mb-0">Save & Next</button>
            </div>
        </div>
        <!-- Basic information START -->
    </form>
</div>    
</template>

<script>
var filename__ = " [componetns > companydash > CompanyDashCourseIntroUpdateComponent.vue] " ;

import axios from 'axios';

export default {
    components: {},
    props: [
        'id','course', 
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputIsDisplay : this.course.is_display,
            inputOnoff : this.course.onoff, 
            inputCertificate : this.course.is_certificate, 

            inputTitle : this.course.title, 
            inputSubtitle : this.course.subtitle, 

            categoryList: [],

            inputCategory: this.course.category,
            inputLevel : this.course.level, 

            inputCoursetime : this.course.coursetime, 
            inputLecturecount : this.course.lecturecount, 

            inputIsPeriod : this.course.is_period, 
            inputIsApply : this.course.is_apply, 
            inputStartapply :this.course.startapply_datetime, //html input datetime
            inputEndapply : this.course.endapply_datetime, 
            inputStartdate : this.course.startdate_datetime, 
            inputEnddate : this.course.enddate_datetime, 

            inputIsPrice: this.course.is_price,
            inputPrice: this.course.price,
            inputIsDiscount: this.course.is_discount, 
            inputDiscountprice: this.course.discountprice, 

            inputIsAuto : this.course.is_auto, 
            inputDaycount: this.course.daycount,

            inputIsAmount : this.course.is_amount,
            inputAmount: this.course.amount,            
        }
    },
    created(){
        this.getCategoryList();
    },
    computed: {

    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
		onChangeIsDisplay(){
			this.inputIsDisplay = !this.inputIsDisplay ;
		},
		onChangeOnoff(){
			this.inputOnoff = !this.inputOnoff ;
		},
		onChangeCertificate(){
			this.inputCertificate = !this.inputCertificate ;
		},
		onChangePrice(){
			this.inputIsPrice = !this.inputIsPrice ;
		},
		onChangePeriod(){
			this.inputIsPeriod = !this.inputIsPeriod ;
		},
		onChangeApply(){
			this.inputIsApply = !this.inputIsApply ;
		},
		onChangeAmount(){
			this.inputIsAmount = !this.inputIsAmount ;
		},

        onChangeSelectCategory(event){
            // let funcname__ ="onChangeSelectCategory()"
            // console.log(filename__+ ""+funcname__+" value:",event.target.value)
            this.inputCategory = parseInt(event.target.value);
        },  
        onChangeSelectLevel(event){
            // let funcname__ ="onChangeSelectLevel()"
            // console.log(filename__+ ""+funcname__+" onChangeSelectLevel() value:",event.target.value)
            this.inputLevel = parseInt(event.target.value);
        },  
        changeStep(){
            return ;
        },

        async getCategoryList(){
            let funcname__ =" getCategoryList() "
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/course/category/all/companydash',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        title:item.title,
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.categoryList = list;

                // console.log(filename__+''+funcname__+" categoryList:", this.categoryList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },

        async sendStep1UpdateForm(){
            let funcname__ = " sendStep1UpdateForm() " ;

            let dataForm = { 
                'is_display': this.inputIsDisplay,

                'onoff': this.inputOnoff,
                'is_certificate': this.inputCertificate, 

                'title': this.inputTitle,
                'subtitle': this.inputSubtitle,
                'category' : this.inputCategory,
                'level' : this.inputLevel,

                'is_period' : this.inputIsPeriod,
                'is_apply' : this.inputIsApply,
                'startapply' : this.inputStartapply,
                'endapply' : this.inputEndapply,
                'startdate' : this.inputStartdate,
                'enddate' : this.inputEnddate,

                'is_auto' : this.inputIsAuto,
                'daycount' : this.inputDaycount, 

                'is_price' : this.inputIsPrice,
                'price' : this.inputPrice,
                'is_discount' : this.inputIsDiscount,
                'discountprice': this.inputDiscountprice,

                'is_amount' : this.inputIsAmount,
                'amount' : this.inputAmount,

            } ;  

            console.log(filename__+ ""+funcname__+' dataForm:', dataForm);

            if( this.inputTitle === '' || this.inputTitle === undefined || this.inputTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="카테고리를  입력해주세요.";
                return ;
            }
            if( this.inputSubtitle === '' || this.inputSubtitle === undefined || this.inputSubtitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="짧은 설명을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/intro/update/'+this.id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+ ""+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle = "Success" ;
                        this.error = "수정되었습니다.";

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('changestep', 2) ;

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+ ""+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    },
    
}
</script>