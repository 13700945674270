<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>
    
    <teleport to="body">
        <!-- Modal -->
        <!-- 참고자료 : https://getbootstrap.com/docs/5.2/components/modal/ -->
        <div class="modal fade" id="applyModal" tabindex="-1" aria-labelledby="applyModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-success">
                    <h1 class="modal-title fs-5" id="applyModalLabel">수강신청</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 강좌를 수강신청하시겠습니까?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">취소</button>
                    <button type="button" @click="sendForm" class="btn btn-success-soft" data-bs-dismiss="modal">수강신청</button>
                </div>
                </div>
            </div>
        </div>        
    </teleport>
    <teleport to="body">
        <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-danger">
                    <h1 class="modal-title fs-5" id="deleteModalLabel">수강취소</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 강좌를 수강취소하시겠습니까? <br/>
                        수강과 관련된 모든 데이터는 삭제됩니다.

                    </p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">취소</button>
                    <button type="button" @click="removeForm" class="btn btn-danger-soft" data-bs-dismiss="modal">수강취소</button>
                </div>
                </div>
            </div>
        </div>        
    </teleport>

    <div data-sticky data-margin-top="80" data-sticky-for="768">
        <div class="row g-4">
            <div class="col-md-6 col-xl-12">
                <!-- Course info START -->
                <div class="card card-body border p-4">
                    <!-- Price and share button -->
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- Price -->
                        <!-- <h3 class="fw-bold mb-0 me-2">$295.55</h3> -->
                        <!-- Share button with dropdown -->
                        <!-- <div class="dropdown">
                            <a href="#" class="btn btn-sm btn-light rounded mb-0 small" role="button" id="dropdownShare" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fas fa-fw fa-share-alt"></i>
                            </a>
                            <ul class="dropdown-menu dropdown-w-sm dropdown-menu-end min-w-auto shadow rounded" aria-labelledby="dropdownShare">
                                <li><a  class="dropdown-item" href="#"><i class="fab fa-twitter-square me-2"></i>Twitter</a></li>
                                <li><a class="dropdown-item" href="#"><i class="fab fa-facebook-square me-2"></i>Facebook</a></li>
                                <li><a class="dropdown-item" href="#"><i class="fab fa-linkedin me-2"></i>LinkedIn</a></li>
                                <li><a class="dropdown-item" href="#"><i class="fas fa-copy me-2"></i>Copy link</a></li>
                            </ul>
                        </div> -->
                    </div>
                        
                    <!-- Buttons -->
                    <div class="mt-0 d-grid">
                        <!-- <a href="#" class="btn btn-outline-primary">Add to cart</a> -->
                        <router-link :to="backToLink" class="btn btn-secondary">목록보기</router-link>

                        <!-- 로그인 안됐으면 wishlsit 클릭 안됨  -->
                        <button v-if="!isAuthCompu"  type='button'  class="btn btn-outline-danger" disabled>로그인 후 Wish List</button>
                        <!-- 로그인 했으나 이미 wishlsit 신청했으면   -->
                        <button v-else-if="(isAuthCompu && isWishlistCompu)" type='button' @click="onChangeWishlist" class="btn btn-danger" >Wish List 삭제</button>
                        <button v-else-if="(isAuthCompu && !isWishlistCompu)" type='button' @click="onChangeWishlist" class="btn btn-outline-danger" >Wish List 담음</button>

                        <!-- 로그인 안됐으면 수강신청 클릭 안됨  -->
                        <button v-if="!isAuthCompu"  type='button'  class="btn btn-outline-primary" disabled>로그인 후 수강신청</button>
                        <!-- 로그인 했으나 이미 수강신청했으면 수강완료  -->
                        <button v-else-if="(isAuthCompu && isExistCompu)" type='button'  class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#deleteModal" >수강취소</button>
                        <button v-else-if="(isAuthCompu && !isExistCompu)" type='button'  class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#applyModal">수강신청</button>
                    </div>
                    <!-- Divider -->
                    <hr>

                    <!-- Title -->
                    <h5 class="mb-3">This course includes</h5>
                    <ul class="list-group list-group-borderless border-0">
                        <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-book-open text-primary"></i>Lectures</span>
                            <span>{{course.lecturecount}} </span>
                        </li>
                        <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-clock text-primary"></i>Duration</span>
                            <span>{{timeFormat}}</span>
                        </li>
                        <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-signal text-primary"></i>Skills</span>
                            <span v-if="course.level ===0"> All levels</span>
                            <span v-if="course.level ===1"> Beginner</span>
                            <span v-if="course.level ===2"> Intermediate</span>
                            <span v-if="course.level ===3"> Advanced</span>
                        </li>
                        <!-- <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-globe text-primary"></i>Language</span>
                            <span>English</span>
                        </li> -->
                        <!-- <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-user-clock text-primary"></i>Deadline</span>
                            <span>Nov 30 2021</span>
                        </li> -->
                        <!-- <li class="list-group-item px-0 d-flex justify-content-between">
                            <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-medal text-primary"></i>Certificate</span>
                            <span v-if="course.is_certificate">Yes</span>
                            <span v-else>No</span>
                        </li> -->
                    </ul>
                    <!-- Divider -->
                    <hr>

                    <!-- company info -->
                    <!-- <div class="d-sm-flex align-items-center">
                        <div class="avatar avatar-lg">
                            <img v-if="course.create_photo" class="avatar-img rounded-circle" :src="course.create_photo" alt="avatar">
                            <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/05.jpg" alt="avatar">
                        </div>
                        <div class="ms-sm-3 mt-2 mt-sm-0">
                            <h5 class="mb-0"><a href="#">{{course.create_name}}</a></h5>
                        </div>
                    </div> -->
                    <div class="d-sm-flex align-items-center">
                        <div class="avatar avatar-lg">
                            <img v-if="course.company_photo" class="avatar-img rounded-circle" :src="course.company_photo" alt="avatar">
                            <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user.png" alt="avatar">
                        </div>
                        <div class="ms-sm-3 mt-2 mt-sm-0">
                            <h5 class="mb-0">
                                <router-link :to="companyLink">{{course.company_name}}</router-link>
                            </h5>
                            <p class="mb-0 small">{{course.company_web}}</p>
                        </div>
                    </div>

                    <!-- Rating and follow -->
                    <!-- <div class="d-sm-flex justify-content-sm-between align-items-center mt-0 mt-sm-2"> -->
                        <!-- Rating star -->
                        <!-- <ul class="list-inline mb-0">
                            <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                            <li class="list-inline-item me-0 small"><i class="fas fa-star-half-alt text-warning"></i></li>
                            <li class="list-inline-item ms-2 h6 fw-light mb-0">4.5/5.0</li>
                        </ul> -->

                        <!-- button -->
                        <!-- <button class="btn btn-sm btn-primary mb-0 mt-2 mt-sm-0">Follow</button> -->
                    <!-- </div> -->
                </div>
                <!-- Course info END -->
            </div>

            <!-- Tags START -->
            <!-- <div class="col-md-6 col-xl-12">
                <div class="card card-body border p-4">
                    <h4 class="mb-3">Popular Tags</h4>
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item"> <a class="btn btn-outline-light btn-sm" href="#">blog</a> </li>
                        <li class="list-inline-item"> <a class="btn btn-outline-light btn-sm" href="#">business</a> </li>
                        <li class="list-inline-item"> <a class="btn btn-outline-light btn-sm" href="#">theme</a> </li>
                        <li class="list-inline-item"> <a class="btn btn-outline-light btn-sm" href="#">bootstrap</a> </li>
                        <li class="list-inline-item"> <a class="btn btn-outline-light btn-sm" href="#">data science</a> </li>
                        <li class="list-inline-item"> <a class="btn btn-outline-light btn-sm" href="#">web development</a> </li>
                        <li class="list-inline-item"> <a class="btn btn-outline-light btn-sm" href="#">tips</a> </li>
                        <li class="list-inline-item"> <a class="btn btn-outline-light btn-sm" href="#">machine learning</a> </li>
                    </ul>
                </div>	
            </div> -->

            <!-- Tags END -->
        </div><!-- Row End -->
    </div>	

</div>
</template>

<script>
// modal__ teleport__ removeForm__
var filename__ ="[components > course > CourseDetailRightTopComponent.vue] " ;

import axios from 'axios';

export default {
    components: {},
    props: [
        'id','course', 'path',
        'is_exist', 'studentcourse_id',
        'is_wishlist',
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  

            inputCompany_id : this.course.company_id,
            inputIsExist : this.is_exist, 
            inputIsWishlist : null, 
        }
    },
    created(){
    },
    computed: {
        backToLink(){
            if(this.path === 'undefined' || this.path === undefined || this.path === null || this.path ==='/undefined?page=undefined&search=undefined'){
                // 외부에서 유입됐을 경우 
                return '/totalcourses' 
            } else if(this.path === 'totalcourses'){
                return '/totalcourses' ;
            } else {
                return this.path
            }
            
        },
        companyLink(){
            return '/company/'+this.course.company_id+'/'+this.course.company_ownerid
        },
        isAuthCompu(){
            if(this.isAuth()){
                return true ;
            } else {
                return false ;
            }
        },
        isExistCompu(){
            if(this.is_exist){
                return true ;
            } else {
                return false ;
            }
        },
        isWishlistCompu(){
            // let funcname__ ="isWishlistCompu()" ;
            // console.log(filename__+''+funcname__+' is_wishlist:', this.is_wishlist);
            if(this.is_wishlist){
                return true ;
            } else {
                return false ;
            }
        },
        timeFormat(){
            let hour = parseInt(this.course.coursetime/60) ;
            let minute = this.course.coursetime - hour * 60 ;
            if(hour == 0){
                return this.course.coursetime +'분'                
            } else if(minute == 0){
                return hour+'시간'
            } else {
                return hour + '시간 '+minute + '분' ;                
            }
        }

    },
    methods: {
        handleError(){
            this.error = null;
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        onChangeWishlist(){
            if(this.is_wishlist){
                this.removeWishlistForm();
            } else {
                this.sendWishlistForm();
            }
            
        },

        // 수강신청하기 
        async sendForm(){
            let funcname__ ="sendForm()" ;

            let dataForm = { 
                'content_id':this.id,
                'company_id' : this.inputCompany_id, 
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/student/course/create',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    const data = serverData.data ;

                    console.log(filename__+''+funcname__+" data:", data);
                    if(data.status_code == 201){
                                                
                        this.dialogTitle ='Success' ;
                        this.error = '수강 등록에 성공했습니다.';   
                        this.inputStudentCourse_id = data.id ;

                        console.log(filename__+''+funcname__+" inputStudentCourse_id:", this.inputStudentCourse_id);

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('changeIsExist', true)

                            }, 500);
                        }                                               

                    } else if(data.status_code == 500){
                        this.dialogTitle ='Fail' ;
                        this.error = '이미 수강등록된 코스입니다.';   

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },     
        // 수강취소하기 
        async removeForm(){
            let funcname__ ="removeForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/student/course/cancel/'+this.studentcourse_id+'/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    const data = serverData.data ;

                    // console.log(filename__+''+funcname__+" data.code:", data.code);
                    if(data.status_code == 201){
                                                
                        this.dialogTitle ='Success' ;
                        this.error = '수강 취소했습니다.';   

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('changeIsExist',false)

                            }, 500);
                        }                                               

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },     

        // wishlist 
        async sendWishlistForm(){
            let funcname__ ="sendWishlistForm()" ;

            let dataForm = { 
                'content_id':this.id,
                'company_id' : this.inputCompany_id, 
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/student/wishlist/create',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    const data = serverData.data ;

                    // console.log(filename__+''+funcname__+" data.code:", data.code);
                    if(data.status_code == 201){
                                                
                        this.dialogTitle ='Success' ;
                        this.error = 'Wishlist 등록에 성공했습니다.';   

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('changeIsWishlist', true)

                            }, 500);
                        }                                                 

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },     
        // wishlist 삭제   
        async removeWishlistForm(){
            let funcname__ = "removeWishlistForm() " ;

            this.isLoading = true ;
            let dataForm = { 
                'content_id':this.id,
                'company_id' : this.inputCompany_id, 
            } ;  

            console.log(filename__+funcname__+' dataForm :', dataForm);

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/student/wishlist/delete',
                    data: dataForm,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

 
                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+funcname__+"serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        // console.log(filename__+funcname__+' data:', serverData.data);

                        this.dialogTitle ='Success' ;
                        this.error = 'Wishlist 삭제에 성공했습니다.';   
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('changeIsWishlist', false)

                            }, 500);
                        }                                                 

                    } else {
                        console.log(filename__+funcname__+' return err:', serverData.data.msg);

                        this.error = serverData.data.msg;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },     

    },
    
}
</script>