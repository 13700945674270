<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

        <!-- Title -->
        <div class="row mb-3">
            <div class="col-12">
                <h1 class="h3 mb-0">Notifications  </h1>
            </div>
        </div>

        <!-- All review table START -->
        <div class="card card-body bg-transparent pb-0 border mb-4">
            <!-- Search bar -->
            <div class="col-sm-12 mb-2">
                <form class="rounded position-relative" style="display: inline-block;">
                    <input class="form-control bg-transparent" type="search" placeholder="Search" aria-label="Search" v-model.trim="inputSearch" >
                    <button @click="onChangeSearch" class="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="button">
                        <i class="fas fa-search fs-6 "></i>
                    </button>
                </form>
                <button type="button" @click="onChangeSearch" class="btn btn-primary mb-0 ms-2">Search</button>
                <button type="button" @click="onChangeAll" class="btn btn-primary mb-0 ms-2">All</button>
            </div>

            <!-- Table START -->
            <div class="table-responsive border-0">
                <table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
                    <!-- Table head -->
                    <thead>
                        <tr>
                            <th scope="col" class="border-0 rounded-start">#</th>
                            <th scope="col" class="border-0">Receive Name</th>
                            <th scope="col" class="border-0">Message</th>
                            <th scope="col" class="border-0">Date</th>
                            <th scope="col" class="border-0">Status</th>
                            <th scope="col" class="border-0">Send Name</th>
                            <th scope="col" class="border-0 rounded-end">Action</th>
                        </tr>
                    </thead>

                    <!-- Table body START -->
                    <tbody>

                        <!-- Table row -->
                        <admin-notification-list-component v-for="item in itemList"
                            :key = "item.id"
                            :id ="item.id"
                            :num ="item.num"
                            :receive_id = "item.receive_id" 
                            :receive_name = "item.receive_name" 
                            :receive_email = "item.receive_email" 
                            :receive_photo = "item.receive_photo" 

                            :create_id = "item.create_id" 
                            :create_name = "item.create_name" 
                            :create_email = "item.create_email" 
                            :create_photo = "item.create_photo" 

                            :content="item.content"
                            :is_check ="item.is_check"
                            :date ="item.date"
                            :search="inputSearch"
                            :page="page" />

                    </tbody>
                    <!-- Table body END -->
                </table>
            </div>
            <!-- Table END -->

            <!-- Card footer START -->
			<div v-if="itemList.length > 0"> 
				<admin-pagination :pagination="paginationCompu" @paging="pagingSend" ></admin-pagination>
			</div>
            <!-- Card footer END -->
        </div>
        <!-- All review table END -->
        </div>    
</div>
</template>

<script>
var filename__ = "[pages > admin > notification > AdminNotification.vue]" ;

import axios from 'axios';

import AdminNotificationListComponent from '../../../components/admin/notification/AdminNotificationListComponent.vue';
import AdminPagination from '../../../components/admin/ui/AdminPagination.vue';

export default {
    components: {
        "admin-notification-list-component" : AdminNotificationListComponent,
        "admin-pagination": AdminPagination,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList: [], 
            page:1,
            current_path:"/admin/notifications",  //current_path
			pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',     

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    computed: {
        paginationCompu(){
            return this.pagination ;
        },

    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			this.getList();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        onChangeSearch(){
			this.page = 1 ;
            this.getList(); //page=1
        },
        onChangeAll(){
			this.page = 1 ;
			this.inputSearch = '' ;
            this.inputStatus = 0 ;  //status = 10 일 경우만 미처리 함 
            this.getList(); //page=1
        },

        async getList(){
            let funcname__ ="getList() ";

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }

            if( this.inputSearch === 'undefinded' || this.inputSearch === '') { 
                this.inputSearch = '' ; //pagination, search 에서 계속작동하기 위해서                 
            }             

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/notification/all/admin',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
                params: { 
                    page: this.page , 
                    search: this.inputSearch,       
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                }
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log("[AdminNotificationList.vue] getNotificationList() serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log("[AdminNotificationList.vue] getNotificationList() data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log('[AdminNotificationList.vue] profile:', data.profile);
                
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        num: counter--, 
                        id: item.id, 

                        receive_id: item.receive_id,
                        receive_name: item.receive_name,
                        receive_email: item.receive_email,
                        receive_photo : item.receive_photo,
                        
                        create_id: item.create_id,
                        create_name: item.create_name,
                        create_email: item.create_email,
                        create_photo : item.create_photo,

                        content: item.content, 
                        is_check:item.is_check,
                        date: item.date
                    });
                }

                // console.log("[AdminNotificationList.vue] tmpList:", tmpList);
                this.itemList = list;

                // console.log("[AdminNotificationList.vue] itemList:", this.itemList);
                // console.log("[AdminNotificationList.vue] pagination:", this.pagination);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:',serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  
                
            }

            this.isLoading = false ;            
        },


    }
}
</script>

<style scoped>

</style>