<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Page main content START -->
	<div class="page-content-wrapper-match border">

		<!-- Title -->
        <form @submit.prevent="sendForm" >

            <div class="col-12">
                <div class="card bg-transparent border rounded-3 h-100">

                    <!-- Catd header -->
                    <div class="card-header bg-light border-bottom">
                        <h5 class="card-header-title"> 
                            <span v-if="category =='single'">1개 이미지 보이기</span>
                            <span v-else>여러 이미지 보이기</span>
                             이미지 업로드

                        </h5>
                    </div>

                    <!-- Card body START -->
                    <div class="card-body">

                        <div class="row g-4 mb-4">
                            <div class="col-12">
                                <uuidid-fileupload-component
                                    :key="inputUuidid"
                                    :uuidid="inputUuidid" 
                                    :dbtable="'mainslide'" 
                                    :category="'mainslide'" 
                                    :sub-category="category" 
                                    :file-kind="'image'"
                                    :height="200"
                                    :explain="inputFileuploadExplain"     
                                    @emit-fileupload-id="emitFileuploadId"                               
                                    />
                            </div>
                        </div>
                        <div class="row g-4">
                            <div class="col-12 mb-2">
                                <label class="form-label">Title</label>
                                <div class="input-group">
                                    <input type="text" v-model.trim="inputTitle" class="form-control" placeholder="title">
                                </div>
                            </div>
                        </div>
                        <div class="row g-4">
                            <div class="col-12 mb-2">
                                <label class="form-label">Link</label>
                                <div class="input-group">
                                    <input type="text" v-model.trim="inputLink" class="form-control" placeholder="필요시">
                                </div>
                            </div>
                        </div>
                        <div class="row g-4">
                            <div class="col-12 mb-2">
                                <label class="form-label">Description</label>
                                <div class="input-group">
                                    <textarea class="form-control" rows="5" placeholder="필요시" v-model.trim="inputDescription"></textarea>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- Card body END -->
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 d-sm-flex justify-content-between align-items-center">
                    <router-link :to="backToLink" @click="removePhoto"  class="btn btn-secondary mb-0" >Cancel</router-link>
                    <button type="submit" class="btn btn-primary mb-0" >Create</button>
                </div>
            </div>
        </form>


	</div>

</div>
</template>

<script>
var filename__="[pages > admin > mainslide > AdminMainSlideCreate.vue] "

import axios from 'axios';
import { v4 } from 'uuid';  // 참고자료 uuid__ : https://jane-aeiou.tistory.com/59

import UuididFileUploadComponent from '../../../components/fileupload/UuididFileUploadComponent.vue';

export default {
    components: {
        'uuidid-fileupload-component' : UuididFileUploadComponent
    },
    props: ['category'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  
            
            inputUuidid : null, 
            inputFileuploadId: null, 

            inputCategory : this.category,
            inputTitle : null, 
            inputLink : null, 
            inputDescription : null, 

            inputFileuploadExplain :`(최적 이미지 비율 및 크기 1420 x 400)`,        
        }
    },
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.createUuidid();
            // return ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    computed: {
        backToLink(){
            return '/admin/mainslide'; 
        },

    },    
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        createUuidid(){
            // uuid 생성 
            const generate_uuid = () => {
                const tokens = v4().split('-')
                return tokens[2] + tokens[1] + tokens[0] + tokens[3] + tokens[4];
            }
            this.inputUuidid =  generate_uuid() ;
            console.log(filename__+' createUuidid() inputUuidid:', this.inputUuidid);
        },
        emitFileuploadId(_fileupload_id){
            console.log(filename__+ "emitFileuploadId() _fileupload_id:",_fileupload_id)
            this.inputFileuploadId = _fileupload_id;
        },  
        // 전송하기  
        async sendForm(){
            let funcname__ ="sendForm()" ;

            let dataForm = { 
                'uuidid': this.inputUuidid,
                'fileupload_id': this.inputFileuploadId,
                'category':this.inputCategory,
                'title':this.inputTitle,
                'link' : this.inputLink,
                'description' : this.inputDescription,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputFileuploadId === '' || this.inputFileuploadId === undefined || this.inputFileuploadId === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이미지를 업로드 해주세요.";
                return ;
            }

            if( this.inputTitle === '' || this.inputTitle === undefined || this.inputTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="카테고리 타이틀을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/mainslide/create',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '생성에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 
                                this.$router.replace(this.backToLink); 
                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' return err:', serverData.data.error_description);
                        this.dialogTitle ="생성 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        removePhoto(){
            // 이미지 생성되었으면 삭제 
            if(this.inputFileuploadId){
                this.removePhotoForm()
            }            
        },
        async removePhotoForm(){
            let funcname__ ="removeForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/fileupload/delete/'+this.inputFileuploadId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }  

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        // this.dialogTitle ='Success' ;
                        // this.error = '삭제에 성공했습니다.';     

                        this.isApplying = true ;

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 
                                
                                // this.$emit('emitRefreshList', this.category);
                            }, 500);
                        }

                    } else {
                        // console.log(filename__+''+funcname__+' return err:', serverData.data.error_description);

                        this.dialogTitle ="변경 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        
    }
}
</script>

<style scoped>

</style>