<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>


    <!-- Review START -->
    <div class="row mb-4">
        <h5 class="mb-4">Our Student Reviews</h5>

        <!-- Rating info -->
        <div class="col-md-4 mb-3 mb-md-0">
            <div class="text-center">
                <!-- Info -->
                <h2 class="mb-0">{{inputReviewAvg}}/5</h2>
                <!-- Star -->
                <!-- <ul class="list-inline mb-0">
                    <li v-if="course.reviewage > 0 " class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li v-else-if="course.reviewavg > 1 "  class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li v-else-if="course.reviewavg > 2 " class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li v-else-if="course.reviewavg > 3 " class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                    <li v-else-if="course.reviewavg > 4 " class="list-inline-item me-0"><i class="fas fa-star-half-alt text-warning"></i></li>
                </ul> -->
                <!-- <p class="mb-0">(Based on todays review)</p> -->
            </div>
        </div>

        <!-- Progress-bar and star -->        
        <div class="col-md-8">
            <div class="row align-items-center text-center">
                <!-- Progress bar and Rating : 5 : 100% -->
                <div v-if="inputReviewAvg == 5" class="col-6 col-sm-8">
                    <!-- Progress item -->
                    <div class="progress progress-sm bg-warning bg-opacity-15">
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                </div>

                <div v-if="inputReviewAvg == 5" class="col-6 col-sm-4">
                    <!-- Star item -->
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                    </ul>
                </div>

                <!-- Progress bar and Rating : 4 : 80%-->
                <div v-if="inputReviewAvg >= 4 && inputReviewAvg < 5"  class="col-6 col-sm-8">
                    <!-- Progress item -->
                    <div class="progress progress-sm bg-warning bg-opacity-15">
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                </div>

                <div v-if="inputReviewAvg >= 4 && inputReviewAvg < 5" class="col-6 col-sm-4">
                    <!-- Star item -->
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    </ul>
                </div>

                <!-- Progress bar and Rating : 3: 60% -->
                <div v-if="inputReviewAvg >= 3 && inputReviewAvg < 4" class="col-6 col-sm-8">
                    <!-- Progress item -->
                    <div class="progress progress-sm bg-warning bg-opacity-15">
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 60%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                </div>

                <div v-if="inputReviewAvg >= 3 && inputReviewAvg < 4" class="col-6 col-sm-4">
                    <!-- Star item -->
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    </ul>
                </div>

                <!-- Progress bar and Rating : 2 : 40% -->
                <div v-if="inputReviewAvg >= 2 && inputReviewAvg < 3"  class="col-6 col-sm-8">
                    <!-- Progress item -->
                    <div class="progress progress-sm bg-warning bg-opacity-15">
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                </div>

                <div v-if="inputReviewAvg >= 2 && inputReviewAvg < 3"  class="col-6 col-sm-4">
                    <!-- Star item -->
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    </ul>
                </div>

                <!-- Progress bar and Rating : 1 : 10% -->
                <div v-if="inputReviewAvg >= 1 && inputReviewAvg < 2"  class="col-6 col-sm-8">
                    <!-- Progress item -->
                    <div class="progress progress-sm bg-warning bg-opacity-15">
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 20%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                </div>

                <div v-if="inputReviewAvg >= 1 && inputReviewAvg < 2 " class="col-6 col-sm-4">
                    <!-- Star item -->
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    </ul>
                </div>

                <!-- Progress bar and Rating : 0 : 0% -->
                <div v-if="inputReviewAvg < 1"  class="col-6 col-sm-8">
                    <!-- Progress item -->
                    <div class="progress progress-sm bg-warning bg-opacity-15">
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                </div>

                <div v-if="inputReviewAvg < 1" class="col-6 col-sm-4">
                    <!-- Star item -->
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                        <li class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- Review END -->

    <!-- Student review START -->
    <div class="row">
        <!-- Review item START -->

        <!-- Comment item START -->    
        <course-detail-review-list-component 
            v-for="item in itemList"
                :key="item.id"
                :id="item.id"
                :content_id="content_id"

                :is_display="item.is_display"
                :description="item.description"
                :review="item.review"

                :depth="item.depth"
                :seq="item.seq"
                :date="item.date"

                :create_id="item.create_id"
                :create_name="item.create_name"
                :create_photo="item.create_photo"
                @sendRefreshForm="sendRefreshForm"
        /> 

            <!-- Review item END -->

    </div>
    <!-- Student review END -->

    <!-- Leave Review START -->
    <div class="mt-2">
        <h5 class="mb-4">Leave a Review</h5>
        <form @submit.prevent="sendForm" class="row g-3">
            <!-- <div class="col-md-6">
                <input type="text" class="form-control" id="inputtext" placeholder="Name" aria-label="First name">
            </div>
            <div class="col-md-6">
                <input type="email" class="form-control" placeholder="Email" id="inputEmail4">
            </div> -->
            <!-- Rating -->
            <div class="col-12">
                <select id="inputState2" class="form-select  js-choice"
                    v-model="inputReview"
                    @change="onChangeSelect($event)"
                    >
                    <option :value="5" selected="">★★★★★ (5/5)</option>
                    <option :value="4">★★★★☆ (4/5)</option>
                    <option :value="3">★★★☆☆ (3/5)</option>
                    <option :value="2">★★☆☆☆ (2/5)</option>
                    <option :value="1">★☆☆☆☆ (1/5)</option>
                </select>
            </div>
            <!-- Message -->
            <div class="col-12">
                <textarea class="form-control" id="exampleFormControlTextarea1" :placeholder="placeholderCompu" rows="3" v-model.trim="inputDescription" ></textarea>
            </div>
            <!-- Button -->
            <div class="col-12">
                <button v-if="isAuth && !inputIsWrite" type="submit" class="btn btn-primary mb-0">Post Review</button>
                <button v-else type="button " disabled class="btn btn-primary mb-0">Post Review</button>
            </div>
        </form>
    </div>
    <!-- Leave Review END -->      
</div>


</template>

<script>
//  select__
var filename__ ="[components > course > CourseDetailReviewPanelComponent.vue] " ;

import axios from 'axios';

import CourseDetailReviewListComponent from './CourseDetailReviewListComponent.vue';

export default {
    components: {
        "course-detail-review-list-component": CourseDetailReviewListComponent,
    },
    props: [ 'content_id','course' ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList : [],
            inputReviewAvg : this.course.review_avg, 
            inputReview : 5, 
            inputDescription : null, 

            inputIsWrite : false, //현재 글쓴여부 
        }
    },
    created(){
        // let funcname__ ="created() "
        // console.log(filename__+funcname__+'course_id:', this.course_id, ' course:', this.course)
        this.getList();
    },    
    
    computed: {
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        name(){
            return this.$store.getters['auth/name'];
        },
        email(){
            return this.$store.getters['auth/email'];
        },
        photo(){
            return this.$store.getters['auth/photo'];
        },
        placeholderCompu(){
            if(this.inputIsWrite){
                return '이미 리뷰를 달았습니다.';
            } else {
                return 'Your review'
            }
        }

    },
    methods: {
        handleError(){
            this.error = null;
        },

        onChangeSelect(event){
            // let funcname__ ="onChangeSelect()"
            // console.log(filename__+ ""+funcname__+" value:",event.target.value)
            this.inputReview = event.target.value;
        },  
        isUserId(){
            return this.$store.getters['auth/userId'] ;
        },

        // Component 답변이나 수정, 삭제 후 refresh list
        sendRefreshForm(_reviewavg){
            this.inputReviewAvg = _reviewavg ;
            this.getList();
        },
        async getList(){
            let funcname__ ="getList() " ;
            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: `/api/comment/review/all/user/${this.content_id}`,

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            // console.log(filename__+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+funcname__+'  response:', data.response);
                    
                    let tmpList = data.response.data ;
                    let list = []; 

                    //메인 카테고리 값들을 입력하기 
                    this.inputIsWrite = false ;
                    for(let item of tmpList ){
                        list.push({
                            id: item.id, 

                            is_display: item.is_display,
                            description: item.description,
                            depth:item.depth,
                            seq : item.seq,
                            review : item.review,

                            create_id: item.create_id,
                            create_photo: item.create_photo,
                            create_name: item.create_name,

                            date: item.date, 
                            update: item.update,
                            
                        });
                        if(item.create_id == this.isUserId()){
                            this.inputIsWrite = true ; //글을 이미 썻으면 
                        }
                    }

                    // console.log(filename__+funcname__+" list:", list);
                    this.itemList = list;

                    // console.log(filename__+funcname__+" this.itemList:", this.itemList);

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;
                }
            }

            this.isLoading = false ;     
        },

        // comment 입력   
        async sendForm(){
            let funcname__ = "sendForm() "
            let dataForm = { 
                'company_id': this.course.company_id,
                'content_id': this.content_id,
                'category': 'review',
                'review': this.inputReview,
                'description': this.inputDescription,
            } ;  

            // console.log(filename__+funcname__+' dataForm:', dataForm);

            if( this.inputReview === '' || this.inputReview === undefined || this.inputReview === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="별점을 선택해주세요.";
                return ;
            }
            if( this.inputDescription === '' || this.inputDescription === undefined || this.inputDescription === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/comment/review/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;

                } else {
                    // console.log(filename__+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+funcname__+'  data:', data);

                        if(data.status_code === 201){
                                                        
                            this.inputReview = 5 ;
                            this.inputDescription = null ;
                            this.inputReviewAvg = data.response ;

                            this.dialogTitle ='Success' ;
                            this.error = '입력에 성공했습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.getList();

                                }, 500);
                            }                        


                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    },
    
}
</script>