<template>
<div>

	<nav class="navbar top-bar navbar-light border-bottom py-0 py-xl-3">
		<div class="container-fluid p-0">
			<div class="d-flex align-items-center w-100">

				<!-- Logo START -->
				<div class="d-flex align-items-center d-xl-none">
					<a href="#" @click="moveToHome" class="navbar-brand" >
						<img class="light-mode-item navbar-brand-item h-30px" src="/assets/images/logo-mobile.svg" alt="">
						<img class="dark-mode-item navbar-brand-item h-30px" src="/assets/images/logo-mobile-light.svg" alt="">
					</a>
				</div>
				<!-- Logo END -->

				<!-- Toggler for sidebar START -->
				<div class="navbar-expand-xl sidebar-offcanvas-menu">
					<button class="navbar-toggler me-auto" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar" aria-expanded="false" aria-label="Toggle navigation" data-bs-auto-close="outside">
						<i class="bi bi-text-right fa-fw h2 lh-0 mb-0 rtl-flip" data-bs-target="#offcanvasMenu"> </i>
					</button>
				</div>
				<!-- Toggler for sidebar END -->

				<!-- Top bar left -->
				<div class="navbar-expand-lg ms-auto ms-xl-0">

					<!-- Toggler for menubar START -->
					<button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTopContent" aria-controls="navbarTopContent" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-animation">
							<span></span>
							<span></span>
							<span></span>
						</span>
					</button>
					<!-- Toggler for menubar END -->

					<!-- Topbar menu START -->
					<div class="collapse navbar-collapse w-100" id="navbarTopContent">
						<!-- Top search START -->
						<!-- <div class="nav my-3 my-xl-0 flex-nowrap align-items-center">
							<div class="nav-item w-100">
								<form class="position-relative">
									<input class="form-control pe-5 bg-secondary bg-opacity-10 border-0" type="search" placeholder="Search" aria-label="Search">
									<button class="bg-transparent px-2 py-0 border-0 position-absolute top-50 end-0 translate-middle-y" type="submit"><i class="fas fa-search fs-6 text-primary"></i></button>
								</form>
							</div>
						</div> -->
						<!-- Top search END -->
					</div>
					<!-- Topbar menu END -->
				</div>
				<!-- Top bar left END -->

				<!-- Top bar right START -->
				<div class="ms-xl-auto">
					<ul class="navbar-nav flex-row align-items-center">

						<!-- Notification dropdown START -->
						
						<li class="nav-item ms-2 ms-md-3 dropdown">
							<a class="btn btn-light btn-round mb-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
								<i class="bi bi-bell fa-fw"></i>
							</a>							
							<admin-top-notification-panel-component />

						</li>
						<!-- Notification dropdown END -->

						<!-- Profile dropdown START -->
						<li class="nav-item ms-2 ms-md-3 dropdown">
							<!-- Avatar -->
							<a class="avatar avatar-sm p-0" href="#" id="profileDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
								<img v-if="photo" class="avatar-img rounded-circle" :src="photo" alt="avatar">
								<img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user.png" alt="avatar">
							</a>

							<!-- Profile dropdown START -->
							<ul class="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3" aria-labelledby="profileDropdown">
								
								<li class="px-3">
									<div class="d-flex align-items-center">

										<div class="avatar me-3 mb-3">
											<img v-if="photo"  class="avatar-img rounded-circle shadow" :src="photo" alt="avatar">
											<img v-else class="avatar-img rounded-circle shadow" src="/assets/images/avatar/user.png" alt="avatar">
										</div>

										<div>
											<router-link to="/admin/company/detail" class="h6 mt-2 mt-sm-0" >{{name}}</router-link>
											<p class="small m-0">{{email}}</p>
										</div>
									</div>
								</li>
								<li> <hr class="dropdown-divider"></li>

								<li><router-link to="/studentdash/photo/update" class="dropdown-item" ><i class="bi bi-person-bounding-box fa-fw me-2"></i>프로필 사진수정</router-link></li>
								<li><router-link to="/studentdash/profile/update" class="dropdown-item" ><i class="bi bi-person fa-fw me-2"></i>프로필 정보수정</router-link></li>
								<li><router-link to="/studentdash/profile/pwupdate" class="dropdown-item" ><i class="bi bi-key fa-fw me-2"></i>비밀번호 변경</router-link></li>
								<li><router-link to="/" class="dropdown-item" ><i class="bi bi-house-door fa-fw me-2"></i>Home</router-link></li>
								<li> <hr class="dropdown-divider"></li>
								<li><button type="button" @click="logout" class="dropdown-item bg-danger-soft-hover" ><i class="bi bi-power fa-fw me-2"></i>로그아웃</button></li>

							</ul>
							<!-- Profile dropdown END -->
						</li>
						<!-- Profile dropdown END -->
					</ul>
				</div>
				<!-- Top bar right END -->
			</div>
		</div>
	</nav>

</div>
</template>

<script>
import AdminTopNotificationPanelComponent from '../admin/notification/AdminTopNotificationPanelComponent.vue'

export default {
    components: {
		"admin-top-notification-panel-component" : AdminTopNotificationPanelComponent,
    },
    props: [],
    data() {
        return {
        }
    },
    computed: {
		isLoggedIn(){
			return this.$store.getters['auth/isAuthenticated'];
		},
		isAdmin(){
			// console.log("[TheHeader.vue] computed : isAdmin():", this.$store.getters['auth/isAdmin'])
			return this.$store.getters['auth/isAdmin'];
		},
		name(){
			// console.log("[AdminTopHeader.vue] computed : name():", this.$store.getters['auth/name'])
			return this.$store.getters['auth/name'];
		},
		email(){
			return this.$store.getters['auth/email'];
		},
		photo(){
			// console.log("[AdminTopHeader.vue] computed : photo():", this.$store.getters['auth/photo'])
			return this.$store.getters['auth/photo'];
		},
    },
    mounted() {

    },
    methods: {
		logout(){
			this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
			this.$router.replace('/');
		},
      moveToHome(){
        this.$store.dispatch('auth/outPageAdmin'); //==> store/modules/auth/actions.js
        this.$router.replace('/');
      }

    },
}
</script>

<style scoped>

</style>
