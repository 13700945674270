// import axios from "axios";

// var filename__="[store/dashboard/actions.js]" ;

export default {
 
    //Mypage
    async Mypage(context, payload){
        // console.log('[store/dashboard/actions.js_mypage()] ');
        context.commit('setMypage', payload);  // mutations.js


        // const response = await axios( {method: 'post', url:'/login', data: data });
        // console.log('[store/userauth/actions.js_login()] response:', response);

        // const responseData = await response.data ;

        // if(response.status === 200){            
        //     console.log('[store/userauth/actions.js_login()] responseData:', responseData);
            
        //     context.commit('setUser', {
        //         token: responseData['access_token'],  
        //         userId : responseData['userid']              
        //     });  // mutations.js

        // } else {
        //     console.log('[store/userauth/actions.js_login()]', responseData.message);

        //     const error = new Error(responseData.message || 'Failed to authenticate.');
        //     console.error("error ==> [store/auth/actions.js_signup()] : \n", error);
            
        //     throw error ;            
        // }


    },       
    //instructor dashboard 
    async companyDashboard(context, payload){        
        // console.log('[store/dashboard/actions.js] instructorDashboard()] ');
        context.commit('setCompanyDashboard', payload);  // mutations.js
    },       

    // 코스별 공동작업자 등록여부 확인 
    async isCourseWorkerDashboard(context, payload){
        // let funcname__ =" instructorDashboard() " ;
        // console.log(filename__+funcname__+'');

        context.commit('setIsCourseWorkerDashboard', payload);  // mutations.js
    },       
    
    //Logout
    async Remove(context){
        // let funcname__ =" Remove() " ;
        // console.log(filename__+funcname__+'');

        localStorage.removeItem("company_dashboard");
        localStorage.removeItem("is_courseworker_dashboard");

        context.commit('setCompanyDashboard', {
            company_dashboard: null,
        }); //=> mutations.js

        context.commit('setIsCourseWorkerDashboard', {
            is_courseworker_dashboard: null,
        }); //=> mutations.js


    },        

}