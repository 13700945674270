<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

	<main>

<!-- =======================
Page Banner START -->
<section class="bg-blue align-items-center d-flex" style="background:url(/assets/images/pattern/04.png) no-repeat center center; background-size:cover;">
	<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<!-- Title -->
				<h1 class="text-white">공동작업자 Courses</h1>
				<!-- Breadcrumb -->
				<div class="d-flex justify-content-center">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb breadcrumb-dark breadcrumb-dots mb-0">
							<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
							<li class="breadcrumb-item"><a href="#">코스별 공동작업자 </a></li>
							<li class="breadcrumb-item active" aria-current="page">전체 작업내역</li>
						</ol>
					</nav>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- =======================
Page Banner END -->

<!-- =======================
Page content START -->
<section class="py-5">
<div class="container">
	<div class="row">
		<!-- Main content START -->
		<div class="col-lg-12 col-xl-12">

			<!-- Search option START -->
			<div class="row mb-4 align-items-center">

				<!-- Search bar -->
				<!-- <div class="col-xl-12 ">
					<div class="card card-body shadow">
						<form @submit.prevent="onChangeSearch" class="border rounded">
							<div class="input-group input-borderless">
								<input class="form-control me-1" type="search" placeholder="내 과정 찾기" v-model.trim="inputSearch">
								<button type="submit" class="btn btn-primary mb-0 rounded z-index-1"><i class="fas fa-search"></i></button>
							</div>
						</form>
					</div>

				</div> -->

				<!-- Select option -->
				<!-- <div class="col-xl-3 mt-3 mt-xl-0">
					<form class="border rounded p-2 input-borderless">
						<select class="form-select form-select-sm js-choice border-0" aria-label=".form-select-sm">
							<option value="">전체 보기</option>
							<option>최근 검색</option>
							<option>최강 인기</option>
							<option>최다 비율</option>
						</select>
					</form>
				</div> -->

				<!-- Content -->
				<!-- <div class="col-12 col-xl-3 d-flex justify-content-between align-items-center mt-3 mt-xl-0">
					<button class="btn btn-primary mb-0 d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
						<i class="fas fa-sliders-h me-1"></i> 필터링 하기
					</button>
					<p class="mb-0 text-end">총 30개 중 9개</p>
				</div> -->

			</div>
			<!-- Search option END -->

			<!-- Course Grid START -->
			<div class="row g-4">

				<editcourse-list-component v-for="item in itemList"
					:key = "item.id"
					:id ="item.id"
					:num ="item.num"
					:content_id ="item.content_id"

					:content_category="item.content_category"
					:title ="item.title"
					:subtitle="item.subtitle"

					:level ="item.level"
					:coursetime ="item.coursetime"
					:lecturecount ="item.lecturecount"

					:image ="item.image"
					:is_text = "item.is_text"

					:date ="item.date"
					:update ="item.update"
					:is_display="item.is_display"
					:reviewavg="item.reviewavg"
					:categorystr="item.categorystr"

					:page="page" 
					
					/>
								


			</div>
			<!-- Course Grid END -->

			<!-- Pagination START -->
			<div class="col-12">
				<div v-if="itemList.length > 0"> 
					<user-pagination :pagination="paginationCompu" @paging="pagingSend" ></user-pagination>
				</div>

				<!-- <nav class="mt-4 d-flex justify-content-center" aria-label="navigation">
					<ul class="pagination pagination-primary-soft d-inline-block d-md-flex rounded mb-0">
						<li class="page-item mb-0"><a class="page-link" href="#" tabindex="-1"><i class="fas fa-angle-double-left"></i></a></li>
						<li class="page-item mb-0"><a class="page-link" href="#">1</a></li>
						<li class="page-item mb-0 active" aria-current="page"><a class="page-link" href="#">2</a></li>
						<li class="page-item mb-0"><a class="page-link" href="#">..</a></li>
						<li class="page-item mb-0"><a class="page-link" href="#">6</a></li>
						<li class="page-item mb-0"><a class="page-link" href="#"><i class="fas fa-angle-double-right"></i></a></li>
					</ul>
				</nav> -->
			</div>
			<!-- Pagination END -->
		</div>
		<!-- Main content END -->

	</div><!-- Row END -->
</div>
</section>
<!-- =======================
Page content END -->


</main>

    <hr/>
    </div>
</template>

<script>
var filename__= "[pages > courseedit > EditCourseList.vue] "

import axios from 'axios';

import EditCourseListComponent from '../../components/courseedit/EditCourseListComponent.vue';
import UserPagination from '../../components/ui/UserPagination.vue';

export default {
    components: {
        "editcourse-list-component": EditCourseListComponent , 
		"user-pagination": UserPagination
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList: [], 
            page:1,
            current_path:"/editcourses",  //current_path
			pagination: null, 

            inputPagePerCount : 20,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',
			
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); 
    },     
    computed: {
        paginationCompu(){
            return this.pagination ;
        },
    },    	
    created(){
		this.getList()
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        onChangeSearch(){
			this.page = 1 ;
            this.getList(); //page=1
        },

		async getList(){
			let funcname__ ="getList()" ;

			this.isLoading = true ;   

			let serverData = await axios({
				method: 'get',
				url: '/api/workercourse/workeredit/all',
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},           
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
					level: this.inputLevel,    
                    search: this.inputSearch,   

                }

			}).catch( err => {

				if(err.response.status === 401) {  
					this.dialogTitle ="Login" ;
					this.error = "로그인이 필요한 페이지입니다.";          

					// 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
					this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
									
				} else {
					let dataForm = { 
						category: 'errorlog(api catech)' , 
						filename : filename__,
						methodname : funcname__,
						title: 'Error(API)',       
						content : ''+err,
					} ;  
					console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

					axios({
						method: 'post',
						url: '/api/errorlog/create',
						data: dataForm,
					});

					this.dialogTitle ="Error" ;
					this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
					
					this.isLoading = false ;

					return ;
				}

			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			// console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				// console.log('[AdminCourseCategoryList.vue] profile:', data.profile);
				
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];

				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					list.push({
						num: counter--, 
						id: item.id, 
						content_id : item.content_id,

						content_category: item.content_category,
						title: item.course_title,
						subtitle: item.course_subtitle,

						level : item.course_level,
                        coursetime : item.coursetime,
                        lecturecount : item.lecturecount,
						reviewavg : item.reviewavg,
						categorystr: item.categorystr,

                        image : item.course_image,
						is_text: item.is_fronttext,
                        
					});
				}

				// console.log(filename__+''+funcname__+" tmpList:", tmpList);
				this.itemList = list;

				// console.log(filename__+''+funcname__+" itemList:", this.itemList);
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);

			} else {
				console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
				this.dialogTitle ="Error" ;
				this.error = serverData.data.error_description;
			}

			this.isLoading = false ;            
		},


    }
}
</script>

<style scoped>

</style>
