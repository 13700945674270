<template>
<div class="overflow-hidden mb-4">
    <h6 class="uppercase">{{name}}</h6>
    <div class="progress progress-sm bg-opacity-10" :class="returnBgColorCompu">
        <div role="progressbar" data-aos="slide-right" data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-out" 
            :class="returnProgressColorCompu" 
            :style="'width: '+percent+'%;'" 
            aria-valuenow="${percent}" aria-valuemin="0" aria-valuemax="100"
            >
            <span class="progress-percent-simple h6 mb-0">{{percent}}%</span>
        </div>
    </div>
</div>  
</template>

<script>

export default {
    components: {
    },
    props: [
        'id', 'count',
        'name','percent'
    ],

    data() {
        return {
            error: null,     
            dialogTitle: null,  

        }
    },
    
    computed: {
        returnBgColorCompu(){
            if(this.count%4 ==0 ) return "bg-primary" ;
            else if (this.count%4 ==1 ) return "bg-success" ;
            else if (this.count%4 ==2 ) return "bg-warning" ;
            else if (this.count%4 ==3 ) return "bg-danger" ;
            else return "bg-primary" ;
        },
        returnProgressColorCompu(){
            if(this.count%4 ==0 ) return "progress-bar bg-primary aos aos-init aos-animate" ;
            else if (this.count%4 ==1 ) return "progress-bar bg-success aos aos-init aos-animate" ;
            else if (this.count%4 ==2 ) return "progress-bar bg-warning aos aos-init aos-animate" ;
            else if (this.count%4 ==3 ) return "progress-bar bg-danger aos aos-init aos-animate" ;
            else return "progress-bar bg-success aos aos-init aos-animate" ;
        }
        // detailLink(){
        //     return '/instructor/'+this.id
        // },
        // contentSplice(){
        //     return this.description.splice(0,30);
        // },
    },
    methods: {
        handleError(){
            this.error = null;
        },
    },
    
}
</script>