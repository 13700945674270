<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

        <!-- Title -->
		<div class="row g-3 align-items-center justify-content-between">
			<div class="col-md-6">
				<h1 class="h3 mb-0">
                    자주하는 질문 내역
                </h1>
			</div>
            <div class="col-md-6 text-end">
                <router-link :to="createLink" class="btn btn-primary">질문 생성</router-link>
            </div>
		</div>

        <!-- All review table START -->
        <div class="card card-body bg-transparent pb-0 border mb-4">

            <!-- Table START -->            
            <div class="table-responsive border-0">
                <table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
                    <!-- Table head -->
                    <thead>
                        <tr>
                            <th scope="col" class="border-0 rounded-start">#</th>
                            <th scope="col" class="border-0">자주하는 질문 내역</th>
                            <th scope="col" class="border-0">보임/숨김</th>
                            <th scope="col" class="border-0">Up/Down</th>
                            <th scope="col" class="border-0 rounded-end">Action</th>
                        </tr>
                    </thead>

                    <!-- Table body START -->
                    <tbody>

                        <!-- Table row -->
                        <admin-faq-frequent-list-component v-for="item in itemList"
                            :key = "item.id"
                            :num="item.num"
                            :id ="item.id"
                            :title ="item.title"
                            :content ="item.content"
                            :is_display="item.is_display"
                            :seq ="item.seq"
                            :min ="item.min"
                            :max ="item.max"
                            @changeUpSeq='sendChageUpSeqForm(item.id)'
                            @changeDownSeq='sendChageDownSeqForm(item.id)'                                                    
                            @changeDisplay='sendChageDisplayForm'                                                    

                        />

                    </tbody>
                    <!-- Table body END -->
                </table>
            </div>
            <!-- Table END -->

        </div>
        <!-- All review table END -->
    </div>    
</div>
</template>

<script>
// watch__
var filename__="[pages > admin > faq > AdminFaqFrequentList.vue] "

import axios from 'axios';

import AdminFaqFrequentListComponent from '../../../components/admin/faq/AdminFaqFrequentListComponent.vue';

export default {
    components: {
        "admin-faq-frequent-list-component" : AdminFaqFrequentListComponent,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList: [], 
            page:1,
            current_path:"/admin/faqfrequents",  //current_path
			pagination: null, 

            inputPagePerCount : 20,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',     
			inputCategory : this.category, //처음에 입력해줘야 됨

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    computed: {
        createLink(){
            return '/admin/faqfrequent/create' ;
        },

    },    
    created(){		      
        this.getList();
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
            return this.$store.getters['auth/isAdmin'] ;
        },
        

        async getList(){
            let funcname__ ="getList()" ;

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/faq/frequent/all/admin',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log('[AdminCourseCategoryList.vue] profile:', data.profile);
                
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        num: counter--, 
                        id: item.id, 
                        title:item.title,
                        content:item.content,
                        seq:item.seq,
                        min: item.min,
                        max: item.max,
                        is_display : item.is_display,
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.itemList = list;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  // this.error = err.msg || 'Failed to authenticate';                
                // alert('error')
            }

            this.isLoading = false ;            
        },

        // 순서 변경 : 위로 올리기         
        async sendChageUpSeqForm(fromId){
            let funcname__ ="sendChageUpSeqForm()" ;

            this.isSendLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/faq/frequent/upseq/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    this.dialogTitle ='Success' ;
                    this.error = '변경에 성공했습니다.';

                    // move                     
                    // this.$router.replace('/admin/open/infos'); 

                } else {
                    console.log(filename__+''+funcname__+' err:', serverData.data.msg);
                    this.dialogTitle ="변경 실패" ;

                    // this.error = err.msg || 'Failed to authenticate';
                    this.error = serverData.data.msg;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="내용 변경 실패" ;
                this.error = err; // this.error = err.msg || 'Failed to authenticate';
            }

            this.isLoading = false ;
            this.getList();

        },   
        
        // 순서 변경 : 아래로 내리기         
        async sendChageDownSeqForm(fromId){
            let funcname__ ="sendChageDownSeqForm()" ;

            this.isSendLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/faq/frequent/downseq/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    this.dialogTitle ='Success' ;
                    this.error = '변경에 성공했습니다.';

                    // move                     
                    // this.$router.replace('/admin/open/infos'); 

                } else {
                    console.log(filename__+''+funcname__+' err:', serverData.data.msg);
                    this.dialogTitle ="내용 변경 실패" ;

                    // this.error = err.msg || 'Failed to authenticate';
                    this.error = serverData.data.msg;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="내용 변경 실패" ;
                this.error = err; // this.error = err.msg || 'Failed to authenticate';
            }

            this.isLoading = false ;
            this.getList();

        },  
        // 순서 변경 : 아래로 내리기         
        async sendChageDisplayForm(fromId, fromIsDisplay){
            let funcname__ ="sendChageDisplayForm()" ;

            let dataForm = { 
                'is_display': fromIsDisplay,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isSendLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/faq/frequent/display/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    this.dialogTitle ='Success' ;
                    this.error = '변경에 성공했습니다.';

                    // move                     
                    // this.$router.replace('/admin/open/infos'); 

                } else {
                    console.log(filename__+''+funcname__+' err:', serverData.data.msg);
                    this.dialogTitle ="내용 변경 실패" ;

                    // this.error = err.msg || 'Failed to authenticate';
                    this.error = serverData.data.msg;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="내용 변경 실패" ;
                this.error = err; // this.error = err.msg || 'Failed to authenticate';
            }

            this.isLoading = false ;
            this.getList();

        },  

    }
}
</script>

<style scoped>

</style>