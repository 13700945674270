<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

	<!-- Page main content START -->
	<div class="page-content-wrapper-match border">

		<!-- Title -->
		<div class="row g-3 align-items-center justify-content-between">
			<div class="col-md-6">
				<h1 class="h3 mb-2 mb-sm-0">파트너 등록 요청 상세</h1>
			</div>
            <div class="col-md-6 text-end">
                <router-link :to="backToLink" class="btn btn-sm btn-secondary mb-0 me-2">목록</router-link>
                <router-link :to="statusLink" class="btn btn-sm btn-success mb-0 me-2">상태변경</router-link>
                <router-link :to="replyLink" class="btn btn-sm btn-primary mb-0 me-2">답변하기</router-link>
                <router-link v-if="isDashboardCompu" :to="createDashboardLink" class="btn btn-sm btn-danger mb-0">Dashboard 생성</router-link>
            </div>
		</div>

        <admin-company-request-detail-component :id="id" :create_id="create_id" />
	</div>

    <!-- 대시보드 생성 표시  -->
    <div v-if="inputIsDashboard" class="page-content-wrapper-danger border alert alert-danger" role="alert">
        파트너 대시보드가 생성되었습니다.
    </div>        

    <div>
        <admin-company-request-reply-panel-component :id="id" :create_id="create_id"  />
    </div>

    
</div>
</template>

<script>
var filename__ = "[pages > admin > company > AdminCompanyRequestDetail.vue]" ;
import axios from 'axios';

import AdminCompanyRequestDetailComponent from '../../../components/admin/company/AdminCompanyRequestDetailComponent.vue';
import AdminCompanyRequestReplyPanelComponent from '../../../components/admin/company/AdminCompanyRequestReplyPanelComponent.vue';

export default {
    components: {
        "admin-company-request-detail-component" : AdminCompanyRequestDetailComponent,
        "admin-company-request-reply-panel-component" : AdminCompanyRequestReplyPanelComponent,
    },
    props: ['id','create_id','parent_id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,      
            
            inputIsDashboard: false, //대시보드 생성여부 
            inputStatus : null, 
            
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			this.getItem();
            this.getCompanyIsDashboard(); 
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    computed: {
        backToLink(){
            return '/admin/company/requests?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },
        statusLink(){
            return '/admin/company/request/status/update/'+this.id+'/'+this.create_id+'?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },
        replyLink(){
            return '/admin/company/request/reply/create/'+this.id+'/'+this.create_id+'?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },
        createDashboardLink(){
            // company 만들기 
            return '/admin/company/create/'+this.id+'/'+this.create_id+'?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },
        isDashboardCompu(){
            return !this.inputIsDashboard && (this.inputStatus == 9) ;
        }

    },    
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },

        // 대시보드 생성 여부 확인 
        async getCompanyIsDashboard(){
            let funcname__ ="getCompanyIsDashboard() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/company/is/'+this.create_id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log("[AdminCompanyRequestDetail.vue] getCompanyIsDashboard() serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log("[AdminCompanyRequestDetail.vue] getCompanyIsDashboard() data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                if(data.is_exist){
                    this.inputIsDashboard = true ;
                } else {
                    this.inputIsDashboard = false ;
                }

                // console.log("[AdminCompanyRequestDetail.vue] inputIsDashboard:", this.inputIsDashboard);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description; 
            }

            this.isLoading = false ;            
        },

        // status 확인하기 위해서 
        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/company/request/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {


                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+''+funcname__+' response:', data.response);
                    const request = data.response

                    this.inputStatus = request.status ;

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description; 
                }
            }

            this.isLoading = false ;     
        },


        
    }
}
</script>

<style scoped>

</style>