<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>

<!-- =======================
Page content START -->
<section class="pt-3 pt-xl-5">
	<div class="container" data-sticky-container>
		<div class="row g-4">
			
			<!-- Main content START -->
			<div class="col-xl-12">

				<div class="row g-4">
                    <!-- Main content START -->
                    <div class="col-xl-12">
                    
                    <div class="card bg-transparent border rounded-3 mb-5">
                        <div id="stepper" class="bs-stepper stepper-outline">
                            <!-- Card header -->
                            <div class="card-header bg-light border-bottom px-lg-5">
                                <!-- Step Buttons START -->
                                <div class="bs-stepper-header" role="tablist">
                                    <!-- Step 1 -->
                                    <div class="step"  >
                                        <div class="d-grid text-center align-items-center">
                                            <router-link :to="backToLink" class="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger0" aria-controls="step-0">
                                                <span class="bs-stepper-circle">Back</span>
                                            </router-link>
                                            <h6 class="bs-stepper-label d-none d-md-block">Back to Detail</h6>
                                        </div>
                                    </div>
                                    <div class="line"></div>

                                    <div class="step" :class="inputStep == 1 ? 'active':''" data-target="#step-1">
                                        <div class="d-grid text-center align-items-center">
                                            <button type="button" @click="onChangeStep(1)" class="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger1" aria-controls="step-1">
                                                <span class="bs-stepper-circle">1</span>
                                            </button>
                                            <h6 class="bs-stepper-label d-none d-md-block">Course details</h6>
                                        </div>
                                    </div>
                                    <div class="line"></div>
            
                                    <!-- Step 2 -->
                                    <div class="step" :class="inputStep == 2 ? 'active':''" data-target="#step-2">
                                        <div class="d-grid text-center align-items-center">
                                            <button type="button" @click="onChangeStep(2)" class="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger2" aria-controls="step-2">
                                                <span class="bs-stepper-circle">2</span>
                                            </button>
                                            <h6 class="bs-stepper-label d-none d-md-block">Description</h6>
                                        </div>
                                    </div>
                                    <div class="line"></div>

                                    <!-- Step 3 -->
                                    <div class="step" :class="inputStep == 3 ? 'active':''" data-target="#step-3">
                                        <div class="d-grid text-center align-items-center">
                                            <button type="button" @click="onChangeStep(3)" class="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger3" aria-controls="step-3">
                                                <span class="bs-stepper-circle">3</span>
                                            </button>
                                            <h6 class="bs-stepper-label d-none d-md-block">Media</h6>
                                        </div>
                                    </div>
                                    <div class="line"></div>
            
                                    <!-- Step 4 -->
                                    <div class="step" :class="inputStep == 4 ? 'active':''" data-target="#step-4">
                                        <div class="d-grid text-center align-items-center">
                                            <button  type="button" @click="onChangeStep(4)" class="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger4" aria-controls="step-4">
                                                <span class="bs-stepper-circle">4</span>
                                            </button>
                                            <h6 class="bs-stepper-label d-none d-md-block">Curriculum</h6>
                                        </div>
                                    </div>
                                    <div class="line"></div>
            
                                    <!-- Step 5 -->
                                    <div class="step" :class="inputStep == 5 ? 'active':''" data-target="#step-5">
                                        <div class="d-grid text-center align-items-center">
                                            <button type="button" @click="onChangeStep(5)" class="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger5" aria-controls="step-5">
                                                <span class="bs-stepper-circle">5</span>
                                            </button>
                                            <h6 class="bs-stepper-label d-none d-md-block">Faq</h6>
                                        </div>
                                    </div>
                                </div>
                                <!-- Step Buttons END -->
                            </div>
            
                            <!-- Card body START -->
                            <div class="card-body">
                                <!-- Step content START -->
                                <div class="bs-stepper-content">

                                    
                                    <!-- <form onsubmit="return false"> -->
            
                                        <!-- Step 1 content START -->
                                        <div id="step-1" role="tabpanel" class="content fade dstepper-block" :class="inputStep == 1 ? 'active':'dstepper-none'" aria-labelledby="steppertrigger1">
                                            <!-- Title -->
                                            <h4>Course details</h4>
            
                                            <hr> <!-- Divider -->
                                                <!-- Upload image START -->
            
                                            <!-- Basic information START -->
                                            <div v-if="course && categoryList.length > 0">
                                                <companydash-course-intro-update-component 
                                                    :id="id" 
                                                    :course="course"  
                                                    :categorylist="categoryList" 
                                                    @changestep="emitChangeStep"
                                                />
                                            </div>

                                        </div>
                                        <!-- Step 1 content END -->
            
                                        <!-- Step 2 description  START -->
                                        <div id="step-2" role="tabpanel" class="content fade dstepper-block" :class="inputStep == 2 ? 'active':'dstepper-none'" aria-labelledby="steppertrigger2">
                                            <!-- Title -->
                                            <h4>Course Description</h4>            
                                            <hr> <!-- Divider -->

                                            <div v-if="course">
                                                <companydash-course-description-panel-component 
                                                    :content_id="id" 
                                                    :course="course"  
                                                    @changestep="emitChangeStep"
                                                />

                                            </div>
                                        </div>
                                        <!-- Step 2 content END -->
            
                                        <!-- Step 3 media START -->
                                        <div id="step-3" role="tabpanel" class="content fade dstepper-block" :class="inputStep == 3 ? 'active':'dstepper-none'" aria-labelledby="steppertrigger3">
                                            <!-- Title -->
                                            <h4>Course media</h4>
            
                                            <hr> <!-- Divider -->
                                            <div v-if="course">
                                                <companydash-course-media-panel-component 
                                                    :content_id="id" 
                                                    :course="course"  
                                                    @changestep="emitChangeStep"
                                                />
                                            </div>
            
                                        </div>
                                        <!-- Step 3 content END -->
            
                                        <!-- Step 4 Curriculum content START -->
                                        <div id="step-4" role="tabpanel" class="content fade dstepper-block" :class="inputStep == 4 ? 'active':'dstepper-none'" aria-labelledby="steppertrigger4">
                                            <!-- Title -->
                                            <h4>Curriculum</h4>
            
                                            <hr> <!-- Divider -->
                                            <companydash-course-update-curriculum-panel-component :content_id="id" />

                                            <!-- Step 3 button -->
                                            <div class="d-flex justify-content-between mt-3">
                                                <button @click="onChangeStep(3)" class="btn btn-secondary prev-btn mb-0">Previous</button>
                                                <button @click="onChangeStep(5)" class="btn btn-primary next-btn mb-0">Next</button>
                                            </div>
                                            
                                        </div>
                                        <!-- Step 4 content END -->
            
                                        <!-- Step 5 content START -->
                                        <div id="step-5" role="tabpanel" class="content fade dstepper-block" :class="inputStep == 5 ? 'active':'dstepper-none'" aria-labelledby="steppertrigger5">
                                            <!-- Title -->
                                            <h4>Additional information</h4>
            
                                            <hr> <!-- Divider -->
                                            <companydash-course-update-faq-panel-component :content_id="id" />

                                            <div class="d-md-flex justify-content-between align-items-start mt-4">
                                                <button @click="onChangeStep(4)" class="btn btn-secondary prev-btn mb-2 mb-md-0">Previous</button>
                                                <!-- <button class="btn btn-light me-auto ms-md-2 mb-2 mb-md-0">Preview Course</button> -->
                                                <div class="text-md-end">
                                                    <router-link :to="backToLink" class="btn btn-success mb-2 mb-sm-0">Finish a Course</router-link>
                                                    <!-- <p class="mb-0 small mt-1">Once you click "Submit a Course", your course will be uploaded and marked as pending for review.</p> -->
                                                </div>
                                            </div>            
            
                                        </div>
                                        <!-- Step 5 content END -->
                                    
                                </div>
                            </div>
                            <!-- Card body END -->
                        </div>
                    </div>



                    
                </div>
                <!-- Main content END -->

                </div>
			</div>
			<!-- Main content END -->


		</div><!-- Row END -->
	</div>
</section>
<!-- =======================
Page content END -->

</main>

    <hr/>
    </div>
</template>

<script>
var filename__ = "[pages > courseedit > EditCourseUpdate.vue]" ;

import axios from 'axios';


import CompanyDashCourseIntroUpdateComponent from '../../components/companydash/CompanyDashCourseIntroUpdateComponent.vue';
import CompanyDashCourseDescriptionPanelComponent from '../../components/companydash/CompanyDashCourseDescriptionPanelComponent.vue';
import CompanyDashCourseMediaPanelComponent from '../../components/companydash/CompanyDashCourseMediaPanelComponent.vue';

import CompanyDashCourseUpdateCurriculumPanelComponent from '../../components/companydash/CompanyDashCourseCurriculumPanelComponent.vue';
import CompanyDashCourseUpdateFaqPanelComponent from '../../components/companydash/CompanyDashCourseFaqPanelComponent.vue';


export default {
    components: {

        "companydash-course-intro-update-component" : CompanyDashCourseIntroUpdateComponent,
        "companydash-course-description-panel-component" : CompanyDashCourseDescriptionPanelComponent,
        "companydash-course-media-panel-component" : CompanyDashCourseMediaPanelComponent,


        'companydash-course-update-curriculum-panel-component' : CompanyDashCourseUpdateCurriculumPanelComponent,
        'companydash-course-update-faq-panel-component' : CompanyDashCourseUpdateFaqPanelComponent,
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            course : null,
            categoryList : [],
            inputStep : 1,
            
        }
    },
    computed: {
        backToLink(){
            return '/editcourse/'+this.id ;
        },
    },    
    created(){
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            // 처음 로딩하면 카테고리 리스트 불러오기 
            this.getCategoryList();
            this.getItem();
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        onChangeStep(_step){
            this.inputStep = _step ;
        },
        emitChangeStep(_step){     
            // let funcname__ ="emitChangeStep() "       
            this.inputStep = _step ;
            // console.log(filename__+ ""+funcname__+' _step:', _step);
            // console.log(filename__+ ""+funcname__+' this.inputStep:', this.inputStep);
            return ;
        },

        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },

        async getCategoryList(){
            let funcname__ =" getCategoryList() "
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/course/category/all/companydash',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        title:item.title,
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.categoryList = list;

                // console.log(filename__+''+funcname__+" categoryList:", this.categoryList);

            } else {
                console.log(filename__+''+funcname__+' err:');

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.msg;  
            }

            this.isLoading = false ;            
        },

        async getItem(){
            let funcname__ =" getItem() "
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/content/course/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }
            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.course = data.response ;     

                // console.log(filename__+''+funcname__+" course:", this.course);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },

    }
}
</script>

<style scoped>

</style>