<template>
<div class="col-12">
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="card border rounded-3">
        <!-- Card header START -->
        <div class="card-header border-bottom">
            <h3 class="mb-0">Curriculum</h3>
        </div>
        <!-- Card header END -->

        <!-- Card body START -->
        <div class="card-body">
            <div class="row g-5">
                <!-- Lecture item START -->
                <div class="col-12" v-for="lecture in itemList" :key="lecture.id" >
                    <!-- Curriculum item -->
                    <h5 v-if="lecture.is_display" class="mb-4">{{lecture.title}}</h5>
                    <h5 v-else class="text-decoration-line-through mb-4">{{lecture.title}}</h5>
                    <companydash-course-detail-curriculum-play-component 
                        v-for="topic in lecture.subList" 
                            :key="topic.id"
                            :id="topic.id"
                            :is_price="topic.is_price"
                            :is_display ="topic.is_display"
                            :title="topic.title"
                            :topictime="topic.topictime"
                            :kind ="topic.kind"
                            :description="topic.description"
                            :topic_photo="topic.topic_photo"
                            :url="topic.url"
                            :youtubestr="topic.youtubestr"
                            :is_max="topic.is_max"
                        />

                </div>

            </div>
        </div>
        <!-- Card body START -->
    </div>
</div>

</template>

<script>
var filename__ ="[components > companydash > CompanyDashCourseDetailCurriculumComponent.vue] " ;

import axios from 'axios';

import CompanyDashCourseDetailCurriculumPlayComponent from './CompanyDashCourseDetailCurriculumPlayComponent.vue' ;
export default {
    components: {
        'companydash-course-detail-curriculum-play-component' : CompanyDashCourseDetailCurriculumPlayComponent
    },
    props: [
        'content_id', 
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,   
            
            itemList : [],
        }
    },
    created(){
        this.getList();        
    },    
    computed: {

    },
    methods: {
        handleError(){
            this.error = null;
        },

        async getList(){
            let funcname__ ="getList()" ;

            this.isLoading = true;

            let serverData = await axios({
                method: 'get',
                url: '/api/content/course/curriculum/all/'+this.content_id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },

            }).catch( err=> {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }


            });

            this.isLoading = false ;

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData !== "undefined" && serverData) {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+"  data:", data);

                if(data.status_code == 200 ){

                    const response = data.response ;

                    // console.log(filename__+''+funcname__+' response:', response);
                    
                    let tmpList = response.data ;
                    let list = [];

                    tmpList.sort(function(a,b){
                        // console.log("sequence:",a.sequence)
                        return a.seq-b.seq
                    });
                    // console.log(filename__+''+funcname__+" tmpList:", tmpList);

                    let cnt=1
                    //메인 카테고리 값들을 입력하기 
                    for(let item of tmpList ){
                        if (item.parent_id == this.content_id) {
                            list.push({
                                num: cnt++, 
                                id: item.id, 
                                title: item.title, 
                                subList : [],
                                is_display : item.is_display,
                                seq: item.seq, 
                                min : item.min,
                                max: item.max,
                            });
                        }
                    }

                    // console.log(filename__+''+funcname__+" list1:", list);

                    //서브 타이틀 배열에 추가하기 
                    for(let sub of list ){
                        for(let subitem of tmpList ){
                            if (sub.id == subitem.parent_id) {
                                sub.subList.push({
                                    // num: cnt++, 
                                    id: subitem.id,
                                    title: subitem.title,    
                                    topictime : subitem.topictime,
                                    is_price : subitem.is_price,

                                    kind : subitem.kind,
                                    description:subitem.description,
                                    topic_photo:subitem.topic_photo,
                                    url:subitem.url,
                                    youtubestr:subitem.youtubestr,

                                    seq : subitem.seq,    
                                    subseq : subitem.subseq,  
                                    submin: subitem.submin,
                                    submax : subitem.submax,  
                                    is_min : subitem.is_min,
                                    is_max : subitem.is_max,
                                    is_display: subitem.is_display                         
                                });
                            }
                        }
                        sub.subList.sort(function(a,b){
                            return a.subseq-b.subseq
                        });

                    }

                    // console.log(filename__+''+funcname__+" list2:", list);
                    this.itemList = list

                    // console.log(filename__+''+funcname__+" itemList:", this.itemList);

                }

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

        },
    },
    
}
</script>