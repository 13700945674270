<template>
<div>

    <!-- Sidebar START -->
    <!-- Sidebar END -->
    
    <!-- Page content START -->
	
	<!-- Top bar START -->
	<!-- Top bar END -->

	<!-- Page main content START -->
	<div class="page-content-wrapper-match border">

		<base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
			<p>{{ error }}</p>
		</base-dialog>

		<base-dialog :show="isLoading" title="Loading..." fixed>
			<p>Loading...</p>
			<base-spinner></base-spinner>
		</base-dialog>


		<!-- Title -->
        <form @submit.prevent="sendForm" >

            <div class="col-12">
                <div class="card bg-transparent border rounded-3 h-100">

                    <!-- Catd header -->
                    <div class="card-header bg-light border-bottom">
                        <h5 class="card-header-title">코스 카테고리 생성 </h5>
                    </div>

                    <!-- Card body START -->
                    <div class="card-body">

                            <div class="row g-4">
                                <!-- Course info and avatar -->
                                <div class="col-12 mb-2">
                                    <label class="form-label">Title</label>
                                    <div class="input-group">
                                        <input type="text" v-model.trim="inputTitle" class="form-control" placeholder="title">
                                    </div>
                                </div>
                                <div v-if="category === 'degree' || category === 'certificate' " class="col-12 mb-2">
                                    <label class="form-label">Sub Title</label>
                                    <div class="input-group">
                                        <input type="text" v-model.trim="inputSubtitle" class="form-control" placeholder="sub title">
                                    </div>
                                </div>
                            </div>

                    </div>
                    <!-- Card body END -->
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 d-sm-flex justify-content-between align-items-center">
                    <router-link :to="backToLink" class="btn btn-secondary mb-0" >Back</router-link>
                    <button type="submit" class="btn btn-primary mb-0" >Create</button>
                </div>
            </div>
        </form>


	</div>

</div>
</template>

<script>
var filename__="[pages > admin > courserecommend > AdminCourseRecommendCategoryCreate.vue] "

import axios from 'axios';

export default {
    components: {
    },
    props: ['category'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  
            
            inputTitle : null, 
            inputSubtitle : '',    
            inputDescription: '',         

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
        backToLink(){
            return '/admin/course/recommendcategories/'+this.category; 
        },

    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
            return ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Success'  ){
                this.$router.replace('/admin/course/recommendcategories/'+this.category);
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },

        // 전송하기  
        async sendForm(){
            let funcname__ ="sendForm()" ;

            let dataForm = { 
                'category': this.category,
                'title':this.inputTitle,
                'subtitle' : this.inputSubtitle,
                'description' : this.inputDescription,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputTitle === '' || this.inputTitle === undefined || this.inputTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="카테고리 타이틀을 입력해주세요.";
                return ;
            }
            if(this.category === 'degree') {
                if( this.inputSubtitle === '' || this.inputSubtitle === undefined || this.inputSubtitle === null ) {
                    this.formIsValid = false ;
                    this.dialogTitle = 'Error'
                    this.error ="서브 타이틀을 입력해주세요.";
                    return ;
                }
            }            

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/course/recommendcategory',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '생성에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$router.replace(this.backToLink);

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' return err:', serverData.data.msg);
                        this.dialogTitle ="전송 실패" ;
                        this.error = serverData.data.msg;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        
    }
}
</script>

<style scoped>

</style>