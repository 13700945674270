export default {
    //  from : actions
    setJobLevelMutation(state, payload){
        console.log('[store/job/mutations.js_setJobLevelMutation()]', payload);

        state.jobLevelState = payload.jobLevel;

        console.log('[store/job/mutations.js_setJobLevelMutation()] state.jobLevel', state.jobLevelState);

    },

}