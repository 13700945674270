<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>


    <!-- update lecture  -->
    <teleport to="body">
        <div class="modal fade" :id="idLectureUpdateCompu" tabindex="-1" aria-labelledby="updateLectureLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-dark">
                    <h5 class="modal-title text-white" id="updateLectureLabel">Update Lecture</h5>
                    <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                </div>
                <form @submit.prevent="sendLectureUpdateForm">
                    <div class="modal-body">
                        <div class="row text-start g-3">
                            <!-- Course name -->
                            <div class="col-12 mt-3">
                                <div class="form-check form-switch form-check-md">
                                    <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputLectureIsDisplayUpdate" @click="onChangeLectureIsDisplayUpdate">
                                    <label v-if="inputLectureIsDisplayUpdate" class="form-check-label" for="onoffPublic">외부 공개</label>
                                    <label v-else class="form-check-label" for="onoffPublic">외부 비공개</label>
                                </div>
                            </div>

                            <div class="col-12">
                                <label class="form-label">Lecture name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter course name" v-model.trim="inputLectureUpdate" >
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger-soft my-0" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-success my-0" data-bs-dismiss="modal">Save Lecture</button>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </teleport>

    <!-- lecture 삭제 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idLectureDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" id="idDeleteCompu">Delete Lecture</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeLectureForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

    <h6 class="accordion-header font-base" id="heading-1">
        <button class="accordion-button fw-bold rounded d-inline-block collapsed d-block pe-5" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+id" aria-expanded="false" :aria-controls="'collapse-'+id">

            <span v-if="is_display" class="me-1 ms-1">{{title}}</span>
            <span v-else class="text-decoration-line-through me-1 ms-1">{{title}}</span>

            <!-- 수정하기  -->
            <span @click="getLecture" 
                class="btn btn-sm btn-success-soft btn-round me-1 mb-1 mb-md-0"
                data-bs-toggle="modal" 
                :data-bs-target="idLectureUpdateShapCompu"
                ><i class="far fa-fw fa-edit"></i></span>

            <!-- <button v-if="lecture.subList.length == 0" type="button"  -->
            <button type="button" 
                    class="btn btn-sm btn-danger-soft btn-round mb-0"
                    data-bs-toggle="modal" 
                    :data-bs-target="idLectureDeleteShapCompu"
                ><i class="fas fa-fw fa-times"></i></button>

            <button v-if="min != true " 
                    @click="upSeqLectureForm" 
                    class="btn btn-sm btn-info mb-0 ms-2 me-2" 
                ><i class="fas fa-arrow-alt-circle-up"></i></button>

            <button v-if="max != true " 
                    @click="downSeqLectureForm" 
                    class="btn btn-sm btn-danger mb-0 ms-2 me-2"
                ><i class="fas fa-arrow-circle-down"></i></button>

        </button>

    </h6>

    <div :id="'collapse-'+id" class="accordion-collapse collapse show" aria-labelledby="heading-1" :data-bs-parent="'#accordionExample'+id">
        <!-- Topic START -->
        <div class="accordion-body mt-3">
            <companydash-course-topic-list-component v-for="topic in sublist" 
                :key="topic.id"
                :content_id="content_id"
                :lecture_id="id"
                :id="topic.id"
                :company_id="topic.company_id"
                :is_price="topic.is_price"
                :is_display="topic.is_display"
                :title="topic.title"
                :kind="topic.kind"
                :topictime="topic.topictime"
                :subseq="topic.subseq"
                :is_max="topic.is_max"
            />

            <!-- Add topic button && form -->
            <companydash-course-topic-create-form-component 
                :content_id="content_id" 
                :company_id="company_id"
                :id="id" 
            />

        </div>
        <!-- Topic END -->
    </div>

</div>    
</template>

<script>
// v-for__string__
var filename__ = "[components > companydash > CompanyDashCourseLecturePanelComponent.vue] " ;

import axios from 'axios';

import CompanyDashCourseTopicListComponent from './CompanyDashCourseTopicListComponent.vue'
import CompanyDashCourseTopicCreateFormComponent from './CompanyDashCourseTopicCreateFormComponent.vue'

export default {
    components: {
        'companydash-course-topic-list-component' : CompanyDashCourseTopicListComponent,
        'companydash-course-topic-create-form-component' : CompanyDashCourseTopicCreateFormComponent
    },
    props: [ 
        'content_id','company_id',
        'id','title','min','max','is_display',
        'sublist' 
    ],
    emits:['refresh-list'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            inputLecture : null,
            inputLectureUpdate : null,
            inputLectureIsDisplayUpdate : null,

        }
    },
    created(){
        // console.log(filename__+' created() sublist:', this.sublist ) ;
    },    
    computed: {
        idLectureUpdateCompu(){
            return 'updateModal'+this.id
        },
        idLectureUpdateShapCompu(){
            return '#updateModal'+this.id
        },
        idLectureDeleteCompu(){
            return 'deleteModal'+this.id
        },
        idLectureDeleteShapCompu(){
            return '#deleteModal'+this.id
        },        
    },
    methods: {
        handleError(){
            this.error = null;
        },
        refreshLectureList(){
            // console.log(filename__+" refreshLectureList()");  //~~log
            this.$emit('refresh-list');
        },
        // lecture display 
        onChangeLectureIsDisplayUpdate(){
            this.inputLectureIsDisplayUpdate = !this.inputLectureIsDisplayUpdate ;
        },

        // 개별 lecture 를 선택했을 때 
        async getLecture(){
            let funcname__ ="getLectire()" ;

            // this.inputChoiceId = _id ;
            this.isLoading = true;

            let serverData = await axios({
                method: 'get',
                url: '/api/content/course/curriculum/lecture/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },

            }).catch( err=> {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }


            });

            this.isLoading = false ;

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData !== "undefined" && serverData) {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+"  data:", data);

                if(data.status_code == 200 ){

                    const response = data.response ;

                    // console.log(filename__+''+funcname__+' response:', response);
                    
                    this.inputLectureUpdate = response.title ;
                    this.inputLectureIsDisplayUpdate = response.is_display ;

                    // console.log(filename__+''+funcname__+' inputLectureUpdate:', inputLectureUpdate);
                }

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

        },

        // lecture update 
        async sendLectureUpdateForm(){
            let funcname__ ="sendLectureUpdateForm()" ;

            let dataForm = { 
                'title': this.inputLectureUpdate,
                'is_display' : this.inputLectureIsDisplayUpdate,
            } ;  

            if( this.inputLectureUpdate === '' || this.inputLectureUpdate === undefined || this.inputLectureUpdate === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="타이틀을 입력해주세요.";
                return ;
            }

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/curriculum/lecture/update/'+this.id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.inputLecture = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('refreshList');

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        // lecture remove 
        async removeLectureForm(){
            let funcname__ ="removeLectureForm()" ;

            // const isConfirm = confirm('Are you delete ?');
            // if(!isConfirm) return ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/content/course/curriculum/lecture/delete/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '삭제에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('refreshList');

                            }, 500);
                        }                        

                    } else if(serverData.data.status_code == 500){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '현재 하위 토픽이 존재합니다.';     

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        // lecture up seq 
        async upSeqLectureForm(){
            let funcname__ ="upSeqLectureForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/curriculum/lecture/upseq/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('refreshList');

                            }, 100);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        // lecture down seq 
        async downSeqLectureForm(){
            let funcname__ ="downSeqLectureForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/curriculum/lecture/downseq/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('refreshList');

                            }, 100);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },
        
    },
    
}
</script>
<style scoped>

</style> 