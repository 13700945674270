<template>
<div class="card shadow h-100">
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Card header -->
    <div class="card-header border-bottom p-4">
        <h5 class="card-header-title">Notifications Of All</h5>
    </div>

    <!-- Card body START -->
    <div class="card-body p-4">

        <!-- Ticket item START -->
        <div v-for="(item, idx ) in itemList" :key="item.id">

            <div class="d-flex justify-content-between position-relative">
                <div class="d-sm-flex">
                    <div v-if="item.receive_photo" class="avatar avatar-md flex-shrink-0">
                        <img v-if="item.receive_photo" class="avatar-img rounded-circle" :src="item.receive_photo" alt="avatar">
                        <!-- <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user2.png" alt="avatar"> -->
                    </div>
                    <div v-else class="icon-lg bg-purple bg-opacity-10 text-purple rounded-2 flex-shrink-0">
                        <i class="fas fa-user-tie fs-5"></i>
                    </div>
                    <!-- Info -->
                    <div class="ms-0 ms-sm-3 mt-2 mt-sm-0">
                        <h6 class="mb-0">
                            <router-link :to="`/admin/notification/${item.id}`" class="stretched-link">
                                {{item.receive_name }}
                            </router-link>
                        </h6>
                        <p class="text-truncate-2 mb-0">{{ item.content }}</p>
                        <span class="small">{{ item.date }}</span>
                    </div>
                </div>
            </div>

            <hr v-if="idx != (inputMaxCount-1)"><!-- Divider -->
        </div>
        <!-- Card footer START -->
        <div class="card-footer border-top">
            <div class="alert alert-success d-flex align-items-center mb-0 py-2">
                <div>
                    <small class="mb-0">More notices listed</small>
                </div>
                <div class="ms-auto">
                    <router-link to="/admin/notifications" class="btn btn-sm btn-success-soft mb-0" > View all </router-link>
                </div>
            </div>
        </div>
        <!-- Card footer START -->


    </div>
    <!-- Card body END -->
</div>

</template>
<script>
var filename__= "[components > admin > dashboard > AdminDashboardNotificationPanelComponent.vue] "

import axios from 'axios';

export default {
    components: {},
    props: [],
    data() {
        return {
           
            isLoading: false,
            error: null,     
            dialogTitle: null,       

            itemList : [],
            inputMaxCount: 0,

            page:1,
            // current_path:"/admin/companies",  //current_path
			pagination: null, 

            inputPagePerCount : 4,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 1,      // 하단 pagination 좌우 블록수     

        }
    },
    created(){
        this.getList()
    },
    computed: {

    },

    methods: {
        handleError(){
            this.error = null;

        },
        async getList(){
            let funcname__ ="getList() ";

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }
            
            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/notification/dashboard/admin',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                }
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }


            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);  //__log

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);  //__log

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log('[AdminContactusList.vue] profile:', data.profile);
                
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        num: counter--, 
                        id: item.id, 
                        email:item.email,

                        receive_id: item.receive_id,
                        receive_name: item.receive_name,
                        receive_email: item.receive_email,
                        receive_photo : item.receive_photo,
                        
                        create_id: item.create_id,
                        create_name: item.create_name,
                        create_email: item.create_email,
                        create_photo : item.create_photo,

                        content: item.content, 
                        is_check:item.is_check,
                        date: item.date

                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);  //__log
                this.itemList = list;
                this.inputMaxCount = list.length;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);  //__log
                // console.log(filename__+''+funcname__+" inputMaxCount:", this.inputMaxCount);  //__log
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);  //__log

            } else {
                console.log(filename__+''+funcname__+' err.error_description:',serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  
            }

            this.isLoading = false ;            
        },


    },
    
}
</script>

<style scoped>
</style>