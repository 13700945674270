<template>
<div class="row g-4 mt-1">

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>


    <!-- Course information START -->
    <div class="col-12">
        <div class="card bg-transparent border rounded-3 h-100">

            <!-- Catd header -->
            <div class="card-header bg-light border-bottom">
                <h5 class="card-header-title">{{inputTitle}} ({{ inputDate }})</h5>
            </div>

            <!-- Card body START -->
            <div class="card-body">

                <!-- Course image and info START -->
                <div class="row g-4">
                    <!-- Course info and avatar -->
                    <div class="col-md-12">
                        <!-- Info -->
                        <p class="mb-3 white-space-newline">{{inputContent}}</p>

                        <!-- Price -->
                        <hr/>
                        <p>에러 카테고리 : {{inputCategory}}</p>
                        <p>에러 파일 : {{inputFilename}}</p>
                        <p>에러 메소드(함수) : {{inputMethodname}}</p>
                        
                        <!-- Avatar -->
                        <hr/>
                        <div class="d-sm-flex align-items-center">
                            <!-- Avatar image -->
                            <div class="ms-sm-3 mt-2 mt-sm-0">
                                <!-- 미처리 : 0 , 확인중:1, 진행중 :2, 보류중 :8  / default : 10 , 처리완료 11 -->
                                <span v-if="inputStatus == 0" class="badge text-bg-danger">미처리</span>
                                <span v-if="inputStatus == 1" class="badge text-bg-info">확인중</span>
                                <span v-if="inputStatus == 2" class="badge text-bg-primary">진행중</span>
                                <span v-if="inputStatus == 8" class="badge text-bg-warning">보류중</span>
                                <span v-if="inputStatus == 11" class="badge text-bg-success">처리완료</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Course image and info END -->
            </div>
            <!-- Card body END -->
        </div>
    </div>
    <!-- Course information END -->


</div> <!-- Row END -->

</template>

<script>
// newline__
var filename__= "[components > admin > errorlog > AdminErrorLogDetailComponent.vue] "

import axios from 'axios';

export default {
    components: {},
    props: [
        'id',
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputWhich : null,
            inputIpaddress : null, 
            inputCategory : null, 
            inputFilename : null, 
            inputMethodname : null, 
            inputTitle : null,
            inputContent : null,
            inputDate : null,
            inputStatus : null,
        }
    },
    created(){
        this.getItem();
    },    
    computed: {

    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }
        },
        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/errorlog/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);  //~~log

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.code == 200 ){

                    const item = data.response

                    this.inputWhich = item.which ;
                    this.inputIpaddress = item.ipaddress ;
                    this.inputCategory = item.category ;
                    this.inputFilename = item.filename ;
                    this.inputMethodname = item.methodname ;
                    this.inputTitle = item.title ;
                    this.inputContent = item.content ;

                    this.inputStatus = item.status ;
                    this.inputDate = item.date ;

                } else {
                    console.log(filename__+''+funcname__+' err:');

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                

                    await axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        params: { 
                            cateogry: 'errorlog(else)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error',       
                            content : serverData.data.msg,
                        }
                    }).catch( err => {
                        console.log(filename__+''+funcname__+' errorlog :',err);

                    });

                    
                }
            }

            this.isLoading = false ;     
        },

    },
    
}
</script>
<style scoped>
</style> 