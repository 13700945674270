<template>
<div>

	<base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
		<p>{{ error }}</p>
	</base-dialog>

	<base-dialog :show="isLoading" title="Loading..." fixed>
		<p>Loading...</p>
		<base-spinner></base-spinner>
	</base-dialog>

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

		<!-- Title -->
		<div class="row">
			<div class="col-12">
				<h1 class="h3 mb-2 mb-sm-0">파트너 리스트</h1>
			</div>
		</div>
		
		<div class="card bg-transparent">

			<!-- Card header START -->
			<div class="card-header bg-transparent border-bottom px-0">
				<!-- Search and select START -->
				<div class="row g-3 align-items-center justify-content-between">

					<!-- Search bar -->
					<div class="col-md-12 mb-2">
						<form class="rounded position-relative" style="display: inline-block;">
							<input class="form-control bg-transparent" type="search" placeholder="Search" aria-label="Search" v-model.trim="inputSearch" >
							<button @click="onChangeSearch" class="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="button">
								<i class="fas fa-search fs-6 "></i>
							</button>
						</form>
						<button type="button" @click="onChangeSearch" class="btn btn-primary mb-0 ms-2">Search</button>
						<button type="button" @click="onChangeAll" class="btn btn-primary mb-0 ms-2">All</button>
						<button type="button" @click="onChangeDisplay" class="btn btn-warning mb-0 ms-3">비공개</button>
						<button type="button" @click="onChangeOut" class="btn btn-danger mb-0 ms-2">탈퇴요청</button>
					</div>


					<!-- Tab button -->
					<div class="col-md-3">
					</div>
				</div>
				<!-- Search and select END -->
			</div>
			<!-- Card header END -->

			<!-- Card body START -->
			<div class="card-body px-0">

				<div class="table-responsive border-0">
					<table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
						<!-- Table head -->
						<thead>
							<tr>
								<th scope="col" class="border-0 rounded-start">Num</th>
								<th scope="col" class="border-0 ">등록자</th>
								<th scope="col" class="border-0 ">파트너명</th>
								<th scope="col" class="border-0">Description</th>
								<th scope="col" class="border-0">총 Course 숫자 </th>
								<th scope="col" class="border-0">Course 등록</th>
								<th scope="col" class="border-0">등록 학생수</th>
								<th scope="col" class="border-0">비공개여부</th>
								<th scope="col" class="border-0">Join date</th>
								<th scope="col" class="border-0 ">탈퇴요청</th>
								<th scope="col" class="border-0 rounded-end">Action</th>
							</tr>
						</thead>

						<!-- Table body START -->
						<tbody>

							<!-- Table row -->
							<admin-company-tr-component v-for="item in itemList"
								:key="item.id"
								:id="item.id"
								:num="item.num"
								:name="item.name"
								:photo="item.photo"
								:phone="item.phone"
								:email="item.email"
								:addr="item.addr"
								:description="item.description"
								:date="item.date"
								:outdate="item.outdate"
								:is_display="item.is_display"
								:is_active="item.is_active"
								:is_out="item.is_out"

								:company_id="item.owner_id"								
								:owner_email ="item.owner_email"
								:owner_name ="item.owner_name"
								:owner_photo ="item.owner_photo"

								:course_count ="item.course_count"
								:enroll_count ="item.enroll_count"
								:student_count ="item.student_count"

								:page="page"
								:search="inputSearch"

								/>

						</tbody>
						<!-- Table body END -->
					</table>
				</div>

			</div>
			<!-- Card body END -->

			<!-- Card footer START -->
			<div v-if="itemList.length > 0"> 
				<admin-pagination :pagination="paginationCompu" @paging="pagingSend" ></admin-pagination>
			</div>
			<!-- Card footer END -->
		</div>
	</div>
	<!-- Page main content END -->


<!-- Page content END -->
    
</div>
</template>

<script>
var filename__= "[pages > admin > company > AdminCompnayList.vue] "

import axios from 'axios';

// import AdminCompanyColComponent from '../../../components/admin/company/AdminCompanyColComponent.vue' ;
import AdminCompanyTrComponent from '../../../components/admin/company/AdminCompanyTrComponent.vue' ;
import AdminPagination from '../../../components/admin/ui/AdminPagination.vue';

export default {
    components: {
		// "admin-company-col-component" : AdminCompanyColComponent,
		"admin-company-tr-component" : AdminCompanyTrComponent,
        "admin-pagination": AdminPagination,
    },
    props: [],
    data() {
        return {
            isLoading: false,
			isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList: [], 
            page:1,
            current_path:"/admin/companies",  //current_path
			pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',     
			inputIsDisplay : true,
			inputIsOut : false,
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    computed: {        
        paginationCompu(){
            return this.pagination ;
        },
    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			this.getList();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
			// console.log('[AdminCompanyList.vue] isAuth():',this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminCompanyList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        // onChangePagePerCount(event){
        //     this.inputPagePerCount = event.target.value ;
        //     this.getList(); //page=1
        //     console.log(filename__+''+funcname__+" inputPagePerCount: ", this.inputPagePerCount);
        // },
        onChangeSearch(){
			this.page = 1 ;
			this.inputIsDisplay = true ;
			this.inputIsOut = false ;
            this.getList(); //page=1
        },
        onChangeAll(){
			this.page = 1 ;
			this.inputSearch = '' ;
			this.inputIsDisplay = true ;
			this.inputIsOut = false ;
            this.getList(); //page=1
        },
        onChangeDisplay(){
			this.page = 1 ;
			this.inputSearch = '' ;
			this.inputIsDisplay = false ;
			this.inputIsOut = false ;
            this.getList(); //page=1
        },
        onChangeOut(){
			this.page = 1 ;
			this.inputSearch = '' ;
			this.inputIsDisplay = false ;
			this.inputIsOut = true ;
            this.getList(); //page=1
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        async getList(){
			let funcname__ ="getList() ";

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }

            if( this.inputSearch === 'undefinded' || this.inputSearch === '') { 
                this.inputSearch = '' ; //pagination, search 에서 계속작동하기 위해서                 
            }             


            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/company/all/admin',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           
                params: { 
                    page: this.page , 
                    search: this.inputSearch,       
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
					is_display : this.inputIsDisplay,
					is_out : this.inputIsOut,
                }

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    // console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            // console.log(filename__+''+funcname__+" serverData:", serverData);  //__log

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ;
                return ;

            } else {
				const data = serverData.data ;

        
				// console.log(filename__+''+funcname__+" data:", data);  //__log

				if( typeof data !== "undefined" &&  data.status_code == 200 ){
					// console.log(filename__+''+funcname__+' response:', data.response);  //__log
					
					this.pagination = data.response.pagination ;
					let cnt = data.response.pagination.counter ;
					let tmpList = data.response.data ;
					let list = []; 

					//메인 카테고리 값들을 입력하기 
					for(let item of tmpList ){
						list.push({
							num: cnt--, 
							id: item.id, 
							company_id : item.owner_id,
							email:item.email,

							name: item.name,
							photo: item.photo,
							phone: item.phone,
							addr: item.addr,							
							description: item.description, 

							date: item.date, 
							outdate: item.date,
							
							is_active : item.is_active,
							is_display : item.is_display,
							is_out : item.is_out,

							owner_id : item.owner_id,
							owner_email : item.owner_email,
							owner_name : item.owner_name,
							owner_photo : item.owner_photo,

							course_count : item.course_count,
							enroll_count : item.enroll_count,
							student_count : item.student_count ,
						});
					}

					// console.log(filename__+''+funcname__+" list:", list); //__log
					this.itemList = list;
					// this.isApplying = true ;

					// console.log(filename__+''+funcname__+" this.itemList:", this.itemList); //__log
					// console.log(filename__+''+funcname__+" this.pagination:", this.pagination); //__log

				} else {
					console.log(filename__+''+funcname__+' err:');  //__log

					this.dialogTitle ="실패" ;                    
					this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
					// alert('error')
				}
			}

            this.isLoading = false ;     
			// if(this.isApplying){
			// 	this.getList();
			// 	this.isApplying = false ;
			// }       
        },


    }
}
</script>

<style scoped>

</style>