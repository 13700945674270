<template>
    <div>

        <main>
	<section class="p-0 d-flex align-items-center position-relative overflow-hidden">
	
		<div class="container-fluid">
			<div class="row">
				<!-- left -->
				<div class="col-12 col-lg-6 d-md-flex align-items-center justify-content-center bg-primary bg-opacity-10 vh-lg-100">
					<div class="p-3 p-lg-5">
						<!-- Title -->
						<div class="text-center">
							<h2 class="fw-bold">워커스카페 교육 커뮤니티에 오신 것을 환영합니다</h2>
							<p class="mb-0 h6 fw-light">플랫폼 노동에 대한 다양한 정보를 접할 수 있습니다.</p>
						</div>
						<!-- SVG Image -->
						<img src="/assets/images/element/02.svg" class="mt-5" alt="">
						
						<!-- <div class="d-sm-flex mt-5 align-items-center justify-content-center">
							<ul class="avatar-group mb-2 mb-sm-0">
								<li class="avatar avatar-sm"><img class="avatar-img rounded-circle" src="/assets/images/avatar/01.jpg" alt="avatar"></li>
								<li class="avatar avatar-sm"><img class="avatar-img rounded-circle" src="/assets/images/avatar/02.jpg" alt="avatar"></li>
								<li class="avatar avatar-sm"><img class="avatar-img rounded-circle" src="/assets/images/avatar/03.jpg" alt="avatar"></li>
								<li class="avatar avatar-sm"><img class="avatar-img rounded-circle" src="/assets/images/avatar/04.jpg" alt="avatar"></li>
							</ul>
						
							<p class="mb-0 h6 fw-light ms-0 ms-sm-3">4명의 학생이 참가했습니다. 지금 당신의 차례입니다.</p>
						</div> -->
					</div>
				</div>
				<!-- Right -->
				<div class="col-12 col-lg-6 d-flex justify-content-center">
					<div class="row my-5">
						<div class="col-sm-10 col-xl-12 m-auto">

              <!-- Title -->
							<span class="mb-0 fs-1">🤔</span>
							<h1 class="fs-2">패스워드를 잊으셨나요?</h1>
							<h5 class="fw-light mb-4">새로운 암호를 받으려면 아래에 메일 주소를 입력해주세요.</h5>

                            <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
                                <p>{{ error }}</p>
                            </base-dialog>

                            <base-dialog :show="isLoading" title="Loading..." fixed>
                                <p>Loading...</p>
                                <base-spinner></base-spinner>
                            </base-dialog>

							<!-- Form START -->
							<form @submit.prevent="sendForm" >
								<!-- Email -->
								<div class="mb-4">
									<label for="exampleInputEmail1" class="form-label">이메일 주소 *</label>
									<div class="input-group input-group-lg">
										<span class="input-group-text bg-light rounded-start border-0 text-secondary px-3"><i class="bi bi-envelope-fill"></i></span>
										<input type="email" v-model.trim="inputEmail" class="form-control border-0 bg-light rounded-end ps-1" placeholder="E-mail" id="exampleInputEmail1">
									</div>
								</div>
								<!-- Button -->
								<div class="align-items-center">
									<div class="d-grid">
										<button type="submit" class="btn btn-primary mb-0" >암호 재설정</button>
									</div>
								</div>	
							</form>
							<!-- Form END -->
						</div>
					</div> <!-- Row END -->
				</div>
			</div> <!-- Row END -->
		</div>
	</section>
</main>
                
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pagess > auth > ForgotPw.vue]" ;
import axios from 'axios';

// import MemberInfoComp from '../../components/account/MemberInfoComp.vue';

export default {
    components: {
        // "member-info-comp": MemberInfoComp
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            inputEmail: null, 
        }
    },
    computed: {

    },    
    created(){
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },

        // 전송하기  
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'email': this.inputEmail,
            } ;  

            // console.log('[ Forgotpw.vue] dataForm:', dataForm);

            if( this.inputEmail === '' || this.inputEmail === undefined || this.inputEmail === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이메일 주소가 이상이 있습니다.";
                return ;
            }

            if( !this.inputEmail.includes('@') ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이메일 주소가 이상이 있습니다.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/user/forgotpw/create',
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
    
                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log("[Forgotpw.vue] serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.inputEmail = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '새로운 임시 비밀번호를 이메일로 발송하였습니다.';     

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                        this.dialogTitle ="실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },


    }
}
</script>

<style scoped>

</style>
