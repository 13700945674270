<template>
    <div>

        <main>

<!-- =======================
Page content START -->
<section class="pt-3 pt-xl-5">
	<div class="container" data-sticky-container>
		<div class="row g-4">
			
			<base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
				<p>{{ error }}</p>
			</base-dialog>

			<base-dialog :show="isLoading" title="Loading..." fixed>
				<p>Loading...</p>
				<base-spinner></base-spinner>
			</base-dialog>
			
			<!-- Main content START -->
			<div class="col-xl-8">

				<div class="row g-4">
					<div v-if="course" >
						<course-detail-center-component 
                            ref="centerRef"
							:course_id="id" 
							:company_id="course.company_id"
							:course="course" 							
							:is_exist="isExistCompu"
							:topic_id="topicIdCompu"
							:topic_title="topicTitleCompu"
							:topic_url="topicUrlCompu"
							:topic_iscomplete="topicIsCompleteCompu"
							@changeCurriculum="onEmitChangeCurriculum"
						/>
					</div>

					<!-- Course detail Bottom Tab -->
					<div v-if="course" class="col-12 mt-2">
						<course-detail-bottom-tab-component :course_id="id" :course="course" />
					</div>
					<!-- Course detail END -->
                </div>
			</div>
			<!-- Main content END -->

			<!-- Right sidebar START -->
			<div class="col-xl-4">
				<div v-if="course">
					<course-detail-right-top-component 
						:id="id" 
						:course="course" 
						:is_exist="isExistCompu"
                        :is_wishlist="isWishlistCompu"
                        :path="backToLink"
						@changeIsExist="onEmitChangeIsExist"
						@changeIsWishlist="onEmitChangeIsWishlist"
					/>    
				</div>
                <div class="col-12 mt-4">
					<course-detail-curriculum-component 
						ref="curriculumRef"
						:course_id="id" 
						:is_exist="isExistCompu"
						@changeTopicUrl="onEmitChangeTopicUrl"
					/>
                </div>

			</div>
			<!-- Right sidebar END -->

		</div><!-- Row END -->
	</div>
</section>
<!-- =======================
Page content END -->

</main>

    <hr/>
    </div>
</template>

<script>
// method__ v-for__ string__
var filename__ = "[pages > course > TopmenuCourseDetail.vue]" ;

import axios from 'axios';

import CourseDetailCenterComponent from '../../components/course/CourseDetailCenterComponent.vue';
import CourseDetailBottomTabComponent from '../../components/course/CourseDetailBottomTabComponent.vue';

import CourseDetailRightTopComponent from '../../components/course/CourseDetailRightTopComponent.vue';
import CourseDetailCurriculumComponent from '../../components/course/CourseDetailCurriculumPanelComponent.vue';

export default {
    components: {
        "course-detail-center-component": CourseDetailCenterComponent,
        "course-detail-bottom-tab-component": CourseDetailBottomTabComponent,

		"course-detail-right-top-component": CourseDetailRightTopComponent,
        "course-detail-curriculum-component": CourseDetailCurriculumComponent,

    },
    props: [
        'id',
        'parent_category','category_id','category_title'
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,     
			
			course : null,

			inputIsExist : false, //수강신청 여부    
            inputIsWishlist : false, // wishlist 여부   

			inputTopic_id : null,   //토픽 수강완료 
			inputTopic_title : null, //토픽 타이틀 
			inputTopic_url : null,  //토픽 url 
			inputTopic_iscomplete : null,  //토픽 수강완료 여부  

            inputIsChangeTopic : false, 
					
        }
    },
    created(){
        this.getItem();
    },
    computed: {
		backToLink(){
			return '/topmenucourses/'+this.parent_category+'/'+this.category_id+'/'+this.category_title
		},

		//로그인 하고, 수강신청했을 때만 수강신청버튼 클릭가능 
		isExistCompu(){  
			if(this.isAuth()) {
				return this.inputIsExist ;  
			} else {
				return false ;
			}			
		},
		isWishlistCompu(){  
			if(this.isAuth()) {
				return this.inputIsWishlist ;  
			} else {
				return false ;
			}			
		},

		topicIdCompu(){
			return this.inputTopic_id ;
		},
		topicTitleCompu(){
			return this.inputTopic_title ;
		},
		topicUrlCompu(){
			return this.inputTopic_url ;
		},
		topicIsCompleteCompu(){
			return this.inputTopic_iscomplete ;
		},

    },    
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },

		// CourseDetailRightTopComponent 수강신청하면 변경시킴 emit
		onEmitChangeIsExist(_is_exist)	{
			this.inputIsExist = _is_exist ;
		},	
        //wishlist 변경 
		onEmitChangeIsWishlist(_is_wishlist)	{
			this.inputIsWishlist = _is_wishlist ;
		},	

		//토픽 클릭했을 때 변경 
		onEmitChangeTopicUrl(_topic_id, _topic_title, _topic_url, _topic_is_complete){
			// let funcname__ = 'onEmitChangeTopicUrl() '
			this.inputTopic_id = _topic_id ;
			this.inputTopic_title = _topic_title ;
			this.inputTopic_url = _topic_url ;
			this.inputTopic_iscomplete = _topic_is_complete ;

            // 선택했을 때 '학습완료'이나 '이미학습된 버튼' 여부 버튼을 보이기 위해서 
            this.$refs.centerRef.changeTopicBtn(_topic_is_complete)

            // console.log(filename__+''+funcname__+" inputTopic_id:", this.inputTopic_id);
            // console.log(filename__+''+funcname__+" inputTopic_title:", this.inputTopic_title);
            // console.log(filename__+''+funcname__+" inputTopic_url:", this.inputTopic_url);
            // console.log(filename__+''+funcname__+" inputTopic_iscomplete:", this.inputTopic_iscomplete);
		},

		// 개별 토릭 수강완료하면, 커리큐럼 리스트에 완료로 변경하기 위해서 다시 커리큐럼 컴포넌트내의 메소드 불러오기 
		// 참고자료 : https://stackoverflow.com/questions/33682651/call-a-vue-js-component-method-from-outside-the-component
		onEmitChangeCurriculum(){
			this.$refs.curriculumRef.getCurriculumList()
		},

		//course 값 불러오기 
        async getItem(){
            let funcname__ =" getItem() "
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/course/user/'+this.id,

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.code == 200 ){
                
                this.course = data.response ;       
                this.isApplying = true ;

				this.inputCompany_id = this.course.company_id ;
                
				// console.log(filename__+''+funcname__+" inputCompany_id:", this.inputCompany_id);
				// console.log(filename__+''+funcname__+" course:", this.course);

            } else {
                console.log(filename__+''+funcname__+' err:');

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
                // alert('error')
            }

            this.isLoading = false ;    
            if(this.isApplying){
                this.isApplying = false ;
                this.inputTopic_url = this.course.url ;  //인트로 화면 

				if(this.isAuth()){
					this.getIsExist();  // 현재 수강신청 여부 체크 
                    this.getIsWishlist(); //현재 wishlist 여부 
				}				
            }        
        },
		// 현재 학생이 수강신청 여부 확인 
        async getIsExist(){
            let funcname__ =" getIsExist() "
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/student/course/is_exist/'+this.id,
                headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });

            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.code == 200 ){
                
                this.inputIsExist = data.is_exist ;

                // console.log(filename__+''+funcname__+" inputIsExist:", this.inputIsExist);

            } else {
                console.log(filename__+''+funcname__+' err:');

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
                // alert('error')
            }

            this.isLoading = false ;    
        },
		// 현재 학생이 wishlist 여부 확인 
        async getIsWishlist(){
            let funcname__ =" getIsWishlist() "
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/student/wishlist/is_exist/'+this.id,
                headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }
            });

            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.code == 200 ){
                
                this.inputIsWishlist = data.is_exist ;

                // console.log(filename__+''+funcname__+" inputIsWishlist:", this.inputIsWishlist);

            } else {
                console.log(filename__+''+funcname__+' err:');

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
                // alert('error')
            }

            this.isLoading = false ;    
        },

    }
}
</script>

<style scoped>

</style>