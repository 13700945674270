<template>
<div class="card shadow h-100">
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Card header -->
    <div class="card-header border-bottom d-flex justify-content-between align-items-center p-4">
        <h5 class="card-header-title">Course Reviews</h5>
        <router-link to="/admin/course/reviews" class="btn btn-link p-0 mb-0">View all</router-link>
    </div>

    <!-- Card body START -->
    <div class="card-body p-4">

        <!-- Ticket item START -->
        <div v-for="(item, idx ) in itemList" :key="item.id">
            <div class="d-flex justify-content-between position-relative">
                <div class="d-sm-flex">
                    <!-- Avatar -->
                    <div class="avatar avatar-md flex-shrink-0">
                        <img v-if="item.create_photo" class="avatar-img rounded-circle" :src="item.create_photo" alt="avatar">
                        <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user2.png" alt="avatar">
                    </div>
                    <!-- Info -->
                    <div class="ms-0 ms-sm-2 mt-2 mt-sm-0">
                        <h6 class="mb-0">
                            <router-link :to="`/admin/course/review/${item.id}`" class="stretched-link">{{ item.create_name }}</router-link>
                        </h6>
                        <p class="text-truncate-2 mb-0">
                            <router-link :to="`/admin/course/review/${item.id}`" class="stretched-link">
                            {{ item.description }}
                            </router-link>
                        </p>
                        <span class="small">{{ item.update }}</span>
                    </div>
                </div>
            </div>
            <!-- Ticket item END -->

            <hr v-if="idx != (inputMaxCount-1)"><!-- Divider -->
        </div>

    </div>
    <!-- Card body END -->
</div>

</template>
<script>
var filename__= "[components > admin > dashboard > AdminDashboardCourseReviewPanelComponent.vue] "

import axios from 'axios';

export default {
    components: {},
    props: [],
    data() {
        return {
           
            isLoading: false,
            error: null,     
            dialogTitle: null,       

            itemList : [],
            inputMaxCount: 0,

            page:1,
            // current_path:"/admin/companies",  //current_path
			pagination: null, 

            inputPagePerCount : 4,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 1,      // 하단 pagination 좌우 블록수     

        }
    },
    created(){
        this.getList()
    },
    computed: {

    },

    methods: {
        handleError(){
            this.error = null;

        },
        async getList(){
            let funcname__ ="getList() ";

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }

            
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/comment/review/dashboard/admin',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                }
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }


            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log('[AdminContactusList.vue] profile:', data.profile);
                
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        num: counter--, 
                        id: item.id, 
                        
                        description: item.description,

                        create_name:item.create_name,
                        create_photo:item.create_photo,

                        date: item.date,
                        update: item.update
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.itemList = list;
                this.inputMaxCount = list.length;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);
                // console.log(filename__+''+funcname__+" inputMaxCount:", this.inputMaxCount);
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);

            } else {
                console.log(filename__+''+funcname__+' err:');

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
                // alert('error')
            }

            this.isLoading = false ;            
        },


    },
    
}
</script>

<style scoped>
</style>