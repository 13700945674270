<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- 삭제 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" :id="idDeleteCompu">Delete</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 강사를 삭제하시겠습니까?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

    <div class="d-sm-flex align-items-center" >
        <!-- Avatar image -->
        <div class="avatar avatar-lg">
            <img v-if="photo" class="avatar-img rounded" :src="photo" alt="avatar">
            <img v-else class="avatar-img rounded" src="/assets/images/avatar/user.png" alt="avatar">
        </div>
        <div class="ms-sm-3 mt-2 mt-sm-0">
            <!-- <h5 class="mb-0"><a href="#">{{course.create_name}}</a></h5> -->
            <h5 class="mb-0"><a href="#">{{ name ? name : '미기재' }}</a></h5>
            <p class="mb-0 small">{{ email }}</p>
            <button type="button" class="btn btn-mini btn-danger" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu">삭제</button>
        </div>
    </div>
    <hr v-if="is_max" />

</div>
</template>

<script>
var filename__ ="[components > companydash > CompanyDashCourseDetailWorkerComponent.vue] " ;

import axios from 'axios';

export default {
    components: {},
    props: [
        'id','name','email','photo','is_max'
    ],
    data() {
        return {
            isLoading: false,
            isApplying : false,
            error: null,     
            dialogTitle: null, 
            
        }
    },
    created(){        
    },        
    computed: {
        idDeleteCompu(){
            return 'deleteReviewModal'+this.id
        },
        idDeleteShapCompu(){
            return '#deleteReviewModal'+this.id
        },

    },
    methods: {
        handleError(){
            this.error = null;
        },
        // 팝업 창을 통해서 삭제   
        async removeForm(){
            let funcname__ = "removeForm() " ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/workercourse/delete/'+this.id,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+funcname__+"serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        // console.log(filename__+funcname__+' data:', serverData.data);

                        this.dialogTitle ='Success' ;
                        this.error = '삭제 성공했습니다.';  
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('sendRefreshForm');

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },     

    },
    
}
</script>