<template>
<div class="row g-4">

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>


    <!-- Personal information START -->
    <div class="col-xxl-12">
        <div class="card bg-transparent border rounded-3 h-100">

            <!-- Card header -->
            <div class="card-header bg-light border-bottom d-sm-flex justify-content-between align-items-center">
                <h5 class="card-header-title mb-0">
                    파트너 정보
                    <span v-if="inputIsOut" class="badge text-bg-danger ms-3 me-2">탈퇴요청 : {{inputOutdate}}</span>
                    <span v-if="!inputIsDisplay" class="badge text-bg-orange me-2">비공개</span>
                    <span v-if="!inputIsActive" class="badge text-bg-warning me-2">비활성화</span>
                </h5>
                <div>
                    <router-link :to="statusLink" v-if="is_status_btn">
                        <h5 class="card-header-title mb-0">
                            <span class="badge text-bg-success">상태변경</span>     
                        </h5>                        
                    </router-link>
                </div>

            </div>

            <!-- Card body START -->
            <div class="card-body">
                <!-- Profile picture -->
                <div class="avatar avatar-xl mb-3">
                    <img v-if="inputPhoto" class="avatar-img rounded border border-white border-3 shadow" :src="inputPhoto" alt="">
                    <img v-else class="avatar-img rounded border border-white border-3 shadow" src="/assets/images/avatar/user.png" alt="">
                </div>

                <!-- Information START -->
                <div class="row">

                    <!-- Information item -->
                    <div class="col-md-6">
                        <ul class="list-group list-group-borderless">
                            <li class="list-group-item">
                                <span>Title:</span>
                                <span class="h6 mb-0">{{inputName}}</span>
                            </li>

                            <li class="list-group-item">
                                <span>Company:</span>
                                <span class="h6 mb-0">{{inputCompany}}</span>
                            </li>

                            <li class="list-group-item">
                                <span>직책 or 특기:</span>
                                <span class="h6 mb-0">{{inputMajor}}</span>
                            </li>

                            <li class="list-group-item">
                                <span>Phone Number:</span>
                                <span class="h6 mb-0">{{inputPhone}}</span>
                            </li>
                        </ul>
                    </div>

                    <!-- Information item -->
                    <div class="col-md-6">
                        <ul class="list-group list-group-borderless">
                            <li class="list-group-item">
                                <span>Customer Email:</span>
                                <span class="h6 mb-0">{{inputEmail}}</span>
                            </li>

                            <li class="list-group-item">
                                <span>Contact Email:</span>
                                <span class="h6 mb-0">{{inputContactemail}}</span>
                            </li>

                            <li class="list-group-item">
                                <span>Joining Date:</span>
                                <span class="h6 mb-0">{{inputDate}}</span>
                            </li>
                        </ul>
                    </div>

                    <!-- Information item -->
                    <div class="col-12">
                        <ul class="list-group list-group-borderless">
                            <li class="list-group-item">
                                <span>Address:</span>
                                <span class="h6 mb-0">{{inputAddr}}</span>
                            </li>
                        </ul>
                    </div>

                    <!-- Information item -->
                    <div class="col-12">
                        <ul class="list-group list-group-borderless">
                            <li class="list-group-item d-flex">
                                <span>Description:</span>
                                <p class="mt-4 mb-4 white-space-newline" v-html="inputDescription"></p>
                            </li>
                        </ul>
                    </div>
                    <hr v-if="careerList.length > 0"/>
                    <div v-if="careerList.length > 0" class="col-md-12">
                        <ul class="list-group list-group-borderless">                            
                            <li class="list-group-item" 
                                v-for="item in careerList"
                                    :key="item.id">
                                <span v-if="item.career"><i class="far fa-address-card fa-fw me-2"></i>회사</span>
                                <span v-else><i class="fas fa-user-graduate fa-fw me-2"></i>학교</span>
                                <span class="h6 mb-0">{{item.name}}</span>
                                <span class="h6 mb-0"><small>{{item.major}}</small></span>
                            </li>
                        </ul>
                    </div>                    
                    <hr v-if="skillList.length > 0"/>
                    <div class="col-md-12">
                        <ul class="list-group list-group-borderless">
                            <li class="list-group-item" 
                                v-for="item in skillList"
                                    :key="item.id">
                                <span class="h6 mb-0">{{item.name}}</span>
                                <span class="h6 mb-0"><small>{{item.percent}}%</small></span>
                            </li>

                        </ul>
                    </div>
                    
                    <hr/>
                    <div class="col-md-12">
                        <ul class="list-group list-group-borderless">
                            <li v-if="inputFacebook" class="list-group-item">
                                <span>facebook:</span>
                                <span class="h6 mb-0">{{inputFacebook}}</span>
                            </li>

                            <li v-if="inputInstagram" class="list-group-item">
                                <span>Instagram:</span>
                                <span class="h6 mb-0">{{inputInstagram}}</span>
                            </li>

                            <li v-if="inputLinkedin" class="list-group-item">
                                <span>Linkedin:</span>
                                <span class="h6 mb-0">{{inputLinkedin}}</span>
                            </li>

                            <li v-if="inputYoutube" class="list-group-item">
                                <span>Youtube:</span>
                                <span class="h6 mb-0">{{inputYoutube}}</span>
                            </li>
                            <li v-if="inputTwitter" class="list-group-item">
                                <span>Twitter:</span>
                                <span class="h6 mb-0">{{inputTwitter}}</span>
                            </li>
                        </ul>
                    </div>                    
                </div>
                <!-- Information END -->
            </div>
            <!-- Card body END -->
        </div>
    </div>
    <!-- Personal information END -->

</div> <!-- Row END -->

</template>

<script>
//  emit__
var filename__= "[components > admin > company > AdminCompanyDetailComponent.vue] "

import axios from 'axios';

export default {
    components: {},
    props: [
        'id','company_id',
        'create_id',
        'vue_page',  //상태 업데이트 
        'is_status_btn',
        'page','search'
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
                        
            inputPhoto : null ,
            inputPhotoUpdate : null,  
            inputPhotoTmp : null, 
            inputWidth : 300,
            
            inputName: null,
            inputCompany: null, 
            inputMajor: null, 
            inputDescription: null,
            inputPhone: null, 
            inputEmail: null, 
            inputContactemail: null, 
            inputWeb: null, 
            inputAddr : null, 
            inputDisplay : null, 

            inputDate : null, 
            inputIsDisplay : null,
            inputIsOut : null, 
            inputIsActive : null, 
            inputOutdate : null,

            inputOwner_id : null, 

            inputFacebook : null,
            inputInstagram : null, 
            inputLinkedin : null, 
            inputYoutube : null, 
            inputTwitter : null,     
            
            careerList :[],
            skillList: [],

        }
    },
    created(){
        this.getItem();
    },    
    computed: {
        statusLink(){
            return '/admin/company/status/update/'+this.id+'/'+this.company_id+'?page='+this.page+"&search="+this.search ; 
        },
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        
        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            // console.log("[AdminInstructorSettingDetailComponent.vue] ${this.$store.getters[auth/isToken]}:", this.$store.getters['auth/isToken'] );
            // console.log("[AdminInstructorSettingDetailComponent.vue] ${this.$store.getters[auth/isToken]}2:", `Bearer  ${this.$store.getters['auth/isToken']}` );

            let serverData = await axios({
                method: 'get',
                url: '/api/company/admin/'+this.id,
                headers: {
                    'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {


                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined" || serverData === undefined ||  serverData.status !== 200){
                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

            } else {
                const data = serverData.data ;

                console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" && data.status_code == 200){
                    console.log(filename__+''+funcname__+' response:', data.response);
                                        
                    const careers = data.careers  ? data.careers  : [];   // 학력, 회사경력 
                    const skills = data.skills ? data.skills : [] ;     // skill
                    const socials = data.socials ? data.socials : [] ;   // 소셜미디어 

                    const setting = data.response ;

                    this.inputPhoto = setting.photo ? setting.photo : '' ;

                    this.inputName = setting.name ? setting.name : '' ;
                    this.inputCompany = setting.company ? setting.company : '' ;
                    this.inputEmail = setting.email ? setting.email : '' ;
                    this.inputContactemail = setting.contactemail ? setting.contactemail : '' ;
                    this.inputMajor = setting.major ? setting.major : '' ;
                    this.inputPhone = setting.phone ? setting.phone : '' ;
                    this.inputWeb = setting.web ? setting.web : '' ;
                    this.inputAddr  = setting.addr ? setting.addr : '' ;
                    this.inputDescription = setting.description ? setting.description : '' ;
                    
                    this.inputDate = setting.date ;
                    this.inputIsDisplay = setting.is_display ;
                    this.inputIsActive = setting.is_active ;
                    this.inputIsOut = setting.is_out ;
                    this.inputOutdate = setting.outdate ;

                    this.inputDisplay = setting.is_display ;
                    this.inputOwner_id = setting.owner_id ;

                    
                    // 학교졸업/회사경력 정보 
                    for(let item of careers ){
                        console.log(filename__+''+funcname__+' item:', item);
                        this.careerList.push({
                            id: item.id, 

                            company_id : item.company_id,
                            name:item.name,
                            career: item.career,
                            major: item.major, 
                            seq: item.seq,
                            min: item.min,
                            max: item.max,
                        });
                    }
                    // skill
                    for(let item of skills ){
                        this.skillList.push({
                            id: item.id, 

                            company_id : item.company_id,
                            name:item.name,
                            percent: item.percent,
                            seq: item.seq,
                            min: item.min,
                            max: item.max,
                        });
                    }

                    for(let item of socials){
                        if(item.name === 'twitter'){
                            this.inputTwitter = item.url ;

                        } else if (item.name === 'facebook'){
                            this.inputFacebook = item.url ;

                        } else if (item.name === 'youtube'){
                            this.inputYoutube = item.url ;

                        } else if (item.name === 'instagram'){
                            this.inputInstagram = item.url ;

                        } else if (item.name === 'linkedin'){
                            this.inputLinkendin = item.url ;
                        }
                    }

                    this.isApplying = true ;

                } else if( typeof data !== "undefined" && data.status_code == 401){
                    this.dialogTitle ="Error" ;                    
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [401] ";  

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:',serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  
                }
            }

            this.isLoading = false ;   

            if(this.isApplying && this.vue_page ==='status_update'){
                this.isApplying = false ;
                this.$emit('is_display', this.inputIsDisplay );
                this.$emit('is_active', this.inputIsActive );
                this.$emit('is_out', this.inputIsOut );
                this.$emit('owner_id', this.inputOwner_id );
            }        
        },

    },
    
}
</script>
<style scoped>

</style> 