<template>
<div>
    <!-- Modal -->
    <!-- 참고자료 : https://getbootstrap.com/docs/5.2/components/modal/ -->

    <!-- 답변 모달  -->
    <div class="modal fade" :id="idReplyCompu" tabindex="-1" aria-labelledby="replyModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header bg-info">
                <h1 class="modal-title fs-5" id="replyModalLabel">답변하기</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <label class="form-label">Your Comment *</label>
                <textarea class="form-control" rows="3" v-model.trim="inputReplyDescription"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" @click="replySendForm" class="btn btn-primary" data-bs-dismiss="modal">Reply comment</button>
            </div>
            </div>
        </div>
    </div>

    <!-- 수정 모달  -->
    <div class="modal fade" :id="idUpdateCompu" tabindex="-1" aria-labelledby="replyModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header bg-info">
                <h1 class="modal-title fs-5" id="replyModalLabel">수정하기</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <label class="form-label">Your Comment</label>
                <textarea class="form-control" rows="3" v-model.trim="inputUpdateDescription"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" @click="updateForm" class="btn btn-info" data-bs-dismiss="modal">Update comment</button>
            </div>
            </div>
        </div>
    </div>

    <!-- 삭제 모달  -->
    <div class="modal fade" :id="idDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header bg-warning">
                <h1 class="modal-title fs-5" id="idDeleteCompu">Delete Comment</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p>해당 내용을 삭제하시겠습니까?</p>
                <p>삭제된 내용은 복구되지 않습니다.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
            </div>
            </div>
        </div>
    </div>

    <!-- List  -->
    <div class="my-4 d-flex" :class="depth_class" >
        <img v-if="create_photo" class="avatar avatar-md rounded-circle me-3" :src="create_photo" alt="avatar">
        <img v-else class="avatar avatar-md rounded-circle me-3" src="/assets/images/avatar/user.png" alt="avatar">
        <div>
            <div class="mb-2">
                <h5 class="m-0">{{create_name}}</h5>
                <span class="me-3 small">{{date}}</span>
            </div>
            <p class="white-space-newline">{{description}}</p>
            <button v-if="depth < 7" type="button" class="btn btn-sm btn-light mb-0" data-bs-toggle="modal" :data-bs-target="idReplyShapCompu">Reply</button>
            <button type="button" class="btn btn-sm btn-info mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idUpdateShapCompu"><i class="bi bi-pencil"></i></button>
            <button type="button" class="btn btn-sm btn-danger mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu"><i class="bi bi-trash"></i></button>
            <button type="button" @click="changeIsDisplay" class="btn btn-sm mb-0 ms-2" :class="is_display ? 'btn-primary':'btn-orange'" >{{is_display ?'보임':'숨김'}}</button>
        </div>
    </div>
</div>        
</template>

<script>
export default {
    components: {},
    props: [
        'id','description','depth','seq','date','create_name','create_photo','is_display'
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
           isReplyShow : false,

           inputReplyDescription : null, 
           inputUpdateDescription : this.description ,
           inputIsDisplay : this.is_display ,
        }
    },
    
    computed: {
        depth_class(){
            if(this.depth === 1){
                return ''
            
            } else {
                return "ps-"+this.depth+" ps-md-"+(this.depth+2)
            }    
        },
        idReplyCompu(){
            return 'replyModal'+this.id
        },
        idReplyShapCompu(){
            return '#replyModal'+this.id
        },
        idUpdateCompu(){
            return 'updateModal'+this.id
        },
        idUpdateShapCompu(){
            return '#updateModal'+this.id
        },
        idDeleteCompu(){
            return 'deleteModal'+this.id
        },
        idDeleteShapCompu(){
            return '#deleteModal'+this.id
        },
    },
    methods: {
        changeReplyShow(){
            this.isReplyShow = !this.isReplyShow ;
        },
        replySendForm(){
            this.$emit('replySendForm', this.id, this.inputReplyDescription)
            this.inputReplyDescription = null ;
        },
        updateForm(){
            this.$emit('updateForm', this.id, this.inputUpdateDescription)
            this.inputUpdateDescription = null ;
        },
        changeIsDisplay(){
            this.inputIsDisplay = !this.inputIsDisplay ;
            this.updateIsDisplayForm();
        },
        updateIsDisplayForm(){
            this.$emit('isDisplayForm', this.id, this.inputIsDisplay)
        },
        removeForm(){
            this.$emit('removeForm', this.id)
        },
    },
    
}
</script>