<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>


    <main>
	
    <!-- =======================
    Page Banner START -->
        <company-dashboard-top :title="'정보창고 내용'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
            <div class="row">
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <company-dashboard-left :menu="'blog'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
    
                    <!-- <div class="row">
                        <div class="col-md-8 mx-auto text-center">
                            <p class="text-center">Use this interface to add a new Course to the portal. Once you are done adding the item it will be reviewed for quality. If approved, your course will appear for sale and you will be informed by email that your course has been accepted.</p>
                        </div>
                    </div> -->
            
                    <div class="row g-4">
                        <!-- Main content START -->
                        <div class="col-xl-8">

                            <div class="row g-4">
                                <div v-if="blog">
                                    <companydash-blog-detail-center-component :content_id="id" :blog="blog" />
                                </div>
                            </div>
                        </div>
                        <!-- Main content END -->

                        <!-- Right sidebar START -->
                        <div class="col-xl-4">
                            <div data-sticky data-margin-top="80" data-sticky-for="768">
                                <div class="row g-4">
                                    <div class="col-md-6 col-xl-12">
                                        <!-- Course info START -->
                                        <div v-if="blog">
                                            <companydash-blog-detail-right-component :id="id" :blog="blog" />    
                                        </div>
                                        <!-- Course info END -->
                                    </div>

                                    <div class="col-md-6 col-xl-12">
                                        <!-- Course Worker -->
                                        <div v-if="blog">
                                            <companydash-blog-detail-worker-panel-component :content_id="id" :company_id="blog.company_id" />    
                                        </div>
                                        <!-- Course Instructor END -->
                                    </div>

                                </div><!-- Row End -->
                            </div>	
                        </div>
                        <!-- Right sidebar END -->

                    </div><!-- Row END -->
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
    
    <hr/>
</div>

</template>

<script>
var filename__= "[pages > companydash > CompanyDashBlogDetail.vue] "
import axios from 'axios';

import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';

import CompanyDashBlogDetailCenterComponent from '../../components/companydash/CompanyDashBlogDetailCenterComponent.vue';

import CompanyDashBlogDetailRightComponent from '../../components/companydash/CompanyDashBlogDetailRightComponent.vue';
import CompanyDashBlogDetailWorkerPanelComponent from '../../components/companydash/CompanyDashBlogDetailWorkerPanelComponent.vue';

export default {
    components: {
        "company-dashboard-top": CompanyDashboardTop,
        "company-dashboard-left": CompanyDashboardLeft,

        "companydash-blog-detail-center-component" :CompanyDashBlogDetailCenterComponent,

        "companydash-blog-detail-right-component" :CompanyDashBlogDetailRightComponent,
        "companydash-blog-detail-worker-panel-component" :CompanyDashBlogDetailWorkerPanelComponent,

    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            blog : null,
            inputUrl : null, 

            inputOnoff : true, 
            inputCertificate : false, 

            inputTitle : null, 
            inputSubtitle : null, 

            categoryList: [],
            inputCategory: null,
            inputLevel : null, 

            inputCoursetime : 0, 
            inputLecturecount : 0, 

            inputIsPeriod : false, 
            inputIsApply : false, 
            inputStartapply : null, 
            inputEndapply : null, 
            inputStartdate : null, 
            inputEnddate : null, 

            inputIsPrice: false,
            inputPrice: 0,
            inputIsDiscount: false, 
            inputDiscountprice: 0, 

            inputIsAmount : false,
            inputAmount: 0, 

        }
    },
    computed: {
        backLink(){
            return '/companydash/blogs?page='+this.$route.query.page+"&search="+this.$route.query.search
        }
    },    
    created(){
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getItem();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        async getItem(){
            let funcname__ =" getItem() "
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/content/blog/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.blog = data.response ;           

                console.log(filename__+''+funcname__+" blog:", this.blog);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                        
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },


    }
}
</script>

<style scoped>

</style>