<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Sidebar START -->
    <!-- Sidebar END -->
    
    <!-- Page content START -->
	
	<!-- Top bar START -->
	<!-- Top bar END -->
        
    <!-- Edit profile START -->
    <div class="page-content-wrapper border">
        <!-- Card header -->
        <div class="card-header bg-transparent border-bottom ">
            <div class="row g-3 align-items-center justify-content-between mb-2">
                <div class="col-md-6">
                    <h3 class="card-header-title mb-0">정보창고 Detail</h3>
                </div>
                <div class="col-md-6 text-end">
                    <router-link :to="backToLink" class="btn btn-secondary mb-0">목록</router-link>
                </div>
            </div>
        </div>
        
        <!-- Card body START -->
        <div class="card-body mt-2">

            <form class="row g-4 mt-1">
                <div class="col-md-12">
                    <label class="form-label">Title</label>
                    <div class="input-group">
                        <input type="text" v-model.trim="inputTitle" class="form-control" placeholder="타이틀" disabled>
                    </div>
                </div>

                <div class="col-md-12">
                    <label class="form-label">짧은 설명(이미지 대신)</label>
                    <div class="input-group">
                        <p class="form-control p-grey">{{ inputSubtitle }}</p>
                    </div>
                </div>
                <!-- Profile picture -->                                
                <div v-if="!inputIsText"  class="col-12 justify-content-center align-items-center">
                    <label class="form-label">Front Image </label>
                    <div class="d-flex align-items-center">
                        <div v-if="photo"  >
                            <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                <!-- Avatar place holder -->
                                <div class="card card-xl">
                                    <img :src="photo" id="uploadfile-1-preview" class="img-fluid border border-white border-3 shadow"  :width="widthCompu" alt=""/>
                                </div>
                            </label>
                        </div>
                        <div v-else >
                            <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                <!-- Avatar place holder -->
                                <span class="card card-xl">
                                    <i class="far fa-image fa-2xl" style="font-size:200px"></i>
                                    <!-- <img id="uploadfile-1-preview" class="card-img rounded-circle border border-white border-3 shadow" src="/assets/images/avatar/user.png" alt=""> -->
                                </span>
                            </label>

                        </div>
                    </div>
                </div>                
                <div class="col-md-12">
                    <!-- <label class="form-label">Content</label> -->
                    <!-- <div v-html="inputDiscription"></div> -->
                    <div class="output" v-html="outputMarkdownCompu"></div>
                </div>

                <hr/>
                <admin-content-file-panel-detail-component :content_id="id" />                
                <!-- <div v-if="inputContentFileList" class="col-md-12">
                    <label class="form-label" >첨부파일</label>
                    <ul>
                        <li v-for="item in inputContentFileList" :key="item.id">{{ item.filename }}</li>
                    </ul>
                    
                </div> -->

                <div class="col-md-2">
                    <div v-if="create_photo" class="avatar me-3">
                        <img class="avatar-img rounded-circle shadow" :src="create_photo" alt="avatar">                        
                    </div>
                    <div v-else class="avatar me-3">
                        <img class="avatar-img rounded-circle shadow" src="/assets/images/avatar/user.png" alt="avatar">
                    </div>
                    {{inputCreate_name}}
                </div>
                <div class="col-md-2">
                    <label class="form-label">Date</label>
                    <div class="input-group">
                        {{inputDate}}
                    </div>
                </div>
                <div class="col-md-2">
                    <label class="form-label">공개여부</label>
					<div class="form-check form-switch form-check-md">
						<input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" disabled v-model="inputIsDisplay" >
						<label v-if="inputIsDisplay" class="form-check-label" for="profilePublic">외부 공개</label>
						<label v-else class="form-check-label" for="profilePublic"><span  class="badge text-bg-orange">외부 비공개</span></label>
					</div>
                </div>                
                <!-- Save button -->
                <div class="d-sm-flex justify-content-end">
                    <router-link :to="backToLink" class="btn btn-secondary mb-0">목록</router-link>
                    <router-link :to="updateLink"  class="btn btn-success mb-0 ms-2">상태변경</router-link>
                </div>                

            </form>
        </div>
        <!-- Card body END -->

        
    </div>

    <!-- <div class="page-content-wrapper border">
        <admin-comment-panel-component :content="'blog'" :content_id="id" />
    </div>     -->

    
</div>
</template>

<script>
// query__
var filename__= "[pages > admin > content > AdminBlogDetail.vue] "

import axios from 'axios';
import { marked } from 'marked';  

import AdminContentFilePanelDetailComponent from '../../../components/admin/content/AdminContentFilePanelDetailComponent.vue' ;
import AdminCommentPanelComponent from '../../../components/admin/comment/AdminCommentPanelComponent.vue' ;

export default {
    components: {
        'admin-content-file-panel-detail-component': AdminContentFilePanelDetailComponent,
        "admin-comment-panel-component" : AdminCommentPanelComponent,
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
			isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputMarkdown : null, 

            inputPhoto : null, 
            inputWidth : 300, 

            inputTitle : null, 
            inputSubtitle : '', 
            inputDescription : '', 

            inputIsDisplay : null, 
            inputIsText : null, 
            inputDate : null, 

            inputCreate_name : null, 
            inputCreate_photo : null, 

            inputContentFileList : [], 

            
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    }, 
    mounted() {        
    },    
    setup(){
    },         
    computed: {
        backToLink(){
            return '/admin/blogs?page='+this.$route.query.page+'&search='+this.$route.query.search ; 
        },				
        updateLink(){
            return '/admin/blog/display/update/'+this.id+'?page='+this.$route.query.page+'&search='+this.$route.query.search ; 
        },	
        photo(){
            return this.inputPhoto ;
        },
        widthCompu(){
            return this.inputWidth ;
        },
        create_photo(){
            return this.inputCreate_photo ;
        },
        outputMarkdownCompu(){
            return marked(this.inputDescription);
        },        
    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getBlog();
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },

        isAuth(){
			// console.log('[AdminMemberList.vue] isAuth():',this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },

        async getBlog(){
            let funcname__ ="getBlog() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/blog/admin/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
            });

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;
            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){
                    // console.log(filename__+''+funcname__+'  response:', data.response);
                    const blog = data.response

                    this.inputPhoto = blog.main_photo ? blog.main_photo.main_photo_path : false ;

                    this.inputTitle = blog.title ;
                    this.inputSubtitle = blog.subtitle ;
                    this.inputDescription = blog.description  ;

                    this.inputIsDisplay = blog.is_display ;
                    this.inputIsText = blog.is_fronttext ;
                    this.inputDate = blog.date ;

                    this.inputCreate_name = blog.create_name ;
                    this.inputCreate_photo = blog.create_photo ? blog.create_photo : false  ;

                    for(let item of blog.content_files ){
                        this.inputContentFileList.push({
                            id: item.content_file_id, 
                            filename : item.content_file_path.replace(/^.*[\\\/]/, ''), 
                            filepath : item.content_file_path,
                        })
                    }
                    

                    console.log(filename__+''+funcname__+' inputContentFileList:', this.inputContentFileList);

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                        
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;

                }
            }

            this.isLoading = false ;     
        },

        
        
    }
}
</script>

<style scoped>
.output {
  overflow: auto;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}
.white-space-newline {

/* white-space: normal;
white-space: nowrap;
white-space: pre;
white-space: pre-wrap;
white-space: pre-line; */
white-space: break-spaces;

/* Global values */
/* white-space: inherit;
white-space: initial;
white-space: unset; */

}
</style>
