<template>
<div class="card border rounded-3">
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="offcanvas-lg offcanvas-end" tabindex="-1" id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
        <div class="offcanvas-header bg-dark">
            <h5 class="offcanvas-title text-white" id="offcanvasSidebarLabel">Course playlist</h5>
            <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close"><i class="bi bi-x-lg"></i></button>
        </div>
        <div class="offcanvas-body p-3 p-lg-0">
            <div v-if="curriculumList.length > 0 " class="col-12">
                <!-- Accordion START -->
                <div class="accordion accordion-icon accordion-bg-light" id="accordionExample2">
                    <!-- Item -->
                    <div class="accordion-item mb-3" v-for="lecture in curriculumList" :key="lecture.id">
                        <h6 class="accordion-header font-base" id="heading-1">
                            <a class="accordion-button fw-bold rounded collapsed d-block" :href="`#collapse-${lecture.id}`" data-bs-toggle="collapse" :data-bs-target="`#collapse-${lecture.id}`" aria-expanded="true" aria-controls="`collapse-${lecture.id}`">
                                <span class="mb-0">{{lecture.title}}</span> 
                                <!-- <span class="small d-block mt-1">(xx Lectures)</span>  -->
                            </a>
                        </h6>
                        <div :id="`collapse-${lecture.id}`" class="accordion-collapse collapse show" aria-labelledby="heading-1" data-bs-parent="#accordionExample2">
                            <div class="accordion-body mt-3">
                                <div class="vstack gap-3">

                                    <!-- Progress bar -->
                                    <div class="overflow-hidden">
                                        <div class="d-flex justify-content-between">
                                            <p class="mb-1 h6">{{lecture.subcomplete}}/{{lecture.subcount}} Completed</p>
                                            <h6 class="mb-1 text-end">{{completePercent(lecture.subcomplete, lecture.subcount)}}%</h6>
                                        </div>
                                        <div class="progress progress-sm bg-primary bg-opacity-10">
                                            <div class="progress-bar bg-primary aos" role="progressbar" data-aos="slide-right" data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-out" style="width: 30%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Course lecture / topic : 비로그인/로그인/수강신청 -->
                                    <div v-for="topic in lecture.subList" :key="topic.id" >
                                        <!-- 로그인 했고 수강신청 안함  -->
                                        <div v-if="(topic.is_price && !isExistCompu )" class="d-sm-flex justify-content-sm-between align-items-center">
                                            <div class="d-flex">
                                                <a class="btn btn-light btn-round mb-0 flex-shrink-0"><i class="bi bi-lock-fill"></i></a>
                                                <div class="ms-2 ms-sm-3 mt-1 mt-sm-0">	
                                                    <h6 class="mb-0">{{topic.title}}</h6>
                                                    <p class="mb-2 mb-sm-0 small">{{topic.topictime}}m</p>
                                                </div>
                                            </div>
                                            <!-- Button -->
                                            <a class="btn btn-sm btn-success mb-0">수강신청</a>
                                        </div>		
                                        <!-- 로그인했고 수강신청 했을 경우  -->
                                        <div v-else-if="(isAuth && topic.is_complete)" class="p-2 bg-success bg-opacity-10 rounded-3">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="position-relative d-flex align-items-center">
                                                    <button v-if="topic.kind == 2" 
                                                        type="button" 
                                                        class="btn btn-success btn-round btn-sm mb-0 stretched-link position-static"
                                                        @click="onChangeTopicUrl(topic.id, topic.title, topic.kind, topic.url, topic.description, topic.photo, topic.is_complete)" 
                                                        ><i class="fas fa-play me-0"></i></button>

                                                    <button v-if="topic.kind == 4" 
                                                        type="button" 
                                                        class="btn btn-success btn-round btn-sm mb-0 stretched-link position-static"
                                                        @click="onChangeTopicUrl(topic.id, topic.title, topic.kind, topic.url, topic.description, topic.photo, topic.is_complete)" 
                                                        ><i class="fas fa-file-alt fa-fw me-0"></i></button>

                                                    <button v-if="topic.kind == 1" 
                                                        type="button" 
                                                        class="btn btn-success btn-round btn-sm mb-0 stretched-link position-static"
                                                        @click="onChangeTopicUrl(topic.id, topic.title, topic.kind, topic.url, topic.description, topic.photo,  topic.is_complete)" 
                                                        ><i class="bi bi-card-image me-0"></i></button>

                                                    <span class="d-inline-block text-truncate ms-2 mb-0 h6 fw-light w-200px"> {{topic.title}} completed</span>
                                                </div>
                                                <!-- <p class="mb-0 text-truncate">Playing</p> -->
                                                <p class="mb-0 text-truncate">{{topic.topictime}} minutes</p>
                                            </div>
                                        </div>
                                        <!-- 로그인 안한 상태  -->
                                        <div v-else  class="d-flex justify-content-between align-items-center">
                                            <div class="position-relative d-flex align-items-center">
                                                <button v-if="topic.kind == 2" 
                                                    type="button" 
                                                    class="btn btn-danger-soft btn-round btn-sm mb-0 stretched-link position-static"
                                                    @click="onChangeTopicUrl(topic.id, topic.title, topic.kind, topic.url, topic.description, topic.photo,  topic.is_complete)" 
                                                    ><i class="fas fa-play me-0"></i></button>

                                                <button v-if="topic.kind == 4" 
                                                    type="button" 
                                                    class="btn btn-primary-soft btn-round btn-sm mb-0 stretched-link position-static"
                                                    @click="onChangeTopicUrl(topic.id, topic.title, topic.kind, topic.url, topic.description, topic.photo,  topic.is_complete)" 
                                                    ><i class="fas fa-file-alt fa-fw me-0"></i></button>

                                                <button v-if="topic.kind == 1" 
                                                    type="button" 
                                                    class="btn btn-orange-soft btn-round btn-sm mb-0 stretched-link position-static"
                                                    @click="onChangeTopicUrl(topic.id, topic.title, topic.kind, topic.url, topic.description, topic.photo,  topic.is_complete)" 
                                                    ><i class="bi bi-card-image me-0"></i></button>

                                                <button v-if="topic.kind == 5" 
                                                    type="button" 
                                                    class="btn btn-danger-soft btn-round btn-sm mb-0 stretched-link position-static"
                                                    @click="onChangeTopicUrl(topic.id, topic.title, topic.kind, topic.url, topic.description, topic.photo,  topic.is_complete)" 
                                                    ><i class="bi bi-boxes me-0"></i></button>

                                                <span class="d-inline-block text-truncate ms-2 mb-0 h6 fw-light w-200px">{{topic.title}}</span>
                                                <!-- <router-link :to="`/totalcourse/topic/4/${topic.id}`">move</router-link> -->
                                            </div>
                                            <p class="mb-0 text-truncate">{{topic.topictime}} minutes</p>
                                        </div>


                                    </div>    
                                    <!-- Course lecture -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Accordion END -->
            </div>
        </div>
    </div>
</div>


</template>

<script>
var filename__ ="[components > course > CourseDetailCurriculumComponent.vue] " ;

import axios from 'axios';


export default {
    components: {
    },
    props: [
        'content_id', 'is_exist',
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,   
            
            curriculumList : [],

            inputTopicId : null, 
            inputTopicTitle : null, 

            inputTopicKind : null, 
            inputTopicUrl : null, 
            inputTopicDescription : null, 
            inputTopicPhoto : null, 

            inputTopicIsComplete : null, 
        }
    },
    created(){
        this.getCurriculumList()       
    },    
    computed: {
        isExistCompu(){
            return this.is_exist ; //수강신청 여부 
        },
		completePercent() {
			// let funcname__ ="completePercent() ";
			return (complete, count) => {
				// console.log(filename__+''+funcname__+" complete:", complete+' , count:', count);
				return parseInt( (complete/count)*100)
			};
		}		
    },
    methods: {
        handleError(){
            this.error = null;

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        onChangeTopicUrl(_topic_id,_topic_title,  _kind, _url , _description, _photo, _is_complete ){
            let funcname__ ="onChangeTopicUrl() ";

			this.inputTopicId = _topic_id ;
            this.inputTopicTitle = _topic_title ;

            this.inputTopicKind = _kind ;
            this.inputTopicUrl = _url ;
            this.inputTopicDescription = _description ;
            this.inputTopicPhoto = _photo ;

			this.inputTopicIsComplete = _is_complete ;

            // console.log(filename__+''+funcname__+" inputTopicId:", this.inputTopicId);
            // console.log(filename__+''+funcname__+" inputTopicTitle:", this.inputTopicTitle);
            console.log(filename__+''+funcname__+" inputTopicUrl:", this.inputTopicUrl);
            console.log(filename__+''+funcname__+" inputTopicPhoto:", this.inputTopicPhoto);
            // console.log(filename__+''+funcname__+" inputTopicIsComplete:", this.inputTopicIsComplete);

            this.$emit('changeTopicUrl', this.inputTopicId, this.inputTopicTitle, this.inputTopicKind, this.inputTopicUrl, this.inputTopicDescription, this.inputTopicPhoto, this.inputTopicIsComplete)
            
        },
        async getCurriculumList(){
            let funcname__ ="getCurriculumList()" ;

            this.isLoading = true;

			let url ="" ;
			if(this.isAuth()){
				// 로그인 여부에 따라 클릭 가능범위가 달라짐 
				url='/api/content/course/curriculum/all/auth/'+this.content_id ;
			} else {
				url='/api/content/course/curriculum/all/user/'+this.content_id ;
			}

            let serverData = await axios({
                method: 'get',
                url: url,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },

            }).catch( err=> {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }


            });

            this.isLoading = false ;

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData !== "undefined" && serverData) {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+"  data:", data);

                if(data.status_code == 200 ){

                    const response = data.response ;

                    // console.log(filename__+''+funcname__+' response:', response);
                    
                    let tmpList = response.data ;
                    let list = [];

                    tmpList.sort(function(a,b){
                        // console.log("sequence:",a.sequence)
                        return a.seq-b.seq
                    });
                    // console.log(filename__+''+funcname__+" tmpList:", tmpList);

                    let cnt=1
                    //메인 카테고리 값들을 입력하기 
                    for(let item of tmpList ){
                        if (item.parent_id == this.content_id) {
                            list.push({
                                num: cnt++, 
                                id: item.id, 
                                title: item.title, 
                                subList : [],
                                is_display : item.is_display,
                                seq: item.seq, 
                                min : item.min,
                                max: item.max,
								is_max: item.is_max,  //topic 의 최대값 알아오기 위해서 
								subcount: 0,
								subcomplete:0,
                            });
                        }
						// topic count 입력하기 위해서 
                    }

                    // console.log(filename__+''+funcname__+" list1:", list);

                    //서브 타이틀 배열에 추가하기 
                    for(let lecture of list ){
						let subcount =0 ; //is_display 된 서브갯수값만 가져옴 
						let subcomplete = 0; // 수강 완료된 topic 
                        for(let item of tmpList ){
                            if (lecture.id == item.parent_id) {
                                lecture.subList.push({
                                    // num: cnt++, 
                                    id: item.id,
                                    title: item.title,    
                                    topictime : item.topictime,
                                    is_price : item.is_price,

                                    kind : item.kind,
                                    description:item.description,
                                    photo:item.topic_photo,
                                    url: item.url,

                                    seq : item.seq,    
                                    subseq : item.subseq,  
                                    submin: item.submin,
                                    submax : item.submax,  
                                    is_min : item.is_min,
                                    is_max : item.is_max,
									is_complete: item.is_complete, //topic 수강여부 

                                    is_display: item.is_display                         
                                });

								subcount++; // topic 의 갯수 
								if(item.is_complete){
									subcomplete++; //수강완료한 topic 의 갯수 
								}
								
                            }
                        }
						lecture.subcount = subcount ;
						lecture.subcomplete = subcomplete ;

                        lecture.subList.sort(function(a,b){
                            return a.subseq-b.subseq
                        });

                    }

                    // console.log(filename__+''+funcname__+" list2:", list);
                    this.curriculumList = list

                    console.log(filename__+''+funcname__+" curriculumList:", this.curriculumList);

                }

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

        },
    },
    
}
</script>