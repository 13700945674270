<template>
    <tr>
        <!-- Table data -->
        <td>{{num}}</td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="avatar avatar-md">
                    <img v-if="owner_photo" :src="owner_photo" class="rounded-circle" alt="">
                    <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                </div>
                <div class="mb-0 ms-3">
                    <h6 v-if="owner_name" class="mb-0"><a class="stretched-link">{{owner_name}}</a></h6>
                    <small>{{owner_email}}</small>                          
                </div>
            </div>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="avatar avatar-md">
                    <router-link :to="detailLink">
                        <img v-if="photo" :src="photo" class="rounded" alt="">
                        <img v-else src="/assets/images/avatar/user.png" class="rounded" alt="">
                    </router-link>
                </div>
                <div class="mb-0 ms-3">
                    <!-- Title -->
                    <router-link :to="detailLink">
                        <h6 v-if="name" class="mb-0"><a class="stretched-link">{{name}}</a></h6>
                    </router-link>
                </div>
            </div>
        </td>

        <!-- <td class="text-center text-sm-start">
            <div class="overflow-hidden">
                <h6 class="mb-0">85%</h6>
                <div class="progress progress-sm bg-primary bg-opacity-10">
                    <div class="progress-bar bg-primary aos" role="progressbar" data-aos="slide-right" data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-out" style="width: 85%" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                    </div>
                </div>
            </div>
        </td> -->
        <td class="text-center text-sm-start">
            <div class="overflow-hidden">
                <span class="mb-0 fw-bold" v-html="contentSplice20"></span>
            </div>
        </td>

        <!-- Table data -->
        <td>
            <span v-if="course_count > 0" class="badge text-bg-primary">{{ course_count }}</span> 
        </td>
        <td>
            <span v-if="enroll_count > 0" class="badge text-bg-success">{{ enroll_count }}</span> 
        </td>
        <td>
            <span v-if="student_count > 0" class="badge text-bg-success">{{ student_count }}</span> 
        </td>

        <!-- Table data -->
        <td>
            <span v-if="!is_display" class="badge text-bg-orange">비공개</span>
            <span v-if="!is_active" class="badge text-bg-warning ms-2">비활성화</span>
        </td>
        <td>{{date}}</td>
        <!-- Table data -->
        <td>
            <!-- <a href="#" class="btn btn-light btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <i class="bi bi-eye"></i>
            </a>
            <a href="#" class="btn btn-light btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Message">
                <i class="bi bi-envelope"></i>
            </a>
            <button class="btn btn-light btn-round mb-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Block">
                <i class="fas fa-ban"></i>
            </button> -->
            <span v-if="is_out" class="badge text-bg-danger">{{outdate}}</span>
        </td>
        <td>
            <router-link :to="detailLink" class="btn btn-info-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="회원정보 보기">
                <i class="bi bi-pencil-square"></i>
            </router-link>
            <!-- <button class="btn btn-danger-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Delete">
                <i class="bi bi-trash"></i>
            </button>
            <a href="#" class="btn btn-sm btn-info-soft mb-0" data-bs-toggle="modal" data-bs-target="#viewReview">View</a> -->
        </td>

    </tr>
</template>

<script>
export default {
    components: {},
    props: [
        'id','num','name','phone','email','addr','description','photo','date',
        'is_display','is_active','is_out','outdate', 
        'course_count','enroll_count','student_count',
        'company_id','owner_email','owner_id', 'owner_name','owner_photo',
        'page','search',
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            return '/admin/company/'+this.id+'/'+this.company_id+'?page='+this.page+"&search="+this.search ; 
        },
        contentSplice20(){
            if(this.description) {
                return this.description.slice(0,20)+"....";
            } else {
                return this.description ;
            }
            
        },
    },
    methods: {

    },
    
}
</script>