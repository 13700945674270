<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <teleport to="body">
        <!-- Modal -->
        <!-- 참고자료 : https://getbootstrap.com/docs/5.2/components/modal/ -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Delete</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>                         
    </teleport>         
    <div class="card card-body border p-4">
        <!-- Buttons -->
        <div class="mt-0 d-grid">
            <!-- <a href="#" class="btn btn-outline-primary">Add to cart</a> -->
            <router-link :to="backToLink" class="btn btn-secondary">목록보기</router-link>
            <router-link :to="updateLink" class="btn btn-info">수정하기</router-link>
            <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">삭제하기</button>
        </div>
        <!-- Divider -->
        <hr>

        <!-- Title -->
        <h5 class="mb-3">정보창고 includes</h5>
        <ul class="list-group list-group-borderless border-0">
            <li class="list-group-item px-0 d-flex justify-content-between">
                <span class="h6 fw-light mb-0"><i class="fas fa-fw fa-signal text-primary"></i>Skills</span>
                <span v-if="blog.level ===0"> All levels</span>
                <span v-if="blog.level ===1"> Beginner</span>
                <span v-if="blog.level ===2"> Intermediate</span>
                <span v-if="blog.level ===3"> Advanced</span>

            </li>
        </ul>
        <!-- Divider -->
        <hr>

        <!-- Instructor info -->
        <div class="d-sm-flex align-items-center">
            <!-- Avatar image -->
            <div class="avatar avatar-xl">
                <img v-if="blog.create_photo" class="avatar-img rounded-circle" :src="blog.create_photo" alt="avatar">
                <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/05.jpg" alt="avatar">
            </div>
            <div class="ms-sm-3 mt-2 mt-sm-0">
                <h5 class="mb-0"><a href="#">{{blog.create_name}}</a></h5>
            </div>
        </div>

    </div>

</div>
</template>

<script>
// modal__ teleport__ removeForm__
var filename__ ="[components > companydash > CompanyDashBlogDetailRightComponent.vue] " ;

import axios from 'axios';

export default {
    components: {},
    props: [
        'id','blog', 
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  
        }
    },
    
    computed: {
        backToLink(){
            return '/companydash/blogs'
        },
        updateLink(){
            return '/companydash/blog/update/'+this.id ;
        },

    },
    methods: {
        handleError(){
            this.error = null;
        },

        // 팝업 창을 통해서 삭제   
        async removeForm(){
            let funcname__ ="removeForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/content/blog/delete/'+this.id,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    const data = serverData.data ;

                    // console.log(filename__+''+funcname__+" data.code:", data.code);
                    if(data.status_code == 201){
                                                
                        this.dialogTitle ='Success' ;
                        this.error = '삭제 성공했습니다.';   

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$router.replace(this.backToLink);

                            }, 700);
                        }                        


                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },     

    },
    
}
</script>