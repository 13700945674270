import axios from "axios";

export default {
    //send data
    async contactCoach(context, payload){
        const newRequest = {
            id: new Date().toISOString(),
            coachId: payload.coachId,
            userEmail: payload.email,
            message: payload.message,
        };

        const data = {
            coachid: payload.coachId,
            useremail: payload.email,
            message: payload.message
            // message: payload.message.replace(/\\n/g, "\\n")  //escape
            //                         .replace(/\\'/g, "\\'")
            //                         .replace(/\\"/g, '\\"')
            //                         .replace(/\\&/g, "\\&")
            //                         .replace(/\\r/g, "\\r")
            //                         .replace(/\\t/g, "\\t")
            //                         .replace(/\\b/g, "\\b")
            //                         .replace(/\\f/g, "\\f"),
        };

        const response = await axios( {method: 'post', url:'/request_add', data: data });
        const responseData = await response.data ;

        console.log('[store/requests/actions.js_contactCoach()] response:', response);

        if(response.status !== 200){
            console.log('[store/requests/actions.js_contactCoach()]', responseData.message);

            const error = new Error(responseData.message || 'Failed to send request.');
            console.error("error ==> [store/coaches/action.js_loadCoaches()] : \n", error);
            throw error ;            
        }
        
        newRequest.id = responseData['id'];
        context.commit('addRequest', newRequest);  // mutations.js
    },

    // get data
    async fetchRequests(context){
        // console.log("call /store/requests/actions.js_fetchRequests()");

        const url = '/requests';
        const _coachId = context.rootGetters.userId ;
        const _params = {
            coachid: _coachId
        }
        const response = await axios.get( url, { params : _params } ) ;        
        const responseData = await response.data['results'] ;

        // console.log('[store/requests/actions.js_fetchRequests()] response:', response);

        if(response.status !== 200) {
            const error = new Error(responseData.message || 'Failed to fetch requests');
            console.error("error ==> [store/requests/action.js_fetchRequests()] : \n", error);

            throw error ;
        }

        const requests = [];
        for(const item in responseData){
            const request = {
                id: responseData[item].id,
                coachId : responseData[item].coachid,
                userEmail : responseData[item].useremail,
                message : responseData[item].message
            }

            requests.push(request);
        }

        // console.log('[store/requests/action.js_fetchRequests()]', requests);
        context.commit('setRequests', requests); //mutations.js

    }
    
};