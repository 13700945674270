<template>
<div>
	<main>

		<!-- =======================
		Page Banner START -->
		<section class="bg-dark align-items-center d-flex" style="background:url(/assets/images/pattern/04.png) no-repeat center center; background-size:cover;">
			<!-- Main banner background image -->
			<div class="container">
					<div class="row">
						<div class="col-12">
							<!-- Title -->
							<h1 class="text-white">{{inputCategoryTitle}}</h1>
							<!-- Breadcrumb -->
							<div class="d-flex">
								<nav aria-label="breadcrumb">
									<ol class="breadcrumb breadcrumb-dark breadcrumb-dots mb-0">
										<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
										<li class="breadcrumb-item"><a href="#">내 대시보드</a></li>
										<li class="breadcrumb-item active" aria-current="page">{{recommendTitleCompu }}</li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
			</div>
		</section>
		<!-- =======================
		Page Banner END -->

		<!-- =======================
		Page content START -->
		<section class="pt-5">
			<div class="container">

				<!-- Course list START -->
				<div class="row g-4 justify-content-center">

					<topmenu-course-list-component v-for="item in itemList"
						:key = "item.id"
						:id ="item.id"
						:num ="item.num"
						:title ="item.title"
						:subtitle="item.subtitle"
						:level ="item.level"
						:is_price ="item.is_price"
						:price ="item.price"
						:coursetime ="item.coursetime"
						:lecturecount ="item.lecturecount"
						:image ="item.image"
						:onoff="item.onoff"
						:reviewavg="item.reviewavg"

						:parent_category ="inputParentCategory"
						:category_id="inputCategoryId"
						:category_title="inputCategoryTitle"
					
					/>
				</div>
				<!-- Course list END -->

			</div>
		</section>
		<!-- =======================
		Page content END -->

	</main>
    <hr/>
</div>
</template>

<script>
// param__ params__ watch__
var filename__ ="[pages > course > TopmenuCourseList.vue] "
import axios from 'axios';

import TopmenuCourseListComponent from '../../components/course/TopmenuCourseListComponent.vue';

export default {
    components: {
		"topmenu-course-list-component": TopmenuCourseListComponent , 
    },
    props: ['parent_category','category_id','category_title'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
			inputParentCategory : this.parent_category,
			inputCategoryId : this.category_id, 
			inputCategoryTitle: this.category_title, 

			itemList: [], 

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); 
    },     
    created(){
		// let funcname__="created()" ;
        // 같은 페이지를 파라미터 달리해서 불러올 때  
        // 참고자료 : https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
        this.$watch(
			() => this.$route.params,(toParams, previousParams) => {
					// console.log(filename__+funcname__+" $watch toParams: ", toParams['category_id']);
					// console.log(filename__+funcname__+ " $watch previousParams: ", previousParams['category_id']);
					console.log(" $watch previousParams: ", previousParams['category_id']);

					this.inputParentCategory = toParams['parent_category'];
					this.inputCategoryId = toParams['category_id'];
					this.inputCategoryTitle =toParams['category_title'] ;

					this.getList();
				}
        )        
        this.getList();
    },

    computed: {
		recommendTitleCompu(){
			if(this.inputParentCategory ==='getstarted'){
				return 'Get Started'
			} else if(this.inputParentCategory ==='degree'){
				return 'Degree'
			} else {
				return 'Certificate'
			}
		}
    },    
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
		async getList(){
			let funcname__ ="getList()" ;
			// console.log(filename__+funcname__+" category_id: ", this.inputCategoryId);

			this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

			let serverData = await axios({
				method: 'get',
				url:'/api/course/recommend/all/user/'+this.inputCategoryId,                

			}).catch( err => {

				if(err.response.status === 401) {  
					this.dialogTitle ="Login" ;
					this.error = "로그인이 필요한 페이지입니다.";          

					// 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
					this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
									
				} else {
					let dataForm = { 
						category: 'errorlog(api catech)' , 
						filename : filename__,
						methodname : funcname__,
						title: 'Error(API)',       
						content : ''+err,
					} ;  
					console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

					axios({
						method: 'post',
						url: '/api/errorlog/create',
						data: dataForm,
					});

					this.dialogTitle ="Error" ;
					this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
					
					this.isLoading = false ;

					return ;
				}

			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			// console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.code == 200 ){
				// console.log('[AdminCourseCategoryList.vue] profile:', data.profile);
				
                let tmpList = data.response ;
                let list = [];

				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					let _course = item.course ;
					list.push({
						id: _course.id, 
						title:_course.title,
						subtitle:_course.subtitle,
						level : _course.level,
						is_price : _course.is_price,
						price : _course.price,
                        coursetime : _course.coursetime,
                        lecturecount : _course.lecturecount,
                        onoff : _course.onoff,
						reviewavg:_course.reviewavg,

                        image : _course.image,
                        
					});
				}

				// console.log(filename__+''+funcname__+" tmpList:", tmpList);
				this.itemList = list;

				// console.log(filename__+''+funcname__+" itemList:", this.itemList);

			} else {
				console.log(filename__+''+funcname__+' err:');

				this.dialogTitle ="실패" ;                    
				this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
				// alert('error')
			}

			this.isLoading = false ;            
		},

    }
}
</script>

<style scoped>

</style>
