<template>
<div class="col-lg-10 col-xl-6">
    <div class="card shadow p-2">
        <div class="row g-0">
            <!-- Image -->
            <div class="col-md-4">
                <router-link :to="detailLink">
                    <img v-if="photo" :src="photo" class="img-thumbnail rounded-3" alt="...">
                    <img v-else src="/assets/images/avatar/user.png" class="rounded-3" alt="...">
                </router-link>
            </div>

            <!-- Card body -->
            <div class="col-md-8">
                <div class="card-body">
                    <!-- Title -->
                    <div class="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                        <div>
                            <h5 class="card-title mb-0"><router-link :to="detailLink">{{name}}</router-link></h5>
                            <p class="small mb-2 mb-sm-0 none"><router-link :to="detailLink" class="grayscale">{{company}}</router-link> </p>
                        </div>
                        <!-- <span class="h6 fw-light">4.3<i class="fas fa-star text-warning ms-1"></i></span> -->
                    </div>
                    <!-- Content -->
                    <p class="text-truncate-2 mb-3">
                        <router-link :to="detailLink" class="grayscale">{{description}}</router-link>
                    </p>
                    <!-- Info -->
                    <div class="d-sm-flex justify-content-sm-between align-items-center">
                        <!-- Title -->
                        <h6 class="text-orange mb-0">{{major}}</h6>

                        <!-- Social button -->
                        <ul class="list-inline mb-0 mt-3 mt-sm-0">
                            <company-social-list-component v-for="item in socials"
                                :key="item.id"
                                :id="item.id"
                                :name="item.name"
                                :url="item.url"
                                />	                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import CompanySocialListComponent from './CompanySocialListComponent.vue';

export default {
    components: {
        "company-social-list-component": CompanySocialListComponent,
    },
    props: [
        'id', 'company_id',
        'photo','name','company','email','contactemail','major','phone','web','addr','description',
        'socials'
    ],

    data() {
        return {
            error: null,     
            dialogTitle: null,  

        }
    },
    
    computed: {
        detailLink(){
            return '/company/'+this.id+'/'+this.company_id
        },
        // contentSplice(){
        //     return this.description.splice(0,30);
        // },
    },
    methods: {
        handleError(){
            this.error = null;
        },
    },
    
}
</script>