<template>
<div class="row">
    <base-dialog  :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="col-md-12"> 
        <div v-if="fileKind == 'image'" class="row">
            <content-image-list-component 
                v-for="item in itemList"
                    :key="item.id"
                    :id="item.id"
                    :img_id="item.img_id"
                    :photo="item.photo"
                    @refreshList="refreshForm"
            />
        </div>
        <div v-if="fileKind == 'file'" class="row">
            <content-file-list-component 
                v-for="item in itemFileList"
                    :key="item.id"
                    :id="item.id"
                    :img_id="item.img_id"
                    :file="item.file"
                    @refreshFileList="refreshFormFile"
            />
        </div>
    </div>

    <div class="col-md-12 mt-2"  > 
        <form @submit.prevent="sendFileForm" class="row">

            <!-- Profile picture -->                                
            <!-- <div class="col-12 justify-content-center align-items-center"> -->
            <div class="col-md-12">
                <!-- <label class="form-label">파일은 다중 선택이 가능합니다.</label> -->
                <div v-if="fileKind == 'image' ">
                    <label class="btn btn-danger-soft mb-0" :for="content_id+'uploadimage'">이미지 업로드</label>

                    <input v-if="isMultiFile && fileKind =='image'" type="file" multiple="multiple" accept="image/*" @change="onChangeMultiFile" :id="content_id+'uploadimage'" class="form-control d-none" >
                    <input v-else type="file" accept="image/*" @change="onChangeFile" :id="content_id+'uploadimage'" class="form-control d-none" >
                </div>

                <div v-if="fileKind == 'file' ">
                    <label class="btn btn-success-soft mb-0" :for="content_id+'uploadfile'">첨부할파일 업로드</label>

                    <input v-if="isMultiFile && fileKind == 'file' "  type="file" multiple="multiple" @change="onChangeMultiFile" :id="content_id+'uploadfile'"  class="form-control d-none" >
                    <input v-else type="file" @change="onChangeFile" :id="content_id+'uploadfile'" class="form-control d-none" >
                </div>
            </div>
        </form>
    </div>
</div>    
</template>
    
<script>
var filename__= "[components > content > ContentIdFileUploadPanelComponent.vue] "

import axios from 'axios';

import ContentImageListComponent from './ContentImageListComponent.vue';
import ContentFileListComponent from './ContentFileListComponent.vue';

export default {
    components: {
        "content-image-list-component" : ContentImageListComponent,
        "content-file-list-component" : ContentFileListComponent,
    },
    props: [
        'content_id',   
        'isMultiFile',
        'fileKind','category','subCategory'
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,      

            inputWidth : 300,

            inputFileAdd : null,  
            inputFileAddTmp : null,  

            inputFileList : [], 
            inputFileTmpList : [], 

            itemList: [],
            itemFileList: [],
            
        }
    },
    beforeMount() {
    },     
    computed: {
    },    
    created(){
        this.getImageList()
        this.getFileList()
    },
    methods: {
        handleError(){
            this.error = null;

        },
        onChangeFile(evt){  // 파일 1개 업로드 
            const file = evt.target.files[0] ;
            const file_size = 1024*1024*10 ; // 10M 크기 

            if(file.size > file_size ){
                // this.dialogTitle ="Error" ;
                // this.error = "이미지 사이즈는 10M 이하만 업로드 됩니다.";
                alert("이미지 사이즈는 10M 이하만 업로드 됩니다.")
                return ;                       

            } else {
                this.inputFileAdd = file; 
                this.inputFileAddTmp = URL.createObjectURL(file);
                
                this.sendFileForm(); // 파일 업로드 
            }
        },
        onChangeMultiFile(evt){ // 파일 여러개 업로드 
                        
            const files = evt.target.files;  // this.$refs.file.files
            this.inputFileList = files ; // 업로드 할 내용  ;
                        
            for (var i = 0; i < files.length; i++ ){
                console.log(filename__+" onChangeMultiFile files["+i+"]:", files[i].name);  //~~log
                
                const file_size = 1024*1024*10 ; // 10M 크기 
                if(files[i].size > file_size ){
                    alert("이미지 사이즈는 10M 이하만 업로드 됩니다. [ 크기초 과파일 : "+files[i].name+ " ]")
                    return ;                       
                }

                // 참고자료 : https://marketsplash.com/tutorials/vue-js/vue-js-file-upload/
                // const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];    
                if(this.fileKind === 'image') {
                    const allowedFileTypes = ['image/jpeg', 'image/png','image/gif'];
                    if (!allowedFileTypes.includes(files[i].type)) {
                        alert('이미지 파일이 아닙니다.Please upload a JPEG, PNG. GIF');
                        return ;
                    }
                }            

                this.inputFileTmpList.push(URL.createObjectURL(files[i])); //미리보기용 
                
            }
            this.sendMultiFileForm(); // 멀티파일 업로드 
        },
        refreshForm(){
            this.getImageList();
        },
        refreshFormFile(){
            this.getFileList();
        },
        // content image 
        async getImageList(){
            let funcname__ ="getImageList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/file/contentid/all/'+this.content_id+'/'+this.category+'/'+this.subCategory+'/'+this.fileKind,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                        
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        img_id: item.img_id, 
                        photo:item.photo,
                    });
                }

                // console.log(filename__+''+funcname__+" list:", list);
                this.itemList = list;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  

            }

            this.isLoading = false ;            
        },
        // content file 
        async getFileList(){
            let funcname__ ="getFileList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/file/contentid/all/'+this.content_id+'/'+this.category+'/'+this.subCategory+'/'+this.fileKind,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                        
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        img_id: item.img_id, 
                        file:item.photo,
                    });
                }

                // console.log(filename__+''+funcname__+" list:", list);
                this.itemFileList = list;

                // console.log(filename__+''+funcname__+" itemFileList:", this.itemFileList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  

            }

            this.isLoading = false ;            
        },


        // 1개 파일 업로드         
        async sendFileForm(){
            let funcname__ ="sendFileForm() ";

            if(this.inputFileAdd == null ){
                this.dialogTitle ="Error" ;
                this.error = "업로드할 파일을 선택해주세요.";
                return ;                       
            }

            // 파일전송 FormData() : https://hagohobby.tistory.com/21 
            const formData = new FormData()
            formData.append('category', this.category);
            formData.append('subcategory', this.subCategory);
            formData.append('filekind', this.fileKind);
            formData.append('file', this.inputFileAdd);

            for (const value of formData.values()) {
                console.log(filename__+''+funcname__+' formData.value:', value);
            }

            this.isLoading = true ;
            try {
                let serverData = await axios({
                    method: 'post',
                    url: `/api/content/file/upload/contentid/${this.content_id}`,
                    headers: {
                        'Content-Type' : 'multipart/form-data',
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 
                    data: formData

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){

                        // console.log(filename__+''+funcname__+' data:', data);
                        
                        this.inputFileAdd = null ;
                        this.inputFileAddTmp = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '이미지 업로드 성공했습니다.';  
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                if(this.fileKind ==='image') {
                                    this.refreshForm();  // 이미지 리스트 다시 불러오기 

                                } else if(this.fileKind ==='file'){
                                    this.refreshFormFile();  // 파일 리스트  다시 불러오기 
                                }

                            }, 500);
                        }                        
                        
                        
                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        // this.dialogTitle ="Error" ;
                        // this.error = serverData.data.error_description;
                        alert(serverData.data.error_description);
                    }
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                // this.dialogTitle ="전송 실패" ;
                // this.error = error; 
                alert(error)
            }

            this.isLoading = false ;

        },
        // 멀티파일 업로드         
        async sendMultiFileForm(){
            let funcname__ ="sendMultiFileForm() ";

            if(this.inputFileList.length === 0 ){
                this.dialogTitle ="Error" ;
                this.error = "업로드할 파일을 선택해주세요.";
                return ;                       

            }

            // 파일전송 FormData() : https://hagohobby.tistory.com/21 
            const formData = new FormData()
            formData.append('category', this.category);
            formData.append('subcategory', this.subCategory);
            formData.append('filekind', this.fileKind);
            for (const file of this.inputFileList) {
                // 참고자료 : https://stackoverflow.com/questions/74507306/fastapi-returns-error-422-unprocessable-entity-when-i-send-multipart-form-dat
                formData.append('files', file);
            }

            for (const value of formData.values()) {
                console.log(filename__+''+funcname__+' formData.value:', value);
            }

            this.isLoading = true ;
            try {
                let serverData = await axios({
                    method: 'post',
                    url: `/api/content/file/multiupload/contentid/${this.content_id}`,
                    headers: {
                        'Content-Type' : 'multipart/form-data',
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 
                    data: formData

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){

                        console.log(filename__+''+funcname__+' data:', data);
                        
                        this.inputFileList = [] ;
                        this.inputFileTmpList = [] ;

                        this.dialogTitle ='Success' ;
                        this.error = '업로드 성공했습니다.';  
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                if(this.fileKind ==='image') {
                                    this.refreshForm();  // 이미지 리스트 다시 불러오기 


                                } else if(this.fileKind ==='file'){
                                    console.log(filename__+''+funcname__+' sucess file');
                                    this.getFileList();  // 파일 리스트  다시 불러오기 
                                }
                                
                            }, 500);
                        }   
                        
                        
                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        // this.dialogTitle ="Error" ;
                        // this.error = serverData.data.error_description;
                        alert(serverData.data.error_description)
                    }
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    }
}
</script>
    
<style scoped>

</style>