<template>
<div class="row g-4 mt-1">

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Course information START -->
    <div class="col-12">
        <div class="card bg-transparent border rounded-3 h-100">

            <!-- Catd header -->
            <div class="card-header bg-light border-bottom">
                <h5 class="card-header-title">{{inputName}}</h5>
            </div>

            <!-- Card body START -->
            <div class="card-body">

                <!-- Course image and info START -->
                <div class="row g-4">
                    <!-- Course info and avatar -->
                    <div class="col-md-12">
                        <!-- Info -->
                        <p class="mb-3 white-space-newline">{{inputContent}}</p>

                        <!-- Price -->
                        <hr/>
                        <h5 class="mb-3">{{inputEmail}}</h5>
                        
                        <!-- Avatar -->
                        <hr/>
                        <div class="d-sm-flex align-items-center">
                            <!-- Avatar image -->
                            <div class="ms-sm-3 mt-2 mt-sm-0">
                                <span v-if="inputStatus == 0" class="badge text-bg-danger">미처리</span>
                                <span v-if="inputStatus == 1" class="badge text-bg-info">확인중</span>
                                <span v-if="inputStatus == 2" class="badge text-bg-primary">진행중</span>
                                <span v-if="inputStatus == 8" class="badge text-bg-warning">보류중</span>
                                <span v-if="inputStatus == 9" class="badge text-bg-success">처리완료</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Course image and info END -->
            </div>
            <!-- Card body END -->
        </div>
    </div>
    <!-- Course information END -->


</div> <!-- Row END -->

</template>

<script>
var filename__= "[components > admin > faq > AdminContactusDetailComponent.vue] "
import axios from 'axios';

export default {
    components: {},
    props: [
        'id',
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputParent_id : null,
            inputName : null, 
            inputEmail : null, 
            inputContent : null,
            inputDate : null,
            inputStatus : null,
        }
    },
    created(){
        this.getItem();
    },    
    computed: {
        // detailLink(){
        //     return '/admin/contactus/'+this.id+'/'+this.parent_id+'?page='+this.page+"&search="+this.search ; 
        // },

    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }
        },
        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/faq/contactus/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요합니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+''+funcname__+' response:', data.response);
                    const contactus = data.response

                    this.inputParent_id = contactus.parent_id ;
                    this.inputName = contactus.name ;
                    this.inputEmail = contactus.email ;
                    this.inputContent = contactus.content ;

                    this.inputStatus = contactus.status ;
                    this.inputDate = contactus.date ;

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:',data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  
                }
            }

            this.isLoading = false ;     
        },

    },
    
}
</script>
<style scoped>
.white-space-newline {

    /* white-space: normal;
    white-space: nowrap;
    white-space: pre;
    white-space: pre-wrap;
    white-space: pre-line; */
    white-space: break-spaces;

    /* Global values */
    /* white-space: inherit;
    white-space: initial;
    white-space: unset; */

}

</style> 