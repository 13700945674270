<template>
<div>

    <!-- Sidebar START -->
    <!-- Sidebar END -->
    
    <!-- Page content START -->
	
	<!-- Top bar START -->
	<!-- Top bar END -->

	<!-- Page main content START -->
	<div class="page-content-wrapper-match border">

		<!-- Title -->
		<div class="row g-3 align-items-center justify-content-between">
			<div class="col-md-6">
				<h1 class="h3 mb-2 mb-sm-0">Notification</h1>
			</div>
            <div class="col-md-6 text-end">
                <router-link :to="backToLink" class="btn btn-secondary mb-0 me-2">목록</router-link>
            </div>
		</div>

        <admin-notification-detail-component :id="id" />
	</div>
    
</div>
</template>

<script>

import AdminNotificationDetailComponent from '../../../components/admin/notification/AdminNotificationDetailComponent.vue';

export default {
    components: {
        "admin-notification-detail-component" : AdminNotificationDetailComponent,
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,      
                        
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
        backToLink(){
            return '/admin/notifications?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },
    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			return ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        
    }
}
</script>

<style scoped>

</style>