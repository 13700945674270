<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="d-flex justify-content-between align-items-center">
        <!-- <div class="position-relative"> -->
        <div style="width:100px;">
            <button v-if="subseq == 1 " 
                    class="btn btn-sm btn-secondary mb-0 me-1" disabled
                ><i class="fas fa-arrow-alt-circle-up"></i></button>
            <button v-else
                    @click="upSeqCardnewsForm" 
                    class="btn btn-sm btn-info mb-0 me-1" 
                ><i class="fas fa-arrow-alt-circle-up"></i></button>

            <button v-if="is_max == false" 
                    @click="downSeqCardnewsForm" 
                    class="btn btn-sm btn-danger mb-0 me-1"
                ><i class="fas fa-arrow-circle-down"></i></button>
            <button v-else
                    class="btn btn-sm btn-secondary mb-0 me-1" disabled
                ><i class="fas fa-arrow-circle-down"></i></button>
                
        </div>

        <div>
            <content_id-cardnews-main-image-fileupload-panel-component
                :key="id+'cardnews'"
                :file-kind="'image'"
                :category="'topic'" 
                :sub-category="'cardnews'" 
                :content_id="id" 
                :width="200"
            />

            <!-- <div class="d-flex justify-content-between align-items-center">
                <img :src="photo" class="card-img-top" alt="course image" style="width:200px;">
            </div> -->
            <!-- <div class="text-center mt-1">
                <content_id-fileupload-list-component 
                    :key="id+'image'"
                    :is-multi-file='false'
                    :file-kind="'image'"
                    :category="'topic'" 
                    :sub-category="'cardnews'" 
                    :content_id="id"                     
                />
            </div> -->

        </div>
        <div class="text-center mt-1" style="width:450px;">
            <textarea class="form-control" rows="5" placeholder="필요시 카드뉴스 설명" v-model.trim="inputDescriptionUpdate"></textarea>
            <div class="d-flex justify-content-between align-items-center mt-2">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" role="switch" id="isText" checked="" v-model="inputIsText" @click="onChangeIsTextUpdate">
                    <label v-if="inputIsText" class="form-check-label" for="isText">텍스트 보임 </label>
                    <label v-else class="form-check-label" for="onoffPublic">텍스트 안보임</label>
                </div>

                <label v-if="isCompareComu" class="btn btn-sm btn-primary-soft me-1 mb-1" disabled >Save</label>
                <button v-else type="button" class="btn btn-sm btn-primary me-1 mb-1" @click="sendCardnewsUpdateForm" >Save</button>
            </div>
        </div>
        <div>
            <button type="button" class="btn btn-sm btn-danger-soft btn-round mb-0" @click="removeCardnewsForm"><i class="fas fa-fw fa-times"></i></button>                
        </div>
    </div>
    <!-- Divider -->
    <hr>

</div>    
</template>

<script>
// v-for__string__
var filename__ = "[components > companydash > CompanyDashCourseCardnewsListComponent.vue] " ;

import axios from 'axios';

import ContentIdCardnewsMainImageFileUploadPanelComponent from '../content/ContentIdCardnewsMainImageFileUploadPanelComponent.vue' ;
// import ContentIdFileUploadListComponent from '../content/ContentIdFileUploadListComponent.vue' ;

export default {
    components: {
        'content_id-cardnews-main-image-fileupload-panel-component': ContentIdCardnewsMainImageFileUploadPanelComponent,
        // 'content_id-fileupload-list-component': ContentIdFileUploadListComponent,
    },
    props: [ 
        'id','parent_id', //parent_id : 임시로 지정된 것이라 상위 컴포넌트에서 인식하지 못함
        'is_fronttext','description','photo',
        'subseq','is_min','is_max'
    ],
    emits:['refreshCardnewsList'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputIsText : this.is_fronttext,

            inputDescription: this.description,
            inputDescriptionUpdate: this.description,
        }
    },
    created(){
        // console.log(filename__+' created() description:', this.description);
        // console.log(filename__+' created() inputDescription:', this.inputDescription);
        // console.log(filename__+' created() inputDescriptionUpdate:', this.inputDescriptionUpdate);
    },    
    computed: {
        isCompareComu(){
            return this.inputDescription == this.inputDescriptionUpdate
        },
    },
    methods: {
        handleError(){
            this.error = null;
        },
        onChangeIsTextUpdate(){
            this.inputIsText = !this.inputIsText ;
            this.sendCardnewsIsTextUpdateForm();
        },

        async sendCardnewsUpdateForm(){
            let funcname__ ="sendCardnewsUpdateForm()" ;

            let dataForm = { 
                'description': this.inputDescriptionUpdate,
            } ;  

            // 텍스트 topic
            if( this.inputDescriptionUpdate === '' || this.inputDescriptionUpdate === undefined || this.inputDescriptionUpdate === null  ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="텍스트를 입력해주세요.";
                return ;
            }

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/curriculum/cardnews/update/'+this.id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.inputDescription = this.inputDescriptionUpdate ;
                        
                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            console.log(filename__+''+funcname__+' Success');
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                this.$emit('refreshCardnewsList') ;

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },
        async sendCardnewsIsTextUpdateForm(){
            let funcname__ ="sendCardnewsIsTextUpdateForm()" ;

            let dataForm = { 
                'is_fronttext': this.inputIsText,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/curriculum/cardnews/istext/'+this.id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            // console.log(filename__+''+funcname__+' Success');
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                // this.$emit('refreshCardnewsList') ;

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },

        async upSeqCardnewsForm(){
            let funcname__ ="upSeqCardnewsForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/curriculum/cardnews/upseq/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                
                                this.$emit('refreshCardnewsList') ;

                            }, 100);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        async downSeqCardnewsForm(){
            let funcname__ ="downSeqCardnewsForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/curriculum/cardnews/downseq/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                
                                this.$emit('refreshCardnewsList') ;

                            }, 100);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        async removeCardnewsForm(){
            let funcname__ ="removeCardnewsForm()" ;

            const isConfirm = confirm('Are you delete ?');
            if(!isConfirm) return ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/content/course/curriculum/cardnews/each/delete/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '삭제에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                this.$emit('refreshCardnewsList') ;

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },


    },
    
}
</script>
<style scoped>

</style> 