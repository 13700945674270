<template>
<div>

	<base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

		<!-- Title -->
		<div class="row mb-3">
			<div class="col-12 d-sm-flex justify-content-between align-items-center">
				<h1 class="h3 mb-2 mb-sm-0">
					강좌관리  
                    <small> > 
                        <span v-if="category === 'recommend'">추천 강좌</span>
                        <span v-else-if="category === 'getstarted'">Get Started</span>
                        <span v-else-if="category === 'degree'">Degree</span>
                        <span v-else-if="category === 'certificate'">Certificate</span>
                    </small>

                    <small> > {{ title }}</small>
				</h1>
				<div>
					<router-link :to="backToLink" class="btn btn-sm btn-secondary mb-0 me-2">목록</router-link>
					<router-link :to="addLink" class="btn btn-sm btn-primary mb-0">Add a Course</router-link>
				</div>
				
			</div>
		</div>

		<!-- Card START -->
		<div class="card bg-transparent border">

			<!-- Card body START -->
			<div class="card-body">
				<!-- Course table START -->
				<div class="table-responsive border-0 rounded-3">
					<!-- Table START -->
					<table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
						<!-- Table head -->
						<thead>
							<tr>
								<th scope="col" class="border-0 rounded-start">Course Name</th>
								<th scope="col" class="border-0">카테고리</th>
								<th scope="col" class="border-0">Type</th>
								<!-- <th scope="col" class="border-0">Price</th> -->
								<th scope="col" class="border-0">Status</th>
								<th scope="col" class="border-0">Rating</th>
								<th scope="col" class="border-0">Hide/Show</th>
								<th scope="col" class="border-0">Up/Down</th>
								<th scope="col" class="border-0 rounded-end">Delete</th>
							</tr>
						</thead>
						
						<!-- Table body START -->
						<tbody>
							<admin-course-recommend-list-component v-for="item in itemList"
								:key = "item.id"
								:id ="item.id"
								:seq="item.seq"
								:is_display="item.is_display"
								:min="item.min"
								:max="item.max"
																
								:content_id="item.content_id"
								:categorystr ="item.categorystr"
								:title ="item.title"
								:level ="item.level"
								:is_price ="item.is_price"
								:price ="item.price"
								:coursetime ="item.coursetime"
								:lecturecount ="item.lecturecount"
								:image ="item.image"
								:onoff="item.onoff"
								:reviewavg="item.reviewavg"
								:course_is_display="item.course_is_display"
								:company_is_display= "item.company_is_display"

								@changeRefreshList="emitRefreshList"

								
							/>
							
							<!-- Table row -->
<!-- 							
							<tr>
								<td>
									<div class="d-flex align-items-center position-relative">
										<div class="w-60px">
											<img src="/assets/images/courses/4by3/08.jpg" class="rounded" alt="">
										</div>
										<h6 class="table-responsive-title mb-0 ms-2">	
											<router-link to="/admin/course/detail" class="stretched-link">Building Scalable APIs with GraphQL</router-link>
										</h6>
									</div>
								</td>

								<td>
									<div class="d-flex align-items-center mb-3">
										<div class="avatar avatar-xs flex-shrink-0">
											<img class="avatar-img rounded-circle" src="/assets/images/avatar/09.jpg" alt="avatar">
										</div>
										<div class="ms-2">
											<h6 class="mb-0 fw-light">Lori Stevens</h6>
										</div>
									</div>
								</td>

								<td> 
									<span class="badge text-bg-orange">All level</span>
									<span class="badge text-bg-primary">Beginner</span> 
									<span class="badge text-bg-purple">Intermediate</span>
									<span class="badge text-bg-success">Advanced</span>
								</td>

								<td>$350</td>

								<td> 
									<span class="badge bg-warning bg-opacity-15 text-warning">Pending</span> 
									<span class="badge bg-success bg-opacity-15 text-success">Live</span>
									<a href="#" class="btn btn-sm btn-dark me-1 mb-1 mb-md-0">Edit</a>
								</td>
								<td>28 Oct 2021</td>
							</tr>
 -->

						</tbody>
						<!-- Table body END -->
					</table>
					<!-- Table END -->
				</div>
				<!-- Course table END -->
			</div>
			<!-- Card body END -->

		</div>
		<!-- Card END -->
	</div>
	<!-- Page main content END -->

    <!-- Page content END -->
    
    </div>
</template>

<script>
var filename__="[pages > admin > courserecommend > AdminCourseRecommendList.vue] "

import axios from 'axios';

import AdminCourseRecommendListComponent from '../../../components/admin/courserecommend/AdminCourseRecommendListComponent.vue' ;


export default {
    components: {
        "admin-course-recommend-list-component": AdminCourseRecommendListComponent,
    },
    props: ['category_id','category','title'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            

            itemList : [],
			inputCategoryId : this.category_id, //처음에 입력해줘야 됨
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
		backToLink(){
			return '/admin/course/recommendcategories/'+this.category ;
		},
		addLink(){
			return '/admin/course/recommend/add/'+this.category_id+'/'+this.category+'/'+this.title ; 
		}
    },    
    created(){
		// let funcname__="created()" ;
        // 같은 페이지를 파라미터 달리해서 불러올 때  
        // 참고자료 : https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
        this.$watch(
			() => this.$route.params,(toParams, previousParams) => {
					// console.log(filename__+funcname__+" $watch toParams: ", toParams['category_id']);
					// console.log(filename__+funcname__+ " $watch previousParams: ", previousParams['category_id']);
					console.log(" $watch previousParams: ", previousParams['category_id']);

					this.inputCategoryId = toParams['category_id'];

					this.getList();
				}
        )        
        this.getList();
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        isAdmin(){
            return this.$store.getters['auth/isAdmin'] ;
        },
		emitRefreshList(){
			this.getList();
		},
        async getList(){
			let funcname__ ="getList()" ;

			this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

			let serverData = await axios({
				method: 'get',
				url: '/api/course/recommend/all/'+this.inputCategoryId,
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},               

			}).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			// console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				// console.log('[AdminCourseCategoryList.vue] profile:', data.profile);
				
				let tmpList = data.response.data ;
				let list = [];


				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					let _course = item['course'] ;

					list.push({
						id: item.id, 
						seq:item.seq,
						is_display : item.is_display,
						min: item.min,
						max: item.max,

						content_id: _course.id,
						image: _course.main_photo ? _course.main_photo.main_photo_path : false ,
						categorystr:_course.categorystr,
						title:_course.title,
						level : _course.level,
						is_price : _course.is_price,
						price : _course.price,
						coursetime : _course.coursetime,
						lecturecount : _course.lecturecount,
						onoff : _course.onoff,
						reviewavg:_course.review_avg,
						course_is_display : _course.is_display,

						company_is_display : _course.company_is_display,						

					});
				}

				// console.log(filename__+''+funcname__+" tmpList:", tmpList);
				this.itemList = list;

				console.log(filename__+''+funcname__+" itemList:", this.itemList);

			} else {
				console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
				this.dialogTitle ="Error" ;
				this.error = serverData.data.error_description;
			}

			this.isLoading = false ;            
		},



    }
}
</script>

<style scoped>

</style>