<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>
	
    <!-- =======================
    Page Banner START -->
    <student-dashboard-top :title="'My Dashboard'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <student-dashboard-left :menu="'dashboard'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
    
                    <!-- Counter boxes START -->
                    <div class="row mb-4">
                        <!-- Counter item -->
                        <div class="col-sm-6 col-lg-6 mb-3 mb-lg-0">
                            <div class="d-flex justify-content-center align-items-center p-4 bg-orange bg-opacity-15 rounded-3">
                                <span class="display-6 lh-1 text-orange mb-0"><i class="fas fa-tv fa-fw"></i></span>
                                <div class="ms-4">
                                    <div class="d-flex">
                                        <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="9"	data-purecounter-delay="200">{{ inputTotalCount }}</h5>
                                    </div>
                                    <p class="mb-0 h6 fw-light">Total Courses</p>
                                </div>
                            </div>
                        </div>
                        <!-- Counter item -->
                        <div class="col-sm-6 col-lg-6 mb-3 mb-lg-0">
                            <div class="d-flex justify-content-center align-items-center p-4 bg-purple bg-opacity-15 rounded-3">
                                <span class="display-6 lh-1 text-purple mb-0"><i class="fas fa-clipboard-check fa-fw"></i></span>
                                <div class="ms-4">
                                    <div class="d-flex">
                                        <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="52"	data-purecounter-delay="200">{{ inputCompleteCount }}</h5>
                                    </div>
                                    <p class="mb-0 h6 fw-light">Complete coures</p>
                                </div>
                            </div>
                        </div>
                        <!-- Counter item -->
                        <!-- <div class="col-sm-6 col-lg-4 mb-3 mb-lg-0">
                            <div class="d-flex justify-content-center align-items-center p-4 bg-success bg-opacity-10 rounded-3">
                                <span class="display-6 lh-1 text-success mb-0"><i class="fas fa-medal fa-fw"></i></span>
                                <div class="ms-4">
                                    <div class="d-flex">
                                        <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="8" data-purecounter-delay="300">{{ inputCertificateCount }}</h5>
                                    </div>
                                    <p class="mb-0 h6 fw-light">Achieved Certificates</p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <!-- Counter boxes END -->
    
                    <div class="card bg-transparent border rounded-3">
                        <!-- Card header START -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="mb-0">My Courses List</h3>
                        </div>
                        <!-- Card header END -->
    
                        <!-- Card body START -->
                        <div class="card-body">
    
                            <!-- Search and select START -->
                            <div class="row g-3 align-items-center justify-content-between mb-4">
                                <!-- Content -->
                                <div class="col-md-8">
                                    <form @submit.prevent="onChangeSearch" class="rounded position-relative">
                                        <input class="form-control pe-5 bg-transparent" type="search" placeholder="Search" aria-label="Search" v-model.trim="inputSearch">
                                        <button class="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="submit">
                                            <i class="fas fa-search fs-6 "></i>
                                        </button>
                                    </form>
                                </div>

                                <!-- Select option -->
                                <div class="col-md-3 d-grid">
                                    <!-- Short by filter -->
                                    <!-- <form>
                                        <select class="form-select js-choice border-0 z-index-9 bg-transparent" aria-label=".form-select-sm">
                                            <option value="">Sort by</option>
                                            <option>Free</option>
                                            <option>Newest</option>
                                            <option>Most popular</option>
                                            <option>Most Viewed</option>
                                        </select>
                                    </form> -->
                                    <button type="button" @click="onChangeAll" class="btn btn-primary mb-0 ms-2">All</button>
                                </div>
                            </div>
                            <!-- Search and select END -->

                            <!-- Course list table START -->
                            <div class="table-responsive border-0">
                                <table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
                                    <!-- Table head -->
                                    <thead>
                                        <tr>
                                            <th scope="col" class="border-0 rounded-start">Num</th>
                                            <th scope="col" class="border-0">Course Title</th>
                                            <th scope="col" class="border-0">Total Lectures</th>
                                            <th scope="col" class="border-0">Completed</th>
                                            <th scope="col" class="border-0 rounded-end">Action</th>
                                        </tr>
                                    </thead>

                                    <!-- Table body START -->
                                    <tbody>

                                        <studentdash-course-list-component v-for="item in itemList"
                                            :key = "item.id"
                                            :id ="item.id"
                                            :num ="item.num"

                                            :title ="item.title"
                                            :level ="item.level"
                                            :coursetime ="item.coursetime"
                                            :lecturecount ="item.lecturecount"

                                            :image ="item.image"
                                            :is_text ="item.is_text"

                                            :date ="item.date"
                                            :update ="item.update"
                                            :is_display="item.is_display"

                                            :create_id = "item.create_id" 
                                            :create_name = "item.create_name" 
                                            :create_email = "item.create_email" 
                                            :create_photo = "item.create_photo" 

                                            :total_count="item.total_count"
                                            :complete_count="item.complete_count"
                                            :percent="item.percent"
                                            :enroll_date="item.enroll_date"

                                            :page="page" 
                                            :search="inputSearch" 
                                            
                                        />                                        
                                        
                                    </tbody>
                                    <!-- Table body END -->
                                </table>
                            </div>
                            <!-- Course list table END -->

                            <!-- Pagination START -->
                            <div v-if="itemList.length > 0"> 
                                <user-pagination :pagination="paginationCompu" @paging="pagingSend" />
                            </div>

                        </div>
                        <!-- Card body START -->
                    </div>
                <!-- Main content END -->
                </div><!-- Row END -->
            </div>
        </div>	
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
        
    <hr/>
    </div>
</template>

<script>
var filename__="[pages > studentdash > StudentDashboard.vue] "

import axios from 'axios';

import StudentDashboardTop from '../../components/studentdash/StudentDashboardTop.vue';
import StudentDashboardLeft from '../../components/studentdash/StudentDashboardLeft.vue';

import StudentDashCourseListComponent from '../../components/studentdash/StudentDashCourseListComponent.vue';
import UserPagination from '../../components/ui/UserPagination.vue';

export default {
    components: {
        "student-dashboard-top": StudentDashboardTop,
        "student-dashboard-left": StudentDashboardLeft,

        "studentdash-course-list-component" : StudentDashCourseListComponent,
        "user-pagination": UserPagination,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList: [], 
            page:1,
            current_path:"/studentdash/dashboard",  //current_path
			pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',    
            
            inputTotalCount : 0,
            inputCompleteCount :0,
            inputCertificateCount: 0,
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getList();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }    

    },  
    computed: {
        paginationCompu(){
            return this.pagination ;
        },

    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        onChangeSearch(){
			this.page = 1 ;
            this.getList(); //page=1
        },
        onChangeAll(){
			this.page = 1 ;
			this.inputSearch = '' ;
            this.getList(); //page=1
        },

        async getList(){
			let funcname__ ="getList()" ;

            this.itemList = [] ; 
			this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

			let serverData = await axios({
				method: 'get',
				url: '/api/student/course/order/user',
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},           
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                    search: this.inputSearch,       
                }

			}).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }
			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			// console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				// console.log('[AdminCourseCategoryList.vue] profile:', data.profile);

                this.inputTotalCount = data.response.enroll_count ;
                this.inputCompleteCount = data.response.complete_count ;
                // this.inputCertificateCount = certificate_count ;
                
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];

                let complete_count = 0 ;
                let certificate_count = 0;

				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
                    let _percent = parseInt( (item.completecount/item.lecturecount)*100 ); //완료 퍼센트 

                    list.push({
						num: counter--, 

                        id : item.content_id,
                        title : item.course_title,
                        image : item.course_image,
                        is_text : item.is_fronttext,
                        
						level : item.course_level,

                        coursetime : item.coursetime,
                        lecturecount : item.lecturecount,
                        is_display : item.is_display,
                        date : item.date,
                        update : item.update,
                        
						create_id: item.create_id,
                        create_photo: item.create_photo,
                        create_name: item.create_name,
                        create_email: item.create_email,

                        percent : _percent, 
                        total_count : item.totalcount,
                        complete_count : item.completecount,
                        enroll_date : item.date,

                        percent : _percent, 
                        
					});
				}

				// console.log(filename__+''+funcname__+" tmpList:", tmpList);
				this.itemList = list;

				// console.log(filename__+''+funcname__+" itemList:", this.itemList);
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);

                // console.log(filename__+''+funcname__+" inputTotalCount:", this.inputTotalCount);
                // console.log(filename__+''+funcname__+" inputCompleteCount:", this.inputCompleteCount);
                // console.log(filename__+''+funcname__+" inputCertificateCount:", this.inputCertificateCount);

			} else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

			this.isLoading = false ;            
		},

    }
}
</script>

<style scoped>

</style>