<template>
    <div>
        <div class="offcanvas-xl offcanvas-end" tabindex="-1" id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
            <!-- Offcanvas header -->
            <div class="offcanvas-header bg-light">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">My profile</h5>
                <button  type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close"></button>
            </div>
            <!-- Offcanvas body -->
            <div class="offcanvas-body p-3 p-xl-0">
                <div class="bg-dark border rounded-3 pb-0 p-3 w-100">
                    <!-- Dashboard menu -->
                    <div class="list-group list-group-dark list-group-borderless">
                        <router-link to="/studentdash/dashboard" class="list-group-item" :class="menu == 'dashboard' ?'active':''" ><i class="bi bi-ui-checks-grid fa-fw me-2"></i>Dashboard</router-link>
                        <router-link to="/studentdash/courses" class="list-group-item" :class="menu == 'course' ?'active':''" ><i class="bi bi-basket fa-fw me-2"></i>수강신청 내역</router-link>
                        <router-link to="/studentdash/wishlists" class="list-group-item" :class="menu == 'wishlist' ?'active':''"><i class="bi bi-cart-check fa-fw me-2"></i>위시 리스트</router-link>
                        <router-link to="/studentdash/faqs" class="list-group-item" :class="menu == 'faq' ?'active':''"><i class="bi bi-question-circle me-2"></i>나의 질문</router-link>
                        <!-- <router-link to="/studentdash/notifications" class="list-group-item" :class="menu == 'notification' ?'active':''"><i class="bi bi-bell fa-fw me-2"></i>My 메세지</router-link> -->
                        <router-link to="/studentdash/photo/update" class="list-group-item" :class="menu == 'photo' ?'active':''" ><i class="bi bi-person-bounding-box fa-fw me-2"></i>프로필 사진수정</router-link>
                        <router-link to="/studentdash/profile/update" class="list-group-item" :class="menu == 'update' ?'active':''" ><i class="bi bi-pencil-square fa-fw me-2"></i>프로필 정보수정</router-link>
                        <router-link to="/studentdash/profile/pwupdate" class="list-group-item" :class="menu == 'pwupdate' ?'active':''" ><i class="bi bi-key fa-fw me-2"></i>비밀번호 변경</router-link>
                        <router-link to="/studentdash/sector/update" class="list-group-item" :class="menu == 'sector' ?'active':''" ><i class="bi bi bi-diagram-2 fa-fw me-2"></i>관련직종 변경</router-link>
                        <router-link to="/studentdash/sector/location/update" class="list-group-item" :class="menu == 'location' ?'active':''" ><i class="bi bi-map fa-fw me-2"></i>활동지역 변경</router-link>
                        <router-link to="/studentdash/profile/delete" class="list-group-item" :class="menu == 'delete' ?'active':''"><i class="bi bi-trash fa-fw me-2"></i>회원탈퇴</router-link>
                        <a href="#" @click="logout" class="list-group-item text-danger bg-danger-soft-hover" ><i class="fas fa-sign-out-alt fa-fw me-2"></i>Log Out</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    components: {
    },
    props: ['menu'],
    data() {
        return {

        }
    },
    methods:{
        logout(){
            this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
            this.$store.dispatch('dashboard/Remove'); //==> store/modules/dashboard/actions.js
            this.$router.replace('/');
        },
    }
}
</script>

    