<template>
<div class="col-12">
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="card border rounded-3">
        <!-- Card header START -->
        <div class="card-header border-bottom">
            <h3 class="mb-0">Frequently Asked Questions</h3>
        </div>
        <!-- Card header END -->

        <!-- Card body START -->
        <div class="card-body">
            <!-- FAQ item -->
            <div v-for="item in itemList" :key="item.id"  class="mb-4">
                <h6 v-if="item.is_display">{{item.title}}</h6>
                <h6 v-else class="text-decoration-line-through">{{item.title}}</h6>
                <p v-if="item.is_display" class="white-space-newline mb-0">{{item.content}}</p>
                <p v-else class="text-decoration-line-through white-space-newline mb-0">{{item.content}}</p>
            </div>

        </div>
        <!-- Card body START -->
    </div>
</div>


</template>

<script>
var filename__ ="[components > comapnydash > CompanyDashCourseDetailFaqComponent.vue] " ;

import axios from 'axios';

export default {
    components: {},
    props: [ 'content_id'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList : [],
        }
    },
    created(){
        this.getList();        
    },    
    
    computed: {

    },
    methods: {
        handleError(){
            this.error = null;
        },

        async getList(){
            let funcname__ ="getList()" ;

            this.isLoading = true;

            let serverData = await axios({
                method: 'get',
                url: '/api/faq/course/all/'+this.content_id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },

            }).catch( err=> {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }


            });

            this.isLoading = false ;

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData !== "undefined" && serverData) {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+"  data:", data);

                if(data.status_code == 200 ){
                    
                    let tmpList = data.response.data ;
                    let list = [];

                    // console.log(filename__+''+funcname__+" tmpList:", tmpList);

                    let cnt=1
                    //메인 카테고리 값들을 입력하기 
                    for(let item of tmpList ){
                        list.push({
                            num: cnt++, 
                            id: item.id, 
                            title: item.title, 
                            content: item.content, 
                            is_display : item.is_display,
                            seq: item.seq, 
                            min : item.min,
                            max : item.max,
                        });
                    }

                    // console.log(filename__+''+funcname__+" list:", list);

                    this.itemList = list

                    // console.log(filename__+''+funcname__+" itemList:", this.itemList);

                }

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;

        },

    },
    
}
</script>