<template>
<section>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="container">
        <!-- Title -->
        <div class="row mb-4">
            <div class="col-lg-8 mx-auto text-center">
                <h2 class="fs-1">추천 강좌</h2>
                <p class="mb-0">워커스카페의 강좌는 Open되어 있습니다. 온라인 강좌 중에서 인기있고 추천하는 강좌들입니다.</p>
            </div>
        </div>

        <!-- Tabs START -->
        <ul class="nav nav-pills nav-pills-bg-soft justify-content-sm-center mb-4 px-3" id="course-pills-tab" role="tablist">
            <!-- Tab item -->
            <li class="nav-item me-2 me-sm-5" v-for="(item, idx) in itemList" :key="item.id">
                <button 
                    class="nav-link mb-2 mb-md-0" 
                    :class="idx == 0 ? 'active':''"
                    :id="'course-pills-tab-'+item.id" 
                    data-bs-toggle="pill" 
                    :data-bs-target="'#course-pills-tabs-'+item.id" 
                    type="button" 
                    role="tab" 
                    :aria-controls="'course-pills-tabs-'+item.id" 
                    aria-selected="false"
                >
                    {{ item.title }}
                </button>
            </li>

        </ul>
        <!-- Tabs END -->

        <!-- Tabs content START -->
        <div class="tab-content" id="course-pills-tabContent">
            <!-- Content START -->
            <div 
                v-for="(item, idx) in itemList"
                :key="item.id"
                class="tab-pane fade" 
                :class="idx === 0 ? 'show active':'' "
                :id="'course-pills-tabs-'+item.id" 
                role="tabpanel" 
                :aria-labelledby="'course-pills-tab-'+item.id"
                >
                <div class="row g-4">

                    <!-- Card item START -->
                    <div class="col-sm-6 col-lg-4 col-xl-3" v-for="subitem in item.courseList" :key="subitem.id">
                        <div class="card shadow h-100">
                            <!-- Image -->
                            <router-link :to="'/course/'+subitem.course.id+'?listpath=totalcourses&page=1&search='">
                                <img v-if="Object.entries(subitem.course.main_photo).length >0" :src="subitem.course.main_photo.main_photo_path" class="card-img-top" alt="course image">
                                <img v-else src="/assets/images/courses/4by3/08.jpg" class="card-img-top" alt="course image">
                            </router-link>
                            <!-- Card body -->
                            <div class="card-body pb-0">
                                <!-- Badge and favorite -->
                                <div class="d-flex justify-content-between mb-2">
                                    <a v-if="subitem.course.level ==0" href="#" class="badge bg-success bg-opacity-10 text-success">All level</a>
                                    <a v-else-if="subitem.course.level ==1" href="#" class="badge bg-primary bg-opacity-10 text-primary">Beginner</a>
                                    <a v-else-if="subitem.course.level ==2" href="#" class="badge bg-orange bg-opacity-10 text-orange">Intermediate</a>
                                    <a v-else-if="subitem.course.level ==3" href="#" class="badge bg-purple bg-opacity-10 text-purple">Advanced</a>
                                </div>
                                <!-- Title -->
                                <h5 class="card-title fw-normal">
                                    <router-link :to="'/course/'+subitem.course.id+'?listpath=totalcourses&page=1&search='">{{ subitem.course.title }}</router-link>
                                </h5>
                                <p class="mb-2 text-truncate-2">{{ subitem.course.subtitle }}</p>
                                <!-- Rating star -->
                                <ul class="list-inline mb-0">
                                    <li v-if="subitem.course.review_avg > 0" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                                    <li v-if="subitem.course.review_avg >= 1" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                                    <li v-if="subitem.course.review_avg >= 2" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                                    <li v-if="subitem.course.review_avg >= 3" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                                    <li v-if="subitem.course.review_avg >= 4" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                                    <li v-if="subitem.course.review_avg == 5" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>

                                    <li class="list-inline-item ms-2 h6 fw-light mb-0">{{subitem.course.review_avg}}/5.0</li>
                                </ul>
                            </div>
                            <!-- Card footer -->
                            <div class="card-footer pt-0 pb-3">
                                <hr>
                                <div class="d-flex justify-content-between">
                                    <span class="h6 fw-light mb-0"><i class="fas fa-table text-orange me-2"></i>{{subitem.course.lecturecount}}회 강의</span>
                                    <span class="h6 fw-light mb-0"><i class="far fa-clock text-danger me-2"></i>{{timeFormat(subitem.course.coursetime)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> <!-- Row END -->
            </div>
            <!-- Content END -->


        </div>
        <!-- Tabs content END -->
    </div>
</section>

</template>

<script>
// compu__ return__ time__ timeformat__
var filename__="[compnents > main > MainRecommendComponent.vue] "

import axios from 'axios';

// import MainInstallListComponentUser from './MainCourseInfoComponent.vue';

export default {
    components: {
        // 'main-install-list-component-user': MainInstallListComponentUser,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            isApplying : false, 
            error: null,     
            dialogTitle: null,    
            
            inputCategory : 'recommend',

            itemList : [],
        }
    },
    created(){
        this.getRecommendCategoryList();
    },
    computed: {
        timeFormat(){
            return(_coursetime) => {
                let hour = parseInt(_coursetime/60) ;
                let minute = _coursetime - hour * 60 ;
                if(hour == 0){
                    return _coursetime +'분'                
                } else if(minute == 0){
                    return hour+'시간'
                } else {
                    return hour + '시간 '+minute + '분' ;                
                }

            }
        },


    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        }, 
        async getRecommendCategoryList(){
            let funcname__ ="getCategoryList()" ;

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 
            this.itemList =[];

            let serverData = await axios({
                method: 'get',
                url: '/api/course/recommendcategory/all/user/'+this.inputCategory, //recommend

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log('[AdminCourseCategoryList.vue] profile:', data.profile);
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        title:item.title,
                        seq:item.seq,
                        is_display : item.is_display,
                        courseList : [],
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.itemList = list;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);
                this.isApplying = true ;

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ; 
            // console.log(filename__+''+funcname__+" this.isApplying:", this.isApplying);
            // console.log(filename__+''+funcname__+" itemList.length:", this.itemList.length);

            if(this.isApplying && this.itemList.length > 0){
                // console.log(filename__+''+funcname__+" &&:", true);
                
                this.isApplying = false;
                this.getRecommendList();
        
            }           
        },

        async getRecommendList(){
            let funcname__ ="getRecommendList()" ;

            this.isLoading = true ;   
            
            let serverData = await axios({
                method: 'get',
                url: '/api/course/recommend/main/user/'+'recommend',  //parent_category

            }).catch( err => {


                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log('[AdminCourseCategoryList.vue] profile:', data.profile);
                
                let tmpList = data.response ;

                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    for(let category of this.itemList){
                        if(item.category_id == category.id){
                            category.courseList.push({
                                id: item.id,
                                course : item.course,
                            })
                        }
                    }
                }

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }
            this.isLoading = false ;            
        },
       
    }
}

</script>

<style scoped>

</style>