<template>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- 삭제 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" id="idDeleteCompu">Delete</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>
    
    <tr>
        <td>
            {{ num }}
        </td>
        <td width="300">
            <router-link :to="updateLink" >
                <img v-if="image_path" :src="image_path" class="rounded" alt="" width="300">
                <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" alt="">
            </router-link>
        </td>
        <td>
            <router-link :to="updateLink" >
                <span v-if="inputIsDisplay" class="h5">{{title}}</span>
                <span v-else class="text-decoration-line-through h6"><i class="fas fa-eye-slash me-3"></i>{{title}}</span>
            </router-link>
            <p>{{ description }}</p>
            <p v-if="link" class="mt-1"><span class="badge text-bg-info me-2">link</span>{{ link }}</p>
        </td>
        <td>
            <div class="form-check form-switch form-check-md">
                <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsDisplay" @click="onChangeDisplay" />
                <label v-if="inputIsDisplay" class="form-check-label" for="profilePublic">보임 </label>
                <label v-else class="form-check-label" for="profilePublic">숨김</label>
            </div>
        </td>
        <td>
            <button type="button" @click="upSeqForm" :class="min? 'btn btn-sm btn-outline-light disabled':'btn btn-sm btn-outline-primary' "><i class="fas fa-arrow-alt-circle-up"></i></button>
            <button type="button" @click="downSeqForm" :class="max? 'btn btn-sm btn-outline-light ms-2 disabled': 'btn btn-sm btn-outline-danger ms-2' "><i class="fas fa-arrow-circle-down"></i></button>
        </td>
        <td>
            <button v-if="isDeleteCompu" type="button"  class="btn btn-sm btn-outline-danger" data-bs-toggle="modal" :data-bs-target="'#'+idDeleteCompu"><i class="bi bi-trash3"></i></button>
        </td>
        <td>
            <router-link :to="updateLink" class="btn btn-sm btn-info">수정</router-link>
        </td>
    </tr>


</template>

<script>
var filename__="[components > admin > mainslide > AdminMainSlideListComponent.vue]" ;

import axios from 'axios';

export default {
    components: {},
    props: [
        'id','num', 
        'image_path',
        'category','title','link','description',
        'is_display', 'seq', 'min','max',        
    ],
    emits:['emitRefreshList'],
    data() {
        return {
            isLoading: false,
			isApplyging: false,
            error: null,     
            dialogTitle: null,    

            inputIsDisplay: this.is_display,
        }
    },
    
    computed: {
        updateLink(){
            return '/admin/mainslide/update/'+this.id+"/"+this.category
        },
        idDeleteCompu(){
            return 'deleteModal'+this.id
        },
        isDeleteCompu(){
            // 1개만 보이기 && 보이기 true 
            if(this.category ==='single' && this.is_display === true){
                return false ;
            } else {
                return true ;
            }
        }

    },
    methods: {
        handleError(){
            this.error = null;
        },
        onChangeDisplay(){
            this.inputIsDisplay = !this.inputIsDisplay ;
            this.sendDisplayForm();
        },
        // 정보 업데이트  
        async sendDisplayForm(){
            let funcname__ ="sendDisplayForm()" ;

            let dataForm = { 
                'is_display': this.inputIsDisplay,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/mainslide/display/'+this.id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        this.isApplying = true ;

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 
                                this.$emit('emitRefreshList', this.category);
                            }, 500);
                        }

                    } else {
                        // console.log(filename__+''+funcname__+' return err:', serverData.data.msg);

                        this.dialogTitle ="변경 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }

            } catch(error){
                // console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="변경 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },
        async upSeqForm(){
            let funcname__ ="upSeqForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/mainslide/upseq/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        // this.dialogTitle ='Success' ;
                        // this.error = '성공했습니다.';     

                        this.$emit('emitRefreshList', this.category);

                    } else {
                        console.log(filename__+''+funcname__+' return err:', serverData.data.error_description);

                        this.dialogTitle ="수정 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                // console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        async downSeqForm(){
            let funcname__ ="downSeqForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/mainslide/downseq/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        // this.dialogTitle ='Success' ;
                        // this.error = '성공했습니다.';     

                        this.$emit('emitRefreshList', this.category);

                    } else {
                        console.log(filename__+''+funcname__+' return err:', serverData.data.error_description);

                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                // console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        async removeForm(){
            let funcname__ ="removeForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/mainslide/delete/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }  

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '삭제에 성공했습니다.';     

                        this.isApplying = true ;

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 

                                this.$emit('emitRefreshList', this.category);
                            }, 1000);
                        }

                    } else {
                        // console.log(filename__+''+funcname__+' return err:', serverData.data.error_description);

                        this.dialogTitle ="변경 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },


    },
    
}
</script>