import axios from "axios";

export default {

    async mainTopProductCategoryAction(context){

        const response = await axios( { 
            method: 'get', 
            url:'/api/product/categories/user'
        });
        
        console.log('[store/modules/product/actions.js_mainTopProductCategoryAcion()] response:', response);

        const responseData = await response.data ;
        console.log('[store/modules/product/actions.js_mainTopProductCategoryAcion()] responseData:', responseData);

        if(responseData.code === 200){            
            console.log('[store/modules/product/actions.js_mainTopProductCategoryAction()] responseData:', responseData);
            // console.log('[store/modules/auth/actions.js_login()] responseData.user:', responseData['user']);
            
            const productCategory = responseData['data'] ; 
            console.log('[store/modules/product/actions.js_mainTopProductCategoryAction()] productCategory type:', typeof productCategory['admin']);

            context.commit('setMainTopProductCategoryMutation', {
                
                admin : productCategory['admin'],
                user : productCategory['user'],

            });  // store/modules/product/mutations.js

        } else {

            const error = {
                code : responseData.code ,
                msg : responseData.msg 
            } ;           

            // console.error("error ==> [store/modules/auth/actions.js_signup()] : \n", error);
            
            throw error ;            
        }
    },   
      
}