<template>
    <tr>
        <td class="text-center text-sm-start">{{num}}</td> 
        <td>
            <div class="d-flex align-items-center">
                <!-- Image -->
                <div v-if="!is_text" class="w-60px">                    
                    <img v-if="course_image" :src="course_image" class="rounded" alt="">
                    <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" alt="">
                </div>
                <div class="mb-0 ms-2">
                    <p>{{course_title}}</p>
                </div>
            </div>
        </td>                
        <td>
            <router-link :to="detailLink" >
                <p v-if="is_display" class="text-body text-truncate-2">{{contentSlice30}}</p>
                <p v-else class="text-body text-truncate-2 text-decoration-line-through"><i class="fas fa-eye-slash me-3"></i>{{contentSlice30}}</p>
            </router-link>
            <!-- <div v-for="item in replylist" :key="item.id">
                <i class="fas fa-reply me-2"></i><span>{{ replySlice20(item.content) }}</span>
            </div> -->
        </td>
        <td>
            <ul class="list-inline mb-0">
                <li v-if="review => 1" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>

                <li v-if="review >= 2" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                <li v-if="review >= 3" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                <li v-if="review >= 4" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                <li v-if="review == 5" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

            </ul>	
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <router-link :to="detailLink" >
                    <div v-if="create_photo" class="avatar avatar-md mb-2 mb-md-0">
                        <img :src="create_photo" class="rounded" alt="">
                    </div>
                    <div class="mb-0 ms-2">
                        <span class="text-body small">{{nameSlice10}}</span>
                    </div>
                </router-link>
            </div>
        </td>
        <td>{{date}}</td>
        <td>
            <button v-if="status == 0" type="button" class="btn btn-danger-soft me-1 mb-1 mb-lg-0">미처리</button>
            <button v-if="status == 2" type="button" class="btn btn-primary-soft me-1 mb-1 mb-lg-0">진행중</button>
            <button v-if="status == 8" type="button" class="btn btn-warning-soft me-1 mb-1 mb-lg-0">보류중</button>
            <button v-if="status == 9" type="button" class="btn btn-success-soft me-1 mb-1 mb-lg-0">처리완료</button>
            <button v-if="status == 10" type="button" class="btn btn-success-soft me-1 mb-1 mb-lg-0">Pass</button>
        </td>
        <td>
            <router-link :to="detailLink" class="btn btn-sm btn-info-soft mb-0" >View</router-link>
        </td>


    </tr>

</template>

<script>
export default {
    components: {},
    props: [
        'id','num','create_id','create_name','create_email','create_photo',
        'course_title',
        'course_image','is_text',
        'is_display','description', 
        'date','update',
        'status', 'review',
        // 'replylist',
        'page', 'search'
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            // return '/instructordash/comment/'+this.id+'?page='+this.$route.query.page+"&search="+this.$route.query.search
            return '/companydash/review/'+this.id+'?page='+this.page+"&search="+this.search
        },
        nameSlice10(){
            if(this.create_name.length > 10) {
                return this.create_name.slice(0,10)+".."; 
            } else {
                return this.create_name ;
            }
            
        },
        contentSlice30(){
            if(this.description.length > 30) {
                return this.description.slice(0,30)+"...."; 
            } else {
                return this.description ;
            }
            
        },
        replySlice20(){
            return (_description)=> {  
                if(_description.length > 20){
                    return _description.slice(0,20)+"...."; 
                } else {
                    return _description ; 
                }              
                
            }            
        },

    },
    methods: {

    },
    
}
</script>