<template>
    <tr>
        <td class="text-center text-sm-start">{{num}}</td>       
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="w-60px">
                    <router-link :to="detailLink" >
                        <img v-if="image" :src="image" class="rounded" alt="">
                        <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" alt="">
                        <!-- <img src="/assets/images/element/gallery.svg" class="h-50px" alt=""></img> -->
                    </router-link>
                </div>
                <!-- Title -->
                <h6 class="table-responsive-title mb-0 ms-2">	
                    <router-link :to="detailLink" class="stretched-link">
                        <span v-if="is_display">{{title}}</span>
                        <span v-else class="text-decoration-line-through "><i class="fas fa-eye-slash me-3"></i>{{title}}</span>
                    </router-link>
                </h6>
            </div>
        </td>
        <td>             
            <span class="badge bg-success bg-opacity-15 text-success">{{ categorystr }}</span> 
        </td>
        <!-- <td>
            <div class="d-flex align-items-center mb-3">
                <div class="avatar avatar-xs flex-shrink-0">
                    <img v-if="create_photo" class="avatar-img rounded-circle" :src="create_photo" alt="avatar">
                    <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/09.jpg" alt="avatar">
                </div>
                <div class="ms-2">
                    <h6 class="mb-0 fw-light">{{create_name}}</h6>
                    <small>{{create_email}}</small>
                </div>
            </div>
        </td> -->

        <!-- <td>             
            <span v-if="onoff" class="badge text-bg-orange">Online</span>
            <span v-else  class="badge text-bg-primary">Offline</span> 
        </td> -->
        <td>             
            <span v-if="level == 0" class="badge text-bg-orange">All level</span>
            <span v-else-if="level == 1"  class="badge text-bg-primary">Beginner</span> 
            <span v-else-if="level == 2" class="badge text-bg-purple">Intermediate</span>
            <span v-else-if="level == 3" class="badge text-bg-success">Advanced</span>
        </td>

        <!-- <td>
            <span v-if="is_price" >{{price}}</span>
            <span v-else class="badge text-bg-success">Free</span>
        </td> -->

        <!-- Table data -->
        <td> 
            <!-- <span v-if="!is_active" class="badge bg-success bg-opacity-15 text-danger">비활성화</span> -->
            <span v-if="is_display" class="badge bg-success bg-opacity-15 text-success">Active</span>
            <span v-else class="badge bg-warning bg-opacity-15 text-warning">Pending</span> 
            <!-- <a href="#" class="btn btn-sm btn-dark me-1 mb-1 mb-md-0">Edit</a> -->
        </td>
        <!-- Table data -->
        <td>
            <ul class="list-inline mb-0">
                <li v-if="reviewavg >= 1" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <li v-if="reviewavg >= 2" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <li v-if="reviewavg >= 3" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <li v-if="reviewavg >= 4" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <li v-if="reviewavg == 5" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>                
                <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                <!-- <li class="list-inline-item ms-2 h6 fw-light">{{reviewavg}}</li> -->
            </ul>

        </td>
        <td>
            <span v-if="enroll_count > 0" class="badge bg-success bg-opacity-15 text-danger">{{ enroll_count }}</span>
        </td>
        <td>{{update}}</td>
    </tr>


</template>

<script>
export default {
    components: {},
    props: [
        'id','num','create_id','create_name','create_email','create_photo','date','update',
        'title','categorystr','level','is_price','price','coursetime','lecturecount','image','is_display', 'onoff','reviewavg',
        'page', 'search', 'is_active',
        'enroll_count'
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            return '/admin/course/'+this.id
        },
        timeFormat(){
            let hour = parseInt(this.coursetime/60) ;
            let minute = this.coursetime - hour * 60 ;
            if(hour == 0){
                return this.coursetime +'m'                
            } else if(minute == 0){
                return hour+'h'
            } else {
                return hour + 'h '+minute + 'm' ;                
            }
        }

    },
    methods: {

    },
    
}
</script>