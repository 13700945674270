<template>
    <div>

	<!-- Page main content START -->
	<div class="page-content-wrapper-match border">

		<!-- Title -->
		<div class="row">
			<div class="col-md-6 mb-3">
				<h1 class="h3 mb-2 mb-sm-0">파트너 세부정보</h1>
			</div>
            <div class="col-md-6 text-end">
                <router-link :to="backToLink" class="btn btn-secondary mb-0 me-2">목록</router-link>
            </div>

		</div>
				
		<div class="row g-4">

			<!-- Personal information START -->
			<div class="col-xxl-9">
                <admin-company-detail-component :id="id" :company_id="company_id" :is_status_btn="true" />
            </div>
			<!-- Personal information END -->

			<!-- Company Count : course, enroll, student START -->
			<div class="col-xxl-3">
				<admin-company-detail-course-count-component :company_id="company_id"  />
			</div>
			<!-- Company Count : course, enroll, student END -->
		
			<!-- Instructor course list START -->
			<div class="col-12">
				<admin-company-detail-course-panel-component :company_id="company_id"  />
			</div>
			<!-- Instructor course list END -->

			<!-- Student review START -->
			<!-- <div class="col-12"> -->
				<!-- <admin-company-detail-review-panel-component :company_id="company_id"  /> -->
			<!-- </div> -->
			<!-- Student review END -->

		</div> <!-- Row END -->
	</div>
	<!-- Page main content END -->


<!-- Page content END -->
    
    </div>
</template>

<script>
var filename__ = "[pages > admin > company > AdminCompanyDetail.vue]" ;

import AdminCompanyDetailComponent from '../../../components/admin/company/AdminCompanyDetailComponent.vue' ;
import AdminCompanyDetailCourseCountComponent from '../../../components/admin/company/AdminCompanyDetailCourseCountComponent.vue' ;
import AdminCompanyDetailCoursePanelComponent from '../../../components/admin/company/AdminCompanyDetailCoursePanelComponent.vue' ;
// import AdminCompanyDetailReviewPanelComponent from '../../../components/admin/company/AdminCompanyDetailReviewPanelComponent.vue' ;

export default {
    components: {
        "admin-company-detail-component": AdminCompanyDetailComponent,
        "admin-company-detail-course-count-component": AdminCompanyDetailCourseCountComponent,
        "admin-company-detail-course-panel-component": AdminCompanyDetailCoursePanelComponent,
        // "admin-company-detail-review-panel-component": AdminCompanyDetailReviewPanelComponent,
    },
    props: ['id','company_id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //일반 사용자 페이지
    },     

    computed: {
        backToLink(){
            return '/admin/companies?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },
		page(){
			return this.$route.query.page ;
		},
		search(){
			return this.$route.query.search ;
		}

    },    
    created(){
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            console.error(filename__+"created() 구현 필요 admin-company-detail-review-list-panel-component ");  //~~log
            
            return ;            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
    }
}
</script>

<style scoped>

</style>