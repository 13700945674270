<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>
	
    <!-- =======================
    Page Banner START -->
        <company-dashboard-top :title="'파트너 관리자 대시보드'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
            <div class="row">
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <company-dashboard-left :menu="'dashboard'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
    
                    <!-- Counter boxes START -->
                    <div class="row g-4">
                        <!-- Counter item -->
                        <div class="col-sm-6 col-lg-4">
                            <div class="d-flex justify-content-center align-items-center p-4 bg-warning bg-opacity-15 rounded-3">
                                <span class="display-6 text-warning mb-0"><i class="fas fa-tv fa-fw"></i></span>
                                <div class="ms-4">
                                    <div class="d-flex">
                                        <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="25"	data-purecounter-delay="200">{{inputCourseCount}}</h5>
                                    </div>
                                    <span class="mb-0 h6 fw-light">총 강의숫자</span>
                                </div>
                            </div>
                        </div>
                        <!-- Counter item -->
                        <div class="col-sm-6 col-lg-4">
                            <div class="d-flex justify-content-center align-items-center p-4 bg-info bg-opacity-10 rounded-3">
                                <span class="display-6 text-info mb-0"><i class="fas fa-gem fa-fw"></i></span>
                                <div class="ms-4">
                                    <div class="d-flex">
                                        <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="12" data-purecounter-delay="300">{{inputEnrollCount}}</h5>
                                        <!-- <span class="mb-0 h5">K</span> -->
                                    </div>
                                    <span class="mb-0 h6 fw-light">신청한 강의숫자</span>
                                </div>
                            </div>
                        </div>

                        <!-- Counter item -->
                        <div class="col-sm-6 col-lg-4">
                            <div class="d-flex justify-content-center align-items-center p-4 bg-purple bg-opacity-10 rounded-3">
                                <span class="display-6 text-purple mb-0"><i class="fas fa-user-graduate fa-fw"></i></span>
                                <div class="ms-4">
                                    <div class="d-flex">
                                        <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="25"	data-purecounter-delay="200">{{inputStudentCount}}</h5>
                                        <!-- <span class="mb-0 h5">K+</span> -->
                                    </div>
                                    <span class="mb-0 h6 fw-light">신청한 학생숫자</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- Counter boxes END -->
    
                    <!-- Chart START -->
                    <!-- <div class="row mt-5">
                        <div class="col-12">
                            <div class="card card-body bg-transparent border p-4 h-100">
                                <div class="row g-4">
                                    <div class="col-sm-6 col-md-4">
                                        <span class="badge text-bg-dark">Current Month</span>
                                        <h4 class="text-primary my-2">$35000</h4>
                                        <p class="mb-0"><span class="text-success me-1">0.20%<i class="bi bi-arrow-up"></i></span>vs last month</p>
                                    </div>
                                    <div class="col-sm-6 col-md-4">
                                        <span class="badge text-bg-dark">Last Month</span>
                                        <h4 class="my-2">$28000</h4>
                                        <p class="mb-0"><span class="text-danger me-1">0.10%<i class="bi bi-arrow-down"></i></span>Then last month</p>
                                    </div>
                                </div>
    
                                <div id="ChartPayout"></div>
    
                            </div>
                        </div>
                    </div> -->
                    <!-- Chart END -->
    
                    <!-- Course List table START -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card border bg-transparent rounded-3 mt-5">
                                <!-- Card header START -->
                                <div class="card-header bg-transparent border-bottom">
                                    <div class="d-sm-flex justify-content-sm-between align-items-center">
                                        <h3 class="mb-2 mb-sm-0">Courses</h3>
                                        <router-link to="/companydash/courses" class="btn btn-sm btn-primary-soft mb-0">View all</router-link>
                                    </div>
                                </div>
                                <!-- Card header END -->
    
                                <!-- Card body START -->
                                <div class="card-body">
                                    <div class="table-responsive border-0 rounded-3">
                                        <!-- Table START -->
                                        <table class="table table-dark-gray align-middle p-4 mb-0">
                                            <!-- Table head -->
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="border-0 rounded-start">Num</th>
                                                    <th scope="col" class="border-0">카테고리</th>
                                                    <th scope="col" class="border-0">Course Title</th>
                                                    <!-- <th scope="col" class="border-0">On/Off</th> -->
                                                    <!-- <th scope="col" class="border-0">Enrolled</th> -->
                                                    <!-- <th scope="col" class="border-0">Price</th> -->
                                                    <th scope="col" class="border-0">Level</th>
                                                    <th scope="col" class="border-0">Time</th>
                                                    <th scope="col" class="border-0">등록인원</th>
                                                    <th scope="col" class="border-0">UpDated</th>
                                                    <th scope="col" class="border-0 rounded-end">Action</th>
                                                </tr>
                                            </thead>
                                            <!-- Table body START -->
                                            <tbody>
                                                
                                                <companydash-course-list-component v-for="item in itemList"
                                                    :key = "item.id"
                                                    :id ="item.id"
                                                    :num ="item.num"

                                                    :categorystr ="item.categorystr"
                                                    :title ="item.title"
                                                    :level ="item.level"
                                                    :coursetime ="item.coursetime"
                                                    :lecturecount ="item.lecturecount"
                                                    
                                                    :image ="item.image"
                                                    :is_text="item.is_text"

                                                    :date ="item.date"
                                                    :update ="item.update"
                                                    :is_display="item.is_display"

                                                    :create_id = "item.create_id" 
                                                    :create_name = "item.create_name" 
                                                    :create_email = "item.create_email" 
                                                    :create_photo = "item.create_photo" 

                                                    :enroll_count ="item.enroll_count"

                                                    :page="page" 
                                                    
                                                />

                                            </tbody>
                                            <!-- Table body END -->
                                        </table>
                                        <!-- Table END -->
                                    </div>
    
                                    <!-- Pagination -->
                                    <div v-if="itemList.length > 0" class="me-2 ms-2"> 
                                        <dash-pagination :pagination="paginationCompu" @paging="pagingSend" ></dash-pagination>
                                    </div>

                                    <!-- <div class="d-sm-flex justify-content-sm-between align-items-sm-center mt-3">
                                        <p class="mb-0 text-center text-sm-start">Showing 1 to 8 of 20 entries</p>
                                        <nav class="d-flex justify-content-center mb-0" aria-label="navigation">
                                            <ul class="pagination pagination-sm pagination-primary-soft d-inline-block d-md-flex rounded mb-0">
                                                <li class="page-item mb-0"><a class="page-link" href="#" tabindex="-1"><i class="fas fa-angle-left"></i></a></li>
                                                <li class="page-item mb-0"><a class="page-link" href="#">1</a></li>
                                                <li class="page-item mb-0 active"><a class="page-link" href="#">2</a></li>
                                                <li class="page-item mb-0"><a class="page-link" href="#">3</a></li>
                                                <li class="page-item mb-0"><a class="page-link" href="#"><i class="fas fa-angle-right"></i></a></li>
                                            </ul>
                                        </nav>
                                    </div> -->
                                </div>
                                <!-- Card body START -->
                            </div>
                        </div>
                    </div>
                    <!-- Course List table END -->
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
    
    <hr/>
</div>
</template>

<script>
var filename__="[pages > companydash > CompanyDashboard.vue]"

import axios from 'axios';

import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';
import CompanyDashCourseListComponent from '../../components/companydash/CompanyDashCourseListComponent.vue';
import CompanyDashPagination from '../../components/ui/CompanyDashPagination.vue';


export default {
    components: {
        "company-dashboard-top": CompanyDashboardTop,
        "company-dashboard-left": CompanyDashboardLeft,
        "companydash-course-list-component" : CompanyDashCourseListComponent,
        "dash-pagination": CompanyDashPagination,

    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList : [],
            page:1,
            current_path:"/companydash/dashboard",  //current_path
			pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수    
            
            inputCourseCount : 0,
            inputEnrollCount: 0 ,
            inputStudentCount: 0 ,
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); 
    },     
    computed: {
        paginationCompu(){
            return this.pagination ;
        },
    },    
    created(){
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getList();            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },
        async getList(){
			let funcname__ ="getList()" ;

			this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

			let serverData = await axios({
				method: 'get',
				url: '/api/content/course/companydash/front',
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},           
                params: { 
                    page: this.page , 
                    search: this.inputSearch,       
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                }

			}).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			// console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				// console.log('[AdminCourseCategoryList.vue] profile:', data.profile);
				
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                this.inputCourseCount = counter ;

                this.inputEnrollCount = data.response.total_enrollcount ;
                this.inputStudentCount = data.response.total_studentcount ;

                let list = [];

				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					list.push({
						num: counter--, 
						id: item.id, 

						title:item.title,
						categorystr:item.categorystr,
						level : item.level,
                        coursetime : item.coursetime ? item.coursetime : false ,
                        lecturecount : item.lecturecount,
                        onoff : item.onoff,

                        image : item.main_photo ? item.main_photo.main_photo_path : false,
                        is_text : item.is_fronttext,

						seq:item.seq,
                        date : item.date,
                        update : item.update,
						is_display : item.is_display,

						create_name : item.create_name,
						create_email : item.create_email,
						create_photo : item.create_photo,

                        enroll_count : item.enroll_count,
                        
					});
				}

				// console.log(filename__+''+funcname__+" tmpList:", tmpList);
				this.itemList = list;

				// console.log(filename__+''+funcname__+" itemList:", this.itemList);
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);

			} else {
				console.log(filename__+''+funcname__+' err:');

				this.dialogTitle ="실패" ;                    
				this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
				// alert('error')
			}

			this.isLoading = false ;            
		},


    }
}
</script>

<style scoped>

</style>