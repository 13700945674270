<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <teleport to="body">
        <!-- Modal -->
        <!-- 참고자료 : https://getbootstrap.com/docs/5.2/components/modal/ -->
        <div class="modal fade" id="finishModal" tabindex="-1" aria-labelledby="finishModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-success">
                    <h1 class="modal-title fs-5" id="finishModalLabel">수강완료</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 강좌를 수강완료하시겠습니까?</p>
                    <p>해당 강좌에 대한 난이도를 선택해주세요</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">취소</button>
                    <button type="button" @click="sendForm(-1)" class="btn btn-success-soft" data-bs-dismiss="modal">쉬웠음</button>
                    <button type="button" @click="sendForm(0)" class="btn btn-primary-soft" data-bs-dismiss="modal">적당</button>
                    <button type="button" @click="sendForm(1)" class="btn btn-orange-soft" data-bs-dismiss="modal">어려웠음</button>
                </div>
                </div>
            </div>
        </div>        
    </teleport>

    <div class="col-12 mb-2">
        <!-- Title -->
        <h2>{{course.title}}</h2>
        <p v-if="!course.is_fronttext && course.kind ==2" class="form-control p-grey">{{course.subtitle}}</p>
        <!-- Content -->
        <ul class="list-inline mb-0">
            <li class="list-inline-item fw-light h6 me-3 mb-1 mb-sm-0"><i class="fas fa-star me-2"></i>{{course.review_avg}}/5.0</li>
            <li class="list-inline-item fw-light h6 me-3 mb-1 mb-sm-0"><i class="fas fa-user-graduate me-2"></i>{{ course.enroll_count }} Enrolled</li>
            <li class="list-inline-item fw-light h6 me-3 mb-1 mb-sm-0"><i class="fas fa-signal me-2"></i>
                <span v-if="course.level ===0"> All levels</span>
                <span v-if="course.level ===1"> Beginner</span>
                <span v-if="course.level ===2"> Intermediate</span>
                <span v-if="course.level ===3"> Advanced</span>
            </li>
            <li class="list-inline-item fw-light h6 me-3 mb-1 mb-sm-0"><i class="bi bi-patch-exclamation-fill me-2"></i>Last updated {{course.update}}</li>
            <!-- <li class="list-inline-item fw-light h6"><i class="fas fa-globe me-2"></i>English</li> -->
        </ul>
    </div>
    <!-- Title END -->

    <!-- 처음 시작 -->
    <div v-if="topicKindCompu == 0">
        <div v-if="topicUrlCompu && !course.is_fronttext && course.kind ==2" class="position-relative mt-3">
            <div class="ratio ratio-16x9 mt-3">
                <iframe 
                    :src="topicUrlCompu" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                ></iframe>                                                            
            </div>
        </div>
        <div v-else class="position-relative mt-3">
            <p class="form-control p-grey">{{course.subtitle}}</p>
        </div>
    </div>
    <!-- topic 동영상 강좌  -->
    <div v-if="topicKindCompu == 2">
        <div class="card shadow">
            <div class="card-body pb-0">
                <div class="position-relative mt-1 mb-3">
                    <div class="ratio ratio-16x9 mt-2">
                        <iframe 
                            :src="topicUrlCompu" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen
                        ></iframe>                                                            
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- topic 텍스트  강좌  -->
    <div v-if="topicKindCompu == 4">
        <div class="position-relative mt-3">
            <div class="card shadow">
                <div class="card-body pb-0">
                    <p class="mt-2" v-html="markedDescriptionCompu"></p>
                </div>
                <hr v-if="itemFileList.length > 0" />
                <div v-if="itemFileList.length > 0 " class="mt-2 ms-4">
                    <label class="form-label" >첨부파일</label> 
                    <ul>
                        <li v-for="item in itemFileList" :key="item.id">
                            <a :href="item.filepath" v-text="item.filename" @click.prevent="downloadItem(item.filepath, item.filename)" />
                        </li>
                    </ul>
                </div>

            </div>
            
        </div>
    </div>
    <!-- topic 이미지  강좌  -->
    <div v-if="topicKindCompu == 1">
        <div class="position-relative mt-3 mb-2">
            <div class="card shadow">
                <div class="card-body pb-0 mb-3">
                    <img :src="topicPhotoCompu" class="img-fluid border border-white border-3 shadow"  alt="" />
                </div>
            </div>
            
        </div>
    </div>

    <!-- topic 카드뉴스 강좌  -->
    <div v-if="topicKindCompu == 5">
        <div class="position-relative mt-3 mb-2">
            <div class="card shadow">
                <div class="card-body pb-0 mb-3">

                    <div id="carouselExampleCaptions" class="carousel slide">
                        <div class="carousel-indicators">
                            <button v-for="item in itemCardnewsList"
                                type="button" 
                                data-bs-target="#carouselExampleCaptions" 
                                :data-bs-slide-to="item.count" 
                                :class="item.count ==0 ? 'active':''" 
                                :aria-current="item.count==0 ? true : false " 
                                :aria-label="'Slide '+item.count">
                            </button>
                            <!-- <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button> -->
                        </div>
                        <div class="carousel-inner">
                            <div v-for="item in itemCardnewsList" 
                                class="carousel-item"
                                :class="item.count==0 ? 'active':''"
                                >
                                <img :src="item.photo" class="d-block w-100" >
                                <div v-if="item.is_fronttext" class="carousel-caption d-md-block">
                                    <div class="alert alert-primary" role="alert">
                                        {{ item.description }}
                                    </div>                                    
                                </div>
                            </div>
                            <!-- <div class="carousel-item active">
                                <img src="/uploads/content/topic/cardnews/202310041848_kTXeF.jpg" class="d-block w-100" >
                                <div class="carousel-caption d-none d-md-block">
                                    <p style="color:white">Some representative placeholder content for the first slide.</p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="/uploads/content/topic/cardnews/202310042121_DGzdM.jpg" class="d-block w-100" alt="...">
                                <div class="carousel-caption d-none d-md-block">
                                    <p>Some representative placeholder content for the second slide.</p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="/uploads/content/topic/cardnews/202310042121_rYqes.jpg" class="d-block w-100" alt="...">
                                <div class="carousel-caption d-none d-md-block">
                                    <p>Some representative placeholder content for the third slide.</p>
                                </div>
                            </div> -->
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                    

                </div>
            </div>
            
        </div>
    </div>


    <div class="col-12 d-sm-flex justify-content-between align-items-center mt-2">							
        <h5> 
            <span class="badge bg-orange bg-opacity-10 text-orange me-2">{{topicTitleComu}}</span>
        </h5>
        <div  v-if="(is_exist && isTopic)">
            <button v-if="topicIsCompleteCompu"  type='button' class="btn btn-secondary" disabled >학습완료된 콘첸츠</button>
            <button v-else type='button' class="btn btn-success" data-bs-toggle="modal" data-bs-target="#finishModal">학습완료</button>
        </div>
    </div>

<!-- 
    <div class="col-12 position-relative">
        <div class="video-player rounded-3">
            <video controls crossorigin="anonymous" playsinline poster="/assets/images/videos/poster.jpg">
                <source src="/assets/images/videos/360p.mp4" type="video/mp4" size="360">
                <source src="/assets/images/videos/720p.mp4" type="video/mp4" size="720">
                <source src="/assets/images/videos/1080p.mp4" type="video/mp4" size="1080">
                <track kind="captions" label="English" srclang="en" src="/assets/images/videos/en.vtt" default>
                <track kind="captions" label="French" srclang="fr" src="/assets/images/videos/fr.vtt">
            </video>
        </div>
    </div> 
-->                        						

</div>


</template>

<script>
var filename__ = "[components > course > CourseDetailCenterComponent.vue] " ;

import axios from 'axios';
import { marked } from 'marked'; 

export default {
    components: {},
    props: [
        'content_id','course','company_id',
        'is_exist',
        'topic_id','topic_title',
        'topic_kind','topic_url','topic_description','topic_photo',
        'topic_iscomplete',
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,     

            isDetail : true, 
			
            itemFileList : [],
            itemCardnewsList : [],

            inputTopic_iscomplete : null,

            slide: 0,
            sliding: null            
        }
    },
    created(){
    },
    computed: {
        topicTitleComu(){
            // console.log(filename__+' topicTitleComu() topic_title:', this.topic_title);

            if(this.topic_title){
                return this.topic_title ;
            } else {
                return '';
            }
        },
        topicKindCompu(){
            // console.log(filename__+' topicKindCompu() topic_kind:', this.topic_kind);
            if(this.topic_kind === 4){
                // console.log(filename__+' topicKindCompu() getFileList()');                
                this.getFileList() ;
            
            } else if(this.topic_kind === 5){
                console.log(filename__+' topicKindCompu() getCardnewsList()');                
                this.getCardnewsList() ;
            }

            return this.topic_kind ;  //토픽 클릭한 내용 보여주기 
        },
        topicUrlCompu(){
            return this.topic_url ;  //토픽 클릭한 내용 보여주기 
        },
        markedDescriptionCompu(){
            return marked(this.topic_description);
        },        
        topicPhotoCompu(){
            return this.topic_photo ;  //토픽 클릭한 내용 보여주기 
        },
        topicIsCompleteCompu(){
            // let funcname__ = 'topicIsCompleteCompu()' ;
            // console.log(filename__+''+funcname__+' inputTopic_iscomplete:', this.inputTopic_iscomplete);
            return this.inputTopic_iscomplete ;
        },
		isTopic(){
			if(this.topic_id){
				return true ;
			}else {
				return false ;
			}
		},

    },
    methods: {
        handleError(){
            this.error = null;
        },
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        },        
        async getFileList(){
            let funcname__ ="getFileList() ";

            this.itemFileList = [] ;

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/file/contentid/all/'+this.topic_id+'/topic/description/file',
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                        
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        filename: item.photo.replace(/^.*[\\\/]/, ''), 
                        filepath:item.photo,
                    });
                }

                // console.log(filename__+''+funcname__+" list:", list);
                this.itemFileList = list;

                console.log(filename__+''+funcname__+" itemFileList:", this.itemFileList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  

            }

            this.isLoading = false ;            
        },
        async downloadItem(_filepath, _filename) {
            axios.get(_filepath, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/octet-stream' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = _filename
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        },        

        async getCardnewsList(){
            let funcname__ ="getCardnewsList() ";
            console.log(filename__+funcname__+' this.topic_id:', this.topic_id);    

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/course/curriculum/cardnews/all/user/'+this.content_id+'/'+this.topic_id,
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                        
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response.data ;
                let list = [];
                let count= 0 ;

                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        count: count++,
                        id: item.id, 
                        description: item.description, 
                        photo:item.topic_photo,
                        is_fronttext:item.is_fronttext,
                    });
                }

                console.log(filename__+''+funcname__+" list:", list);
                this.itemCardnewsList = list;

                console.log(filename__+''+funcname__+" itemCardnewsList:", this.itemCardnewsList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  
            }

            this.isLoading = false ;            
        },

        // 부모 컴포넌트에서 선택함, sendForm에서 값을 변환시키기 위해서  
        changeTopicBtn(_is_complete){
            this.inputTopic_iscomplete = _is_complete ;
        },
        
        async sendForm(_difficulty){
			let funcname__ ="sendForm()" ;
			let dataForm = { 
				'company_id': this.company_id,
				'content_id': parseInt(this.content_id),
				'topic_id': this.topic_id,
				'difficulty': _difficulty,
			} ;  

			// console.log(filename__+''+funcname__+' dataForm:', dataForm);

			this.isLoading = true ;

			try {
				let serverData = await axios({
					method: 'post',
					url: '/api/student/topic/create',
					data: dataForm,
					headers: {
						Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
					}, 

				}).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

				});

				if(typeof serverData === "undefined"  ||  serverData.status !== 200){
					this.isLoading = false ;
					return ;

				} else {
					// console.log(filename__+''+funcname__+" serverData:", serverData);

					if(typeof serverData !== "undefined" && serverData.status === 200) {
						const data = serverData.data ;
						// console.log(filename__+''+funcname__+' data:', data);

						if(data.status_code === 201){
														
							this.dialogTitle ='Success' ;
							this.error = '수강완료 확인했습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.inputTopic_iscomplete = true ; //현재 학습완료된 버튼으로 변경 
                
                                    this.$emit('changeTopic') ; // 커리큐럼에 수강완료 표시하기 위해서 다시 불러오기 

                                }, 500);
                            }                        


						} else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
						}
					} else {
						this.isLoading = false ;

						this.dialogTitle ='error' ;
						this.error = '관리자에게 문의 부탁합니다.';

					}
				}


			} catch(error){
				console.error(filename__+''+funcname__+' error:',error);

				this.isLoading = false ;

				this.dialogTitle ="전송 실패" ;
				this.error = error; 
			}

			this.isLoading = false ;
		},


    },
    
}
</script>