<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Modal -->
    <!-- 참고자료 : https://getbootstrap.com/docs/5.2/components/modal/ -->

    <!-- 답변 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idCompu" tabindex="-1" aria-labelledby="replyModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-info">
                    <h1 class="modal-title fs-5" id="replyModalLabel">답변하기</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <label class="form-label">Your Comment *</label>
                    <textarea class="form-control" rows="3" v-model.trim="inputReplyDescription"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="replySendForm" class="btn btn-primary" data-bs-dismiss="modal">Reply comment</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

    <!-- 수정 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idUpdateCompu" tabindex="-1" aria-labelledby="replyModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-info">
                    <h1 class="modal-title fs-5" id="replyModalLabel">수정하기</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <label class="form-label">Your Comment</label>
                    <textarea class="form-control" rows="3" v-model.trim="inputUpdateDescription"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="updateForm" class="btn btn-info" data-bs-dismiss="modal">Update comment</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

    <!-- 삭제 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" id="idDeleteCompu">Delete Comment</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

    <!-- List  -->
    <!-- <div v-if="depth==1" class="border p-0 p-sm-4 rounded-3 mb-3"> -->
        <div class="my-4 d-flex" :class="depth_class" >
            <div class="avatar avatar-sm flex-shrink-0">
                <img v-if="is_display && create_photo" class="avatar-img rounded-circle" :src="create_photo" alt="">
                <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user.png" alt="">
            </div>

            <div class="ms-2">
                <div class="mb-2">
                    <h6 v-if="is_display" class="m-0">{{create_name}}</h6>
                    <span class="me-3 small">{{date}}</span>
                </div>            
                <div class="bg-light p-3 rounded mb-2">
                    <div class="d-flex justify-content-center">
                        <div class="me-2">
                            <p v-if="is_display" class="mb-0 white-space-newline">{{description}}</p>
                            <p v-else class="mb-0"><i class="bi bi-exclamation-circle"></i> 보기 중단된 메세지입니다.</p>
                        </div>
                        <!-- <small>{{date}}</small> -->
                    </div>
                </div>
                <!-- <ul class="nav nav-divider py-2 small">
                    <li class="nav-item"> <a class="text-primary-hover" href="#"> Like (3)</a> </li>
                    <li class="nav-item"> <a class="text-primary-hover" href="#"> Reply</a> </li>
                    <li class="nav-item"> <a class="text-primary-hover" href="#"> View 5 replies</a> </li>
                </ul> -->
                <!-- 로그인 && 사용자가 현재글 사용자와 같지 않음 && 답글 depth < 7  -->
                <button v-if="isAuth && isUser != create_id && (depth < 7) && is_display" type="button" class="btn btn-sm btn-primary mb-0" data-bs-toggle="modal" :data-bs-target="idShapCompu">Reply</button>
                <!-- 로그인 안됐을 때 답글 못함  -->
                <button v-if="!isAuth && is_display" type="button" disabled class="btn btn-sm btn-primary mb-0" >Reply</button>

                <button v-if="isUser == create_id" type="button" class="btn btn-sm btn-info mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idUpdateShapCompu"><i class="bi bi-pencil"></i></button>
                <button v-if="isUser == create_id" type="button" class="btn btn-sm btn-danger mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu"><i class="bi bi-trash"></i></button>
            </div>
        </div>
    <!-- </div> -->
  
</div>        
</template>

<script>
var filename__ ="[components > comment > CourseDetailCommentListComponent.vue] " ;

// import axios from 'axios';

export default {
    components: {},
    props: [
        'id','group_id',
        'is_display','description','depth','seq','date',
        'create_id','create_name','create_photo'
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputReplyDescription : null, 
            inputUpdateDescription : this.description ,
        }
    },
    
    computed: {
        depth_class(){
            if(this.depth === 1){
                return ''
            
            } else {
                return "ps-"+this.depth+" ps-md-"+(this.depth+2)
            }    
        },
        idCompu(){
            return 'replyModal'+this.id
        },
        idShapCompu(){
            return '#replyModal'+this.id
        },
        idUpdateCompu(){
            return 'updateModal'+this.id
        },
        idUpdateShapCompu(){
            return '#updateModal'+this.id
        },
        idDeleteCompu(){
            return 'deleteModal'+this.id
        },
        idDeleteShapCompu(){
            return '#deleteModal'+this.id
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },

    },
    methods: {
        replySendForm(){
            this.$emit('replySendForm', this.id, this.group_id, this.inputReplyDescription)
            this.inputReplyDescription = null ;
        },
        updateForm(){
            this.$emit('updateForm', this.id, this.inputUpdateDescription)
            this.inputUpdateDescription = null ;
        },
        removeForm(){
            this.$emit('removeForm', this.id)
        },

    },


    
}
</script>