<template>
<div class="row g-4 position-relative align-items-center justify-content-center">

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Counter item -->
    <div class="col-sm-4 col-lg-4 text-center">
        <div class="d-flex justify-content-center">
            <h4 class="purecounter display-6 text-orange fw-bold mb-0" data-purecounter-start="0" data-purecounter-end="89"	data-purecounter-delay="200">{{ inputStudentCount }}</h4>
            <span class="display-6 text-orange mb-0">명</span>
        </div>
        <h6 class="mb-0 fw-bold">현재 총 수강생</h6>
    </div>

    <!-- Counter item -->
    <div class="col-sm-4 col-lg-4 text-center">
        <div class="d-flex justify-content-center">
            <h4 class="purecounter display-6 text-orange fw-bold mb-0" data-purecounter-start="0" data-purecounter-end="25"	data-purecounter-delay="200">{{ inputCompanyCount }}</h4>
            <span class="display-6 text-orange mb-0">명</span>
        </div>
        <h6 class="mb-0 fw-bold">현재 총 파트너 수</h6>
    </div>

    <!-- Counter item -->
    <div class="col-sm-4 col-lg-4 text-center">
        <div class="d-flex justify-content-center">
            <h4 class="purecounter display-6 text-orange fw-bold mb-0" data-purecounter-start="0" data-purecounter-end="180"	data-purecounter-delay="200">{{ inputCourseCount }}</h4>
            <span class="display-6 text-orange mb-0">개</span>
        </div>
        <h6 class="mb-0 fw-bold">총 강좌 수</h6>
    </div>

    <!-- Counter item -->
    <!-- <div class="col-sm-6 col-lg-3 text-center">
        <div class="d-flex justify-content-center">
            <h4 class="purecounter display-6 text-orange fw-bold mb-0" data-purecounter-start="0" data-purecounter-end="20"	data-purecounter-delay="200">{{ inputCertificateCount }}</h4>
            <span class="display-6 text-orange mb-0">개</span>
        </div>
        <h6 class="mb-0 fw-bold">인증 코스</h6>
    </div> -->
</div> <!-- Row END -->

</template>

<script>
var filename__= "[components > company > MainCourseInfoComponent.vue] "
import axios from 'axios';

export default {
    components: {},
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputCourseCount: 0, 
            inputCompanyCount: 0,
            inputStudentCount: 0,  //등록한 갯수 
            inputCertificateCount: 0 ,

        }
    },
    created(){
        // this.getCourseCount();
        console.log(filename__+" created(): this.getCourseCount() 작성 필요 ");  //~~log
        this.getCompanyCount();
        // this.getStudentCount();
        console.log(filename__+" created(): this.getInstructorCount() 작성 필요 ");  //~~log
    },
    computed: {

    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        // 코스 콘텐츠 갯수 
        async getCourseCount(){
            let funcname__ =" getCourseCount() "

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/course/all/count',

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputCourseCount = data.response.display_count ;  
                this.inputCertificateCount = data.response.certificate_count ;  

                // console.log(filename__+''+funcname__+" TotalCount:", data.response.total_count);
                // console.log(filename__+''+funcname__+" inputCourseCount:", this.inputCourseCount);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="입력 실패" ;
                this.error = serverData.data.error_description;

            }

            this.isLoading = false ;            
        },

        // 파트너 등록숫자 
        async getCompanyCount(){
            let funcname__ =" getCompanyCount() "
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/company/count/user',

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputCompanyCount = data.response ;           

                console.log(filename__+''+funcname__+" inputCompanyCount:", this.inputCompanyCount);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="입력 실패" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },

        // 전체 수강 학생수  
        async getStudentCount(){
            let funcname__ =" getStudentCount() "
            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/student/course/order/allstudent/count',

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+"serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputStudentCount = data.response ;           

                // console.log(filename__+''+funcname__+" inputStudentCount:", this.inputStudentCount);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="입력 실패" ;
                this.error = serverData.data.error_description;
                
            }

            this.isLoading = false ;            
        },


    },
    
}
</script>