<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- 삭제 모달  -->
    <div class="modal fade" id="deleteMainPhoto" tabindex="-1" aria-labelledby="deleteMainPhotoLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header bg-warning">
                <h1 class="modal-title fs-5" id="deleteMainPhoto">Delete</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p>해당 이미지를 삭제하시겠습니까?</p>
                <p>삭제된 내용은 복구되지 않습니다.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
            </div>
            </div>
        </div>
    </div>    

    <form @submit.prevent="sendFileForm" class="row g-4 mb-2">
        <div class="col-12 justify-content-center align-items-center">
            <label class="form-label">Front Image </label>
            <div class="d-flex align-items-center">
                <div v-if="photo"  >
                    <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                        <!-- Avatar place holder -->
                        <div class="card card-xl">
                            <img :src="photo" id="uploadfile-1-preview" class="img-fluid border border-white border-3 shadow"  :width="widthCompu" alt=""/>
                        </div>
                        <!-- Remove btn -->
                        <button type="button" class="uploadremove" data-bs-toggle="modal" data-bs-target="#deleteMainPhoto">
                            <i class="bi bi-x text-white"></i>
                        </button>
                    </label>
                </div>
                <div v-else-if="phototmp" >
                    <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                        <!-- Avatar place holder -->
                        <div class="card card-xl">
                            <img :src="phototmp" id="uploadfile-1-preview" class="img-fluid border border-white border-3 shadow" :width="widthCompu"  alt="" />
                        </div>
                        <!-- Remove btn -->
                        <button type="button" @click="resetImage" class="updateremove"><i class="bi bi-x text-white"></i></button>
                    </label>
                    <!-- Upload button -->
                    <label class="btn btn-primary-soft mb-0" for="uploadfile">Save</label>
                    <input type="submit" id="uploadfile" class="form-control d-none" >
                </div>

                <div v-else >
                    <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                        <!-- Avatar place holder -->
                        <span class="card card-xl">
                            <i class="far fa-image fa-2xl" style="font-size:200px"></i>
                            <!-- <img id="uploadfile-1-preview" class="card-img rounded-circle border border-white border-3 shadow" src="/assets/images/avatar/user.png" alt=""> -->
                        </span>
                    </label>

                    <!-- Upload button -->
                    <label class="btn btn-primary-soft mb-0" for="uploadfile-1">Change</label>
                    <input type="file" accept="image/*" ref="photoref" @change="onChangeFile" id="uploadfile-1" class="form-control d-none" >
                </div>
            </div>
        </div>
    </form>
    <div class="col-md-4">
        <label class="form-label">Image Width(쎔네일 이미지 3:2 비율 600x400, 300x200)</label>
        <div class="input-group">
            <input type="text" v-model.trim="inputWidth" class="form-control" placeholder="width">
        </div>
    </div>

</div>    
</template>
    
<script>
var filename__= "[components > admin > content > AdminMainImagePanelUpdateComponent.vue] "
import axios from 'axios';

export default {
    components: {
    },
    props: ['content_id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,      

            inputCategory : 'blog',
            inputSubCategory : 'main',
            inputKind : 'image',

            inputWidth : 300,
            inputPhoto : null ,
            inputPhotoUpdate : null,  
            inputPhotoTmp : null, 

            inputId : 0 ,             
        }
    },
    beforeMount() {
    },     

    computed: {
        photo(){
            return this.inputPhoto ;
        },
        phototmp(){
            return this.inputPhotoTmp ;
        },
        widthCompu(){
            return this.inputWidth ;
        },
    },    
    created(){
        this.getItem()
    },
    methods: {
        handleError(){
            this.error = null;

        },
        resetImage: function(){
            this.inputPhotoUpdate = null;  
            this.inputPhotoTmp = null;
        },
        onChangeFile(evt){
            const file = evt.target.files[0] ;
            this.inputPhotoUpdate = file;  
            this.inputPhotoTmp = URL.createObjectURL(file);
        },

        // 정보 가져오기 
        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/file/contentid/'+this.content_id+'/blog/main/image',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){
                    
                    // console.log(filename__+''+funcname__+' response:', data.response);

                    const photo = data.response ;
                    if(photo){
                        this.inputId = data.response.id ;
                        this.inputPhoto = data.response.photo ? data.response.photo  : false ;
                    }
                    // console.log(filename__+''+funcname__+' inputPhoto:', this.inputPhoto);
                           
                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;
                }
            }
            this.isLoading = false ;   
        },

        // 메인 사진 업로드         
        async sendFileForm(){
            let funcname__ ="sendFileForm() ";
            
            if(this.inputPhotoUpdate == null){
                this.dialogTitle ="Error" ;
                this.error = "업로드할 사진을 선택해주세요.";
                return ;                       

            }

            this.inputCategory ='blog' ;
            this.inputSubCategory ='main' ;
            this.inputKind ='image' ;

            // 파일전송 FormData() : https://hagohobby.tistory.com/21 
            const formData = new FormData()
            formData.append('category', this.inputCategory);
            formData.append('subcategory', this.inputSubCategory);
            formData.append('kind', this.inputKind);
            formData.append('photo', this.inputPhotoUpdate);

            for (const value of formData.values()) {
                console.log(filename__+''+funcname__+' formData.value:', value);
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: `/api/content/file/upload/contentid/${this.content_id}`,
                    headers: {
                        'Content-Type' : 'multipart/form-data',
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 
                    data: formData

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){

                        console.log(filename__+''+funcname__+' data:', data);
                        
                        this.inputId = data.id ;
                        this.inputPhoto = data.photo_path ;
                        this.inputPhotoUpdate = null ;
                        this.inputPhotoTmp = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '이미지 업로드 성공했습니다.';  
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                            }, 500);
                        }                        
                        
                        
                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                }

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // 메인  사진 삭제  
        async removeForm(){
            let funcname__ ="removeForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: `/api/content/file/delete/id/${this.inputId}`,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){
                        // console.log(filename__+''+funcname__+' data:', data);
                        
                        this.inputId = null ;
                        this.inputPhoto = null ;
                        this.inputPhotoUpdate = null ;
                        this.inputPhotoTmp = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '이미지 삭제 성공했습니다.';    

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }

                }                    

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="실패" ;
                this.error = error; 
            }
            this.isLoading = false ;

        },
    
    }
}
</script>
    
<style scoped>

</style>