import mutations from './mutations.js' ;
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state(){
        return {
            is_loading: false,

            name : null,
            copyright: null, 
            email: null, 
            description : null, 
            phone : null, 
            worktime : null, 
            supportemail : null, 
            contactaddr : null,

            is_active : null, 
        }
    },    
    mutations,
    actions,
    getters
}