<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="card card-body border p-4">
            
        <!-- Buttons -->
        <div class="mt-0 mb-2 d-grid">
            <router-link :to="addLink" class="btn btn-primary">공동작업자 추가하기</router-link>
        </div>
        <!-- Divider -->

        <!-- worker info -->
        <companydash-course-detail-worker-component 
            v-for="(item, idx) in itemList" 
            :key="item.id"
            :id="item.id"
            :name="item.user_name"
            :email="item.user_email"
            :photo="item.user_photo"
            :is_max="isMaxCompu(idx)"
            @sendRefreshForm='sendChageFreshForm'
        />

    </div>

</div>
</template>

<script>
// return__ param__
var filename__ ="[components > companydash > CompanyDashCourseDetailWorkerPanelComponent.vue] " ;

import axios from 'axios';

import CompanyDashCourseDetailWorkerComponent from './CompanyDashCourseDetailWorkerComponent.vue' ;

export default {
    components: {
        "companydash-course-detail-worker-component" : CompanyDashCourseDetailWorkerComponent,
    },
    props: [
        'content_id','company_id'
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            dialogTitle: null, 
            
            itemList: [],

            page:1,
            // current_path:"/instructordash/requests",  //current_path
			pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

        }
    },
    created(){        
        this.getList();      
    },        
    computed: {
        addLink(){
            return '/companydash/course/worker/add/list/'+this.content_id+'/'+this.company_id ;
        },
        isMaxCompu(){
            return(_idx) => {
                if(_idx === this.itemList.length-1) return false;
                else return true ;
            }
            
        }

    },
    methods: {
        handleError(){
            this.error = null;

        },
        sendChageFreshForm(){
            this.getList();
        },
        async getList(){

            let funcname__ ="getList()" ;

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/workercourse/all/companydash/'+this.content_id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
                params: { 
                    page: this.page , 
                    search: this.inputSearch,       
                    page_per_count : this.inputPagePerCount,
                    block_size : this.inputBlockSize,
                }
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        num: counter--, 
                        id: item.id, 

                        user_id: item.user_id,
                        user_name: item.create_name ,
                        user_email: item.crate_email,
                        user_photo : item.create_photo,

                        is_active:item.is_active,
                        is_display:item.is_display,

                        date: item.date,
                        update: item.update,
                        outdate: item.outdate,
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.itemList = list;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },


    },
    
}
</script>