export default {
    //  from : actions
    setMainTopProductCategoryMutation(state, payload){
        console.log('[store/product/mutations.js_setMainTopProductCategoryMutation()]', payload);
        console.log('[store/product/mutations.js_setMainTopProductCategoryMutation()] admin', payload.admin);
        console.log('[store/product/mutations.js_setMainTopProductCategoryMutation()] typeof admin', typeof payload.admin);
        
        // for (let item of payload.user){
        //     state.mainTopProductCategoryUserState.push(item)
        // }

        state.mainTopProductCategoryAdminState = payload.admin;
        state.mainTopProductCategoryUserState = payload.user;
        // state.mainTopProductCategoryUserState.push(payload.user);

        console.log('[store/product/mutations.js_setMainTopProductCategoryMutation()] state.user', state.mainTopProductCategoryUserState);

    },

}