<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>
	
    <!-- =======================
    Main Banner START -->
        <company-dashboard-top :title="'강좌별 공동작업자'" />
    <!-- =======================
    Main Banner END -->
    
    <!-- =======================
    Inner part START -->
    <section class="pt-0">
        <div class="container">
            <div class="row">
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <company-dashboard-left :menu="'courseworker'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Card START -->
                    <div class="card border bg-transparent rounded-3">
                        <!-- Card header START -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="mb-0">Course 공동작업자 리스트</h3>
                        </div>
                        <!-- Card header END -->
    
                        <!-- Card body START -->
                        <div class="card-body">
    
                            <!-- Search and select START -->
                            <div class="row g-3 align-items-center justify-content-between mb-4">
                                <!-- Search -->
                                <!-- <div class="col-md-12">
                                    <form @submit.prevent="onChangeSearch" class="rounded position-relative">
                                        <input class="form-control pe-5 bg-transparent" type="search" placeholder="Course Search" aria-label="Search" v-model.trim="inputSearch">
                                        <button type="submit" class="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" >
                                            <i class="fas fa-search fs-6 "></i>
                                        </button>
                                    </form>
                                </div> -->
    
                                <!-- Select option -->
                                <!-- <div class="col-md-3">
                                    <form>
                                        <select class="form-select js-choice border-0 z-index-9 bg-transparent" aria-label=".form-select-sm">
                                            <option value="">Sort by</option>
                                            <option>Free</option>
                                            <option>Newest</option>
                                        </select>
                                    </form>
                                </div> -->
                            </div>
                            <!-- Search and select END -->
    
                            <!-- Course list table START -->
                            <div class="table-responsive border-0">
                                <table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
                                    <!-- Table head -->
                                    <thead>
                                        <tr>
                                            <th scope="col" class="border-0 rounded-start">Num</th>
                                            <th scope="col" class="border-0">Worker</th>
                                            <th scope="col" class="border-0">Course</th>
                                            <th scope="col" class="border-0 rounded-end">Enrolled Date</th>
                                        </tr>
                                    </thead>
    
                                    <!-- Table body START -->
                                    <tbody>

                                        <companydash-course-worker-list-component v-for="item in itemList"
                                            :key = "item.id"
                                            :id ="item.id"
                                            :num ="item.num"
                                            :content_id ="item.content_id"
                                            :title ="item.course_title"
                                            :level ="item.course_level"
                                            :coursetime ="item.coursetime"
                                            :lecturecount ="item.lecturecount"

                                            :course_image ="item.course_image"
                                            :is_text="item.is_text"

                                            :date ="item.date"
                                            :update ="item.update"
                                            :is_display="item.is_display"

                                            :create_id = "item.create_id" 
                                            :create_name = "item.create_name" 
                                            :create_email = "item.create_email" 
                                            :create_photo = "item.create_photo" 

                                            :page="page" 
                                            
                                        />

                                        <!-- Table item -->
    
                                    </tbody>
                                    <!-- Table body END -->
                                </table>
                            </div>
                            <!-- Course list table END -->
    
                            <!-- Pagination START -->
                            <div v-if="itemList.length > 0" class="me-2 ms-2"> 
                                <dash-pagination :pagination="paginationCompu" @paging="pagingSend" ></dash-pagination>
                            </div>

                            <!-- Pagination END -->
                        </div>
                        <!-- Card body START -->
                    </div>
                    <!-- Card END -->
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Inner part END -->
    
    </main>
        
    <hr/>
</div>
</template>

<script>
var filename__="[pages > companydash > CompanyDashCourseWorkerList.vue]" ;

import axios from 'axios';

import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';
import CompanyDashCourseWorkerListComponent from '../../components/companydash/CompanyDashCourseWorkerListComponent.vue';
import CompanyDashPagination from '../../components/ui/CompanyDashPagination.vue';

export default {
    components: {
        "company-dashboard-top": CompanyDashboardTop,
        "company-dashboard-left": CompanyDashboardLeft,
        "companydash-course-worker-list-component" : CompanyDashCourseWorkerListComponent,
        "dash-pagination": CompanyDashPagination,
    },

    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            dialogTitle: null,       
            
            itemList : [],
            page:1,
            current_path:"/companydash/course/workers",  //current_path
			pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',     

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); 
    },     
    computed: {
        paginationCompu(){
            return this.pagination ;
        },
    },    
    created(){
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getList();            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        onChangeSearch(){
			this.page = 1 ;
            this.getList(); //page=1
        },

        async getList(){
			let funcname__ ="getList()" ;
            this.itemList = [] ; 
			this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

			let serverData = await axios({
				method: 'get',
				url: '/api/workercourse/worker/all/companydash',
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},           
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                }

			}).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			// console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				// console.log('[AdminCourseCategoryList.vue] profile:', data.profile);
				
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];

				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					list.push({
						num: counter--, 
						id: item.id, 
						content_id: item.content_id, 
						course_title: item.course_title,
						course_level : item.course_level,
                        coursetime : item.coursetime,
                        lecturecount : item.lecturecount,

                        course_image : item.course_image,
                        is_text:item.is_fronttext,

                        is_display : item.is_display,

                        date : item.date,
                        update : item.update,

                        create_name : item.create_name,
						create_email : item.create_email,
						create_photo : item.create_photo,
                        
					});
				}

				// console.log(filename__+''+funcname__+" tmpList:", tmpList);
				this.itemList = list;

				// console.log(filename__+''+funcname__+" itemList:", this.itemList);
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);

			} else {
				console.log(filename__+''+funcname__+' err:');

				this.dialogTitle ="실패" ;                    
				this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
				// alert('error')
			}

			this.isLoading = false ;            
		},

    }
}
</script>

<style scoped>

</style>