import mutations from './mutations.js' ;
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state(){
        return {
            is_load: false,

            categorylist : null,
        }
    },    
    mutations,
    actions,
    getters
}