<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Authenticating..." fixed>
        <p>Authentiacting...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>

	<section class="p-0 d-flex align-items-center position-relative overflow-hidden">
	
		<div class="container-fluid">
			<div class="row">
				<!-- left -->
				<div class="col-12 col-lg-6 d-md-flex align-items-center justify-content-center bg-primary bg-opacity-10 vh-lg-60">
					<div class="p-3 p-lg-5">
						<!-- Title -->
						<div class="text-center">
							<h2 class="fw-bold">워커스카페에 오신 것을 환영합니다</h2>
							<p class="mb-0 h6 fw-light">플랫폼 노동에 대한 다양한 정보를 접할 수 있습니다.</p>
						</div>
						<!-- SVG Image -->
						<img src="assets/images/element/02.svg" style="height:350px;" class="mt-5" alt="">
						
						<!-- <div class="d-sm-flex mt-5 align-items-center justify-content-center">
							<ul class="avatar-group mb-2 mb-sm-0">
								<li class="avatar avatar-sm"><img class="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt="avatar"></li>
								<li class="avatar avatar-sm"><img class="avatar-img rounded-circle" src="assets/images/avatar/02.jpg" alt="avatar"></li>
								<li class="avatar avatar-sm"><img class="avatar-img rounded-circle" src="assets/images/avatar/03.jpg" alt="avatar"></li>
								<li class="avatar avatar-sm"><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt="avatar"></li>
							</ul>
						
							<p class="mb-0 h6 fw-light ms-0 ms-sm-3">오늘 4명의 학생이 등록했습니다. 이제 당신의 차례입니다.</p>
						</div> -->
					</div>
				</div>

				<!-- Right -->
				<div class="col-12 col-lg-6 m-auto">

					<div class="row my-5">
						<div class="col-sm-10 col-xl-8 m-auto">
							<!-- Title -->
							<img src="assets/images/element/03.svg" class="h-40px mb-2" alt="">
							<h2>회원 등록</h2>
							<p class="lead mb-4">반갑습니다! 회원등록 페이지입니다.</p>
						
							<!-- Form START -->
							<form @submit.prevent="sendForm">
								<!-- Email -->
								<div class="mb-4">
									<label for="exampleInputEmail1" class="form-label">이메일 주소 *</label>
									<div class="input-group input-group-lg">
										<span class="input-group-text bg-light rounded-start border-0 text-secondary px-3"><i class="bi bi-envelope-fill"></i></span>
										<input type="email" class="form-control border-0 bg-light rounded-end ps-1" placeholder="E-mail" id="exampleInputEmail1" 
                                            v-model.trim="inputEmail" />
									</div>
								</div>
								<!-- Password -->
								<div class="mb-4">
									<label for="inputPassword5" class="form-label">암호 *</label>
									<div class="input-group input-group-lg">
										<span class="input-group-text bg-light rounded-start border-0 text-secondary px-3"><i class="fas fa-lock"></i></span>
										<input v-if="isShowPassword" type="text" class="form-control border-0 bg-light rounded-end ps-1" placeholder="****** 6자리이상" id="inputPassword5" v-model.trim="inputPassword" />
										<input v-else type="password" class="form-control border-0 bg-light rounded-end ps-1" placeholder="****** 6자리이상" id="inputPassword5" v-model.trim="inputPassword" />
                                        <span class="input-group-text p-0 bg-transparent">
                                            <i class="far cursor-pointer p-2 w-40px"
                                                :class="{ 'fa-eye-slash': !isShowPassword, 'fa-eye': isShowPassword }"
                                                @click="toggleShow"                                                    
                                            ></i>
                                        </span>
									</div>
                                    <div class="rounded mt-1" id="psw-strength"></div>
								</div>
								<!-- Confirm Password -->
								<div class="mb-4">
									<label for="inputPassword6" class="form-label">암호 확인 *</label>
									<div class="input-group input-group-lg">
										<span class="input-group-text bg-light rounded-start border-0 text-secondary px-3"><i class="fas fa-lock"></i></span>
										<input v-if="isShowPassword" type="text" class="form-control border-0 bg-light rounded-end ps-1" placeholder="****** 6자리이상" id="inputPassword6" v-model.trim="inputPasswordConfirm" />
										<input v-else type="password" class="form-control border-0 bg-light rounded-end ps-1" placeholder="****** 6자리이상" id="inputPassword6" v-model.trim="inputPasswordConfirm" />
									</div>
								</div>


                                
								<!-- Check box -->
								<div class="mb-4">
									<div class="form-check">
										<input type="checkbox" class="form-check-input" id="checkbox-1"
                                            v-model="inputCheck" >
										<label class="form-check-label" for="checkbox-1">회원등록 버튼을 클릭하시면 <a href="#"> 회원 계약서</a>를 승인하시게 됩니다.</label>
									</div>
								</div>
								<!-- Button -->
								<div class="align-items-center mt-0">
									<div class="d-grid">
										<button type="submit" class="btn btn-primary mb-0" >회원등록</button>
									</div>
								</div>
							</form>
							<!-- Form END -->

							<!-- Sign up link -->
							<div class="mt-4 text-center">
								<span>이미 회원이시라고요?<router-link to="/signin"> 여기에서 로그인 하세요</router-link></span>
								<span><router-link to="/signup/sector"> signup sector</router-link></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</main>
                
    <hr/>
</div>
</template>

<script>
var filename__ = "[pages > auth > Signup.vue]" ;

import axios from 'axios';

export default {
    components: {
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            dialogTitle: null,       

            formIsValid: true,
            
            isShowPassword : false, 
            inputEmail : null, 
            inputPassword : null, 
            inputPasswordConfirm : null, 
            inputCheck : false, 

            inputIsActive : null, 

        }
    },
    computed: {
        is_activeCompu(){
            console.log(filename__,'computed() > is_activeCompu() :',this.$store.getters['setting/is_active'])
            return this.$store.getters['setting/is_active'];
        },        
    },    
    created() {
        // 가입시 자동 로그인 가능 여부 
        this.getItem()
        // let is_loading = this.$store.getters['setting/is_loading'];
      
    // 한번도 불러오지 않았으면, is_active :회워가입시 자동으로 로그인 가능 활성화 여부  
    //   if(!is_loading) {
    //     // console.log("Signup.vue에서 getSetting() 해야됨 ")
    //     this.getSetting();
        
    //   } 
        
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle ==='Success'){
                this.$router.replace('/'); 
            }             
        },
        toggleShow() {
            this.isShowPassword = !this.isShowPassword;
        },        
        async getSetting(refresh=false){
            
            // this.isLoading = true;

            try {
                await this.$store.dispatch('setting/LoadSetting' , { foreceRefresh: refresh } );  //  /store/modules/message/actions.js + mutations.js   

                // this.isLoading = false ;             
            } catch(err){
                console.error('[App.vue]store.disptach() err:',err);
                // this.dialogTitle ="Error" ;
                // this.error = err;
            }

            // this.isLoading = false ;
            
        },

        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/setting/approve/user',

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+''+funcname__+'  response:', data.response);
                    const setting = data.response
                    this.inputIsActive = setting.is_active ;

                } else {
                    console.log(filename__+''+funcname__+' err:');

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  // this.error = err.msg || 'Failed to authenticate';                
                    // alert('error')
                }
            }

            this.isLoading = false ;     
        },

        async sendForm(){

            //validation
            this.formIsValid = true ;

            if( this.inputEmail === '' || this.inputEmail === undefined || this.inputEmail === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이메일 주소가 이상이 있습니다.";
                return ;
            }

            if( !this.inputEmail.includes('@') ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="이메일 주소가 이상이 있습니다.";
                return ;
            }

            if( this.inputPassword.length < 6 )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="비밀번호는 6자리 이상이어야 합니다.";
                return ;
            }

            if( this.inputPassword !== this.inputPasswordConfirm )  {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="확인용 비밀번호가 다릅니다.";
                this.inputPasswordConfirm = '' ;
                return ;
            }

            if( this.inputCheck === '' || this.inputCheck === undefined || this.inputCheck === false ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="가입승인 동의를 체크해주세요.";
                return ;
            }

            this.isLoading = true ;

            //send http request...
            const actionPayload = {
                email: this.inputEmail,
                password: this.inputPassword
            };

            try {

                await this.$store.dispatch('auth/Signup', actionPayload);  //  /store/modules/auth/actions.js                    


                this.inputEmail = '';
                this.inputPassword = '';
                this.inputPasswordConfirm = '';

                this.dialogTitle ="Success" ;

                //관리자에서 관리자 승인 여부 체크 가능 
                if(this.inputIsActive) {
                    this.error = "회원가입에 성공했습니다." ;

                    setTimeout(() => {
                        this.error = null;
                        this.dialogTitle = null 
                        this.$router.replace('/signup/sector'); 
                    }, 500);

                } else {
                    this.error = "회원가입에 성공했습니다. 관리자 승인 후 사용 가능합니다."
                }

                
            
            } catch(err){ 
                // console.error('[Signup.vue_sendForm() err:',err);

                this.dialogTitle ="회원가입 실패" ;
                this.error = err.error_description ;
            }

            this.isLoading = false ;

        },


    }
}
</script>

<style scoped>

</style>
