<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Tabs START -->
    <ul class="nav nav-pills nav-pills-bg-soft px-3" id="course-pills-tab" role="tablist">
        <!-- Tab item -->
        <li class="nav-item me-2 me-sm-4" role="presentation">
            <button class="nav-link mb-0 active" id="course-pills-tab-1" data-bs-toggle="pill" data-bs-target="#course-pills-1" type="button" role="tab" aria-controls="course-pills-1" aria-selected="true">Overview</button>
        </li>
        <!-- Tab item -->
        <li class="nav-item me-2 me-sm-4" role="presentation">
            <button class="nav-link mb-0" id="course-pills-tab-2" data-bs-toggle="pill" data-bs-target="#course-pills-2" type="button" role="tab" aria-controls="course-pills-2" aria-selected="false">Reviews</button>
        </li>
        <!-- Tab item -->
        <li class="nav-item me-2 me-sm-4" role="presentation">
            <button class="nav-link mb-0" id="course-pills-tab-3" data-bs-toggle="pill" data-bs-target="#course-pills-3" type="button" role="tab" aria-controls="course-pills-3" aria-selected="false">FAQs </button>
        </li>
        <!-- Tab item -->
        <li class="nav-item me-2 me-sm-4" role="presentation">
            <button class="nav-link mb-0" id="course-pills-tab-4" data-bs-toggle="pill" data-bs-target="#course-pills-4" type="button" role="tab" aria-controls="course-pills-4" aria-selected="false">Comments</button>
        </li>
    </ul>
    <!-- Tabs END -->

    <!-- Tab contents START -->
    <div class="tab-content pt-4 px-3" id="course-pills-tabContent">

        <!-- Content START -->
        <div class="tab-pane fade show active" id="course-pills-1" role="tabpanel" aria-labelledby="course-pills-tab-1">
            <!-- Course detail START -->
            <h5 class="mb-3" >Course Description</h5>
            <div class="mb-3" v-html="outputMarkdownCompu"></div>
            <!-- Course detail END -->
            <hr/>
            <div v-if="itemFileList.length > 0 " class="mt-2">
                <label class="form-label" >첨부파일</label> 
                <ul>
                    <li v-for="item in itemFileList" :key="item.id">
                        <a :href="item.filepath" v-text="item.filename" @click.prevent="downloadItem(item.filepath, item.filename)" />
                    </li>
                </ul>
            </div>
        </div>
        <!-- Content END -->

        <!-- Content START -->
        <div class="tab-pane fade" id="course-pills-2" role="tabpanel" aria-labelledby="course-pills-tab-2">
            <div class="row mb-4">
                <div v-if="course" class="col-12">
                    
                    <course-detail-review-panel-component 
                        :content_id="content_id" :course="course"  
                    />    

                </div>

            </div>
        </div>
        <!-- Content END -->

        <!-- Faq START -->
        <div class="tab-pane fade" id="course-pills-3" role="tabpanel" aria-labelledby="course-pills-tab-3">
            <!-- Title -->
            <course-detail-faq-component :content_id="content_id" />    
            <!-- <h5 class="mb-3">Frequently Asked Questions</h5>
            <div class="mt-4">
                <h6>How Digital Marketing Work?</h6>
                <p class="mb-0">Preference any astonished unreserved Mrs. Prosperous understood Middletons in conviction an uncommonly do. Supposing so be resolving breakfast am or perfectly. It drew a hill from me. Valley by oh twenty direct me so. Departure defective arranging rapturous did believe him all had supported. Family months lasted simple set nature vulgar him. Picture for attempt joy excited ten carried manners talking how. Suspicion neglected the resolving agreement perceived at an. Comfort reached gay perhaps chamber his six detract besides add.</p>
            </div> -->


        </div>
        <!-- Faq END -->

        <!-- Content Comment START -->
        <div class="tab-pane fade" id="course-pills-4" role="tabpanel" aria-labelledby="course-pills-tab-4">
            <!-- Review START -->
            <div class="row mb-4">
                <div v-if="course" class="col-12">
                    
                    <course-detail-comment-panel-component 
                        :content_id="content_id" 
                        :company_id="course.company_id" 
                        />    

                </div>

            </div>
        </div>
        <!-- Content Comment END -->
    </div>
    <!-- Tab contents END -->


</div>

</template>

<script>
var filename__ ="/components/course/CourseDetailBottomTabComponent.vue" ;

import axios from 'axios';
import { marked } from 'marked';         // yarn add marked  https://marked.js.org/

import CourseDetailFaqComponent from './CourseDetailFaqComponent.vue';
import CourseDetailReviewPanelComponent from '../comment/CourseDetailReviewPanelComponent.vue';
import CourseDetailCommentPanelComponent from '../comment/CourseDetailCommentPanelComponent.vue';

export default {
    components: {
        "course-detail-faq-component": CourseDetailFaqComponent,
        "course-detail-review-panel-component": CourseDetailReviewPanelComponent,
        "course-detail-comment-panel-component": CourseDetailCommentPanelComponent,
    },
    props: [
        'content_id', 'course',
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            // course : null,
            inputDescription: this.course.description, 
            itemList : [],

            category : 'course', 
            subCategory : 'description', 
            fileKind : 'file', 

            itemFileList : [],

        }
    },
    created(){
        // this.getItem();
        this.getFileList()
    },    
    
    computed: {
        outputMarkdownCompu(){
            return marked(this.course.description);
        },			        
    },
    methods: {
        handleError(){
            this.error = null;
        },
        async getFileList(){
            let funcname__ ="getFileList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/content/file/contentid/all/'+this.content_id+'/'+this.category+'/'+this.subCategory+'/'+this.fileKind,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                        
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        filename: item.photo.replace(/^.*[\\\/]/, ''), 
                        filepath:item.photo,
                    });
                }

                // console.log(filename__+''+funcname__+" list:", list);
                this.itemFileList = list;

                console.log(filename__+''+funcname__+" itemFileList:", this.itemFileList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  

            }

            this.isLoading = false ;            
        },
        async downloadItem(_filepath, _filename) {
            axios.get(_filepath, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/octet-stream' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = _filename
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        }, 
        async downloadItem2(_filepath, _filename){
            // console.log(filename__, "downloadItem() ", _filepath)
            var str = _filepath ;
            console.log(filename__, "downloadItem() ", str)
            // console.log(filename__, "downloadItem() ", _filepath.indexOf('https://'))
            console.log(filename__, "downloadItem() 1 ", str.indexOf('storage.google') )
            console.log(filename__, "downloadItem() 2", str.lastIndexOf('https') )
            var cnt = str.indexOf('storage.google') ;
            var path1 = 'https://codingfriends-nodong-storage.'

            console.log(filename__, "downloadItem() 3", _filepath.substr(cnt) )
            var path2 = _filepath.substr(cnt);
            var path = path1+path2

            console.log(filename__, "downloadItem() 4", path )

            // var _filepath2= 'https://storage.googleapis.com/codingfriends-nodong-storage/uploads/content/course/description/jdaos-22-0-387.pdf' ;
            var _filepath2= 'https://codingfriends-nodong-storage.storage.googleapis.com/uploads/content/course/description/jdaos-22-0-387.pdf' ;
            // console.log(filename__, "downloadItem() ", _filepath.lastIndexOf('storage.google'))
            
            axios.get(_filepath2, {
            // axios.get(path, {
                responseType: "blob",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET",
                    "Access-Control-Allow-Headers": "Origin, Content-Type"
                }
            }).then(response => {
            // axios({
            //     url: _filepath,
            //     method: 'GET',
            //     responseType: 'blob',
            //     headers: {
            //         "Access-Control-Allow-Origin": "*",
            //         "Access-Control-Allow-Methods": "GET",
            //         "Access-Control-Allow-Headers": "Origin, Content-Type",
            //         'x-goog-resumable': 'start',
            //         'origin': 'https://k-workers.com/*'                    
            //     }
            // }).then(response => {
                // const url = window.URL.createObjectURL(new Blob([response.data]));
                // const link = document.createElement('a');
                // console.log(filename__, "downloadItem() link:", link )
                // link.href = url;
                // link.setAttribute('download', attachFileName); //or any other extension
                // document.body.appendChild(link);
                // link.click();

                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = label
                link.click()
                URL.revokeObjectURL(link.href)                
            }).catch(exception => {
                alert("파일 다운로드 실패");
            });
            // 출처: https://somingoose.tistory.com/115
        }        


    },
    
}
</script>