<template>
    <tr>
        <!-- Table data -->
        <td>{{num}}</td>

        <!-- Table data -->
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="avatar avatar-md">
                    <img v-if="create_photo" :src="create_photo" class="rounded-circle" alt="">
                    <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                </div>
                <div class="mb-0 ms-3">
                    <h6 v-if="create_name" class="mb-0">
                        <router-link :to="`/admin/member/${create_id}`" class="stretched-link">
                            {{create_name}}
                        </router-link>
                    </h6>
                    <small>{{create_email}}</small>                          
                </div>
            </div>
        </td>
        <td>
            <h6 class="table-responsive-title mb-0">
                <router-link :to="detailLink">{{contentSplice30}} </router-link>
            </h6>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">{{date}}</h6>
                </div>
            </div>
        </td>

        <!-- Table data -->
        <td>
            <span v-if="status == 0" class="badge text-bg-danger">미처리</span>
            <span v-if="status == 1" class="badge text-bg-info">확인중</span>
            <span v-if="status == 2" class="badge text-bg-primary">진행중</span>
            <span v-if="status == 8" class="badge text-bg-warning">보류중</span>
            <span v-if="status == 9" class="badge text-bg-success">처리완료</span>
        </td>

        <!-- Table data -->
        <td>
            <router-link :to="detailLink" class="btn btn-success-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Edit">
                <i class="bi bi-pencil-square"></i>
            </router-link>
            <!-- <button class="btn btn-danger-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Delete">
                <i class="bi bi-trash"></i>
            </button>
            <a href="#" class="btn btn-sm btn-info-soft mb-0" data-bs-toggle="modal" data-bs-target="#viewReview">View</a> -->
        </td>
    </tr>    
</template>

<script>
// slice__
export default {
    components: {},
    props: [
        'id','num','name','email','content','status','date',
        'create_id','create_name','create_email','create_photo',
        'page','search',
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            return '/admin/faq/'+this.id+'?page='+this.page+"&search="+this.search ; 
        },
        contentSplice30(){
            return this.content.slice(0,30)+"...";
        },

    },
    methods: {

    },
    
}
</script>