<template>
  <!-- 관리자 화면  -->
  <div v-if="isAdmin && isPageAdmin">
      <main>
        <admin-left></admin-left>
        <div class="page-content">
          <admin-top></admin-top>
          <router-view></router-view>
        </div>
      </main>
  </div>      
  <!-- 일반 사용자 화면  -->
  <div v-else>
      <the-header></the-header>
      <router-view></router-view>
      <the-footer></the-footer> 
  </div>      

</template>

<script>
import TheHeader from './components/layout/TheHeader.vue';
import TheFooter from './components/layout/TheFooter.vue'
import AdminLeft from './components/layout/AdminLeft.vue'
import AdminTop from './components/layout/AdminTop.vue'


export default {
    components: {
      "the-header": TheHeader, 
      "the-footer": TheFooter,
      "admin-left": AdminLeft,
      "admin-top": AdminTop,
    },
    props: [],
    data() {
        return {
            sampleData: ''
        }
    },
    setup() {},
    computed: {
      isAdmin(){
        // console.log("[App.vue] computed : isAdmin():", this.$store.getters['auth/isAdmin'])
        return this.$store.getters['auth/isAdmin'];
      },
      isPageAdmin(){
        // console.log("[App.vue] computed : isPageAdmin():", this.$store.getters['auth/isPageAdmin'])
        return this.$store.getters['auth/isPageAdmin'];
      },
    },
    created() {
      let is_loading = this.$store.getters['setting/is_loading'];
      // 한번도 불러오지 않았으면 
      if(!is_loading) {        
        this.getSetting();
      }
    },
    mounted() {},
    unmounted() {},
    methods: {
      async getSetting(refresh=false){
            
            // this.isLoading = true;
            try {                

                await this.$store.dispatch('course/Loading' , { foreceRefresh: refresh } );  //  course categoryList 가져오기  /store/modules/cuorse/actions.js + mutations.js   
                await this.$store.dispatch('blog/Loading' , { foreceRefresh: refresh } );  //  course categoryList 가져오기  /store/modules/cuorse/actions.js + mutations.js   
                
                // this.isLoading = false ;             
            } catch(err){
                console.error('[App.vue]store.disptach() err:',err);
                // this.dialogTitle ="Error" ;
                // this.error = err;
            }
            // this.isLoading = false ;            
      },

    },

}
</script>

<style>

</style>