<template>
    <div>
        <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
            <p>{{ error }}</p>
        </base-dialog>

        <base-dialog :show="isLoading" title="Loading..." fixed>
            <p>Loading...</p>
            <base-spinner></base-spinner>
        </base-dialog>

        <section class="pt-0">
        <!-- Main banner background image -->
            <div class="container-fluid px-0">
                <div class="bg-blue h-100px h-md-200px rounded-0" style="background:url(/assets/images/pattern/04.png) no-repeat center center; background-size:cover;">
                    <div class="row">
                        <div class="col-12 text-center mt-6">
                            <h1 class="text-white">{{title}}</h1>
                            <!-- <p class="text-white mb-0">Read our <a href="#" class="text-white"><u>"Before you create a course"</u></a> article before submitting!</p> -->
                            
                        </div>
                    </div>            
            </div>
            </div>
            <!-- </section>
            <section class="pt-0"> -->
            <div class="container mt-n4">
                <div class="row">
                    <!-- Profile banner START -->
                    <div class="col-12">
                        <div class="card bg-transparent card-body p-0">
                            <div class="row d-flex justify-content-between">
                                <!-- Avatar -->
                                <div class="col-auto">
                                    <div class="avatar avatar-xxl position-relative mt-n3">
                                        <img v-if="photo"  class="avatar-img rounded-circle border border-white border-3 shadow" :src="photo" alt="">
                                        <img v-else class="avatar-img rounded-circle border border-white border-1 shadow" src="/assets/images/avatar/user.png" alt="">
                                        <!-- <span class="badge text-bg-success rounded-pill position-absolute top-50 start-100 translate-middle mt-4 mt-md-5 ms-n3 px-md-3">Pro</span> -->
                                    </div>
                                </div>
                                <!-- Profile info -->
                                <div class="col d-sm-flex justify-content-between align-items-center">
                                    <div>
                                        <h1 class="my-1 fs-4">{{name}}</h1>
                                        <ul class="list-inline mb-0">
                                            <!-- <li class="list-inline-item me-3 mb-1 mb-sm-0">
                                                <span class="h6">255</span>
                                                <span class="text-body fw-light">points</span>
                                            </li> -->
                                            <li class="list-inline-item me-3 mb-1 mb-sm-0">
                                                <span class="h6">{{ inputTotalCount }}</span>
                                                <span class="text-body fw-light">Total courses</span>
                                            </li>
                                            <li class="list-inline-item me-3 mb-1 mb-sm-0">
                                                <span class="h6">{{ inputCompleteCount }}</span>
                                                <span class="text-body fw-light">Completed courses</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- Button -->
                                    <div class="mt-2 mt-sm-0">
                                        <router-link to="/studentdash/courses" class="btn btn-outline-primary mb-0">View my courses</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Profile banner END -->
        
                        <!-- Advanced filter responsive toggler START -->
                        <!-- Divider -->
                        <hr class="d-xl-none">
                        <div class="col-12 col-xl-3 d-flex justify-content-between align-items-center">
                            <a class="h6 mb-0 fw-bold d-xl-none" href="#">Menu</a>
                            <button class="btn btn-primary d-xl-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
                                <i class="fas fa-sliders-h"></i>
                            </button>
                        </div>
                        <!-- Advanced filter responsive toggler END -->
                    </div>
                </div>
            </div>
    </section>

    </div>
</template>

<script>
var filename__="[components > studentdash > StudentDashboardTop.vue] "

import axios from 'axios';

export default {
    components: {
    },
    props: ['title'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
        }
    },
    created(){
    },
    computed: {
      isLoggedIn(){
        return this.$store.getters['auth/isAuthenticated'];
      },     
      name(){
        // console.log("[AdminTopHeader.vue] computed : name():", this.$store.getters['auth/name'])
        return this.$store.getters['auth/name']; 
      },
      email(){
        return this.$store.getters['auth/email']; 
      },
      photo(){
        // console.log("[TheHeader.vue] computed : photo():", this.$store.getters['auth/photo'])
        return this.$store.getters['auth/photo'];
      },

    }, 
    methods: {
        handleError(){
            this.error = null;

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
    }

}
</script>

    