<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Sidebar START -->
    <!-- Sidebar END -->
    
    <!-- Page content START -->
	
	<!-- Top bar START -->
	<!-- Top bar END -->
    <!-- 삭제 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" id="idDeleteCompu">Delete Comment</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

	<!-- Page main content START -->
	<div class="page-content-wrapper-match border">

		<!-- Title -->
		<div class="row g-3 align-items-center justify-content-between">
			<div class="col-md-9">
				<h1 class="h3 mb-2 mb-sm-0">Error Log Detail</h1>
			</div>
            <div class="col-md-3 text-end">
                <router-link :to="backToLink" class="btn btn-secondary mb-0 me-2">Back</router-link>
                <router-link :to="statusLink" class="btn btn-success mb-0 me-2">상태변경</router-link>
            </div>
		</div>

        <admin-errorlog-detail-component :id="id" />

        <div class="row g-3 align-items-center justify-content-between">
			<div class="col-md-9">
				<h1 class="h3 mb-2 mb-sm-0"></h1>
			</div>
            <div class="col-mt-3 text-end">
                <button type="button" class="btn btn-danger mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu"><i class="bi bi-trash me-2"></i>삭제</button>
            </div>
		</div>

    </div>

    
</div>
</template>

<script>
var filename__= "[pages > admin > errorlog > AdminErrorLogDetail.vue] "

import axios from 'axios';

import AdminErrorLogDetailComponent from '../../../components/admin/errorlog/AdminErrorLogDetailComponent.vue';

export default {
    components: {
        "admin-errorlog-detail-component" : AdminErrorLogDetailComponent,
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,      
                        
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
        backToLink(){
            return '/admin/errorlogs?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },
        statusLink(){
            return '/admin/errorlog/status/update/'+this.id+'?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
        },
        idDeleteCompu(){
            return 'deleteModal'+this.id
        },
        idDeleteShapCompu(){
            return '#deleteModal'+this.id
        },

    },    
    created(){
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle == 'Success'){
                this.$router.replace('/admin/errorlogs?page='+this.$route.query.page+"&search="+this.$route.query.search);
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
            return this.$store.getters['auth/isAdmin'] ;
        },
        // 팝업 창을 통해서 삭제   
        async removeForm(){
            let funcname__ = "removeForm() "
            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/errorlog/'+this.id,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+funcname__+"serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.code == 201){
                        // console.log(filename__+funcname__+' data:', serverData.data);
                                                
                        this.dialogTitle ='Success' ;
                        this.error = '삭제 성공했습니다.';  
                                                                                                                        
                    } 
                } 

            } catch(error){
                console.error(filename__+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 

                await axios({
                    method: 'post',
                    url: '/api/errorlog/create',
                    params: { 
                        cateogry: 'errorlog(catch)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error',       
                        content : error,
                    }
                }).catch( err => {
                    console.log(filename__+''+funcname__+' errorlog(catch) :',err);
                });

            }

            this.isLoading = false ;

        },     

        
    }
}
</script>

<style scoped>

</style>