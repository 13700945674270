<template>
<div>
    <li>
        <div v-if="isShow" class="list-group-item-action border-0 border-bottom d-flex p-3">
            <div class="me-3">
                <div class="avatar avatar-md">
                    <img v-if="create_photo" class="avatar-img rounded-circle" :src="create_photo" alt="avatar">
                    <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user.png" alt="avatar">
                </div>
            </div>
            <div class="summary"><!--summary cursor:pointer-->
                <p class="text-body small m-0">{{contentSplice60}}</p>
                <!-- <u class="small">{{create_name}}</u> -->
                <u class="small" @click="onChangeShow" >View detail</u>
            </div>
        </div>
        <div v-if="!isShow" class="list-group-item-action border-0 border-bottom d-flex p-3">
            <div>
                <p class="white-space-newline"><small>{{content}}</small></p>
            </div>
        </div> 
    </li>


    <!-- List  -->
    <!-- <div class="my-4 d-flex" :class="depth_class" >
        <img v-if="create_photo" class="avatar avatar-md rounded-circle me-3" :src="create_photo" alt="avatar">
        <img v-else class="avatar avatar-md rounded-circle me-3" src="/assets/images/avatar/user.png" alt="avatar">
        <div>
            <div class="mb-2">
                <h5 class="m-0">{{create_name}}</h5>
                <span class="me-3 small">{{date}}</span>
            </div>
            <p class="white-space-newline">{{content}}</p>
            <button v-if="depth < 7" type="button" class="btn btn-sm btn-light mb-0" data-bs-toggle="modal" :data-bs-target="idShapCompu">Reply</button>
            <button type="button" class="btn btn-sm btn-info mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idUpdateShapCompu"><i class="bi bi-pencil"></i></button>
            <button type="button" class="btn btn-sm btn-danger mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu"><i class="bi bi-trash"></i></button>
            <button type="button" @click="changeIsDisplay" class="btn btn-sm mb-0 ms-2" :class="is_display ? 'btn-primary':'btn-orange'" >{{is_display ?'보임':'숨김'}}</button>
        </div>
    </div> -->
</div>        
</template>

<script>
export default {
    components: {},
    props: [
        'id','content','date',
        'create_name','create_photo',
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  

           isShow: true, 
        }
    },
    
    computed: {
        // detailLink(){
        //     return '/admin/contactus/'+this.id+'?page='+this.page+"&search="+this.search ; 
        // },
        contentSplice60(){
            return this.content.slice(0,60);
        },
        idTopCompu(){
            return 'topModal'+this.id
        },
        idShapTopCompu(){
            return '#topModal'+this.id
        },

    },
    methods: {
        onChangeShow(){
            this.isShow = !this.isShow ;
            this.updateCheckForm();
        },
        updateCheckForm(){
            this.$emit('updateCheck', this.id)
        },
    },
    
}
</script>