import axios from "axios";

export default {
    isMessageCategory(state){
        return !!state.messagecategory ;
    },
    async MessageCategory(context){

        const response = await axios( {
            method: 'get', 
            url:'/api/message/categories',
        });
        console.log('[store/modules/message/actions.js_MessageCategory()] response:', response);

        const responseData = await response.data ;
        console.log('[store/modules/message/actions.js_MessageCategory()] responseData:', responseData);

        if(responseData.code === 200){
            console.log('[store/modules/message/actions.js_MessageCategory()] success:', responseData.msg);


            let tmpList = responseData.data ;
            let cateList = [];

            tmpList.sort(function(a,b){
                // console.log("sequence:",a.sequence)
                return a.sequence-b.sequence
            });
            console.log("[store/modules/message/actions.js_MessageCategory()] tmpList:", tmpList);

            //메인 카테고리 값들을 입력하기 
            for(let item of tmpList ){
                if (item.category == 0 && item.isdisplay == true) {
                    cateList.push({
                        id: item.id, 
                        title: item.title, 
                        subtitleList : [],
                        isDisplay : item.isdisplay,
                        seq: item.sequence, 
                    });
                }
            }

            //서브 타이틀 배열에 추가하기 
            for(let cate1 of cateList ){
                for(let item2 of tmpList ){
                    if (cate1.id == item2.parent && item2.isdisplay == true) {
                        cate1.subtitleList.push({
                            // num: cnt++, 
                            id: item2.id,
                            title: item2.title,    
                            seq : item2.sequence,    
                            isDisplay: item2.isdisplay                         
                        });
                    }
                }
                cate1.subtitleList.sort(function(a,b){
                    return a.sequence-b.sequence
                });

            }


            console.log("[MessageComponent.vue] cateList:", cateList);

            context.commit('setMessageCategory', {
                
                messagecategory: cateList,  
                
            });  // store/modules/message/mutations.js

        } else {
            console.log('[store/modules/message/actions.js_MessageCategory()] error:', responseData.msg);

            // const error = new Error(responseData.msg || 'Failed to authenticate.');
            const error = new Error(responseData.msg);
            console.error("error ==> [store/modules/message/actions.js_MessageCategory()] : \n", error);

            throw error ;            

        }
        
    },   
}