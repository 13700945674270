<template>
    <div>
        <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
            <p>{{ error }}</p>
        </base-dialog>

        <base-dialog :show="isLoading" title="Loading..." fixed>
            <p>Loading...</p>
            <base-spinner></base-spinner>
        </base-dialog>

        <teleport to="body">
            <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModal" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header btn-primary-soft">
                        <h1 class="modal-title fs-5" id="deleteModal">정보 삭제 </h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>현재의 정보를 삭제하시겠습니까? <br/>삭제된 정보는 복구할 수 없습니다.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-success-soft" data-bs-dismiss="modal">Close</button>
                        <button type="button" @click="removeForm" class="btn btn-sm btn-danger-soft" data-bs-dismiss="modal">Delete</button>
                    </div>
                    </div>
                </div>
            </div>
        </teleport>           

        <main>
	
    <!-- =======================
    Page Banner START -->
    <student-dashboard-top :title="'Edit 관련직종 '" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
                        
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <student-dashboard-left :menu="'sector'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Edit profile START -->
                    <div class="card bg-transparent border rounded-3">
                        <!-- Card header -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="card-header-title mb-0">관련 직종변경</h3>
                        </div>
                        <!-- Card body START -->
                        <div class="card-body">
                            <form @submit.prevent="sendForm" class="row g-4">
                                <!-- 직종 -->
                                <div class="col-12 mb-1">
                                    <div class="row g-xl-0 align-items-center">
                                        <div class="col-lg-2">
                                            <h6 class="mb-lg-0">직종 <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-lg-10">
                                            <select class="form-select js-choice z-index-9 border-0 bg-light" aria-label=".form-select-sm"
                                                v-model="inputMainSector"
                                                @change="onChangeSelectMainSector($event)" >
                                                <option v-for="opt in sectorList"  :key="opt.id"  :value="opt.id" >
                                                    {{ opt.title}}
                                                </option>                                                        
                                            </select>
                                            <small>&nbsp;&nbsp;* 직종을 선택해주세요</small>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="inputMainSector" class="col-12 mb-2">
                                    <div class="row g-xl-0 align-items-center">
                                        <div class="col-lg-2">
                                            <h6 class="mb-lg-0">세부직종</h6>
                                        </div>
                                        <div class="col-lg-10">
                                            <select class="form-select js-choice z-index-9 border-0 bg-light" aria-label=".form-select-sm"
                                                v-model="inputSubSector"
                                                @change="onChangeSelectSubSector($event)" >
                                                <option v-for="opt2 in subSectorList"  :key="opt2.id" :value="opt2.id" >
                                                    {{ opt2.title}}
                                                </option>                                                        
                                            </select>
                                            <small>&nbsp;&nbsp;* 세부직종을 선택해주세요</small>
                                        </div>
                                    </div>
                                </div>
    
    
                                <!-- Save button -->
                                <div class="d-sm-flex justify-content-between">
                                    <button v-if="inputSubSector" type="button" class="btn btn-danger mb-0" data-bs-toggle="modal" data-bs-target="#deleteModal">Delete 직종정보</button>
                                    <div v-else></div> 

                                    <button type="submit" class="btn btn-primary mb-0">Save changes</button>
                                </div>
                            </form>
                        </div>
                        <!-- Card body END -->
                    </div>
                    <!-- Edit profile END -->
                    
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pages > studentdash > StudentDashSectorUpdate.vue]" ;
import axios from 'axios';

import StudentDashboardTop from '../../components/studentdash/StudentDashboardTop.vue';
import StudentDashboardLeft from '../../components/studentdash/StudentDashboardLeft.vue';

export default {
    components: {
        "student-dashboard-top": StudentDashboardTop,
        "student-dashboard-left": StudentDashboardLeft,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,       
            
            inputMainSector : null, 
            inputSubSector : null, 
            inputLocation : null, 
            
            locationList : [], 
            sectorList : [], 
            subSectorList : [],
            isFirstSubSectorCheck : false //처음 로딩될 때는 subsector 가 선택이 안되기 때문에 
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            // this.getList(); 나중에 call
            this.getItem();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },    
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        email : function(){
            return this.$store.getters['auth/email'] ;
        },
        mainSectorCompu(){
            return this.inputMainSector
        }      

    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/signin');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            // console.log("[StudentDashProfieleUpdate.vue] isAuth():", this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            // console.log("[StudentDashProfieleUpdate.vue] isUser():", this.$store.getters['auth/userId']);
            return this.$store.getters['auth/userId'] ;
        },
        onChangeSelectMainSector(event){
            // let funcname__ ="onChangeSelectCategory()"
            this.isFirstSubSectorCheck = true ; // 처음으로 메인직종을 선택하면 변경됨 
            console.log(filename__+ " onChangeSelectMainSector() value:",event.target.value)
            this.inputMainSector = event.target.value;

            //서브 카테고리 값들을 입력하기 
            for(let item of this.sectorList ){
                if(item.id === parseInt(event.target.value)){
                    this.subSectorList = item.subtitleList ;
                    break ;
                }
            }

        },  
        onChangeSelectSubSector(event){
            // let funcname__ ="onChangeSelectCategory()"
            console.log(filename__+ " onChangeSelectSubSector() value:",event.target.value)
            this.inputSubSector = event.target.value;
        },    

        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 
            
            
            let serverData = await axios({
                method: 'get',
                url: '/api/user/myaccount',
                headers: {
                    'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined" || serverData === undefined ||  serverData.status !== 200){
                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" && data.status_code == 200){
                    console.log(filename__+''+funcname__+' response:', data.response);
                    
                    const profile = data.response ;

                    this.inputId = profile.id ;

                    this.inputMainSector = profile.main_sector ? profile.main_sector : 0 ;
                    this.inputSubSector = profile.sub_sector ? profile.sub_sector : 0 ;
                    this.inputLocation = profile.location_sector ? profile.location_sector : 0 ;

                    // console.log(filename__+''+funcname__+" inputMainSector:", this.inputMainSector);
                    // console.log(filename__+''+funcname__+" inputSubSector:", this.inputSubSector);

                } else if( typeof data !== "undefined" && data.status_code == 401){
                    this.dialogTitle ="Error" ;                    
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [401] ";  // this.error = err.msg || 'Failed to authenticate';                

                } else {
                    console.log(filename__+''+funcname__+' err.msg:', serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  // this.error = err.msg || 'Failed to authenticate';                
                    // alert('error')
                }
            }


            this.isLoading = false ;     
            this.getList(); //===>      
        },

        async getList(){
			let funcname__ ="getList()" ;

			this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

			let serverData = await axios({
				method: 'get',
				url: '/api/user/sector/all',

			}).catch( err => {

                let dataForm = { 
                    category: 'errorlog(api catech)' , 
                    filename : filename__,
                    methodname : funcname__,
                    title: 'Error(API)',       
                    content : ''+err,
                } ;  
                console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                axios({
                    method: 'post',
                    url: '/api/errorlog/create',
                    data: dataForm,
                });

                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                return ;
                
			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			// console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				// console.log('[AdminCourseCategoryList.vue] profile:', data.profile);
				
				let tmpList = data.response ;
				let sectorList = [];
                
				let locationList = [];

				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
                    // location 
					if(item.category === 'location' && item.is_display === true ) {
						locationList.push({
							id: item.id, 
							category: item.category, 
							depth: item.depth, 
							parent: item.parent, 
							title: item.title, 
							is_display: item.is_display, 
							seq:item.seq,
						});
					}


                    // sector 
					else if(item.category === 'sector' && item.parent_id === 0 && item.is_display === true ) {
						sectorList.push({
							id: item.id, 
							category: item.category, 
							depth: item.depth, 
							parent: item.parent, 
							title: item.title, 
							subtitleList : [],
							is_display: item.is_display, 
							seq:item.seq,
						});
					}

				}

				if(locationList.length > 1) {
					locationList.sort(function(a,b){
						// console.log("seq:",a.seq)
						return a.seq-b.seq
					});
				}

				if(locationList.length > 1) {
					locationList.sort(function(a,b){
						// console.log("seq:",a.seq)
						return a.seq-b.seq
					});
				}

				//서브 타이틀 배열에 추가하기 
				for(let cate1 of sectorList ){
					for(let item2 of tmpList ){
						if (cate1.id == item2.parent_id && item2.is_display === true ) {
							cate1.subtitleList.push({
								// num: cnt++, 
								id: item2.id,
								title: item2.title,    
								seq : item2.seq,    
								is_display: item2.is_display                         
							});
						}
					}

					cate1.subtitleList.sort(function(a,b){
						return a.seq-b.seq
					});   
                    
                    //서브 섹터의 subSectorList에 저장함 : 그래야 처음 로딩할 때 서브 섹터가 선택해서 나옴 
                    if(cate1.id === this.inputMainSector){
                        this.subSectorList = cate1.subtitleList ;
                    }

				}
                
                // console.log(filename__+''+funcname__+" locationList:", this.locationList);
                // console.log(filename__+''+funcname__+" sectorList:", sectorList);
                // console.log(filename__+''+funcname__+" this.subSectorList:", this.subSectorList);
				
                this.locationList = locationList;
                this.sectorList = sectorList;

			} else {
				console.log(filename__+''+funcname__+' err:');

				this.dialogTitle ="실패" ;                    
				this.error = serverData.data.error_description;  
			}


			this.isLoading = false ;            
		},

        // 프로필 정보 업데이트  
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'main_sector': parseInt(this.inputMainSector), 
                'sub_sector': parseInt(this.inputSubSector),
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: `/api/user/sector/update`,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }
      

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                
                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+' data:', serverData.data);

                        if(data.status_code === 201){
                                                                            
                            this.dialogTitle ='Success' ;
                            this.error = '성공했습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null 
                                    // this.$router.replace('/'); 
                                }, 1000);
                            }				


                        } else {
                            console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                            this.dialogTitle ="입력 실패" ;
                            this.error = serverData.data.msg;

                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // 팝업 창을 통해서 삭제   
        async removeForm(){
            let funcname__ ="removeForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/user/sector/delete',
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log("[AdminMemberUpdate.vue] removeForm() serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        console.log(filename__+''+funcname__+' removeForm() data:', serverData.data);
                                                
                        this.dialogTitle ='Success' ;
                        this.error = '정보 삭제 성공했습니다.';    

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.inputMainSector = null;
                                this.inputSubSector = null ;

                            }, 500);
                        }                        
                        
                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                        this.error = serverData.data.error_description;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+''+funcname__+' removeForm() error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },     

    }
}
</script>

<style scoped>

</style>