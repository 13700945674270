<template>
    <div>
        <footer class="pt-4">
            <div class="container">
                <!-- Row START -->
                <div class="row g-4">

                    <!-- Widget 1 START -->
                    <div class="col-lg-3">
                        <!-- logo -->
                        <router-link class="me-0" to="/">
                            <!-- <img class="light-mode-item h-40px" src="/assets/images/logo.svg" alt="logo">
                            <img class="dark-mode-item h-40px" src="/assets/images/logo-light.svg" alt="logo"> -->
                            <!-- 신 logo 덧 붙임  -->
                            <img class="light-mode-item h-40px" src="/assets/workerscafe/logo.svg" alt="logo">
                            <img class="dark-mode-item h-40px" src="/assets/workerscafe/logo.svg" alt="logo">
                        </router-link>
                        <!-- 로고 아래 이 부분은 변수 데이터 가져오는 곳 모르겠음  -->
                        <p class="my-3">{{descriptionCompu}}</p>
                        <!-- Social media icon -->
                        <ul class="list-inline mb-0 mt-3">
                            <li v-if="facebookCompu" class="list-inline-item"> 
                                <a target='_blank' :href="facebookCompu" class="btn btn-white btn-sm shadow px-2 text-facebook" ><i class="fab fa-fw fa-facebook-f text-facebook"></i></a> </li>
                            <li v-if="instagramCompu" class="list-inline-item"> 
                                <a target='_blank' :href="instagramCompu"  class="btn btn-white btn-sm shadow px-2 text-instagram" ><i class="fab fa-fw fa-instagram"></i></a> </li>
                            <li v-if="twitterCompu" class="list-inline-item"> 
                                <a target='_blank' :href="twitterCompu"  class="btn btn-white btn-sm shadow px-2 text-twitter" ><i class="fab fa-fw fa-twitter"></i></a> </li>
                            <li v-if="youtubeCompu" class="list-inline-item"> 
                                <a target='_blank' :href="youtubeCompu"  class="btn btn-white btn-sm shadow px-2 text-linkedin" ><i class="fab fa-fw fa-youtube text-google-icon"></i></a> </li>
                        </ul>
                    </div>
                    <!-- Widget 1 END -->

                    <!-- Widget 2 START -->
                    <div class="col-lg-6">
                        <div class="row g-4">
                            
                            <div class="col-6 col-md-4">
                                <h5 class="mb-2 mb-md-4">워커스 카페</h5>
                                <ul class="nav flex-column">
                                    <li class="nav-item"><router-link to="/intro" class="nav-link" >워커스카페 소개</router-link></li>
                                    <!-- <li class="nav-item"><router-link to="/blogs" class="nav-link" >뉴스 및 블로그</router-link></li> -->
                                    <li class="nav-item"><router-link to="/contactus" class="nav-link" >Contact us</router-link></li>
                                </ul>
                            </div>
                            <div class="col-6 col-md-4">
                                <h5 class="mb-2 mb-md-4">정보창고 및 파트너</h5>
                                <ul class="nav flex-column">
                                    <li class="nav-item"><router-link to="/blogs" class="nav-link" >정보창고</router-link></li>
                                    <li class="nav-item"><router-link to="/totalcourses" class="nav-link" >전체 스터디품</router-link></li>
                                    <!-- <li class="nav-item"><a class="nav-link" href="#">기간과 조건</a></li> -->
                                    <li class="nav-item"><router-link to="/companies" class="nav-link" >파트너 리스트</router-link></li>
                                    <li class="nav-item"><router-link to="/company/request" class="nav-link" >파트너 등록</router-link></li>
                                </ul>
                            </div>

                            <div class="col-6 col-md-4">
                                <h5 class="mb-2 mb-md-4">고객지원</h5>
                                <ul class="nav flex-column">
                                    <!-- <li class="nav-item"><a class="nav-link" href="#">지원받기</a></li> -->
                                    <li class="nav-item"><router-link to="/faqs" class="nav-link" >자주하는 질문</router-link></li>
                                </ul>
                            </div>


                        </div>
                    </div>
                    <!-- Widget 2 END -->

                    <!-- Widget 3 START -->
                    <div class="col-lg-3">
                        <h5 class="mb-2 mb-md-4">연락처</h5>
                        <!-- Time -->
                        <p class="mb-1">
                            Tel :<span class="h6 fw-light ms-2">{{phoneCompu}}</span>
                            <span class="d-block small">{{worktimeCompu}}</span>
                        </p>
                        <p class="my-3">{{contactaddrCompu}}</p>


                        <p class="mb-0">Email:<span class="h6 fw-light ms-2">{{emailCompu}}</span></p>

                    </div> 
                    <!-- Widget 3 END -->
                </div><!-- Row END -->

                <!-- Divider -->
                <hr class="mt-4 mb-0">

                <!-- Bottom footer -->
                <div class="py-3">
                    <div class="container px-0">
                        <div class="d-lg-flex justify-content-between align-items-center py-3 text-center text-md-left">
                            <!-- copyright text -->
                            <div class="text-primary-hover">{{copyrightCompu}}</div>
                            <!-- copyright links-->
                            <div class="justify-content-center mt-3 mt-lg-0">
                                <ul class="nav list-inline justify-content-center mb-0">
                                    <li class="list-inline-item"><router-link to="protectprivacy" class="nav-link pe-0" >개인정보 보호 정책</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <!-- Back to top -->
        <div class="back-top"><i class="bi bi-arrow-up-short position-absolute top-50 start-50 translate-middle"></i></div>

    </div>
</template>

<script>
export default {
    components: {},
    props: [],
    data() {
        return {
            
        }
    },
    created() {
      let is_loading = this.$store.getters['setting/is_loading'];
      let is_social = this.$store.getters['social/is_social'];
      // 한번도 불러오지 않았으면 
      if(!is_loading) {
        this.loadSetting();
      } 
      if(!is_social) {
        // console.log("Footer.vue에서 loadSocial() 해야됨 ")
        this.loadSocial();
      } 

        
    },
    computed: {
        nameCompu(){
            return this.$store.getters['setting/name'];
        },
        copyrightCompu(){
            return this.$store.getters['setting/copyright'];
        },
        emailCompu(){
            return this.$store.getters['setting/email'];
        },
        descriptionCompu(){
            return this.$store.getters['setting/description'];
        },
        phoneCompu(){
            return this.$store.getters['setting/phone'];
        },
        worktimeCompu(){
            return this.$store.getters['setting/worktime'];
        },
        supportemailCompu(){
            return this.$store.getters['setting/supportemail'];
        },
        contactaddrCompu(){
            return this.$store.getters['setting/contactaddr'];
        },

        facebookCompu(){
            return this.$store.getters['social/facebook'];
        },
        youtubeCompu(){
            return this.$store.getters['social/youtube'];
        },
        twitterCompu(){
            return this.$store.getters['social/twitter'];
        },
        instagramCompu(){
            return this.$store.getters['social/instagram'];
        },
    },
    methods: {
        async loadSetting(refresh=false){            
            // this.isLoading = true;
            try {
                await this.$store.dispatch('setting/LoadSetting' , { foreceRefresh: refresh } );  //  /store/modules/setting/actions.js + mutations.js   
                // this.isLoading = false ; 

            } catch(err){
                console.error('[App.vue]store.disptach() loadSetting() err:',err);
                // this.dialogTitle ="Error" ;
                // this.error = err;
            }

            // this.isLoading = false ;
            
        },
        async loadSocial(refresh=false){            
            // this.isLoading = true;
            try {
                await this.$store.dispatch('social/LoadSocial' , { foreceRefresh: refresh } );  //  /store/modules/social/actions.js + mutations.js   

                // this.isLoading = false ; 
            
            } catch(err){
                console.error('[TheFooter.vue]store.disptach() loadSocial() err:',err);
                // this.dialogTitle ="Error" ;
                // this.error = err;
            }

            // this.isLoading = false ;
            
        },

    }
}
</script>

<style scoped>

</style>
