<template>
<div class="card shadow h-100">
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Card header -->
    <div class="card-header border-bottom d-flex justify-content-between align-items-center p-4">
        <h5 class="card-header-title">등록된 파트너</h5>
        <router-link to="/admin/companies" class="btn btn-link p-0 mb-0">View all</router-link>
    </div>

    <!-- Card body START -->
    <div class="card-body p-4">

        <!-- Ticket item START -->
        <div v-for="(item, idx ) in itemList" :key="item.id">
            <!-- Instructor item START -->
            <div class="d-sm-flex justify-content-between align-items-center">
                <!-- Avatar and info -->
                <div class="d-sm-flex align-items-center mb-1 mb-sm-0">
                    <!-- Avatar -->
                    <div class="avatar avatar-md flex-shrink-0">
                        <img v-if="item.photo" class="avatar-img rounded-circle" :src="item.photo" alt="avatar">
                        <img v-else class="avatar-img rounded-circle" src="/assets/images/avatar/user2.png" alt="avatar">
                    </div>
                    <!-- Info -->
                    <div class="ms-0 ms-sm-2 mt-2 mt-sm-0">
                        <h6 class="mb-1">{{item.name}}<i class="bi bi-patch-check-fill text-info small ms-1"></i></h6>
                        <!-- <ul class="list-inline mb-0 small">
                            <li class="list-inline-item fw-light me-2 mb-1 mb-sm-0"><i class="fas fa-book text-purple me-1"></i>25 Courses</li>
                            <li class="list-inline-item fw-light me-2 mb-1 mb-sm-0"><i class="fas fa-star text-warning me-1"></i>4.5/5.0</li>
                        </ul> -->
                    </div>
                </div>
                <!-- Button -->
                <router-link :to="'/admin/company/'+item.id+'/'+item.company_id" class="btn btn-sm btn-light mb-0">View</router-link>
            </div>
            <!-- Instructor item END -->

            <hr v-if="idx != (inputMaxCount-1)"><!-- Divider -->
        </div>

    </div>
    <!-- Card body END -->
</div>

</template>
<script>
var filename__= "[components > admin > dashboard > AdminDashboardCompanyListComponent.vue] "

import axios from 'axios';

export default {
    components: {},
    props: [],
    data() {
        return {
           
            isLoading: false,
            error: null,     
            dialogTitle: null,       

            itemList : [],
            inputMaxCount: 0,

            page:1,
            // current_path:"/admin/companies",  //current_path
			pagination: null, 

            inputPagePerCount : 5,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 1,      // 하단 pagination 좌우 블록수     

        }
    },
    created(){
        this.getList()
    },
    computed: {

    },

    methods: {
        handleError(){
            this.error = null;

        },
        async getList(){
            let funcname__ ="getList() ";

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }
            
            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/company/dashboard/admin',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                }
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);  //__log

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);  //__log

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log('[AdminContactusList.vue] profile:', data.profile);
                
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        num: counter--, 
                        id: item.id, 
                        company_id: item.owner_id, 
                        email:item.email,

                        name: item.name,
                        photo: item.photo,
                        phone: item.phone,
                        addr: item.addr,							
                        description: item.description, 

                        date: item.date, 
                        outdate: item.date,
                        
                        is_active : item.is_active,
                        is_display : item.is_display,
                        is_out : item.is_out,

                        create_id : item.create_id,
                        instructor_email : item.instructor_email,                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);  //__log
                this.itemList = list;
                this.inputMaxCount = list.length;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);  //__log
                // console.log(filename__+''+funcname__+" inputMaxCount:", this.inputMaxCount);  //__log
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);  //__log

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },


    },
    
}
</script>

<style scoped>
</style>