<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>
                

    <main>
	
    <!-- =======================
    Page Banner START -->
    <company-dashboard-top :title="'공동작업자 요청등록'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">

            <!-- Notification 모달  -->
            <div class="modal fade" id="notificationModal" tabindex="-1" aria-labelledby="notificationModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header bg-orange">
                        <h1 class="modal-title fs-5" id="notificationModalLabel">Notification</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <label class="form-label">Your Message *</label>
                        <textarea class="form-control" rows="5" v-model.trim="inputContent"></textarea>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" @click="sendNotificationForm" class="btn btn-orange" data-bs-dismiss="modal">Send Notification</button>
                    </div>
                    </div>
                </div>
            </div>
                        
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <company-dashboard-left :menu="'request'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Edit profile START -->
                    <div class="card bg-transparent border rounded-3">
                        <!-- Card header -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="card-header-title mb-0">공동작업자 등록요청 상태변경</h3>
                        </div>

                        <div class="row g-4">

                            <div class="col-xxl-12">

                                <div class="card bg-transparent h-100 ms-3 me-3">

                                    <!-- Card body START -->
                                    <div class="card-body">
                                        <!-- Profile picture -->
                                        <div class="avatar avatar-xl mb-3">
                                            <img v-if="inputPhoto" class="avatar-img rounded-circle border border-white border-3 shadow" :src="inputPhoto" alt="">
                                            <img v-else class="avatar-img rounded-circle border border-white border-3 shadow" src="/assets/images/avatar/user.png" alt="">
                                        </div>

                                        <!-- Information START -->
                                        <div class="row">

                                            <!-- Information item -->
                                            <div class="col-md-6">
                                                <ul class="list-group list-group-borderless">
                                                    <li class="list-group-item">
                                                        <span>Name:</span>
                                                        <span class="h6 mb-0">{{inputName}}</span>
                                                    </li>

                                                    <li class="list-group-item">
                                                        <span>Email:</span>
                                                        <span class="h6 mb-0">{{inputEmail}}</span>
                                                    </li>

                                                </ul>
                                            </div>

                                        </div>
                                        <!-- Information END -->
                                    </div>
                                    <!-- Card body END -->
                                </div>
                            </div>
                            
                            <!-- Personal information END -->

                            <!-- Student status chart START -->
                            <div class="col-xxl-12">
                                <div class="row g-4">

                                    <!-- Enrolled START -->
                                    <div class="col-md-12 col-xxl-12">
                                        <div class="card bg-transparent border overflow-hidden">
                                            <!-- Card header -->
                                            <div class="card-header bg-light border-bottom">
                                                <h5 class="card-header-title mb-0">상태변경</h5>
                                            </div>
                                            <!-- Card body -->
                                            <div class="card-body p-0">
                                                <form @submit.prevent="sendForm" class="row g-4 align-items-center mt-1">

                                                    <div class="col-12">
                                                        <div class="row g-xl-0 align-items-center">
                                                            <div class="col-lg-2 ms-3">
                                                                <h6 class="mb-lg-0">활성화여부 <span class="text-danger">*</span></h6>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="d-flex">
                                                                    <div class="form-check radio-bg-light me-4">
                                                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
                                                                                v-model="inputIsActive" 
                                                                                value="true"
                                                                            >
                                                                        <label class="form-check-label" for="flexRadioDefault1">
                                                                            활성화
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check radio-bg-light">
                                                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" 
                                                                                v-model="inputIsActive" 
                                                                                value="false"
                                                                            >
                                                                        <label class="form-check-label" for="flexRadioDefault2">
                                                                            비활성화
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12">
                                                        <div class="row g-xl-0 align-items-center">
                                                            <div class="col-lg-2 ms-3">
                                                                <h6 class="mb-lg-0">상태변경 <span class="text-danger">*</span></h6>
                                                            </div>
                                                        
                                                            <div class="col-lg-8">
                                                                <div class="row g-2 g-sm-4">
                                                                    <div class="col-4">
                                                                        <select class="form-select js-choice z-index-9 border-0 bg-light" aria-label=".form-select-sm" v-model="inputStatus">
                                                                            <option :value="0">미처리</option>
                                                                            <option :value="2">진행중</option>
                                                                            <option :value="8">보류중</option>
                                                                            <option :value="9">Accept</option>
                                                                            <option :value="-1">Reject</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <!-- Radio items -->
                                                        <!-- <div class="ms-3">
                                                            <div class="form-check form-switch form-check-md">
                                                                <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsActive" @click="onChangeActive">
                                                                <label v-if="inputIsActive" class="form-check-label" for="profilePublic">활성화 </label>
                                                                <label v-else class="form-check-label" for="profilePublic">비활성화</label>
                                                            </div>
                                                        </div> -->
                                                    <div class="text-center mb-3">
                                                        <router-link :to="backLink" class="btn btn-secondary mb-0 " >목록</router-link>
                                                        <button type="submit" class="btn btn-primary mb-0 ms-2">Save Change</button>
                                                        <!-- <button type="button" class="btn btn-orange mb-0 ms-2" data-bs-toggle="modal" data-bs-target="#notificationModal">Notification</button> -->
                                                    </div>
                                                        
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Enrolled END -->

                                </div>
                            </div>
                            <!-- Student status chart END -->

                        </div> <!-- Row END -->

                  

                    </div>
                    <!-- Edit profile END -->
                    
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pages > companydash > CompanyDashWorkerRequestUpdate.vue]" ;
import axios from 'axios';

import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';

export default {
    components: {
        "company-dashboard-top": CompanyDashboardTop,
        "company-dashboard-left": CompanyDashboardLeft,
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,    
            
            inputCreate_id : null,
            inputPhoto : null,
            inputName : null,
            inputEmail : null,
            inputIsActive :null,
            inputStatus : null,
            
            inputDescription: null, //notification

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getItem();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },    
    computed: {
        backLink(){
            return '/companydash/worker/requests'
        },
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        paginationCompu(){
            return this.pagination ;
        },

    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            // console.log("[InstructorDashSettingUpdate.vue] isAuth():", this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUserId(){
            // console.log("[InstructorDashSettingUpdate.vue] isUser():", this.$store.getters['auth/userId']);
            return this.$store.getters['auth/userId'] ;
        },
        onChangeActive(){
            this.inputIsActive = !this.inputIsActive ;
        },

        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/worker/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log('[InstructorDashRequestUpdate.vue] profile:', data.profile);
                
                this.inputCreate_id = data.response.create_id ;

                this.inputPhoto = data.response.create_photo ;
                this.inputName = data.response.create_name ;
                this.inputEmail = data.response.create_email ;

                this.inputIsActive = data.response.is_active ;
                this.inputStatus = data.response.status ;


            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },

        // 전송하기  
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'is_active' : this.inputIsActive,
                'status':this.inputStatus,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputIsActive === '' || this.inputIsActive === undefined || this.inputIsActive === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="활성화 여부를 선택해주세요.";
                return ;
            }

            if( this.inputStatus === '' || this.inputStatus === undefined || this.inputStatus === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="상태를 선택해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/worker/status/'+this.id,
                    headers: { 
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // notification 발송    
        async sendNotificationForm(){
            let funcname__ ="sendNotificationForm() ";

            let dataForm = { 
                'receive_id': this.inputCreate_id,
                'content': this.inputContent,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputContent === '' || this.inputContent === undefined || this.inputContent === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/notification/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                }, 

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData.status === 200) {
                    const data = serverData.data ;
                    // console.log(filename__+''+funcname__+'  data:', data);

                    if(data.code === 201){
                                                    
                        this.inputContent = null ;

                        this.dialogTitle ='Success' ;
                        this.error = 'Notification 전송에 성공했습니다.';     

                    } else {
                        console.log(filename__+''+funcname__+' err.msg:', serverData.data.msg);
                        this.dialogTitle ="입력 실패" ;
                        this.error = serverData.data.msg;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }
            }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },


    }
}
</script>

<style scoped>

</style>