export default {
    mainTopProductCategoryUserGet(state){
        return state.mainTopProductCategoryUserState ;
    },
    mainTopProductCategoryAdminGet(state){
        return state.mainTopProductCategoryAdminState ;
    },
    isMainTopProductCategoryGet(state){
        console.log("[store/product/getters.js] state.mainTopProductCategoryUserState:",state.mainTopProductCategoryUserState)
        return !!state.mainTopProductCategoryUserState
    },
  
}