<template>
<tr>
    <td class="text-center text-sm-start">{{num}}</td> 
    <td>
        <div class="d-flex align-items-center">
            <div v-if="!is_text" class="w-100px">
                <img v-if="image" :src="image" class="rounded" alt="">
                <img v-else src="/assets/images/courses/4by3/08.jpg" class="rounded" alt="">
            </div>
            <div class="mb-0 ms-2">
                <h6 class="table-responsive-title">
                    <router-link :to="detailLink">{{title}}</router-link>
                </h6>
                
            </div>
        </div>
    </td>

    <!-- Table data -->
    <td>{{total_count}}</td>

    <!-- Table data -->
    <td>{{ complete_count }}</td>

    <!-- Table data -->
    <td>

        <router-link v-if="percent == 100" :to="detailLink" class="btn btn-sm btn-success me-1 mb-1 mb-x;-0"><i class="bi bi-check me-1"></i>Complete</router-link>
        <!-- <a v-if="percent == 100" href="#" class="btn btn-sm btn-light me-1"><i class="bi bi-arrow-repeat me-1"></i>Restart</a> -->
        <router-link v-else :to="detailLink" class="btn btn-sm btn-primary-soft me-1 mb-1 mb-md-0"><i class="bi bi-play-circle me-1"></i>Continue</router-link>

        <div class="overflow-hidden mt-3">
            <span class="uppercase">&nbsp; </span>
            <div class="progress progress-sm bg-danger bg-opacity-10">
                <div 
                    role="progressbar" 
                    data-aos="slide-right" 
                    data-aos-delay="200" 
                    data-aos-duration="1000" 
                    data-aos-easing="ease-in-out" 
                    class="progress-bar bg-danger aos aos-init aos-animate" 
                    :style="'width: '+percent+'%;'" 
                    aria-valuenow="${percent}" 
                    aria-valuemin="0" 
                    aria-valuemax="100">
                    <span class="progress-percent-simple h6 mb-0">{{percent}}%</span>
                </div>
            </div>
        </div>  

    </td>
</tr>


</template>

<script>
export default {
    components: {},
    props: [
        'id','num','date',
        'title','level','is_price','price','coursetime','lecturecount',
        'image','is_text',
        'is_display', 'onoff',
        'percent','total_count', 'complete_count','enroll_date',
        'page', 'search'
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            return '/course/'+this.id ;
        },
        timeFormat(){
            let hour = parseInt(this.coursetime/60) ;
            let minute = this.coursetime - hour * 60 ;
            if(hour == 0){
                return this.coursetime +'m'                
            } else if(minute == 0){
                return hour+'h'
            } else {
                return hour + 'h '+minute + 'm' ;                
            }
        }

    },
    methods: {

    },
    
}
</script>