import mutations from './mutations.js' ;
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state(){
        return {
            userId: null,
            token: null,
            email: null, 
            level: 0,
            admin: false,
            isPageAdmin : false, 
            tokenExpiration: null,
        }
    },    
    mutations,
    actions,
    getters
}
