<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>
	
    <!-- =======================
    Page Banner START -->
        <company-dashboard-top :title="'정보창고 생성'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
            <div class="row">
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <company-dashboard-left :menu="'blog'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
    
                    <!-- <div class="row">
                        <div class="col-md-8 mx-auto text-center">
                            <p class="text-center">Use this interface to add a new Course to the portal. Once you are done adding the item it will be reviewed for quality. If approved, your course will appear for sale and you will be informed by email that your course has been accepted.</p>
                        </div>
                    </div> -->
            
                    <div class="card bg-transparent border rounded-3 mb-5">
                        <div id="stepper" class="bs-stepper stepper-outline">
                            <!-- Card header -->
                            <div class="card-header bg-light border-bottom px-lg-5">
                                <!-- Step Buttons START -->
                                <div class="bs-stepper-header" role="tablist">
                                    <!-- Step 1 -->
                                    <div class="step" :class="inputStep == 1 ? 'active':''" data-target="#step-1">
                                        <div class="d-grid text-center align-items-center">
                                            <button type="button" @click="onChangeStep(1)" class="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger1" aria-controls="step-1">
                                                <span class="bs-stepper-circle">1</span>
                                            </button>
                                            <h6 class="bs-stepper-label d-none d-md-block">Intro</h6>
                                        </div>
                                    </div>
                                    <div class="line"></div>
            
                                    <!-- Step 2 -->
                                    <div class="step" :class="inputStep == 2 ? 'active':''" data-target="#step-2">
                                        <div class="d-grid text-center align-items-center">
                                            <button v-if="contentIdCompu" type="button" @click="onChangeStep(2)" class="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger2" aria-controls="step-2">
                                                <span class="bs-stepper-circle">2</span>
                                            </button>
                                            <button v-else type="button" class="btn btn-link mb-0"  style="cursor:none;">
                                                <span class="bs-stepper-circle">2</span>
                                            </button>
                                            <h6 class="bs-stepper-label d-none d-md-block">Description</h6>
                                        </div>
                                    </div>
                                    <div class="line"></div>

                                    <!-- Step 3 -->
                                    <div class="step" :class="inputStep == 3 ? 'active':''" data-target="#step-3">
                                        <div class="d-grid text-center align-items-center">
                                            <button v-if="contentIdCompu" type="button" @click="onChangeStep(3)" class="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger3" aria-controls="step-3">
                                                <span class="bs-stepper-circle">3</span>
                                            </button>
                                            <button v-else type="button" class="btn btn-link mb-0"  style="cursor:none;">
                                                <span class="bs-stepper-circle">3</span>
                                            </button>
                                            <h6 class="bs-stepper-label d-none d-md-block">Media</h6>
                                        </div>
                                    </div>
                                                
                                </div>
                                <!-- Step Buttons END -->
                            </div>
            
                            <!-- Card body START -->
                            <div class="card-body">
                                <!-- Step content START -->
                                <div class="bs-stepper-content">

                                    
                                    <!-- <form onsubmit="return false"> -->
            
                                        <!-- Step 1 content START -->
                                        <div id="step-1" role="tabpanel" class="content fade dstepper-block" :class="inputStep == 1 ? 'active':'dstepper-none'" aria-labelledby="steppertrigger1">
                                            <h4>정보창고</h4>
                                            <hr>

                                            <companydash-blog-intro-create-component 
                                                @updateContentId ="updateContentId" 
                                            />                                                
                                        </div>
                                        <!-- Step 1 content END -->
            
                                        <!-- Step 2 description  START -->
                                        <div id="step-2" role="tabpanel" class="content fade dstepper-block" :class="inputStep == 2 ? 'active':'dstepper-none'" aria-labelledby="steppertrigger2">
                                            <!-- Title -->
                                            <h4>정보창고 Description</h4>           
                                            <hr> <!-- Divider -->

                                            <div v-if="contentIdCompu">
                                                <companydash-blog-description-panel-component 
                                                    :content_id ="contentIdCompu" 
                                                    @changestep="onChangeStep" 
                                                />
                                            </div>
                                        </div>
                                        <!-- Step 2 content END -->
            
                                        <!-- Step 3 media START -->
                                        <div id="step-3" role="tabpanel" class="content fade dstepper-block" :class="inputStep == 3 ? 'active':'dstepper-none'" aria-labelledby="steppertrigger3">
                                            <div v-if="contentIdCompu">
                                                <companydash-blog-media-panel-component 
                                                    :content_id ="contentIdCompu" 
                                                    @changestep="onChangeStep" 
                                                />

                                            </div>

                                            <!-- <div class="d-md-flex justify-content-between align-items-start mt-4">
                                                <button @click="onChangeStep(2)" class="btn btn-secondary prev-btn mb-2 mb-md-0">Previous</button>
                                                <div class="text-md-end">
                                                    <router-link :to="listLink" class="btn btn-success mb-2 mb-sm-0">Submit a Course</router-link>
                                                    <p class="mb-0 small mt-1">Once you click "Submit a Course", your course will be uploaded and marked as pending for review.</p>
                                                </div>
                                            </div>             -->

                                        </div>
                                        <!-- Step 3 content END -->
                                                    
            
                                        <!-- Step 5 content START -->
                                        <div id="step-5" role="tabpanel" class="content fade dstepper-block" :class="inputStep == 5 ? 'active':'dstepper-none'" aria-labelledby="steppertrigger5">

                                            <div class="d-md-flex justify-content-between align-items-start mt-4">
                                                <button @click="onChangeStep(4)" class="btn btn-secondary prev-btn mb-2 mb-md-0">Previous</button>
                                                <!-- <button class="btn btn-light me-auto ms-md-2 mb-2 mb-md-0">Preview Course</button> -->
                                                <div class="text-md-end">
                                                    <router-link :to="listLink" class="btn btn-success mb-2 mb-sm-0">Submit a Course</router-link>
                                                    <p class="mb-0 small mt-1">Once you click "Submit a Course", your course will be uploaded and marked as pending for review.</p>
                                                </div>
                                            </div>            
            
                                        </div>
                                        <!-- Step 5 content END -->
                                    
                                </div>
                            </div>
                            <!-- Card body END -->
                        </div>
                    </div>



                    
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
    
    <hr/>
</div>
</template>

<script>
var filename__ = "[pages > companydash > CompanyDashBlogCreate.vue]" ;

// select__ Select__ modal__ teleport__ preview__
// teleport : https://vuejs.org/guide/built-ins/teleport.html#basic-usage
// router-link preview__ : https://github.com/ashwamegh/vue-link-preview
// datetime__ https://mariomka.github.io/vue-datetime/

import axios from 'axios';
// import { ref } from 'vue' ;

import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';

import CompanyDashBlogIntroCreateComponent from '../../components/companydash/CompanyDashBlogIntroCreateComponent.vue';
import CompanyDashBlogDescriptionPanelComponent from '../../components/companydash/CompanyDashBlogDescriptionPanelComponent.vue';
import CompanyDashBlogMediaPanelComponent from '../../components/companydash/CompanyDashBlogMediaPanelComponent.vue';

export default {
    components: {
        "company-dashboard-top": CompanyDashboardTop,
        "company-dashboard-left": CompanyDashboardLeft,

        'companydash-blog-intro-create-component' : CompanyDashBlogIntroCreateComponent,
        'companydash-blog-description-panel-component' : CompanyDashBlogDescriptionPanelComponent,
        'companydash-blog-media-panel-component' : CompanyDashBlogMediaPanelComponent,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            inputId : null, 

            inputStep : 1,
            
        }
    },
    setup(){
        // const inputId = ref(false) ;
        // return { inputId }
    },
    created(){
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            // 처음 로딩하면 카테고리 리스트 불러오기 
            // if(!this.inputAlredayCreated){
            //     this.getCategoryList();
            // }            
            return ;
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    computed: {
        listLink(){
            return '/companydash/blogs'
        },
        contentIdCompu(){
            return this.inputId ;
        },

    },    
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },        
        onChangeStep(_step){
            this.inputStep = _step ;
        },
        updateContentId(_id){
            // step1-create 에서 생성된 content_id 를 업데이트함 
            this.inputId = _id ;
            this.onChangeStep(2);
        },

        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },

    }
}
</script>

<style scoped>

</style>