<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- 답변 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idReplyCompu" tabindex="-1" aria-labelledby="replyModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-info">
                    <h1 class="modal-title fs-5" id="replyModalLabel">답변하기</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <label class="form-label">Your Comment *</label>
                    <textarea class="form-control" rows="3" v-model.trim="inputReplyDescription"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="sendReplyForm" class="btn btn-primary" data-bs-dismiss="modal">Reply comment</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

    <!-- 수정 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idUpdateCompu" tabindex="-1" aria-labelledby="updateModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-info">
                    <h1 class="modal-title fs-5" id="updateModalLabel">수정하기</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <label class="form-label">Your Reply Comment</label>
                    <textarea class="form-control" rows="3" v-model.trim="inputReplyUpdateDescription"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="sendReplyUpdateForm" class="btn btn-info" data-bs-dismiss="modal">Update Reply</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

    <!-- 삭제 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" id="idDeleteCompu">Delete Comment</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeReplyForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>

    <li class="list-group-item" :class="depth_class" >
        
        <h6><i class="fas fa-reply me-2"></i> reply comment:</h6>
        <div class="avatar">
            <img v-if="create_photo" class="avatar-img rounded-circle border border-white border-3 shadow" :src="create_photo" alt="">
            <img v-else class="avatar-img rounded-circle border border-white border-3 shadow" src="/assets/images/avatar/user.png" alt="">
        </div>
        <div class="row">
            <div class="col-md-12">
                <ul class="list-group list-group-borderless">
                    <li class="list-group-item">
                        <span>Name:</span>
                        <span class="h6 mb-0">{{create_name}}</span>
                    </li>
                </ul>
            </div>
        </div>


        <div class="bg-light p-2 rounded">
            <span class="mb-0">{{inputReplyUpdateDescription}}</span>
        </div>   
        <button v-if="depth < 7" type="button" class="btn btn-sm btn-primary mb-0" data-bs-toggle="modal" :data-bs-target="idReplyShapCompu">Reply</button>
        <button type="button" class="btn btn-sm btn-info mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idUpdateShapCompu"><i class="bi bi-pencil"></i></button>
        <button type="button" class="btn btn-sm btn-danger mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu"><i class="bi bi-trash"></i></button>
        <button type="button" @click="changeIsDisplay" class="btn btn-sm mb-0 ms-2" :class="inputIsDisplay ? 'btn-primary':'btn-orange'" >{{inputIsDisplay ?'보임':'숨김'}}</button>

    </li>
</div>    
</template>

<script>
var filename__="[components > companydash > CompanyDashCommentReplyListComponent.vue] " ;

import axios from 'axios';

export default {
    components: {},
    props: [
        'content_id', 'company_id','group_id',
        'id','description','depth','date','is_display',
        'create_name','create_photo'
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,    

            inputIsDisplay : this.is_display,
            inputReplyDescription : null,
            inputReplyUpdateDescription : this.description,
            
        }
    },
    
    computed: {
        idReplyCompu(){
            return 'replyModal'+this.id
        },
        idReplyShapCompu(){
            return '#replyModal'+this.id
        },
        idUpdateCompu(){
            return 'updateModal'+this.id
        },
        idUpdateShapCompu(){
            return '#updateModal'+this.id
        },
        idDeleteCompu(){
            return 'deleteModal'+this.id
        },
        idDeleteShapCompu(){
            return '#deleteModal'+this.id
        },
        depth_class(){
            if(this.depth === 1){
                return ''
            
            } else {
                return "ps-"+this.depth+" ps-md-"+(this.depth+2)
            }    
        },

    },
    methods: {
        handleError(){
            this.error = null;
        },
        changeIsDisplay(){
            this.inputIsDisplay = !this.inputIsDisplay ;
            this.sendDisplayForm();
        },
        // reply comment 발송    
        async sendReplyForm(){
            let funcname__ ="sendReplyForm() "
            let dataForm = { 
                'category': 'course',
                'content_id': this.content_id,
                'company_id': this.company_id,
                'parent_id' : this.id,
                'group_id' : this.group_id,
                'description': this.inputReplyDescription,                
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputReplyDescription === '' || this.inputReplyDescription === undefined || this.inputReplyDescription === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/comment/course/reply/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                }, 

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData.status === 200) {
                    const data = serverData.data ;
                    // console.log(filename__+''+funcname__+'  data:', data);

                    if(data.status_code === 201){
                                                    
                        this.inputReplyDescription = null ;

                        this.dialogTitle ='Success' ;
                        this.error = 'Reply Comment 전송에 성공했습니다.';    

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('sendRefreshForm');

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }
            }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        async sendReplyUpdateForm(){
            let funcname__ ="sendReplyUpdateForm() "
            let dataForm = { 
                'description': this.inputReplyUpdateDescription,    
                'is_reply': true,            
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputReplyUpdateDescription === '' || this.inputReplyUpdateDescription === undefined || this.inputReplyUpdateDescription === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="내용을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/comment/course/update/'+this.id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;

                } else {
                    // console.log(filename__+''+funcname__+" serverData:", serverData);

                    if(typeof serverData !== "undefined" && serverData.status === 200) {
                        const data = serverData.data ;
                        // console.log(filename__+''+funcname__+'  data:', data);

                        if(data.status_code === 201){
                                                        
                            // this.inputReplyUpdateContent = null ;

                            this.dialogTitle ='Success' ;
                            this.error = 'Reply 수정에 성공했습니다.';     

                            if(this.dialogTitle ==='Success'){
                                setTimeout(() => {
                                    this.error = null;
                                    this.dialogTitle = null ;
                                    this.$emit('sendRefreshForm');

                                }, 500);
                            }                        


                        } else {
                            console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                            this.dialogTitle ="Error" ;
                            this.error = serverData.data.error_description;
    
                        }
                    } else {
                        this.isLoading = false ;

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';

                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },
        // 팝업 창을 통해서 삭제   
        async removeReplyForm(){
            let funcname__ = "removeReplyForm() "
            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/comment/course/delete/'+this.id,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                this.isLoading = false ;

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){                    
                    return ;
                }

                // console.log(filename__+funcname__+"serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        // console.log(filename__+funcname__+' data:', serverData.data);
                                                
                        this.dialogTitle ='Success' ;
                        this.error = '삭제 성공했습니다.';  
                        
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('sendRefreshForm');

                            }, 500);
                        }                        

                    } else if(serverData.data.status_code == 500){
                        // console.log(filename__+funcname__+' data:', serverData.data);
                                                
                        this.dialogTitle ='Fail' ;
                        this.error = '하위 답변이 존재해서 삭제할 수 없습니다.';    
                                                                                                
                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                } else {

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';
                }

            } catch(error){
                console.error(filename__+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="삭제 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        }, 
        async sendDisplayForm(){
            let funcname__ ="sendDisplayForm() " ;
            let dataForm = { 
                'is_display': this.inputIsDisplay,
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/comment/course/display/'+this.id,
                    headers: { 
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.$emit('sendRefreshForm');

                            }, 500);
                        }                        


                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;


        },


    },
    
}
</script>