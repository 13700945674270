import axios from "axios";

export default {
    //Signup
    async Signup(context, payload){
        const data = {
            email: payload.email,
            password: payload.password,
        };

        //__ console.log("[store/modules/auth/actions.js_signup()] data:",data)

        const response = await axios( {
            method: 'post', 
            url:'/api/user/signup', 
            data: data 
        });
        // console.log('[store/modules/auth/actions.js_signup()] response:', response);

        const responseData = await response.data ;
        // console.log('[store/modules/auth/actions.js_signup()] responseData:', responseData);

        if(responseData.status_code === 201){
            // console.log('[store/modules/auth/actions.js_signup()] success:', responseData.msg);
            // context.commit('addRequest', newRequest);  // mutations.js
            context.commit('setUserId', {                
                userId: parseInt(responseData.response.id)   
            });  // store/modules/auth/mutations.js          

        } else {
            // console.log('[store/modules/auth/actions.js_signup()] error:', responseData.msg);

            const error = new Error(responseData.error_description);
            // console.error("error ==> [store/modules/auth/actions.js_signup()] : \n", error);
            error.error_description = responseData.error_description

            throw error ;            

        }
        
    },   
    //Login
    async Login(context, payload){
        const data = {
            email: payload.email,
            password: payload.password,
        };

        // console.log("[store/modules/auth/actions.js_login()] data:",data)

        const response = await axios( { 
            method: 'post', 
            url:'/api/user/login', 
            data: data 
        }).catch( err => {

            console.log('[store/modules/auth/actions.js_Login()] err:', err);
            // console.log('[store/modules/auth/actions.js_Login()] err.message:', err.message);
            err['error_description'] = err.message ;

            throw err ;
        });
        
        // console.log('[store/modules/auth/actions.js_login()] response:', response);

        if(response.status === 200){                        
            const data = response.data ;

            if(data['status_code'] === 500){
                const error = {
                    status : data['status_code'] ,
                    error_description : data['error_description'], 
                } ;                    
                throw error ;  
            
            } else if(data['status_code'] === 200){

                // console.log('[store/modules/auth/actions.js_login()] data:', data);
                
                const user = data['user'] ;            
                const _name = user['displayname'] ? 
                                        user['displayname'] : user['name'] ? 
                                                        user['name'] : user['email'].split('@')[0] ; //이메일 앞부분

                const _photo = user['photo'] ? user['photo'] : false ;

                context.commit('setUser', {
                    
                    token: data['access_token'],  
                    userId : user['id'],
                    email : user['email'],              
                    is_admin : user['is_admin'],              
                    name: _name,   
                    photo: _photo,  
                    isPageAdmin: false,   //처음 로그인할 때는 false  
                    
                //     // token: responseData.access_token['iat'],
                //     // userId: responseData.access_token['sup'],
                //     // tokenExpiration: responseData.access_token['exp'],

                });  // store/modules/auth/mutations.js

                

                localStorage.setItem('token', data['access_token']);
                localStorage.setItem('userId', user['id']);
                localStorage.setItem('email', user['email']);
                localStorage.setItem('name', _name);
                localStorage.setItem('photo', _photo);
                localStorage.setItem('is_admin', user['is_admin']);
                localStorage.setItem('isPageAdmin', false);
            
            }  

        } else {

            const error = {
                status_code : response.status ,
                error_description : response.detail, 
            } ;           

            console.error("error ==> [store/modules/auth/actions.js_signup()] : \n", error);
            error['error_description'] = response.detail ;
            
            throw error ;            
        }
    },   

    //Logout
    async Logout(context){
        // console.log('[store/modules/auth/actions.js_logout()] ');

        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        localStorage.removeItem("photo");
        localStorage.removeItem("is_admin");
        localStorage.removeItem("isPageAdmin");
        // localStorage.removeItem("tokenExpiration");

        //global 변수, 로그인 만료시간 
        // clearTimeout(gTimer);

        context.commit('setUser', {
            token: null,
            userId: null,
            email: null,
            name: null,
            photo: null,
            is_admin: null,
            isPageAdmin: null,
            // tokenExpiration: null 
        }); //=> mutations.js


        // const response = await axios( { 
        //     method: 'get', 
        //     url:'/api/user/logout'
        // });
        
        // console.log('[store/modules/auth/actions.js_logout()] response:', response);

        // const responseData = await response.data ;

        // if(responseData.code === 200){            
        //     console.log('[store/modules/auth/actions.js_logout()] responseData:', responseData);            
            
        // } else {
        //     console.log('[store/modules/auth/actions.js_logout()]', responseData.msg);

        //     const error = new Error(responseData.msg);
        //     console.error("error ==> [store/modules/auth/actions.js_logout()] : \n", error);
            
        //     throw error ;            
        // }        
    },   
    Name(context, payload){
        const data = {
            name: payload.name
        };

        // console.log("[store/modules/auth/actions.js_name()] data:",data)
        
        context.commit('setName', {                
            name: data.name   
        });  // store/modules/auth/mutations.js

        localStorage.setItem('name', data.name);
    }, 

    Photo(context, payload){
        const data = {
            photo: payload.photo
        };

        // console.log("[store/modules/auth/actions.js_photo()] data:",data)
        
        context.commit('setPhoto', {                
            photo: data.photo   
        });  // store/modules/auth/mutations.js

        localStorage.setItem('photo', data.photo);
    }, 

    Level(context, payload){
        const data = {
            level: payload.level
        };

        console.log("[store/modules/auth/actions.js_level()] data:",data)
        
        context.commit('setLevel', {                
            level: data.level   
        });  // store/modules/auth/mutations.js

        localStorage.setItem('level', data.level);
    },  
    
    // App.vue 에서 관리자 페이지 여부 확인 
    pageAdmin(context){
        context.commit('setPageAdmin', {                
            isPageAdmin: true   
        });  // store/modules/auth/mutations.js

        localStorage.setItem('isPageAdmin', true);
    },      
    outPageAdmin(context){
        context.commit('setPageAdmin', {                
            isPageAdmin: false   
        });  // store/modules/auth/mutations.js

        localStorage.setItem('isPageAdmin', false);
    },      

}