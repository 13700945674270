<template>
    <tr>
        <!-- Table data -->
        <td>{{num}}</td>

        <!-- Table data -->
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        <router-link :to="detailLink" class="stretched-link">{{name}}</router-link>
                    </h6>
                </div>
            </div>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        <router-link :to="detailLink" class="stretched-link">{{phone}}</router-link>
                    </h6>
                </div>
            </div>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        <router-link :to="detailLink"  class="stretched-link">{{email}}</router-link>
                    </h6>
                </div>
            </div>
        </td>

        <!-- Table data -->
        <td>
            <h6 class="table-responsive-title mb-0">
                <router-link :to="detailLink">{{contentSplice30}} </router-link>
            </h6>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0"><a href="#" class="stretched-link">{{date}}</a></h6>
                </div>
            </div>
        </td>

        <td>
            <button v-if="is_dashboard" type="button" class="btn btn-danger-soft me-1 mb-1 mb-lg-0">Dashboard</button>
        </td>
        <td>
            <!-- <span v-if="status == 0" class="badge text-bg-danger">미처리</span>
            <span v-if="status == 1" class="badge text-bg-info">확인중</span>
            <span v-if="status == 2" class="badge text-bg-primary">진행중</span>
            <span v-if="status == 8" class="badge text-bg-warning">보류중</span>
            <span v-if="status == 9" class="badge text-bg-success">처리완료</span> -->

            <button v-if="status == 0" type="button" class="btn btn-orange-soft me-1 mb-1 mb-lg-0">미처리</button>
            <button v-if="status == -1" type="button" class="btn btn-danger-soft me-1 mb-1 mb-lg-0">Reject</button>
            <button v-if="status == 2" type="button" class="btn btn-primary-soft me-1 mb-1 mb-lg-0">진행중</button>
            <button v-if="status == 8" type="button" class="btn btn-warning-soft me-1 mb-1 mb-lg-0">보류중</button>
            <button v-if="status == 9" type="button" class="btn btn-success-soft me-1 mb-1 mb-lg-0">Accept</button>
            <!-- <a href="#" class="btn btn-primary-soft me-1 mb-0" data-bs-toggle="modal" data-bs-target="#appDetail">View App</a> -->
        </td>

        <!-- Table data -->
        <td>
            <router-link :to="detailLink" class="btn btn-info-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="회원정보 보기">
                <i class="bi bi-pencil-square"></i>
            </router-link>
            <!-- <button class="btn btn-danger-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Delete">
                <i class="bi bi-trash"></i>
            </button>
            <a href="#" class="btn btn-sm btn-info-soft mb-0" data-bs-toggle="modal" data-bs-target="#viewReview">View</a> -->
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <div class="avatar avatar-md">
                    <router-link :to="openLink">
                        <img v-if="create_photo" :src="create_photo" class="rounded-circle" alt="">
                        <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                    </router-link>
                </div>
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        {{ create_name }}
                        <br/>
                        <small>{{create_email}}</small>
                        <br/>
                        <router-link :to="openLink"  class="stretched-link">                            
                            <span class="badge text-bg-primary ms-2">개인정보</span>
                        </router-link>
                    </h6>
                </div>
            </div>
        </td>

    </tr>    
</template>

<script>
export default {
    components: {},
    props: [
        'id','num','name','phone','email','content','status','date',
        'create_id','create_name','create_email','create_photo',
        'is_dashboard',
        'page','search',
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        openLink(){
            return '/admin/member/'+this.create_id ; 
        },
        detailLink(){
            return '/admin/company/request/'+this.id+'/'+this.create_id+'?page='+this.page+"&search="+this.search ; 
        },
        contentSplice30(){
            return this.content.slice(0,30)+"....";
        },

    },
    methods: {

    },
    
}
</script>