export default {
    setLoading(state, payload){
        // console.log('[store/setting/mutations.js] setLoading() payload:', payload);
        
        state.is_loading = payload.is_loading;

        state.name = payload.name;
        state.copyright= payload.copyright;
        state.email= payload.email;
        state.description = payload.description;
        state.phone = payload.phone;
        state.worktime = payload.worktime;
        state.supportemail = payload.supportemail;
        state.contactaddr = payload.contactaddr;

        state.is_active = payload.is_active;
    }
}