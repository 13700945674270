<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div v-if="inputIsMultiple" class="position-relative">
        <!-- 이미지 여러개  -->
        <div id="carouselExampleCaptions" class="carousel slide">
            <div class="carousel-indicators">
                <button v-for="item in itemList"
                    type="button" 
                    data-bs-target="#carouselExampleCaptions" 
                    :data-bs-slide-to="item.count" 
                    :class="item.count ==0 ? 'active':''" 
                    :aria-current="item.count==0 ? true : false " 
                    :aria-label="'Slide '+item.count">
                </button>
            </div>
            <div class="carousel-inner">
                <div v-for="item in itemList" 
                    class="carousel-item card overflow-hidden h-400px h-md-500px text-center rounded-0"
                    :class="item.count==0 ? 'active':''"
                    style="background-position: center left; background-size: cover;"                                
                    :style="'background-image:url('+item.image_path"
                    >
                    <!-- Background dark overlay -->
                    <div class="bg-overlay bg-dark opacity-6"></div>
                    <!-- Card image overlay -->
                    <div class="card-img-overlay d-flex align-items-center p-2 p-sm-4"> 
                        <div class="w-100 my-auto">{{ item.link }}
                            <div v-if="item.description" class="row justify-content-center">
                                <div class="col-11 col-lg-7">
                                    <!-- Title -->
                                    <h1 class="text-white display-6">{{item.title}}</h1>
                                    <p class="text-white">{{ item.description }}</p>
                                    <!-- Button -->
                                    <!-- <a href="university-admission-form.html" class="btn btn-primary me-2 mb-0">Admissions</a> -->
                                    <router-link v-if="item.link" :to="item.link" class="btn btn-outline-white mb-0">자세히 보기</router-link>
                                </div>
                            </div>
                        </div>
                    </div>                                
                </div>

            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

    </div>
    <img v-else-if="inputPhoto" :src="inputPhoto">  
    <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" width="100%">
    <!-- <img src="/assets/images/bg/07.jpg" style="height:300px;">  here-->

</div>    
</template>

<script>
// __import
var filename__= "[components > main > MainSlidePanelComponent.vue] "
import axios from 'axios';


export default {
    components: {},
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputIsMultiple: false,
            inputPhoto: null, 
            itemList: [],




        }
    },
    created(){
        this.getItem()
        this.getList()
    },
    computed: {

    },
    methods: {
        handleError(){
            this.error = null;
        },

        async getItem(){
            let funcname__ ="getItem()" ;
            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/setting/mainslide',
            }).catch( err => {

                let dataForm = { 
                    category: 'errorlog(api catech)' , 
                    filename : filename__,
                    methodname : funcname__,
                    title: 'Error(API)',       
                    content : ''+err,
                } ;  
                console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                axios({
                    method: 'post',
                    url: '/api/errorlog/create',
                    data: dataForm,
                });

                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                return ;
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.inputIsMultiple = data.response ;
				// console.log(filename__+''+funcname__+" inputIsMultiple:", this.inputIsMultiple);
				
            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },

        async getList(){
            let funcname__ ="getList()" ;

            this.isLoading = true ;   
            
            let serverData = await axios({
                method: 'get',
                url: '/api/mainslide/all/user', 

            }).catch( err => {

                let dataForm = { 
                    category: 'errorlog(api catech)' , 
                    filename : filename__,
                    methodname : funcname__,
                    title: 'Error(API)',       
                    content : ''+err,
                } ;  
                console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                axios({
                    method: 'post',
                    url: '/api/errorlog/create',
                    data: dataForm,
                });

                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                return ;

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let count= 0 ;

                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    if(item.category ==='single'){
                        this.inputPhoto = item.image_path ;
                    } else {

                        this.itemList.push({
                            count: count++,
                            id:item.id, 
                            image_path: item.image_path,
                            title : item.title,
                            link : item.link,
                            description : item.description,
                        })
                    }
                }

                // console.log(filename__+''+funcname__+" inputPhoto:", this.inputPhoto);
                // console.log(filename__+''+funcname__+" itemList:", this.itemList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }
            this.isLoading = false ;            
        },


    },
    
}
</script>
<style scoped >

</style>