<template>
<div> 
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <div class="row">

        <div class="col-12 mb-3">
            <div class="form-check form-switch form-check-md">
                <input class="form-check-input" type="checkbox" role="switch" id="isImage"  v-model="inputIsText" @click="onChangeIsText">
                <label v-if="inputIsText"  class="form-check-label" for="isImage">Text 출력(description)</label>
                <label v-else class="form-check-label" for="isImage">이미지 </label>
            </div>
        </div>
        <hr/>
        <div v-if="isTextCompu" class="col-12">
            <span class="badge text-bg-secondary mb-2">정보에 대한 짧은 설명</span>
            <p v-if="blog" class="form-control p-grey">{{ blog.subtitle }}</p>
            <textarea v-else class="form-control" rows="2" placeholder="subtitle의 내용이 대신합니다" disabled></textarea>
        </div>

        <div v-if="!isTextCompu" class="col-12">
            <div class="row g-xl-0 align-items-center">
                <div class="col-lg-5 ms-3">
                    <h6 class="mb-lg-0">Display Image in Front <span class="text-danger">*</span></h6>
                </div>
            </div>
        </div>

        <!-- Upload image START -->
        <div v-if="!isTextCompu" class="col-12 mt-5">
            <!-- 메인 이미지  -->
            <content_id-main-image-fileupload-panel-component
                :key="media"
                :file-kind="'image'"
                :category="'blog'" 
                :sub-category="'main'" 
                :content_id="content_id" 
            />
        </div>
        <!-- Upload image END -->

        <div class="d-md-flex justify-content-between align-items-start mt-4">
            <button @click="onChangeStep(2)" class="btn btn-secondary prev-btn mb-2 mb-md-0">Previous</button>
            <!-- <button class="btn btn-light me-auto ms-md-2 mb-2 mb-md-0">Preview Course</button> -->
            <div class="text-md-end">
                <!-- <router-link :to="backToLink" class="btn btn-success mb-2 mb-sm-0">Finish</router-link> -->
                <button @click="sendForm" class="btn btn-success mb-2 mb-sm-0">Finish</button>
            </div>
        </div>            
        
    </div>
    
</div>

</template>

<script>
var filename__ = " [components > companydash > CompanyDashBlogMediaPanelComponent.vue] " ;

import axios from 'axios';

import ContentIdMainImageFileUploadPanelComponent from '../content/ContentIdMainImageFileUploadPanelComponent.vue' ;

export default {
    components: {
        'content_id-main-image-fileupload-panel-component': ContentIdMainImageFileUploadPanelComponent,
    },
    props: ['content_id','blog'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            // step3 
            inputIsText : this.blog ? this.blog.is_fronttext : false , // 텍스트 위주 

           
        }
    },
    created(){
    },
    computed: {
        isTextCompu(){
            return this.inputIsText;
        }, 
    },
    methods: {
        handleError(){
            this.error = null;
        },
		onChangeIsText(){
			this.inputIsText = !this.inputIsText ;
		},        
		onChangeStep(_step){
			this.$emit('changestep', _step) ;
            return ;
		},

        async sendForm(){
            let funcname__ = ' sendForm() '

            let dataForm = { 
                'is_fronttext': this.inputIsText,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/blog/media/update/'+this.content_id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle = "Success" ;
                        this.error = "수정되었습니다.";

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                // this.$emit('changestep', 4) ;
                                this.$router.replace('/companydash/blog/'+this.content_id);

                            }, 500);
                        }                        


                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },



    },
    
}
</script>