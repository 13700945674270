<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Sidebar START -->
    <!-- Sidebar END -->
    
    <!-- Page content START -->
	
	<!-- Top bar START -->
	<!-- Top bar END -->

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

        <!-- Title -->
		<div class="row g-3 align-items-center justify-content-between">
			<div class="col-md-6">
				<h1 class="h3 mb-0">
                    강좌관리 
                    <small> > 
                        <span v-if="category === 'recommend'">추천 강좌</span>
                        <span v-else-if="category === 'getstarted'">Get Started</span>
                        <span v-else-if="category === 'degree'">Degree</span>
                        <span v-else-if="category === 'certificate'">Certificate</span>
                    </small>
                </h1>
			</div>
            <div class="col-md-6 text-end">
                <router-link :to="createLink" class="btn btn-primary">카테고리 생성</router-link>
            </div>
		</div>

        <!-- All review table START -->
        <div class="card card-body bg-transparent pb-0 border mb-4">

            <!-- Table START -->            
            <div class="table-responsive border-0">
                <table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
                    <!-- Table head -->
                    <thead>
                        <tr>
                            <th scope="col" class="border-0 rounded-start">#</th>
                            <th v-if="category === 'certificate'" scope="col" class="border-0">Image</th>
                            <th scope="col" class="border-0">Title</th>
                            <th scope="col" class="border-0">Action</th>
                            <th scope="col" class="border-0">보임/숨김</th>
                            <th scope="col" class="border-0">Up/Down</th>
                            <th scope="col" class="border-0 rounded-end">Update</th>
                        </tr>
                    </thead>

                    <!-- Table body START -->
                    <tbody>

                        <!-- Table row -->
                        <admin-course-recommend-category-list-component v-for="item in categoryList"
                            :key = "item.id"
                            :id ="item.id"
                            :category="category"
                            :title ="item.title"
                            :subtitle ="item.subtitle"
                            :image="item.image"
                            :is_display="item.is_display"
                            :seq ="item.seq"
                            :min ="item.min"
                            :max ="item.max"
                            :recommend_count="item.recommend_count"
                            @changeUpSeq='sendChageUpSeqForm(item.id)'
                            @changeDownSeq='sendChageDownSeqForm(item.id)'                                                    

                        />

                    </tbody>
                    <!-- Table body END -->
                </table>
            </div>
            <!-- Table END -->

        </div>
        <!-- All review table END -->
    </div>    
</div>
</template>

<script>
// watch__
var filename__="[pages > admin > courserecommend > AdminCourseRecommendCategoryList.vue] "

import axios from 'axios';

import AdminCourseRecommendCategoryListComponent from '../../../components/admin/courserecommend/AdminCourseRecommendCategoryListComponent.vue';

export default {
    components: {
        "admin-course-recommend-category-list-component" : AdminCourseRecommendCategoryListComponent,
    },
    props: ['category'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            categoryList: [], 
            page:1,
            current_path:"/admin/course/recommendcategories/"+this.category,  //current_path
			pagination: null, 

            inputPagePerCount : 50,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',     
			inputCategory : this.category, //처음에 입력해줘야 됨

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    computed: {
        createLink(){
            return '/admin/course/recommendcategory/create/'+this.category ;
        },

    },    
    created(){
		// let funcname__="created()" ;
        // 같은 페이지를 파라미터 달리해서 불러올 때  
        // 참고자료 : https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
        this.$watch(
			() => this.$route.params,
                (toParams, previousParams) => {
					// console.log(filename__+funcname__+" $watch toParams: ", toParams['category']);
					// console.log(filename__+funcname__+ " $watch previousParams: ", previousParams['category']);
					console.log(" $watch previousParams: ", previousParams['category']);

					this.inputCategory = toParams['category'];

					this.getList();
				}
        )        
        this.getList();

    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        

        async getList(){
            let funcname__ ="getList()" ;

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/course/recommendcategory/all/'+this.inputCategory,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response.data ;
                let counter = data.response.counter ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        num: counter--, 
                        id: item.id, 
                        title:item.title,
                        subtitle:item.subtitle,
                        image: item.image,
                        seq:item.seq,
                        min: item.min,
                        max: item.max,
                        is_display : item.is_display,
                        recommend_count : item.recommend_count,
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.categoryList = list;

                // console.log(filename__+''+funcname__+" categoryList:", this.categoryList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;

            }

            this.isLoading = false ;            
        },

        // 순서 변경 : 위로 올리기         
        async sendChageUpSeqForm(fromId){
            let funcname__ ="sendChageUpSeqForm()" ;

            this.isSendLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/course/recommendcategory/upseq/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.status_code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    this.dialogTitle ='Success' ;
                    this.error = '변경에 성공했습니다.';

                    if(this.dialogTitle ==='Success'){
                        setTimeout(() => {
                            this.error = null;
                            this.dialogTitle = null ;
                            this.getList();

                        }, 500);
                    }                        

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;

    
                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="내용 변경 실패" ;
                this.error = err; // this.error = err.msg || 'Failed to authenticate';
            }

            this.isLoading = false ;

        },   
        
        // 순서 변경 : 아래로 내리기         
        async sendChageDownSeqForm(fromId){
            let funcname__ ="sendChageDownSeqForm()" ;

            this.isSendLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/course/recommendcategory/downseq/'+fromId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },

                }).catch( err => {

                    let dataForm = { 
                        category: 'errorlog(api catch)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.isLoading = false ; 

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;  
                    
                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(serverData.data.status_code == 201){
                    // console.log(filename__+''+funcname__+' data:', serverData.data.data);
                    
                    this.dialogTitle ='Success' ;
                    this.error = '변경에 성공했습니다.';

                    if(this.dialogTitle ==='Success'){
                        setTimeout(() => {
                            this.error = null;
                            this.dialogTitle = null ;
                            this.getList();

                        }, 500);
                    }                        

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.error_description;

                }

            } catch(err){
                console.error(filename__+''+funcname__+' err:',err);

                this.dialogTitle ="내용 변경 실패" ;
                this.error = err; // this.error = err.msg || 'Failed to authenticate';
            }

            this.isLoading = false ;
            

        },  

    }
}
</script>

<style scoped>

</style>