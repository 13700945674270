<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <nav class="navbar sidebar navbar-expand-xl navbar-dark bg-dark">

    <!-- Navbar brand for xl START -->
    <div class="d-flex align-items-center">
        <a href="#" @click="moveToHome" class="navbar-brand" >
            <img class="navbar-brand-item" src="/assets/workerscafe/logo_admin.svg" alt="">
        </a>
    </div>
    <!-- Navbar brand for xl END -->

    <div class="offcanvas offcanvas-start flex-row custom-scrollbar h-100" data-bs-backdrop="true" tabindex="-1" id="offcanvasSidebar">
        <div class="offcanvas-body sidebar-content d-flex flex-column bg-dark">

            <!-- Sidebar menu START -->
            <ul class="navbar-nav flex-column" id="navbar-sidebar">

                <li class="nav-item">
                    <!-- <router-link to="/admin" class="nav-link active"><i class="bi bi-house fa-fw me-2"></i>대시보드</router-link> -->
                    <router-link to="/admin" class="nav-link">
                        <i class="bi bi-house fa-fw me-2"></i>대시보드
                    </router-link>
                </li>

                <!-- Title -->
                <li class="nav-item ms-2 my-2">Pages</li>

                <!-- 관리자 설정 -->
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="collapse" href="#adminsetting" role="button" aria-expanded="false" aria-controls="adminsetting">
                        <i class="fas fa-cog fa-fw me-2"></i>관리자 설정
                    </a>
                    <ul class="nav collapse flex-column" id="adminsetting" data-bs-parent="#navbar-sidebar">
                        <li class="nav-item"> 
                            <router-link to="/admin/mainslide" class="nav-link" >- 메인화면 설정</router-link>
                        </li>
                        <li class="nav-item"> 
                            <router-link to="/admin/settings" class="nav-link" >- Website Settings</router-link>
                        </li>
                        <li class="nav-item"> 
                            <router-link to="/admin/setting/socials" class="nav-link" >- Social Settings</router-link>
                        </li>
                        <li class="nav-item"> 
                            <router-link to="/admin/setting/approve/update" class="nav-link" >- 가입즉시 활성화 여부 </router-link>
                        </li>
                        <li class="nav-item"> 
                            <router-link to="/admin/setting/email/update" class="nav-link" >- 이메일 활성화 여부 </router-link>
                        </li>
                        <li class="nav-item"> 
                            <router-link to="/admin/plans" class="nav-link" >- 작업 리스트</router-link>
                        </li>

                    </ul>
                </li>

                <!-- 멤버 -->
                <li class="nav-item">
                    <router-link to="/admin/members" class="nav-link"><i class="fas fa-user fa-fw me-2"></i>가입회원</router-link>
                </li>
                <!-- 업종분류 및 지역 -->
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="collapse" href="#collapsejobs" role="button" aria-expanded="false" aria-controls="collapsejobs">
                        <i class="fas fa-user-tie fa-fw me-2"></i>업종분류 및 지역
                    </a>
                    <ul class="nav collapse flex-column" id="collapsejobs" data-bs-parent="#navbar-sidebar">
                        <li class="nav-item"> 
                            <router-link to="/admin/sector/sectors" class="nav-link" >- 업종분류</router-link>
                        </li>
                        <li class="nav-item"> 
                            <router-link to="/admin/sector/locations" class="nav-link" >- 활동지역구분</router-link>
                        </li>
                    </ul>
                </li>

                <!-- 파트너 -->
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="collapse" href="#collapsecompany" role="button" aria-expanded="false" aria-controls="collapsecompany">
                        <i class="fas fa-user-tie fa-fw me-2"></i>파트너
                    </a>
                    <ul class="nav collapse flex-column" id="collapsecompany" data-bs-parent="#navbar-sidebar">
                        <li class="nav-item"> 
                            <router-link to="/admin/companies" class="nav-link" >- 등록된 파트너</router-link></li>
                        <li class="nav-item"> 
                            <router-link to="/admin/company/requests" class="nav-link" >- 파트너 등록요청
                                <!-- <span v-if="inputRequestUndoneCount > 0" class="badge text-bg-success rounded-circle ms-2">{{inputRequestUndoneCount}}</span> -->
                            </router-link>
                        </li>
                    </ul>
                </li>
                <!-- 스터디룸 -->
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="collapse" href="#collapsepage" role="button" aria-expanded="false" aria-controls="collapsepage">
                        <i class="bi bi-film fa-fw me-2"></i>스터디룸/정보창고
                    </a>
                    <ul class="nav collapse flex-column" id="collapsepage" data-bs-parent="#navbar-sidebar">
                        <li class="nav-item"> <router-link to="/admin/course/categories" class="nav-link" ><i class="bi bi-bookmarks-fill me-2"></i> 카테고리</router-link></li>
                        <li class="nav-item"> <router-link to="/admin/courses" class="nav-link" ><i class="bi bi-film me-2"></i> 스터디룸 모든 리스트</router-link></li>
                        <li class="nav-item"> 
                            <router-link to="/admin/course/reviews" class="nav-link" ><i class="far fa-comment-dots fa-fw me-2"></i>스터디룸 Reviews</router-link>
                        </li>
                        <li class="nav-item"> 
                            <router-link to="/admin/course/comments" class="nav-link" ><i class="far fa-comments fa-fw me-2"></i> 스터디룸 Comments</router-link>
                        </li>
                        <li class="nav-item"> 
                            <router-link to="/admin/blogs" class="nav-link" ><i class="far fa-edit fa-fw me-2"></i>정보창고 모든 리스트</router-link>
                        </li>
                        
                    </ul>
                </li>
                <!-- 플랫폼별 추천강좌  -->
                <li class="nav-item">
                    <router-link to="/admin/course/recommendcategories/recommend" class="nav-link">
                        <i class="far fa-folder-open fa-fw me-2"></i>플랫폼 추천강좌
                    </router-link>
                </li>
                <!-- 
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="collapse" href="#mainpage" role="button" aria-expanded="false" aria-controls="mainpage">
                        <i class="far fa-folder-open fa-fw me-2"></i>플랫폼 추천강좌
                    </a>
                    <ul class="nav collapse flex-column" id="mainpage" data-bs-parent="#navbar-sidebar">
                        <li class="nav-item"> <router-link to="/admin/course/recommendcategories/recommend" class="nav-link" >추천 강좌</router-link></li>
                        <li class="nav-item"> <router-link to="/admin/course/recommendcategories/getstarted" class="nav-link" >Get Started</router-link></li>
                        <li class="nav-item"> <router-link to="/admin/course/recommendcategories/degree" class="nav-link" >Degree</router-link></li>
                        <li class="nav-item"> <router-link to="/admin/course/recommendcategories/certificate" class="nav-link" >Certificate</router-link></li>
                    </ul>
                </li> 
                -->


                <!-- Menu item 4 -->
                <!-- 
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="collapse" href="#collapsestudents" role="button" aria-expanded="false" aria-controls="collapsestudents">
                        <i class="fas fa-user-graduate fa-fw me-2"></i>수강생
                    </a>
                    <ul class="nav collapse flex-column" id="collapsestudents" data-bs-parent="#navbar-sidebar">
                        <li class="nav-item">
                            <router-link to="/admin/companies" class="nav-link" >수강생 목록</router-link></li>
                        <li class="nav-item">
                            <router-link to="/admin/company/request" class="nav-link" >수강생 등록 요청
                                <span class="badge text-bg-success rounded-circle ms-2">2</span>
                            </router-link>
                        </li>
                    </ul>
                </li> 
                -->

                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="collapse" href="#collapsefaq" role="button" aria-expanded="false" aria-controls="collapsefaq">
                        <i class="bi bi-question-circle me-2"></i>자주하는 질문
                    </a>
                    <ul class="nav collapse flex-column" id="collapsefaq" data-bs-parent="#navbar-sidebar">
                        <li class="nav-item"> 
                            <router-link to="/admin/faqs" class="nav-link" >- 사용자 Faq 요청내역
                                <span v-if="inputFaqUndoneCount > 0" class="badge text-bg-success rounded-circle ms-2">{{inputFaqUndoneCount}}</span>
                            </router-link>
                        </li>
                        <li class="nav-item"> 
                            <router-link to="/admin/faqfrequents" class="nav-link" >- 자주하는 Faq 관리</router-link>
                        </li>
                    </ul>
                </li>

                <li class="nav-item">
                    <router-link to="/admin/contactuses" class="nav-link" ><i class="bi bi-envelope me-2"></i>Contact Us</router-link>
                </li>
                
                <li class="nav-item">
                    <router-link to="/admin/notifications" class="nav-link" ><i class="bi bi-bell fa-fw me-2"></i>Notification</router-link>
                </li> 
                
                <!-- 
                <li class="nav-item">
                    <router-link to="/admin/forgotpws" class="nav-link" ><i class="fa fa-key fa-fw me-2"></i>비밀번호 재설정 요청</router-link>
                </li> 
                -->

                <!-- 관리자 페이지 설정 -->
                <!-- <li class="nav-item">
                    <router-link to="/admin/setting" class="nav-link" ><i class="fas fa-user-cog fa-fw me-2"></i>관리자 페이지 설정</router-link>
                </li> -->


            </ul>
            <!-- Sidebar menu end -->

            <!-- Sidebar footer START -->
            <div class="px-3 mt-auto pt-3">
                <div class="d-flex align-items-center justify-content-between text-primary-hover">
                        <!-- <router-link to="/admin/setting" class="h5 mb-0 text-body"  data-bs-toggle="tooltip" data-bs-placement="top" title="Settings">
                            <i class="bi bi-gear-fill"></i>
                        </router-link> -->
                        <a href="#" @click="moveToHome" class="h5 mb-0 text-body"  data-bs-toggle="tooltip" data-bs-placement="top" title="Home">
                            <i class="fas fa-house-user"></i>
                        </a>
                        <router-link to="/admin/errorlogs" class="h5 mb-0 text-body"  data-bs-toggle="tooltip" data-bs-placement="top" title="Error">
                            <i class="bi bi-bug-fill"></i>
                        </router-link>
                        <a href="#" @click="logout" class="h5 mb-0 text-body" data-bs-toggle="tooltip" data-bs-placement="top" title="Sign out">
                            <i class="bi bi-power"></i>
                        </a>
                </div>
            </div>
            <!-- Sidebar footer END -->

        </div>
    </div>
</nav>

</div>
</template>

<script>
var filename__ = "[components > layout> AdminLeft.vue]" ;
import axios from 'axios';

export default {
    components: {
    },
    props: [],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            inputRequestUndoneCount : 0,
            inputFaqUndoneCount : 0,

        }
    },
    computed: {
		isLoggedIn(){
			return this.$store.getters['auth/isAuthenticated'];
		},
		isAdmin(){
			// console.log("[TheHeader.vue] computed : isAdmin():", this.$store.getters['auth/isAdmin'])
			return this.$store.getters['auth/isAdmin'];
		},
		name(){
			// console.log("[AdminTopHeader.vue] computed : name():", this.$store.getters['auth/name'])
			return this.$store.getters['auth/name'];
		},
		email(){
			return this.$store.getters['auth/email'];
		},
		photo(){
			// console.log("[AdminTopHeader.vue] computed : photo():", this.$store.getters['auth/photo'])
			return this.$store.getters['auth/photo'];
		},
    },
    created(){
        // console.log(filename__+"created() : getRequestUndoneCount() 처리해야 함 ")
        // console.log(filename__+"created() : getFaqUndoneCount() 처리해야 함 ")
        // this.getRequestUndoneCount(); //강사등록 후 처리못한 것 
        // this.getFaqUndoneCount(); //Faq 후 처리못한 것 
    },
    mounted() {

    },
    methods: {
		logout(){
			this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
			this.$router.replace('/');
		},
        moveToHome(){
            this.$store.dispatch('auth/outPageAdmin'); //==> store/modules/auth/actions.js
            this.$router.replace('/');
        },
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        // 강사요청에서 아직 승인완료나 거절하지 않은 갯수 
        async getRequestUndoneCount(){
            let funcname__ ="getRequestUndoneCount() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/instructor/company/request/undone/count',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.code == 200 ){

                    // console.log(filename__+''+funcname__+'  response:', data.response);

                    this.inputRequestUndoneCount = data.response ;

                } else {
                    console.log(filename__+''+funcname__+' err:');

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
                    // alert('error')
                }
            }

            this.isLoading = false ;     
        },

        // 질문후 아직 처리완료 안된 갯수 
        async getFaqUndoneCount(){
            let funcname__ ="getFaqUndoneCount() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/faq/undone/count',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.code == 200 ){

                    // console.log(filename__+''+funcname__+' response:', data.response);

                    this.inputFaqUndoneCount = data.response ;

                } else {
                    console.log(filename__+''+funcname__+' err:');

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
                }
            }

            this.isLoading = false ;     
        },


    },
}
</script>

<style scoped>

</style>
