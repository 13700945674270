<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Popup modal for add topic START -->
    <!-- create topic : Youtube  -->
    <teleport to="body" :id="idModalYoutubeCompu">
        <div class="modal fade" :id="idAddYoutubeCompu" tabindex="-1" aria-labelledby="addTopicLabel" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content" style="width:900px">
                    <div class="modal-header bg-dark">
                        <h5 class="modal-title text-white" :id="idAddYoutubeCompu">Add Youtube Topic </h5>
                        <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                    </div>
                    <form @submit.prevent="sendTopicCreateForm">
                        <div class="modal-body">
                            <div class="row text-start g-3">

                                <div class="col-12 mt-3">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIsDisplay" @click="onChangeIsDisplay">
                                        <label v-if="inputIsDisplay" class="form-check-label" for="onoffPublic">외부 공개</label>
                                        <label v-else class="form-check-label" for="onoffPublic">외부 비공개</label>
                                    </div>
                                </div>

                                <!-- Topic name -->
                                <div class="col-12 mt-3">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIs_price" @click="onChangePrice">
                                        <label v-if="inputIs_price" class="form-check-label" for="onoffPublic">수강신청시 가능 </label>
                                        <label v-else class="form-check-label" for="onoffPublic">무료보기</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="form-label">Topic name</label>
                                    <input class="form-control" type="text" placeholder="Enter topic name" v-model.trim="inputTopicTitle">
                                </div>
                                <div class="col-12 mt-3">
                                    <label class="form-label">Topic 수강시간(minutes)</label>
                                    <input class="form-control" type="number" placeholder="Enter topic name" v-model="inputTopictime">
                                </div>

                                <!-- Video link -->
                                <div class="col-12 mt-3">
                                    <div class="col-md-12">
                                        <label class="form-label">Youtube 링크를 복사해서 입력해주세요</label>
                                        <input class="form-control" type="text" placeholder="https://www.youtube.com/watch?v=nPIYUS2UqUg"  v-model.trim="inputYoutubeTotalstr" style="width:100%;" @input="onChangeTotal($event)">
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">ex) <small>https://www.youtube.com/watch?v=</small>
                                            <b>nPIYUS2UqUg</b>
                                            <small>&t=1s</small>
                                            &nbsp;&nbsp;&nbsp;
                                            <b>(굵은부분만)</b>
                                        </label>
                                        <input class="form-control" type="text" placeholder="nPIYUS2UqUg"  v-model.trim="inputYoutubestr" style="width:200px;">
                                    </div>
                                    <!-- Video link -->
                                    <div class="col-md-12">
                                        <label class="form-label">별도로 입력되는 링크(하단 link 포맷 참조)</label>
                                        <input class="form-control" type="text" placeholder="Enter Video link" v-model.trim="inputUrl">
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">link : {{urlCompu}}</label>
                                    </div>
                                    <h5 v-if="urlCompu" class="mt-4">Video preview</h5>
                                    <div v-if="urlCompu" class="position-relative">
                                        <div class="ratio ratio-16x9">
                                            <iframe 
                                                :src="urlCompu" 
                                                title="YouTube video player" 
                                                frameborder="0" 
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                allowfullscreen
                                            ></iframe>                                                                                                    
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger-soft my-0" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-success my-0" data-bs-dismiss="modal">Create Topic</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </teleport>

    <!-- Text modal  -->
    <teleport to="body" :id="idModalTextCompu">
        <div class="modal fade" :id="idAddTextCompu" tabindex="-1" aria-labelledby="addTopicLabel" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content" style="width:900px">
                    <div class="modal-header bg-dark">
                        <h5 class="modal-title text-white" :id="idAddTextCompu">Add Text Topic</h5>
                        <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                    </div>
                    <form @submit.prevent="sendTopicUpdateForm">
                        <div class="modal-body">
                            <div class="row text-start g-3">

                                <div class="col-12 mt-3">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIsDisplay" @click="onChangeIsDisplay">
                                        <label v-if="inputIsDisplay" class="form-check-label" for="onoffPublic">외부 공개</label>
                                        <label v-else class="form-check-label" for="onoffPublic">외부 비공개</label>
                                    </div>
                                </div>

                                <!-- Topic name -->
                                <div class="col-12 mt-3">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIs_price" @click="onChangePrice">
                                        <label v-if="inputIs_price" class="form-check-label" for="onoffPublic">수강신청시 가능 </label>
                                        <label v-else class="form-check-label" for="onoffPublic">무료보기</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="form-label">Topic name</label>
                                    <input class="form-control" type="text" placeholder="Enter topic name" v-model.trim="inputTopicTitle">
                                </div>
                                <div class="col-12 mt-3">
                                    <label class="form-label">Topic 수강시간(minutes)</label>
                                    <input class="form-control" type="number" placeholder="Enter topic name" v-model="inputTopictime">
                                </div>

                                <!-- Text -->
                                <div class="col-12 mt-3">
                                    <label class="form-label">
                                        Content (<a href="https://gist.github.com/ihoneymon/652be052a0727ad59601" target="_blank">마크다운 설명 링크</a>)
                                    </label>
                                    <div class="editor">
                                        <textarea class="input" v-model.trim="inputMarkdown" ></textarea>
                                        <div class="output" v-html="outputMarkdownCompu"></div>
                                    </div>

                                    <div class="col-md-12 mt-2"  >                             
                                        <label class="position-relative me-4" for="uploadfile-1">
                                            위의 Markdown와 연동된 이미지입니다.이미지 클릭(경로 복사) 
                                        </label>                    
                                    </div>
                                    <div v-if="inputIdCompu" class="col-md-12 mt-2"  >  
                                        <!-- 이미지 업로드  -->
                                        <content_id-fileupload-panel-component 
                                            :key="uuididCompu+'image'"
                                            :content_id="inputIdCompu" 
                                            :is-multi-file='true'
                                            :file-kind="'image'"
                                            :category="'topic'" 
                                            :sub-category="'description'" 
                                            
                                        />
                                    </div>
                                    <!-- 파일 업로드  -->
                                    <!-- <div v-if="inputIdCompu" class="col-md-12 mt-2"  >                                          
                                        <content_id-fileupload-panel-component 
                                            :key="uuididCompu+'file'"
                                            :content_id="inputIdCompu" 
                                            :is-multi-file='true'
                                            :file-kind="'file'"
                                            :category="'topic'" 
                                            :sub-category="'description'" 
                                        />
                                    </div> -->

                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="removeTopicForm" class="btn btn-danger-soft my-0" data-bs-dismiss="modal">생성 취소</button>
                            <button type="submit" class="btn btn-success my-0" data-bs-dismiss="modal">Create Text Topic</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </teleport>

    <!-- Image 토픽 생성 버튼      -->
    <teleport to="body" :id="idModalImageCompu">
        <div class="modal fade" :id="idAddImageCompu" tabindex="-1" aria-labelledby="addTopicLabel" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content" style="width:900px">
                    <div class="modal-header bg-dark">
                        <h5 class="modal-title text-white" :id="idAddImageCompu">Add Image Topic</h5>
                        <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                    </div>
                    <form @submit.prevent="sendTopicUpdateForm">
                        <div class="modal-body">
                            <div class="row text-start g-3">

                                <div class="col-12 mt-3">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIsDisplay" @click="onChangeIsDisplay">
                                        <label v-if="inputIsDisplay" class="form-check-label" for="onoffPublic">외부 공개</label>
                                        <label v-else class="form-check-label" for="onoffPublic">외부 비공개</label>
                                    </div>
                                </div>

                                <!-- Topic name -->
                                <div class="col-12 mt-3">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIs_price" @click="onChangePrice">
                                        <label v-if="inputIs_price" class="form-check-label" for="onoffPublic">수강신청시 가능 </label>
                                        <label v-else class="form-check-label" for="onoffPublic">무료보기</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="form-label">Topic name</label>
                                    <input class="form-control" type="text" placeholder="Enter topic name" v-model.trim="inputTopicTitle">
                                </div>
                                <div class="col-12 mt-3">
                                    <label class="form-label">Topic 수강시간(minutes)</label>
                                    <input class="form-control" type="number" placeholder="Enter topic name" v-model="inputTopictime">
                                </div>

                                <!-- Image -->
                                <div v-if="inputIdCompu" class="col-12 mt-3">
                                    <content_id-main-image-fileupload-panel-component
                                        :key="inputIdCompu+'main'"
                                        :file-kind="'image'"
                                        :category="'topic'" 
                                        :sub-category="'main'" 
                                        :width="300" 
                                        :content_id="inputIdCompu" 
                                    />
                                </div>


                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="removeTopicForm" class="btn btn-danger-soft my-0" data-bs-dismiss="modal">생성 취소</button>
                            <button type="submit" class="btn btn-success my-0" data-bs-dismiss="modal">Create Image Topic</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </teleport>

    <!-- Cardnews modal  -->
    <teleport to="body" :id="idModalCardNewsCompu">
        <div class="modal fade" :id="idAddCardNewsCompu" tabindex="-1" aria-labelledby="addTopicLabel" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content" style="width:900px">
                    <div class="modal-header bg-dark">
                        <h5 class="modal-title text-white" :id="idAddCardNewsCompu">Add Cardnews Topic</h5>
                        <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close" @click="removeCardnewsTopicForm"><i class="bi bi-x-lg"></i></button>
                    </div>
                    <form @submit.prevent="sendTopicCardNewsUpdateForm">
                        <div class="modal-body">
                            <div class="row text-start g-3">

                                <div class="col-12 mt-3">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIsDisplay" @click="onChangeIsDisplay">
                                        <label v-if="inputIsDisplay" class="form-check-label" for="onoffPublic">외부 공개</label>
                                        <label v-else class="form-check-label" for="onoffPublic">외부 비공개</label>
                                    </div>
                                </div>

                                <!-- Topic name -->
                                <div class="col-12 mt-3">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIs_price" @click="onChangePrice">
                                        <label v-if="inputIs_price" class="form-check-label" for="onoffPublic">수강신청시 가능 </label>
                                        <label v-else class="form-check-label" for="onoffPublic">무료보기</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="form-label">Topic name</label>
                                    <input class="form-control" type="text" placeholder="Enter topic name" v-model.trim="inputTopicTitle">
                                </div>
                                <div class="col-12 mt-3">
                                    <label class="form-label">Topic 수강시간(minutes)</label>
                                    <input class="form-control" type="number" placeholder="Enter topic name" v-model="inputTopictime">
                                </div>

                                <!-- Text -->
                                <div v-if="checkInputCardnewsTopicIdCompu" class="col-12 mt-3">  
                                    <!-- 카드뉴스 이미지 업로드  -->
                                    <content-cardnews-fileupload-panel-component 
                                        :key="createCardnewsUuidid"
                                        :group_id="content_id"
                                        :parent_id="inputCardnewsTopicIdCompu"
                                        :company_id="company_id"
                                        :is-multi-file='true'
                                        :file-kind="'image'"
                                        :category="'topic'" 
                                        :sub-category="'cardnews'" 
                                    />
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="removeCardnewsTopicForm" class="btn btn-danger-soft my-0" data-bs-dismiss="modal">생성 취소</button>
                            <button type="submit" class="btn btn-success my-0" data-bs-dismiss="modal">Create Cardnews</button>                            
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </teleport>

    <!-- youtube 토픽 생성 버튼  -->
    <button 
        type="button" 
        class="btn btn-sm btn-danger-soft mb-0" 
        data-bs-toggle="modal" 
        :data-bs-target="'#'+idAddYoutubeCompu"
        @click="createUuidid(2)"
        ><i class="bi bi-plus-circle me-2"></i>Youtube</button>

    <!-- Text 토픽 생성 버튼  -->
    <button 
        type="button" 
        class="btn btn-sm btn-primary-soft mb-0 ms-2" 
        data-bs-toggle="modal" 
        :data-bs-target="'#'+idAddTextCompu"
        @click="preCreateUuidid(4)"
        ><i class="bi bi-plus-circle me-2"></i>Text</button>

    <!-- Image 토픽 생성 버튼  -->
    <button 
        type="button" 
        class="btn btn-sm btn-success-soft mb-0 ms-2" 
        data-bs-toggle="modal" 
        :data-bs-target="'#'+idAddImageCompu"
        @click="preCreateUuidid(1)"
        ><i class="bi bi-plus-circle me-2"></i>Image</button>

    <!-- 카드뉴스 생성 버튼  -->
    <button 
        type="button" 
        class="btn btn-sm btn-orange-soft mb-0 ms-2" 
        data-bs-toggle="modal" 
        :data-bs-target="'#'+idAddCardNewsCompu" 
        @click="sendTopicPreCardNewsCreateForm"
        ><i class="bi bi-plus-circle me-2"></i>Card News</button>

    <!-- 새로고침 버튼  -->
    <button 
        type="button" 
        class="btn btn-sm btn-primary-soft mb-0 ms-2" 
        @click="curriculumlist"
        ><i class="bi bi-arrow-repeat"></i></button>

</div>    
</template>

<script>
// v-for__string__
var filename__ = "[components > companydash > CompanyDashCourseTopicCreateFormComponent.vue] " ;

import axios from 'axios';

import { marked } from 'marked';         // yarn add marked  https://marked.js.org/
import { v4 } from 'uuid';  // 참고자료 uuid__ : https://jane-aeiou.tistory.com/59


import ContentIdFileUploadPanelComponent from '../content/ContentIdFileUploadPanelComponent.vue' ;
import ContentIdMainImageFileUploadPanelComponent from '../content/ContentIdMainImageFileUploadPanelComponent.vue' ;
import ContentCardnewsFileUploadPanelComponent from '../content/ContentCardnewsFileUploadPanelComponent.vue';

export default {
    components: {
        'content_id-fileupload-panel-component': ContentIdFileUploadPanelComponent,
        'content_id-main-image-fileupload-panel-component': ContentIdMainImageFileUploadPanelComponent,
        'content-cardnews-fileupload-panel-component': ContentCardnewsFileUploadPanelComponent,
    },
    props: [ 'content_id', 'id','company_id'],
    inject:['curriculumlist'], // CompnayDashCourseCurriculumPanelComponent.vue 에서 설정
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            componentKey: 0, 

            inputKind : 5,  // 1: 이미지, 2: 영상 링크, 3: 실제 영상 업로드, 4: text, 5: card news
            inputUuidid: null, 
            inputId : null, 
            inputInitialize : false,
            
            inputCardnewsTopicId : null, 
            inputTopicTitle : 'topic',
            inputIsDisplay : true,

            
            inputUrl : null,
            inputYoutubeTotalstr: null,
            inputYoutubestr: null,
            inputIs_price: false,
            inputTopictime : 0,

            inputDescription: null,
            inputDescriptionCategory :'markdown',

            inputMarkdown : 
            `
### 새로운 글 작성
---

1. 첫번째
2. 두번째
3. 세번째

링크 : [Google](https://google.com, "google link")

* 외부링크 : <https://google.com/>
`,   
            inputMarkdownInit : 
            `
### 새로운 글 작성
---

1. 첫번째
2. 두번째
3. 세번째

링크 : [Google](https://google.com, "google link")

* 외부링크 : <https://google.com/>
`,   

        }
    },
    created(){
        console.log(filename__+" created() company_id:", this.company_id);  //~~log
        this.createUuidid() 
    },  
    computed: {
        createCardnewsLink(){
            // 현재는 바로 생성함 
            return '/companydash/course/cardnews/create/'+this.content_id+'/'+this.id
        },
        outputMarkdownCompu(){
            return marked(this.inputMarkdown);
        },			
        idModalYoutubeCompu(){
            return 'modalYoutube'+this.inputUuidid ;
        },
        idModalTextCompu(){
            return 'modalText'+this.inputUuidid ;
        },
        idModalImageCompu(){
            return 'modalImage'+this.inputUuidid ;
        },
        idModalCardNewsCompu(){
            return 'modalCardnewsCard'+this.inputUuidid ;
        },

        idAddYoutubeCompu(){
            return 'addYoutubeModal'+this.inputUuidid
        },
        idAddTextCompu(){
            return 'addTextModal'+this.inputUuidid
        },
        idAddImageCompu(){
            return 'addImageModal'+this.inputUuidid
        },
        idAddCardNewsCompu(){
            return 'addCardNewsModal'+this.inputUuidid
        },
        nameCompu(){
            return 'name'+this.inputUuidid ;
        },
        uuididCompu(){
            return this.inputUuidid ;
        },
        inputIdCompu(){
            return this.inputId ;
        },
        checkInputCardnewsTopicIdCompu(){            
            if( this.inputCardnewsTopicId === '' || this.inputCardnewsTopicId === undefined || this.inputCardnewsTopicId === null ) {                
                console.log(filename__+' checkInputCardnewsTopicIdCompu() false:', this.inputCardnewsTopicId ) ;
                return false;
            } else {
                console.log(filename__+' checkInputCardnewsTopicIdCompu() true:', this.inputCardnewsTopicId ) ;
                return true ;
            }
        },
        inputCardnewsTopicIdCompu(){
            return this.inputCardnewsTopicId;
        },
        inputInitializeCompu(){
            return this.inputInitialize ;
        },
        kindCompu(){
            console.log(filename__+' kindCompu() inputKind:', this.inputKind ) ;
            return this.inputKind ;
        },      
        urlCompu(){
            if(this.inputYoutubestr){
                return 'https://www.youtube.com/embed/'+this.inputYoutubestr+'?autohide=0&showinfo=0&controls=0' ;
            } else {
                return this.inputUrl;
            }            
        },
        componentKeyCompu(){
            return this.componentKey ;
        }
    },
    methods: {
        handleError(){
            this.error = null;
        },
        createUuidid(_kind){
            this.inputKind = _kind ; 

            // uuid 생성 
            const generate_uuid = () => {
                const tokens = v4().split('-')
                return tokens[2] + tokens[1] + tokens[0] + tokens[3] + tokens[4];
            }
            this.inputUuidid =  generate_uuid() ;
            console.log(filename__+' createUuidid() inputUuidid:', this.inputUuidid);
        },
        preCreateUuidid(_kind){
            this.inputKind = _kind ; 

            // uuid 생성 
            const generate_uuid = () => {
                const tokens = v4().split('-')
                return tokens[2] + tokens[1] + tokens[0] + tokens[3] + tokens[4];
            }
            this.inputUuidid =  generate_uuid() ;
            console.log(filename__+' preCreateUuidid() inputUuidid:', this.inputUuidid);
            this.sendTopicPreCreateForm() ;  //미리 토픽 생성하기 
        },
        createCardnewsUuidid(){
            // uuid 생성 
            const generate_uuid = () => {
                const tokens = v4().split('-')
                return tokens[2] + tokens[1] + tokens[0] + tokens[3] + tokens[4];
            }
            return generate_uuid() ;
        },
        onChangeTotal(event){
            if(event.target.value.length > 0){
                const idx = event.target.value.indexOf('com/watch?v=');
                // console.log(filename__+" onChangeTotal() idx:", idx);  //~~log    
                const first_idx = idx + 12 ;  
                const last_idx = event.target.value.length-1 ;

                // console.log(filename__+" onChangeTotal() first_idx:", first_idx);  //~~log    
                // console.log(filename__+" onChangeTotal() last_idx:", last_idx);  //~~log    
                // console.log(filename__+" onChangeTotal() 문자열:", event.target.value.substr(first_idx,last_idx));  //~~log    
                
                this.inputYoutubestr = event.target.value.substr(first_idx,last_idx) ;
            }
        },
        onChangePrice(){
            this.inputIs_price = !this.inputIs_price ;
        },
        onChangeIsDisplay(){
            this.inputIsDisplay = !this.inputIsDisplay ;
        },
        onChangeButton(_kind){                  
            this.inputKind = parseInt(_kind)  ;
        },
        // youtube topic 생성
        async sendTopicCreateForm(){
            let funcname__ ="sendTopicCreateForm()" ;

            if(this.inputYoutubestr){
                this.inputUrl = 'https://www.youtube.com/embed/'+this.inputYoutubestr+'?autohide=0&showinfo=0&controls=0'
            }

            let dataForm = { 
                'group_id': this.content_id,
                'parent_id': this.id,

                'kind' : this.inputKind,
                'title' : this.inputTopicTitle,
                'is_display' : this.inputIsDisplay,

                'topictime': this.inputTopictime,
                'is_price': this.inputIs_price,

                'url' : this.inputUrl,
                'youtubestr': this.inputYoutubestr,

                'uuidid': this.inputUuidid,  // 수정하면서 frontend popup 구분용으로만 사용하게 됨 
                'description': this.inputMarkdown,
                'description_category': this.inputDescriptionCategory,

            } ;  

            if( this.inputTopicTitle === '' || this.inputTopicTitle === undefined || this.inputTopicTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="타이틀을 입력해주세요.";
                return ;
            }
            // 1: 이미지 2: 영상 링크 3: 실제 영상 업로드 4: text 5: image+text 
            // 동영상 topic 
            if( this.inputKind === 2 && (this.inputUrl === '' || this.inputUrl === undefined || this.inputUrl === null ) ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="동영상 링크를 입력해주세요.";
                return ;
            }
            // 텍스트 topic
            if( this.inputKind === 4 && (this.inputMarkdown === '' || this.inputMarkdown === undefined || this.inputMarkdown === null ) ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="텍스트를 입력해주세요.";
                return ;
            }

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/content/course/curriculum/topic/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        console.log(filename__+''+funcname__+" Success");

                        this.inputKind = 2 ;

                        this.inputTopicTitle = 'topic' ;
                        this.inputIsDisplay = true ;

                        this.inputIs_price = false ;
                        this.inputTopictime = 0 ;

                        this.inputDescription = null ;
                        this.inputMarkdown = this.inputMarkdownInit

                        this.inputUrl = null ;
                        this.inputYoutubestr = null ;
                        this.inputYoutubeTotalstr = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                this.curriculumlist(); //inject

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        // text topic, image topic 미리 만들어둠 
        async sendTopicPreCreateForm(){
            let funcname__ ="sendTopicPreCreateForm()" ;

            if(this.inputKind === 1){
                this.inputTopicTitle ='이미지 토픽 ' ;
            } else if(this.inputKind === 4){
                this.inputTopicTitle ='텍스트 토픽 ' ;
            }
            
            let dataForm = { 
                'group_id': this.content_id,
                'parent_id': this.id,

                'kind' : this.inputKind,
                'title' : this.inputTopicTitle,
                'is_display' : this.inputIsDisplay,

                'topictime': this.inputTopictime,
                'is_price': this.inputIs_price,

                'url' : this.inputUrl,
                'youtubestr': this.inputYoutubestr,

                'uuidid': this.inputUuidid,
                'description': this.inputMarkdownInit,
                'description_category': this.inputDescriptionCategory,

            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/content/course/curriculum/topic/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        // console.log(filename__+''+funcname__+" Success");
                        this.inputId = serverData.data.id ;

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                
                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        // sendTopicPreCreateForm()에서 생성한 값 저장 
        async sendTopicUpdateForm(){
            let funcname__ ="sendTopicUpdateForm()" ;
            

            let dataForm = { 
                'group_id': this.content_id,  // topic 시간 및 갯수 업데이트 
                'kind' : this.inputKind,
                'title' : this.inputTopicTitle,
                'is_price': this.inputIs_price,
                'topictime': this.inputTopictime,
                'is_display' : this.inputIsDisplay,
                'url' : this.inputUrl,
                'youtubestr': this.inputYoutubestr,
                'description': this.inputMarkdown,
            } ;  

            if( this.inputTopicTitle === '' || this.inputTopicTitle === undefined || this.inputTopicTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="타이틀을 입력해주세요.";
                return ;
            }
            // 1: 이미지 2: 영상 링크 3: 실제 영상 업로드 4: text 5: image+text 
            // 동영상 topic 
            if( this.inputKindUpdate === 2 && ( this.inputUrlUpdate === '' || this.inputUrlUpdate === undefined || this.inputUrlUpdate === null) ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="동영상 링크를 입력해주세요.";
                return ;
            }
            // 텍스트 topic
            if( this.inputKind === 4 && (this.inputMarkdown === '' || this.inputMarkdown === undefined || this.inputMarkdown === null ) ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="텍스트를 입력해주세요.";
                return ;
            }

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/curriculum/topic/update/'+this.inputId,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.inputTopicTitle = 'topic' ;
                        this.inputDescription = null ;
                        this.inputUrlUpdate = null ;
                        this.inputYoutubestr = null ;
                        this.inputIs_price = false ;
                        this.inputTopictime = 0 ;
                        
                        this.dialogTitle ='Success' ;
                        this.error = '생성에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            // console.log(filename__+''+funcname__+' Success');
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.curriculumlist();  // inject

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },


        // cardnews topic 을 생성하고, 팝업창 띄움 
        async sendTopicPreCardNewsCreateForm(){
            let funcname__ ="sendTopicPreCardNewsCreateForm()" ;
            
            this.inputUuidid = this.createCardnewsUuidid()
            this.inputKind = 5 ;
            this.inputTopicTitle ='카드 뉴스' ;
            this.inputDescriptionCategory = null ;

            let dataForm = { 
                'group_id': this.content_id,
                'parent_id': this.id,

                'kind' : this.inputKind,
                'title' : this.inputTopicTitle,
                'is_display' : this.inputIsDisplay,

                'topictime': this.inputTopictime,
                'is_price': this.inputIs_price,

                'url' : this.inputUrl,
                'youtubestr': this.inputYoutubestr,

                'uuidid': this.inputUuidid,
                'description': this.inputDescription,
                'description_category': this.inputDescriptionCategory,

            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputTopicTitle === '' || this.inputTopicTitle === undefined || this.inputTopicTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="타이틀을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: '/api/content/course/curriculum/cardnews/create',
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        console.log(filename__+''+funcname__+" Success");

                        this.inputCardnewsTopicId = serverData.data.id ;  //새롭게 생긴 cardnews 에 대한 topic 업데이트 
                        this.inputId = serverData.data.id ;
                        console.log(filename__+''+funcname__+" inputCardnewsTopicId:",this.inputCardnewsTopicId);

                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                // this.curriculumlist(); //inject : 미리 만들어둔 팝업이기 때문에 refresh 안함 

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        // 미리 만들어둔 cardnews를 이미지만 추가함 
        async sendTopicCardNewsUpdateForm(){
            let funcname__ ="sendTopicCardNewsUpdateForm()" ;
            
            let dataForm = { 
                'group_id' : this.content_id,
                'title' : this.inputTopicTitle,
                'is_display' : this.inputIsDisplay,

                'topictime': this.inputTopictime,
                'is_price': this.inputIs_price,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputTopicTitle === '' || this.inputTopicTitle === undefined || this.inputTopicTitle === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="타이틀을 입력해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/curriculum/topic/update/'+this.inputCardnewsTopicId,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.inputId = serverData.data.id ;
                        this.inputCardnewsTopicId = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                this.curriculumlist(); //inject

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        //생성된 uuidid 기준의 이미지 삭제
        /*
        async removeTopicForm(){
            let funcname__ ="removeTopicForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: `/api/content/file/delete/uuidid/all/${this.inputUuidid}`,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){
                        console.log(filename__+''+funcname__+' data:', data);

                        this.inputKind = 2 ;

                        this.inputTopicTitle = 'topic' ;
                        this.inputIsDisplay = true ;

                        this.inputIs_price = false ;
                        this.inputTopictime = 0 ;

                        this.inputDescription = null ;
                        this.inputMarkdown = this.inputMarkdownInit

                        this.inputUrl = null ;
                        this.inputYoutubestr = null ;
                        this.inputYoutubeTotalstr = null ;
                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;

                    }

                }                    

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="실패" ;
                this.error = error; 
            }
            this.isLoading = false ;

        },
        */
        //생성된 topic id 기준의 이미지 삭제
        async removeTopicForm(){
            let funcname__ ="removeTopicForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/content/course/curriculum/topic/delete/'+this.inputId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '취소했습니다.';     

                        this.inputId = null ;
                        this.inputTopicTitle = null ;
                        this.inputTopictime = 0 ;

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                this.curriculumlist(); //inject : 생성취소에서도 확인하기 위해서 

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },       
        //생성된 카드뉴스 기준의 이미지 삭제
        async removeCardnewsTopicForm(){
            let funcname__ ="removeCardnewsTopicForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: '/api/content/course/curriculum/cardnews/delete/'+this.inputCardnewsTopicId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '카드뉴스를 취소했습니다.';     

                        this.inputCardnewsTopicId = null ;

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                this.curriculumlist(); //inject

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    },
    
}
</script>
<style scoped>
.editor {
  height: 50vh;
  display: flex;
}
.editor_sample {
  height: 50vh;
  display: flex;
}

.input,
.output {
  overflow: auto;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.input {
  border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 20px;
}

code {
  color: #f66;
}
</style>