// var filename__="[store/dashboard/mutation.js] " ;

export default {
    setMypage(state, payload){
        state.dashboard = payload.dashboard;
    },
    setCompanyDashboard(state, payload){
        // console.log('[store/dashboard/mutations.js] setInstructorDashboard() payload:' , payload);
        
        state.company_dashboard = payload.company_dashboard;

    },
    
    setIsCourseWorkerDashboard(state, payload){
        // let funcname__ =" setIsCourseInstructorDashboard() " ;
        // console.log(filename__+funcname__+' payload:' , payload);

        state.is_courseworker_dashboard = payload.is_courseworker_dashboard;

    }
}