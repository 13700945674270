export default {

    is_loading(state){
        return state.is_loading ;
    },
    name(state){
        return state.name;
    },
    copyright(state){
        return state.copyright;
    },
    email(state){
        return state.email;
    },
    description(state){
        return state.description;
    },
    phone(state){
        return state.phone;
    },
    worktime(state){
        return state.worktime;
    },
    supportemail(state){
        return state.supportemail;
    },
    contactaddr(state){
        return state.contactaddr;
    },
    is_active(state){
        return state.is_active;
    },
}