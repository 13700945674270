<template>
    <div>

    <!-- Sidebar START -->
    <!-- Sidebar END -->
    
    <!-- Page content START -->
	
	<!-- Top bar START -->
	<!-- Top bar END -->

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

		<!-- Title -->
		<div class="row mb-3">
			<div class="col-12 d-sm-flex justify-content-between align-items-center">
				<h1 class="h3 mb-2 mb-sm-0">지역</h1>
				<div>
					<router-link :to="addLink" class="btn btn-sm btn-primary mb-0">Add</router-link>
				</div>
				
			</div>
		</div>

		<!-- Card START -->
		<div class="card bg-transparent border">

			<!-- Card body START -->
			<div class="card-body">
				<!-- Course table START -->
				<div class="table-responsive border-0 rounded-3">
					<!-- Table START -->
					<table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
						<!-- Table head -->
						<thead>
							<tr>
								<th scope="col" class="border-0 rounded-start">Num</th>
								<th scope="col" class="border-0">지역</th>
								<th scope="col" class="border-0">Hide/Show</th>
								<th scope="col" class="border-0">Up/Down</th>
								<th scope="col" class="border-0">Delete</th>
								<th scope="col" class="border-0 rounded-end">Update</th>
							</tr>
						</thead>
						
						<!-- Table body START -->
						<tbody>
							<admin-location-list-component v-for="item in itemList"
								:key = "item.id"
								:id ="item.id"
								:num ="item.seq"
								:title="item.title"
								:seq="item.seq"
								:is_display="item.is_display"
								:min="item.min"
								:max="item.max"	

								@changeRefreshList="emitRefreshList"																							
							/>
							
							<!-- Table row -->
<!-- 							
							<tr>
								<td>
									<div class="d-flex align-items-center position-relative">
										<div class="w-60px">
											<img src="/assets/images/courses/4by3/08.jpg" class="rounded" alt="">
										</div>
										<h6 class="table-responsive-title mb-0 ms-2">	
											<router-link to="/admin/course/detail" class="stretched-link">Building Scalable APIs with GraphQL</router-link>
										</h6>
									</div>
								</td>

								<td>
									<div class="d-flex align-items-center mb-3">
										<div class="avatar avatar-xs flex-shrink-0">
											<img class="avatar-img rounded-circle" src="/assets/images/avatar/09.jpg" alt="avatar">
										</div>
										<div class="ms-2">
											<h6 class="mb-0 fw-light">Lori Stevens</h6>
										</div>
									</div>
								</td>

								<td> 
									<span class="badge text-bg-orange">All level</span>
									<span class="badge text-bg-primary">Beginner</span> 
									<span class="badge text-bg-purple">Intermediate</span>
									<span class="badge text-bg-success">Advanced</span>
								</td>

								<td>$350</td>

								<td> 
									<span class="badge bg-warning bg-opacity-15 text-warning">Pending</span> 
									<span class="badge bg-success bg-opacity-15 text-success">Live</span>
									<a href="#" class="btn btn-sm btn-dark me-1 mb-1 mb-md-0">Edit</a>
								</td>
								<td>28 Oct 2021</td>
							</tr>
 -->

						</tbody>
						<!-- Table body END -->
					</table>
					<!-- Table END -->
				</div>
				<!-- Course table END -->
			</div>
			<!-- Card body END -->

		</div>
		<!-- Card END -->
	</div>
	<!-- Page main content END -->

    <!-- Page content END -->
    
    </div>
</template>

<script>
var filename__="[pages > admin > sector > AdminLocationList.vue] "

import axios from 'axios';

import AdminLocationListComponent from '../../../components/admin/sector/AdminLocationListComponent.vue' ;


export default {
    components: {
        "admin-location-list-component": AdminLocationListComponent,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            

            itemList : [],
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
		addLink(){
			return '/admin/sector/location/add'; 
		}
    },    
    created(){
        this.getList();
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        isAdmin(){
            return this.$store.getters['auth/isAdmin'] ;
        },
		emitRefreshList(){
			this.getList();
		},
        async getList(){
			let funcname__ ="getList()" ;

			this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

			let serverData = await axios({
				method: 'get',
				url: '/api/user/sector/all',
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},               

			}).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				// console.log('[AdminCourseCategoryList.vue] profile:', data.profile);
				
				let tmpList = data.response ;
				let list = [];


				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					if(item.category === 'location') {
						list.push({
							id: item.id, 
							category: item.category, 
							depth: item.depth, 
							parent: item.parent, 
							title: item.title, 
							is_display: item.is_display, 
							seq:item.seq,
							min: true ,
							max: true,
						});
					}

				}
				if(list.length > 1) {
					for(let item of list ){
						item['min'] = (item.seq === 1) ? true : false ;
						item['max'] = (item.seq === list.length) ? true : false ;
					}
					list.sort(function(a,b){
						console.log("seq:",a.seq)
						return a.seq-b.seq
					});
				}

				console.log(filename__+''+funcname__+" list:", list);
				this.itemList = list;

			} else {
				console.log(filename__+''+funcname__+' err:');

				this.dialogTitle ="실패" ;                    
				this.error = serverData.data.error_description;  
			}

			this.isLoading = false ;            
		},



    }
}
</script>

<style scoped>

</style>