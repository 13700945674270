<template>
<div>
    <!-- 삭제 모달  -->
    <!-- teleport 를 통해서 body에 붙임  -->
    <teleport to="body">
        <div class="modal fade" :id="idDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" :id="idDeleteCompu">Delete</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                    <p>삭제된 내용은 복구되지 않습니다.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeCarrerForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div> 
    </teleport>

    <form @submit.prevent="sendCareerUpdateForm" class="row g-4 align-items-center mt-1">
        <!-- Radio items -->
        <div class="col-lg-12">
            <label class="form-label">회사근무/학교졸업</label>
            <div class="d-sm-flex">
                <div class="form-check form-switch form-check-md">
                    <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputCareer" @click="onChangeCareer">
                    <label v-if="inputCareer" class="form-check-label" for="profilePublic"><i class="far fa-address-card fa-fw me-2"></i>회사근무 </label>
                    <label v-else class="form-check-label" for="profilePublic"><i class="fas fa-user-graduate fa-fw me-2"></i>학교졸업</label>
                </div>	
            </div>
        </div>
        <div class="col-lg-6">
            <label class="form-label">학교이름 or 회사이름 </label>
            <input type="text" class="form-control" placeholder="학교/회사" v-model.trim="inputCareerName">
            <div class="form-text">Enter Name. </div>
        </div>
        <div class="col-lg-6">
            <label class="form-label">졸업내역 or 회사직책</label>
            <input type="text" class="form-control" placeholder="졸업내역 or 회사직책" v-model.trim="inputCareerMajor">
            <div class="form-text">For 졸업, 회사직책 </div>
        </div>


        <div class="col-lg-6">
            <label class="form-label"><i v-if="!max" @click="downSeq" class="fas fa-arrow-alt-circle-down me-2" style="font-size:27px;cursor:pointer"></i></label>
            <label class="form-label"><i v-if="!min" @click="upSeq" class="fas fa-arrow-alt-circle-up  me-2" style="font-size:27px;cursor:pointer"></i></label>
        </div>
        <div class="col-lg-6 text-lg-end">
            <button type="submit" class="btn btn-success mb-0">Save change</button>
            <button type="button" class="btn btn-sm btn-danger mb-0 ms-2" data-bs-toggle="modal" :data-bs-target="idDeleteShapCompu"><i class="bi bi-trash"></i></button>
        </div>

        <!-- <div class="d-sm-flex justify-content-end">
            <button type="submit" class="btn btn-success mb-0">Save change</button>
        </div> -->
    </form>
    <hr/>
</div>    
</template>

<script>

export default {
    components: {
    },
    props: [
        'id', 'company_id',
        'career','name','major',
        'seq','min','max',
    ],

    data() {
        return {
           error: null,     
           dialogTitle: null,  

           inputCareer : this.career,
           inputCareerName : this.name,
           inputCareerMajor : this.major,

        }
    },
    
    computed: {
        idDeleteCompu(){
            return 'deleteModal'+this.id
        },
        idDeleteShapCompu(){
            return '#deleteModal'+this.id
        },
    },
    methods: {
        handleError(){
            this.error = null;
        },

        onChangeCareer(){
            this.inputCareer = !this.inputCareer ;
        },
        upSeq(){
            this.$emit('changeUpSeq', this.id)
        },
        downSeq(){
            this.$emit('changeDownSeq', this.id)
        },
        sendCareerUpdateForm(){
            this.$emit('sendCareerUpdateForm',this.id, this.inputCareer, this.inputCareerName, this.inputCareerMajor)
        },
        removeCarrerForm(){
            this.$emit('removeCareerForm', this.id)
        },


    },
    
}
</script>