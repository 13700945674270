<template>
    <div>

        <main>
	
    <!-- =======================
    Main Content START -->
    <section class="pb-0 pt-4 pb-md-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
    
                    <!-- Title and Info START -->
                    <div class="row">
                        <!-- Avatar and Share -->
                        <div class="col-lg-3 align-items-center mt-4 mt-lg-5 order-2 order-lg-1">
                            <div class="text-lg-center">
                                <!-- Author info -->
                                <div class="position-relative">
                                    <!-- Avatar -->
                                    <div class="avatar avatar-xxl">
                                        <img class="avatar-img rounded-circle" src="/assets/images/avatar/09.jpg" alt="avatar">
                                    </div>
                                    <a href="#" class="h5 stretched-link mt-2 mb-0 d-block">Frances Guerrero</a>
                                    <p class="mb-2">Editor at Eduport</p>
                                </div>
                                <!-- Info -->
                                <ul class="list-inline list-unstyled">
                                    <li class="list-inline-item d-lg-block my-lg-2">2021-10-15</li>
                                    <li class="list-inline-item badge text-bg-orange"><i class="far text-white fa-heart me-1"></i>266</li>
                                </ul>
                            </div>
                        </div>
    
                        <!-- Content -->
                        <div class="col-lg-9 order-1">
                            <!-- Title -->
                            <h1 class="mt-2 mb-0 display-5">Never underestimate the influence of Eduport</h1>
                            <!-- Info -->
                            <p class="mt-2">For who thoroughly her boy estimating conviction. Removed demands expense account in outward tedious do. Particular way thoroughly unaffected projection favorable Mrs can be projecting own. Thirty it matter enable become admire in giving. See resolved goodness felicity shy civility domestic had but. Drawings offended yet answered Jennings perceive laughing six did far.</p>
                            <p class="mb-0 mb-lg-3">Perceived end knowledge certainly day sweetness why cordially.  On forth doubt miles of child. Exercise joy man children rejoiced. Yet uncommonly his ten who diminution astonished. Demesne new manners savings staying had. Under folly balls, death own point now men. Match way these she avoids seeing death. She who drift their fat off. Ask a quick six seven offer see among. Handsome met debating sir dwelling age material. As style lived he worse dried. Offered related so visitors we private removed.</p>
                        </div>
                    </div>
                    <!-- Title and Info END -->
    
                    <!-- Video START -->
                    <div class="row mt-4">
                        <div class="col-xl-10 mx-auto">
                            <!-- Card item START -->
                            <div class="card overflow-hidden h-200px h-sm-300px h-lg-400px h-xl-500px rounded-3 text-center" style="background-image:url(assets/images/event/10.jpg); background-position: center left; background-size: cover;">
                                <!-- Card Image overlay -->
                                <div class="bg-overlay bg-dark opacity-4"></div>
                                <div class="card-img-overlay d-flex align-items-center p-2 p-sm-4"> 
                                    <div class="w-100 my-auto">
                                        <div class="row justify-content-center">
                                            <!-- Video -->
                                            <div class="col-12">
                                                <a href="https://www.youtube.com/embed/tXHviS-4ygo" class="btn btn-lg text-danger btn-round btn-white-shadow stretched-link position-static mb-0" data-glightbox="" data-gallery="video-tour">
                                                    <i class="fas fa-play"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Card item END -->
                        </div>
                    </div>
                    <!-- Video END -->
    
                    <!-- Content START -->
                    <div class="row">
                        <div class="row mb-4">
                            <h4 class="mt-4">Productive rant about business</h4>
                            <div class="col-md-6">
                                <p class="mb-0">Fulfilled direction use continual set him propriety continued. Saw met applauded favorite deficient engrossed concealed and her. Concluded boy perpetual old supposing. Farther-related bed and passage comfort civilly. Dashwoods see frankness objection abilities. As hastened oh produced prospect formerly up am. Placing forming nay looking old married few has. Margaret disposed of add screened rendered six say his striking confined. Saw met applauded favorite deficient engrossed concealed and her. Concluded boy perpetual old supposing. Farther-related bed and passage comfort civilly. Dashwoods see frankness objection abilities. As hastened oh produced prospect formerly up am. Placing forming nay looking old married few has. Margaret disposed.</p>
                            </div>
                            <div class="col-md-6">
                                <p class="mb-0">Meant balls it if up doubt small purse. Paid mind even sons does he door no. Attended overcame repeated it is perceived Marianne in. I think on style child of. Servants moreover in sensible it ye possible. Required his you put the outlived answered position. A pleasure exertion if believed provided to. All led out world this music while asked. Paid mind even sons does he door no. Attended overcame repeated it is perceived Marianne in. I think on style child of. Servants moreover in sensible it ye possible.	</p>
                            </div>
                        </div>
                    </div>
                    <!-- Content END -->
        
                    <hr> <!-- Divider -->
    
                    <!-- Comment review and form START -->
                    <div class="row mt-4">
                        <!-- Comment START -->
                        <div class="col-md-7">
                            <h3>3 comments</h3>
                            <!-- Comment level 1-->
                            <div class="my-4 d-flex">
                                <img class="avatar avatar-md rounded-circle me-3" src="/assets/images/avatar/01.jpg" alt="avatar">
                                <div>
                                    <div class="mb-2">
                                        <h5 class="m-0">Frances Guerrero</h5>
                                        <span class="me-3 small">June 11, 2021 at 6:01 am</span>
                                    </div>
                                    <p>Satisfied conveying a dependent contented he gentleman agreeable do be. Warrant private blushes removed an in equally totally if. Delivered dejection necessary objection do Mr prevailed. Mr feeling does chiefly cordial in do.</p>
                                    <a href="#" class="btn btn-sm btn-light mb-0">Reply</a>
                                </div>
                            </div>
                            <!-- Comment children level 2 -->
                            <div class="my-4 d-flex ps-2 ps-md-4">
                                <img class="avatar avatar-md rounded-circle me-3" src="/assets/images/avatar/02.jpg" alt="avatar">
                                <div>
                                    <div class="mb-2">
                                        <h5 class="m-0">Louis Ferguson</h5>
                                        <span class="me-3 small">June 11, 2021 at 6:55 am</span>
                                    </div>
                                    <p>Water timed folly right aware if oh truth. Imprudence attachment him for sympathize. Large above be to means. Dashwood does provide stronger is. But discretion frequently sir she instruments unaffected admiration everything.</p>
                                    <a href="#" class="btn btn-sm btn-light mb-0">Reply</a>
                                </div>
                            </div>
                            <!-- Comment children level 3 -->
                            <div class="my-4 d-flex ps-3 ps-md-5">
                                <img class="avatar avatar-md rounded-circle me-3" src="/assets/images/avatar/01.jpg" alt="avatar">
                                <div>
                                    <div class="mb-2">
                                        <h5 class="m-0">Frances Guerrero</h5>
                                        <span class="me-3 small">June 12, 2021 at 7:30 am</span>
                                    </div>
                                    <p>Water timed folly right aware if oh truth.</p>
                                    <a href="#" class="btn btn-sm btn-light mb-0">Reply</a>
                                </div>
                            </div>
                            <!-- Comment level 1 -->
                            <div class="my-4 d-flex">
                                <img class="avatar avatar-md rounded-circle me-3" src="/assets/images/avatar/04.jpg" alt="avatar">
                                <div>
                                    <div class="mb-2">
                                        <h5 class="m-0">Judy Nguyen</h5>
                                        <span class="me-3 small">June 18, 2021 at 11:55 am</span>
                                    </div>
                                    <p>Fulfilled direction use continual set him propriety continued. Saw met applauded favorite deficient engrossed concealed and her. Concluded boy perpetual old supposing. Farther-related bed and passage comfort civilly.</p>
                                    <a href="#" class="btn btn-sm btn-light mb-0">Reply</a>
                                </div>
                            </div>
                        </div>
                        <!-- Comment END -->
    
                        <!-- Form START -->
                        <div class="col-md-5">
                            <!-- Title -->
                            <h3 class="mt-3 mt-sm-0">Your Views Please!</h3>
                            <small>Your email address will not be published. Required fields are marked *</small>
    
                            <form class="row g-3 mt-2 mb-5">
                                <!-- Name -->
                                <div class="col-lg-6">
                                    <label class="form-label">Name *</label>
                                    <input type="text" class="form-control" aria-label="First name">
                                </div>
                                <!-- Email -->
                                <div class="col-lg-6">
                                    <label class="form-label">Email *</label>
                                    <input type="email" class="form-control">
                                </div>
                                <!-- Comment -->
                                <div class="col-12">
                                    <label class="form-label">Your Comment *</label>
                                    <textarea class="form-control" rows="3"></textarea>
                                </div>
                                <!-- Button -->
                                <div class="col-12">
                                    <button type="submit" class="btn btn-primary mb-0">Post comment</button>
                                </div>
                            </form>
                        </div>
                        <!-- Form END -->
                    </div>
                    <!-- Comment review and form END -->
                </div>
            </div> <!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Main Content END -->
       
    </main>
    
    <hr> <!-- Divider -->
    </div>
</template>

<script>

export default {
    components: {
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            
        }
    },
    computed: {

    },    
    created(){
        // if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
        //     this.id = this.isUser() ;
        //     if(this.id) {
        //         this.getMember();
        //     }
            
        // } else {
        //     this.dialogTitle ="Error" ;
        //     this.error = "로그인이 필요합니다";    
        //     this.errorLogin = true ;
        // }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        
    }
}
</script>

<style scoped>

</style>