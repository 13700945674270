<template>
    <tr>
        <!-- Table data -->
        <td>{{num}}</td>

        <!-- Table data -->
        <td>
            <div v-if="receive_id != 0 " class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="avatar avatar-md">
                    <router-link :to="detailLink" >
                        <img v-if="receive_photo" :src="receive_photo" class="rounded-circle" alt="">
                        <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                    </router-link>
                </div>
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        <router-link :to="detailLink"  class="stretched-link">{{receive_name}}</router-link>
                        <router-link :to="receiveMemberLink"  class="stretched-link">
                            <span class="badge text-bg-info ms-2">개인정보</span>
                        </router-link>
                        <br/>
                        <small>{{receive_email}}</small>
                    </h6>
                </div>
            </div>
            <div v-else class="d-flex align-items-center position-relative">
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        <span class="badge text-bg-danger ms-2">플랫폼 관리자</span>
                    </h6>
                </div>
            </div>            
        </td>
        <td>
            <h6 class="table-responsive-title mb-0">
                <router-link :to="detailLink">{{contentSplice50}} </router-link>
            </h6>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0"><a href="#" class="stretched-link">{{date}}</a></h6>
                </div>
            </div>
        </td>

        <!-- Table data -->
        <td>
            <span v-if="is_check == false" class="badge text-bg-orange"><i class="fas fa-bell"></i></span>
        </td>

        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="avatar avatar-md">
                    <img v-if="create_photo" :src="create_photo" class="rounded-circle" alt="">
                    <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                </div>
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        Send: {{create_name}}
                        <router-link v-if="create_id != -9" :to="creatMemberLink"  class="stretched-link">
                            <span class="badge text-bg-primary ms-2">개인정보</span>
                        </router-link>
                        <br/>
                        <small>{{create_email}}</small>
                        
                    </h6>
                </div>
            </div>
        </td>
        <td>
            <router-link :to="detailLink" class="btn btn-success-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Edit">
                <i class="bi bi-pencil-square"></i>
            </router-link>
        </td>

    </tr>    
</template>

<script>
export default {
    components: {},
    props: [
        'id','num',
        'receive_id','receive_name','receive_email','receive_photo',
        'create_id','create_name','create_email','create_photo',
        'content','is_check','date',
        'page','search',
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        creatMemberLink(){
            return '/admin/member/'+this.create_id ; 
        },
        receiveMemberLink(){
            return '/admin/member/'+this.receive_id ; 
        },
        detailLink(){
            return '/admin/notification/'+this.id+'?page='+this.page+"&search="+this.search ; 
        },
        contentSplice50(){
            return this.content.slice(0,50)+"....";
        },

    },
    methods: {

    },
    
}
</script>