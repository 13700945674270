<template>
    <div>
        <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
            <p>{{ error }}</p>
        </base-dialog>

        <base-dialog :show="isLoading" title="Loading..." fixed>
            <p>Loading...</p>
            <base-spinner></base-spinner>
        </base-dialog>

    <!-- Sidebar START -->
    <!-- Sidebar END -->
    
    <!-- Page content START -->
	
	<!-- Top bar START -->
	<!-- Top bar END -->

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

        <!-- Title -->
        <div class="row mb-3">
            <div class="col-12">
                <h1 class="h3 mb-0">Error Logs</h1>
            </div>
        </div>

        <!-- All review table START -->
        <div class="card card-body bg-transparent pb-0 border mb-4">
            <!-- Search bar -->
            <div class="col-sm-12 mb-2">
                <form class="rounded position-relative" style="display: inline-block;">
                    <input class="form-control bg-transparent" type="search" placeholder="Search" aria-label="Search" v-model.trim="inputSearch" >
                    <button @click="onChangeSearch" class="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="button">
                        <i class="fas fa-search fs-6 "></i>
                    </button>
                </form>
                <button type="button" @click="onChangeSearch" class="btn btn-primary mb-0 ms-2">Search</button>
                <button type="button" @click="onChangeAll" class="btn btn-primary mb-0 ms-2">All</button>
                <button type="button" @click="onChangeStatus" class="btn btn-danger mb-0 ms-2">미처리</button>
            </div>

            <!-- Table START -->
            <div class="table-responsive border-0">
                <table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
                    <!-- Table head -->
                    <thead>
                        <tr>
                            <th scope="col" class="border-0 rounded-start">#</th>
                            <th scope="col" class="border-0">which</th>
                            <th scope="col" class="border-0">File</th>
                            <th scope="col" class="border-0">Method</th>
                            <th scope="col" class="border-0">Tag</th>
                            <th scope="col" class="border-0">Message</th>
                            <th scope="col" class="border-0">Date</th>
                            <th scope="col" class="border-0">Status</th>
                            <th scope="col" class="border-0 rounded-end">Action</th>
                        </tr>
                    </thead>

                    <!-- Table body START -->
                    <tbody>

                        <!-- Table row -->
                        <admin-errorlog-list-component v-for="item in itemList"
                            :key = "item.id"
                            :id ="item.id"
                            :num ="item.num"
                            :which = "item.which" 
                            :ipaddress ="item.ipaddress"
                            :category ="item.category"
                            :filename ="item.filename"
                            :methodname ="item.methodname"
                            :title ="item.title"
                            :content="item.content"
                            :status ="item.status"
                            :date ="item.date"
                            :search="inputSearch"
                            :page="page" />

                    </tbody>
                    <!-- Table body END -->
                </table>
            </div>
            <!-- Table END -->

            <!-- Card footer START -->
			<div v-if="itemList.length > 0"> 
				<admin-pagination :pagination="paginationCompu" @paging="pagingSend" ></admin-pagination>
			</div>
            <!-- Card footer END -->
        </div>
        <!-- All review table END -->
        </div>    
    </div>
</template>

<script>
//  router__ send__
var filename__= "[pages > admin > errorlog > AdminErrorLogList.vue] "
import axios from 'axios';

import AdminErrorLogListComponent from '../../../components/admin/errorlog/AdminErrorLogListComponent.vue';
import AdminPagination from '../../../components/admin/ui/AdminPagination.vue';

export default {
    components: {
        "admin-errorlog-list-component" : AdminErrorLogListComponent,
        "admin-pagination": AdminPagination,
    },
    props: [],
    data() {
        return {
            isLoading: false,            
            error: null,     
            dialogTitle: null,
            
            adminLogin : true,
            
            itemList: [], 
            page:1,
            current_path:"/admin/errorlogs",  //current_path
			pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',     
            inputStatus : 10,

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    computed: {
        paginationCompu(){
            return this.pagination ;
        },

    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			this.getList();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.adminLogin = false ;            
        }          
    },
    methods: {
        handleError(){
            this.error = null;
            if(!this.adminLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js

                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        onChangeSearch(){
			this.page = 1 ;
            this.getList(); //page=1
        },
        onChangeAll(){
			this.page = 1 ;
			this.inputSearch = '' ;
            this.inputStatus = 10 ;  //모든 상태 검색 
            this.getList(); //page=1
        },
        onChangeStatus(){
			this.page = 1 ;
            this.inputStatus = 9 ; // 미치러만 나오게
            this.getList(); //page=1
        },

        async getList(){
            let funcname__ ="getList() ";
            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }

            if( this.inputSearch === 'undefinded' || this.inputSearch === '') { 
                this.inputSearch = '' ; //pagination, search 에서 계속작동하기 위해서                 
            }             

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            try {
                let serverData = await axios({
                    method: 'get',
                    url: '/api/errorlog/all',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                
                    params: { 
                        page: this.page , 
                        page_per_count : this.inputPagePerCount,
                        block_size : this.inputBlockSize,
                        search: this.inputSearch,       
                        status : this.inputStatus,
                    }
                }).catch( err => {
                    
                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });


                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ; 
                    return ;
                }
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.code == 200 ){
                    // console.log('[AdminContactusList.vue] profile:', data.profile);
                    
                    this.pagination = data.response.pagination ;
                    let tmpList = data.response.data ;
                    let counter = data.response.pagination.counter ;
                    let list = [];


                    //메인 카테고리 값들을 입력하기 
                    for(let item of tmpList ){
                        list.push({
                            num: counter--, 
                            id: item.id, 
                            which:item.which,
                            ipaddress:item.ipaddress,
                            category:item.category,
                            filename:item.filename,
                            methodname:item.methodname,
                            title:item.title,
                            content: item.content, 
                            status:item.status,
                            date: item.date
                        });
                    }

                    // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                    this.itemList = list;

                    // console.log(filename__+''+funcname__+" itemList:", this.itemList);
                    // console.log(filename__+''+funcname__+" pagination:", this.pagination);

                } else {

                    let dataForm = { 
                        category: 'errorlog(else)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(else)',       
                        content : ''+serverData.data.msg,
                    } ;  
                    console.log(filename__+''+funcname__+" errorlog(else) dataForm:", dataForm);  //~~log

                    await axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        params: dataForm
                    }).catch( err => {
                        console.log(filename__+''+funcname__+' errorlog(else) :',err);
                    });

                    this.dialogTitle ="Error" ;
                    this.error = serverData.data.msg;  
                    
                    this.isLoading = false ;
                                        
                    console.log(filename__+''+funcname__+' err(else):'+serverData.data.msg);

                }

            } catch(error){

                let dataForm = { 
                    category: 'errorlog(catch)' , 
                    filename : filename__,
                    methodname : funcname__,
                    title: 'Error(catch)',       
                    content : ''+error,
                } ;  
                console.log(filename__+''+funcname__+" errorlog(catch) dataForm:", dataForm);  //~~log

                await axios({
                    method: 'post',
                    url: '/api/errorlog/create',
                    params: dataForm
                }).catch( err => {
                    console.log(filename__+''+funcname__+' errorlog(catch) :',err);
                });

                this.dialogTitle ="전송 실패" ;
                this.error = error; 

                this.isLoading = false ;

                console.error(filename__+''+funcname__+' error(catch):',error);

            }

            this.isLoading = false ;            
        },


    }
}
</script>

<style scoped>

</style>