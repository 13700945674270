<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

	<!-- Page main content START -->
	<div class="page-content-wrapper-match border">
        <form @submit.prevent="sendForm" >
            <!-- Title -->
            <div class="row mb-2">
                <div class="col-12 d-sm-flex justify-content-between align-items-center">
                    <h1 class="h3 mb-2 mb-sm-0">가입즉시 활성화 여부</h1>
                    <div></div>
                </div>
            </div>
            <div class="card card-body bg-transparent pb-0 border mb-4">
                <div class="row mt-2 mb-3">
                    <div class="mb-2 bg-light-input">
                        <label for="activeBox" class="form-label">가입즉시 활성화 여부*</label>
                        <div class="form-check form-switch form-check-md">
                            <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsActive" @click="onChangeActive" />
                            <label v-if="inputIsActive" class="form-check-label" for="profilePublic">가입즉시 활성화 </label>
                            <label v-else class="form-check-label" for="profilePublic">가입 후 관리자 승인 필요</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-1">
                <div class="col-12 d-sm-flex justify-content-between align-items-center">
                    <div></div>
                    <button type="submit" class="btn btn-primary mb-0" >Change</button>
                </div>
            </div>
        </form>
	</div>
    
</div>
</template>

<script>
var filename__= "[pages > admin > setting > AdminSettingApproveUpdate.vue] "
import axios from 'axios';


export default {
    components: {
    },
    props: ['id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  
            
            inputId : null, 
            inputIsActive : null, 
            
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
        backToLink(){
            // return '/admin/settings/'+this.parent_id+'?page='+this.$route.query.page+"&search="+this.$route.query.search ; 
            return '/admin/settings'; 
        },

    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			this.getItem();
            return ;
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },
        onChangeActive(){
            this.inputIsActive = !this.inputIsActive ;
        },

        async getItem(){
            let funcname__ ="getItem() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/setting/approve',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },           

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ;
                return ;

            } else {
                const data = serverData.data ;

                console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" &&  data.status_code == 200 ){

                    // console.log(filename__+''+funcname__+'  response:', data.response);
                    const setting = data.response

                    this.inputId = setting.id ;
                    this.inputIsActive = setting.is_active ;

                } else {
                    console.log(filename__+''+funcname__+' err:');

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  // this.error = err.msg || 'Failed to authenticate';                
                    // alert('error')
                }
            }

            this.isLoading = false ;     
        },
        
        // 전송하기  
        async sendForm(){
            let funcname__ ="sendForm() ";

            let dataForm = { 
                'is_active':this.inputIsActive,
            } ;  

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            if( this.inputIsActive === '' || this.inputIsActive === undefined || this.inputIsActive === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="가입시 활성화 여부를 선택해주세요.";
                return ;
            }

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/setting/active/update/'+this.inputId,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    },                     
                    data: dataForm,

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }
                    
                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){

                        this.dialogTitle ='Success' ;
                        this.error = '변경 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 
                            }, 1000);
                        }                        


                    } else {
                        console.log(filename__+''+funcname__+' err.msg:', serverData.data.error_description);
                        this.dialogTitle ="전송 실패" ;
                        this.error = serverData.data.error_description;

                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    }
}
</script>

<style scoped>

</style>