<template>
<div class="card bg-transparent border rounded-3 mb-2">
    <!-- Card header -->
    <div class="card-header bg-transparent border-bottom">
        <h3 class="card-header-title mb-0">Faq 답변내역<small>({{ date }})</small></h3>
    </div>
    <!-- Card body START -->
    <div class="card-body">
        <form  class="row g-4">
            
            <!-- About me -->
            <div class="col-12">
                <p class="font-13 white-space-newline mb-0" >{{ content }}</p>
            </div>
        </form>
    </div>
    <!-- Card body END -->
</div>

</template>

<script>

export default {
    components: {},
    props: [
        'id', 'parent_id','content','date'
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

        }
    },
    created(){
        // this.getContactus();
    },    
    computed: {

    },
    methods: {
        handleError(){
            this.error = null;
        },

    },
    
}
</script>
<style scoped>
.white-space-newline {

    /* white-space: normal;
    white-space: nowrap;
    white-space: pre;
    white-space: pre-wrap;
    white-space: pre-line; */
    white-space: break-spaces;

    /* Global values */
    /* white-space: inherit;
    white-space: initial;
    white-space: unset; */

}

</style> 