export default {
    addRequest(state, payload){
        state.requests.push(payload);
    },

    setRequests(state, payload){
        // console.log("[store/requests/mutations.js_setRequests]", state, payload);        
        state.requests = payload ;
    },

    setFetchTimestamp(state){
        state.lastFetch = new Date().getTime();
    }
};