<template>
<div>

    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

		<div class="row g-3 align-items-center justify-content-between">
			<div class="col-md-6">
				<h1 class="h3 mb-0">Socail Link Settings</h1>
			</div>
            <div class="col-md-6 text-end">
                <router-link :to="createLink" class="btn btn-primary">Add</router-link>
            </div>
		</div>

        <!-- All review table START -->
        <div class="card card-body bg-transparent pb-0 border mb-4">

            <!-- Table START -->
            <div class="table-responsive border-0">
                <table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
                    <!-- Table head -->
                    <thead>
                        <tr>
                            <th scope="col" class="border-0 rounded-start">#</th>
                            <th scope="col" class="border-0">Icon</th>
                            <th scope="col" class="border-0">Title</th>
                            <th scope="col" class="border-0">Description</th>
                            <th scope="col" class="border-0">Status</th>
                            <th scope="col" class="border-0 rounded-end">Action</th>
                        </tr>
                    </thead>

                    <!-- Table body START -->
                    <tbody>

                        <!-- Table row -->
                        <admin-setting-social-list-component v-for="item in itemList"
                            :key = "item.id"
                            :id ="item.id"
                            :num ="item.num"
                            :title ="item.title"
                            :description="item.description"
                            :is_active ="item.is_active"
                            />

                    </tbody>
                    <!-- Table body END -->
                </table>
            </div>
            <!-- Table END -->

        </div>
        <ul>
            <li><i class="fab fa-facebook text-facebook me-2"></i>facebook</li>
            <li><i class="fab fa-youtube text-google-icon me-2"></i>youtube</li>
            <li><i class="fab fa-twitter text-twitter me-2"></i>twitter</li>
            <li><i class="fab fa-instagram text-facebook me-2"></i>instagram</li>
        </ul>
        <!-- All review table END -->
    </div>    
</div>
</template>

<script>
var filename__= "[pages > admin > setting > AdminSettingSocialList.vue] "
import axios from 'axios';

import AdminSettingSocialListComponent from '../../../components/admin/setting/AdminSettingSocialListComponent.vue';

export default {
    components: {
        "admin-setting-social-list-component" : AdminSettingSocialListComponent,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList: [], 

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     
    computed: {
        createLink(){
            return '/admin/setting/social/create'; 
        },

    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			this.getList();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "관리자 로그인이 필요합니다";    
            this.errorLogin = true ;
        }          
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isAdmin(){
			// console.log('[AdminMemberList.vue] isAdmin():',this.$store.getters['auth/isAdmin']);
            return this.$store.getters['auth/isAdmin'] ;
        },

        async getList(){
            let funcname__ ="getList() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/setting/all/social',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log(filename__+''+funcname__+' profile:', data.profile);
                
                let tmpList = data.response ;
                let list = [];
                let count = 1;

                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        num: count++, 
                        id: item.id, 
                        title:item.title,
                        description: item.description, 
                        is_active:item.is_active
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.itemList = list;

                console.log(filename__+''+funcname__+" itemList:", this.itemList);

            } else {
                console.log(filename__+''+funcname__+' err:');

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  // this.error = err.msg || 'Failed to authenticate';                
                // alert('error')
            }

            this.isLoading = false ;            
        },


    }
}
</script>

<style scoped>

</style>