<template>
    <tr>
        <!-- Table data -->
        <td>{{num}}</td>

        <!-- Table data -->
        <td>
            
            <p class="table-responsive-title mb-0">
                <router-link :to="detailLink" class="list-group-item">{{contentSplice40}}</router-link>                
            </p>
        </td>
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="list-group-item mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0"><a href="#" class="stretched-link">{{date}}</a></h6>
                </div>
            </div>
        </td>

        <!-- Table data -->
        <td>
            <span v-if="is_check == false" class="badge text-bg-orange"><i class="fas fa-bell"></i></span>
        </td>

        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="avatar avatar-md">
                    <img v-if="create_photo" :src="create_photo" class="rounded-circle" alt="">
                    <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                </div>
                <div class="list-group-item mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">{{create_name}}</h6>
                </div>
            </div>
        </td>
        <td>
            <router-link :to="detailLink" class="btn btn-success-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Edit">
                <i class="bi bi-pencil-square"></i>
            </router-link>
        </td>


    </tr>    
</template>

<script>
export default {
    components: {},
    props: [
        'id','num',
        'receive_id','receive_name','receive_email','receive_photo',
        'create_id','create_name','create_email','create_photo',
        'content','is_check','date',
        'page','search',
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            return '/companydash/notification/'+this.id+'?page='+this.page+"&search="+this.search ; 
        },
        contentSplice40(){
            return this.content.slice(0,40)+"....";
        },

    },
    methods: {

    },
    
}
</script>