<template>
<div>

        <!-- Page main content START -->
        <div class="page-content-wrapper border">

            <!-- Title -->
            <div class="row">
                <div class="col-12 mb-3">
                    <h1 class="h3 mb-2 mb-sm-0">대시보드</h1>
                </div>
            </div>

            <!-- Counter boxes START -->
            <admin-course-count-component />
            <!-- Counter boxes END -->

            <!-- Chart and Ticket START -->
            <div class="row g-4 mb-4">

                <!-- Chart START -->
                <!-- <div class="col-xxl-8">
                    <div class="card shadow h-100">

                        <div class="card-header p-4 border-bottom">
                            <h5 class="card-header-title">Earnings</h5>
                        </div>

                        <div class="card-body">
                            <div id="ChartPayout"></div>
                        </div>
                    </div>
                </div> -->
                <!-- Chart END -->

                <!-- Faq START -->
                <div class="col-xxl-4">
                    <admin-dashboard-faq-panel-component />
                </div>
                <!-- Faq END -->

                <!-- Course Comment  START -->
                <div class="col-xxl-4">
                    <admin-dashboard-course-comment-panel-component />
                </div>
                <!-- Course Comment  END -->
                
                <!-- Course Review  START -->
                <div class="col-xxl-4">
                    <admin-dashboard-course-review-panel-component />
                </div>
                <!-- Course Review  END -->

            </div>
            <!-- Chart and Ticket END -->

            <!-- Top listed Cards START -->
            <div class="row g-4">

                <!-- Top instructors START -->
                <div class="col-lg-6 col-xxl-4">
                    <admin-dashboard-company-panel-component />
                </div>
                <!-- Top instructors END -->

                <!-- Notice Board START -->
                <div class="col-lg-6 col-xxl-4">
                    <!-- Notification List -->
                    <admin-dashboard-notification-panel-component />
                </div>
                <!-- Notice Board END -->

                <!-- Error Log START -->
                <div class="col-lg-6 col-xxl-4">
                    <admin-dashboard-errorlog-panel-component />
                </div>
                <!-- Traffic sources END -->

            </div>
            <!-- Top listed Cards END -->

        </div>
        <!-- Page main content END -->

    <!-- Page content END -->

    </div>
</template>

<script>

import AdminCourseCountComponent from '../../components/admin/dashboard/AdminCourseCountComponent.vue' ;

import AdminDashboardFaqPanelComponent from '../../components/admin/dashboard/AdminDashboardFaqPanelComponent.vue' ;
import AdminDashboardCourseCommentPanelComponent from '../../components/admin/dashboard/AdminDashboardCourseCommentPanelComponent.vue' ;
import AdminDashboardCourseReviewPanelComponent from '../../components/admin/dashboard/AdminDashboardCourseReviewPanelComponent.vue' ;

import AdminDashboardCompanyPanelComponent from '../../components/admin/dashboard/AdminDashboardCompanyPanelComponent.vue' ;
import AdminDashboardNotificationPanelComponent from '../../components/admin/dashboard/AdminDashboardNotificationPanelComponent.vue' ;
import AdminDashboardErrorLogPanelComponent from '../../components/admin/dashboard/AdminDashboardErrorLogPanelComponent.vue' ;

export default {
    components: {
        "admin-course-count-component": AdminCourseCountComponent ,
      
        "admin-dashboard-faq-panel-component": AdminDashboardFaqPanelComponent ,
        "admin-dashboard-course-comment-panel-component": AdminDashboardCourseCommentPanelComponent ,
        "admin-dashboard-course-review-panel-component": AdminDashboardCourseReviewPanelComponent ,

        "admin-dashboard-company-panel-component": AdminDashboardCompanyPanelComponent ,
        "admin-dashboard-notification-panel-component": AdminDashboardNotificationPanelComponent ,
        "admin-dashboard-errorlog-panel-component": AdminDashboardErrorLogPanelComponent ,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,
            errorLogin : null,
            dialogTitle: null,

        }
    },
    computed: {
        email : function(){
            return this.$store.getters['auth/email'] ;
        },
        photo(){
            return this.inputPhotoUpdate ;
        },
        realname(){
            return this.inputRealnameUpdate ;
        },
        displayname(){
            return this.inputDisplaynameUpdate ;
        },
        phone(){
            return this.inputPhoneUpdate ;
        },

    },
    created(){
        // if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크
        //     this.id = this.isUser() ;
        //     if(this.id) {
        //         this.getMember();
        //     }

        // } else {
        //     this.dialogTitle ="Error" ;
        //     this.error = "로그인이 필요합니다";
        //     this.errorLogin = true ;
        // }

        // admin인 경우
        this.$store.dispatch('auth/pageAdmin'); //==> store/modules/auth/actions.js

    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },

    }
}
</script>

<style scoped>

</style>
