export default {
    setSocial(state, payload){
        // console.log('[store/social/mutations.js] setSocial() payload:', payload);
        
        state.is_social = payload.is_social;

        state.facebook = payload.facebook;
        state.youtube= payload.youtube;
        state.twitter= payload.twitter;
        state.instagram = payload.instagram;
    }
}