<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- Counter START -->
    <div class="row mt-2 g-3">
        <!-- Counter item -->
        <div class="col-sm-6 col-lg-6">
            <div class="card card-body shadow p-4 mb-4">
                <div class="d-flex align-items-center">
                    <span class="icon-lg text-success mb-0 bg-success bg-opacity-10 rounded-3"><i class="fas fa-play"></i></span>
                    <div class="ms-3">
                        <div class="d-flex">
                            <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="10"	data-purecounter-delay="200">{{ courseCountCompu }}</h5>
                            <span class="mb-0 h5">+</span>
                        </div>
                        <p class="mb-0 h6 fw-light">등록 강의</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Counter item -->
        <div class="col-sm-6 col-lg-6">
            <div class="card card-body shadow p-4 mb-4">
                <div class="d-flex align-items-center">
                    <span class="icon-lg text-purple bg-purple bg-opacity-10 rounded-3 mb-0"><i class="fas fa-users"></i></span>
                    <div class="ms-3">
                        <div class="d-flex">
                            <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="36"	data-purecounter-delay="200">{{ studentCountCompu }}</h5>
                            <span class="mb-0 h5">+</span>
                            <!-- <span class="mb-0 h5">K+</span> -->
                        </div>
                        <p class="mb-0 h6 fw-light">등록 학생</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Counter item -->
        <!-- <div class="col-sm-6 col-lg-4">
            <div v-if="isApplyingCompu" class="card card-body shadow p-4 mb-4">
                <div class="d-flex align-items-center">
                    <span class="icon-lg text-orange bg-orange bg-opacity-10 rounded-3 mb-0"><i class="fas fa-trophy"></i></span>
                    <div class="ms-3">
                        <div class="d-flex">
                            <h5 class="purecounter mb-0 fw-bold" data-purecounter-start="0" data-purecounter-end="11" data-purecounter-delay="200">0</h5>
                            <span class="mb-0 h5">+</span>
                        </div>
                        <p class="mb-0 h6 fw-light">경력 연수</p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <!-- Counter END -->

    <div class="row g-4 mt-0">
        <!-- Title -->
        <h2>과정 목록</h2>

        <company-detail-course-list-component v-for="item in itemList"
            :key = "item.id"
            :id ="item.id"
            :num ="item.num"
            :title ="item.title"
            :subtitle="item.subtitle"
            :level ="item.level"
            :coursetime ="item.coursetime"
            :lecturecount ="item.lecturecount"

            :is_fronttext ="item.is_fronttext"
            :image ="item.image"
            
            :date ="item.date"
            :update ="item.update"
            :is_display="item.is_display"
            :onoff="item.onoff"
            :reviewavg="item.reviewavg"
            :categorystr="item.categorystr"

            :create_id = "item.create_id" 
            :create_name = "item.create_name" 
            :create_email = "item.create_email" 
            :create_photo = "item.create_photo" 

            :page="page" 
            
        />
        <div class="col-12">
            <div v-if="itemList && itemList.length > 0"> 
                <user-pagination :pagination="paginationCompu" @paging="pagingSend" />
            </div>

            <!-- <nav class="mt-4 d-flex justify-content-center" aria-label="navigation">
                <ul class="pagination pagination-primary-soft d-inline-block d-md-flex rounded mb-0">
                    <li class="page-item mb-0"><a class="page-link" href="#" tabindex="-1"><i class="fas fa-angle-double-left"></i></a></li>
                    <li class="page-item mb-0"><a class="page-link" href="#">1</a></li>
                    <li class="page-item mb-0 active" aria-current="page"><a class="page-link" href="#">2</a></li>
                    <li class="page-item mb-0"><a class="page-link" href="#">..</a></li>
                    <li class="page-item mb-0"><a class="page-link" href="#">6</a></li>
                    <li class="page-item mb-0"><a class="page-link" href="#"><i class="fas fa-angle-double-right"></i></a></li>
                </ul>
            </nav> -->
        </div>        
    </div>


</div>    
</template>

<script>
var filename__="[components > company > CompanyDetailCoursePanelComponent.vue] "

import axios from 'axios';

import CompanyDetailCourseListComponent from './CompanyDetailCourseListComponent.vue';
import UserPagination from '../../components/ui/UserPagination.vue';

export default {
    components: {
        "company-detail-course-list-component": CompanyDetailCourseListComponent,
        "user-pagination": UserPagination,
    },
    props: [ 'id','company_id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       

            itemList: [], 
            page:1,
            current_path:'/company/'+this.id,  //current_path
			pagination: null, 

            inputPagePerCount : 9,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수        
            
            inputStudentCount: 0,
            inputCourseCount:0 ,
        }
    },
    created(){        
        this.getList();
    },  
    
    computed: {
        paginationCompu(){
            return this.pagination ;
        },
        courseCountCompu(){
            return this.inputCourseCount ;
        },
        studentCountCompu(){
            return this.inputStudentCount ;
        }
    },
    methods: {
        handleError(){
            this.error = null;

        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
		async getList(){
			let funcname__ ="getList()" ;

			this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

			let serverData = await axios({
				method: 'get',
				url: '/api/content/course/company/user/'+this.id,
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},           
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
					level: this.inputLevel,    
                    search: this.inputSearch,   

                }

			}).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				// console.log(filename__+''+funcname__+' profile:', data.profile);
				
                this.inputCourseCount = data.response.total_enrollcount ;
                this.inputStudentCount = data.response.total_studentcount ;
                
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;

                let list = [];

				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					list.push({
						num: counter--, 
						id: item.id, 

						title:item.title,
						subtitle:item.subtitle,

						level : item.level,
                        coursetime : item.coursetime ? item.coursetime : false ,
                        lecturecount : item.lecturecount,
						reviewavg : item.review_avg,

                        image : item.main_photo ? item.main_photo.main_photo_path : false,
                        is_fronttext : item.is_fronttext, 

						category: item.category,
						categorystr: item.categorystr,

						is_student: item.is_student,
						is_wishlist: item.is_wishlist,

						seq:item.seq,
                        date : item.date,
                        update : item.update,

                        create_name : item.create_name,
						create_email : item.create_email,
						create_photo : item.create_photo,
                        
					});
				}

				// console.log(filename__+''+funcname__+" tmpList:", tmpList);
				this.itemList = list;

                this.inputCourseCount = list.length ;
                
				console.log(filename__+''+funcname__+" itemList:", this.itemList);
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);

			} else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

			this.isLoading = false ;            
		},


    },
    
}
</script>