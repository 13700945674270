<template>
    <div>
    
        <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
            <p>{{ error }}</p>
        </base-dialog>
    
        <base-dialog :show="isLoading" title="Loading..." fixed>
            <p>Loading...</p>
            <base-spinner></base-spinner>
        </base-dialog>
        
        <admin-contactus-reply-list-component v-for="item in itemList"
            :key = "item.id"
            :id ="item.id"
            :parent_id ="item.parent_id"
            :done_name = "item.done_name" 
            :done_photo ="item.done_photo"
            :content="item.content"
            :date="item.date"
             />
    
        
    </div>
</template>
    
<script>
var filename__= "[pages > admin > faq > AdminContactusReplyPanelComponent.vue] "
import axios from 'axios';

import AdminContactusReplyListComponent from '../../../components/admin/faq/AdminContactusReplyListComponent.vue';

export default {
    components: {
        "admin-contactus-reply-list-component" : AdminContactusReplyListComponent,
    },
    props: ['id','parent_id'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,      
            
            itemList : [],
            
        }
    },
    beforeMount() {
    },     

    computed: {

    },    
    created(){
        this.getReplyList();            
    },
    methods: {
        handleError(){
            this.error = null;

        },
        async getReplyList(){
            let funcname__ ="getReplyList() ";

            this.isLoading = true ;   

            let serverData = await axios({
                method: 'get',
                url: '/api/faq/contactus/replies/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                        
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }
                
            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                let tmpList = data.response ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        id: item.id, 
                        parent_id: item.parent_id, 
                        done_name:item.done_name,
                        done_photo:item.done_photo,
                        content: item.content, 
                        date: item.date
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.itemList = list;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                this.dialogTitle ="실패" ;                    
                this.error = serverData.data.error_description;  // this.error = err.msg || 'Failed to authenticate';                
                // alert('error')
            }

            this.isLoading = false ;            
        },

        
    }
}
</script>
    
<style scoped>

</style>