<template>
    <tr>
        <!-- Course item -->
        <td class="text-center text-sm-start">{{num}}</td> 
        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div v-if="create_photo" class="avatar avatar-md mb-2 mb-md-0">
                    <img :src="create_photo" class="rounded" alt="">
                </div>
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        <a href="#" class="stretched-link">{{create_name}}</a>
                        <span class="text-body small ms-2"> {{ create_email }}</span>
                    </h6>
                    <!-- Address -->
                    <!-- <span class="text-body small"><i class="fas fa-fw fa-map-marker-alt me-1 mt-1"></i>주소</span> -->
                    
                </div>
            </div>
        </td>
        <td>
            <div class="d-flex align-items-center">
                <!-- Image -->
                <div v-if="!is_text" class="w-60px">
                    <router-link  :to="detailLink" >
                        <img v-if="course_image" :src="course_image" class="rounded" alt="">
                        <!-- <img v-else src="/assets/images/courses/4by3/08.jpg" class="rounded" alt=""> -->
                        <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" alt="">
                    </router-link>
                </div>
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6>
                        <router-link :to="detailLink">
                            <span>{{title}}</span>
                        </router-link>
                    </h6>
                    <!-- Info -->
                    <div class="d-sm-flex">
                        <p class="h6 fw-light mb-0 small me-3">
                            <i class="fas fa-table text-orange me-2"></i>{{lecturecount}} lectures
                            <span class="badge bg-danger bg-opacity-10 text-primary me-2">{{timeFormat}}</span>

                            <!-- <span v-if="is_price" class="badge bg-danger bg-opacity-10 text-danger me-2">{{price}}</span>
                            <span v-else class="badge bg-success bg-opacity-10 text-success me-2">free</span> -->

                            <!-- <span v-if="onoff" class="badge bg-success bg-opacity-10 text-success me-2">Online</span>
                            <span v-else class="badge bg-danger bg-opacity-10 text-danger me-2">Offline</span> -->

                            <span v-if="level == 0" class="badge btn-success-soft mb-0 me-2">All Level</span>
                            <span v-if="level == 1"  class="badge btn-primary-soft mb-0 me-2">초급</span>
                            <span v-if="level == 2" class="badge btn-orange-soft mb-0 me-2">중급</span>
                            <span v-if="level == 3" class="badge btn-danger-soft mb-0 me-2">고급</span>


                        </p>
                    </div>
                </div>
            </div>
        </td>
        <td>{{date}}</td>

    </tr>

</template>

<script>
// query__ param__
export default {
    components: {},
    props: [
        'content_id',
        'id','num','create_id','create_name','create_email','create_photo','date',
        'title','level','coursetime','lecturecount',
        'is_text','course_image',
        'is_display', 
        'page', 
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  
        }
    },
    
    computed: {
        detailLink(){
            // return '/instructordash/course/'+this.id+'?page='+this.$route.query.page+"&search="+this.$route.query.search
            return '/companydash/course/'+this.content_id ;
        },
        timeFormat(){
            let hour = parseInt(this.coursetime/60) ;
            let minute = this.coursetime - hour * 60 ;
            if(hour == 0){
                return this.coursetime +'분'                
            } else if(minute == 0){
                return hour+'시간'
            } else {
                return hour + '시간 '+minute + '분' ;                
            }
        }

    },
    methods: {

    },
    
}
</script>