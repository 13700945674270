<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <main>
	
    <!-- =======================
    Page Banner START -->
    <company-dashboard-top :title="'정보창고 공동작업자 추가'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
                        
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <company-dashboard-left :menu="'blog'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Edit profile START -->
                    <div class="card bg-transparent border rounded-3">
                        <!-- Card header -->
                        <div class="card-header bg-transparent border-bottom d-flex justify-content-between">
                            <h3 class="card-header-title mb-0">정보창고에 추가할 공동작업자 리스트</h3>
                            <router-link :to="backToLink" class="btn btn-secondary">Back</router-link>
                        </div>
                  
                        <!-- Search bar -->
                        <!-- <div class="col-sm-12 mb-2">
                            <form class="rounded position-relative" style="display: inline-block;">
                                <input class="form-control bg-transparent" type="search" placeholder="Search" aria-label="Search" v-model.trim="inputSearch" >
                                <button @click="onChangeSearch" class="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="button">
                                    <i class="fas fa-search fs-6 "></i>
                                </button>
                            </form>
                            <button type="button" @click="onChangeSearch" class="btn btn-primary mb-0 ms-2">Search</button>
                            <button type="button" @click="onChangeAll" class="btn btn-primary mb-0 ms-2">All</button>
                            <button type="button" @click="onChangeStatus" class="btn btn-danger mb-0 ms-2">미처리</button>
                        </div> -->

                        <!-- Table START -->
                        <div class="table-responsive border-0 mt-2 me-2 ms-2">
                            <table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
                                <!-- Table head -->
                                <thead>
                                    <tr>
                                        <th scope="col" class="border-0 rounded-start">#</th>
                                        <th scope="col" class="border-0">Photo</th>
                                        <th scope="col" class="border-0">Name</th>
                                        <th scope="col" class="border-0">Email</th>
                                        <th scope="col" class="border-0 rounded-end">Add</th>
                                    </tr>
                                </thead>

                                <!-- Table body START -->
                                <tbody>

                                    <!-- Table row -->
                                    <companydash-course-worker-add-list-component v-for="item in itemList"
                                        :key = "item.id"
                                        :id ="item.id"
                                        :content_id ="content_id"
                                        :company_id ="company_id"
                                        :user_id = "item.user_id" 
                                        :user_name = "item.user_name" 
                                        :user_email = "item.user_email" 
                                        :user_photo = "item.user_photo" 
                                        @sendRefreshForm="sendRefreshForm"
                                        
                                        />

                                </tbody>
                                <!-- Table body END -->
                            </table>
                        </div>
                        <!-- Table END -->

                        <!-- Card footer START -->
                        <div v-if="itemList.length > 0" class="me-2 ms-2"> 
                            <dash-pagination :pagination="paginationCompu" @paging="pagingSend" ></dash-pagination>
                        </div>
                        <!-- Card footer END -->
                    </div>
                    <!-- Edit profile END -->
                    
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
    </div>
</template>

<script>
var filename__= "[pages > companydash > CompanyDashCourseWorkerAddList.vue] "
import axios from 'axios';

import CompanyDashboardTop from '../../components/companydash/CompanyDashboardTop.vue';
import CompanyDashboardLeft from '../../components/companydash/CompanyDashboardLeft.vue';
import CompanyDashCourseWorkerAddListComponent from '../../components/companydash/CompanyDashCourseWorkerAddListComponent.vue';
import CompanyDashPagination from '../../components/ui/CompanyDashPagination.vue';

export default {
    components: {
        "company-dashboard-top": CompanyDashboardTop,
        "company-dashboard-left": CompanyDashboardLeft,
        "companydash-course-worker-add-list-component" : CompanyDashCourseWorkerAddListComponent,
        "dash-pagination": CompanyDashPagination,
    },
    props: ['content_id','company_id'],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,       
            
            itemList: [], 
            page:1,
            // current_path:"/instructordash/requests",  //current_path
			pagination: null, 

            inputPagePerCount : 10,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        this.getList();       
    },    
    computed: {
        backToLink(){
            return '/companydash/blog/'+this.content_id ;
        },
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        paginationCompu(){
            return this.pagination ;
        },

    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            // console.log("[InstructorDashSettingUpdate.vue] isAuth():", this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUserId(){
            // console.log("[InstructorDashSettingUpdate.vue] isUser():", this.$store.getters['auth/userId']);
            return this.$store.getters['auth/userId'] ;
        },

        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        sendRefreshForm(){
            this.$router.replace(this.backToLink);
        },
        async getList(){
            let funcname__ ="getList()" ;

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }

            // if( this.inputSearch === 'undefinded' || this.inputSearch === '') { 
            //     this.inputSearch = '' ; //pagination, search 에서 계속작동하기 위해서                 
            // }             

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/worker/add/all/companydash/'+this.isUserId(),
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                }
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                // console.log('[InstructorDashRequestList.vue] profile:', data.profile);
                
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
                for(let item of tmpList ){
                    list.push({
                        num: counter--, 
                        id: item.id, 
                        user_id: item.create_id,
                        user_name: item.create_name,
                        user_email: item.create_email,
                        user_photo : item.create_photo,
                        is_active:item.is_active,
                        status:item.status,
                        date: item.date
                    });
                }

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.itemList = list;

                // console.log(filename__+''+funcname__+" itemList:", this.itemList);
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },


    }
}
</script>

<style scoped>

</style>