import { createRouter, createWebHistory} from 'vue-router';

// 참고자료(라우터) : https://router.vuejs.org/installation.html

import TheMain from './pages/main/TheMain.vue';

//************** Auth  */
import Signin from './pages/auth/Signin.vue';   // const Signin = () => import('./pages/auth/Signin.vue') ;
import Signup from './pages/auth/Signup.vue';
import SignupSector from './pages/auth/SignupSector.vue';
import ForgotPw from './pages/auth/ForgotPw.vue';  // const ForgotPw = () => import('./pages/auth/ForgotPw.vue') ;


//************** Dashboard  */
import DashboardAdmin from './pages/admin/DashboardAdmin.vue';

//************** Dashboard > Member */
// import AdminMemberList from './pages/admin/member/AdminMemberList.vue';
// import AdminMemberDetail from './pages/admin/member/AdminMemberDetail.vue';
// import AdminMemberUpdate from './pages/admin/member/AdminMemberUpdate.vue';

// import AdminCourseList from './pages/admin/course/AdminCourseList.vue';
// import AdminCourseDetail from './pages/admin/course/AdminCourseDetail.vue';
// import AdminCourseActiveUpdate from './pages/admin/course/AdminCourseActiveUpdate.vue';

// import AdminStudentList from './pages/admin/student/AdminStudentList.vue';

const AdminMemberList = () => import('./pages/admin/member/AdminMemberList.vue') ;
const AdminMemberDetail = () => import('./pages/admin/member/AdminMemberDetail.vue') ;
const AdminMemberUpdate = () => import('./pages/admin/member/AdminMemberUpdate.vue') ;

// 탈퇴회원 
const AdminMemberOutList = () => import('./pages/admin/member/AdminMemberOutList.vue') ;

/************** Dashboard > 직종 및 지역  */
import AdminSectorList from './pages/admin/sector/AdminSectorList.vue' ;
import AdminSectorCreate from './pages/admin/sector/AdminSectorCreate.vue' ;
import AdminSectorUpdate from './pages/admin/sector/AdminSectorUpdate.vue' ;

import AdminSubSectorList from './pages/admin/sector/AdminSubSectorList.vue' ;
import AdminSubSectorCreate from './pages/admin/sector/AdminSubSectorCreate.vue' ;
import AdminSubSectorUpdate from './pages/admin/sector/AdminSubSectorUpdate.vue' ;

import AdminLocationList from './pages/admin/sector/AdminLocationList.vue' ;
import AdminLocationCreate from './pages/admin/sector/AdminLocationCreate.vue' ;
import AdminLocationUpdate from './pages/admin/sector/AdminLocationUpdate.vue' ;



const AdminCourseList = () => import('./pages/admin/course/AdminCourseList.vue') ;
const AdminCourseDetail = () => import('./pages/admin/course/AdminCourseDetail.vue') ;
const AdminCourseActiveUpdate = () => import('./pages/admin/course/AdminCourseActiveUpdate.vue') ;

const AdminStudentList = () => import('./pages/admin/student/AdminStudentList.vue') ;

/************** Dashboard > category  / */

import AdminCourseCategoryList from './pages/admin/coursecategory/AdminCourseCategoryList.vue';
import AdminCourseCategoryCreate from './pages/admin/coursecategory/AdminCourseCategoryCreate.vue';
import AdminCourseCategoryUpdate from './pages/admin/coursecategory/AdminCourseCategoryUpdate.vue';

/************** Dashboard > 코스 관리  */
import AdminCourseRecommendCategoryList from './pages/admin/courserecommend/AdminCourseRecommendCategoryList.vue';
import AdminCourseRecommendCategoryCreate from './pages/admin/courserecommend/AdminCourseRecommendCategoryCreate.vue';
import AdminCourseRecommendCategoryUpdate from './pages/admin/courserecommend/AdminCourseRecommendCategoryUpdate.vue';

import AdminCourseRecommendList from './pages/admin/courserecommend/AdminCourseRecommendList.vue';
import AdminCourseRecommendAddList from './pages/admin/courserecommend/AdminCourseRecommendAddList.vue';

/************** Dashboard > Instructor / */
import AdminCompanyList from './pages/admin/company/AdminCompanyList.vue';
import AdminCompanyDetail from './pages/admin/company/AdminCompanyDetail.vue';
import AdminCompanyStatusUpdate from './pages/admin/company/AdminCompanyStatusUpdate.vue';

//************** Dashboard > Instructor / Request  */
import AdminCompanyRequestList from './pages/admin/company/AdminCompanyRequestList.vue';
import AdminCompanyRequestDetail from './pages/admin/company/AdminCompanyRequestDetail.vue';
import AdminCompanyRequestReplyCreate from './pages/admin/company/AdminCompanyRequestReplyCreate.vue';
import AdminCompanyRequestReplyUpdate from './pages/admin/company/AdminCompanyRequestReplyUpdate.vue';
import AdminCompanyRequestStatusUpdate from './pages/admin/company/AdminCompanyRequestStatusUpdate.vue';

//************** Dashboard > Instructor / Setting  */
import AdminCompanyCreate from './pages/admin/company/AdminCompanyCreate.vue';

//************** Dashboard > course comment  */
import AdminCourseCommentList from './pages/admin/course/AdminCourseCommentList.vue';
import AdminCourseCommentDetail from './pages/admin/course/AdminCourseCommentDetail.vue';

//************** Dashboard > course review  */
import AdminCourseReviewList from './pages/admin/course/AdminCourseReviewList.vue';
import AdminCourseReviewDetail from './pages/admin/course/AdminCourseReviewDetail.vue';

//************** Dashboard > Blogs and News */
import AdminBlogList from './pages/admin/blog/AdminBlogList.vue';
import AdminBlogCreate from './pages/admin/blog/AdminBlogCreate.vue';
import AdminBlogDetail from './pages/admin/blog/AdminBlogDetail.vue';
import AdminBlogUpdate from './pages/admin/blog/AdminBlogUpdate.vue';
import AdminBlogDisplayUpdate from './pages/admin/blog/AdminBlogDisplayUpdate.vue';

//************** Dashboard > Contactus */
import AdminContactusList from './pages/admin/faq/AdminContactusList.vue';
import AdminContactusDetail from './pages/admin/faq/AdminContactusDetail.vue';
import AdminContactusReplyCreate from './pages/admin/faq/AdminContactusReplyCreate.vue';
import AdminContactusReplyUpdate from './pages/admin/faq/AdminContactusReplyUpdate.vue';
import AdminContactusStatusUpdate from './pages/admin/faq/AdminContactusStatusUpdate.vue';

//************** Dashboard > Faq */
import AdminFaqList from './pages/admin/faq/AdminFaqList.vue';
import AdminFaqDetail from './pages/admin/faq/AdminFaqDetail.vue';
import AdminFaqReplyCreate from './pages/admin/faq/AdminFaqReplyCreate.vue';
import AdminFaqReplyUpdate from './pages/admin/faq/AdminFaqReplyUpdate.vue';
import AdminFaqStatusUpdate from './pages/admin/faq/AdminFaqStatusUpdate.vue';

import AdminFaqFrequentList from './pages/admin/faq/AdminFaqFrequentList.vue';
import AdminFaqFrequentCreate from './pages/admin/faq/AdminFaqFrequentCreate.vue';
import AdminFaqFrequentUpdate from './pages/admin/faq/AdminFaqFrequentUpdate.vue';

//************** Dashboard > Notification */
import AdminNotificationList from './pages/admin/notification/AdminNotificationList.vue';
import AdminNotificationDetail from './pages/admin/notification/AdminNotificationDetail.vue';

//************** Dashboard > Forgot Password */
// import AdminForgotpwList from './pages/admin/forgotpw/AdminForgotpwList.vue';
// import AdminForgotpwDetail from './pages/admin/forgotpw/AdminForgotpwDetail.vue';
// import AdminForgotpwReply from './pages/admin/forgotpw/AdminForgotpwReply.vue';
// import AdminForgotpwStatusUpdate from './pages/admin/forgotpw/AdminForgotpwStatusUpdate.vue';


import AdminMainSlideList from './pages/admin/mainslide/AdminMainSlideList.vue';
import AdminMainSlideCreate from './pages/admin/mainslide/AdminMainSlideCreate.vue';
import AdminMainSlideUpdate from './pages/admin/mainslide/AdminMainSlideUpdate.vue';

import AdminSettingList from './pages/admin/setting/AdminSettingList.vue';
import AdminSettingUpdate from './pages/admin/setting/AdminSettingUpdate.vue';
import AdminSettingCreate from './pages/admin/setting/AdminSettingCreate.vue';

import AdminSettingSocialList from './pages/admin/setting/AdminSettingSocialList.vue';
import AdminSettingSocialUpdate from './pages/admin/setting/AdminSettingSocialUpdate.vue';
import AdminSettingSocialCreate from './pages/admin/setting/AdminSettingSocialCreate.vue';

import AdminSettingApproveUpdate from './pages/admin/setting/AdminSettingApproveUpdate.vue';
import AdminSettingEmailUpdate from './pages/admin/setting/AdminSettingEmailUpdate.vue';

//************** Dashboard > Error Log  */
import AdminErrorLogList from './pages/admin/errorlog/AdminErrorLogList.vue';
import AdminErrorLogDetail from './pages/admin/errorlog/AdminErrorLogDetail.vue';
import AdminErrorLogStatusUpdate from './pages/admin/errorlog/AdminErrorLogStatusUpdate.vue';

//************** Dashboard > Plan : 작업   */
import AdminPlanList from './pages/admin/plan/AdminPlanList.vue';
import AdminPlanDetail from './pages/admin/plan/AdminPlanDetail.vue';
import AdminPlanCreate from './pages/admin/plan/AdminPlanCreate.vue';
import AdminPlanUpdate from './pages/admin/plan/AdminPlanUpdate.vue';


import Intro from './pages/intro/Intro.vue';
import MicrolearningList from './pages/intro/MicrolearningList.vue';
import MicrolearningDetail from './pages/intro/MicrolearningDetail.vue';
import ProtectPrivacy from './pages/intro/ProtectPrivacy.vue';

// 정보창고 
import TotalBlogList from './pages/blog/TotalBlogList.vue';
import BlogList from './pages/blog/BlogList.vue';
import BlogDetail from './pages/blog/BlogDetail.vue';


import TotalCourseList from './pages/course/TotalCourseList.vue';
// import TotalCourseDetail from './pages/course/TotalCourseDetail.vue';
// import TotalCourseTopic from './pages/course/TotalCourseTopic.vue';
// import TotalCoursePlayDetail from './pages/course/TotalCoursePlayDetail.vue';
import CourseList from './pages/course/CourseList.vue';
import CourseDetail from './pages/course/CourseDetail.vue';
// import CourseDetailExt from './pages/course/CourseDetailExt.vue';
// import CoursePlayDetail from './pages/course/CoursePlayDetail.vue';

import TopmenuCourseList from './pages/course/TopmenuCourseList.vue';
import TopmenuCourseDetail from './pages/course/TopmenuCourseDetail.vue';

// 공동작업자 
import EditCourseList from './pages/courseedit/EditCourseList.vue';
import EditCourseDetail from './pages/courseedit/EditCourseDetail.vue';
import EditCourseUpdate from './pages/courseedit/EditCourseUpdate.vue';
import EditBlogDetail from './pages/courseedit/EditBlogDetail.vue';
import EditBlogUpdate from './pages/courseedit/EditBlogUpdate.vue';

import CompanyList from './pages/company/CompanyList.vue';
import CompanyDetail from './pages/company/CompanyDetail.vue';
import CompanyRequestForm from './pages/company/CompanyRequestForm.vue';

import CompanyDashboard from './pages/companydash/CompanyDashboard.vue';

import CompanyDashCourseList from './pages/companydash/CompanyDashCourseList.vue';
import CompanyDashCourseCreate from './pages/companydash/CompanyDashCourseCreate.vue';
import CompanyDashCourseDetail from './pages/companydash/CompanyDashCourseDetail.vue';
import CompanyDashCourseCardnewsCreate from './pages/companydash/CompanyDashCourseCardnewsCreate.vue';
import CompanyDashCourseUpdate from './pages/companydash/CompanyDashCourseUpdate.vue';

// 정보창고 
import CompanyDashBlogList from './pages/companydash/CompanyDashBlogList.vue';
import CompanyDashBlogCreate from './pages/companydash/CompanyDashBlogCreate.vue';
import CompanyDashBlogDetail from './pages/companydash/CompanyDashBlogDetail.vue';
import CompanyDashBlogUpdate from './pages/companydash/CompanyDashBlogUpdate.vue';

import CompanyDashBlogWorkerAddList from './pages/companydash/CompanyDashBlogWorkerAddList.vue';
import CompanyDashCourseWorkerAddList from './pages/companydash/CompanyDashCourseWorkerAddList.vue';
import CompanyDashCourseWorkerList from './pages/companydash/CompanyDashCourseWorkerList.vue';


import CompanyDashOrderList from './pages/companydash/CompanyDashOrderList.vue';
import CompanyDashStudentList from './pages/companydash/CompanyDashStudentList.vue';
import CompanyDashCommentList from './pages/companydash/CompanyDashCommentList.vue';
import CompanyDashCommentDetail from './pages/companydash/CompanyDashCommentDetail.vue';
import CompanyDashReviewList from './pages/companydash/CompanyDashReviewList.vue';
import CompanyDashReviewDetail from './pages/companydash/CompanyDashReviewDetail.vue';
import CompanyDashWorkerList from './pages/companydash/CompanyDashWorkerList.vue';
import CompanyDashWorkerRequestList from './pages/companydash/CompanyDashWorkerRequestList.vue';
import CompanyDashWorkerRequestUpdate from './pages/companydash/CompanyDashWorkerRequestUpdate.vue';
import CompanyDashNotificationList from './pages/companydash/CompanyDashNotificationList.vue';
import CompanyDashNotificationDetail from './pages/companydash/CompanyDashNotificationDetail.vue';

import CompanyDashSettingUpdate from './pages/companydash/CompanyDashSettingUpdate.vue';
import CompanyDashSettingInfoUpdate from './pages/companydash/CompanyDashSettingInfoUpdate.vue';
import CompanyDashSettingPhotoUpdate from './pages/companydash/CompanyDashSettingPhotoUpdate.vue';
import CompanyDashSettingDisplayUpdate from './pages/companydash/CompanyDashSettingDisplayUpdate.vue';
import CompanyDashSettingCareerUpdate from './pages/companydash/CompanyDashSettingCareerUpdate.vue';
import CompanyDashSettingSkillUpdate from './pages/companydash/CompanyDashSettingSkillUpdate.vue';
import CompanyDashSettingSocialUpdate from './pages/companydash/CompanyDashSettingSocialUpdate.vue';
import CompanyDashSettingWorkerUpdate from './pages/companydash/CompanyDashSettingWorkerUpdate.vue';

import CompanyDashDeleteUpdate from './pages/companydash/CompanyDashDeleteUpdate.vue';

import StudentDashboard from './pages/studentdash/StudentDashboard.vue';
import StudentDashCourseList from './pages/studentdash/StudentDashCourseList.vue';
import StudentDashWishList from './pages/studentdash/StudentDashWishList.vue';
import StudentDashFaqList from './pages/studentdash/StudentDashFaqList.vue';
import StudentDashFaqDetail from './pages/studentdash/StudentDashFaqDetail.vue';
import StudentDashNotificationList from './pages/studentdash/StudentDashNotificationList.vue';
import StudentDashNotificationDetail from './pages/studentdash/StudentDashNotificationDetail.vue';
import StudentDashPhotoUpdate from './pages/studentdash/StudentDashPhotoUpdate.vue';
import StudentDashProfileUpdate from './pages/studentdash/StudentDashProfileUpdate.vue';
import StudentDashPasswordUpdate from './pages/studentdash/StudentDashPasswordUpdate.vue';
import StudentDashSectorUpdate from './pages/studentdash/StudentDashSectorUpdate.vue';
import StudentDashSectorLocationUpdate from './pages/studentdash/StudentDashSectorLocationUpdate.vue';
import StudentDashDeleteUpdate from './pages/studentdash/StudentDashDeleteUpdate.vue';

import Support from './pages/help/Support.vue';
import SupportDetail from './pages/help/SupportDetail.vue';

import Contactus from './pages/faq/Contactus.vue';
import Faq from './pages/faq/Faq.vue';



const RecoverPw = () => import('./pages/auth/RecoverPw.vue') ;
const RecoverExpire = () => import('./pages/auth/RecoverExpire.vue') ;

const MyAccount = () => import('./pages/intro/Intro.vue') ;

const NotFound = () => import('./pages/NotFound.vue') ;

// const SearchList = () => import('./pages/search/SearchList.vue') ;


const Dashboard = () => import('./pages/dashboard/DashBoard.vue') ;

const CmsImageListAdmin = () => import('./pages/admin/cms/CmsImageListAdmin.vue') ;
const CmsImageDetailAdmin = () => import('./pages/admin/cms/CmsImageDetailAdmin.vue') ;
const CmsImageUpdateAdmin = () => import('./pages/admin/cms/CmsImageUpdateAdmin.vue') ;
const CmsMovieListAdmin = () => import('./pages/admin/cms/CmsMovieListAdmin.vue') ;
const CmsMovieDetailAdmin = () => import('./pages/admin/cms/CmsMovieDetailAdmin.vue') ;
const CmsMovieUpdateAdmin = () => import('./pages/admin/cms/CmsMovieUpdateAdmin.vue') ;
const CmsInstallContentListAdmin = () => import('./pages/admin/cms/CmsInstallContentListAdmin.vue') ;
const CmsInstallContentDetailAdmin = () => import('./pages/admin/cms/CmsInstallContentDetailAdmin.vue') ;
const CmsInstallContentUpdateAdmin = () => import('./pages/admin/cms/CmsInstallContentUpdateAdmin.vue') ;


const router = createRouter({
    history: createWebHistory(),
    routes: [

        {   path: '/', component: TheMain },

        {   path: '/signin', component: Signin },
        {   path: '/signup', component: Signup },
        {   path: '/signup/sector', component: SignupSector },
        {   path: '/forgotpw', component: ForgotPw },



        {   path: '/admin', component: DashboardAdmin },

        {   path: '/admin/members', component: AdminMemberList },
        {   path: '/admin/member/:id', component: AdminMemberDetail , props: true },
        {   path: '/admin/member/update/:id', component: AdminMemberUpdate , props: true },


        {   path: '/admin/members/out', component: AdminMemberOutList },

        // 관리자 : 직종 및 지역 
        {   path: '/admin/sector/sectors', component: AdminSectorList },
        {   path: '/admin/sector/sector/add', component: AdminSectorCreate },
        {   path: '/admin/sector/sector/update/:id', component: AdminSectorUpdate , props: true },

        {   path: '/admin/sector/subsectors/:parent_id/:parent', component: AdminSubSectorList, props: true },
        {   path: '/admin/sector/subsector/add/:parent_id/:parent', component: AdminSubSectorCreate , props: true },
        {   path: '/admin/sector/subsector/update/:id/:parent_id/:parent', component: AdminSubSectorUpdate , props: true },

        {   path: '/admin/sector/locations', component: AdminLocationList },
        {   path: '/admin/sector/location/add', component: AdminLocationCreate },
        {   path: '/admin/sector/location/update/:id', component: AdminLocationUpdate , props: true },

        //관리자 Course 
        {   path: '/admin/courses', component: AdminCourseList },
        {   path: '/admin/course/:id', component: AdminCourseDetail, props: true },
        {   path: '/admin/course/is_active/:id', component: AdminCourseActiveUpdate, props: true },

        {   path: '/admin/course/categories', component: AdminCourseCategoryList },
        {   path: '/admin/course/category/create', component: AdminCourseCategoryCreate , props: true },
        {   path: '/admin/course/category/update/:id', component: AdminCourseCategoryUpdate , props: true },

        //관리자 : 코스 관리 
        {   path: '/admin/course/recommendcategories/:category', component: AdminCourseRecommendCategoryList, props: true },
        {   path: '/admin/course/recommendcategory/create/:category', component: AdminCourseRecommendCategoryCreate , props: true },
        {   path: '/admin/course/recommendcategory/update/:category/:id', component: AdminCourseRecommendCategoryUpdate , props: true },

        {   path: '/admin/course/recommends/:category_id/:category/:title', component: AdminCourseRecommendList, props: true },
        {   path: '/admin/course/recommend/add/:category_id/:category/:title', component: AdminCourseRecommendAddList, props: true },

        //관리자 : 학생 
        {   path: '/admin/students', component: AdminStudentList },


        //관리자 Instructor Setting
        {   path: '/admin/companies', component: AdminCompanyList },
        {   path: '/admin/company/:id/:company_id', component: AdminCompanyDetail , props: true },
        {   path: '/admin/company/status/update/:id/:company_id', component: AdminCompanyStatusUpdate , props: true },

        //관리자 Instructor Request
        {   path: '/admin/company/requests', component: AdminCompanyRequestList },
        {   path: '/admin/company/request/:id/:create_id', component: AdminCompanyRequestDetail , props: true },
        {   path: '/admin/company/request/reply/create/:id/:create_id', component: AdminCompanyRequestReplyCreate , props: true },
        {   path: '/admin/company/request/reply/update/:id/:parent_id/:create_id', component: AdminCompanyRequestReplyUpdate , props: true },
        {   path: '/admin/company/request/status/update/:id/:create_id', component: AdminCompanyRequestStatusUpdate , props: true },

        //관리자 Instructor Setting
        {   path: '/admin/company/create/:id/:owner_id', component: AdminCompanyCreate , props: true },

        //관리자 Course comment
        {   path: '/admin/course/comments', component: AdminCourseCommentList },
        {   path: '/admin/course/comment/:id', component: AdminCourseCommentDetail, props: true },

        //관리자 Course review
        {   path: '/admin/course/reviews', component: AdminCourseReviewList },
        {   path: '/admin/course/review/:id', component: AdminCourseReviewDetail, props: true },

        //관리자 Blogs and News
        {   path: '/admin/blogs', component: AdminBlogList },
        {   path: '/admin/blog/create', component: AdminBlogCreate },
        {   path: '/admin/blog/:id', component: AdminBlogDetail , props: true },
        {   path: '/admin/blog/update/:id', component: AdminBlogUpdate , props: true },
        {   path: '/admin/blog/display/update/:id', component: AdminBlogDisplayUpdate , props: true },


        //관리자 Contact Us 
        {   path: '/admin/contactuses', component: AdminContactusList },
        {   path: '/admin/contactus/:id', component: AdminContactusDetail , props: true },
        {   path: '/admin/contactus/reply/create/:id', component: AdminContactusReplyCreate , props: true },
        {   path: '/admin/contactus/reply/update/:id/:parent_id', component: AdminContactusReplyUpdate , props: true },
        {   path: '/admin/contactus/status/update/:id', component: AdminContactusStatusUpdate , props: true },

        //관리자 Faq
        {   path: '/admin/faqs', component: AdminFaqList },
        {   path: '/admin/faq/:id', component: AdminFaqDetail , props: true },
        {   path: '/admin/faq/reply/create/:id', component: AdminFaqReplyCreate , props: true },
        {   path: '/admin/faq/reply/update/:id/:parent_id', component: AdminFaqReplyUpdate , props: true },
        {   path: '/admin/faq/status/update/:id', component: AdminFaqStatusUpdate , props: true },

        {   path: '/admin/faqfrequents', component: AdminFaqFrequentList },
        {   path: '/admin/faqfrequent/create', component: AdminFaqFrequentCreate , props: true },
        {   path: '/admin/faqfrequent/update/:id', component: AdminFaqFrequentUpdate , props: true },

        //관리자 Notification
        {   path: '/admin/notifications', component: AdminNotificationList },
        {   path: '/admin/notification/:id', component: AdminNotificationDetail , props: true },
        
        //관리자 Forgot Password
        // {   path: '/admin/forgotpws', component: AdminForgotpwList },
        // {   path: '/admin/forgotpw/:id', component: AdminForgotpwDetail , props: true },
        // {   path: '/admin/forgotpw/reply/:id', component: AdminForgotpwReply , props: true },
        // {   path: '/admin/forgotpw/status/update/:id', component: AdminForgotpwStatusUpdate , props: true },

        {   path: '/admin/mainslide', component: AdminMainSlideList },
        {   path: '/admin/mainslide/create/:category', component: AdminMainSlideCreate , props: true },
        {   path: '/admin/mainslide/update/:id/:category', component: AdminMainSlideUpdate , props: true },
        

        {   path: '/admin/settings', component: AdminSettingList },
        {   path: '/admin/setting/:id', component: AdminSettingUpdate , props: true },
        {   path: '/admin/setting/create', component: AdminSettingCreate },

        {   path: '/admin/setting/socials', component: AdminSettingSocialList },
        {   path: '/admin/setting/social/:id', component: AdminSettingSocialUpdate , props: true },
        {   path: '/admin/setting/social/create', component: AdminSettingSocialCreate },

        {   path: '/admin/setting/approve/update', component: AdminSettingApproveUpdate  },
        {   path: '/admin/setting/email/update', component: AdminSettingEmailUpdate  },

        //관리자 Error Log
        {   path: '/admin/errorlogs', component: AdminErrorLogList },
        {   path: '/admin/errorlog/:id', component: AdminErrorLogDetail , props: true },
        {   path: '/admin/errorlog/status/update/:id', component: AdminErrorLogStatusUpdate , props: true },

        //관리자 plan
        {   path: '/admin/plans', component: AdminPlanList },
        {   path: '/admin/plan/create', component: AdminPlanCreate , props: true },
        {   path: '/admin/plan/:id', component: AdminPlanDetail , props: true },
        {   path: '/admin/plan/update/:id', component: AdminPlanUpdate , props: true },


        {   path: '/intro', component: Intro },
        {   path: '/microlearning', component: MicrolearningList },
        {   path: '/microlearning/detail', component: MicrolearningDetail },
        {   path: '/protectprivacy', component: ProtectPrivacy },

        // 정보창고 
        {   path: '/totalblogs', component: TotalBlogList },
        {   path: '/blogs/:coursecategory/:category_title', component: BlogList , props: true },
        {   path: '/blog/:id', component: BlogDetail , props: true },

        // 강좌 
        {   path: '/totalcourses', component: TotalCourseList },
        // {   path: '/totalcourse/:id', component: TotalCourseDetail , props: true },
        // {   path: '/totalcourse/topic/:course_id/:id', component: TotalCourseTopic , props: true },
        // {   path: '/totalcourse/play/:course_id/:topic_id', component: TotalCoursePlayDetail , props: true },
        {   path: '/courses/:coursecategory/:category_title', component: CourseList , props: true },
        {   path: '/course/:id', component: CourseDetail , props: true },
        // {   path: '/course/detail/play', component: CoursePlayDetail },
        {   path: '/topmenucourses/:parent_category/:category_id/:category_title', component: TopmenuCourseList , props: true },
        {   path: '/topmenucourse/:id/:parent_category/:category_id/:category_title', component: TopmenuCourseDetail , props: true },

        // 코스별 공동 작업 
        {   path: '/editcourses', component:EditCourseList , props: true },
        {   path: '/editcourse/:id', component: EditCourseDetail , props: true },
        {   path: '/editcourse/update/:id', component: EditCourseUpdate , props: true },
        {   path: '/editblog/:id', component: EditBlogDetail , props: true },
        {   path: '/editblog/update/:id', component: EditBlogUpdate , props: true },


        {   path: '/companies', component: CompanyList },
        {   path: '/company/:id/:company_id', component: CompanyDetail , props: true },
        {   path: '/company/request', component: CompanyRequestForm },

        // company dashboard 
        {   path: '/companydash/dashboard', component: CompanyDashboard },

        {   path: '/companydash/courses', component: CompanyDashCourseList },
        {   path: '/companydash/course/create', component: CompanyDashCourseCreate },
        {   path: '/companydash/course/:id', component: CompanyDashCourseDetail , props: true },
        {   path: '/companydash/course/cardnews/create/:content_id/:lecture_id', component: CompanyDashCourseCardnewsCreate , props: true },
        {   path: '/companydash/course/update/:id', component: CompanyDashCourseUpdate , props: true },

        // company 정보창고 
        {   path: '/companydash/blogs', component: CompanyDashBlogList },
        {   path: '/companydash/blog/create', component: CompanyDashBlogCreate },
        {   path: '/companydash/blog/:id', component: CompanyDashBlogDetail , props: true },
        {   path: '/companydash/blog/update/:id', component: CompanyDashBlogUpdate , props: true },

        // course : worker 
        {   path: '/companydash/blog/worker/add/list/:content_id/:company_id', component: CompanyDashBlogWorkerAddList , props: true },
        {   path: '/companydash/course/worker/add/list/:content_id/:company_id', component: CompanyDashCourseWorkerAddList , props: true },
        {   path: '/companydash/course/workers', component: CompanyDashCourseWorkerList , props: true },


        {   path: '/companydash/orders', component: CompanyDashOrderList },
        {   path: '/companydash/students', component: CompanyDashStudentList },
        {   path: '/companydash/comments', component: CompanyDashCommentList },
        {   path: '/companydash/comment/:id', component: CompanyDashCommentDetail , props: true },
        {   path: '/companydash/reviews', component: CompanyDashReviewList },
        {   path: '/companydash/review/:id', component: CompanyDashReviewDetail , props: true },
        {   path: '/companydash/workers', component: CompanyDashWorkerList },
        {   path: '/companydash/worker/requests', component: CompanyDashWorkerRequestList },
        {   path: '/companydash/worker/request/:id', component: CompanyDashWorkerRequestUpdate , props: true },
        {   path: '/companydash/notifications', component: CompanyDashNotificationList },
        {   path: '/companydash/notification/:id', component: CompanyDashNotificationDetail, props: true },
        {   path: '/companydash/setting', component: CompanyDashSettingUpdate },
        {   path: '/companydash/setting/info/update', component: CompanyDashSettingInfoUpdate },
        {   path: '/companydash/setting/photo/update', component: CompanyDashSettingPhotoUpdate },
        {   path: '/companydash/setting/display/update', component: CompanyDashSettingDisplayUpdate },
        {   path: '/companydash/setting/career/update', component: CompanyDashSettingCareerUpdate },
        {   path: '/companydash/setting/skill/update', component: CompanyDashSettingSkillUpdate },
        {   path: '/companydash/setting/social/update', component: CompanyDashSettingSocialUpdate },
        {   path: '/companydash/setting/worker/update', component: CompanyDashSettingWorkerUpdate },
        {   path: '/companydash/delete', component: CompanyDashDeleteUpdate },

        // student dashboard 
        {   path: '/studentdash/dashboard', component: StudentDashboard },
        {   path: '/studentdash/courses', component: StudentDashCourseList },
        {   path: '/studentdash/wishlists', component: StudentDashWishList },
        {   path: '/studentdash/faqs', component: StudentDashFaqList },
        {   path: '/studentdash/faq/:id', component: StudentDashFaqDetail , props: true },
        {   path: '/studentdash/notifications', component: StudentDashNotificationList },
        {   path: '/studentdash/notification/:id', component: StudentDashNotificationDetail , props: true },
        {   path: '/studentdash/photo/update', component: StudentDashPhotoUpdate },
        {   path: '/studentdash/profile/update', component: StudentDashProfileUpdate },
        {   path: '/studentdash/profile/pwupdate', component: StudentDashPasswordUpdate },
        {   path: '/studentdash/sector/update', component: StudentDashSectorUpdate },
        {   path: '/studentdash/sector/location/update', component: StudentDashSectorLocationUpdate },
        {   path: '/studentdash/profile/delete', component: StudentDashDeleteUpdate },


        {   path: '/support', component: Support },
        {   path: '/support/detail', component: SupportDetail },
        
        {   path: '/contactus', component: Contactus },
        {   path: '/faqs', component: Faq },
        
        // {   path: '/search', component: SearchList },


        // ok
        // {   path: '/signup', component: Signup },
        // {   path: '/login', component: Login },

        {   path: '/recoverpw', component: RecoverPw },
        {   path: '/recoverexpire', component: RecoverExpire },

        {   path: '/account/myaccount', component: MyAccount },

        //**************Dashboard  */
        {   path: '/dashboard', component: Dashboard },

        {   path: '/admin/cms/image', component: CmsImageListAdmin },
        {   path: '/admin/cms/image/detail', component: CmsImageDetailAdmin },
        {   path: '/admin/cms/image/update', component: CmsImageUpdateAdmin },
        {   path: '/admin/cms/movie', component: CmsMovieListAdmin },
        {   path: '/admin/cms/movie/detail', component: CmsMovieDetailAdmin },
        {   path: '/admin/cms/movie/update', component: CmsMovieUpdateAdmin },
        {   path: '/admin/cms/installcontent', component: CmsInstallContentListAdmin },
        {   path: '/admin/cms/installcontent/detail', component: CmsInstallContentDetailAdmin },
        {   path: '/admin/cms/installcontent/update', component: CmsInstallContentUpdateAdmin },


        // Dashboard > Members 관리 

        {   path: '/:notFound(.*)', component: NotFound },


    ],
});

export default router ;