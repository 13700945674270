<template>
<div>
    <div class="rounded-3">
        <!-- Image -->
        <img v-if="companyinfo.photo" :src="companyinfo.photo" class="card-img img-thumbnail" alt="course image">
        <img v-else src="/assets/images/avatar/user.png" class="rounded-3" alt="...">
    </div>

    <!-- Card body -->
    <div class="card-body px-3">
        <!-- Rating -->
        <!-- <ul class="list-inline">
            <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
            <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
            <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
            <li class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
            <li class="list-inline-item me-0 small"><i class="fas fa-star-half-alt text-warning"></i></li>
            <li class="list-inline-item ms-2 h6 fw-light mb-0">4.5/5.0</li>
        </ul> -->
        <!-- Social media button -->
        <ul class="list-inline mb-0">
            <company-detail-social-list-component v-for="item in socials"
                :key="item.id"
                :id="item.id"
                :name="item.name"
                :url="item.url"
                />	                            
        </ul>
    </div>
</div>    
</template>

<script>

import CompanyDetailSocialListComponent from './CompanyDetailSocialListComponent.vue';

export default {
    components: {
        "company-detail-social-list-component": CompanyDetailSocialListComponent,
    },
    props: [
        'companyinfo','socials'
    ],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
        }
    },
    created(){
        
    },    
    computed: {
    },
    methods: {
        handleError(){
            this.error = null;
        },

    },
    
}
</script>
<style scoped>

</style> 