<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>


    <main>
	
    <!-- =======================
    Page Banner START -->
    <student-dashboard-top :title="'My WishLists'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <student-dashboard-left :menu="'wishlist'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
    
                    <div class="card bg-transparent border rounded-3">
                        <!-- Card header START -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="mb-0">WishList</h3>
                        </div>
                        <!-- Card header END -->
    
                        <!-- Card body START -->
                        <div class="card-body p-3 p-md-4">
                            <div class="row g-4">
                                
                                <studentdash-wishlist-component v-for="item in itemList"
                                    :key = "item.id"
                                    :id ="item.id"
                                    :num ="item.num"
                                    :content_id="item.content_id"
                                    :company_id="item.company_id"
                                    :title ="item.title"
                                    :subtitle="item.subtitle"
                                    :level ="item.level"
                                    :coursetime ="item.coursetime"
                                    :lecturecount ="item.lecturecount"

                                    :image ="item.image"
                                    :is_text ="item.is_text"

                                    :date ="item.date"
                                    :onoff="item.onoff"
                                    :reviewavg="item.reviewavg"
                                    :categorystr="item.categorystr"

                                    :page="page" 
                                    @changeList="onEmitChangeList"

                                    
                                />            
                            </div>

                            <div v-if="itemList.length > 0" class="mt-3"> 
                                <user-pagination :pagination="paginationCompu" @paging="pagingSend" />
                            </div>

                        </div>

                        <!-- Card body EMD -->
                    </div>  
                </div>
                <!-- Main content END -->
            </div> <!-- Row END --> 
        </div>	
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
        
    <hr/>
    </div>
</template>

<script>
var filename__= "[pages > studentdash > StudentDashWishList.vue] "

import axios from 'axios';

import StudentDashboardTop from '../../components/studentdash/StudentDashboardTop.vue';
import StudentDashboardLeft from '../../components/studentdash/StudentDashboardLeft.vue';

import StudentDashWishListComponent from '../../components/studentdash/StudentDashWishListComponent.vue';
import UserPagination from '../../components/ui/UserPagination.vue';

export default {
    components: {
        "student-dashboard-top": StudentDashboardTop,
        "student-dashboard-left": StudentDashboardLeft,
        "studentdash-wishlist-component":StudentDashWishListComponent,
        "user-pagination": UserPagination,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList: [], 
            page:1,
            current_path:"/studentdash/wishlists",  //current_path
			pagination: null, 

            inputPagePerCount : 9,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',     

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.getList();        
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    }, 
    computed: {
        paginationCompu(){
            return this.pagination ;
        },

    },    
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
		onEmitChangeList(){
			this.getList()
		},

        async getList(){
            let funcname__ ="getList()" ;

            if( this.page === 'undefinded' || this.page == '' || this.page == null) { 
                this.page = 1
            }

            // if( this.inputSearch === 'undefinded' || this.inputSearch === '') { 
            //     this.inputSearch = '' ; //pagination, search 에서 계속작동하기 위해서                 
            // }             

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

            let serverData = await axios({
                method: 'get',
                url: '/api/student/wishlist/all/auth',
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                
                params: { 
                    page: this.page , 
                    page_per_count : this.inputPagePerCount,
                    block_size : this.inputBlockSize,
                    // search: this.inputSearch,       
                }
            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                this.isLoading = false ; 
                return ;
            }
            const data = serverData.data ;

            // console.log(filename__+''+funcname__+" data:", data);

            if( typeof data !== "undefined" &&  data.status_code == 200 ){
                
                this.pagination = data.response.pagination ;
                let tmpList = data.response.data ;
                let counter = data.response.pagination.counter ;
                let list = [];


                //메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					list.push({
						num: counter--, 
						id: item.id, 

						content_id: item.content_id,
						company_id: item.company_id,

						title: item.course_title,
						subtitle: item.course_subtitle,
						level : item.course_level,
                        coursetime : item.coursetime,
                        lecturecount : item.lecturecount,
						reviewavg : item.reviewavg,
						categorystr: item.categorystr,

                        image : item.course_image,
                        is_text : item.is_fronttext,

                        date : item.date,

					});
				}

                // console.log(filename__+''+funcname__+" tmpList:", tmpList);
                this.itemList = list;

                console.log(filename__+''+funcname__+" itemList:", this.itemList);
                // console.log(filename__+''+funcname__+" pagination:", this.pagination);

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                            
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

            this.isLoading = false ;            
        },

    }
}
</script>

<style scoped>

</style>