<template>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <tr>
        <!-- Table data -->
        <td>{{num}}</td>

        <td>
            <div class="d-flex align-items-center">
                <!-- Image -->
                <div class="w-60px">                    
                    <img v-if="course_image" :src="course_image" class="rounded" alt="">
                    <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" alt="">
                </div>
                <div class="mb-0 ms-2">
                    <p>{{course_title}}</p>
                </div>
            </div>
        </td>   
        <td>
            <router-link :to="detailLink" >
                <p v-if="is_display" class="text-body text-truncate-2">{{contentSlice30}}</p>
                <p v-else class="text-body text-truncate-2 text-decoration-line-through"><i class="fas fa-eye-slash me-3"></i>{{contentSlice30}}</p>
            </router-link>
            <!-- <div v-for="item in replylist" :key="item.id">
                <i class="fas fa-reply me-2"></i><span>{{ replySlice20(item.content) }}</span>
            </div> -->
        </td>
        <td>
            <ul class="list-inline mb-0">
                <li v-if="review >= 1" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>

                <li v-if="review >= 2" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                <li v-if="review >= 3" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                <li v-if="review >= 4" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

                <li v-if="review == 5" class="list-inline-item me-0"><i class="fas fa-star text-warning"></i></li>
                <li v-else class="list-inline-item me-0"><i class="far fa-star text-warning"></i></li>

            </ul>
        </td>

        <td>
            <div class="d-flex align-items-center position-relative">
                <!-- Image -->
                <div class="avatar avatar-xs mb-2 mb-md-0">
                    <img v-if="create_photo" :src="create_photo" class="rounded-circle" alt="">
                    <img v-else src="/assets/images/avatar/user.png" class="rounded-circle" alt="">
                </div>
                <div class="mb-0 ms-2">
                    <!-- Title -->
                    <h6 class="mb-0">
                        <router-link :to="`/admin/member/${create_id}`" class="stretched-link">
                            {{create_name}}
                        </router-link>
                    </h6>
                </div>
            </div>
        </td>
        <td>
            <div class="d-flex align-items-center">
                <!-- Image -->
                <div class="w-60px">                    
                    <img v-if="company_image" :src="company_image" class="rounded" alt="">
                    <img v-else src="/assets/images/element/gallery.svg" class="rounded h-50px" alt="">
                </div>
                <div class="mb-0 ms-2">
                    <p>{{company_name}}</p>
                </div>
            </div>
        </td>   

        <td>{{date}}</td>
        <td>
            <button v-if="status == 0" type="button" class="btn btn-danger-soft me-1 mb-1 mb-lg-0">미처리</button>
            <button v-if="status == 2" type="button" class="btn btn-primary-soft me-1 mb-1 mb-lg-0">진행중</button>
            <button v-if="status == 8" type="button" class="btn btn-warning-soft me-1 mb-1 mb-lg-0">보류중</button>
            <button v-if="status == 9" type="button" class="btn btn-success-soft me-1 mb-1 mb-lg-0">처리완료</button>
            <button v-if="status == 10" type="button" class="btn btn-success-soft me-1 mb-1 mb-lg-0">Pass</button>
        </td>
        <td>
            <div class="form-check form-switch form-check-md">
                <input class="form-check-input" type="checkbox" role="switch" id="profilePublic" checked="" v-model="inputIsDisplay" @click="onChangeDisplay" />
                <label v-if="is_display" class="form-check-label" for="profilePublic">보임 </label>
                <label v-else class="form-check-label" for="profilePublic">숨김</label>
            </div>
        </td>

        <!-- Table data -->
        <td>
            <!-- <a href="#" class="btn btn-success-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Edit">
                <i class="bi bi-pencil-square"></i>
            </a>
            <button class="btn btn-danger-soft btn-round me-1 mb-1 mb-md-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Delete">
                <i class="bi bi-trash"></i>
            </button> -->
            <router-link :to="detailLink" class="btn btn-sm btn-info-soft mb-0" >View</router-link>
        </td>
    </tr>

</template>

<script>
var filename__="[components > admin > course > AdminCourseReviewListComponent.vue]" ;

import axios from 'axios';

export default {
    components: {},
    props: [
        'id','num',
        'create_id','create_name','create_photo',
        'company_id', 'company_name','company_image',
        'content_id',
        'course_id','course_title','course_image',
        'description','review','reply',
        'is_display','status',
        'date','update',
        'page', 'review_count'
    ],
    data() {
        return {
           error: null,     
           dialogTitle: null,  

           inputIsDisplay: this.is_display,
        }
    },
    
    computed: {
        detailLink(){
            // return '/admin/course/review/'+this.id+'?page='+this.$route.query.page+"&review_count="+this.$route.query.review_count
            return '/admin/course/review/'+this.id+'?page='+this.page+"&review_count="+this.review_count
        },
        contentSlice30(){
            if(this.description.length > 30) {
                return this.description.slice(0,30)+"...."; 
            } else {
                return this.description ;
            }
            
        },
    },
    methods: {
        onChangeDisplay(){
            this.inputIsDisplay = !this.inputIsDisplay ;
            this.sendDisplayForm();
        },
        // 정보 업데이트  
        async sendDisplayForm(){
            let funcname__ ="sendDisplayForm()" ;

            let dataForm = { 
                'is_display': this.inputIsDisplay,
                'content_id': this.content_id,  //별점 재계산을 위해서 
            } ;  

            // console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/comment/review/display/'+this.id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                     
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 
                                this.$emit('sendRefreshForm');
                            }, 500);
                        }

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                                
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }

            } catch(error){
                // console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="변경 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

    },
    
}
</script>