<template>
    <div>

    <!-- Sidebar START -->
    <!-- Sidebar END -->
    
    <!-- Page content START -->
	
	<!-- Top bar START -->
	<!-- Top bar END -->

	<!-- Page main content START -->
	<div class="page-content-wrapper border">

		<!-- Title -->
		<div class="row mb-3">
			<div class="col-12 d-sm-flex justify-content-between align-items-center">
				<h1 class="h3 mb-2 mb-sm-0">Add a Course</h1>
				<router-link :to="backToLink" class="btn btn-sm btn-secondary mb-0">Back</router-link>
			</div>
		</div>

		<!-- Card START -->
		<div class="card bg-transparent border">

			<!-- Card header START -->
			<div class="card-header bg-light border-bottom">
				<!-- Search and select START -->
				<div class="row g-3 align-items-center justify-content-between">
					<!-- Search bar -->
					<div class="col-md-8">
						<form @submit.prevent="onChangeSearch" class="rounded position-relative">
							<input class="form-control bg-body" type="search" placeholder="Search" aria-label="Search" v-model.trim="inputSearch">
							<button type="submit" class="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" >
								<i class="fas fa-search fs-6 "></i>
							</button>
						</form>
					</div>

					<!-- Select option -->
					<div class="col-md-3 d-grid">
						<!-- Short by filter -->
						<!-- <form>
							<select class="form-select js-choice border-0 z-index-9" aria-label=".form-select-sm">
								<option value="">Sort by</option>
								<option>Newest</option>
								<option>Oldest</option>
								<option>Accepted</option>
								<option>Rejected</option>
							</select>
						</form> -->
						<button type="button" @click="onChangeAll" class="btn btn-primary mb-0">All</button>
					</div>
				</div>
				<!-- Search and select END -->
			</div>
			<!-- Card header END -->

			<!-- Card body START -->
			<div class="card-body">
				<!-- Course table START -->
				<div class="table-responsive border-0 rounded-3">
					<!-- Table START -->
					<table class="table table-dark-gray align-middle p-4 mb-0 table-hover">
						<!-- Table head -->
						<thead>
							<tr>
								<th scope="col" class="border-0 rounded-start">Num</th>
								<th scope="col" class="border-0">Course Name</th>
								<th scope="col" class="border-0">카테고리</th>
								<th scope="col" class="border-0">Company</th>
								<!-- <th scope="col" class="border-0">On/Off</th> -->
								<th scope="col" class="border-0">Type</th>
								<!-- <th scope="col" class="border-0">Price</th> -->
								<th scope="col" class="border-0">Rating</th>
								<th scope="col" class="border-0">Enrolled</th>
								<th scope="col" class="border-0">Updated Date</th>
								<th scope="col" class="border-0 rounded-end">Add</th>
							</tr>
						</thead>
						
						<!-- Table body START -->
						<tbody>
							<admin-course-recommend-list-component v-for="item in itemList"
								:key = "item.id"
								:id ="item.id"
								:num ="item.num"
								
								:categorystr ="item.categorystr"
								:title ="item.title"
								:image ="item.image"
								:level ="item.level"

								:coursetime ="item.coursetime"
								:lecturecount ="item.lecturecount"
								:category ="item.category"

								:date ="item.date"
								:update ="item.update"
								:is_active="item.is_active"
								:is_display="item.is_display"
								:onoff="item.onoff"
								:reviewavg="item.reviewavg"

								:create_id = "item.create_id" 
								:create_name = "item.create_name" 
								:create_email = "item.create_email" 
								:create_photo = "item.create_photo" 

								:company_name = "item.company_name" 
								:company_email = "item.company_email" 
								:company_photo = "item.company_photo" 

								:enroll_count="item.enroll_count"

								:page="page" 
								:search="inputSearch" 
								@changeRefreshList="emitRefreshList"


								:recommendcategory_id="category_id"
								:recommendcategory="category"
								:recommend_title="title"
								
							/>
							
						</tbody>
						<!-- Table body END -->
					</table>
					<!-- Table END -->
				</div>
				<!-- Course table END -->
			</div>
			<!-- Card body END -->

			<!-- Card footer START -->
			<div v-if="itemList.length > 0"> 
				<admin-pagination :pagination="paginationCompu" @paging="pagingSend" />
			</div>
			<!-- Card footer END -->
		</div>
		<!-- Card END -->
	</div>
	<!-- Page main content END -->

    <!-- Page content END -->
    
    </div>
</template>

<script>
var filename__ ="[pages > admin > courserecommend > AdminCourseRecommendAddList.vue]"

import axios from 'axios';

import AdminCourseRecommendAddListComponent from '../../../components/admin/courserecommend/AdminCourseRecommendAddListComponent.vue' ;
import AdminPagination from '../../../components/admin/ui/AdminPagination.vue';


export default {
    components: {
        "admin-course-recommend-list-component": AdminCourseRecommendAddListComponent,
        "admin-pagination": AdminPagination,
    },
    props: ['category_id','category','title'],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,       
            
            itemList : [],
            page:1,
            current_path:"/admin/course/recommen/add",  //current_path
			pagination: null, 

            inputPagePerCount : 50,  // 한 페이지에 보여주는 줄수  
			inputBlockSize : 10,      // 하단 pagination 좌우 블록수     

            inputSearch : '',     

        }
    },
    beforeMount() {
        this.$store.dispatch('auth/pageAdmin'); //관리자 페이지
    },     

    computed: {
		backToLink(){
			return '/admin/course/recommends/'+this.category_id+'/'+this.category+'/'+this.title ; 
		},
        paginationCompu(){
            return this.pagination ;
        },
    },    
    created(){
        if(this.isAuth() && this.isAdmin()){  //로그인 여부 체크 && 관리자 여부 체크 
			this.getList();
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },
    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/');
            }
			if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            return this.$store.getters['auth/userId'] ;
        },
        isAdmin(){
            return this.$store.getters['auth/isAdmin'] ;
        },
        pagingSend(page){
			this.page = page ;
            return this.getList();
        },		
        onChangeSearch(){
			this.page = 1 ;
            this.getList(); //page=1
        },
        onChangeAll(){
			this.page = 1 ;
			this.inputSearch ='' ;
            this.getList(); //page=1
        },
        async getList(){
			let funcname__ ="getList()" ;

			this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 

			let serverData = await axios({
				method: 'get',
				url: '/api/content/course/recommend/add/list',  // course.py
				headers: {
					Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
				},               
                params: { 
                    page: this.page , 
					page_per_count : this.inputPagePerCount,
					block_size : this.inputBlockSize,
                    search: this.inputSearch,       
                }

			}).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          
                                     
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                    return ;
                }

			});


			// console.log(filename__+''+funcname__+" serverData:", serverData);

			if(typeof serverData === "undefined"  ||  serverData.status !== 200){
				this.isLoading = false ; 
				return ;
			}
			const data = serverData.data ;

			// console.log(filename__+''+funcname__+" data:", data);

			if( typeof data !== "undefined" &&  data.status_code == 200 ){
				
				this.pagination = data.response.pagination ;
				let counter = data.response.pagination.total_count ;
				
				let tmpList = data.response.data ;
				let list = [];


				//메인 카테고리 값들을 입력하기 
				for(let item of tmpList ){
					list.push({
						num: counter--, 
						id: item.id, 
						image:item.main_photo ? item.main_photo.main_photo_path : false ,
						title:item.title,
						category:item.category,
						categorystr:item.categorystr,

						seq:item.seq,
						is_active : item.is_active,
						is_display : item.is_display,

						
						level : item.level,
						is_price : item.is_price,
						price : item.price,
						reviewavg : item.review_avg,
						date: item.date,
						update: item.update,

						create_email : item.create_email,
						create_name : item.create_name,
						create_photo : item.create_photo,

						company_email : item.company_email,
						company_name : item.company_name,
						company_photo : item.company_photo,

						enroll_count: item.enroll_count,
						
					});
				}

				// console.log(filename__+''+funcname__+" tmpList:", tmpList);
				this.itemList = list;

				console.log(filename__+''+funcname__+" itemList:", this.itemList);

			} else {
				console.log(filename__+''+funcname__+' err:');

				this.dialogTitle ="실패" ;                    
				this.error = serverData.data.msg;  // this.error = err.msg || 'Failed to authenticate';                
				// alert('error')
			}

			this.isLoading = false ;            
		},


    }
}
</script>

<style scoped>

</style>