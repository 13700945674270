<template>
    <div>

        <main>
	
    <!-- =======================
    Page Banner START -->
    <student-dashboard-top :title="'Edit Profile'" />
    <!-- =======================
    Page Banner END -->
    
    <!-- =======================
    Page content START -->
    <section class="pt-0">
        <div class="container">
            <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
                <p>{{ error }}</p>
            </base-dialog>

            <base-dialog :show="isLoading" title="Loading..." fixed>
                <p>Loading...</p>
                <base-spinner></base-spinner>
            </base-dialog>
                        

            <!-- 삭제 모달  -->
            <teleport to="body">
                <div class="modal fade" id="modalDelPhoto" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                        <div class="modal-header btn-primary-soft">
                            <h1 class="modal-title fs-5" id="modalDelPhoto">Delete Photo</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p>현재의 사진을 삭제하시겠습니까? <br/>삭제된 사진을 복구할 수 없습니다.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-sm btn-success-soft" data-bs-dismiss="modal">Close</button>
                            <button type="button" @click="removePhotoForm" class="btn btn-sm btn-danger-soft" data-bs-dismiss="modal">Delete</button>
                        </div>
                        </div>
                    </div>
                </div>
            </teleport>           

            <div class="row">
    
                <!-- Left sidebar START -->
                <div class="col-xl-3">
                    <!-- Responsive offcanvas body START -->
                    <student-dashboard-left :menu="'photo'" />
                    <!-- Responsive offcanvas body END -->
                </div>
                <!-- Left sidebar END -->
    
                <!-- Main content START -->
                <div class="col-xl-9">
                    <!-- Edit profile START -->
                    <div class="card bg-transparent border rounded-3">
                        <!-- Card header -->
                        <div class="card-header bg-transparent border-bottom">
                            <h3 class="card-header-title mb-0">Edit Profile Photo</h3>
                        </div>
                        <!-- Card body START -->
                        <div class="card-body">
                            <!-- Form -->
                            <form @submit.prevent="sendFileForm" class="row g-4">
    
                                <!-- Profile picture -->                                
                                <div class="col-12 justify-content-center align-items-center">
                                    <label class="form-label">Profile picture</label>
                                    <div class="d-flex align-items-center">
                                        <div v-if="photo" >
                                            <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                                <!-- Avatar place holder -->
                                                <span class="avatar avatar-xl">
                                                    <img :src="photo" id="uploadfile-1-preview" class="avatar-img rounded-circle border border-white border-3 shadow"  alt="">
                                                </span>
                                                <!-- Remove btn -->
                                                <button type="button" class="uploadremove" data-bs-toggle="modal" data-bs-target="#modalDelPhoto">
                                                    <i class="bi bi-x text-white"></i>
                                                </button>
                                            </label>
                                        </div>
                                        <div v-else-if="phototmp" >
                                            <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                                <!-- Avatar place holder -->
                                                <span class="avatar avatar-xl">
                                                    <img :src="phototmp" id="uploadfile-1-preview" class="avatar-img rounded-circle border border-white border-3 shadow"  alt="">
                                                </span>
                                                <!-- Remove btn -->
                                                <button type="button" @click="resetImage" class="updateremove"><i class="bi bi-x text-white"></i></button>
                                            </label>
                                            <!-- Upload button -->
                                            <label class="btn btn-primary-soft mb-0" for="uploadfile">Save</label>
                                            <input type="submit" id="uploadfile" class="form-control d-none" >

                                        </div>

                                        <div v-else >
                                            <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                                <!-- Avatar place holder -->
                                                <span class="avatar avatar-xl">
                                                    <img id="uploadfile-1-preview" class="avatar-img rounded-circle border border-white border-3 shadow" src="/assets/images/avatar/user.png" alt="">
                                                </span>
                                            </label>

                                            <!-- Upload button -->
                                            <label class="btn btn-primary-soft mb-0" for="uploadfile-1">Change</label>
                                            <input type="file" accept="image/*" ref="photoref" @change="onChangeFile" id="uploadfile-1" class="form-control d-none" >
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- Card body END -->
                    </div>
                    <!-- Edit profile END -->
                    
                </div>
                <!-- Main content END -->
            </div><!-- Row END -->
        </div>
    </section>
    <!-- =======================
    Page content END -->
    
    </main>
                
    <hr/>
    </div>
</template>

<script>
var filename__ = "[pages > studentdash > StudentDashPhotoUpdate.vue]" ;
import axios from 'axios';

import StudentDashboardTop from '../../components/studentdash/StudentDashboardTop.vue';
import StudentDashboardLeft from '../../components/studentdash/StudentDashboardLeft.vue';

export default {
    components: {
        "student-dashboard-top": StudentDashboardTop,
        "student-dashboard-left": StudentDashboardLeft,
    },
    props: [],
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null,             
            dialogTitle: null,       
            
            inputId : null,

            inputPhoto : null ,
            inputPhotoUpdate : null,  
            inputPhotoTmp : null, 
            
        }
    },
    beforeMount() {
        this.$store.dispatch('auth/outPageAdmin'); //일반 사용자 페이지
    },     
    created(){        
        if(this.isAuth()){  //로그인 여부 체크 && 관리자 여부 체크 
            this.id = this.isUser() ;
            if(this.id) {
                this.getItem();
            }
            
        } else {
            this.dialogTitle ="Error" ;
            this.error = "로그인이 필요합니다";    
            this.errorLogin = true ;
        }       
    },    
    computed: {
        isLoggedIn(){
            return this.$store.getters['auth/isAuthenticated'];
        },
        email : function(){
            return this.$store.getters['auth/email'] ;
        },

        photo(){
            // console.log("[StudentDashProfieleUpdate.vue] photo ()inputPhotoUpdate :", this.inputPhoto);
            return this.inputPhoto ;
        },
        phototmp(){
            // console.log("[StudentDashProfieleUpdate.vue] photo ()inputPhotoTmp :", this.inputPhotoTmp);
            return this.inputPhotoTmp ;
        },
    },    

    methods: {
        handleError(){
            this.error = null;
            if(this.errorLogin){
                this.$router.replace('/signin');
            }
            if(this.dialogTitle === 'Login'  ){
                this.$router.replace('/signin');
            }

        },
        isAuth(){
            // console.log("[StudentDashProfieleUpdate.vue] isAuth():", this.$store.getters['auth/isAuthenticated']);
            return this.$store.getters['auth/isAuthenticated'] ;
        },
        isUser(){
            // console.log("[StudentDashProfieleUpdate.vue] isUser():", this.$store.getters['auth/userId']);
            return this.$store.getters['auth/userId'] ;
        },
        async getItem(){
            let funcname__ ="getMember() ";

            this.isLoading = true ;   //setTimeout(function(){}, 3000); // delay 체크 
            
            
            let serverData = await axios({
                method: 'get',
                url: '/api/user/photo',
                headers: {
                    'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                },                

            }).catch( err => {

                if(err.response.status === 401) {  
                    this.dialogTitle ="Login" ;
                    this.error = "로그인이 필요한 페이지입니다.";          

                    // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                    this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                    
                } else {
                    let dataForm = { 
                        category: 'errorlog(api catech)' , 
                        filename : filename__,
                        methodname : funcname__,
                        title: 'Error(API)',       
                        content : ''+err,
                    } ;  
                    console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                    axios({
                        method: 'post',
                        url: '/api/errorlog/create',
                        data: dataForm,
                    });

                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                    
                    this.isLoading = false ;

                    return ;
                }

            });


            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData === "undefined" || serverData === undefined ||  serverData.status !== 200){
                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

            } else {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+" data:", data);

                if( typeof data !== "undefined" && data.status_code == 200){
                    // console.log(filename__+''+funcname__+' response:', data.response);
                    
                    const profile = data.response ;

                    this.inputId = profile.id ;
                    this.inputPhoto = profile.photo ? profile.photo : '' ;
                    this.inputPhotoUpdate = profile.photo ? profile.photo : '' ;

                    // console.log(filename__+''+funcname__+' inputId:', this.inputId);

                } else if( typeof data !== "undefined" && data.status_code == 401){
                    this.dialogTitle ="Error" ;                    
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [401] ";  // this.error = err.msg || 'Failed to authenticate';                

                } else {
                    console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);

                    this.dialogTitle ="실패" ;                    
                    this.error = serverData.data.error_description;  // this.error = err.msg || 'Failed to authenticate';                
                    // alert('error')
                }
            }


            this.isLoading = false ;            
        },

        // https://codepen.io/Atinux/pen/qOvawK/        
        resetImage: function(){
            // this.inputPhoto= null;
            // this.$refs.photoref.value = null ;  //업로드 type=file 내용 없애기 
            this.inputPhotoUpdate = null;  // this.inputImage = this.$refs.file.files[0];
            this.inputPhotoTmp = null;

        },
        onChangeFile(evt){
            // console.log(filename__+''+funcname__+' onChangeFile()1 ');
            // const files = evt.target.files[0] || evt.dataTransfer.files;
            const file = evt.target.files[0] ;

            // console.log(filename__+''+funcname__+' onChangeFile()2 file:', file);
            
            this.inputPhotoUpdate = file;  // this.inputImage = this.$refs.file.files[0];
            this.inputPhotoTmp = URL.createObjectURL(file);
        },

        //프로필 사진 업로드 
        async sendFileForm(){
            let funcname__ ="sendFileForm() ";

            if(this.inputPhotoUpdate == null){
                this.dialogTitle ="Error" ;
                this.error = "업로드할 사진을 선택해주세요.";
                return ;                       

            }
            // 파일전송 FormData() : https://hagohobby.tistory.com/21 
            const formData = new FormData()
            formData.append('photo', this.inputPhotoUpdate);

            // console.log(filename__+''+funcname__+' formData:', formData);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'post',
                    url: `/api/user/photo/upload`,
                    headers: {
                        'Content-Type' : 'multipart/form-data',
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 
                    data: formData

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }     

                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {
    
                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){

                        // console.log(filename__+''+funcname__+' data:', data);
                        
                        this.inputPhoto = data.photo_path ;
                        this.inputPhotoUpdate = null ;
                        this.inputPhotoTmp = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '사진 변경 성공했습니다.';    
                        
                        const user_id = this.$store.getters['auth/userId'] ;

                        //로그인한 사람과 수정하는 정보가 같으면, 최상단 사진 정보 업데이트 
                        if(data.id == user_id) {
                            // console.log(filename__+''+funcname__+' photo:', this.inputPhoto);

                            const actionPayload = {
                                photo: this.inputPhoto
                            };
                            this.$store.dispatch('auth/Photo', actionPayload);  //  /store/modules/auth/actions.js + mutations.js 

                        }

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 
                            }, 1000);
                        }
                        
                        
                    } else {
                        console.error(filename__+''+funcname__+' err.error_description:',data.error_description);

                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다[else].';
                    }
                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        //프로필 사진 삭제  
        async removePhotoForm(){
            let funcname__ ="removePhotoForm() ";

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: `/api/user/photo/delete`,
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          

                        // 401 에러생기면 로그아웃()을 불러서 기존토큰 모두 없앰 
                        this.$store.dispatch('auth/Logout'); //==> store/modules/auth/actions.js
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";  
                        
                        this.isLoading = false ;

                        return ;
                    }

                });

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData === "undefined" || serverData === undefined  ||  serverData.status !== 200){                    
                    this.dialogTitle ="Error" ;
                    this.error = "Error가 발생했습니다. 관리자 문의부탁합니다. [500]";   

                } else {

                    const data = serverData.data ;

                    if( typeof data !== "undefined" && data.status_code == 201){
                        // console.log(filename__+''+funcname__+' data:', data);
                        
                        this.inputPhoto = null ;
                        this.inputPhotoUpdate = null ;
                        this.inputPhotoTmp = null ;

                        this.dialogTitle ='Success' ;
                        this.error = '사진 삭제 성공했습니다.';    

                        const user_id = this.$store.getters['auth/userId'] ;

                        //로그인한 사람과 수정하는 정보가 같으면, 최상단 사진 정보 업데이트 
                        if(this.id == user_id) {
                            const actionPayload = {
                                photo: ''
                            };
                            this.$store.dispatch('auth/Photo', actionPayload);  //  /store/modules/auth/actions.js + mutations.js 

                        }  
                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null 
                            }, 1000);
                        }                      

                    } else {
                        console.error(filename__+''+funcname__+' err.error_description:',data.error_description);
                        this.dialogTitle ='error' ;
                        this.error = '관리자에게 문의 부탁합니다.';
                    }

                }                    

            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="실패" ;
                this.error = error; 
            }
            this.isLoading = false ;

        },

    }
}
</script>

<style scoped>

</style>