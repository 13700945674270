<template>
<div class="col-sm-6 col-xl-3">
    <div class="card shadow h-100">
        <!-- Image -->
        <div v-if="!is_text">
            <router-link :to="detailLink">
                <img v-if="image" :src="image" class="card-img-top" alt="course image">
                <img v-else src="/assets/images/courses/course.png" class="card-img-top" alt="course image">
            </router-link>
        </div>

        <!-- Card body -->
        <div class="card-body pb-0">
            <!-- Badge and favorite -->
            <div class="d-flex justify-content-between mb-2">
                <div>
                    <a v-if="level ==0" href="#" class="badge bg-success bg-opacity-10 text-success">All level</a>
                    <a v-else-if="level ==1" href="#" class="badge bg-primary bg-opacity-10 text-primary">Beginner</a>
                    <a v-else-if="level ==2" href="#" class="badge bg-orange bg-opacity-10 text-orange">Intermediate</a>
                    <a v-else-if="level ==3" href="#" class="badge bg-purple bg-opacity-10 text-purple">Advanced</a>
                </div>
            </div>
            <!-- Title -->
            <h5 class="card-title"><router-link :to="detailLink">{{title}}</router-link></h5>
            <p v-if="is_text" class="mb-2">{{subtitleCompu}}</p>
            <p v-else class="mb-2 text-truncate-2">{{subtitleCompu}}</p>
            
            <div class="d-flex justify-content-between mb-2">
                <!-- Rating star -->
                <ul v-if="content_category == 'course'" class="list-inline mb-0">
                    <li v-if="reviewavg > 0" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    <li v-if="reviewavg >= 1" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    <li v-if="reviewavg >= 2" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    <li v-if="reviewavg >= 3" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>
                    <li v-if="reviewavg >= 4" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>
                    <li v-else class="list-inline-item me-0 small"><i class="far fa-star text-warning"></i></li>

                    <li v-if="reviewavg == 5" class="list-inline-item me-0 small"><i class="fas fa-star text-warning"></i></li>

                    <li class="list-inline-item ms-2 h6 fw-light mb-0">{{reviewavg}}/5.0</li>
                </ul>
                <a v-if="categorystr" href="#" class="badge bg-primary bg-opacity-10 text-success ms-1"><b>{{ categorystr }}</b></a>
            </div>
        </div>
        <!-- Card footer -->
        <div v-if="content_category == 'course'" class="card-footer pt-0 pb-3">
            <hr>
            <div class="d-flex justify-content-between">
                <span class="h6 fw-light mb-0"><i class="fas fa-table text-orange me-2"></i>{{lecturecount}}회 강의</span>
                <span class="h6 fw-light mb-0"><i class="far fa-clock text-danger me-2"></i>{{timeFormat}}</span>
            </div>
        </div>
    </div>
</div>

</template>

<script>
// var filename__="[components > courseedit > EditCourseListComponent.vue] "

export default {
    components: {},
    props: [
        'id','num','create_id','create_name','create_email','create_photo','date',
        'content_id',
        'content_category', 
        'title','subtitle','level','coursetime','lecturecount',
        'image','is_text',
        'is_display', 'reviewavg', 
        'categorystr',
        'page', 
    ],
    data() {
        return {
            isLoading: false,
            error: null,     
            errorLogin : null, 
            dialogTitle: null,  

        }
    },
    
    computed: {
        detailLink(){
            // return '/instructordash/course/'+this.id+'?page='+this.$route.query.page+"&search="+this.$route.query.search
            if(this.content_category =='course'){
                return '/editcourse/'+this.content_id+'?page='+this.page ;
            } else {
                return '/editblog/'+this.content_id+'?page='+this.page ;
            }
            
        },
        subtitleCompu(){            
            if(this.is_text) {                
                return this.subtitle.slice(0,260)+"..."; 
            } else {
                return this.subtitle.slice(0,100)+"..."; 
            }
        },
        timeFormat(){
            let hour = parseInt(this.coursetime/60) ;
            let minute = this.coursetime - hour * 60 ;
            if(hour == 0){
                return this.coursetime +'분'                
            } else if(minute == 0){
                return hour+'시간'
            } else {
                return hour + '시간 '+minute + '분' ;                
            }
        },
        isAuth(){
            return this.$store.getters['auth/isAuthenticated'] ;
        },
    },
    methods: {
        handleError(){
            this.error = null;
        },

    },
    
}
</script>