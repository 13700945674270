<template>
<div>
    <base-dialog :show="!!error" :title="dialogTitle" @close="handleError">
        <p>{{ error }}</p>
    </base-dialog>

    <base-dialog :show="isLoading" title="Loading..." fixed>
        <p>Loading...</p>
        <base-spinner></base-spinner>
    </base-dialog>

    <!-- update youtube topic  -->
    <teleport v-if="kind != 5" to="body" :id="idModalUpdateCompu">
        <div class="modal fade" :id="idTopicUpdateCompu" tabindex="-1" aria-labelledby="updateTopicLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content" style="width:900px">
                    <div class="modal-header bg-dark">
                        <h5 class="modal-title text-white" :id="idTopicUpdateCompu">Update Topic</h5>
                        <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close" ><i class="bi bi-x-lg"></i></button>
                    </div>
                    <form @submit.prevent="sendTopicUpdateForm">
                        <div class="modal-body">
                            <div class="row text-start g-3">

                                <div class="col-12 mt-3">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIsDisplayUpdate" @click="onChangeIsDisplayUpdate">
                                        <label v-if="inputIsDisplayUpdate" class="form-check-label" for="onoffPublic">외부 공개</label>
                                        <label v-else class="form-check-label" for="onoffPublic">외부 비공개</label>
                                    </div>
                                </div>
                                <div class="col-12 mt-3">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIs_priceUpdate" @click="onChangePriceUpdate">
                                        <label v-if="inputIs_priceUpdate" class="form-check-label" for="onoffPublic">수강신청 후 확인가능 </label>
                                        <label v-else class="form-check-label" for="onoffPublic">무료보기</label>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <label class="form-label">Topic name</label>
                                    <input class="form-control" type="text" placeholder="Enter topic name" v-model.trim="inputTitleUpdate">
                                </div>
                                <div class="col-12 mt-3">
                                    <label class="form-label">Topic 수강시간(minutes)</label>
                                    <input class="form-control" type="number" placeholder="Enter topic time" v-model="inputTopictimeUpdate">
                                </div>

                                <!-- Video link -->
                                <div v-if="kindUpdateCompu == 2" class="col-12 mt-3">
                                    <div class="col-md-12">
                                        <label class="form-label">Youtube 링크를 복사해서 입력해주세요</label>
                                        <input class="form-control" type="text" placeholder="https://www.youtube.com/watch?v=nPIYUS2UqUg"  v-model.trim="inputYoutubeTotalstr" style="width:100%;" @input="onChangeTotal($event)">
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">ex) <small>https://www.youtube.com/watch?v=</small>
                                            <b>nPIYUS2UqUg</b>
                                            <small>&t=1s</small>
                                            &nbsp;&nbsp;&nbsp;
                                            <b>(굵은부분만)</b>
                                        </label>
                                        <input class="form-control" type="text" placeholder="nPIYUS2UqUg"  v-model.trim="inputYoutubestrUpdate" style="width:200px;">
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">Link</label>
                                        <input class="form-control" type="text" placeholder="Enter Video link" v-model.trim="inputUrlUpdate">
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">Save link : {{urlUpdateCompu}}</label>
                                    </div>
                                    <h5 v-if="urlUpdateCompu" class="mt-4">Video preview</h5>
                                    <div v-if="urlUpdateCompu" class="position-relative">
                                        <div class="ratio ratio-16x9">
                                            <iframe 
                                                :src="urlUpdateCompu" 
                                                title="YouTube video player" 
                                                frameborder="0" 
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                allowfullscreen
                                            ></iframe>                                                            
                                        </div>
                                    </div>
                                </div>

                                <!-- Text -->
                                <div v-if="kindUpdateCompu == 4" class="col-12 mt-3">
                                    <label class="form-label">Content (<a href="https://gist.github.com/ihoneymon/652be052a0727ad59601" target="_blank">마크다운 설명 링크</a>)</label>
                                    <div class="editor">
                                        <textarea class="input" v-model.trim="inputMarkdown" ></textarea>
                                        <div class="output" v-html="outputMarkdownCompu"></div>
                                    </div>

                                    <!-- <label class="form-label">텍스트 설명</label>
                                    <textarea class="form-control" rows="10" placeholder="설명을 입력해주세요" v-model.trim="inputDescription"></textarea> -->

                                    <div class="col-md-12 mt-2"  >                             
                                        <label class="position-relative me-4" for="uploadfile-1">
                                            위의 Markdown와 연동된 이미지입니다.이미지 클릭(경로 복사) 
                                        </label>                    
                                    </div>
                                    <div v-if="inputIsClick" class="col-md-12 mt-2"  >                             
                                            <!-- 이미지 업로드  -->
                                            <content_id-fileupload-panel-component 
                                                :key="id+'image'"
                                                :content_id="id" 
                                                :is-multi-file='true'
                                                :file-kind="'image'"
                                                :category="'topic'" 
                                                :sub-category="'description'" 
                                                
                                            />
                                        </div>
                                        <div class="col-md-12 mt-4 mb-3"  >  
                                            <!-- 첨부 파일 업로드 -->
                                            <content_id-fileupload-panel-component 
                                                :key="id+'file'"
                                                :content_id="id" 
                                                :is-multi-file='true'
                                                :file-kind="'file'"
                                                :category="'topic'" 
                                                :sub-category="'description'" 
                                            />
                                        </div>
                                </div>

                                <!-- Image -->
                                <div v-if="content_id && kindUpdateCompu == 1 " class="col-12 mt-3">
                                    <content_id-main-image-fileupload-panel-component
                                        :key="content_id+'topicmain'"
                                        :file-kind="'image'"
                                        :category="'topic'" 
                                        :sub-category="'main'" 
                                        :content_id="id" 
                                    />

                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger-soft my-0" data-bs-dismiss="modal" >Close</button>
                            <button type="submit" class="btn btn-success my-0" data-bs-dismiss="modal">Save Topic</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </teleport>
    
    <!-- update cardnews topic  -->
    <teleport v-if="kind == 5" to="body" :id="idModalCardnewsUpdateCompu">
        <div class="modal fade" :id="idCardnewsUpdateCompu" tabindex="-1" aria-labelledby="updateTopicLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content" style="width:900px">
                    <div class="modal-header bg-dark">
                        <h5 class="modal-title text-white" :id="idCardnewsUpdateCompu">Update Cardnews Topic</h5>
                        <button type="button" class="btn btn-sm btn-light mb-0" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"><i class="bi bi-x-lg"></i></button>
                    </div>
                    <form @submit.prevent="sendTopicUpdateForm">
                        <div class="modal-body">
                            <div class="row text-start g-3">

                                <div class="col-12 mt-3">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIsDisplayUpdate" @click="onChangeIsDisplayUpdate">
                                        <label v-if="inputIsDisplayUpdate" class="form-check-label" for="onoffPublic">외부 공개</label>
                                        <label v-else class="form-check-label" for="onoffPublic">외부 비공개</label>
                                    </div>
                                </div>
                                <div class="col-12 mt-3">
                                    <div class="form-check form-switch form-check-md">
                                        <input class="form-check-input" type="checkbox" role="switch" id="onoffPublic" checked="" v-model="inputIs_priceUpdate" @click="onChangePriceUpdate">
                                        <label v-if="inputIs_priceUpdate" class="form-check-label" for="onoffPublic">수강신청 후 확인가능 </label>
                                        <label v-else class="form-check-label" for="onoffPublic">무료보기</label>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <label class="form-label">Topic name</label>
                                    <input class="form-control" type="text" placeholder="Enter topic name" v-model.trim="inputTitleUpdate">
                                </div>
                                <div class="col-12 mt-3">
                                    <label class="form-label">Topic 수강시간(minutes)</label>
                                    <input class="form-control" type="number" placeholder="Enter topic time" v-model="inputTopictimeUpdate">
                                </div>
                                <div v-if="inputIsClick" class="col-12 mt-3">
                                    <!-- 카드뉴스 이미지 업로드  -->
                                    <content-cardnews-fileupload-panel-component 
                                        :key="createCardnewsModalUuidid"
                                        :initialize="true"
                                        :group_id="content_id"
                                        :parent_id="parentIdCompu"
                                        :company_id="company_id"
                                        :is-multi-file='true'
                                        :file-kind="'image'"
                                        :category="'topic'" 
                                        :sub-category="'cardnews'" 
                                    />
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger-soft my-0" data-bs-dismiss="modal" @click="closeModal">Close</button>
                            <button type="submit" class="btn btn-success my-0" data-bs-dismiss="modal">Save Topic</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </teleport>
    
    <!-- topic 삭제 모달  -->
    <teleport to="body">
        <div class="modal fade" :id="idTopicDeleteCompu" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header bg-warning">
                    <h1 class="modal-title fs-5" id="idDeleteCompu">Delete Topic</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>해당 내용을 삭제하시겠습니까?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" @click="removeTopicForm" class="btn btn-danger" data-bs-dismiss="modal">Delete</button>
                </div>
                </div>
            </div>
        </div>
    </teleport>



    <div class="d-flex justify-content-between align-items-center">
        <div class="position-relative">
            <a v-if="kind == 2" class="btn btn-danger-soft btn-round btn-sm mb-0 stretched-link position-static"><i class="fas fa-play"></i></a>
            <a v-else-if="kind == 4" class="btn btn-primary-soft btn-round btn-sm mb-0 stretched-link position-static"><i class="fas fa-file-alt fa-fw"></i></a>
            <a v-else-if="kind == 1" class="btn btn-success-soft btn-round btn-sm mb-0 stretched-link position-static"><i class="bi bi-card-image"></i></a>
            <a v-else-if="kind == 5" class="btn btn-orange-soft btn-round btn-sm mb-0 stretched-link position-static"><i class="bi bi-boxes"></i></a>

            <span class="ms-2 mb-0 h6 fw-light">

                <!-- <a v-if="!topic.is_display" href="#" class="btn btn-sm btn-primary-soft btn-round me-1 ms-1 mb-1 mb-md-0"><i class="fas fa-eye"></i>hidden</a> -->
                <a v-if="is_price" class="btn btn-sm btn-primary-soft me-1 ms-1 mb-1 mb-md-0" disabled>수강신청</a>
                <span v-if="is_display" class="me-1 ms-1" >{{title}} (<b>{{topictime}}</b> minutes)</span>
                <span v-else class="text-decoration-line-through me-1 ms-1">{{title}} (<b>{{topictime}}</b> minutes)</span>

            </span>
        </div>
        <!-- Edit and cancel button -->
        <div>
            <!-- up  -->
            <button v-if="subseq ==1 " 
                    class="btn btn-sm btn-secondary mb-0 me-3" disabled
                ><i class="fas fa-arrow-alt-circle-up"></i></button>

            <button v-else
                    @click="upSeqTopicForm" 
                    class="btn btn-sm btn-info mb-0 me-3" 
                ><i class="fas fa-arrow-alt-circle-up"></i></button>

            <!-- down      -->
            <button v-if="is_max" 
                    class="btn btn-sm btn-secondary mb-0 me-3" disabled
                ><i class="fas fa-arrow-circle-down"></i></button>

            <button v-else
                    @click="downSeqTopicForm" 
                    class="btn btn-sm btn-danger mb-0 me-3"
                ><i class="fas fa-arrow-circle-down"></i></button>

            <!-- 카드뉴스  -->
            <button v-if="kind == 5" type="button" class="btn btn-sm btn-success-soft me-1 mb-1 mb-md-0"
                    @click="getTopic"                    
                    data-bs-toggle="modal" 
                    :data-bs-target="'#'+idCardnewsUpdateCompu"
                ><i class="far fa-fw fa-edit"></i>수정</button>

            <!-- 카드뉴스 이외  -->
            <button v-else type="button" class="btn btn-sm btn-success-soft me-1 mb-1 mb-md-0"
                    @click="getTopic"                    
                    data-bs-toggle="modal" 
                    :data-bs-target="'#'+idTopicUpdateCompu"
                ><i class="far fa-fw fa-edit"></i>수정</button>

            <button type="button" class="btn btn-sm btn-danger-soft mb-0"
                data-bs-toggle="modal" 
                :data-bs-target="'#'+idTopicDeleteCompu"
                ><i class="fas fa-fw fa-times"></i>삭제</button>
                
        </div>
    </div>
    <!-- Divider -->
    <hr>

</div>    
</template>

<script>
// v-for__string__
var filename__ = "[components > companydash > CompanyDashCourseTopicListComponent.vue] " ;

import axios from 'axios';
import { marked } from 'marked';         // yarn add marked  https://marked.js.org/
import { v4 } from 'uuid';  // 참고자료 uuid__ : https://jane-aeiou.tistory. com/59

import ContentIdFileUploadPanelComponent from '../content/ContentIdFileUploadPanelComponent.vue' ;
import ContentIdMainImageFileUploadPanelComponent from '../content/ContentIdMainImageFileUploadPanelComponent.vue' ;
import ContentCardnewsFileUploadPanelComponent from '../content/ContentCardnewsFileUploadPanelComponent.vue' ;


export default {
    components: {
        'content_id-fileupload-panel-component': ContentIdFileUploadPanelComponent,
        'content_id-main-image-fileupload-panel-component': ContentIdMainImageFileUploadPanelComponent,
        'content-cardnews-fileupload-panel-component': ContentCardnewsFileUploadPanelComponent,
    },
    props: [ 
        'company_id',
        'content_id','lecture_id',
        'id',
        'is_price','is_display','title','topictime','kind',
        'description',
        'subseq','is_max'
    ],
    emits:['refresh-lecture-list'],
    inject:['curriculumlist'], // CompnayDashCourseCurriculumPanelUpdateComponent.vue 에서 설정
    data() {
        return {
            isLoading: false,
            isApplying: false, 
            error: null,     
            errorLogin : null, 
            dialogTitle: null,   
            
            inputIsClick : false, // list에서 개별 content file 리스트 불러오는 것 방지 위해

            inputKindUpdate : null,

            inputTitleUpdate : null,
            inputIsDisplayUpdate : null,
            inputIs_priceUpdate: false,
            inputTopictimeUpdate : 0,

            inputDescriptionUpdate: null,

            inputYoutubeTotalstr : null, 
            inputUrlUpdate : null,
            inputYoutubestrUpdate: null,

            inputMarkdown: null,

            inputParentId: null, 

        }
    },
    created(){
        // console.log(filename__+' created() id:', this.id ) ;
    },    
    computed: {
        outputMarkdownCompu(){
            return marked(this.inputMarkdown);
        },		
        idModalUpdateCompu(){
            return 'modalUpdate'+this.id ;
        },
        idTopicUpdateCompu(){
            return 'updateTopciModal'+this.id
        },
        idTopicDeleteCompu(){
            return 'deleteTopicModal'+this.id
        },
        idModalCardnewsUpdateCompu(){
            return 'modalCardnewsUpdate'+this.id ;
        },
        idCardnewsUpdateCompu(){
            return 'updateCardnewsModal'+this.id
        },
        idTopicDeleteCompu(){
            return 'deleteTopicModal'+this.id
        },
        nameUpdateCompu(){
            return 'nameUpdate'+this.id ;
        },
        kindUpdateCompu(){
            // console.log(filename__+' kindUpdateCompu() inputKindUpdate:', this.inputKindUpdate ) ;
            return this.inputKindUpdate ;
        },      
        urlUpdateCompu(){
            if(this.inputYoutubestrUpdate){
                return 'https://www.youtube.com/embed/'+this.inputYoutubestrUpdate+'?autohide=0&showinfo=0&controls=0' ;
            } else {
                return this.inputUrlUpdate;
            }            
        },
        parentIdCompu(){
            return this.inputParentId ;
        }
    },
    methods: {
        handleError(){
            this.error = null;
        },
        createCardnewsModalUuidid(){
            // uuid 생성 : key를 매번 새롭게 갱신해주여야 itemList 변경되었을 때 새롭게 갱신됨
            const generate_uuid = () => {
                const tokens = v4().split('-')
                return tokens[2] + tokens[1] + tokens[0] + tokens[3] + tokens[4];
            }
            return generate_uuid() ;
        },
        onChangePriceUpdate(){
            this.inputIs_priceUpdate = !this.inputIs_priceUpdate ;
        },

        onChangeIsDisplayUpdate(){
            this.inputIsDisplayUpdate = !this.inputIsDisplayUpdate ;
        },
        onChangeButton(_kind){                  
            this.inputKindUpdate = parseInt(_kind)  ;
        },
        closeModal(){
            this.inputIsClick = false ;
        },
        // topic
        async getTopic(){
            let funcname__ ="getTopic()" ;
            this.inputIsClick = true ;
            this.inputParentId = this.id ;
            this.isLoading = true;

            let serverData = await axios({
                method: 'get',
                url: '/api/content/course/curriculum/topic/'+this.id,
                headers: {
                    Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                },

            }).catch( err=> {

                let dataForm = { 
                    category: 'errorlog(api catech)' , 
                    filename : filename__,
                    methodname : funcname__,
                    title: 'Error(API)',       
                    content : ''+err,
                } ;  
                console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                axios({
                    method: 'post',
                    url: '/api/errorlog/create',
                    data: dataForm,
                });

                this.dialogTitle ="Error" ;
                this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                return ;


            });

            this.isLoading = false ;

            // console.log(filename__+''+funcname__+" serverData:", serverData);

            if(typeof serverData !== "undefined" && serverData) {
                const data = serverData.data ;

                // console.log(filename__+''+funcname__+"  data:", data);

                if(data.status_code == 200 ){

                    const response = data.response ;

                    console.log(filename__+''+funcname__+' response:', response);
                    
                    this.inputKindUpdate = response.kind ;

                    this.inputTitleUpdate = response.title ;

                    this.inputIs_priceUpdate = response.is_price ;
                    this.inputTopictimeUpdate = response.topictime ;
                    this.inputIsDisplayUpdate = response.is_display ;

                    this.inputUrlUpdate = response.url ;
                    this.inputYoutubestrUpdate = response.youtubestr ;

                    this.inputMarkdown = response.description ;
                }

            } else {
                console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                this.dialogTitle ="Error" ;
                this.error = serverData.data.error_description;
            }

        },

        async sendTopicUpdateForm(){
            let funcname__ ="sendTopicUpdateForm()" ;
            

            if(this.inputYoutubestrUpdate){
                this.inputUrlUpdate = 'https://www.youtube.com/embed/'+this.inputYoutubestrUpdate+'?autohide=0&showinfo=0&controls=0'
            }

            let dataForm = { 
                'group_id': this.content_id,  // topic 시간 및 갯수 업데이트 
                'kind' : this.inputKindUpdate,
                'title' : this.inputTitleUpdate,
                'is_price': this.inputIs_priceUpdate,
                'topictime': this.inputTopictimeUpdate,
                'is_display' : this.inputIsDisplayUpdate,
                'url' : this.inputUrlUpdate,
                'youtubestr': this.inputYoutubestrUpdate,
                'description': this.inputMarkdown,
            } ;  

            if( this.inputTitleUpdate === '' || this.inputTitleUpdate === undefined || this.inputTitleUpdate === null ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="타이틀을 입력해주세요.";
                return ;
            }
            // 1: 이미지 2: 영상 링크 3: 실제 영상 업로드 4: text 5: image+text 
            // 동영상 topic 
            if( this.inputKindUpdate === 2 && ( this.inputUrlUpdate === '' || this.inputUrlUpdate === undefined || this.inputUrlUpdate === null) ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="동영상 링크를 입력해주세요.";
                return ;
            }
            // 텍스트 topic
            if( this.inputKindUpdate === 4 && (this.inputMarkdown === '' || this.inputMarkdown === undefined || this.inputMarkdown === null ) ) {
                this.formIsValid = false ;
                this.dialogTitle = 'Error'
                this.error ="텍스트를 입력해주세요.";
                return ;
            }

            console.log(filename__+''+funcname__+' dataForm:', dataForm);

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/curriculum/topic/update/'+this.id,
                    data: dataForm,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.inputTitleUpdate = null ;
                        // this.inputDescription = null ;
                        this.inputUrlUpdate = null ;
                        this.inputYoutubestrUpdate = null ;
                        this.inputIs_priceUpdate = false ;
                        this.inputTopictimeUpdate = 0 ;
                        
                        this.dialogTitle ='Success' ;
                        this.error = '변경에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            console.log(filename__+''+funcname__+' Success');
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                this.curriculumlist();

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;
        },


        async upSeqTopicForm(){
            let funcname__ ="upSeqTopicForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/curriculum/topic/upseq/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }


                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                // this.$emit('refreshLecture');
                                this.curriculumlist(); //inject

                            }, 100);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },
        async downSeqTopicForm(){
            let funcname__ ="downSeqTopicForm()" ;

            this.isLoading = true ;

            try {
                let serverData = await axios({
                    method: 'put',
                    url: '/api/content/course/curriculum/topic/downseq/'+this.id,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;
                                // this.$emit('refreshLecture');
                                this.curriculumlist(); //inject

                            }, 100);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },

        async removeTopicForm(){
            let funcname__ ="removeTopicForm()" ;

            // const isConfirm = confirm('Are you delete ?');
            // if(!isConfirm) return ;

            this.isLoading = true ;

            // 카드뉴스일 경우 카드뉴스 하위 content까지 삭제
            let del_url = null 
            if(this.kind == 5){
                del_url = '/api/content/course/curriculum/cardnews/delete/'+this.id 
            } else {
                del_url = '/api/content/course/curriculum/topic/delete/'+this.id 
            }

            try {
                let serverData = await axios({
                    method: 'delete',
                    url: del_url,
                    headers: {
                        Authorization: `Bearer ${this.$store.getters['auth/isToken']}`, 
                    }, 

                }).catch( err => {

                    if(err.response.status === 401) {  
                        this.dialogTitle ="Login" ;
                        this.error = "로그인이 필요한 페이지입니다.";          
                                        
                    } else {
                        let dataForm = { 
                            category: 'errorlog(api catech)' , 
                            filename : filename__,
                            methodname : funcname__,
                            title: 'Error(API)',       
                            content : ''+err,
                        } ;  
                        console.log(filename__+''+funcname__+" err dataForm:", dataForm);  //~~log

                        axios({
                            method: 'post',
                            url: '/api/errorlog/create',
                            data: dataForm,
                        });

                        this.dialogTitle ="Error" ;
                        this.error = "Error가 발생했습니다. 관리자 문의부탁합니다.";   
                        return ;
                    }

                });

                if(typeof serverData === "undefined"  ||  serverData.status !== 200){
                    this.isLoading = false ;
                    return ;
                }

                // console.log(filename__+''+funcname__+" serverData:", serverData);

                if(typeof serverData !== "undefined" && serverData) {
                    if(serverData.data.status_code == 201){
                        
                        this.dialogTitle ='Success' ;
                        this.error = '삭제에 성공했습니다.';     

                        if(this.dialogTitle ==='Success'){
                            setTimeout(() => {
                                this.error = null;
                                this.dialogTitle = null ;

                                this.curriculumlist(); //inject

                            }, 500);
                        }                        

                    } else {
                        console.log(filename__+''+funcname__+' err.error_description:', serverData.data.error_description);
                    
                        this.dialogTitle ="Error" ;
                        this.error = serverData.data.error_description;
    
                    }
                } else {
                    this.isLoading = false ;

                    this.dialogTitle ='error' ;
                    this.error = '관리자에게 문의 부탁합니다.';

                }


            } catch(error){
                console.error(filename__+''+funcname__+' error:',error);

                this.isLoading = false ;

                this.dialogTitle ="전송 실패" ;
                this.error = error; 
            }

            this.isLoading = false ;

        },


    },
    
}
</script>
<style scoped>
.editor {
  height: 50vh;
  display: flex;
}

.input,
.output {
  overflow: auto;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.input {
  border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 20px;
}

code {
  color: #f66;
}
</style> 